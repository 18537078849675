import * as ModelEvent from "@node-elion/syncron";

import ServiceSubscribeOptionsBase from "../../types/ServiceSubscribeOptionsBase";
import { NonEditableProperties } from "../../types/NonEditableProperties";
import Subscription from "../../types/Subscription";
import SubscriptionPool from "../../redux/services/SubscriptionPool";
import createLogger from "../../utils/logger.util";
import Base from "../Base";

const logger = createLogger({ name: "ChatMessage" });

class ChatMessage extends Base {
	static fromResponse(data: any): ChatMessage.Model {
		if (!data) {
			throw new Error("ChatMessage: Data is undefined or null");
		}
		const response = data;

		return response;
	}

	static toRequestNew(model: ChatMessage.Model.New): ChatMessage.Model.New {
		return model;
	}

	static toRequestModified(
		model: ChatMessage.Model.Modified,
	): ChatMessage.Model.Modified {
		return model;
	}

	public static async getAll(object: ChatMessage.SubscribeOptions) {
		try {
			const data = await this.request((prpc) =>
				prpc.theirsModel.chatMessage.getAll(
					this.optionsToRequest(object),
				),
			);
			return data;
		} catch (error) {
			logger.error("Error in ChatMessage.getAll:", error);
			throw error;
		}
	}

	public static async setLastRead(id: number) {
		try {
			const response = await this.request(
				(prpc) => prpc.theirsModel.chatMessage.setLastRead(id),
				{ silent: false, error: true },
			);

			return response;
		} catch (error) {
			logger.error("Error in ChatMessage.setLastRead:", error);
			throw error;
		}
	}

	public static async getLastRead() {
		try {
			const response = await this.request(
				(prpc) => prpc.theirsModel.chatMessage.getLastRead(),
				{ silent: false, error: true },
			);

			return response;
		} catch (error) {
			logger.error("Error in ChatMessage.getLastRead:", error);
			throw error;
		}
	}

	public static async store(object: ChatMessage.Model.New) {
		try {
			const response = await this.request(
				(prpc) =>
					prpc.theirsModel.chatMessage.create(
						this.toRequestNew(object),
					),
				{ silent: false, error: true },
			);

			if (response?.id > 0) await this.setLastRead(response.id);

			return response;
		} catch (error) {
			logger.error("Error in ChatMessage.store:", error);
			throw error;
		}
	}

	public static async update(object: ChatMessage.Model.Modified) {
		const response = await this.request(
			(prpc) =>
				prpc.theirsModel.chatMessage.update(
					this.toRequestModified(object),
				),
			{ silent: false, error: true },
		);
		return response;
	}

	public static async readSosMessageByDispatcher(messageId: number) {
		try {
			const response = await this.request(
				(prpc) =>
					prpc.theirsModel.chatMessage.readSosMessageByDispatcher(
						messageId,
					),
				{ silent: false, error: true },
			);
			logger.info("readSosMessageByDispatcher", response);
			return response;
		} catch (error) {
			logger.error("readSosMessageByDispatcher", error);
			throw error;
		}
	}

	public static async subscribe(
		options: ChatMessage.SubscribeOptions,
		onUpdate: Subscription.OnUpdate<ChatMessage.Model>,
	): Promise<Subscription<ChatMessage.SubscribeOptions> | null> {
		const modelEventConstructor = new ModelEvent.ModelEventConstructor({
			onUpdate: (state) => {
				if (!state.models) {
					logger.error("State models are undefined");
					return;
				}
				// console.log("[ChatMessage] subscribe", state);
				const models = state.models.map(this.fromResponse);
				onUpdate({
					...state,
					models,
				});
			},
		});

		// console.group("Chat modelSubscriptionOptions");

		// console.log("result:");
		// console.table(result);

		// console.groupEnd();

		try {
			const subscription = await SubscriptionPool.add(
				(prpc) => {
					const params = this.optionsToRequest(options);
					// console.group("Chat modelSubscriptionOptions");

					// console.log("result:");
					// console.table(params);

					// console.groupEnd();
					return prpc.theirsModel.chatMessage.subscribe({
						params,
						// params: {
						// 	order: { createdAt: "DESC" },
						// },
						ping: () => true,
						onEvent: (events) => {
							modelEventConstructor.onEvent(events);
						},
						onError: (error) => {
							logger.error(
								"Subscribe to chat messages error: %o",
								error,
							);
						},
					});
				},
				{ name: "ChatMessage.subscribe" },
			);

			return {
				unsubscribe: () => subscription.unsubscribe(),
				update: (options: ChatMessage.SubscribeOptions) =>
					subscription.update(this.optionsToRequest(options)),
			} as Subscription<ChatMessage.SubscribeOptions>;
		} catch (error) {
			logger.error("Error chat during subscription: %o", error);
			throw error;
		}
	}

	private static optionsToRequest(options: ChatMessage.SubscribeOptions) {
		return options;
		// return {
		// 	offset: options.offset,
		// 	limit: options.limit,
		// 	// query: options.query,
		// 	// chatId: options.chatId,
		// };
	}
}

export enum Priority {
	SOS = "sos",
}

declare namespace ChatMessage {
	export interface Model extends NonEditableProperties {
		content: string;
		type?: string | undefined;
		chatMessageToAllowedDispatchers?: number[];
		chatMessageToAllowedExecutors?: number[];
		executor?: ChatMessage.OutputGrouped.Executor;
		priority?: Priority;
	}

	interface SubscribeOptions
		extends ServiceSubscribeOptionsBase<ChatMessage.Model> {
		executorId?: number;
		dispatcherId?: number;
		limit?: number;
		offset?: number;
		lang: string;
		showedExecutorIds?: number[];
		priority?: Priority;
		unreadMessages?: boolean;
	}

	namespace Model {
		interface Modified {
			readonly content: string;
			readonly parentId?: number;
			readonly allowedExecutorIds?: number[];
			readonly allowedDispatcherIds?: number[];

			id: number;
			createdAt: Date;
			updatedAt: Date;
			deletedAt?: Date;
			chatMessageToAllowedDispatchers?: number[];
			chatMessageToAllowedExecutors?: number[];
		}

		interface New {
			readonly content: string;
			readonly expirationDate?: Date | undefined;
			readonly parentId?: number;
			readonly allowedExecutorIds?: number[];
			readonly allowedDispatcherIds?: number[];
			// type?: string | undefined; // TODO need to add in backend
		}
	}

	export namespace OutputGrouped {
		interface Person {
			readonly id: number;
			readonly createdAt: string;
			updatedAt: string;
			deletedAt?: string | null;
			name?: string;
			surname?: string;
			fatherName?: string;
			birthday?: string | null;
			country?: string | null;
			city?: string | null;
			address?: string | null;
			phones?: Record<string, any>[] | null;
		}

		interface Passport {
			number?: string;
			series?: string;
			issuedAt?: string | null;
			issuedBy?: string;
			birthPlace?: string;
			residencePlace?: string;
		}

		interface Settings {
			fontName?: string;
			fontSize?: number;
			language?: string;
		}

		interface AdditionalFields {
			taxId?: string;
			phones?: string[];
			passport?: Passport;
		}

		interface Dispatcher {
			readonly id: number;
			readonly createdAt: string;
			updatedAt: string;
			deletedAt?: string | null;
			callSign?: string;
			v?: number;
			login?: string;
			password?: string;
			notes?: string;
			status?: string;
			settings?: Settings;
			additionalFields?: AdditionalFields;
			closedAt?: string | null;
			ipWhiteList?: string[];
			person?: Person;
		}

		interface Executor {
			readonly id: number;
			readonly createdAt: string;
			person?: Person;
			callSign?: string;
			content?: string;
		}

		interface ChatMessage {
			readonly id: number;
			readonly createdAt: string;
			updatedAt: string;
			deletedAt?: string | null;
			v?: number;
			children?: any[];
			chatMessageToAllowedDispatchers?: any[];
			chatMessageToAllowedExecutors?: any[];
			content?: string;
			expirationDate?: string | undefined;
			executor?: any;
			dispatcher?: Dispatcher;
			type?: string | undefined;
			parent?: ChatMessage;
			priority?: Priority;
		}
	}
}

export default ChatMessage;
