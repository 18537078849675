import { TFunction } from "i18next";

const getOrderOptions = (t: TFunction) => [
	{
		key: 1,
		value: 1,
		label:
			t(
				"pages.mainPage.pages.accounts.tabs.executorTeams.modal.content.tabs.main.blocks.sixth.str202",
			) ?? "",
	},
	{
		key: 2,
		value: 2,
		label:
			t(
				"pages.mainPage.pages.accounts.tabs.executorTeams.modal.content.tabs.main.blocks.sixth.str203",
			) ?? "",
	},
	{
		key: 3,
		value: 3,
		label:
			t(
				"pages.mainPage.pages.accounts.tabs.executorTeams.modal.content.tabs.main.blocks.sixth.str204",
			) ?? "",
	},
	{
		key: 4,
		value: 4,
		label:
			t(
				"pages.mainPage.pages.accounts.tabs.executorTeams.modal.content.tabs.main.blocks.sixth.str205",
			) ?? "",
	},
]; // orderOptions

export default getOrderOptions;
