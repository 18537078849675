import React, { Dispatch, memo, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { cloneDeep } from "lodash";
import { Column } from "uikit";
import Content from "./components/Content";
import defaultValue from "./constants/defaultValue";
import Modal from "./components/Modal";
import DefaultPageHeader from "../../../../../../../../../../../../components/DefaultPageHeader";
import DeleteModal from "../../../../../../../../../../../../components/DeleteModal";
import TabRoot from "../../components/TabRoot";
import useObjectEditor from "../../../../../../../../../../../../hooks/useObjectEditor";

const Addresses: React.FC<Addresses.Props> = memo((props) => {
	const { visible, hasPaddings, value, onChange } = props;

	const editor = useObjectEditor(value, onChange);
	const addresses = editor.useGetter("addresses");
	const setAddresses = editor.useSetter("addresses");

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [actualDefaultValue, setActualDefaultValue] = useState(defaultValue);

	const [showModal, setShowModal] = useState(false);
	const [showDeleteModal, setShowDeleteModal] = useState(false);

	const [selected, setSelected] = useState<number[]>([]);

	const [editingItem, setEditingItem] =
		useState<Modal.Value>(actualDefaultValue);
	const [indexEditingItem, setIndexEditingItem] = useState<number | null>(
		null,
	);

	const edit = useCallback(
		(index: number) => {
			const item = addresses[index];

			setEditingItem(cloneDeep(item));
			setShowModal(true);
			setSelected([]);
		},
		[addresses],
	);

	const editHeaderHandler = useCallback(() => {
		setIndexEditingItem(selected[0]);
		edit(selected[0]);
	}, [edit, selected]);

	const editContentHandler = useCallback(
		(index: number) => {
			setIndexEditingItem(index);
			edit(index);
		},
		[edit],
	);

	const addHandler = useCallback(() => {
		setEditingItem(actualDefaultValue);
		setShowModal(true);
	}, [actualDefaultValue]);

	const preDeleteHandler = useCallback(() => {
		setShowDeleteModal(true);
	}, []);

	const cancelDelete = useCallback(() => {
		setShowDeleteModal(false);
		setSelected([]);
	}, []);

	const deleteHandler = useCallback(() => {
		const newRewards = addresses.filter((_, i) => !selected.includes(i));
		setAddresses(newRewards);
		setSelected([]);
		setShowDeleteModal(false);
	}, [addresses, selected, setAddresses]);

	const cancelHandler = useCallback(() => {
		setShowModal(false);
		setEditingItem(actualDefaultValue);
	}, [actualDefaultValue]);

	const saveHandler = useCallback(
		(model: Modal.Value, index: number | null = null) => {
			const internalIndex = index ?? indexEditingItem;

			if (internalIndex != null) {
				const newModels = [...addresses];
				newModels[internalIndex] = model;
				setAddresses(newModels);
			} else {
				setAddresses([...addresses, model]);
			}

			setShowModal(false);
			setEditingItem(actualDefaultValue);
			setIndexEditingItem(null);
		},
		[actualDefaultValue, addresses, indexEditingItem, setAddresses],
	);

	const { t } = useTranslation();
	return (
		<TabRoot visible={visible} hasPaddings={hasPaddings}>
			<Column sizes="auto! 1fr" gaps="16px" maxedWidth maxedHeight>
				<DefaultPageHeader
					canEdit={selected.length === 1}
					canDelete={!!selected.length}
					onAdd={addHandler}
					onDelete={preDeleteHandler}
					onEdit={editHeaderHandler}
					padding="20px 20px 0 20px"
				/>
				<Content
					value={addresses}
					selected={selected}
					setSelected={setSelected}
					onEdit={editContentHandler}
					saveHandler={saveHandler}
				/>
				{showModal && (
					<Modal
						value={editingItem}
						onCancel={cancelHandler}
						onSave={saveHandler}
						headerTitle={
							t(
								"pages.mainPage.pages.customers.tabs.agents.modal.content.tabs.addresses.str200",
							) ?? ""
						}
					/>
				)}
				{showDeleteModal && (
					<DeleteModal
						label={
							t(
								"pages.mainPage.pages.customers.tabs.agents.modal.content.tabs.addresses.str201",
							) ?? ""
						}
						onCancel={cancelDelete}
						onConfirm={deleteHandler}
					/>
				)}
			</Column>
		</TabRoot>
	);
});

declare namespace Addresses {
	interface Props extends TabRoot.Props {
		value: Value;
		onChange: Dispatch<Value>;
	}

	interface Value {
		addresses: Modal.Value[];
	}
}

export default Addresses;
