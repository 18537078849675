import React, { memo, useMemo } from "react";
import Transaction from "../../../../../../../../../../../../services/Transaction";
import TransactionsTab from "../../../../../../../../../../../../components/TransactionsTab";
import { PaymentAccount } from "../../../../../../../../../../../../types/PaymentAccount";
import {
	StyledRow,
	SuspenseLoader,
} from "../../../../../../../../../../../../components/common";

const Transactions: React.FC<Transactions.Props> = memo(
	({ paymentAccounts, ...props }) => {
		const paymentAccountIds = useMemo(
			() => paymentAccounts?.map((a) => a.id).filter(Boolean),
			[paymentAccounts],
		);

		const subscribeOptions = useMemo<Transaction.SubscribeOptions>(
			() => ({
				paymentAccountIds,
			}),
			[paymentAccountIds],
		);
		try {
			return (
				<TransactionsTab
					options={subscribeOptions}
					isSubscribe={!!paymentAccountIds?.length}
					tableFrom="customers.counterparty"
					{...props}
				/>
			);
		} catch (error) {
			return (
				<StyledRow position="absolute" top="50%" left="50%">
					<SuspenseLoader />
				</StyledRow>
			);
		}
	},
);

declare namespace Transactions {
	interface Props
		extends Omit<TransactionsTab.Props, "options" | "tableFrom"> {
		paymentAccounts: PaymentAccount[] | undefined;
	}
}

export default Transactions;
