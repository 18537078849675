import { RefObject } from "react";
import { react } from "uikit";
import MainData from "./components/MainData";
import WorkData from "./components/WorkData";

interface Context {
	mainDataRef: RefObject<MainData.Ref | null>;
	workDataRef: RefObject<WorkData.Ref | null>;
}

export default class Controller extends react.ControllerBase<Context> {
	async validate() {
		const isWorkDataValid =
			!!(await this.context?.workDataRef.current?.validate());
		const isMainDataValid = !!this.context?.mainDataRef.current?.validate();

		return isMainDataValid && isWorkDataValid;
	}
}
