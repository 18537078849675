import React, { Dispatch, useCallback, useMemo, useState } from "react";
import { Button, Icon, InputGroup, Row, TextBox, react, theme } from "uikit";
import { clamp, clone } from "lodash";
import { useTranslation } from "react-i18next";

import LabeledField from "../../../../../../../../../../../../../../../../../components/LabeledField";
import useObjectEditor from "../../../../../../../../../../../../../../../../../hooks/useObjectEditor";
import {
	countryCodePhone,
	validatePhone,
} from "../../../../../../../../../../../../../../../../../utils/validatePhone";

import InternalController from "./Controller";

declare namespace Phones {
	type Ref = InternalController | null;

	type Controller = InternalController;

	type Value = string[];

	interface Props {
		value: Value;
		onChange: Dispatch<Value>;

		min?: number;
		max?: number;
		errors: boolean[];
	}
}

const Phones = react.withController<Phones.Props, Phones.Controller>(
	({
		controller,
		value,
		onChange,
		min = 1,
		max = Number.MAX_SAFE_INTEGER,
	}) => {
		const { t } = useTranslation();

		const internalValue = useMemo(
			() =>
				value.length < min
					? clone(value).concat(Array(min - value.length).fill(""))
					: clone(value).slice(0, max),
			[value, min, max],
		);

		const valueEditor = useObjectEditor(internalValue, onChange);
		const [errors, setErrors] = useState<boolean[]>([]);

		controller.setContext({
			phones: valueEditor.value,
			onPhoneErrors: setErrors,
		});

		const addPhone = useCallback(() => {
			valueEditor.push("");
		}, [valueEditor]);

		const onPhoneChange = useCallback(
			(newPhone, index: number) => {
				const phone = countryCodePhone(newPhone, "uk");

				valueEditor.set(index, phone);

				const errors = valueEditor.value.map(
					(phone) => validatePhone(phone) !== true,
				);

				setErrors(errors);
			},
			[valueEditor],
		);

		const renderField = useCallback(
			(index: number) => {
				const isFirstItem = index === 0;
				const isLastItem = index === valueEditor.value.length - 1;
				const isMaxedItem = index === (max ?? 0) - 1;
				const hasMoreThanOneItem = valueEditor.value.length > 1;

				const label = isFirstItem
					? t(
							`mainPage.customers.clients.main.personalData.mainPhone`,
					  ) || "Main page"
					: t(
							`mainPage.customers.clients.main.personalData.additionalPhone`,
					  ) || "Additional phone";

				let sizes = "";

				if (isFirstItem && !hasMoreThanOneItem) {
					sizes = "1fr 32px";
				} else if (!isLastItem || (isLastItem && isMaxedItem)) {
					sizes = "1fr";
				} else if (isLastItem) {
					sizes = "1ft 32px";
				}

				return (
					<>
						<Row
							key={index}
							gaps="8px*"
							sizes={sizes}
							align="end"
							justify="stretch"
						>
							<LabeledField label={label}>
								{!isFirstItem ? (
									<InputGroup.InputGroup
										sizes="1fr 32px!"
										error={errors[index]}
									>
										<TextBox.TextBox
											error={errors[index]}
											autoComplete="one-time-code"
											type="phone"
											style={{
												minHeight: "32px",
												flex: "1 0 0",
											}}
											placeholder={label}
											value={valueEditor.get(index) ?? ""}
											onChange={(value) =>
												onPhoneChange(value, index)
											}
										/>

										<Button.Button
											icon={
												<Icon
													id="minus"
													size={16}
													colors={[
														theme.colors.white,
													]}
												/>
											}
											style={{
												borderTopLeftRadius: 0,
												borderBottomLeftRadius: 0,
											}}
											onClick={() => {
												valueEditor.remove(index);

												setErrors((prev) => {
													const newErrors =
														Array.from(prev);
													newErrors.splice(index, 1);
													return newErrors;
												});
											}}
										/>
									</InputGroup.InputGroup>
								) : (
									<TextBox.TextBox
										error={errors[index]}
										autoComplete="one-time-code"
										type="phone"
										style={{
											minHeight: "32px",
											flex: "1 0 0",
										}}
										placeholder={label}
										value={valueEditor.get(index) ?? ""}
										onChange={(value) =>
											onPhoneChange(value, index)
										}
									/>
								)}
							</LabeledField>
						</Row>

						{isLastItem && !isMaxedItem && (
							<div>
								<Button.Button
									style={{
										flexShrink: 1,
									}}
									icon={
										<Icon
											id="plus"
											size={16}
											colors={[theme.colors.white]}
										/>
									}
									onClick={addPhone}
								/>
							</div>
						)}
					</>
				);
			},
			[valueEditor, max, t, errors, addPhone, onPhoneChange],
		);

		return (
			<>
				{Array(clamp(valueEditor.value.length, min, max))
					.fill(null)
					.map((_, index) => renderField(index))}
			</>
		);
	},
	InternalController,
);

export default Phones;
