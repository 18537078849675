import * as ModelEvent from "@node-elion/syncron";

import SubscriptionPool from "../../redux/services/SubscriptionPool";
import IResponseWithItems from "../../types/IResponse";
import ServiceSubscribeOptionsBase from "../../types/ServiceSubscribeOptionsBase";
import Subscription from "../../types/Subscription";
import { NonEditableProperties } from "../../types/NonEditableProperties";
import Language from "../Language";
import Dispatcher from "../Dispatcher";
import Client from "../Client";
import Executor from "../Executor";
import TaxiService from "../TaxiService";
import Base from "../Base";

class BankTransaction extends Base {
	public static fromResponse(data: any): BankTransaction.Model {
		console.log("[BankTransaction]", data);
		return {
			id: data.id,
			transactionId: data.transactionId,
			executor: data.executor
				? Executor.fromResponse(data.executor) || null
				: null,
			customer: data.customer ? Client.fromResponse(data.customer) : null,
			dispatcher: data.dispatcher
				? Dispatcher.fromResponse(data.dispatcher)
				: null,
			taxiService: data.taxiService
				? TaxiService.fromResponse(data.taxiService)
				: null,
			order: data.order,
			amount: data.amount,
			amountWithFee: data.amountWithFee,
			callbackRaw: data.callbackRaw,
			paymentTransaction: data.paymentTransaction,
			paymentSystemAccount: data.paymentSystemAccount,
			status: data.status,
			type: data.type,

			amountFromCustomer: data.amountFromCustomer || 0,
			amountToExecutorCard: data.amountToExecutorCard || 0,
			amountToExecutorMain: data.amountToExecutorMain || 0,
			statusDescription: data.statusDescription || "",
			createdAt: data.createdAt,
			updatedAt: data.updatedAt,
			deletedAt: data.deletedAt,
			remountTransactionType:
				data.remountTransactionType || RemountTransactionType.UNKNOWN,
			orderNumber: data.order?.orderNumber,
			orderId: data.order?.id,
		};
	}

	public static async getAll(
		object?: BankTransaction.SubscribeOptions,
	): Promise<IResponseWithItems<BankTransaction.Model>> {
		const response = await this.request((prpc) =>
			prpc.theirsModel.bankTransaction.getAll(object),
		);

		return {
			...response,
			items: response?.items?.map(this.fromResponse),
		};
	}

	public static async subscribe(
		options: BankTransaction.SubscribeOptions,
		onUpdate: Subscription.OnUpdate<BankTransaction.Model>,
	): Promise<Subscription<BankTransaction.SubscribeOptions> | null> {
		const modelEventConstructor = new ModelEvent.ModelEventConstructor({
			onUpdate: (state) => {
				onUpdate({
					...state,

					models: state.models.map(this.fromResponse),
				});
			},
		});

		const subscription = await SubscriptionPool.add(
			(prpc) =>
				prpc.theirsModel.bankTransaction.subscribe({
					params: this.optionsToRequest(options),
					ping: () => true,
					onEvent: (events) => {
						modelEventConstructor.onEvent(events);
					},
					onError: (error) => {
						console.error(error);
					},
				}),
			{ name: "BankTransaction.subscribe" },
		);

		return {
			unsubscribe: () => subscription.unsubscribe(),
			update: (options: BankTransaction.SubscribeOptions) =>
				subscription.update(this.optionsToRequest(options)),
		} as Subscription<BankTransaction.SubscribeOptions>;
	}

	private static optionsToRequest(options: BankTransaction.SubscribeOptions) {
		return {
			limit: options.limit,
			offset: options.offset,
			query: options.query,
			statuses: options.statuses,
			types: options.types,
			dateRange: options.dateRange,
			lang: options.language,
			order: options.order,
		};
	}
}
export enum RemountTransactionType {
	HOLD = "hold",
	REFUND = "refund",
	WITHDRAW = "withdraw",
	REPLENISHMENT = "replenishment",
	UNKNOWN = "unknown",
}
declare namespace BankTransaction {
	interface Model extends NonEditableProperties {
		transactionId: number;
		amount: number;
		amountWithFee: number;
		dispatcher: Dispatcher.Model | null;
		customer: Client.Model | null;
		executor: Executor.Model | null;
		taxiService: TaxiService.Model | null;
		order: any;
		callbackRaw: CallbackRaw;
		paymentTransaction: null;
		paymentSystemAccount: any;
		status: PaymentTransactionStatus;
		type: BankTransactionType;
		remountTransactionType: RemountTransactionType;
		orderNumber: number | null;
		orderId: number;
		amountFromCustomer: number;
		amountToExecutorCard: number;
		amountToExecutorMain: number;
		statusDescription: string;
	}

	interface SubscribeOptions
		extends ServiceSubscribeOptionsBase<BankTransaction.Model> {
		types?: BankTransactionType[];
		statuses?: PaymentTransactionStatus[];
		dateRange?: {
			from: Date;
			to: Date;
		};
		language?: Language;
	}
	type PaymentTransactionStatus = "pending" | "completed" | "cancelled";

	type BankTransactionType = "order_payment" | "replenishment_executor_card";

	interface CallbackRaw {
		Card: {
			CardBin: string | null;
			CardBank: string | null;
			CardMask: string | null;
			CardType: string | null;
			CardBrand: string | null;
			CardLevel: string | null;
			NameOnCard: string | null;
			CardCountry: string | null;
			PaymentToken: string | null;
		};
		Order: {
			Fee: number;
			Amount: number;
			OrderId: string;
			Currency: string | null;
			AltAmount: number;
			CustomInfo: string | null;
			AltCurrency: string | null;
			CreditAmount: number;
		};
		Address: {
			Zip: string | null;
			City: string | null;
			Zone: string | null;
			Country: string | null;
			FullAddress: string | null;
		};
		Payment: {
			Eci: string | null;
			Rrn: string | null;
			Status: string | null;
			ErrorCode: number;
			InvoiceId: string | null;
			Signature: string;
			StatusCode: number;
			TerminalId: string;
			BankOrderId: string | null;
			IsRedirect3DS: boolean;
			OperationType: string;
			TransactionId: number;
			CapturedAmount: number;
			RefundedAmount: number;
			PaymentSystemId: number;
			TransactionDate: string;
			ErrorDescription: string | null;
			OperationTypeCode: number;
		};
		Customer: {
			Email: string | null;
			Phone: string | null;
			LastName: string | null;
			FirstName: string | null;
			CustomerId: string | null;
		};
		Products: null;
		IpAddress: string;
		Recurrent: null;
		RefundedProducts: null;
	}
}

export default BankTransaction;
