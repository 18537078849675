/* eslint-disable no-shadow */
import * as ModelEvent from "@node-elion/syncron";

import OptionsBase from "../../types/ServiceSubscribeOptionsBase";
import Subscription from "../../types/Subscription";
import Base from "../Base";
import {
	NonEditableProperties,
	NonEditablePropertyNames,
} from "../../types/NonEditableProperties";
import { BlacklistPhoneLevel } from "../../pages/MainPage/pages/Blacklist/tabs/Customers/components/Modal/components/Content/tabs/Main/components/Level/constants";
import Company from "../Company";
import { ModelId } from "../../types/ModelId";
import SubscriptionPool from "../../redux/services/SubscriptionPool";

class CustomersBlacklist extends Base {
	public static fromResponse(data: any): CustomersBlacklist.Model {
		const companies = data.blacklistPhoneToCompanies.flatMap((item) =>
			Company.fromResponse(item.company),
		);

		return {
			id: data.id,

			companies,
			companyIds: companies.map(({ id }) => id),
			phone: data.phone,
			level: data.level,
			description: data.description,
			surname: data.surname,
			name: data.name,
			fatherName: data.fatherName,

			createdAt: data.createdAt,
			updatedAt: data.updatedAt,
			deletedAt: data.deletedAt,
		};
	}

	public static toRequest(
		model: CustomersBlacklist.New | CustomersBlacklist.Modified,
	) {
		const payload = {
			phone: model.phone || "",
			level: model.level,
			description: model.description || "",
			surname: model.surname || "",
			name: model.name || "",
			fatherName: model.fatherName || "",
			companyIds: model.companyIds,
		};

		console.log("toRequest", { payload, model });
		return payload;
	}

	public static async getAll() {
		const res = await this.request((prpc) =>
			prpc.theirsModel.blacklistPhone.getAll(),
		);
		return res?.items?.map(this.fromResponse);
	}

	public static async store(object: CustomersBlacklist.New) {
		try {
			await this.request((prpc) =>
				prpc.theirsModel.blacklistPhone.create(this.toRequest(object)),
			);
		} catch (err: any) {
			return false;
		}
		return true;
	}

	public static async update(object: CustomersBlacklist.Modified) {
		try {
			await this.request((prpc) =>
				prpc.theirsModel.blacklistPhone.update(
					object.id,
					this.toRequest(object),
				),
			);
		} catch (err: any) {
			return false;
		}
		return true;
	}

	public static async delete(id: number[] | number) {
		this.request((prpc) => prpc.theirsModel.blacklistPhone.delete(id));
	}

	public static async subscribe(
		options: CustomersBlacklist.SubscribeOptions,
		onUpdate: Subscription.OnUpdate<CustomersBlacklist.Model>,
	): Promise<Subscription<CustomersBlacklist.SubscribeOptions> | null> {
		const modelEventConstructor = new ModelEvent.ModelEventConstructor({
			onUpdate: (state) => {
				onUpdate({
					...state,
					models: state.models.map(this.fromResponse),
				});
			},
		});
		const subscription = await SubscriptionPool.add(
			(prpc) =>
				prpc.theirsModel.blacklistPhone.subscribe({
					params: options,
					ping: () => true,
					onEvent: async (events) => {
						await modelEventConstructor.onEvent(events);
					},
					onError: (error) => {
						// eslint-disable-next-line no-console
						console.log(error);
					},
				}),
			{ name: "CustomersBlacklist.subscribe" },
		);

		return {
			unsubscribe: () => subscription.unsubscribe(),
			update: (options: CustomersBlacklist.SubscribeOptions) =>
				subscription.update(options),
		};
	}
}

declare namespace CustomersBlacklist {
	interface Model extends NonEditableProperties {
		companies?: Company.Model[];
		companyIds: ModelId[];
		phone: string;
		level: BlacklistPhoneLevel;
		description: string;
		surname: string;
		name: string;
		fatherName?: string;
	}

	type New = Omit<Model, NonEditablePropertyNames>;
	type Modified = Partial<Model> & Pick<NonEditableProperties, "id">;

	interface SubscribeOptions extends OptionsBase<CustomersBlacklist.Model> {}
}

export default CustomersBlacklist;
