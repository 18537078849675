import { react } from "uikit";
import { Dispatch, RefObject } from "react";
import { isNil } from "lodash";
import WorkData from ".";
import ParkNumber from "./components/ParkNumber";

interface Context {
	value: WorkData.Value;

	parkNumberRef: RefObject<ParkNumber.Controller>;

	setTaxiServiceIdError: Dispatch<boolean>;
}

export default class Controller extends react.ControllerBase<Context> {
	async validate() {
		const isValidTaxiServiceId = !isNil(this.context?.value.taxiServiceId);
		const isParkNumberValid =
			await this.context?.parkNumberRef.current?.validate();

		if (!isValidTaxiServiceId) this.context?.setTaxiServiceIdError(true);

		return isValidTaxiServiceId && isParkNumberValid;
	}
}
