import React, { memo, useCallback, useState } from "react";
import { useRefWithSetter } from "uikit";
import EditModal from "../../../../../../../../components/EditModal";
import useObjectEditor from "../../../../../../../../hooks/useObjectEditor";
import Content from "./components/Content";
import Header from "./components/Header";
import Root from "./components/Root";
import { NonEditableProperties } from "../../../../../../../../types/NonEditableProperties";
import ClientGroup from "../../../../../../../../services/ClientGroup";

const Modal: React.FC<Modal.Props> = memo((props) => {
	const {
		clients,
		clientById,
		value,
		onCancel,
		onSave,
		headerTitle,
		editorTable,
		onChangeTable,
	} = props;

	const [contentRef, setContentRef] = useRefWithSetter<Content.Ref>(null);

	const [internalValue, setInternalValue] = useState(value);
	const editor = useObjectEditor(internalValue, setInternalValue);

	const contentValues = editor.usePicker([
		"name",
		"clientIds",
		"notes",
		"id",
	]);

	const handleSubmit = useCallback(() => {
		if (!contentRef.current?.validate()) return;

		onSave(internalValue);
	}, [contentRef, internalValue, onSave]);

	return (
		<EditModal onCancel={onCancel} onSave={handleSubmit}>
			<Root sizes="auto! 1fr">
				<Header title={headerTitle} createdAt={value.createdAt} />
				<Content
					ref={setContentRef}
					clients={clients}
					clientById={clientById}
					value={contentValues}
					onChange={editor.assign}
					editorTable={editorTable}
					onChangeTable={onChangeTable}
				/>
			</Root>
		</EditModal>
	);
});

declare namespace Modal {
	interface Props {
		clients: Content.Props["clients"];
		clientById: Content.Props["clientById"];
		editorTable: Content.Props["editorTable"];
		onChangeTable: Content.Props["onChangeTable"];
		value: Value;
		headerTitle: string;
		onCancel: () => void;
		onSave: (
			model: ClientGroup.New | ClientGroup.Modified,
		) => Promise<void>;
	}

	interface Value extends Content.Value, Partial<NonEditableProperties> {}
}

export default Modal;
