/* eslint-disable no-shadow */

import React, { Dispatch, memo, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Icon, InputGroup, Row, theme } from "uikit";

import SearchIconBorders from "../../../../../../../../../../components/SearchIconBorders";
import SearchTextBox from "../../../../../../../../../../components/SearchTextBox";

const Filters = memo<Filters.Props>(({ value, onChange }) => {
	const { t } = useTranslation();

	const searchTextBoxOnChange = useCallback(
		(search: string) => {
			onChange?.({
				...value,

				search,
			});
		},
		[onChange, value],
	);

	return (
		<Row sizes="270px!" gaps="12px 10px*" align="center">
			<InputGroup.InputGroup sizes="auto 1fr">
				<SearchIconBorders>
					<Icon
						id="search2"
						size={16}
						colors={[theme.colors.disabled_text]}
					/>
				</SearchIconBorders>
				<SearchTextBox
					placeholder={
						t(
							"pages.mainPage.pages.accounts.tabs.workShifts.header.filters.find",
						) ?? ""
					}
					value={value.search}
					onChange={searchTextBoxOnChange}
				/>
			</InputGroup.InputGroup>
		</Row>
	);
});

declare namespace Filters {
	interface Value {
		search: string;
	}

	interface Props {
		value: Value;

		onChange: Dispatch<Value>;
	}
}

export default Filters;
