import React, { Dispatch, Key } from "react";
import { Option } from "uikit";
import Root from "./components/Root";
import Item from "./components/Item";

// eslint-disable-next-line prettier/prettier
const List = <OptionValue, ValueType extends Key>({ value, options, onChange }: List.Props<OptionValue, ValueType>) => (
	<Root align="stretch">
		{options.map((option) => (
			<Item
				key={option.key}
				value={value === option.key}
				label={option.label}
				onChange={(itemValue) => {
					if (itemValue) onChange(option.key as ValueType);
					else onChange(undefined);
				}}
			/>
		))}
	</Root>
);

declare namespace List {
	type Value<Type extends Key> = Type | undefined;

	interface Props<OptionValue, ValueType extends Key> {
		value: Value<ValueType>;

		options: Option<OptionValue>[];

		onChange: Dispatch<Value<ValueType>>;
	}
}

export default List;
