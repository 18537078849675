import { TFunction } from "i18next";

interface Column {
	id: string;
	label: string;
}

const getColumns = (t: TFunction): Column[] => [
	{
		id: "fullName",
		label:
			t(
				"pages.mainPage.pages.accounts.tabs.executorTeams.modal.content.tabs.executorSelectTab.modelTable.columns.fullName",
			) ?? "Full Name",
	},
	{
		id: "roles",
		label:
			t(
				"pages.mainPage.pages.accounts.tabs.executorTeams.modal.content.tabs.executorSelectTab.modelTable.columns.roles",
			) ?? "Roles",
	},
	{
		id: "team",
		label:
			t(
				"pages.mainPage.pages.accounts.tabs.executorTeams.modal.content.tabs.executorSelectTab.modelTable.columns.team",
			) ?? "Team",
	},
	{
		id: "alias",
		label:
			t(
				"pages.mainPage.pages.accounts.tabs.executorTeams.modal.content.tabs.executorSelectTab.modelTable.columns.alias",
			) ?? "Alias",
	},
	{
		id: "company",
		label:
			t(
				"pages.mainPage.pages.accounts.tabs.executorTeams.modal.content.tabs.executorSelectTab.modelTable.columns.company",
			) ?? "Company",
	},
	{
		id: "taxiService",
		label:
			t(
				"pages.mainPage.pages.accounts.tabs.executorTeams.modal.content.tabs.executorSelectTab.modelTable.columns.taxiService",
			) ?? "Taxi Service",
	},
];
const defaultColumnIds = [
	"fullName",
	"roles",
	"team",
	"alias",
	"company",
	"taxiService",
];

export { getColumns, defaultColumnIds };
