import React from "react";
import { ToggleButton } from "uikit";

import { Role } from "../../../../../../../../../../../../services";
import { CellContentRoot } from "../../../../../../../../../../../../components/LightTable";

const ActiveCellContent: React.FC<ActiveCellContent.Props> = ({ item }) => {
	const content = (
		<div onClick={(event) => event.stopPropagation()}>
			<ToggleButton.ToggleButton
				value={!!item.active}
				onChange={(value) => {
					Role.update({
						...(item as Role.Model),

						active: value,
					});
				}}
			/>
		</div>
	);

	return (
		<CellContentRoot alignItems="center" w="100%" h="100%">
			{content}
		</CellContentRoot>
	);
};

declare namespace ActiveCellContent {
	interface Props {
		item: Role.Model;
	}
}

export default ActiveCellContent;
