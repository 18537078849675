/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable no-shadow */

import React, { Dispatch, RefAttributes, memo } from "react";
import { useTranslation } from "react-i18next";
import { Column, MultilineTextBox, react, useRefWithSetter } from "uikit";

import Language from "../../../../../../../../../../../../services/Language";
import FieldsContainer from "../../../../../../../../../../../../components/FieldsContainer";
import useObjectEditor from "../../../../../../../../../../../../hooks/useObjectEditor";
import TabRoot from "../TabRoot";

import WorkData from "./components/WorkData";
import InternalController from "./Controller";
import MainData from "./components/MainData";

const MainTab = memo(
	react.withController<MainTab.PropsBase, MainTab.Controller>(
		({
			controller,

			value,

			disabled,
			visible,
			language,
			originalTaxiServiceId,
			originalParkNumber,
			allowedTaxiServiceId,
			currentId,

			onChange,
		}) => {
			const { t } = useTranslation();
			const [mainDataRef, setMainDataRef] =
				useRefWithSetter<MainData.Ref | null>(null);
			const [workDataRef, setWorkDataRef] =
				useRefWithSetter<WorkData.Ref | null>(null);

			controller.setContext({ mainDataRef, workDataRef });

			const valueEditor = useObjectEditor(value, onChange);

			const mainDataValue = valueEditor.usePicker([
				"carColorId",
				"carBaseTypeId",
				"carBodyTypeId",
				"carBrandId",
				"carModelId",
				"carClassId",
				"taxiServiceId",

				"registrationNumber",
				"vehicleNumber",
				"manufactureYear",
				"seats",

				"carClass",
				"serviceIds",
				"distributableCarClassIds",
				"broadcastableCarClassIds",
			]);

			const workDataValue = valueEditor.usePicker([
				"taxiServiceId",
				"carParkId",
				"responsibleDispatcherId",
				"carClassId",
				"carClass",
				"parkNumber",
			]);

			const assignValue = valueEditor.useAssigner();

			const notesOnChange = valueEditor.useSetter("notes");

			return (
				<TabRoot hasPaddings visible={visible}>
					<Column
						sizes="auto!*2 1fr"
						gaps="19px*"
						maxedWidth
						maxedHeight
					>
						<FieldsContainer
							label={
								t(
									"pages.mainPage.pages.accounts.tabs.car.editModal.content.mainTab.str1",
								) ?? ""
							}
						>
							<WorkData
								ref={setWorkDataRef}
								value={workDataValue}
								disabled={disabled}
								language={language}
								originalTaxiServiceId={originalTaxiServiceId}
								originalParkNumber={originalParkNumber}
								currentId={currentId}
								onChange={assignValue}
								allowedTaxiServiceId={allowedTaxiServiceId}
							/>
						</FieldsContainer>
						<FieldsContainer
							label={
								t(
									"pages.mainPage.pages.accounts.tabs.car.editModal.content.mainTab.str0",
								) ?? ""
							}
						>
							<MainData
								ref={setMainDataRef}
								value={mainDataValue}
								disabled={disabled}
								onChange={assignValue}
							/>
						</FieldsContainer>

						<MultilineTextBox
							value={valueEditor.get("notes")}
							disabled={disabled}
							placeholder={
								t(
									"pages.mainPage.pages.accounts.tabs.car.editModal.content.mainTab.str2",
								) ?? ""
							}
							onChange={notesOnChange}
						/>
					</Column>
				</TabRoot>
			);
		},
		InternalController,
	),
);

declare namespace MainTab {
	type Ref = InternalController | null;

	type Controller = InternalController;

	interface Value extends MainData.Value, WorkData.Value {
		notes: string;
	}

	interface PropsBase {
		value: Value;

		disabled: boolean;
		visible: boolean;
		language: Language;
		originalTaxiServiceId?: number;
		originalParkNumber?: string;
		allowedTaxiServiceId?: number;
		currentId?: number;

		onChange: Dispatch<Value>;
	}

	type Props = PropsBase & RefAttributes<Ref>;
}

export default MainTab;
