import React, {
	Dispatch,
	RefAttributes,
	memo,
	useCallback,
	useMemo,
	useState,
} from "react";
import { useTranslation } from "react-i18next";
import { Button, Icon, InputGroup, Row, TextBox, react, theme } from "uikit";
import { clamp, clone } from "lodash";
import LabeledField from "../../../../../../../../../../../../../../../../components/LabeledField";
import useObjectEditor from "../../../../../../../../../../../../../../../../hooks/useObjectEditor";
import { countryCodePhone } from "../../../../../../../../../../../../../../../../utils/validatePhone";
import InternalController from "./Controller";

const PhonesBase = react.withController<Phones.PropsBase, Phones.Controller>(
	({
		controller,
		value,
		disabled,
		min = 1,
		max = Number.MAX_SAFE_INTEGER,
		onChange,
	}) => {
		const { t } = useTranslation();
		const internalValue = useMemo(
			() =>
				value.length < min
					? clone(value).concat(Array(min - value.length).fill(""))
					: clone(value).slice(0, max),
			[max, min, value],
		);

		const [error, setError] = useState(false);

		const valueEditor = useObjectEditor(internalValue, onChange);

		const addPhone = useCallback(() => {
			valueEditor.push("");
		}, [valueEditor]);

		const renderField = useCallback(
			(index: number) => {
				const isFirstItem = index === 0;
				const isLastItem = index === valueEditor.value.length - 1;
				const isMaxedItem = index === (max ?? 0) - 1;
				const hasMoreThanOneItem = valueEditor.value.length > 1;

				const label = isFirstItem
					? t(
							"pages.mainPage.pages.accounts.tabs.executors.editModal.content.mainTab.personData.phones.str200",
					  ) ?? ""
					: t(
							"pages.mainPage.pages.accounts.tabs.executors.editModal.content.mainTab.personData.phones.str201",
					  ) ?? "";

				let sizes = "";

				if (isFirstItem && !hasMoreThanOneItem) sizes = "1fr 32px";
				else if (!isLastItem || (isLastItem && isMaxedItem))
					sizes = "1fr";
				else if (isLastItem) sizes = "1ft 32px";

				return (
					<Row
						key={index}
						gaps="8px*"
						sizes={sizes}
						align="end"
						justify="stretch"
					>
						<LabeledField label={label}>
							{!isFirstItem ? (
								<InputGroup.InputGroup sizes="1fr 32px!">
									<TextBox.TextBox
										value={valueEditor.get(index) ?? ""}
										disabled={disabled}
										autoComplete="one-time-code"
										type="phone"
										style={{
											minHeight: "32px",
											flex: "1 0 0",
										}}
										placeholder={label}
										onChange={(newPhone) =>
											valueEditor.set(
												index,
												countryCodePhone(
													newPhone,
													"uk",
												),
											)
										}
									/>
									<Button.Button
										disabled={disabled}
										icon={
											<Icon
												id="minus"
												size={16}
												colors={[theme.colors.white]}
											/>
										}
										style={{
											borderTopLeftRadius: 0,
											borderBottomLeftRadius: 0,
										}}
										onClick={() =>
											valueEditor.remove(index)
										}
									/>
								</InputGroup.InputGroup>
							) : (
								<TextBox.TextBox
									value={valueEditor.get(index) ?? ""}
									error={error}
									disabled={disabled}
									autoComplete="one-time-code"
									type="phone"
									style={{
										minHeight: "32px",
										flex: "1 0 0",
									}}
									placeholder={label}
									onChange={(newPhone) => {
										setError(false);

										valueEditor.set(
											index,
											countryCodePhone(newPhone, "uk"),
										);
									}}
								/>
							)}
						</LabeledField>
						{isLastItem && !isMaxedItem && (
							<Button.Button
								disabled={disabled}
								icon={
									<Icon
										id="plus"
										size={16}
										colors={[theme.colors.white]}
									/>
								}
								onClick={addPhone}
							/>
						)}
					</Row>
				);
			},
			[valueEditor, max, disabled, error, addPhone, t],
		);

		controller.setContext({
			value,

			setError,
		});

		return (
			<>
				{Array(clamp(valueEditor.value.length, min, max))
					.fill(null)
					.map((_, index) => renderField(index))}
			</>
		);
	},
	InternalController,
);

const Phones = memo(PhonesBase);

declare namespace Phones {
	type Ref = InternalController;

	type Controller = InternalController;

	type Value = string[];

	interface PropsBase {
		value: Value;

		disabled: boolean;
		min?: number;
		max?: number;

		onChange: Dispatch<Value>;
	}

	type Props = PropsBase & RefAttributes<Ref>;
}

export default Phones;
