import React, { Dispatch, RefAttributes, useMemo } from "react";
import { react, useRefWithSetter } from "uikit";

import Client from "../../../../../../../../../../services/Client";
import useObjectEditor from "../../../../../../../../../../hooks/useObjectEditor";

import EditModal from "../..";

import InternalController from "./Controller";

import Root from "./components/Root";
import MainTab from "./components/tabs/MainTab";
import OptionsTab from "./components/tabs/OptionsTab";
import AddressesTab from "./components/tabs/AddressesTab";
import OrderTab from "./components/tabs/OrderTab";
import HistoryTab from "./components/tabs/HistoryTab";
import FilesTab from "./components/tabs/FilesTab";
import Transactions from "./components/tabs/Transactions";

declare namespace Content {
	type Ref = InternalController | null;
	type Controller = InternalController;

	type Value = Omit<
		MainTab.Value &
			OptionsTab.Value &
			AddressesTab.Value &
			OrderTab.Value &
			FilesTab.Value,
		"id"
	> & { id?: number };

	interface PropsBase {
		clientId?: number;
		groups: MainTab.Props["groups"];
		value: Value;
		onChange: Dispatch<Value>;

		type: EditModal.Tab;
		history: Client.History;
	}

	type Props = PropsBase & RefAttributes<Ref>;
}

const Content = react.withController<Content.PropsBase, Content.Controller>(
	({ controller, clientId, groups, value, type, history, onChange }) => {
		const [mainTabRef, setMainTabRef] =
			useRefWithSetter<MainTab.Ref | null>(null);
		const [optionsTabRef, setOptionsTabRef] =
			useRefWithSetter<OptionsTab.Ref | null>(null);

		controller.setContext({ mainTabRef, optionsTabRef });

		const valueEditor = useObjectEditor(value, onChange);

		const paymentAccounts = valueEditor.useGetter("paymentAccounts");

		const mainPicker = valueEditor.usePicker([
			"customerGroupId",
			"companyId",
			"defaultTaxiServiceId",
			"latestTaxiServiceId",
			"mainRateId",
			"additionalRateId",

			"paymentAccounts",
			"paymentTransactions",

			"status",

			"firstName",
			"lastName",
			"fatherName",
			"email",
			"phones",

			"gender",
			"birthday",

			"carClassId",

			"notes",
			"orderNotes",
			"executorNotes",
		]);

		const optionsPicker = valueEditor.usePicker([
			"smsNotifications",
			"isAllowedApp",
			"isAllowedCabinet",
			"login",
			"hasPassword",
			"password",
			"id",
		]);

		const addressesPicker = valueEditor.usePicker([
			"additionalFields",
			"id",
		]);

		const orderPicker = valueEditor.usePicker(["companyId", "id"]);
		const filesPicker = valueEditor.usePicker([
			"personalFiles",
			"otherFiles",
		]);

		const assigner = valueEditor.useAssigner();

		const content = useMemo(
			() => (
				<>
					<MainTab
						ref={setMainTabRef}
						visible={type === "main"}
						groups={groups}
						clientId={clientId}
						value={mainPicker}
						onChange={assigner}
					/>
					<OptionsTab
						ref={setOptionsTabRef}
						visible={type === "options"}
						value={optionsPicker}
						onChange={assigner}
					/>
					<AddressesTab
						visible={type === "addresses"}
						value={addressesPicker}
						onChange={assigner}
					/>
					<OrderTab
						visible={type === "orders"}
						value={orderPicker}
						// onChange={anyTabOnChange}
					/>
					<Transactions
						tableFrom="customers.clients.transactions"
						visible={type === "transactions"}
						paymentAccounts={paymentAccounts}
					/>
					<FilesTab
						visible={type === "files"}
						value={filesPicker}
						onChange={assigner}
					/>
					<HistoryTab visible={type === "history"} value={history} />
					{/* <RatingTab
								visible={type === "rating"}
								value={valueEditor.value}
								onChange={anyTabOnChange}
							/> */}
					{/* <ExecutorsTab
						visible={type === "files"}
						value={valueEditor.value}
						onChange={anyTabOnChange}
					/> */}
					<HistoryTab visible={type === "rating"} value={history} />
					<HistoryTab
						visible={type === "executors"}
						value={history}
					/>
				</>
			),
			[
				setMainTabRef,
				type,
				groups,
				clientId,
				mainPicker,
				assigner,
				setOptionsTabRef,
				optionsPicker,
				addressesPicker,
				orderPicker,
				paymentAccounts,
				filesPicker,
				history,
			],
		);

		return <Root>{content}</Root>;
	},
	InternalController,
);

export default Content;
