import { useTranslation } from "react-i18next";
import { useCallback } from "react";

import { Entry } from "../../../../../../../../../../../../../components/HistoryTable/hooks/useFieldLocalizer";
import Executor from "../../../../../../../../../../../../../services/Executor";

export default function useChangeFormatter() {
	const { t } = useTranslation();

	return useCallback(
		(entry: Executor.History.Entry): Entry | null => {
			switch (entry.field) {
				case "executorPriority":
				case "balance":
				case "driverLicense.generalDrivingExperience":
					return {
						field: entry.field,
						type: "string",
						previous: entry.previous?.toString(),
						actual: entry.previous?.toString(),
					};

				case "login":
				case "callSign":
				case "notes":
				case "name":
				case "surname":
				case "fatherName":
				case "country":
				case "city":
				case "address":
				case "passport.number":
				case "passport.series":
				case "passport.issuedBy":
				case "passport.birthPlace":
				case "passport.residencePlace":
				case "bankCard.number":
				case "bankCard.notes":
				case "driverLicense.number":
				case "driverLicense.series":
				case "driverLicense.category":
				case "driverLicense.notes":
				case "license.notes":
				case "individualProprietor.number":
				case "individualProprietor.series":
				case "individualProprietor.identificationNumber":
				case "individualProprietor.iban":
				case "individualProprietor.notes":
				case "taxiService":
				case "schedule":
				case "responsibleDispatcher":
					return {
						field: entry.field,
						type: "string",
						previous: entry.previous,
						actual: entry.actual,
					};

				case "status":
					return {
						field: entry.field,
						type: "string",
						actual: t(`statuses.${entry.actual}`) || entry.actual,
						previous:
							t(`statuses.${entry.previous}`) || entry.previous,
					};

				case "password":
					return {
						field: entry.field,
						type: "string",
					};

				case "active":
				case "workingStatus":
				case "passwordAutoReset":
				case "executorPriorityStatus":
					return {
						field: entry.field,
						type: "toggle",
						actual: entry.actual,
						previous: entry.previous,
					};

				case "birthday":
				case "passport.issuedAt":
				case "bankCard.issuedAt":
				case "driverLicense.issuedAt":
				case "driverLicense.dateRange.from":
				case "driverLicense.dateRange.to":
				case "individualProprietor.issuedAt":
					return {
						field: entry.field,
						type: "date",
						actual: entry.actual,
						previous: entry.previous,
					};

				case "emails": {
					return {
						field: entry.field,
						type: "array",
						actual: entry.actual?.map((v) => String(v.value)),
						previous: entry.previous?.map((v) => String(v.value)),
					};
				}

				case "phones":
					return {
						field: entry.field,
						type: "array",
						actual: entry.actual?.map((v) => String(v.number)),
						previous: entry.previous?.map((v) => String(v.number)),
					};

				case "services":
				case "roles":
					return {
						field: entry.field,
						type: "array",
						actual: entry.actual?.map((v) => v),
						previous: entry.previous?.map((v) => v),
					};

				case "personalFiles":
				case "passportFiles":
				case "driverLicenseFiles":
				case "licenseFiles":
				case "individualProprietorFiles":
				case "otherFiles":
					return {
						field: entry.field,
						type: "array",
						actual: entry.actual?.map((v) => v.name),
						previous: entry.previous?.map((v) => v.name),
					};

				default:
					return null;
			}
		},
		[t],
	);
}
