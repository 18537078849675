import React, {
	Dispatch,
	RefAttributes,
	useCallback,
	useMemo,
	useState,
} from "react";
import { useTranslation } from "react-i18next";
import { Option, Select, react } from "uikit";

import Dispatcher from "../../../../../../../../../../../../../../../../services/Dispatcher";

import useModelSubscribe from "../../../../../../../../../../../../../../../../hooks/useModelSubscribe";
import InternalController from "./Controller";
import mapByKey from "../../../../../../../../../../../../../../../../utils/mapByKey";
import LabeledField from "../../../../../../../../../../../../../../../../components/LabeledField";

const ResponsibleDispatcherId = react.withController<
	ResponsibleDispatcherId.PropsBase,
	ResponsibleDispatcherId.Controller
>(
	({
		controller,

		value,

		disabled,
		taxiServiceId,

		onChange,
	}) => {
		const { t } = useTranslation();
		const taxiServiceIds = useMemo(
			() => (typeof taxiServiceId === "number" ? [taxiServiceId] : []),
			[taxiServiceId],
		);

		const modelData = useModelSubscribe(
			{
				taxiServiceIds,
			},
			Dispatcher,
		);

		const items = useMemo(() => modelData?.cache ?? [], [modelData?.cache]);

		const [error, setError] = useState(false);

		const selectOptions = useMemo<Option<number>[]>(
			() =>
				items.map((item) => ({
					key: item.id,
					label: [
						item.person?.lastName ?? "",
						item.person?.firstName ?? "",
						item.person?.fatherName ?? "",
					]
						.join(" ")
						.trim(),
					value: item.id,
				})),
			[items],
		);

		const selectOptionByKey = useMemo(
			() => mapByKey(selectOptions, "key"),
			[selectOptions],
		);

		const selectOnChange = useCallback(
			(key) => {
				setError(false);

				onChange(selectOptionByKey[key].value);
			},
			[onChange, selectOptionByKey],
		);

		controller.setContext({ value, setError });

		return (
			<LabeledField
				label={
					t(
						"pages.mainPage.pages.accounts.tabs.executors.editModal.content.mainTab.workData.responsibleDispatcherId.str0",
					) ?? ""
				}
			>
				<Select
					value={value}
					disabled={disabled}
					error={error}
					placeholder={
						t(
							"pages.mainPage.pages.accounts.tabs.executors.editModal.content.mainTab.workData.responsibleDispatcherId.str0",
						) ?? ""
					}
					options={selectOptions}
					onChange={selectOnChange}
				/>
			</LabeledField>
		);
	},
	InternalController,
);

declare namespace ResponsibleDispatcherId {
	type Ref = InternalController | null;

	type Controller = InternalController;

	type Value = number | undefined;
	type NewValue = number;

	interface PropsBase {
		value: Value;

		disabled: boolean;
		taxiServiceId?: number;

		onChange: Dispatch<NewValue>;
	}

	type Props = PropsBase & RefAttributes<Ref>;
}

export default ResponsibleDispatcherId;
