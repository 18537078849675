import styled from "styled-components";
import { Row, theme } from "uikit";

const Root = styled(Row)`
	height: 32px;

	padding-inline: 6px 8px;

	background: ${theme.colors.white};

	cursor: grab;
	border-bottom: 1px solid #dee0e2;

	overflow: hidden;
`;

export default Root;
