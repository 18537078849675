/* eslint-disable no-param-reassign */

function mod(value: number, from: number, to: number) {
	const delta = to - from;

	value -= from;

	while (value < 0) value += delta;
	while (value > delta) value -= delta;

	return value + from;
}

export default mod;
