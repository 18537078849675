import React, { Dispatch, memo, useCallback } from "react";
import { Row } from "uikit";
import { useTranslation } from "react-i18next";

import SearchBar from "../../../../../../../../../../components/SearchBar";
import getOptions from "../../../../../../../../../../utils/generateOptions";
import Client from "../../../../../../../../../../services/Client";

import PopupFilter from "./components/PopupFilter";

const options = getOptions(Object.values(Client.Status));

const Filters = memo<Filters.Props>(({ value, onChange }) => {
	const { t } = useTranslation();

	const searchTextBoxOnChange = useCallback(
		(search: string) => {
			onChange?.({
				...value,

				search,
			});
		},
		[onChange, value],
	);

	// const statusesOnChange = useCallback(
	// 	(statuses: Key[]) => {
	// 		onChange?.({
	// 			...value,

	// 			statuses: statuses as Filters.Value["statuses"],
	// 		});
	// 	},
	// 	[onChange, value],
	// );

	// const localizedStatuses = useMemo(
	// 	() =>
	// 		options.map((status) => ({
	// 			...status,
	// 			label: t(`statuses.${status.label}`),
	// 		})),
	// 	[t],
	// );

	return (
		<Row sizes="270px! auto" gaps="12px 10px*" align="center">
			{/* <MultiSelect
				all
				options={localizedStatuses}
				value={value.statuses}
				onChange={statusesOnChange}
			/> */}

			<SearchBar
				value={value.search}
				onChange={searchTextBoxOnChange}
				placeholder={`${t("enterSearchQuery")}...`}
			/>

			<PopupFilter disabled={true} />
		</Row>
	);
});

declare namespace Filters {
	interface Value {
		statuses: Client.Status[];
		search: string;

		filter: PopupFilter.Value;
	}

	interface Props {
		value: Value;

		onChange: Dispatch<Value>;
	}
}

export default Filters;
