/* eslint-disable no-param-reassign */
/* eslint-disable no-case-declarations */
import React, { Dispatch, useMemo } from "react";
import { CheckBox, Row } from "uikit";
import { useTranslation } from "react-i18next";

import Client from "../../../../../../../../../../../../../../services/Client";
import useModelSubscribe from "../../../../../../../../../../../../../../hooks/useModelSubscribe";
import useModelSubscribe2 from "../../../../../../../../../../../../../../hooks/useModelSubscribe2";
import Company from "../../../../../../../../../../../../../../services/Company";
import TaxiService from "../../../../../../../../../../../../../../services/TaxiService";
import makeLookupTable from "../../../../../../../../../../../../../../utils/makeLookupTable";
import { useTypedSelector } from "../../../../../../../../../../../../../../redux/store";
import CarClass from "../../../../../../../../../../../../../../services/CarClass";
import useDateTimeLocalizer from "../../../../../../../../../../../../../../hooks/useDateLocalizer";
import formatNumber from "../../../../../../../../../../../../../../utils/formatNumber";

import Filters from "./components/Filters";
import Root from "./components/Root";
import ModelTable from "./components/ModelTable";
import {
	UseTableOptions,
	useTableOptions,
} from "../../../../../../../../../../../../../../components/LightTable";

const Table: React.FC<Table.Props> = ({
	defaultColumns,
	localStorageKey,
	value,
	selected,
	setSelected,
	onChangeTable,
	editorTable,
	showFilter,
}) => {
	const { setQuery, query } = useTableOptions({
		value: editorTable,
		setValue: onChangeTable,
	});

	const { t } = useTranslation();
	const language = useTypedSelector((state) => state.session.language);
	const localizeDate = useDateTimeLocalizer();

	const companies = useModelSubscribe({}, Company)?.cache;
	const taxiServices = useModelSubscribe({}, TaxiService)?.cache;

	const companyLookup = useMemo(
		() =>
			companies
				? makeLookupTable(companies, (c) => c.id, true)
				: ({} as Record<number, undefined>),
		[companies],
	);
	const taxiServiceLookup = useMemo(
		() =>
			taxiServices
				? makeLookupTable(taxiServices, (ts) => ts.id, true)
				: ({} as Record<number, undefined>),
		[taxiServices],
	);

	const { models: carClasses } = useModelSubscribe2({}, CarClass);
	const carClassLookup = useMemo(
		() => makeLookupTable(carClasses, (c) => c.id, true),
		[carClasses],
	);

	const tableData = useMemo<ModelTable.TableData>(
		() =>
			value.map((client) => {
				const mainBalance = client.paymentAccounts.find(
					(a) => a.type === "main",
				);
				const bonusBalance = client.paymentAccounts.find(
					(a) => a.type === "bonus",
				);

				const company =
					companyLookup[client.companyId]?.value.name[language] ||
					"-";

				const defaultTaxiService =
					(client.defaultTaxiServiceId &&
						taxiServiceLookup[client.defaultTaxiServiceId]?.value
							.settlement[language]) ||
					"-";

				const latestTaxiService =
					(client.latestTaxiServiceId &&
						taxiServiceLookup[client.latestTaxiServiceId]?.value
							.settlement[language]) ||
					"-";

				const defaultCarClass =
					(client.carClassId != null &&
						carClassLookup[client.carClassId]?.value.name[
							language
						]) ||
					"-";

				const createdAt = localizeDate(
					new Date(client.createdAt),
					"dateTime",
				);
				const updatedAt = localizeDate(
					new Date(client.updatedAt),
					"dateTime",
				);
				const deletedAt = client.deletedAt
					? localizeDate(new Date(client.deletedAt), "dateTime")
					: "";

				return {
					_isActive: client.status.level === "active",
					id: client.id,

					rideCount: client.rideCount,
					balance: formatNumber(mainBalance?.amount ?? 0),
					bonusBalance: formatNumber(bonusBalance?.amount ?? 0),
					bonusRides: "todo",

					totalOrderCount: "todo",
					completedOrderCount: "todo",
					orderEstimationCount: "todo",
					canceledOrderCount: "todo",
					canceledOrderCountNoCarFound: "todo",

					firstName: client?.person?.firstName,
					lastName: client?.person?.lastName || "",
					fatherName: client?.person?.fatherName || "",
					birthday: client?.person?.birthday
						? localizeDate(new Date(client.person.birthday), "date")
						: "-",
					gender:
						t(`genders.${client.gender || "unknown"}`) || "Unknown",
					app: (
						<div
							style={{
								display: "flex",
								justifyContent: "center",
								pointerEvents: "none",
							}}
						>
							<CheckBox disabled value={client.isApp} />
						</div>
					),
					email:
						client?.person?.emails
							?.map((email) => email.value)
							.join(", ") || "-",
					phones:
						client?.person?.phones
							?.map((n) => n.number)
							.join(", ") || "",

					clientGroup: "todo",
					mainLoyaltyProgram: "todo",
					additionalLoyaltyProgram: "todo",

					bonusCard: "todo",
					tariff: "todo",
					defaultCarClass,

					status: t(`statuses.${client.status.level}`) || "",
					company,
					defaultTaxiService,
					latestTaxiService,

					notes: client.notes,

					group: client.customerGroup?.name,

					createdAt,
					updatedAt,
					deletedAt,
				};
			}),
		[
			carClassLookup,
			companyLookup,
			language,
			localizeDate,
			t,
			taxiServiceLookup,
			value,
		],
	);

	return (
		<Root sizes="1fr">
			{showFilter && (
				<Row
					align="center"
					sizes="270px"
					justify="flex-end"
					style={{ padding: 10 }}
				>
					<Filters value={query} onChange={setQuery} />
				</Row>
			)}
			<ModelTable
				defaultColumns={defaultColumns}
				localStorageKey={localStorageKey}
				data={tableData}
				loading={false}
				selected={selected}
				setSelected={setSelected}
				editorTable={editorTable}
				onChangeTable={onChangeTable}
			/>
		</Root>
	);
};

declare namespace Table {
	interface Props {
		value: Client.Model[];
		localStorageKey?: string;
		defaultColumns?: ModelTable.Props["defaultColumns"];
		selected: number[];
		setSelected: Dispatch<number[]>;
		editorTable: UseTableOptions["editor"];
		onChangeTable: UseTableOptions["onChange"];
		showFilter?: boolean;
	}
}

export default Table;
