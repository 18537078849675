/* eslint-disable no-shadow */

import React, {
	Dispatch,
	PropsWithChildren,
	RefAttributes,
	memo,
	useMemo,
} from "react";
import { Row, react, useRefWithSetter } from "uikit";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { useTypedSelector } from "../../../../../../../../../../../../../../../../../redux/store";
import Client from "../../../../../../../../../../../../../../../../../services/Client";
import TaxiService from "../../../../../../../../../../../../../../../../../services/TaxiService";
import useObjectEditor from "../../../../../../../../../../../../../../../../../hooks/useObjectEditor";
import useModelSubscribe from "../../../../../../../../../../../../../../../../../hooks/useModelSubscribe";
import RequiredKeys from "../../../../../../../../../../../../../../../../../types/RequiredKeys";
import LabeledField from "../../../../../../../../../../../../../../../../../components/LabeledField";

import InternalController from "./Controller";
import TaxiServiceSelect from "./components/TaxiServiceSelect";
import CompanySelect from "./components/CompanySelect";

const StyledPadding = styled.div`
	padding-inline: 12px;
	padding-block: 6px;
`;

declare namespace CompanyAndTaxiService {
	type Ref = InternalController;

	type Controller = InternalController;

	type Value = RequiredKeys<
		Pick<
			Client.Model,
			"carClassId" | "customerGroupId" | "latestTaxiServiceId"
		>
	> &
		CompanySelect.Value &
		TaxiServiceSelect.Value;

	interface PropsBase extends PropsWithChildren {
		isCompanyEditable: boolean;

		value: Value;
		onChange: Dispatch<Value>;
	}

	type Props = PropsBase & RefAttributes<Ref>;
}

const CompanyAndTaxiServiceBase = react.withController<
	CompanyAndTaxiService.PropsBase,
	CompanyAndTaxiService.Controller
>(({ controller, isCompanyEditable: disabled, value, onChange, children }) => {
	const { t } = useTranslation();
	const language = useTypedSelector((state) => state.session.language);

	const [companySelectRef, setCompanySelectRef] =
		useRefWithSetter<CompanySelect.Ref | null>(null);
	const [taxiServiceSelectRef, setTaxiServiceSelectRef] =
		useRefWithSetter<TaxiServiceSelect.Ref | null>(null);

	controller.setContext({
		companySelectRef,
		taxiServiceSelectRef,
	});

	const valueEditor = useObjectEditor(value, onChange);
	const picker = valueEditor.usePicker([
		"companyId",
		"defaultTaxiServiceId",
		"carClassId",
	]);
	const assigner = valueEditor.useAssigner();

	const taxiServices = useModelSubscribe(
		useMemo(
			() => ({
				companyIds: picker.companyId ? [picker.companyId] : undefined,
			}),
			[picker.companyId],
		),
		TaxiService,
	)?.cache;

	const latestTaxiServiceId = valueEditor.useGetter("latestTaxiServiceId");
	const latestTaxiService = useMemo(
		() =>
			taxiServices?.find((c) => c.id === latestTaxiServiceId)?.settlement[
				language
			],
		[language, latestTaxiServiceId, taxiServices],
	);

	return (
		<Row gaps="8px*" sizes="275.5px*" align="start">
			<CompanySelect
				ref={setCompanySelectRef}
				disabled={disabled}
				value={picker}
				onChange={assigner}
			/>

			<TaxiServiceSelect
				ref={setTaxiServiceSelectRef}
				disabled={disabled}
				value={picker}
				onChange={assigner}
			/>

			<LabeledField
				label={
					t(`mainPage.customers.clients.main.other.lastRideCity`) ||
					"Last ride city"
				}
			>
				<StyledPadding>
					{latestTaxiService ||
						t(`mainPage.customers.clients.main.other.no_data`) ||
						"No data"}
				</StyledPadding>
			</LabeledField>
			{children}
		</Row>
	);
}, InternalController);

const CompanyAndTaxiService = memo(CompanyAndTaxiServiceBase);

export default CompanyAndTaxiService;
