/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable no-shadow */

import React, { Dispatch, RefAttributes, memo } from "react";
import { useTranslation } from "react-i18next";
import { Column, MultilineTextBox, react, useRefWithSetter } from "uikit";
import Language from "../../../../../../../../../../../../services/Language";
import useObjectEditor from "../../../../../../../../../../../../hooks/useObjectEditor";
import FieldsContainer from "../../../../../../../../../../../../components/FieldsContainer";

import TabRoot from "../TabRoot";
import WorkData from "./components/WorkData";
import InternalController from "./Controller";
import PersonData from "./components/PersonData";

const MainTab = memo(
	react.withController<MainTab.PropsBase, MainTab.Controller>(
		({ controller, value, disabled, visible, language, onChange }) => {
			const { t } = useTranslation();
			const [personDataRef, setPersonDataRef] =
				useRefWithSetter<PersonData.Ref | null>(null);
			const [workDataRef, setWorkDataRef] =
				useRefWithSetter<WorkData.Ref | null>(null);

			controller.setContext({ personDataRef, workDataRef });

			const valueEditor = useObjectEditor(value, onChange);

			const personDataValue = valueEditor.usePicker([
				"firstName",
				"lastName",
				"fatherName",
				"login",
				"password",
				"phones",
				"email",
			]);

			const workDataValue = valueEditor.usePicker([
				"roleIds",
				"carParkIds",
				"softPhoneSIPIds",
				"hardPhoneSIPIds",
				"companyIds",
				"taxiServiceIds",
				"defaultTaxiServiceId",
				"alias",
				"status",
			]);

			const assignValue = valueEditor.useAssigner();

			const notesOnChange = valueEditor.useSetter("notes");

			return (
				<TabRoot hasPaddings visible={visible}>
					<Column
						sizes="auto!*2 1fr"
						gaps="19px*"
						maxedWidth
						maxedHeight
					>
						<FieldsContainer
							label={
								t(
									"pages.mainPage.pages.accounts.tabs.users.editModal.content.mainTab.str0",
								) ?? ""
							}
						>
							<PersonData
								ref={setPersonDataRef}
								value={personDataValue}
								disabled={disabled}
								onChange={assignValue}
							/>
						</FieldsContainer>
						<FieldsContainer
							label={
								t(
									"pages.mainPage.pages.accounts.tabs.users.editModal.content.mainTab.str1",
								) ?? ""
							}
						>
							<WorkData
								ref={setWorkDataRef}
								value={workDataValue}
								disabled={disabled}
								language={language}
								onChange={assignValue}
							/>
						</FieldsContainer>
						<MultilineTextBox
							value={valueEditor.get("notes")}
							disabled={disabled}
							placeholder={
								t(
									"pages.mainPage.pages.accounts.tabs.users.editModal.content.mainTab.str2",
								) ?? ""
							}
							onChange={notesOnChange}
						/>
					</Column>
				</TabRoot>
			);
		},
		InternalController,
	),
);

declare namespace MainTab {
	type Ref = InternalController | null;

	type Controller = InternalController;

	interface Value extends PersonData.Value, WorkData.Value {
		notes: string;
	}

	interface PropsBase {
		value: Value;

		disabled: boolean;
		visible: boolean;
		language: Language;

		onChange: Dispatch<Value>;
	}

	type Props = PropsBase & RefAttributes<Ref>;
}

export default MainTab;
