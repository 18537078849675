import React, {
	RefAttributes,
	memo,
	useCallback,
	useEffect,
	useMemo,
	useState,
} from "react";
import { MultiSelect, react } from "uikit";
import { useTranslation } from "react-i18next";
import InternalController from "./Controller";
import {
	useTypedDispatch,
	useTypedSelector,
} from "../../../../../../../../../../../../../../redux/store";
import LabeledField from "../../../../../../../../../../../../../../components/LabeledField";
import getCompanies from "../../../../../../../../../../../../../../redux/services/Company/getCompanies";
import tPath from "../../../../../../../../constants/tPath";
import { ModelId } from "../../../../../../../../../../../../../../types/ModelId";

const CompaniesBase = react.withController<
	Companies.PropsBase,
	Companies.Controller
>(({ companyIds, setCompanyIds, controller }) => {
	const [error, setError] = useState(false);
	controller.setContext({ value: companyIds, setError });

	const { t } = useTranslation();
	const lang = useTypedSelector((state) => state.session.language);

	const handleCompanyIdsUpdate = useCallback(
		(newIds) => {
			setError(false);
			setCompanyIds(newIds);
		},
		[setCompanyIds],
	);

	const dispatch = useTypedDispatch();
	useEffect(() => {
		dispatch(getCompanies());
	}, [dispatch]);
	const { companies } = useTypedSelector((state) => state.ordersPageReducer);

	const selectOptionsWrap = useCallback(
		(item: { id: number; label: string }) => ({
			key: item.id,
			value: item.id,
			label: item.label,
		}),
		[],
	);
	const companiesOptions = useMemo(
		() =>
			companies.items.map((company) =>
				selectOptionsWrap({
					id: company.id,
					label: company.name[lang],
				}),
			),
		[companies.items, lang, selectOptionsWrap],
	);

	return (
		<LabeledField label={t(`${tPath}.modal.tabs.main.company.title`) || ""}>
			<MultiSelect
				error={
					error &&
					(t(`${tPath}.modal.tabs.main.company.error`) as string)
				}
				all
				placeholder={t(`${tPath}.modal.tabs.main.company.choose`) || ""}
				value={companyIds}
				options={companiesOptions}
				onChange={handleCompanyIdsUpdate}
			/>
		</LabeledField>
	);
}, InternalController);

const Companies = memo(CompaniesBase);

declare namespace Companies {
	type Ref = InternalController | null;
	type Controller = InternalController;
	interface PropsBase {
		companyIds: Value;
		setCompanyIds: (x: Value) => void;
	}
	type Props = PropsBase & RefAttributes<Ref>;

	type Value = ModelId[];
}

export default Companies;
