import React, {
	Dispatch,
	SetStateAction,
	useCallback,
	useLayoutEffect,
	useMemo,
	useState,
} from "react";
import { RowDataType } from "rsuite-table";
import { useTranslation } from "react-i18next";

import Counterparty from "../../../../../../../../../../../../../../../../../../../../services/Counterparty";
import LightTable, {
	useTableOptions,
} from "../../../../../../../../../../../../../../../../../../../../components/LightTable";
import TableSettings from "../../../../../../../../../../../../../../../../../../../../components/TableSettings";
import { PaymentAccount } from "../../../../../../../../../../../../../../../../../../../../types/PaymentAccount";
import formatNumber from "../../../../../../../../../../../../../../../../../../../../utils/formatNumber";
import {
	StyledGrid,
	StyledGridItem,
} from "../../../../../../../../../../../../../../../../../../../../components/common";
import tPath from "../../../../../../../../../../../../../../constants/tPath";

const Table: React.FC<Table.Props> = ({ tableData, selected, setSelected }) => {
	const { t } = useTranslation();

	const columns: Table.Column[] = useMemo(
		() => [
			{
				id: "type",
				label: t([`${tPath}.modal.tabs.main.other.typeBalance`]),
			},
			{
				id: "amount",
				label: t([`${tPath}.modal.tabs.main.other.amountBalance`]),
			},
		],
		[t],
	);

	const defaultColumnIds = columns.map((c) => c.id);

	const { sort, onSort, onScroll, tableRef, setDataLength } = useTableOptions(
		{
			itemLimit: 2,
		},
	);

	useLayoutEffect(() => {
		setDataLength(tableData.length);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [tableData.length]);

	const [columnIds, setColumnIds] = useState(defaultColumnIds);

	const updateSelected = useCallback(
		(item: RowDataType<Counterparty.Model>) => {
			setSelected((prev) => (prev === item.id ? null : item.id));
		},
		[setSelected],
	);

	const data = useMemo(
		() =>
			columnIds.map((columnId) => {
				const column = columns.find((col) => col.id === columnId);
				if (!column) return null;

				return (
					<LightTable.Column
						resizable
						sortable
						key={columnId}
						verticalAlign="middle"
						flexGrow={1}
					>
						<LightTable.HeaderCell style={{ padding: "0 20px" }}>
							{column.label}
						</LightTable.HeaderCell>
						<LightTable.Cell
							verticalAlign="middle"
							style={{ padding: "0 20px" }}
							dataKey={column.id}
							fullText
						>
							{(rowData: RowDataType<PaymentAccount>) => {
								const model = rowData as PaymentAccount;

								switch (columnId) {
									case "type":
										return t(`balances.${model.type}`);

									case "amount":
										return formatNumber(model.amount ?? 0);

									default:
										return null;
								}
							}}
						</LightTable.Cell>
					</LightTable.Column>
				);
			}),
		[columnIds, columns, t],
	);

	const getRowClassName = useCallback(
		(item: RowDataType<Counterparty.Model>) =>
			item && selected === item.id ? "selected" : "",
		[selected],
	);

	const areas = useMemo(() => `'table' 'settings' `, []);
	return (
		<StyledGrid
			areas={areas}
			rows="1fr 32px"
			w={{ min: "1000px" }}
			h={{
				min: "300px",
				max: "400px",
			}}
			br="5px"
			over="hidden"
		>
			<StyledGridItem area="table" w="100%" h="100%" tabIndex={-1}>
				<LightTable
					ref={tableRef}
					data={tableData}
					virtualized
					fillHeight
					rowHeight={44}
					shouldUpdateScroll={false}
					rowClassName={getRowClassName}
					onRowClick={updateSelected}
					sortColumn={sort?.dataKey}
					sortType={sort?.sortType}
					onScroll={onScroll}
					onSortColumn={onSort}
				>
					{data}
				</LightTable>
			</StyledGridItem>
			<StyledGridItem area="settings" w="100%" h="100%" zi="1">
				<TableSettings
					value={columnIds}
					defaultValue={columnIds}
					columns={columns}
					onChange={setColumnIds}
				/>
			</StyledGridItem>
		</StyledGrid>
	);
};

declare namespace Table {
	interface Props {
		tableData: PaymentAccount[];
		selected: number | null;
		setSelected: Dispatch<SetStateAction<number | null>>;
	}
	interface Column {
		id: string;
		label: string;
	}
}

export default Table;
