/* eslint-disable no-shadow */

import React, { Dispatch, memo, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Icon, InputGroup, Option, Row, Select, theme } from "uikit";

import useObjectEditor from "../../../../../../hooks/useObjectEditor";
import Language from "../../../../../../services/Language";
import CompanyAndTaxiServiceSelect from "../../../../../CompanyAndTaxiServiceSelect";
import SearchIconBorders from "../../../../../SearchIconBorders";
import SearchTextBox from "../../../../../SearchTextBox";

const Filters = memo<Filters.Props>(
	({ value, allowedTaxiServiceIds, onChange }) => {
		const { t } = useTranslation();

		const valueEditor = useObjectEditor(value, onChange);

		const CTSSValue = valueEditor.usePicker([
			"companyIds",
			"taxiServiceIds",
		]);

		const CTSSOnChange = valueEditor.useAssigner();

		const languageOnSelect = useCallback(
			(option: Option<Language>) => {
				valueEditor.set("language", option.value);
			},
			[valueEditor],
		);

		const searchTextBoxOnChange = valueEditor.useSetter("search");

		return (
			<Row align="center" justify="space-between" gaps="10px" maxedWidth>
				<Row sizes="200px!*2" gaps="12px" align="center">
					<CompanyAndTaxiServiceSelect
						value={CTSSValue}
						language={valueEditor.get("language")}
						allowedTaxiServiceIds={allowedTaxiServiceIds}
						onChange={CTSSOnChange}
					/>
				</Row>
				<Row sizes="66px! 270px!" gaps="10px*" align="center">
					<Select<Language>
						options={Language.constants.select.options.shortened}
						value={valueEditor.get("language")}
						onSelect={languageOnSelect}
					/>
					<InputGroup.InputGroup sizes="auto 1fr">
						<SearchIconBorders>
							<Icon
								id="search2"
								size={16}
								colors={[theme.colors.disabled_text]}
							/>
						</SearchIconBorders>
						<SearchTextBox
							placeholder={`${
								t("carSelectTab.header.filters.str200") ?? ""
							}...`}
							value={valueEditor.get("search")}
							onChange={searchTextBoxOnChange}
						/>
					</InputGroup.InputGroup>
				</Row>
			</Row>
		);
	},
);

declare namespace Filters {
	interface Value {
		companyIds: number[] | ["all"];
		taxiServiceIds: number[] | ["all"];
		language: Language;
		search: string;
	}

	interface Props {
		value: Value;

		allowedTaxiServiceIds: number[];

		onChange: Dispatch<Value>;
	}
}

export default Filters;
