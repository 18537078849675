import React, { memo, useCallback, useState } from "react";
import { Button, Icon } from "uikit";

import BalanceModal from "../../../../../../../../../../components/BalanceModal";
import { PaymentAccount } from "../../../../../../../../../../types/PaymentAccount";
import { updateBalance } from "../../../../../../../../../../utils/updateBalances";
import { PaymentProviderAccessorType } from "../../../../../../../../../../services/Payment/types";
import Executor from "../../../../../../../../../../services/Executor";

const Balance: React.FC<Balance.Props> = memo(
	({ executor, paymentAccount: account, iconId }) => {
		const [showModal, setShowModal] = useState(false);
		const [error, setError] = useState(false);
		const [isSave, setIsSave] = useState<boolean>(false);

		const handleOpen = useCallback(() => {
			setShowModal(true);
		}, []);
		const handleClose = useCallback(() => {
			setShowModal(false);
		}, []);

		const handleSubmit = useCallback(
			async (data: { amount: number; description: string }) => {
				const { amount, description } = data;
				if (!description) {
					setError(true);
					return;
				}
				setError(false);

				if (
					amount === 0 ||
					!account ||
					!executor ||
					!executor.taxiService
				) {
					return;
				}

				if (isSave) return;
				try {
					setIsSave(true);
					const res = await updateBalance(
						{
							...account,
							isEdited: true,
							amount,
							description,
						},
						executor.taxiService?.id,
						PaymentProviderAccessorType.TAXI_SERVICE,
					);

					if (res) {
						setShowModal(false);
						setIsSave(false);
					}
				} catch (error) {
					console.error(error);
					setShowModal(false);
				}
			},
			[account, executor, isSave],
		);

		return (
			<div>
				<Button.Button
					variant="secondary"
					disabled={!executor}
					onClick={handleOpen}
					icon={<Icon id={iconId} size={20} />}
				/>
				{showModal && (
					<BalanceModal
						error={error}
						isSave={isSave}
						onClose={handleClose}
						onSubmit={handleSubmit}
					/>
				)}
			</div>
		);
	},
);

declare namespace Balance {
	interface Props {
		executor: Executor.Model | false;
		paymentAccount?: PaymentAccount;
		iconId: "balance" | "bonus-balance";
	}
}

export default Balance;
