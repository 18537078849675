import React, { useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Tabs } from "uikit";

import { useTypedSelector } from "../../../../redux/store";
import useActiveTab from "../../../../hooks/useActiveTab";
import { hasAccess } from "../../../../access";
import { TabOptions } from "../../../../types";
import BasicPageLayout from "../../../../components/BasicPageLayout";
import { CommunicationMethods } from "../../../../components/Settings/tabProps";

import { Auto, Main, General, Additional } from "./tabs";
import { TabKeys, TabAccessNames } from "./constants/access";

const Tariffs: React.FC = () => {
	const { t } = useTranslation();
	const methods = useRef<CommunicationMethods>();

	const personalRole = useTypedSelector(
		(state) => state.account.personalRole,
	);

	const [, setHasChanges] = useState(false);

	const tabs: TabOptions.Array = useMemo(
		() =>
			[
				{
					key: TabKeys.MAIN_TARIFFS,
					label: t("settings.tariffs.main.title"),
					value: {
						render: () => (
							<Main
								methods={(communicationMethods) => {
									methods.current = communicationMethods;
								}}
								onHasChanges={setHasChanges}
							/>
						),
					},
					accessName: TabAccessNames[TabKeys.MAIN_TARIFFS],
				},
				{
					key: TabKeys.AUTOMATIC_TARIFFS,
					label: t("settings.tariffs.auto.title"),
					value: {
						render: () => (
							<Auto
								methods={(communicationMethods) => {
									methods.current = communicationMethods;
								}}
								onHasChanges={setHasChanges}
							/>
						),
					},
					accessName: TabAccessNames[TabKeys.AUTOMATIC_TARIFFS],
				},
				{
					key: TabKeys.GENERAL_TARIFFS,
					label: t("settings.tariffs.general.title"),
					value: {
						render: () => (
							<General
								methods={(communicationMethods) => {
									methods.current = communicationMethods;
								}}
								onHasChanges={setHasChanges}
							/>
						),
					},
					accessName: TabAccessNames[TabKeys.GENERAL_TARIFFS],
				},
				{
					key: TabKeys.ADDITIONAL,
					label: t("settings.tariffs.additional.title"),
					value: {
						render: () => (
							<Additional
								methods={(communicationMethods) => {
									methods.current = communicationMethods;
								}}
								onHasChanges={setHasChanges}
							/>
						),
					},
					accessName: TabAccessNames[TabKeys.ADDITIONAL],
				},
			].filter((btn) => hasAccess(btn.accessName, personalRole)),
		[t, personalRole],
	);

	const { activeKey, activeTab, setActiveKey } = useActiveTab(tabs);

	return (
		<BasicPageLayout
			navigation={
				<Tabs
					value={activeKey}
					onChange={setActiveKey}
					options={tabs}
					variant="vertical"
				/>
			}
			content={activeTab.value.render()}
		/>
	);
};

export default Tariffs;
