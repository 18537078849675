/* eslint-disable no-shadow */

import React, {
	Dispatch,
	RefAttributes,
	memo,
	useCallback,
	useLayoutEffect,
	useMemo,
} from "react";
import { useTranslation } from "react-i18next";
import { Column, Row, TextBox, react, useRefWithSetter } from "uikit";
import { isUndefined } from "lodash";

import CarClass from "../../../../../../../../../../../../../../services/CarClass";
import LabeledField from "../../../../../../../../../../../../../../components/LabeledField";
import useObjectEditor from "../../../../../../../../../../../../../../hooks/useObjectEditor";

import CarColorId from "./components/CarColorId";
import CarBrandId from "./components/CarBrandId";
import CarModelId from "./components/CarModelId";
import CarBodyTypeId from "./components/CarBodyTypeId";
import CarClassId from "./components/CarClassIId";
import CarBaseTypeId from "./components/CarBaseTypeId";
import InternalController from "./Controller";

const MainDataBase = react.withController<
	MainData.PropsBase,
	MainData.Controller
>(
	({
		controller,

		value,

		disabled,

		onChange,
	}) => {
		const { t } = useTranslation();
		const [carBrandIdRef, setCarBrandIdRef] =
			useRefWithSetter<CarBrandId.Ref | null>(null);
		const [carModelIdRef, setCarModelIdRef] =
			useRefWithSetter<CarModelId.Ref | null>(null);

		// const decodeCarBrandIdByCarModelId = useCarBrandIdByCarModelIdDecoder();

		const valueEditor = useObjectEditor(value, onChange);

		const taxiServiceId = valueEditor.useGetter("taxiServiceId");

		const allowedTaxiServiceIds = useMemo(
			() =>
				typeof taxiServiceId === "number" ? [taxiServiceId] : undefined,
			[taxiServiceId],
		);

		const registrationNumberValue =
			valueEditor.useGetter("registrationNumber");
		const registrationNumberOnChange =
			valueEditor.useSetter("registrationNumber");

		const vehicleIdentificationNumberValue =
			valueEditor.useGetter("vehicleNumber");
		const vehicleIdentificationNumberOnChange =
			valueEditor.useSetter("vehicleNumber");

		const manufactureYearValue = valueEditor.useGetter("manufactureYear");
		const manufactureYearOnChange =
			valueEditor.useSetter("manufactureYear");

		const passengerSeatsValue = valueEditor.useGetter("seats");
		const passengerSeatsOnChange = valueEditor.useSetter("seats");

		const carColorIdValue = valueEditor.useGetter("carColorId");
		const carColorIdOnChange = valueEditor.useSetter("carColorId");

		const carBaseTypeIdValue = valueEditor.useGetter("carBaseTypeId");
		const setCarBaseTypeIdValue = valueEditor.useSetter("carBaseTypeId");

		const carBodyTypeIdValue = valueEditor.useGetter("carBodyTypeId");
		const carBodyTypeIdOnChange = valueEditor.useSetter("carBodyTypeId");

		const carBrandIdValue = valueEditor.useGetter("carBrandId");
		const setCarBrandIdValue = valueEditor.useSetter("carBrandId");

		const carModelIdValue = valueEditor.useGetter("carModelId");
		const setCarModelIdValue = valueEditor.useSetter("carModelId");

		const carClassIdValue = valueEditor.useGetter("carClassId");
		const carClassIdOnChange = valueEditor.useSetter("carClassId");

		const carClassOnChange = valueEditor.useSetter("carClass");
		const serviceIdsOnChange = valueEditor.useSetter("serviceIds");
		const distributableCarClassIdsOnChange = valueEditor.useSetter(
			"distributableCarClassIds",
		);
		const broadcastableCarClassIdsOnChange = valueEditor.useSetter(
			"broadcastableCarClassIds",
		);

		const carBaseTypeIdOnChange = useCallback(
			(newCarBaseTypeId: number | undefined) => {
				if (newCarBaseTypeId === carBodyTypeIdValue) return;

				setCarBaseTypeIdValue(newCarBaseTypeId);
				setCarBrandIdValue(undefined);
				setCarModelIdValue(undefined);
				carBodyTypeIdOnChange(undefined);
			},
			[
				carBodyTypeIdValue,
				setCarBaseTypeIdValue,
				setCarBrandIdValue,
				setCarModelIdValue,
				carBodyTypeIdOnChange,
			],
		);

		const carBrandIdOnChange = useCallback(
			(newCarBrandId: number | undefined) => {
				if (newCarBrandId === carBrandIdValue) return;

				setCarBrandIdValue(newCarBrandId);
				setCarModelIdValue(undefined);
				carBodyTypeIdOnChange(undefined);
			},
			[
				carBrandIdValue,
				setCarBrandIdValue,
				setCarModelIdValue,
				carBodyTypeIdOnChange,
			],
		);

		const carModelIdOnChange = useCallback(
			(newCarModelId: number | undefined) => {
				if (newCarModelId === carModelIdValue) return;

				setCarModelIdValue(newCarModelId);
				carBodyTypeIdOnChange(undefined);
			},
			[carModelIdValue, setCarModelIdValue, carBodyTypeIdOnChange],
		);

		controller.setContext({
			carBrandIdRef,
			carModelIdRef,

			value,
		});

		return (
			<Column gaps="8px*">
				<Row gaps="8px*" sizes="1fr* 2fr">
					<LabeledField
						label={
							t(
								"pages.mainPage.pages.accounts.tabs.car.editModal.content.mainTab.mainData.str200",
							) ?? ""
						}
					>
						<TextBox.TextBox
							value={registrationNumberValue}
							disabled={disabled}
							style={{
								minHeight: "32px",
								flex: "1 0 0",
							}}
							placeholder={
								t(
									"pages.mainPage.pages.accounts.tabs.car.editModal.content.mainTab.mainData.str200",
								) ?? ""
							}
							onChange={registrationNumberOnChange}
						/>
					</LabeledField>
					<CarColorId
						value={carColorIdValue}
						disabled={disabled}
						taxiServiceIds={allowedTaxiServiceIds}
						onChange={carColorIdOnChange}
					/>
					<LabeledField
						label={
							t(
								"pages.mainPage.pages.accounts.tabs.car.editModal.content.mainTab.mainData.str0",
							) ?? ""
						}
					>
						<TextBox.TextBox
							value={manufactureYearValue}
							disabled={disabled}
							style={{
								minHeight: "32px",
								flex: "1 0 0",
							}}
							placeholder={
								t(
									"pages.mainPage.pages.accounts.tabs.car.editModal.content.mainTab.mainData.str0",
								) ?? ""
							}
							onChange={manufactureYearOnChange}
						/>
					</LabeledField>
					<LabeledField
						label={
							t(
								"pages.mainPage.pages.accounts.tabs.car.editModal.content.mainTab.mainData.str2",
							) ?? ""
						}
					>
						<TextBox.TextBox
							value={
								typeof passengerSeatsValue === "number"
									? passengerSeatsValue.toString()
									: ""
							}
							disabled={disabled}
							style={{
								minHeight: "32px",
								flex: "1 0 0",
							}}
							type="number"
							placeholder={
								t(
									"pages.mainPage.pages.accounts.tabs.car.editModal.content.mainTab.mainData.str2",
								) ?? ""
							}
							onChange={(value) =>
								passengerSeatsOnChange(parseInt(value, 10))
							}
						/>
					</LabeledField>
					<LabeledField
						label={
							t(
								"pages.mainPage.pages.accounts.tabs.car.editModal.content.mainTab.mainData.str4",
							) ?? ""
						}
					>
						<TextBox.TextBox
							value={vehicleIdentificationNumberValue}
							disabled={disabled}
							style={{
								minHeight: "32px",
								flex: "1 0 0",
							}}
							placeholder={
								t(
									"pages.mainPage.pages.accounts.tabs.car.editModal.content.mainTab.mainData.str4",
								) ?? ""
							}
							onChange={vehicleIdentificationNumberOnChange}
						/>
					</LabeledField>
				</Row>
				<Row gaps="8px*" sizes="1fr*">
					<CarBaseTypeId
						value={carBaseTypeIdValue}
						disabled={disabled}
						taxiServiceIds={allowedTaxiServiceIds}
						onChange={carBaseTypeIdOnChange}
					/>
					<CarBrandId
						ref={setCarBrandIdRef}
						value={carBrandIdValue}
						disabled={disabled || isUndefined(carBaseTypeIdValue)}
						carBaseTypeId={carBaseTypeIdValue}
						onChange={carBrandIdOnChange}
					/>
					<CarModelId
						ref={setCarModelIdRef}
						value={carModelIdValue}
						disabled={disabled || isUndefined(carBrandIdValue)}
						carBrandId={carBrandIdValue}
						onChange={carModelIdOnChange}
					/>
					<CarBodyTypeId
						value={carBodyTypeIdValue}
						disabled={disabled || isUndefined(carModelIdValue)}
						carModelId={carModelIdValue}
						onChange={carBodyTypeIdOnChange}
					/>
					<CarClassId
						value={carClassIdValue}
						disabled={disabled}
						taxiServiceIds={allowedTaxiServiceIds}
						onChange={carClassIdOnChange}
						carClassOnChange={carClassOnChange}
						serviceIdsOnChange={serviceIdsOnChange}
						distributableCarClassIdsOnChange={
							distributableCarClassIdsOnChange
						}
						broadcastableCarClassIdsOnChange={
							broadcastableCarClassIdsOnChange
						}
					/>
				</Row>
			</Column>
		);
	},
	InternalController,
);

const MainData = memo(MainDataBase);

declare namespace MainData {
	type Ref = InternalController;

	type Controller = InternalController;

	interface Value {
		carColorId?: number;
		carBaseTypeId?: number;
		carBodyTypeId?: number;
		carBrandId?: number;
		carModelId?: number;
		carClassId?: number;
		taxiServiceId?: number;

		registrationNumber: string;
		vehicleNumber: string;
		manufactureYear: string;
		seats?: number;

		carClass?: CarClass.Model;
		serviceIds?: number[];
		distributableCarClassIds?: number[];
		broadcastableCarClassIds?: number[];
	}

	interface PropsBase {
		value: Value;

		disabled: boolean;

		onChange: Dispatch<Value>;
	}

	type Props = PropsBase & RefAttributes<Ref>;
}

export default MainData;
