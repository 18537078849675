import React, { RefAttributes, memo, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { Column, Row, TextBox, react } from "uikit";

import useObjectEditor from "../../../../../../../../../../../../../../hooks/useObjectEditor";
import { validateEmail } from "../../../../../../../../../../../../../../utils/validateEmail";
import FieldsContainer from "../../../../../../../../../../../../../../components/FieldsContainer";
import LabeledField from "../../../../../../../../../../../../../../components/LabeledField";

import tPath from "../../../../../../../../constants/tPath";
import Phones from "../Phones";
import InternalController from "./Controller";

const tPath2 = "modal.tabs.main.mainData";

const MainDataBase = react.withController<
	MainData.PropsBase,
	MainData.Controller
>(({ controller, value, onChange }) => {
	const { t } = useTranslation();

	const valueEditor = useObjectEditor(value, onChange);

	const name = valueEditor.useGetter("name");
	const setName = valueEditor.useSetter("name");
	const edrpou = valueEditor.useGetter("edrpou");
	const setEdrpou = valueEditor.useSetter("edrpou");
	const email = valueEditor.useGetter("email");
	const setEmail = valueEditor.useSetter("email");
	const phones = valueEditor.useGetter("phones");
	const setPhones = valueEditor.useSetter("phones");
	const address = valueEditor.useGetter("address");
	const setAddress = valueEditor.useSetter("address");
	const contactName = valueEditor.useGetter("contactName");
	const setContactName = valueEditor.useSetter("contactName");

	const [errors, setError] = useState({
		email: false,
		name: false,
	});

	const errorsEditor = useObjectEditor(errors, setError);
	const nameErr = errorsEditor.useGetter("name");
	const setNameError = errorsEditor.useSetter("name");
	const emailErr = errorsEditor.useGetter("email");
	const setEmailError = errorsEditor.useSetter("email");

	const onChangeEmail = useCallback(
		(str: string) => {
			const isValid = validateEmail(str);
			setEmailError(!isValid);
			setEmail(str);
		},
		[setEmail, setEmailError],
	);

	const changeNameHandler = useCallback(
		(newName) => {
			setNameError(false);
			setName(newName);
		},
		[setName, setNameError],
	);

	controller.setContext({
		value,
		setNameError,
		setEmailError,
	});
	return (
		<FieldsContainer label={t(`${tPath}.${tPath2}.title`) || ""}>
			<Column gaps="8px*">
				<Row gaps="8px*" sizes="1fr*">
					<LabeledField label={t(`${tPath}.${tPath2}.name`) || ""}>
						<TextBox.TextBox
							style={{
								minHeight: "32px",
								flex: "1 0 0",
							}}
							error={nameErr}
							value={name}
							onChange={changeNameHandler}
							autoComplete="one-time-code"
							placeholder={t(`${tPath}.${tPath2}.name`) || ""}
						/>
					</LabeledField>
					<LabeledField label={t(`${tPath}.${tPath2}.edrpou`) || ""}>
						<TextBox.TextBox
							style={{
								minHeight: "32px",
								flex: "1 0 0",
							}}
							value={edrpou}
							onChange={setEdrpou}
							autoComplete="one-time-code"
							placeholder={t(`${tPath}.${tPath2}.edrpou`) || ""}
						/>
					</LabeledField>
					<LabeledField
						label={t(`${tPath}.${tPath2}.contactName`) || ""}
					>
						<TextBox.TextBox
							value={contactName}
							onChange={setContactName}
							style={{
								minHeight: "32px",
								flex: "1 0 0",
							}}
							autoComplete="one-time-code"
							placeholder={
								t(`${tPath}.${tPath2}.contactName`) || ""
							}
						/>
					</LabeledField>
					<LabeledField label={t(`${tPath}.${tPath2}.address`) || ""}>
						<TextBox.TextBox
							style={{
								minHeight: "32px",
								flex: "1 0 0",
							}}
							value={address}
							onChange={setAddress}
							autoComplete="one-time-code"
							placeholder={t(`${tPath}.${tPath2}.address`) || ""}
						/>
					</LabeledField>
				</Row>
				<Row
					gaps="8px*"
					sizes={phones?.length === 3 ? "1fr*" : "24.57%*"}
				>
					<LabeledField label={t(`${tPath}.${tPath2}.email`) || ""}>
						<TextBox.TextBox
							error={emailErr}
							value={email}
							style={{
								minHeight: "32px",
								flex: "1 0 0",
							}}
							type="email"
							placeholder={t(`${tPath}.${tPath2}.email`) || ""}
							onChange={onChangeEmail}
						/>
					</LabeledField>
					<Phones value={phones} max={3} onChange={setPhones} />
				</Row>
			</Column>
		</FieldsContainer>
	);
}, InternalController);
const MainData = memo(MainDataBase);

declare namespace MainData {
	type Ref = InternalController | null;
	type Controller = InternalController;
	interface PropsBase {
		value: Value;
		onChange: (value: Value) => void;
	}
	type Props = PropsBase & RefAttributes<Ref>;

	interface Value {
		name: string;
		edrpou: string;
		phones: string[];
		address: string;
		email: string;
		contactName: string;
	}
}

export default MainData;
