import React, {
	Dispatch,

	KeyboardEvent,
	useCallback,
	useMemo,
	useState,
} from "react";
import { CheckBox } from "uikit";
import { RowDataType } from "rsuite-table";

import {
	useColumns,
	useVisibleColumns,
} from "../../../../../../../../../../../../../../../hooks/useTableSettings";
import LightTable from "../../../../../../../../../../../../../../../components/LightTable";
import TableSettings from "../../../../../../../../../../../../../../../components/TableSettings";
import DisabledCheckbox from "../../../../../../../../../../../../../../../components/DisabledCheckbox";
import {
	StyledRow,
	StyledSpan,
} from "../../../../../../../../../../../../../../../components/common";

import Cards from "../..";
import Modal from "../Modal";
import Root from "./components/Root";
import Status from "./components/Status";

const Content: React.FC<Content.Props> = ({
	value,
	selected,
	setSelected,
	onEdit,
	saveHandler,
	height,
}) => {
	const columns = useColumns("counterpartyCheckCard");
	const { columnIds, setColumnIds } = useVisibleColumns(
		"customers.counterpartyCheckCard",
		"counterpartyCheckCard",
	);

	const [ctrlPressed, setCtrlPressed] = useState(false);

	const updateSelected = useCallback(
		(index: number, force = false) => {
			if (ctrlPressed || force) {
				if (selected.includes(index)) {
					setSelected(selected.filter((i) => index !== i));
				} else {
					setSelected([...selected, index]);
				}
			} else {
				selected.includes(index)
					? setSelected([])
					: setSelected([index]);
			}
		},
		[ctrlPressed, selected, setSelected],
	);

	const getOrderCount = useCallback(({ rideCounter }: Modal.Value) => {
		const total = rideCounter?.total ?? 0;
		const success = rideCounter?.success ?? 0;
		const failed = rideCounter?.failed ?? 0;
		return (
			<StyledRow alignItems="center">
				<StyledSpan font={{ fw: "700" }} colors="#03a9f4">
					{total}
				</StyledSpan>
				<StyledSpan font={{ fw: "700" }} colors="#647079" p="0 2px">
					/
				</StyledSpan>
				<StyledSpan font={{ fw: "700" }} colors="#4caf50">
					{success}
				</StyledSpan>
				<StyledSpan font={{ fw: "700" }} colors="#647079" p="0 2px">
					/
				</StyledSpan>
				<StyledSpan font={{ fw: "700" }} colors="#f83528">
					{failed}
				</StyledSpan>
			</StyledRow>
		);
	}, []);

	const data = useMemo(
		() =>
			columnIds.map((columnId) => {
				const column = columns.find((col) => col.id === columnId);
				if (!column) return null;

				return (
					<LightTable.Column
						resizable
						sortable
						key={columnId}
						verticalAlign="middle"
						flexGrow={1}
					>
						<LightTable.HeaderCell style={{ padding: "0 20px" }}>
							{column.label}
						</LightTable.HeaderCell>
						<LightTable.Cell
							verticalAlign="middle"
							style={{ padding: "0 20px" }}
							dataKey={column.id}
							fullText
						>
							{(rowData: RowDataType<Modal.Value>) => {
								const model = rowData as Modal.Value;

								switch (columnId) {
									case "status":
										return (
											<Status
												model={model}
												value={value}
												saveHandler={saveHandler}
											/>
										);

									case "cardNumber":
										return model?.code?.value;

									case "keyword":
										return model?.code?.keyword;

									case "orderCount":
										return <>{getOrderCount(model)}</>;

									case "registrationDate":
										return model.createdAt
											? new Date(
													model?.createdAt,
											  ).toLocaleDateString()
											: new Date().toLocaleDateString();

									case "activationDate":
										return new Date(
											model.activationDate,
										).toLocaleDateString();

									case "completionDate":
										return <></>;

									case "firstRideDate":
										return <></>;

									case "lastRideDate":
										return <></>;

									case "mobileApp":
										return (
											<DisabledCheckbox
												value={
													model?.accessAllEmployeeToPersonalApp
												}
											/>
										);

									case "corporateAccount":
										return (
											<DisabledCheckbox
												value={
													model?.isAccessToCorporateAccount
												}
											/>
										);

									case "notes":
										return model?.additionalFields?.notes;

									default:
										return null;
								}
							}}
						</LightTable.Cell>
					</LightTable.Column>
				);
			}),
		[columnIds, columns, getOrderCount, saveHandler, value],
	);

	const getClassName = useCallback(
		(rowData) =>
			rowData && selected.includes(value.indexOf(rowData))
				? "selected"
				: "",
		[selected, value],
	);

	const handleOnRowClick = useCallback(
		(rowData) => {
			updateSelected(value.indexOf(rowData));
		},
		[updateSelected, value],
	);

	const handleOnChangeSelected = useCallback(
		(isAllSelected: boolean) => {
			if (isAllSelected) {
				setSelected(value.map((_, i) => i));
			} else {
				setSelected([]);
			}
		},
		[setSelected, value],
	);

	const handleContentEdit = useCallback(
		(rowData) => {
			onEdit?.(value.indexOf(rowData));
		},
		[onEdit, value],
	);

	const handleKeyDown = useCallback((e: KeyboardEvent<HTMLDivElement>) => {
		if (e.ctrlKey) {
			setCtrlPressed(true);
		}
	}, []);

	const handleKeyUp = useCallback((e: KeyboardEvent<HTMLDivElement>) => {
		if (!e.ctrlKey) {
			setCtrlPressed(false);
		}
	}, []);

	return (
		<Root sizes="1fr auto!">
			<div tabIndex={-1} onKeyDown={handleKeyDown} onKeyUp={handleKeyUp}>
				<LightTable
					data={value}
					virtualized
					fillHeight
					shouldUpdateScroll={false}
					rowClassName={getClassName}
					onRowClick={handleOnRowClick}
					onRowDoubleClick={handleContentEdit}
					height={height - 145}
				>
					<LightTable.Column verticalAlign="middle" width={50}>
						<LightTable.HeaderCell style={{ padding: "0 25px" }}>
							<CheckBox
								value={
									selected.length === value.length &&
									value.length !== 0
								}
								onChange={handleOnChangeSelected}
							/>
						</LightTable.HeaderCell>
						<LightTable.Cell
							verticalAlign="middle"
							style={{ padding: "0 25px" }}
						>
							{(data) => (
								<CheckBox
									value={selected.includes(
										value.indexOf(data as Modal.Value),
									)}
									onChange={() => {
										updateSelected(
											value.indexOf(data as Modal.Value),
											true,
										);
									}}
								/>
							)}
						</LightTable.Cell>
					</LightTable.Column>
					{data}
				</LightTable>
			</div>
			<TableSettings
				value={columnIds}
				defaultValue={columnIds}
				columns={columns}
				onChange={setColumnIds}
			/>
		</Root>
	);
};

declare namespace Content {
	interface Props {
		value: Cards.Value;
		height: number;
		selected: number[];
		setSelected: Dispatch<number[]>;

		onEdit?: Dispatch<number>;
		saveHandler?: (card: Modal.Value, index?: number | null) => void;
	}

	type ColumnType = {
		id: string;
		label: string;
		width?: number;
		justify?: "center";
	};
}

export default Content;
