import React, { memo, useMemo } from "react";
import { TextBox } from "uikit";
import { useTranslation } from "react-i18next";
import LabeledField from "../../../../../../../../../../../../../../../../components/LabeledField";
import useObjectEditor from "../../../../../../../../../../../../../../../../hooks/useObjectEditor";

const LoginPassword: React.FC<LoginPassword.Props> = memo(
	({ value, onChange }) => {
		const { t } = useTranslation();

		const valueEditor = useObjectEditor(value, onChange);

		const login = valueEditor.useGetter("corporateLogin");
		const setLogin = valueEditor.useSetter("corporateLogin");

		const password = valueEditor.useGetter("corporatePassword");
		const setPassword = valueEditor.useSetter("corporatePassword");

		const loginLabel = useMemo(() => t(`login`) || "Login", [t]);
		const passwordLabel = useMemo(() => t(`password`) || "Password", [t]);

		return (
			<>
				<LabeledField label={loginLabel}>
					<TextBox.TextBox
						value={login ?? ""}
						onChange={setLogin}
						placeholder={loginLabel}
					/>
				</LabeledField>

				<LabeledField label={passwordLabel}>
					<TextBox.TextBox
						value={password ?? ""}
						onChange={setPassword}
						placeholder={passwordLabel}
					/>
				</LabeledField>
			</>
		);
	},
);

declare namespace LoginPassword {
	type Value = {
		corporateLogin?: string;
		corporatePassword?: string;
	};

	interface Props {
		value: Value;
		onChange: (value: Value) => void;
	}
}

export default LoginPassword;
