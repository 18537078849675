import React, { Dispatch, memo } from "react";
import { Row, TextBox, CheckBox } from "uikit";
import { useTranslation } from "react-i18next";
import FieldsContainer from "../../../../../../../../../../../../../../../../../../../../components/FieldsContainer";
import useObjectEditor from "../../../../../../../../../../../../../../../../../../../../hooks/useObjectEditor";
import tPath from "../../../../../../../../constants/tPath";

const Permissions: React.FC<Permissions.Props> = memo(({ value, onChange }) => {
	const { t } = useTranslation();

	const valueEditor = useObjectEditor(value, onChange);

	const mobileApp = valueEditor.useGetter("accessAllEmployeeToPersonalApp");
	const setMobileApp = valueEditor.useSetter(
		"accessAllEmployeeToPersonalApp",
	);

	const corporateAccount = valueEditor.useGetter(
		"isAccessToCorporateAccount",
	);
	const setCorporateAccount = valueEditor.useSetter(
		"isAccessToCorporateAccount",
	);

	const corporateLogin = valueEditor.useGetter("corporateLogin");
	const setCorporateLogin = valueEditor.useSetter("corporateLogin");

	const corporatePassword = valueEditor.useGetter("corporatePassword");
	const setCorporatePassword = valueEditor.useSetter("corporatePassword");

	return (
		<FieldsContainer
			gaps="10px*"
			label={t(`${tPath}.modal.tabs.main.options.title`) || ""}
		>
			<Row gaps="10px*" sizes="250px*2">
				<TextBox.TextBox
					value={corporateLogin ?? ""}
					onChange={setCorporateLogin}
					autoComplete="one-time-code"
					placeholder={
						t(`${tPath}.modal.tabs.main.options.login`) || ""
					}
				/>
				<TextBox.TextBox
					value={corporatePassword ?? ""}
					onChange={setCorporatePassword}
					autoComplete="one-time-code"
					placeholder={
						t(`${tPath}.modal.tabs.main.options.password`) || ""
					}
				/>
				<CheckBox
					label={
						t(`${tPath}.modal.tabs.main.options.corporateApp`) || ""
					}
					value={mobileApp}
					onChange={setMobileApp}
				/>
				<CheckBox
					disabled
					label={
						t(
							`${tPath}.modal.tabs.main.options.corporateAccount`,
						) || ""
					}
					value={corporateAccount}
					onChange={setCorporateAccount}
				/>
			</Row>
		</FieldsContainer>
	);
});

declare namespace Permissions {
	interface Props {
		value: Value;
		onChange: Dispatch<Value>;
	}

	interface Value {
		accessAllEmployeeToPersonalApp: boolean;
		isAccessToCorporateAccount: boolean;
		corporateLogin?: string;
		corporatePassword?: string;
	}
}

export default Permissions;
