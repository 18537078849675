import { react } from "uikit";
import { RefObject } from "react";

import MainData from "./components/MainData";
import Other from "./components/Other";
import Access from "./components/Access";

interface Context {
	mainDataRef: RefObject<MainData.Ref>;
	otherRef: RefObject<Other.Ref>;
	accessRef: RefObject<Access.Ref>;
}

export default class Controller extends react.ControllerBase<Context> {
	validate() {
		const isValidMainData = !!this.context?.mainDataRef.current?.validate();
		const isValidOther = !!this.context?.otherRef.current?.validate();
		const isValidAccess = !!this.context?.accessRef.current?.validate();

		return isValidMainData && isValidOther && isValidAccess;
	}
}
