/* eslint-disable no-shadow */

import React, {
	Dispatch,
	RefAttributes,
	memo,
	useCallback,
	useMemo,
	useState,
} from "react";
import { Column, Row, react, useRefWithSetter } from "uikit";
import { isUndefined } from "lodash";

import Language from "../../../../../../../../../../../../../../services/Language";
import { CarClass } from "../../../../../../../../../../../../../../services";
import useObjectEditor from "../../../../../../../../../../../../../../hooks/useObjectEditor";
import useCompanyIdByTaxiServiceIdDecoder from "../../../../../../../../../../../../../../hooks/useCompanyIdByTaxiServiceIdDecoder";
import useTaxiServices from "../../../../../../../../../../../../../../hooks/useTaxiServices";

import ResponsibleDispatcherId from "./components/ResponsibleDispatcherId";
import InternalController from "./Controller";
import CompanyId from "./components/CompanyId";
import TaxiServiceId from "./components/TaxiServiceId";
import CarParkId from "./components/CarParkId";
import ParkNumber from "./components/ParkNumber";

const WorkDataBase = react.withController<
	WorkData.PropsBase,
	WorkData.Controller
>(
	({
		controller,

		value,

		disabled,
		language,
		originalTaxiServiceId,
		originalParkNumber,
		allowedTaxiServiceId,
		currentId,

		onChange,
	}) => {
		const [parkNumberRef, setParkNumberRef] =
			useRefWithSetter<ParkNumber.Ref | null>(null);

		const decodeCompanyIdByTaxiServiceId =
			useCompanyIdByTaxiServiceIdDecoder();

		const taxiServices = useTaxiServices();

		const [taxiServiceIdError, setTaxiServiceIdError] = useState(false);

		const valueEditor = useObjectEditor(value, onChange);

		const setCarClassId = valueEditor.useSetter("carClassId");
		const setCarClass = valueEditor.useSetter("carClass");
		const taxiServiceIdValue = valueEditor.useGetter("taxiServiceId");
		const setTaxiServiceIdValue = valueEditor.useSetter("taxiServiceId");

		const taxiServiceIdOnChange = useCallback(
			(newTaxiServiceId?: number) => {
				setTaxiServiceIdError(false);
				setTaxiServiceIdValue(newTaxiServiceId);
				setCarClassId(undefined);
				setCarClass(undefined);
				if (typeof newTaxiServiceId === "undefined") return;

				setCompanyIdValue(
					decodeCompanyIdByTaxiServiceId(newTaxiServiceId),
				);
			},
			[
				decodeCompanyIdByTaxiServiceId,
				setTaxiServiceIdValue,
				setCarClassId,
				setCarClass,
			],
		);

		const companyId = useMemo(
			() =>
				typeof taxiServiceIdValue === "number"
					? decodeCompanyIdByTaxiServiceId(taxiServiceIdValue)
					: undefined,
			[decodeCompanyIdByTaxiServiceId, taxiServiceIdValue],
		);

		const [companyIdValue, setCompanyIdValue] = useState<
			number | undefined
		>(undefined);

		const companyIdOnChange = useCallback(
			(newCompanyId: number) => {
				setCompanyIdValue(newCompanyId);

				if (
					taxiServices.find(
						(taxiService) => taxiService.id === taxiServiceIdValue,
					)?.company?.id !== newCompanyId &&
					taxiServiceIdValue !== undefined
				) {
					taxiServiceIdOnChange(undefined);
				}
				setCarClassId(undefined);
				setCarClass(undefined);
			},
			[
				setCarClass,
				setCarClassId,
				taxiServiceIdOnChange,
				taxiServiceIdValue,
				taxiServices,
			],
		);

		const parkNumberValue = valueEditor.useGetter("parkNumber");
		const parkNumberOnChange = valueEditor.useSetter("parkNumber");

		const carParkIdValue = valueEditor.useGetter("carParkId");
		const carParkIdOnChange = valueEditor.useSetter("carParkId");

		const responsibleDispatcherIdValue = valueEditor.useGetter(
			"responsibleDispatcherId",
		);
		const responsibleDispatcherIdOnChange = valueEditor.useSetter(
			"responsibleDispatcherId",
		);

		controller.setContext({
			value,
			parkNumberRef,
			setTaxiServiceIdError,
		});

		return (
			<Column gaps="8px*">
				<Row gaps="8px*" sizes="1fr*">
					<CompanyId
						value={companyIdValue ?? companyId}
						disabled={disabled || !!allowedTaxiServiceId}
						error={
							isUndefined(companyIdValue) && taxiServiceIdError
						}
						language={language}
						onChange={companyIdOnChange}
					/>
					<TaxiServiceId
						value={taxiServiceIdValue}
						disabled={disabled || !!allowedTaxiServiceId}
						error={taxiServiceIdError}
						language={language}
						companyId={companyIdValue}
						onChange={taxiServiceIdOnChange}
					/>
					<ParkNumber
						ref={setParkNumberRef}
						value={parkNumberValue}
						originalTaxiServiceId={originalTaxiServiceId}
						originalValue={originalParkNumber}
						disabled={disabled}
						taxiServiceId={value.taxiServiceId}
						currentCarId={currentId}
						onChange={parkNumberOnChange}
					/>
					<CarParkId
						value={carParkIdValue}
						disabled={disabled}
						taxiServiceId={taxiServiceIdValue}
						onChange={carParkIdOnChange}
					/>
					<ResponsibleDispatcherId
						value={responsibleDispatcherIdValue}
						disabled={disabled}
						taxiServiceId={taxiServiceIdValue}
						onChange={responsibleDispatcherIdOnChange}
					/>
				</Row>
			</Column>
		);
	},
	InternalController,
);

const WorkData = memo(WorkDataBase);

declare namespace WorkData {
	type Ref = InternalController | null;

	type Controller = InternalController;

	interface Value {
		taxiServiceId?: number;
		carParkId?: number;
		responsibleDispatcherId?: number;
		parkNumber: ParkNumber.Value;
		carClassId?: number;
		carClass?: CarClass.Model;
	}

	interface PropsBase {
		value: Value;

		disabled: boolean;
		language: Language;
		originalTaxiServiceId?: number;
		originalParkNumber?: string;
		allowedTaxiServiceId?: number;
		currentId?: number;
		onChange: Dispatch<Value>;
	}

	type Props = PropsBase & RefAttributes<Ref>;
}

export default WorkData;
