import React, { useMemo } from "react";
import { Row, TextBox } from "uikit";
import { useTranslation } from "react-i18next";
import useObjectEditor from "../../../../../../../../../../../../../../../hooks/useObjectEditor";
import Client from "../../../../../../../../../../../../../../../services/Client";
import LabeledField from "../../../../../../../../../../../../../../../components/LabeledField";

declare namespace LoginPassword {
	type Value = Pick<Client.Model, "login" | "hasPassword" | "password">;

	interface Props {
		value: Value;

		onChange: (value: Value) => void;
	}
}

const LoginPassword: React.FC<LoginPassword.Props> = ({ value, onChange }) => {
	const { t } = useTranslation();

	const valueEditor = useObjectEditor(value, onChange);

	const login = valueEditor.useGetter("login");
	const setLogin = valueEditor.useSetter("login");

	const password = valueEditor.useGetter("password");
	const setPassword = valueEditor.useSetter("password");

	const loginLabel = useMemo(() => t(`login`) || "Login", [t]);
	const passwordLabel = useMemo(() => t(`password`) || "Password", [t]);

	return (
		<Row gaps="8px*" sizes="275.5px*">
			<LabeledField label={loginLabel}>
				<TextBox.TextBox
					value={login}
					placeholder={loginLabel}
					autoComplete="one-time-code"
					onChange={setLogin}
				/>
			</LabeledField>

			<LabeledField label={passwordLabel}>
				<TextBox.TextBox
					value={password ?? (value.hasPassword ? "********" : "")}
					type="password"
					placeholder={passwordLabel}
					autoComplete="one-time-code"
					onChange={setPassword}
				/>
			</LabeledField>
		</Row>
	);
};

export default LoginPassword;
