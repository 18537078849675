import styled from "styled-components";
import { Column, theme } from "uikit";

const Root = styled(Column)<Root.Props>`
	position: relative;

	padding: 10px;
	${(props) => props.label && "padding-top: 14px"};

	border: 1px solid ${theme.colors.color_border_basic};
	border-radius: ${theme.borderRadius.m};

	display: flex;
	flex-direction: column;
`;

declare namespace Root {
	interface Props extends Column.Props {
		label?: string;
	}
}

export default Root;
