import React, { memo } from "react";
import { ToggleButton } from "uikit";
import Modal from "../../../Modal";

const Status: React.FC<Status.Props> = memo(({ value, model, saveHandler }) => (
	<div
		onClick={(event) => event.stopPropagation()}
		style={{
			maxWidth: "max-content",
		}}
	>
		<ToggleButton.ToggleButton
			value={model.active}
			onChange={(newActive) => {
				const index = value.indexOf(model);
				saveHandler?.({ ...model, active: newActive }, index);
			}}
		/>
	</div>
));

declare namespace Status {
	interface Props {
		value: Modal.Value[];
		model: Modal.Value;
		saveHandler?: (check: Modal.Value, index?: number | null) => void;
	}
}

export default Status;
