import { react } from "uikit";
import Level from ".";

interface Context {
	value: Level.Value;
	setError: (x: boolean) => void;
}

export default class Controller extends react.ControllerBase<Context> {
	validate() {
		const isValid = !!this.context?.value;

		if (!isValid) this.context?.setError(true);

		return isValid;
	}
}
