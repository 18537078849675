import React, { Dispatch, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Icon, InputGroup, TextBox, theme } from "uikit";
import File from "../../../../../../../../../../../../../../services/File";
import useObjectEditor from "../../../../../../../../../../../../../../hooks/useObjectEditor";
import FileListModal from "../../../../../../../../../../../../../../components/FileListModal";

import LabeledField from "../../../../../../../../../../../../../../components/LabeledField";

const LicenseCard: React.FC<LicenseCard.Props> = ({
	value,
	disabled,
	onChange,
}) => {
	const { t } = useTranslation();
	const valueEditor = useObjectEditor(value, onChange);

	const licenseCardValue = valueEditor.useGetter("licenseCard");
	const licenseCardOnChange = valueEditor.useSetter("licenseCard");

	const licenseCardFilesValue = valueEditor.useGetter("licenseCardFiles");
	const licenseCardFilesOnChange = valueEditor.useSetter("licenseCardFiles");

	const [showFileModal, setShowFileModal] = useState(false);

	const fileButtonOnClick = useCallback(() => {
		setShowFileModal(true);
	}, []);

	const fileListModalOnClose = useCallback(() => {
		setShowFileModal(false);
	}, []);

	const fileListModalOnSubmit = useCallback(
		(files: File.Model[]) => {
			licenseCardFilesOnChange(files);
			setShowFileModal(false);
		},
		[licenseCardFilesOnChange],
	);

	return (
		<>
			<LabeledField
				label={
					t(
						"pages.mainPage.pages.accounts.tabs.car.editModal.content.additionalDataTab.licenseCard.str0",
					) ?? ""
				}
			>
				<InputGroup.InputGroup disabled={disabled} sizes="1fr 32px!">
					<TextBox.TextBox
						value={licenseCardValue}
						disabled={disabled}
						placeholder={
							t(
								"pages.mainPage.pages.accounts.tabs.car.editModal.content.additionalDataTab.licenseCard.str0",
							) ?? ""
						}
						onChange={licenseCardOnChange}
					/>
					<Button.Button
						style={{
							borderTopLeftRadius: 0,
							borderBottomLeftRadius: 0,
						}}
						disabled={disabled}
						icon={
							<Icon
								id="copy"
								size={16}
								colors={[
									disabled
										? theme.colors.secondary
										: theme.colors.white,
								]}
							/>
						}
						onClick={fileButtonOnClick}
					/>
				</InputGroup.InputGroup>
			</LabeledField>
			{showFileModal ? (
				<FileListModal
					value={licenseCardFilesValue}
					title={
						t(
							"pages.mainPage.pages.accounts.tabs.car.editModal.content.additionalDataTab.licenseCard.str0",
						) ?? ""
					}
					onSubmit={fileListModalOnSubmit}
					onClose={fileListModalOnClose}
				/>
			) : undefined}
		</>
	);
};

declare namespace LicenseCard {
	interface Value {
		licenseCard: string;
		licenseCardFiles: File.Model[];
	}

	interface Props {
		value: Value;

		disabled: boolean;

		onChange: Dispatch<Value>;
	}
}

export default LicenseCard;
