import React, {
	Dispatch,

	memo,
	useCallback,
	useMemo,
	useState,
} from "react";
import { Column, Icon } from "uikit";
import { useTranslation } from "react-i18next";

import DefaultPageHeader from "../../../../../../../../../../../../components/DefaultPageHeader";
import DefaultPageHeaderButton from "../../../../../../../../../../../../components/DefaultPageHeaderButton";
import Counterparty from "../../../../../../../../../../../../services/Counterparty";
import {
	useTableOptions,
	UseTableOptions,
} from "../../../../../../../../../../../../components/LightTable";

import Table from "./components/Table";
import Modal from "./components/Modal";
import {
	Popup,
	StyledP,
} from "../../../../../../../../../../../../components/common";

const Main: React.FC<Main.Props> = memo((props) => {
	const {
		counterparties,
		counterpartyById,
		value,
		onChange,
		editorTable,
		onChangeTable,
	} = props;
	const { t } = useTranslation();
	const { setQuery } = useTableOptions({
		value: editorTable,
		setValue: onChangeTable,
	});

	const selectedCounterparties = useMemo(
		() => counterparties.filter((c) => value.includes(c.id)),
		[counterparties, value],
	);

	const [showModal, setShowModal] = useState(false);

	const [selected, setSelected] = useState<number[]>([]);

	const addHandler = useCallback(() => {
		setShowModal(true);
	}, []);

	const unpinHandler = useCallback(async () => {
		onChange(
			selectedCounterparties
				.filter((agent) => !selected.includes(agent.id))
				.map(({ id }) => id),
		);
		setSelected([]);
	}, [onChange, selected, selectedCounterparties]);

	const cancelHandler = useCallback(() => {
		setShowModal(false);
		setQuery("");
	}, [setQuery]);

	const saveHandler = useCallback(
		(newIds: number[]) => {
			const payload = [...value, ...newIds];
			onChange(payload);
			setShowModal(false);
			setQuery("");
		},
		[onChange, setQuery, value],
	);

	const dataCounterparties = useMemo(
		() => [...counterparties].filter((item) => !value.includes(item.id)),
		[counterparties, value],
	);

	return (
		<Column sizes="auto 1fr" gaps="8px*">
			<DefaultPageHeader
				buttons={
					<>
						<Popup
							useHoverControl
							trackerId={"counterparties_table_add_button_id"}
							offset={{ x: 32, y: 33 }}
							tracker={
								<DefaultPageHeaderButton
									id="counterparties_table_add_button_id"
									variant={"primary"}
									icon={<Icon id="pencil" size={16} />}
									onClick={addHandler}
								/>
							}
						>
							<StyledP
								br="4px"
								bgC="#000000"
								colors="#ffffff"
								p="5px 10px"
							>
								{t("table.buttons.add")}
							</StyledP>
						</Popup>
						<Popup
							useHoverControl
							trackerId={"counterparties_table_delete_button_id"}
							offset={{ x: 32, y: 33 }}
							tracker={
								<DefaultPageHeaderButton
									id="counterparties_table_delete_button_id"
									disabled={!selected.length}
									variant={"secondary"}
									icon={<Icon id="trash" size={20} />}
									onClick={unpinHandler}
								/>
							}
						>
							<StyledP
								br="4px"
								bgC="#000000"
								colors="#ffffff"
								p="5px 10px"
							>
								{t("table.buttons.delete")}
							</StyledP>
						</Popup>
					</>
				}
			/>
			<Table
				value={selectedCounterparties}
				selected={selected}
				setSelected={setSelected}
				editorTable={editorTable}
				onChangeTable={onChangeTable}
			/>
			{showModal && (
				<Modal
					counterparties={dataCounterparties}
					counterpartyById={counterpartyById}
					value={value}
					onCancel={cancelHandler}
					onSave={saveHandler}
					editorTable={editorTable}
					onChangeTable={onChangeTable}
				/>
			)}
		</Column>
	);
});

declare namespace Main {
	interface Props {
		counterparties: Counterparty.Model[];
		counterpartyById: Record<string, Counterparty.Model>;
		editorTable: UseTableOptions["editor"];
		onChangeTable: UseTableOptions["onChange"];
		value: Value;
		onChange: Dispatch<Value>;
	}

	type Value = number[];
}

export default Main;
