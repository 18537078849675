/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable no-param-reassign */
/* eslint-disable no-shadow */

import React, { RefAttributes, memo, useState } from "react";
import { Flex, react } from "uikit";
import InternalController from "./Controller";
import ModelTable from "./components/ModelTable";
import Language from "../../../../../../../../../../../../services/Language";
import TabRoot from "../TabRoot";
import Dispatcher from "../../../../../../../../../../../../services/Dispatcher";
import { SuspenseLoader } from "../../../../../../../../../components";

const HistoryTab = memo(
	react.withController<HistoryTab.PropsBase, HistoryTab.Controller>(
		({ value, visible, language }) => {
			const [sort, setSort] = useState<ModelTable.Sort>({});

			return (
				<TabRoot hasPaddings={false} visible={visible}>
					{/* <Column sizes="1fr" maxedWidth maxedHeight>
						<ModelTable
							sort={sort}
							loading={false}
							data={value}
							language={language}
							visible={visible}
							onChangeSort={setSort}
							onLoadMore={noop}
						/>
					</Column> */}
					<Flex
						align="center"
						justify="center"
						maxedWidth
						maxedHeight
					>
						<SuspenseLoader />
					</Flex>
				</TabRoot>
			);
		},
		InternalController,
	),
);

declare namespace HistoryTab {
	type Ref = InternalController | null;

	type Controller = InternalController;

	type Value = Dispatcher.History;

	interface PropsBase {
		value: Value;

		visible: boolean;
		language: Language;
	}

	type Props = PropsBase & RefAttributes<Ref>;
}

export default HistoryTab;
