import { react } from "uikit";

import CustomInputModal from "./components/CustomInputModal";

interface Context {
	status: CustomInputModal.Value;
}

export default class Controller extends react.ControllerBase<Context> {
	validate() {
		const isStatusValid = !!this.context?.status.level;

		return isStatusValid;
	}
}
