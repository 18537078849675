import React, { memo, useCallback } from "react";
import { Button, Icon } from "uikit";
import { useTypedSelector } from "../../../../../../../../../../redux/store";
import SIP from "../../../../../../../../../../services/SIP";
import Agent from "../../../../../../../../../../services/Agent";

interface Props {
	agents: Agent.Model[];
}

const Call: React.FC<Props> = memo(({ agents }) => {
	const phone = agents[0]?.additionalFields.contactPersonPhone;

	const sipToDispatcherId = useTypedSelector(
		(state) => state.sipToDispatcher.id,
	);

	const handleCall = useCallback(async () => {
		if (phone && sipToDispatcherId) {
			await SIP.callToPhone({
				sipToDispatcherId,
				phone,
			});
		}
	}, [phone, sipToDispatcherId]);

	return (
		<Button.Button
			variant="secondary"
			disabled={!sipToDispatcherId || !phone}
			onClick={handleCall}
			icon={<Icon id="call-to-person" size={20} />}
		/>
	);
});

export default Call;
