import React, { memo, useEffect, useState } from "react";
import { Row, TextBox } from "uikit";
import { useTranslation } from "react-i18next";
import useObjectEditor from "../../../../../../../../../../../../../../hooks/useObjectEditor";
import LabeledField from "../../../../../../../../../../../../../../components/LabeledField";
import tPath from "../../../../../../../../constants/tPath";

const path = `${tPath}.modal.tabs.main`;

const FullName: React.FC<FullName.Props> = memo(({ value, onChange }) => {
	const { t } = useTranslation();
	const editor = useObjectEditor(value, onChange);

	const name = editor.useGetter("name");
	const setName = editor.useSetter("name");

	const surname = editor.useGetter("surname");
	const setSurname = editor.useSetter("surname");

	const fatherName = editor.useGetter("fatherName");
	const setFatherName = editor.useSetter("fatherName");

	const [disabled, setDisabled] = useState({
		surname: false,
		name: false,
		fatherName: false,
	});
	useEffect(() => {
		const newDisabled = { ...disabled };
		if (name) newDisabled.name = true;
		if (surname) newDisabled.surname = true;
		if (fatherName) newDisabled.fatherName = true;
		setDisabled(newDisabled);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<Row sizes="1fr*" gaps="16px*">
			<LabeledField label={t(`${path}.surname`) || ""}>
				<TextBox.TextBox
					disabled={disabled.surname}
					placeholder={t(`${path}.surname`) || ""}
					value={surname}
					onChange={setSurname}
				/>
			</LabeledField>
			<LabeledField label={t(`${path}.name`) || ""}>
				<TextBox.TextBox
					disabled={disabled.name}
					placeholder={t(`${path}.name`) || ""}
					value={name}
					onChange={setName}
				/>
			</LabeledField>
			<LabeledField label={t(`${path}.fatherName`) || ""}>
				<TextBox.TextBox
					disabled={disabled.fatherName}
					placeholder={t(`${path}.fatherName`) || ""}
					value={fatherName}
					onChange={setFatherName}
				/>
			</LabeledField>
		</Row>
	);
});

declare namespace FullName {
	interface Props {
		value: Value;
		onChange: (value: Value) => void;
	}

	interface Value {
		name?: string;
		surname?: string;
		fatherName?: string;
	}
}

export default FullName;
