import React, {
	Dispatch,
	SetStateAction,
	memo,
	useCallback,
	useMemo,
	useState,
} from "react";
import { Button, DateAndTimePicker, Icon, Row, TextBox } from "uikit";
import { useTranslation } from "react-i18next";
import moment from "moment";

import useDatePickerLocale from "../../../../../../../../../../../../../../hooks/useDatePickerLocale";
import ArchiveFilter from "../../../../../../../../../../../../../../types/ArchiveFilter";
import { DateRange } from "../../../../../../../../../../../../../../types/DataRange";

const Filters: React.FC<Filters.Props> = memo(({ filter, setFilter }) => {
	const { t } = useTranslation();
	const locale = useDatePickerLocale();

	const [internalDateRange, setInternalDateRange] = useState<DateRange>({
		from: moment().startOf("day").toDate(),
		to: moment().endOf("day").toDate(),
	});

	const changeDateTimeFromHandler = useCallback(
		(newValue) => {
			setInternalDateRange((prev) => ({ ...prev, from: newValue }));
		},
		[setInternalDateRange],
	);

	const changeDateTimeToHandler = useCallback(
		(newValue) => {
			setInternalDateRange((prev) => ({ ...prev, to: newValue }));
		},
		[setInternalDateRange],
	);

	const updateQueryHandler = useCallback(
		(searchQuery) => {
			setFilter((prev) => ({ ...prev, searchQuery }));
		},
		[setFilter],
	);

	const dateRangeHasChanges = useMemo(
		() =>
			filter.dateFrom.getTime() !== internalDateRange.from.getTime() ||
			filter.dateTo.getTime() !== internalDateRange.to.getTime(),
		[filter.dateFrom, filter.dateTo, internalDateRange],
	);

	const applyDateRange = useCallback(() => {
		setFilter((prev) => ({
			...prev,
			dateFrom: internalDateRange.from,
			dateTo: internalDateRange.to,
		}));
	}, [internalDateRange, setFilter]);

	return (
		<Row gaps="25px*" justify="end" align="center" style={{ flexGrow: 1 }}>
			<Row maxedWidth gaps="5px*" justify="end" align="center">
				{t(`from`, { context: "capitalized" })}
				<DateAndTimePicker
					value={internalDateRange.from}
					onChange={changeDateTimeFromHandler}
					locale={locale}
				/>
				{t(`to`)}
				<DateAndTimePicker
					value={internalDateRange.to}
					onChange={changeDateTimeToHandler}
					locale={locale}
				/>
				<Button.Button
					variant="primary"
					disabled={!dateRangeHasChanges}
					icon={<Icon id="search" size={20} />}
					onClick={applyDateRange}
				/>
			</Row>
			<Row gaps="5px*" align="center" sizes="240px! auto*">
				<TextBox.TextBox
					placeholder={`${t("search") ?? "Search"}...`}
					value={filter.searchQuery}
					onChange={updateQueryHandler}
				/>
				<Button.Button
					variant="secondary"
					icon={<Icon id="filters" size={20} />}
				/>
			</Row>
		</Row>
	);
});

declare namespace Filters {
	interface Props {
		filter: ArchiveFilter;
		setFilter: Dispatch<SetStateAction<ArchiveFilter>>;
	}
}

export default Filters;
