/* eslint-disable no-param-reassign */
/* eslint-disable no-shadow */

import React, { useMemo } from "react";
import { Flex } from "uikit";
import useChangeFormatter from "./hooks/useChangeFormatter";
import { Entry } from "../../../../../../../../../../../../components/HistoryTable/hooks/useFieldLocalizer";
import HistoryTable from "../../../../../../../../../../../../components/HistoryTable";
import TabRoot from "../TabRoot";
import Executor from "../../../../../../../../../../../../services/Executor";
import { SuspenseLoader } from "../../../../../../../../../../../../components/common";

const HistoryTab: React.FC<HistoryTab.Props> = ({ value, visible }) => {
	const formatChange = useChangeFormatter();

	const data = useMemo<HistoryTable.Item[]>(
		() =>
			value
				.map((item) => {
					const changes = item.changes
						.map((change) => formatChange(change))
						.filter((change) => !!change) as Entry[];

					return {
						...item,
						changes,
					};
				})
				.filter(
					(item) =>
						item.action !== "update" || item.changes.length !== 0,
				),
		[formatChange, value],
	);

	return (
		<TabRoot hasPaddings={false} visible={visible}>
			{/* <Column sizes="1fr" maxedWidth maxedHeight>
				<HistoryTable
					loading={false}
					data={data}
					onLoadMore={noop}
					tableSettingsSaveSlot="accounts.executors.modal.history"
				/>
			</Column> */}
			<Flex align="center" justify="center" maxedWidth maxedHeight>
				<SuspenseLoader />
			</Flex>
		</TabRoot>
	);
};

declare namespace HistoryTab {
	type Value = Executor.History;

	interface Props {
		value: Value;
		visible: boolean;
	}
}

export default HistoryTab;
