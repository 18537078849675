import styled from "styled-components";
import { theme } from "uikit";

const Error = styled.div`
	font-family: Lato;
	font-size: 13px;
	line-height: 13px;

	color: ${theme.colors.negative};
`;

export default Error;
