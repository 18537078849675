/* eslint-disable no-param-reassign */
/* eslint-disable no-shadow */

import * as ModelEvent from "@node-elion/syncron";

import SubscriptionPool from "../../redux/services/SubscriptionPool";
import { SortingOrder } from "../../types/SortingOrder";
import SubscriptionBase from "../../types/Subscription";
import Base from "../Base";
import Client from "../Client";
import Executor from "../Executor";
import Order from "../Order";

class ExecutorFeedback extends Base {
	static fromResponse(data: any): ExecutorFeedback.Model {
		return {
			id: data.id,

			client: data.customer
				? Client.fromResponse(data.customer)
				: undefined,
			order: data.order ? Order.fromResponse(data.order) : undefined,
			executor: data.executor
				? Executor.fromResponse(data.executor) || undefined
				: undefined,

			rating: data.rating,
			comment: data.comment,

			updatedAt: data.updatedAt,
			createdAt: data.createdAt,
			deletedAt: data.deletedAt,
		};
	}

	// static toRequest(
	// 	model: Subscription.Model.New | Subscription.Model.Modified,
	// ): any {
	// 	return {};
	// }

	public static async getAll(options: ExecutorFeedback.SubscribeOptions) {
		const data = await this.request((prpc) =>
			prpc.theirsModel.feedback.executor.getAll(
				ExecutorFeedback.optionsToRequest(options),
			),
		);

		return data.items.map(this.fromResponse);
	}

	// public static async store(object: Subscription.Model.New) {
	// 	const data = await this.request((prpc) =>
	// 		prpc.theirsModel.executorRate.create(
	// 			Subscription.toRequest(object),
	// 		),
	// 	);

	// 	return data;
	// }

	// public static async update(object: Subscription.Model.Modified) {
	// 	const data = await this.request((prpc) =>
	// 		prpc.theirsModel.executorRate.update(
	// 			object.id,
	// 			Subscription.toRequest(object),
	// 		),
	// 	);

	// 	return data;
	// }

	// public static async destroy(id: number[] | number) {
	// 	if (Array.isArray(id))
	// 		await Promise.all(id.map((id) => this.destroyOne(id)));
	// 	else await this.destroyOne(id);
	// }

	public static async getRating(executorId: number) {
		const rating: number = await this.request((prpc) =>
			prpc.theirsModel.feedback.executor.rating(executorId),
		);

		return rating;
	}

	public static async subscribe(
		options: ExecutorFeedback.SubscribeOptions,
		onUpdate: SubscriptionBase.OnUpdate<ExecutorFeedback.Model>,
	): Promise<SubscriptionBase<ExecutorFeedback.SubscribeOptions> | null> {
		const modelEventConstructor = new ModelEvent.ModelEventConstructor({
			onUpdate: (state) => {
				onUpdate({
					...state,

					models: state.models.map(this.fromResponse),
				});
			},
		});
		const subscription = await SubscriptionPool.add(
			(prpc) =>
				prpc.theirsModel.feedback.executor.subscribe({
					params: this.optionsToRequest(options),
					ping: () => true,
					onEvent: (event) => {
						modelEventConstructor.onEvent(event);
					},
					onError: (error) => {
						// eslint-disable-next-line no-console
						console.log(error);
					},
				}),
			{ name: "ExecutorFeedback.subscribe" },
		);

		return {
			unsubscribe: () => subscription.unsubscribe(),
			update: (options: ExecutorFeedback.SubscribeOptions) =>
				subscription.update(this.optionsToRequest(options)),
		} as SubscriptionBase<ExecutorFeedback.SubscribeOptions>;
	}

	// private static async destroyOne(id: number) {
	// 	this.request((prpc) => prpc.theirsModel.executorRate.delete(id));
	// }

	private static optionsToRequest(
		options: ExecutorFeedback.SubscribeOptions,
	) {
		return {
			limit: options.limit,
			offset: options.offset,

			query: {
				customerId: options.customerId,
				executorId: options.executorId,
				orderId: options.orderId,
			},

			order: options.order,
		};
	}
}

namespace ExecutorFeedback {
	export interface Model {
		id: number;

		client?: Client.Model;
		order?: Order.Model;
		executor?: Executor.Model;

		rating: number;
		comment: string;

		updatedAt: string;
		createdAt: string;
		deletedAt: string | null;
	}

	export interface SubscribeOptions {
		limit?: number;
		offset?: number;

		customerId?: number;
		executorId?: number;
		orderId?: number;

		order?: Partial<
			Record<
				| "id"
				| "executorId"
				| "customerId"
				| "orderId"
				| "rating"
				| "createdAt"
				| "updatedAt",
				SortingOrder
			>
		>;
	}

	export namespace Model {
		export interface New {}

		export type Modified = Partial<New> & { id: number };
	}
}

export default ExecutorFeedback;
