import React, {
	PropsWithChildren,
	memo,
	useCallback,
	useMemo,
	useState,
} from "react";

import Map from "../../../../../../services/Map";
import { StyledP, Popup } from "../../../../../common";
import {
	ValueAccessors,
	usePointOrderModalTableContext,
	Provider,
} from "../../context";
import PointOrderModalTableBase from "../..";
import { Settlement } from "../Settlement";
import BaseStreet, { Street } from "../Street";
import { House } from "../House";
import { Entrance } from "../Entrance";
import { Setting } from "../Setting";
import { HandleEventsProps } from "../../hooks";

const WrapItemBase: React.FC<WrapItemBase.Props> = ({
	rowData,
	rowIndex,
	dataKey,
	colIndex,
	width,
	setData,
	disabled,
	customerId,
	height,
	containerId,
	onDelete,
	onAddRow,
	countRow,
	searchTypes,
	taxiServiceId,
}) => {
	const {
		addStreet,
		getData,
		addSettlement,
		reload,
		rowId,
		reset,
		addSettlementCoordinates,
		addHouseCoordinates,
	} = usePointOrderModalTableContext();

	const [payload, setPayload] = useState<Provider.GetData>({
		street: undefined,
		settlement: undefined,
		settlementCoordinates: undefined,
		streetCoordinates: undefined,
		houseCoordinates: undefined,
	});

	const removeRow = useCallback(
		(id: number) => {
			if (onDelete) onDelete(id);
			reset(id);
			setPayload({
				street: undefined,
				settlement: undefined,
				settlementCoordinates: undefined,
				streetCoordinates: undefined,
				houseCoordinates: undefined,
			});
		},
		[onDelete, reset],
	);

	useMemo(() => {
		if (!rowData.raw.type || !rowData.raw.saveType) {
			setPayload({
				street: undefined,
				settlement: rowData.raw as unknown as Map.Search.Object,
				settlementCoordinates: rowData.raw.coordinates,
				streetCoordinates: undefined,
				houseCoordinates: undefined,
			});
		}
	}, [rowData.raw]);

	useMemo(() => {
		if (reload && rowData.raw.id === rowId) {
			const data = getData(rowData.raw.id);
			setPayload(data);
		}
	}, [getData, reload, rowData.raw.id, rowId]);

	const street = useMemo(() => payload.street, [payload.street]);
	const settlement = useMemo(() => payload.settlement, [payload.settlement]);
	const settlementCoordinates = useMemo(
		() => payload.settlementCoordinates,
		[payload.settlementCoordinates],
	);

	const offset = useMemo<Popup.Props["offset"]>(() => ({ x: 0, y: 28 }), []);
	const popoverWidth = useMemo(() => {
		if (width) return `${width}px`;
		return "50px";
	}, [width]);

	const styles = useMemo<Popup.Styles>(
		() => ({
			h: { max: "200px", min: "0px" },
			w: { min: popoverWidth },
		}),
		[popoverWidth],
	);

	const lastHouse = useMemo<HandleEventsProps>(
		() => ({
			nextElement:
				countRow === rowData.raw.id ? "" : `${rowData.raw.id}entrance`,
			externalElement:
				countRow === rowData.raw.id ? "driver-node-id" : "",
			arrowLeft: `${rowData.raw.id}street`,
			arrowRight: `${rowData.raw.id}entrance`,
		}),
		[countRow, rowData.raw.id],
	);

	const lastEntrance = useMemo<HandleEventsProps>(
		() => ({
			labels: ["entrance"],
			nextElement: `${rowData.raw.id + 1}street`,
			externalElement:
				countRow === rowData.raw.id
					? "driver-node-id"
					: `${rowData.raw.id + 1}street`,
			arrowLeft: `${rowData.raw.id}house`,
			arrowRight:
				countRow === rowData.raw.id
					? "driver-node-id"
					: `${rowData.raw.id + 1}street`,
		}),
		[countRow, rowData.raw.id],
	);

	const firstCity = useMemo<HandleEventsProps>(
		() => ({
			nextElement: `${rowData.raw.id}street`,
			arrowRight: `${rowData.raw.id}street`,
			arrowLeft:
				rowData.raw.id === 1
					? `customer-phone-id`
					: `${rowData.raw.id - 1}entrance`,
		}),
		[rowData.raw.id],
	);

	const streetOption = useMemo<HandleEventsProps>(
		() => ({
			nextElement: `${rowData.raw.id}house`,
			arrowLeft: `${rowData.raw.id}city`,
			arrowRight: `${rowData.raw.id}house`,
		}),
		[rowData.raw.id],
	);

	const settingSwitch = useMemo(
		() => ({
			nextElement: `${rowData.raw.id + 1}street`,
			externalElement: "driver-node-id",
			initialElement: `${rowData.raw.id}street`,
		}),
		[rowData.raw.id],
	);

	const element = useMemo(() => {
		if (disabled) {
			return (
				<StyledP
					w="100%"
					h="100%"
					justify="start"
					p="0 6px"
					alignItems="center"
					textAlign="left"
					colors="#21333f"
					font={{
						ff: "Inter Lato",
						fw: "400",
						size: "12px",
						line: "16px",
					}}
					text={{ tt: "uppercase" }}
				>{`${rowData[dataKey] || ""}`}</StyledP>
			);
		}

		switch (dataKey as ValueAccessors) {
			case "city":
				return (
					<Settlement
						height={height}
						rowIndex={rowIndex}
						rowData={rowData}
						dataKey={dataKey}
						colIndex={colIndex}
						offset={offset}
						styles={styles}
						setData={setData}
						customerId={customerId}
						containerId={containerId}
						addSettlement={addSettlement}
						addSettlementCoordinates={addSettlementCoordinates}
						handleEventsOptions={firstCity}
					/>
				);

			case "street":
				return (
					<Street
						height={height}
						rowIndex={rowIndex}
						rowData={rowData}
						dataKey={dataKey}
						colIndex={colIndex}
						offset={offset}
						styles={styles}
						setData={setData}
						customerId={customerId}
						containerId={containerId}
						addStreet={addStreet}
						settlementCoordinates={settlementCoordinates}
						settlement={settlement}
						addSettlement={addSettlement}
						addSettlementCoordinates={addSettlementCoordinates}
						street={street}
						handleEventsOptions={streetOption}
						searchTypes={searchTypes}
						taxiServiceId={taxiServiceId}
					/>
				);

			case "house":
				return (
					<House
						height={height}
						rowIndex={rowIndex}
						rowData={rowData}
						dataKey={dataKey}
						colIndex={colIndex}
						offset={offset}
						styles={styles}
						street={street}
						setData={setData}
						customerId={customerId}
						containerId={containerId}
						handleEventsOptions={lastHouse}
						addHouseCoordinates={addHouseCoordinates}
						countRow={countRow}
					/>
				);

			case "entrance":
				return (
					<Entrance
						height={height}
						rowIndex={rowIndex}
						rowData={rowData}
						dataKey={dataKey}
						colIndex={colIndex}
						setData={setData}
						handleEventsOptions={lastEntrance}
					/>
				);

			case "polygon":
				return (
					<StyledP
						w="100%"
						h={`${height}px`}
						justify="start"
						p="0 6px"
						alignItems="center"
						textAlign="left"
						colors="#21333f"
						font={{
							ff: "Inter Lato",
							fw: "400",
							size: "12px",
							line: "16px",
						}}
						text={{ tt: "uppercase" }}
					>
						{`${rowData[dataKey] || ""}`}
					</StyledP>
				);

			case "setting":
				return (
					<Setting
						rowData={rowData}
						onDelete={removeRow}
						onAddRow={onAddRow}
						countRow={countRow}
						disabled={disabled}
						handleEventsOptions={settingSwitch}
					/>
				);

			default:
				return (
					<StyledP
						w="100%"
						h={`${height}px`}
						justify="start"
						p="0 6px"
						alignItems="center"
						textAlign="left"
						colors="#21333f"
						font={{
							ff: "Inter Lato",
							fw: "400",
							size: "12px",
							line: "16px",
						}}
					>
						{`${rowData[dataKey] || ""}`}
					</StyledP>
				);
		}
	}, [
		disabled,
		dataKey,
		rowData,
		height,
		rowIndex,
		colIndex,
		offset,
		styles,
		setData,
		customerId,
		containerId,
		addSettlement,
		addSettlementCoordinates,
		firstCity,
		addStreet,
		settlementCoordinates,
		settlement,
		street,
		streetOption,
		searchTypes,
		lastHouse,
		addHouseCoordinates,
		lastEntrance,
		removeRow,
		onAddRow,
		countRow,
		settingSwitch,
		taxiServiceId,
	]);

	return <>{element}</>;
};

declare namespace WrapItemBase {
	interface Props extends PropsWithChildren {
		rowData: PointOrderModalTableBase.OrderModal;
		setData: (data: PointOrderModalTableBase.OrderModal) => void;
		onAddRow?: (num: number) => void;
		onDelete?: (id: number) => void;
		rowIndex?: number;
		dataKey: ValueAccessors | string;
		colIndex: number;
		width?: number;
		height?: number;
		disabled?: boolean;
		customerId?: number;
		containerId?: string;
		countRow: number;
		searchTypes?: BaseStreet.Props["searchTypes"];
		taxiServiceId?: BaseStreet.Props["taxiServiceId"];
	}
}

export const WrapItem = memo(WrapItemBase);

export default WrapItemBase;
