import React, {
	PropsWithChildren,
	createContext,
	memo,
	useContext,
	useMemo,
} from "react";

import Executor from "../../../../../../../../../services/Executor";
import ExecutorGroup from "../../../../../../../../../services/ExecutorGroup";
import ExecutorRate from "../../../../../../../../../services/ExecutorRate";
import TaxiService from "../../../../../../../../../services/TaxiService2";
import Role from "../../../../../../../../../services/Role";
import { Service } from "../../../../../../../../../services";
import useModelSubscribe from "../../../../../../../../../hooks/useModelSubscribe2";
import { StyledRow } from "../../../../../../../../../components/common";
import { useTypedSelector } from "../../../../../../../../../redux/store";

export const ExecutorContext = createContext<ExecutorProvider.Context | null>(
	null,
);

export const useExecutorContext = (): ExecutorProvider.Context => {
	const store = useContext<ExecutorProvider.Context | null>(ExecutorContext);
	if (!store) {
		throw new Error("Missing ExecutorContext.Provider in the tree");
	}
	return store;
};

export const ExecutorProvider: React.FC<ExecutorProvider.Props> = ({
	children,
	id = "executor_provider_id",
	taxiServiceIds = [],
}): JSX.Element => {
	const services = useTypedSelector(
		(state) => state.preferencesReducer.services.data.models,
	);
	const subscribeExecutor = useModelSubscribe({}, Executor);
	const subscribeTaxiService = useModelSubscribe({}, TaxiService);
	const subscribeRole = useModelSubscribe({ assignableTo: "executor" }, Role);
	const subscribeExecutorsGroup = useModelSubscribe(
		{ taxiServiceIds },
		ExecutorGroup,
	);
	const subscribeExecutorRate = useModelSubscribe(
		{ taxiServiceIds },
		ExecutorRate,
	);

	const executorRole = useMemo<Role.Model[]>(
		() => subscribeRole.models,
		[subscribeRole.models],
	);

	const executorRate = useMemo<ExecutorRate.Model[]>(
		() => subscribeExecutorRate.models,
		[subscribeExecutorRate.models],
	);

	const executorsGroup = useMemo<ExecutorGroup.Model[]>(
		() => subscribeExecutorsGroup.models ?? [],
		[subscribeExecutorsGroup.models],
	);

	const executors = useMemo<Executor.Model[]>(
		() => subscribeExecutor.models ?? [],
		[subscribeExecutor.models],
	);

	const taxiServices = useMemo<TaxiService.Model[]>(
		() => subscribeTaxiService.models ?? [],
		[subscribeTaxiService.models],
	);

	const provideValue = useMemo(
		() => ({
			executors,
			taxiServices,
			executorsGroup,
			executorRate,
			executorRole,
			services,
		}),
		[
			executorRate,
			executorRole,
			executors,
			executorsGroup,
			taxiServices,
			services,
		],
	);

	return (
		<ExecutorContext.Provider value={provideValue}>
			<StyledRow position="relative" w="100%" h="100%" id={id}>
				{children}
			</StyledRow>
		</ExecutorContext.Provider>
	);
};

declare namespace ExecutorProvider {
	type Value = Omit<Executor.Model, "id"> & { id?: number };
	export interface Props extends PropsWithChildren {
		id?: string;
		taxiServiceIds?: number[];
	}

	interface Context {
		taxiServices: TaxiService.Model[];
		executors: Executor.Model[];
		executorsGroup: ExecutorGroup.Model[];
		executorRate: ExecutorRate.Model[];
		executorRole: Role.Model[];
		services: Service.Model[];
	}

	namespace Context {}
}

export const ExecutorProviderMemo = memo(ExecutorProvider);
