import React from "react";
import { Icon, theme } from "uikit";
import Root from "./components/Root";
import Label from "./components/Label";

const Button: React.FC<Button.Props> = ({ icon, label, onClick }) => (
	<Root align="center" gaps="5px" onClick={onClick}>
		<Icon id={icon} size={12} colors={[theme.colors.secondary]} />
		<Label>{label}</Label>
	</Root>
);

declare namespace Button {
	interface Props {
		icon: Icon.Props["id"];
		label: string;

		onClick?: () => void;
	}
}

export default Button;
