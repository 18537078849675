/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable no-shadow */

import React, {
	Dispatch,
	Key,
	RefAttributes,
	memo,
	useCallback,
	useMemo,
	useState,
} from "react";
import { useTranslation } from "react-i18next";
import {
	CheckBoxWithContent,
	Column,
	MultilineTextBox,
	Row,
	Select,
	Stepper,
	TextBox,
	react,
	useRefWithSetter,
} from "uikit";
import Language from "../../../../../../../../../../../../services/Language";
import WorkShift from "../../../../../../../../../../../../services/WorkShift";
import useObjectEditor from "../../../../../../../../../../../../hooks/useObjectEditor";

import LabeledField from "../../../../../../../../../../../../components/LabeledField";
import FieldsContainer from "../../../../../../../../../../../../components/FieldsContainer";

import TabRoot from "../TabRoot";
import { openAndCloseTypeOptions } from "./constants";
import Access from "./components/Access";
import Button from "./components/Button";
import InternalController from "./Controller";

const MainTab = memo(
	react.withController<MainTab.PropsBase, MainTab.Controller>(
		({ controller, value, disabled, visible, onChange }) => {
			const { t } = useTranslation();

			const [accessRef, setAccessRef] =
				useRefWithSetter<Access.Ref | null>(null);

			const valueEditor = useObjectEditor(value, onChange);

			const nameValue = valueEditor.useGetter("name");

			const [nameError, setNameError] = useState(false);

			const nameOnChange = useCallback(
				(name: string) => {
					setNameError(false);

					valueEditor.set("name", name);
				},
				// eslint-disable-next-line react-hooks/exhaustive-deps
				[valueEditor.set],
			);

			const [openAndCloseTypeError, setOpenAndCloseTypeError] =
				useState(false);

			const openAndCloseTypeValue =
				valueEditor.useGetter("openAndCloseType");

			const setOpenAndCloseType =
				valueEditor.useSetter("openAndCloseType");

			const openAndCloseTypeOnChange = useCallback(
				(value: WorkShift.OpenAndCloseType) => {
					setOpenAndCloseTypeError(false);

					setOpenAndCloseType(value);
				},
				[setOpenAndCloseType],
			);

			const accessValue = valueEditor.usePicker([
				"companyIds",
				"taxiServiceIds",
			]);

			const accessOnChange = valueEditor.useAssigner();

			const isSpentMinuteRangeEnabledValue = valueEditor.useGetter(
				"isSpentMinuteRangeEnabled",
			);

			const isSpentMinuteRangeEnabledOnChange = valueEditor.useSetter(
				"isSpentMinuteRangeEnabled",
			);

			const toggleIsSpentMinuteRangeEnabled = valueEditor.useToggler(
				"isSpentMinuteRangeEnabled",
			);

			const minimumMinutesSpentValue = valueEditor.useGetter(
				"minimumMinutesSpent",
			);

			const minimumMinutesSpentOnChange = valueEditor.useSetter(
				"minimumMinutesSpent",
			);

			const maximumMinutesSpentValue = valueEditor.useGetter(
				"maximumMinutesSpent",
			);

			const maximumMinutesSpentOnChange = valueEditor.useSetter(
				"maximumMinutesSpent",
			);

			const isLunchTimeEnabledValue =
				valueEditor.useGetter("isLunchTimeEnabled");

			const isLunchTimeEnabledOnChange =
				valueEditor.useSetter("isLunchTimeEnabled");

			const lunchTimeValue = valueEditor.useGetter("lunchTime");

			const lunchTimeOnChange = valueEditor.useSetter("lunchTime");

			const descriptionValue = valueEditor.useGetter("description");

			const descriptionOnChange = valueEditor.useSetter("description");

			controller.setContext({
				value,

				setNameError,
				setOpenAndCloseTypeError,

				accessRef,
			});

			const firstRow = useMemo(
				() => (
					<Row gaps="8px*" sizes="1fr*">
						<LabeledField
							label={
								t(
									"pages.mainPage.pages.accounts.tabs.workShifts.editModal.content.mainTab.str0",
								) ?? ""
							}
						>
							<TextBox.TextBox
								value={nameValue}
								disabled={disabled}
								style={{
									minHeight: "32px",
									flex: "1 0 0",
								}}
								autoComplete="one-time-code"
								placeholder={
									t(
										"pages.mainPage.pages.accounts.tabs.workShifts.editModal.content.mainTab.str0",
									) ?? ""
								}
								error={nameError}
								onChange={nameOnChange}
							/>
						</LabeledField>
						<Access
							ref={setAccessRef}
							value={accessValue}
							disabled={disabled}
							language={"uk"}
							onChange={accessOnChange}
						/>
					</Row>
				),
				[
					accessOnChange,
					accessValue,
					disabled,
					nameError,
					nameOnChange,
					nameValue,
					setAccessRef,
					t,
				],
			);

			const secondRow = useMemo(
				() => (
					<Row gaps="8px*" sizes="1fr 2fr">
						<LabeledField
							label={
								t(
									"pages.mainPage.pages.accounts.tabs.workShifts.editModal.content.mainTab.str200",
								) ?? ""
							}
						>
							<Select
								value={openAndCloseTypeValue}
								// disabled={disabled}
								disabled
								style={{
									minHeight: "32px",
									flex: "1 0 0",
								}}
								placeholder={
									t(
										"pages.mainPage.pages.accounts.tabs.workShifts.editModal.content.mainTab.str200",
									) ?? ""
								}
								error={openAndCloseTypeError}
								options={openAndCloseTypeOptions}
								onChange={
									openAndCloseTypeOnChange as Dispatch<Key>
								}
							/>
						</LabeledField>
					</Row>
				),
				[
					openAndCloseTypeError,
					openAndCloseTypeOnChange,
					openAndCloseTypeValue,
					t,
				],
			);

			return (
				<TabRoot hasPaddings visible={visible}>
					<Column
						sizes="auto!* 1fr"
						gaps="19px*"
						maxedWidth
						maxedHeight
					>
						<FieldsContainer
							label={
								t(
									"pages.mainPage.pages.accounts.tabs.workShifts.editModal.content.mainTab.str2",
								) ?? ""
							}
						>
							<Column gaps="8px*">
								{firstRow}
								{secondRow}
							</Column>
						</FieldsContainer>
						<FieldsContainer
							label={
								t(
									"pages.mainPage.pages.accounts.tabs.workShifts.editModal.content.mainTab.str3",
								) ?? ""
							}
						>
							<Column gaps="10px*">
								<Row
									align="center"
									gaps="10px*"
									sizes="auto! 70px! auto! 70px"
								>
									<CheckBoxWithContent
										style={{
											width: "354.38px",
											minWidth: "max-content",
										}}
										gap="10px"
										value={isSpentMinuteRangeEnabledValue}
										// disabled={disabled}
										disabled
										onChange={
											isSpentMinuteRangeEnabledOnChange
										}
									>
										{t(
											"pages.mainPage.pages.accounts.tabs.workShifts.editModal.content.mainTab.str202",
										) ?? ""}
										,
										{t(
											"pages.mainPage.pages.accounts.tabs.workShifts.editModal.content.mainTab.str203",
										) ?? ""}
										:
									</CheckBoxWithContent>
									<Stepper
										value={minimumMinutesSpentValue}
										// disabled={
										// 	!isSpentMinuteRangeEnabledValue ||
										// 	disabled
										// }
										disabled
										min={0}
										onChange={(newValue) => {
											if (newValue != null)
												minimumMinutesSpentOnChange(
													newValue,
												);
										}}
									/>
									<Button
										onClick={
											toggleIsSpentMinuteRangeEnabled
										}
									>
										{t(
											"pages.mainPage.pages.accounts.tabs.workShifts.editModal.content.mainTab.str204",
										) ?? ""}
										:
									</Button>
									<Stepper
										value={maximumMinutesSpentValue}
										// disabled={
										// 	!isSpentMinuteRangeEnabledValue ||
										// 	disabled
										// }
										disabled
										min={0}
										onChange={(newValue) => {
											if (newValue != null)
												maximumMinutesSpentOnChange(
													newValue,
												);
										}}
									/>
								</Row>
								<Row
									align="center"
									gaps="10px*"
									sizes="auto! 70px!"
								>
									<CheckBoxWithContent
										value={isLunchTimeEnabledValue}
										// disabled={disabled}
										disabled
										gap="10px"
										onChange={isLunchTimeEnabledOnChange}
									>
										{t(
											"pages.mainPage.pages.accounts.tabs.workShifts.editModal.content.mainTab.str205",
										) ?? ""}
									</CheckBoxWithContent>
									<Stepper
										value={lunchTimeValue}
										// disabled={
										// 	!isLunchTimeEnabledValue || disabled
										// }
										disabled
										min={0}
										onChange={(newValue) => {
											if (newValue != null)
												lunchTimeOnChange(newValue);
										}}
									/>
								</Row>
								<CheckBoxWithContent disabled gap="10px">
									<Row align="center" style={{ height: 32 }}>
										{t(
											"pages.mainPage.pages.accounts.tabs.workShifts.editModal.content.mainTab.str206",
										) ?? ""}
									</Row>
								</CheckBoxWithContent>
								<CheckBoxWithContent disabled gap="10px">
									<Row align="center" style={{ height: 32 }}>
										{t(
											"pages.mainPage.pages.accounts.tabs.workShifts.editModal.content.mainTab.str152",
										) ?? ""}
									</Row>
								</CheckBoxWithContent>
								<CheckBoxWithContent disabled gap="10px">
									<Row align="center" style={{ height: 32 }}>
										{t(
											"pages.mainPage.pages.accounts.tabs.workShifts.editModal.content.mainTab.str153",
										) ?? ""}
									</Row>
								</CheckBoxWithContent>
								<CheckBoxWithContent disabled gap="10px">
									<Row align="center" style={{ height: 32 }}>
										{t(
											"pages.mainPage.pages.accounts.tabs.workShifts.editModal.content.mainTab.str207",
										) ?? ""}
									</Row>
								</CheckBoxWithContent>
							</Column>
						</FieldsContainer>
						<MultilineTextBox
							value={descriptionValue}
							disabled={disabled}
							placeholder={
								t(
									"pages.mainPage.pages.accounts.tabs.workShifts.editModal.content.mainTab.str4",
								) ?? ""
							}
							onChange={descriptionOnChange}
						/>
					</Column>
				</TabRoot>
			);
		},
		InternalController,
	),
);

declare namespace MainTab {
	type Ref = InternalController | null;

	type Controller = InternalController;

	interface Value extends Access.Value {
		name: string;
		openAndCloseType?: WorkShift.OpenAndCloseType;

		isSpentMinuteRangeEnabled: boolean;
		minimumMinutesSpent: number;
		maximumMinutesSpent: number;

		isLunchTimeEnabled: boolean;
		lunchTime: number;

		isSchedulesEnabled: boolean;

		default: boolean;

		description: string;
	}

	interface PropsBase {
		value: Value;

		disabled: boolean;
		visible: boolean;
		language: Language;

		onChange: Dispatch<Value>;
	}

	type Props = PropsBase & RefAttributes<Ref>;
}

export default MainTab;
