import React, { useState } from "react";
import { Button, Icon, Float, react } from "uikit";
import Popover from "./components/Popover";

declare namespace PopupFilter {
	type Value = Popover.Value;

	interface PropsBase {
		disabled?: boolean;
	}
}

const PopupFilter = react.inputify<PopupFilter.PropsBase, PopupFilter.Value>(
	({ value, onChange, disabled }) => {
		const [open, setOpen] = useState(false);

		return (
			<>
				<Float.Tracker id="filters">
					<Button.Button
						disabled={disabled}
						variant="secondary"
						icon={<Icon id="filters" size={20} />}
						onClick={() => setOpen(true)}
					/>
				</Float.Tracker>

				<Popover
					value={value}
					onChange={onChange}
					open={open}
					onClose={() => setOpen(false)}
				/>
			</>
		);
	},
);

export default PopupFilter;
