import React, { Dispatch, useMemo } from "react";
import { useTranslation } from "react-i18next";

import CheckBoxSelect from "../../../../../../../../../../../../../../components/CheckBoxSelect";

import { getOptions, OptionsKey } from "./constants";

const OrderPaymentTypes: React.FC<OrderPaymentTypes.Props> = ({
	value,
	// disabled,
	onChange,
}) => {
	const { t } = useTranslation();

	const options = useMemo(() => getOptions(t), [t]);

	return (
		<CheckBoxSelect
			value={value}
			disabled
			title={
				t(
					"pages.mainPage.pages.accounts.tabs.executors.editModal.content.optionTab.orderPaymentTypes.str0",
				) ?? ""
			}
			options={options}
			onChange={onChange}
		/>
	);
};

declare namespace OrderPaymentTypes {
	type Value = OptionsKey[];

	interface Props {
		value: Value;

		disabled: boolean;

		onChange: Dispatch<Value>;
	}
}

export default OrderPaymentTypes;
