import React, {
	Dispatch,
	SetStateAction,
	memo,
	useCallback,
	useEffect,
	useMemo,
	useState,
} from "react";
import { useTranslation } from "react-i18next";
import { Column, MultilineTextBox, Option, Row, Select } from "uikit";
import styled from "styled-components";
import SMSTemplate from "../../../../../../../../../../../../../../services/SMSTemplate";

import mapByKey from "../../../../../../../../../../../../../../utils/mapByKey";
import { ModelId } from "../../../../../../../../../../../../../../types/ModelId";
import useObjectEditor from "../../../../../../../../../../../../../../hooks/useObjectEditor";
import LabeledField from "../../../../../../../../../../../../../../components/LabeledField";
import { TEMPLATE_MISSING } from "../../defaultValue";

const Count = styled(Row)`
	position: absolute;
	top: -20px;
	right: 0;
	opacity: 0.8;
`;

const Template: React.FC<Template.Props> = memo(
	({ error, setError, templates, value, onChange }) => {
		const { t } = useTranslation();
		const teamplateOptions = useMemo<Option<number>[]>(() => {
			const options = templates.map((t) => ({
				key: t.id,
				value: t.id,
				label: t.name,
			}));
			options.unshift({
				key: TEMPLATE_MISSING,
				value: TEMPLATE_MISSING,
				label:
					t(
						"pages.mainPage.pages.accounts.tabs.executors.header.sendingSMS.modal.template.str200",
					) ?? "",
			});
			return options;
		}, [templates, t]);

		const templateById = useMemo(
			() => mapByKey(templates, "id"),
			[templates],
		);

		const editor = useObjectEditor(value, onChange);

		const templateId = editor.useGetter("smsTemplateId");
		const setTemplateId = editor.useSetter("smsTemplateId");

		const text = editor.useGetter("text");
		const setText = editor.useSetter("text");
		const [customText, setCustomText] = useState("");

		const handleTemplateIdChange = useCallback(
			(newTemplateId) => {
				setError((prev) => ({ ...prev, templateId: false }));
				setTemplateId(newTemplateId);
				if (newTemplateId !== TEMPLATE_MISSING) {
					setText(templateById[newTemplateId].templateText);
				} else {
					setText(customText);
				}
			},
			[customText, setError, setTemplateId, setText, templateById],
		);

		useEffect(() => {
			setText(customText);
			if (customText) {
				setError((prev) => ({ ...prev, text: false }));
			}
		}, [customText, setError, setText]);

		return (
			<Column gaps="15px*" style={{ flexGrow: 1 }}>
				<LabeledField
					label={
						t(
							"pages.mainPage.pages.accounts.tabs.executors.header.sendingSMS.modal.template.str0",
						) ?? ""
					}
				>
					<Select
						error={error.templateId}
						placeholder={`${
							t(
								"pages.mainPage.pages.accounts.tabs.executors.header.sendingSMS.modal.template.str201",
							) ?? ""
						} SMS ${
							t(
								"pages.mainPage.pages.accounts.tabs.executors.header.sendingSMS.modal.template.str202",
							) ?? ""
						}`}
						options={teamplateOptions}
						value={templateId ?? undefined}
						onChange={handleTemplateIdChange}
					/>
				</LabeledField>
				<LabeledField
					label={
						t(
							"pages.mainPage.pages.accounts.tabs.executors.header.sendingSMS.modal.template.str1",
						) ?? ""
					}
					style={{ flex: "1 1 auto" }}
				>
					<Row
						style={{ flex: "1 1 auto", position: "relative" }}
						sizes="auto 1fr"
					>
						<Count gaps="10px*">
							<Row gaps="5px*">
								<div>
									{t(
										"pages.mainPage.pages.accounts.tabs.executors.header.sendingSMS.modal.template.str203",
									) ?? ""}
									:
								</div>
								<div>{text?.length}</div>
							</Row>
							<Row gaps="5px*">
								<div>
									{t(
										"pages.mainPage.pages.accounts.tabs.executors.header.sendingSMS.modal.template.str204",
									) ?? ""}{" "}
									SMS:
								</div>
								<div>{text?.split("\n").length}</div>
							</Row>
						</Count>
						<MultilineTextBox
							error={error.text}
							placeholder={
								t(
									"pages.mainPage.pages.accounts.tabs.executors.header.sendingSMS.modal.template.str2",
								) ?? ""
							}
							disabled={templateId !== TEMPLATE_MISSING}
							value={text}
							onChange={setCustomText}
						/>
					</Row>
				</LabeledField>
			</Column>
		);
	},
);

declare namespace Template {
	interface Props {
		error: Error;
		setError: Dispatch<SetStateAction<Error>>;
		templates: SMSTemplate.Model[];
		value: Value;
		onChange: (x: Value) => void;
	}

	type Value = { smsTemplateId?: number; text?: string } & (
		| {
				smsTemplateId: ModelId;
		  }
		| { text: string }
	);

	interface Error {
		templateId: boolean;
		text: boolean;
	}
}

export default Template;
