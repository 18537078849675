import Modal from "../components/Modal";

const defaultValue: Modal.Value = {
	active: true,
	paymentAccounts: [],
	personalFiles: [],
	otherFiles: [],
	additionalFields: {
		contactName: "",
		name: "",
		edrpou: "",
		phones: [],
		address: "",
		email: "",
		deactivationThreshold: {
			value: 0,
			description: "",
		},
	},
	paymentTransactions: {
		main: [],
		threshold: [
			{
				amount: 0,
				description: "",
			},
		],
		bonus: [],
	},
	companyId: 0,
	defaultTaxiServiceId: 0,
	counterpartyGroupId: undefined,
	isAccessToCorporateAccount: false,
	accessAllEmployeeToPersonalApp: false,
	addCustomerAsEmployeeAfterOrder: false,
	allowGeneralBonusBalance: false,
	ignoreGeneralThresholdBalance: false,
	checks: [],
};

export default defaultValue;
