import React, {
	Dispatch,
	RefAttributes,
	memo,
	useCallback,
	useMemo,
	useState,
} from "react";
import { Option, Select, react } from "uikit";
import { Skeleton } from "@mui/material";
import { useTranslation } from "react-i18next";
import InternalController from "./Controller";
import LabeledField from "../../../../../../../../../../../../../../../../components/LabeledField";
import useModelSubscribe from "../../../../../../../../../../../../../../../../hooks/useModelSubscribe";
import { useTypedSelector } from "../../../../../../../../../../../../../../../../redux/store";
import Company from "../../../../../../../../../../../../../../../../services/Company";
import tPath from "../../../../../../../../../../constants/tPath";

declare namespace CompanySelect {
	type Ref = InternalController;
	type Controller = InternalController;
	interface PropsBase {
		disabled: boolean;
		value: Value;
		onChange: Dispatch<number>;
	}
	type Props = PropsBase & RefAttributes<Ref>;

	type Value = number;
}

const CompanySelectBase = react.withController<
	CompanySelect.PropsBase,
	CompanySelect.Controller
>(({ controller, disabled, value, onChange }) => {
	const { t } = useTranslation();
	const lang = useTypedSelector((state) => state.session.language);
	const companies = useModelSubscribe({}, Company);

	const options = useMemo<Option<Company.Model["id"]>[]>(
		() =>
			companies?.cache.map((company) => ({
				key: company.id,
				label: company.name[lang],
				value: company.id,
			})) || [],
		[companies?.cache, lang],
	);

	const [companyError, setCompanyError] = useState(false);

	const onCompanyIdChange = useCallback(
		(newValue) => {
			onChange(newValue);
			setCompanyError(false);
		},
		[onChange],
	);

	controller.setContext({
		value,
		setCompanyError,
	});

	return (
		<LabeledField label={t(`${tPath}.modal.tabs.main.other.company`) || ""}>
			{!companies ? (
				<Skeleton variant="rectangular" width={275.5} height={32} />
			) : (
				<Select
					disabled={disabled}
					value={value}
					onChange={onCompanyIdChange}
					options={options}
					error={companyError}
					placeholder={
						t(`${tPath}.modal.tabs.main.other.chooseCompany`) || ""
					}
				/>
			)}
		</LabeledField>
	);
}, InternalController);

const CompanySelect = memo(CompanySelectBase);

export default CompanySelect;
