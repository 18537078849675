import React from "react";
import { useTranslation } from "react-i18next";
import { Column, Row, Stepper, CheckBox } from "uikit";

import { useCurrencyGlobalSettings } from "../../../../../../../../../../../../../../hooks";
import FieldsContainer from "../../../../../../../../../../../../../../components/FieldsContainer";
import { ValueStepper } from "../../../../../../../../../../../../../../components/common";

const marginLeft = { marginLeft: 30 };
const mainColGaps = "12px*";
const rowGaps = "8px*";

const GeneralBlock: React.FC<GeneralBlock.Props> = () => {
	const { t } = useTranslation();
	const currencyGlobalSettings = useCurrencyGlobalSettings();
	return (
		<Column style={marginLeft} gaps={mainColGaps}>
			<Column gaps={mainColGaps}>
				<FieldsContainer
					label={
						t(
							"pages.mainPage.pages.accounts.tabs.executorTeams.modal.content.tabs.orderCancellation.generalBlock.str0",
						) ?? ""
					}
				>
					<Column gaps="8px*">
						<Column gaps={mainColGaps}>
							<CheckBox
								disabled
								label={
									t(
										"pages.mainPage.pages.accounts.tabs.executorTeams.modal.content.tabs.orderCancellation.generalBlock.str1",
									) ?? ""
								}
							/>
							<CheckBox
								disabled
								label={
									t(
										"pages.mainPage.pages.accounts.tabs.executorTeams.modal.content.tabs.orderCancellation.generalBlock.str2",
									) ?? ""
								}
							/>
						</Column>
						<Column
							style={{
								...marginLeft,
								maxWidth: "max-content",
							}}
							gaps="8px*"
						>
							<Row
								gaps={rowGaps}
								align="center"
								justify="space-between"
							>
								<CheckBox
									disabled
									label={
										t(
											"pages.mainPage.pages.accounts.tabs.executorTeams.modal.content.tabs.orderCancellation.generalBlock.str3",
										) ?? ""
									}
								/>
								<Row gaps={rowGaps} align="center">
									<ValueStepper disabled />
									<span>{currencyGlobalSettings}</span>
								</Row>
							</Row>
							<Row gaps={rowGaps} align="center">
								<CheckBox
									disabled
									label={
										t(
											"pages.mainPage.pages.accounts.tabs.executorTeams.modal.content.tabs.orderCancellation.generalBlock.str4",
										) ?? ""
									}
								/>
								<Stepper disabled />
								<span>
									{t(
										"pages.mainPage.pages.accounts.tabs.executorTeams.modal.content.tabs.orderCancellation.generalBlock.str100",
									) ?? ""}
								</span>
								<Stepper disabled />
								<span>
									{t(
										"pages.mainPage.pages.accounts.tabs.executorTeams.modal.content.tabs.orderCancellation.generalBlock.str101",
									) ?? ""}
								</span>
								<Row
									gaps={rowGaps}
									align="center"
									style={{ marginLeft: 20 }}
								>
									<ValueStepper disabled />
									<span>{currencyGlobalSettings}</span>
								</Row>
							</Row>
						</Column>
					</Column>
				</FieldsContainer>
			</Column>
		</Column>
	);
};

declare namespace GeneralBlock {
	interface Props {}
}

export default GeneralBlock;
