import React, { RefAttributes, memo, useCallback, useState } from "react";
import { MultilineTextBox, react } from "uikit";
import { useTranslation } from "react-i18next";
import InternalController from "./Controller";
import LabeledField from "../../../../../../../../../../../../../../components/LabeledField";
import tPath from "../../../../../../../../constants/tPath";

const path = `${tPath}.modal.tabs.main.description`;

const DescriptionBase = react.withController<
	Description.PropsBase,
	Description.Controller
>(({ description, setDescription, controller }) => {
	const [error, setError] = useState(false);
	controller.setContext({ value: description, setError });

	const { t } = useTranslation();

	const handleDescriptionChange = useCallback(
		(newDescription: string) => {
			setError(false);
			setDescription(newDescription);
		},
		[setDescription],
	);

	return (
		<LabeledField label={t(`${path}.title`) || ""}>
			<MultilineTextBox
				error={error && (t(`${path}.error`) as string)}
				placeholder={t(`${path}.placeholder`) || ""}
				value={description ?? undefined}
				onChange={handleDescriptionChange}
			/>
		</LabeledField>
	);
}, InternalController);

const Description = memo(DescriptionBase);

declare namespace Description {
	type Ref = InternalController | null;
	type Controller = InternalController;

	interface PropsBase {
		description: Value;
		setDescription: (x: Value) => void;
	}
	type Props = PropsBase & RefAttributes<Ref>;

	type Value = string | undefined;
}

export default Description;
