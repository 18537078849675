import React, { memo, useCallback, useMemo, useState } from "react";
import { Button, Icon } from "uikit";
import useModelSubscribe from "../../../../../../../../../../hooks/useModelSubscribe2";
import SMSProvider from "../../../../../../../../../../services/SMSProvider";
import Modal from "./components/Modal";
import SMSTemplate from "../../../../../../../../../../services/SMSTemplate";
import Message from "../../../../../../../../../../services/Message";

interface Props {
	executors: Modal.Props["executors"];
}

const SendingSMS: React.FC<Props> = memo(({ executors }) => {
	const { models: providers } = useModelSubscribe({}, SMSProvider);
	const { models: templates } = useModelSubscribe({}, SMSTemplate);

	const notOrdersTemplates = useMemo(
		() => templates.filter((t) => !t.orderTemplate),
		[templates],
	);

	const [showModal, setShowModal] = useState(false);

	const handleShowModal = useCallback(() => {
		setShowModal(true);
	}, []);

	const handleCloseModal = useCallback(() => {
		setShowModal(false);
	}, []);

	const handleSend = useCallback(async (value: Message.Send) => {
		const res = await Message.send(value);
		if (res) {
			setShowModal(false);
		}
	}, []);

	return (
		<div>
			<Button.Button
				disabled={executors.length === 0}
				variant="secondary"
				onClick={handleShowModal}
				icon={<Icon id="sms" size={20} />}
			/>
			{showModal && (
				<Modal
					executors={executors}
					providers={providers}
					templates={notOrdersTemplates}
					onClose={handleCloseModal}
					onSubmit={handleSend}
				/>
			)}
		</div>
	);
});

export default SendingSMS;
