import React, { Dispatch, useCallback } from "react";
import { Column, Row, Tabs } from "uikit";
import { useTranslation } from "react-i18next";
import Title from "./components/Title";
import Root from "./components/Root";
import translationPath from "../../../../constants/translationPath";
import tabs from "../../constants/tabs";

const Header: React.FC<Header.Props> = ({ tab, title, createdAt, onChangeTab }) => {
	const { t } = useTranslation();
	const translatedTabs = tabs.map((internalTab) => ({
		...internalTab,
		label: t(`${translationPath}.modal.tabs.${internalTab.key}.tabName`),
	}));

	const changeTabHandler = useCallback(
		(newTab) => {
			onChangeTab(newTab.toString());
		},
		[onChangeTab],
	);

	return (
		<Root sizes="auto!*">
			<Row
				style={{ padding: "16px 20px" }}
				align="center"
				justify="space-between"
				gaps="10px"
			>
				<Title>{title}</Title>
				<Column align="end" gaps="15px">
					<Row gaps="7px">
						<span>{t(`${translationPath}.modal.createdAt`)}:</span>
						<span style={{ color: "black", fontWeight: "600" }}>
							{createdAt}
						</span>
					</Row>
				</Column>
			</Row>
			<Tabs
				value={tab}
				options={translatedTabs}
				variant="panels"
				onChange={changeTabHandler}
			/>
		</Root>
	);
};

declare namespace Header {
	interface Props {
		tab: TabType;
		title: string;
		createdAt?: string;
		onChangeTab: Dispatch<TabType>;
	}

	type TabType = string;
}

export default Header;
