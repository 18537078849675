import React, { Dispatch, RefAttributes, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Option, Select, react } from "uikit";
import LabeledField from "../../../../../../../../../../../../../../../../components/LabeledField";
import useModelSubscribe from "../../../../../../../../../../../../../../../../hooks/useModelSubscribe";
import mapByKey from "../../../../../../../../../../../../../../../../utils/mapByKey";
import Company from "../../../../../../../../../../../../../../../../services/Company";
import Language from "../../../../../../../../../../../../../../../../services/Language";
import InternalController from "./Controller";

const CompanyId = react.withController<
	CompanyId.PropsBase,
	CompanyId.Controller
>(
	({
		value,

		disabled,
		error,
		language,

		onChange,
	}) => {
		const { t } = useTranslation();
		const modelData = useModelSubscribe({}, Company);

		const items = useMemo(() => modelData?.cache ?? [], [modelData?.cache]);

		const selectOptions = useMemo<Option<number>[]>(
			() =>
				items.map((item) => ({
					key: item.id,
					label: item.name[language],
					value: item.id,
				})),
			[items, language],
		);

		const selectOptionByKey = useMemo(
			() => mapByKey(selectOptions, "key"),
			[selectOptions],
		);

		const selectOnChange = useCallback(
			(key) => {
				onChange(selectOptionByKey[key].value);
			},
			[onChange, selectOptionByKey],
		);

		return (
			<LabeledField
				label={
					t(
						"pages.mainPage.pages.accounts.tabs.executors.editModal.content.mainTab.workData.companyId.str0",
					) ?? ""
				}
			>
				<Select
					value={value}
					disabled={disabled}
					error={error}
					placeholder={
						t(
							"pages.mainPage.pages.accounts.tabs.executors.editModal.content.mainTab.workData.companyId.str0",
						) ?? ""
					}
					options={selectOptions}
					onChange={selectOnChange}
				/>
			</LabeledField>
		);
	},
	InternalController,
);

declare namespace CompanyId {
	type Ref = InternalController | null;

	type Controller = InternalController;

	type Value = number | undefined;
	type NewValue = number;

	interface PropsBase {
		value: Value;

		disabled: boolean;
		error: boolean;
		language: Language;

		onChange: Dispatch<NewValue>;
	}

	type Props = PropsBase & RefAttributes<Ref>;
}

export default CompanyId;
