/* eslint-disable no-shadow */

import React, {
	Dispatch,
	RefAttributes,
	memo,
	useCallback,
	useEffect,
	useMemo,
} from "react";
import { Column, Option, Row, Select, react, useRefWithSetter } from "uikit";
import { useTranslation } from "react-i18next";
import { isNaN } from "lodash";

import Client from "../../../../../../../../../../../../../../../services/Client";
import useObjectEditor from "../../../../../../../../../../../../../../../hooks/useObjectEditor";
import LabeledField from "../../../../../../../../../../../../../../../components/LabeledField";
import useModelSubscribe from "../../../../../../../../../../../../../../../hooks/useModelSubscribe2";
import CustomerRate from "../../../../../../../../../../../../../../../services/CustomerRate";
import { useTypedSelector } from "../../../../../../../../../../../../../../../redux/store";

import InternalController from "./Controller";
import ClientGroup from "./components/ClientGroup";
import CompanyAndTaxiService from "./components/CompanyAndTaxiService";
import CarClassId from "./components/CarClassIId";

declare namespace AdditionalData {
	type Ref = InternalController;

	type Controller = InternalController;

	type Value = Pick<
		Client.Model,
		"carClassId" | "customerGroupId" | "latestTaxiServiceId"
	> &
		CompanyAndTaxiService.Value & {
			mainRateId?: number;
			additionalRateId?: number;
		};

	interface PropsBase {
		groups: ClientGroup.Props["groups"];
		value: Value;
		onChange: Dispatch<Value>;
		isCompanyEditable: boolean;
	}

	type Props = PropsBase & RefAttributes<Ref>;
}

const AdditionalDataBase = react.withController<
	AdditionalData.PropsBase,
	AdditionalData.Controller
>(({ groups, value, onChange, isCompanyEditable, controller }) => {
	const language = useTypedSelector((state) => state.session.language);

	const { t } = useTranslation();

	const mainCustomerRateData = useModelSubscribe(
		{ type: CustomerRate.Model.Type.Main },
		CustomerRate,
	);

	const additionalCustomerRateData = useModelSubscribe(
		{ type: CustomerRate.Model.Type.Additional },
		CustomerRate,
	);

	const notActive = useMemo(() => t("actives.false"), [t]);

	const valueEditor = useObjectEditor(value, onChange);

	const cleared = useMemo(
		() => ({
			key: 0,
			label:
				t(
					"pages.mainPage.pages.accounts.tabs.executors.editModal.content.mainTab.workData.executorTeamId.str1",
				) ?? "",
			value: 0,
		}),
		[],
	);
	const mainRateIdValue = valueEditor.useGetter("mainRateId");
	const mainRateIdOnChange = valueEditor.useSetter("mainRateId");

	useEffect(() => {
		if (
			isNaN(mainRateIdValue) &&
			mainCustomerRateData?.models &&
			mainCustomerRateData?.models?.length
		) {
			const item = mainCustomerRateData.models.find(
				(item) => item.active && item.default,
			);

			if (!item) mainRateIdOnChange(undefined);
			else mainRateIdOnChange(item.id);
		}
	}, [mainCustomerRateData?.models, mainRateIdOnChange, mainRateIdValue]);

	const mainCustomerRateOptions = useMemo<Option<number>[]>(() => {
		if (
			!mainCustomerRateData?.models ||
			!mainCustomerRateData?.models?.length
		) {
			return [];
		}

		const models = mainCustomerRateData.models.filter((item) => {
			if (item.active) return true;
			if (item.id === mainRateIdValue) return true;
			return false;
		});

		if (!models?.length) return [];

		const items = models.map((customerRate) => ({
			key: customerRate.id,
			value: customerRate.id,
			label: customerRate.active
				? customerRate.name[language]
				: `${customerRate.name[language]} (${notActive})`,
		}));

		return [cleared, ...items];
	}, [
		mainCustomerRateData.models,
		cleared,
		mainRateIdValue,
		language,
		notActive,
	]);

	const selectMainRateId = useCallback(
		(value) => {
			const find = [...mainCustomerRateOptions].find(
				(item) => item.key === value,
			);

			if (find === undefined) {
				mainRateIdOnChange(undefined);
				return;
			}

			if (find.value === 0) {
				mainRateIdOnChange(0);
				return;
			}

			mainRateIdOnChange(find.value);
		},
		[mainRateIdOnChange, mainCustomerRateOptions],
	);

	const additionalRateIdValue = valueEditor.useGetter("additionalRateId");
	const additionalRateIdOnChange = valueEditor.useSetter("additionalRateId");

	useEffect(() => {
		if (
			isNaN(additionalRateIdValue) &&
			additionalCustomerRateData?.models &&
			additionalCustomerRateData?.models?.length
		) {
			const item = additionalCustomerRateData.models.find(
				(item) => item.active && item.default,
			);

			if (!item) additionalRateIdOnChange(undefined);
			else additionalRateIdOnChange(item.id);
		}
	}, [
		additionalCustomerRateData?.models,
		additionalRateIdOnChange,
		additionalRateIdValue,
	]);

	const additionalCustomerRateOptions = useMemo<Option<number>[]>(() => {
		if (
			!additionalCustomerRateData?.models ||
			!additionalCustomerRateData?.models?.length
		) {
			return [];
		}

		const models = additionalCustomerRateData.models.filter((item) => {
			if (item.active) return true;
			if (item.id === additionalRateIdValue) return true;
			return false;
		});

		if (!models?.length) return [];

		const items = models.map((customerRate) => ({
			key: customerRate.id,
			value: customerRate.id,
			label: customerRate.active
				? customerRate.name[language]
				: `${customerRate.name[language]} (${notActive})`,
		}));

		return [cleared, ...items];
	}, [
		language,
		additionalCustomerRateData.models,
		cleared,
		additionalRateIdValue,
		notActive,
	]);

	const selectAdditionalRateId = useCallback(
		(value) => {
			const find = [...additionalCustomerRateOptions].find(
				(item) => item.key === value,
			);

			if (find === undefined) {
				additionalRateIdOnChange(undefined);
				return;
			}

			if (find.value === 0) {
				additionalRateIdOnChange(0);
				return;
			}
			additionalRateIdOnChange(find.value);
		},
		[additionalCustomerRateOptions, additionalRateIdOnChange],
	);

	const [companyAndTaxiServiceRef, setCompanyAndTaxiServiceRef] =
		useRefWithSetter<CompanyAndTaxiService.Ref | null>(null);

	// const picker = valueEditor.usePicker([]);
	// const assigner = valueEditor.useAssigner();

	const taxiServiceId = valueEditor.useGetter("defaultTaxiServiceId");
	const carClassId = valueEditor.useGetter("carClassId");
	const setCarClassId = valueEditor.useSetter("carClassId");

	const groupId = valueEditor.useGetter("customerGroupId");
	const setGroupId = valueEditor.useSetter("customerGroupId");

	const onCarClassIdChange = useCallback<React.Dispatch<typeof carClassId>>(
		(carClassId) => {
			// required to replace undefined with null
			// or else deletion of carClass won't work

			setCarClassId(carClassId == null ? -1 : carClassId);
		},
		[setCarClassId],
	);

	controller.setContext({ companyAndTaxiServiceRef });
	return (
		<Column gaps="8px*">
			<CompanyAndTaxiService
				ref={setCompanyAndTaxiServiceRef}
				isCompanyEditable={isCompanyEditable}
				value={value}
				onChange={onChange}
			>
				<ClientGroup
					groups={groups}
					value={groupId}
					onChange={setGroupId}
				/>
			</CompanyAndTaxiService>

			<Row gaps="8px*" sizes="275.5px*">
				<LabeledField
					label={
						t(
							`mainPage.customers.clients.main.additionalData.mainLoyaltyProgram`,
						) || "Main loyalty program"
					}
				>
					<Select
						value={mainRateIdValue}
						options={mainCustomerRateOptions}
						disabled={mainCustomerRateOptions.length === 0}
						onSelect={(option) => selectMainRateId(option?.key)}
						placeholder={cleared.label}
					/>
				</LabeledField>

				<LabeledField
					label={
						t(
							`mainPage.customers.clients.main.additionalData.additionalLoyaltyProgram`,
						) || "Additional loyalty program"
					}
				>
					<Select
						value={additionalRateIdValue}
						options={additionalCustomerRateOptions}
						disabled={additionalCustomerRateOptions.length === 0}
						onSelect={(option) =>
							selectAdditionalRateId(option?.key)
						}
						placeholder={cleared.label}
					/>
				</LabeledField>
				<CarClassId
					taxiServiceId={taxiServiceId}
					value={carClassId}
					onChange={onCarClassIdChange}
				/>
				<LabeledField
					label={
						t(
							`mainPage.customers.clients.main.additionalData.tariff`,
						) || "Tariff"
					}
				>
					<Select disabled />
				</LabeledField>
			</Row>
		</Column>
	);
}, InternalController);

const AdditionalData = memo(AdditionalDataBase);

export default AdditionalData;
