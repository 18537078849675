import styled from "styled-components";
import { Column } from "uikit";

const Root = styled(Column)`
	width: 600px;
	height: 350px;
	border-radius: 5px;
	overflow: hidden;
`;

export default Root;
