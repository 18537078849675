import { react } from "uikit";
import { Dispatch, SetStateAction } from "react";
import SearchHouse from ".";

interface Context {
	value: SearchHouse.Value;
	setError: Dispatch<SetStateAction<boolean>>;
}

export default class Controller extends react.ControllerBase<Context> {
	validate() {
		const isValid = !!this.context?.value?.coordinates;

		if (!isValid) this.context?.setError(true);

		return isValid;
	}
}
