import { RefObject } from "react";
import { react } from "uikit";

import CarBrandId from "./components/CarBrandId";
import CarModelId from "./components/CarModelId";

import MainData from ".";

interface Context {
	carBrandIdRef: RefObject<CarBrandId.Ref>;
	carModelIdRef: RefObject<CarModelId.Ref>;

	value: MainData.Value;
}

export default class Controller extends react.ControllerBase<Context> {
	validate() {
		const isCarBrandIdValue =
			this.context?.carBrandIdRef.current?.validate();
		const isCarModelIdValue =
			this.context?.carModelIdRef.current?.validate();

		return isCarBrandIdValue && isCarModelIdValue;
	}
}
