import React, {
	Dispatch,
	SetStateAction,
	useCallback,
	useMemo,
	useState,
} from "react";
import { CheckBox } from "uikit";
import { useTranslation } from "react-i18next";
import Root from "./components/Root";
import Modal from "../Modal";

import LightTable from "../../../../../../../../../../../../../../components/LightTable";
import TableSettings from "../../../../../../../../../../../../../../components/TableSettings";
import { Columns, columns, defaultColumnIds } from "./constants";
import tPath from "../../constants/tPath";
import Switcher from "./components/Switcher";

const Content: React.FC<Content.Props> = ({
	value,
	selected,
	setSelected,
	onEdit,
	saveHandler,
}) => {
	const { t } = useTranslation();

	const tColumns = useMemo(
		() =>
			columns.map((c) => ({
				...c,
				label: t(`${tPath}.columns.${c.id}`),
			})),
		[t],
	);
	const [columnIds, setColumnIds] = useState(defaultColumnIds);

	const [ctrlPressed, setCtrlPressed] = useState(false);

	const updateSelected = useCallback(
		(index: number, force = false) => {
			if (ctrlPressed || force) {
				if (selected.includes(index)) {
					setSelected(selected.filter((i) => index !== i));
				} else {
					setSelected([...selected, index]);
				}
			} else {
				selected.includes(index)
					? setSelected([])
					: setSelected([index]);
			}
		},
		[ctrlPressed, selected, setSelected],
	);

	const getClassName = useCallback(
		(rowData) =>
			rowData && selected.includes(value.indexOf(rowData))
				? "selected"
				: "",
		[selected, value],
	);

	const handleOnRowClick = useCallback(
		(rowData) => {
			updateSelected(value.indexOf(rowData));
		},
		[updateSelected, value],
	);

	const handleOnChangeSelected = useCallback(
		(isAllSelected) => {
			if (isAllSelected) {
				setSelected(value.map((_, i) => i));
			} else {
				setSelected([]);
			}
		},
		[setSelected, value],
	);

	const handleContentEdit = useCallback(
		(rowData) => {
			onEdit?.(value.indexOf(rowData));
		},
		[onEdit, value],
	);

	const handleKeyDown = useCallback((e) => {
		if (e.ctrlKey) {
			setCtrlPressed(true);
		}
	}, []);

	const handleKeyUp = useCallback((e) => {
		if (!e.ctrlKey) {
			setCtrlPressed(false);
		}
	}, []);

	const data = useMemo(
		() =>
			columnIds.map((columnId) => {
				const column = tColumns.find((col) => col.id === columnId);
				if (!column) return null;

				return (
					<LightTable.Column
						key={columnId}
						verticalAlign="middle"
						width={column.width}
						flexGrow={column.width ? 0 : 1}
					>
						<LightTable.HeaderCell style={{ padding: "0 15px" }}>
							{column.label}
						</LightTable.HeaderCell>
						<LightTable.Cell
							verticalAlign="middle"
							style={{ padding: "0 15px" }}
						>
							{(rowData) => {
								const model = rowData as Modal.Value;

								switch (columnId) {
									case Columns.AUTODETECTION:
										return (
											<Switcher
												value={model}
												saveHandler={saveHandler}
												index={value.indexOf(model)}
											/>
										);

									case Columns.PHONE:
										return model.number;

									default:
										return null;
								}
							}}
						</LightTable.Cell>
					</LightTable.Column>
				);
			}),
		[columnIds, saveHandler, tColumns, value],
	);

	return (
		<Root sizes="1fr auto!">
			<div tabIndex={-1} onKeyDown={handleKeyDown} onKeyUp={handleKeyUp}>
				<LightTable
					data={value}
					virtualized
					fillHeight
					shouldUpdateScroll={false}
					rowClassName={getClassName}
					onRowClick={handleOnRowClick}
					onRowDoubleClick={handleContentEdit}
				>
					<LightTable.Column width={0}>
						<LightTable.HeaderCell>
							<></>
						</LightTable.HeaderCell>
						<LightTable.Cell />
					</LightTable.Column>
					<LightTable.Column verticalAlign="middle" width={50}>
						<LightTable.HeaderCell style={{ padding: "0 20px" }}>
							<CheckBox
								value={
									selected.length === value.length &&
									value.length !== 0
								}
								onChange={handleOnChangeSelected}
							/>
						</LightTable.HeaderCell>
						<LightTable.Cell
							verticalAlign="middle"
							style={{ padding: "0 20px" }}
						>
							{(data) => (
								<CheckBox
									value={selected.includes(
										value.indexOf(data as Modal.Value),
									)}
									onChange={() => {
										updateSelected(
											value.indexOf(data as Modal.Value),
											true,
										);
									}}
								/>
							)}
						</LightTable.Cell>
					</LightTable.Column>
					{data}
					<LightTable.Column width={0}>
						<LightTable.HeaderCell>
							<></>
						</LightTable.HeaderCell>
						<LightTable.Cell />
					</LightTable.Column>
				</LightTable>
			</div>
			<TableSettings
				value={columnIds}
				defaultValue={columnIds}
				columns={columns}
				onChange={setColumnIds}
			/>
		</Root>
	);
};

declare namespace Content {
	interface Props {
		value: Modal.Value[];
		selected: number[];
		setSelected: Dispatch<SetStateAction<number[]>>;
		onEdit?: Dispatch<number>;
		saveHandler: (model: Modal.Value, index?: number | null) => void;
	}

	type ColumnType = {
		id: string;
		label: string;
		width?: number;
		justify?: "center";
	};
}

export default Content;
