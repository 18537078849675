/* eslint-disable react-hooks/rules-of-hooks */

import React, {
	Dispatch,
	RefAttributes,
	memo,
	useCallback,
	useMemo,
	useState,
} from "react";
import { useTranslation } from "react-i18next";
import { Overlayer, Row, TextBox, react } from "uikit";
import { isNil } from "lodash";

import useObjectEditor from "../../../../../../../../../../../../../../../../hooks/useObjectEditor";
import LabeledField from "../../../../../../../../../../../../../../../../components/LabeledField";
import InternalController from "./Controller";
import ShowPasswordButton from "./components/ShowPasswordButton";

const Authorization = memo(
	react.withController<Authorization.PropsBase, Authorization.Controller>(
		({ controller, value, disabled, onChange }) => {
			const { t } = useTranslation();
			const valueEditor = useObjectEditor(value, onChange);

			const [showPassword, setShowPassword] = useState(false);
			const [loginError, setLoginError] = useState(false);

			const hasPassword = useMemo(() => !isNil(value.password), [value]);

			const loginOnChange = useCallback(
				(login: string) => {
					setLoginError(false);

					valueEditor.set("login", login);
				},
				[valueEditor],
			);

			const [passwordError, setPasswordError] = useState(false);

			const passwordOnChange = useCallback(
				(password: string) => {
					setPasswordError(false);

					valueEditor.set("password", password);
				},
				[valueEditor],
			);

			controller.setContext({ value, setLoginError, setPasswordError });

			return (
				<Row gaps="8px*" sizes="275.5px*">
					<LabeledField
						label={
							t(
								"pages.mainPage.pages.accounts.tabs.users.editModal.content.mainTab.personData.authorization.str0",
							) ?? ""
						}
					>
						<TextBox.TextBox
							value={valueEditor.get("login")}
							disabled={disabled}
							style={{
								minHeight: "32px",
								flex: "1 0 0",
							}}
							autoComplete="one-time-code"
							placeholder={
								t(
									"pages.mainPage.pages.accounts.tabs.users.editModal.content.mainTab.personData.authorization.str0",
								) ?? ""
							}
							error={loginError}
							onChange={loginOnChange}
						/>
					</LabeledField>
					<LabeledField
						label={
							t(
								"pages.mainPage.pages.accounts.tabs.users.editModal.content.mainTab.personData.authorization.str2",
							) ?? ""
						}
					>
						<Overlayer
							overlay={
								<ShowPasswordButton
									value={showPassword}
									disabled={!hasPassword}
									onChange={setShowPassword}
								/>
							}
						>
							<TextBox.TextBox
								value={
									valueEditor.get("password") ?? "********"
								}
								disabled={disabled}
								style={{
									minHeight: "32px",
									flex: "1 0 0",
								}}
								type={
									showPassword && hasPassword
										? "text"
										: "password"
								}
								autoComplete="new-password"
								placeholder={
									t(
										"pages.mainPage.pages.accounts.tabs.users.editModal.content.mainTab.personData.authorization.str2",
									) ?? ""
								}
								error={passwordError}
								onChange={passwordOnChange}
							/>
						</Overlayer>
					</LabeledField>
				</Row>
			);
		},
		InternalController,
	),
);

declare namespace Authorization {
	type Ref = InternalController | null;

	type Controller = InternalController;

	interface Value {
		login: string;
		password?: string;
	}

	interface PropsBase {
		value: Value;

		disabled: boolean;

		onChange: Dispatch<Value>;
	}

	type Props = PropsBase & RefAttributes<Ref>;
}

export default Authorization;
