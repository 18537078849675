import Modal from ".";

export const TEMPLATE_MISSING = 0;

const defaultValue: Modal.Value = {
	data: {
		notificationTemplateId: TEMPLATE_MISSING,
		text: "",
		title: "",
		bigPicture: null,
		largeIcon: null,
	},
};

export default defaultValue;
