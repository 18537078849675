import React, {
	Dispatch,
	RefAttributes,
	SetStateAction,
	memo,
	useCallback,
	useEffect,
	useState,
} from "react";
import { useTranslation } from "react-i18next";
import { Option, TextSelect, react } from "uikit";
import { DebouncedState } from "use-debounce/lib/useDebouncedCallback";
import Map from "../../../../../../../../../../../../../../../../../../services/Map";
import LabeledField from "../../../../../../../../../../../../../../../../../../components/LabeledField";
import { useTypedSelector } from "../../../../../../../../../../../../../../../../../../redux/store";
import Controller from "./Controller";

const SearchStreetBase = react.withController<
	SearchStreet.PropsBase,
	Controller
>((props) => {
	const { t } = useTranslation();
	const {
		disabled,
		street,
		setStreet,
		streetFocused,
		setStreetFocused,
		setHouseFocused,
		debouncedSearchRequest,
		controller,
	} = props;

	const { minSearchQueryLength } = useTypedSelector((s) => s.settings.map);

	const [error, setError] = useState(false);
	const [streetQuery, setStreetQuery] = useState("");
	const [streetOptions, setStreetOptions] = useState<SearchStreet.Options>(
		[],
	);

	useEffect(() => {
		if (street) setStreetQuery(`${street.streetType} ${street.street}`);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleStreetQueryChange = useCallback(
		(query: string) => {
			setStreetQuery(query);
			if (query.trim().length >= minSearchQueryLength) {
				debouncedSearchRequest(query, "street", setStreetOptions);
			}
		},
		[debouncedSearchRequest, minSearchQueryLength],
	);
	const handleStreetSelect = useCallback(
		(newStreet: Option<Map.Search.Object>) => {
			setError(false);
			setStreet(newStreet.value);
			setStreetFocused(false);
			setHouseFocused(true);
		},
		[setHouseFocused, setStreet, setStreetFocused],
	);

	const handleFocusStreet = useCallback(() => {
		setStreetFocused(true);
	}, [setStreetFocused]);

	const handleBlurStreet = useCallback(() => {
		setStreetFocused(false);
	}, [setStreetFocused]);

	controller.setContext({ value: street, setError });

	return (
		<LabeledField
			label={
				t(
					"pages.mainPage.pages.customers.tabs.agents.modal.content.tabs.addresses.modal.content.searchStreet.str0",
				) ?? ""
			}
		>
			<TextSelect
				placeholder={
					t(
						"pages.mainPage.pages.customers.tabs.agents.modal.content.tabs.addresses.modal.content.searchStreet.str1",
					) ?? ""
				}
				disabled={disabled}
				error={error}
				value={streetQuery}
				onChange={handleStreetQueryChange}
				options={streetOptions}
				onSelect={handleStreetSelect}
				focused={streetFocused}
				onFocus={handleFocusStreet}
				onBlur={handleBlurStreet}
			/>
		</LabeledField>
	);
}, Controller);

const SearchStreet = memo(SearchStreetBase);

declare namespace SearchStreet {
	type Ref = Controller | null;

	interface PropsBase {
		disabled?: boolean;
		street: Value;
		setStreet: Dispatch<Value>;
		debouncedSearchRequest: DebouncedState<
			(
				query: string,
				type: Map.Search.Type,
				setTextSelectOptions: Dispatch<SetStateAction<Options>>,
			) => Promise<void>
		>;
		streetFocused: boolean;
		setStreetFocused: Dispatch<SetStateAction<boolean>>;
		setHouseFocused: Dispatch<SetStateAction<boolean>>;
	}
	type Props = PropsBase & RefAttributes<Ref>;

	type Value = Map.Search.Object | null;

	type Options = Option<Map.Search.Object>[];
}

export default SearchStreet;
