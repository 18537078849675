import React, {
	Dispatch,
	RefAttributes,
	useCallback,
	useMemo,
	useState,
} from "react";
import { Option, react } from "uikit";
import { useTranslation } from "react-i18next";

import CarClass from "../../../../../../../../../../../../../../../../services/CarClass";
import useModelSubscribe from "../../../../../../../../../../../../../../../../hooks/useModelSubscribe2";
import LabeledField from "../../../../../../../../../../../../../../../../components/LabeledField";
import SelectWithModal from "../../../../../../../../../../../../../../../../components/SelectWithModal";
import { MultiSelectCarClassWithModal } from "../../../../../../../../../../../../../../../../components/common";

import InternalController from "./Controller";

const CarClassId = react.withController<
	CarClassId.Props,
	CarClassId.Controller
>(
	({
		controller,

		value,
		onChange,

		disabled,

		carClassOnChange,
		serviceIdsOnChange,
		distributableCarClassIdsOnChange,
		broadcastableCarClassIdsOnChange,
		taxiServiceIds,
	}) => {
		const { t } = useTranslation();

		const [error, setError] = useState(false);

		const modelData = useModelSubscribe({}, CarClass);

		const items = useMemo(() => modelData.models, [modelData.models]);

		const selectOnChange = useCallback(
			(newValue: SelectWithModal.Value<number>) => {
				setError(false);

				onChange(newValue as number | undefined);

				const exist = items.find((item) => item.id === newValue);
				carClassOnChange(exist);
				serviceIdsOnChange(exist?.serviceDefaultIds || []);
				distributableCarClassIdsOnChange(
					exist?.compatibleCarClassIds || [],
				);
				broadcastableCarClassIdsOnChange(
					exist?.compatibleCarClassIdsToBroadcastable || [],
				);
			},
			[
				onChange,
				items,
				carClassOnChange,
				serviceIdsOnChange,
				distributableCarClassIdsOnChange,
				broadcastableCarClassIdsOnChange,
			],
		);

		controller.setContext({ value, setError });

		return (
			<LabeledField
				label={
					t(
						`mainPage.customers.clients.main.additionalData.vehicleClass`,
					) || "car class"
				}
			>
				<MultiSelectCarClassWithModal
					value={value ? [value] : []}
					disabled={disabled || !taxiServiceIds?.length}
					error={error}
					onChange={(values) => selectOnChange(values.at(0))}
					titleText={
						t(
							"pages.mainPage.pages.accounts.tabs.car.editModal.content.mainTab.mainData.carClassIId.str0",
						) ?? ""
					}
					required
					selectOnlyOne
					showSelectAll={false}
					subscribeOptions={{ taxiServiceIds }}
				/>
			</LabeledField>
		);
	},
	InternalController,
);

declare namespace CarClassId {
	type Ref = InternalController | null;

	type Controller = InternalController;

	type Value = number | undefined;

	interface PropsBase {
		value: Value;
		onChange: Dispatch<Value>;

		carClassOnChange: Dispatch<CarClass.Model | undefined>;
		serviceIdsOnChange: Dispatch<number[]>;
		distributableCarClassIdsOnChange: Dispatch<number[]>;
		broadcastableCarClassIdsOnChange: Dispatch<number[]>;

		disabled?: boolean;
		taxiServiceIds?: number[];
	}

	type Props = PropsBase & RefAttributes<Ref>;
}

export default CarClassId;
