import React, { memo, useCallback, useState } from "react";
import { Button, Icon } from "uikit";
import { useTypedSelector } from "../../../../../../../../../../redux/store";
import SIP from "../../../../../../../../../../services/SIP";
import CallArrayModal from "./modal";
import Wrapper from "./container";
import Executor from "../../../../../../../../../../services/Executor";

interface Props {
	executors: Executor.Model[];
}

const Call: React.FC<Props> = memo(({ executors }) => {
	const isOneSelected = executors.length === 1;

	const phones = executors[0]?.person?.phones;

	const sipToDispatcherId = useTypedSelector(
		(state) => state.sipToDispatcher.id,
	);

	const [showModal, setShowModal] = useState(false);

	const handleCall = useCallback(
		async (phone?: string) => {
			if (sipToDispatcherId) {
				await SIP.callToPhone({
					sipToDispatcherId,
					phone: phone || (phones?.[0]?.number as string),
				});
			}
		},
		[phones, sipToDispatcherId],
	);

	const handleShowModal = useCallback(() => {
		setShowModal((prev) => !prev);
	}, []);

	const handleCloseModal = useCallback(() => {
		setShowModal(false);
	}, []);

	return (
		<Wrapper>
			<div style={{ maxWidth: 30 }}>
				<div style={{ pointerEvents: showModal ? "none" : undefined }}>
					<Button.Button
						variant="secondary"
						disabled={
							showModal ||
							!sipToDispatcherId ||
							!isOneSelected ||
							!phones
						}
						onClick={handleShowModal}
						icon={<Icon id="call-to-person" size={20} />}
					/>
				</div>
			</div>
			{showModal && (
				<CallArrayModal
					phones={phones}
					onClose={handleCloseModal}
					onSubmit={handleCall}
				/>
			)}
		</Wrapper>
	);
});

export default Call;
