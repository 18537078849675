/* eslint-disable no-shadow */
import React, {
	Dispatch,
	ReactElement,
	useMemo,
	useRef,
	useState,
} from "react";
import { UseTranslationResponse, useTranslation } from "react-i18next";
import { Column } from "uikit";
import { RowDataType, SortType } from "rsuite-table";

import Language from "../../../../../../../../../../../../../../services/Language";
import ExecutorFeedback from "../../../../../../../../../../../../../../services/ExecutorFeedback";
import useFormatDate from "../../../../../../../../../../../../../../hooks/useFormatDate";
import { getColumnsModeTwo } from "../../../../../../../../../../../../../../utils";
import LightTable from "../../../../../../../../../../../../../../components/LightTable";
import TableSettings from "../../../../../../../../../../../../../../components/TableSettings";

interface ColumnContext {
	language: Language;
	t: UseTranslationResponse<"translation", undefined>[0];
}

type CellContent = (rowData: RowDataType, rowIndex?: number) => React.ReactNode;

const Columns: Record<string, (context: ColumnContext) => ReactElement> = {
	createdAt: ({ t }) => (
		<LightTable.Column width={150} fixed sortable resizable>
			<LightTable.HeaderCell>
				{t(
					"pages.mainPage.pages.accounts.tabs.executors.editModal.content.feedbackTab.modelTable.str100",
				) ?? ""}
			</LightTable.HeaderCell>
			<LightTable.Cell dataKey="createdAt">
				{((item: any) => item.createdAt) as unknown as CellContent}
			</LightTable.Cell>
		</LightTable.Column>
	),

	rating: ({ t }) => (
		<LightTable.Column width={100} sortable resizable>
			<LightTable.HeaderCell>
				{t(
					"pages.mainPage.pages.accounts.tabs.executors.editModal.content.feedbackTab.modelTable.str101",
				) ?? ""}
			</LightTable.HeaderCell>
			<LightTable.Cell dataKey="rating">
				{((item: any) => item.rating) as unknown as CellContent}
			</LightTable.Cell>
		</LightTable.Column>
	),

	comment: ({ t }) => (
		<LightTable.Column width={400} sortable resizable>
			<LightTable.HeaderCell verticalAlign="middle">
				{t(
					"pages.mainPage.pages.accounts.tabs.executors.editModal.content.feedbackTab.modelTable.str150",
				) ?? ""}
			</LightTable.HeaderCell>
			<LightTable.Cell verticalAlign="middle" dataKey="comment">
				{((item: any) => item.comment) as CellContent}
			</LightTable.Cell>
		</LightTable.Column>
	),

	customer: ({ t }) => (
		<LightTable.Column width={200} sortable resizable>
			<LightTable.HeaderCell>
				{t(
					"pages.mainPage.pages.accounts.tabs.executors.editModal.content.feedbackTab.modelTable.str102",
				) ?? ""}
			</LightTable.HeaderCell>
			<LightTable.Cell dataKey="customer">
				{((item) => item.customer) as unknown as CellContent}
			</LightTable.Cell>
		</LightTable.Column>
	),

	orderNumber: ({ t }) => (
		<LightTable.Column width={200} sortable resizable>
			<LightTable.HeaderCell>
				{t(
					"pages.mainPage.pages.accounts.tabs.executors.editModal.content.feedbackTab.modelTable.str103",
				) ?? ""}
			</LightTable.HeaderCell>
			<LightTable.Cell dataKey="orderNumber">
				{((item) => item.orderNumber) as unknown as CellContent}
			</LightTable.Cell>
		</LightTable.Column>
	),
};

const ModelTable: React.FC<ModelTable.Props> = ({
	sort,
	loading,
	data,
	language,
	onChangeSort,
}) => {
	const { t } = useTranslation();

	const defaultColumnIds: Parameters<typeof getColumnsModeTwo>[1] = useMemo(
		() => ["createdAt", "comment", "customer", "rating", "orderNumber"],
		[],
	);

	const columns = useMemo(
		() => getColumnsModeTwo(t, defaultColumnIds),
		[t, defaultColumnIds],
	);

	const formatDate = useFormatDate();

	const tableRef = useRef<LightTable.Ref | null>(null);

	const [columnIds, setColumnIds] = useState(defaultColumnIds);

	const columnContext = useMemo<ColumnContext>(
		() => ({ language, t }),
		[language, t],
	);

	const items = useMemo(
		() =>
			data.map((item) => {
				const customer = `${[
					item.client?.person?.lastName,
					item.client?.person?.firstName,
					item.client?.person?.fatherName,
				]
					.filter(Boolean)
					.join(" ")}${
					item.client?.person?.phones?.length
						? ` (${item.client?.person?.phones[0]})`
						: ""
				}`;

				return {
					createdAt: formatDate(
						item.createdAt,
						"DD.MM.YYYY HH:mm:ss",
					),
					rating: item.rating.toString(),
					comment: item.comment,
					customer,
					orderNumber: item.order?.orderNumber,
				};
			}),
		[data, formatDate],
	);

	return (
		<Column sizes="1fr auto!" maxedWidth maxedHeight>
			<div>
				<LightTable
					ref={tableRef}
					fillHeight
					virtualized
					shouldUpdateScroll={false}
					headerHeight={44}
					rowHeight={44}
					sortColumn={sort.column}
					sortType={sort.type}
					loading={loading}
					data={items}
					onSortColumn={(column, type) =>
						onChangeSort({ column, type })
					}
				>
					{columnIds.map((columnId) =>
						Columns[columnId](columnContext),
					)}
				</LightTable>
			</div>

			<TableSettings
				value={columnIds}
				defaultValue={defaultColumnIds}
				columns={columns}
				onChange={setColumnIds}
			/>
		</Column>
	);
};

declare namespace ModelTable {
	interface Sort {
		column?: string;
		type?: SortType;
	}

	interface Props {
		sort: Sort;

		loading: boolean;
		data: ExecutorFeedback.Model[];
		language: Language;

		onChangeSort: Dispatch<Sort>;
	}
}

export default ModelTable;
