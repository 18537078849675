import { react } from "uikit";
import { RefObject } from "react";
import CompanyAndTaxiService from "./components/CompanyAndTaxiService";

interface Context {
	companyAndTaxiServiceRef: RefObject<CompanyAndTaxiService.Ref | null>;
}

export default class Controller extends react.ControllerBase<Context> {
	validate() {
		const isCompanySelectValid =
			this.context?.companyAndTaxiServiceRef?.current?.validate();

		return isCompanySelectValid;
	}
}
