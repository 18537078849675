import React, { MouseEventHandler, useMemo } from "react";
import { InputBorders } from "uikit";
import { isUndefined } from "lodash";

import Root from "./components/Root";
import Text from "./components/Text";

const LabelBox: React.FC<LabelBox.Props> = ({
	children,

	disabled = false,
	placeholder = "",

	onClick,

	...props
}) => {
	const hasPlaceholder = useMemo(() => isUndefined(children), [children]);

	const text = useMemo(
		() => children ?? placeholder,
		[children, placeholder],
	);

	return (
		<Root disabled={!!disabled} onClick={onClick}>
			<InputBorders.InputBorders {...props} disabled={disabled}>
				<Text isPlaceholder={hasPlaceholder}>{text}</Text>
			</InputBorders.InputBorders>
		</Root>
	);
};

declare namespace LabelBox {
	interface Props extends InputBorders.Props {
		children?: string;

		disabled?: boolean;
		placeholder?: string;

		onClick?: MouseEventHandler<HTMLDivElement>;
	}
}

export default LabelBox;
