import React from "react";
import { RowDataType } from "rsuite-table";
import { TFunction } from "i18next";
import BalanceOutput from "../../../components/BalanceOutput";
import Counterparty from "../../../services/Counterparty";
import Language from "../../../services/Language";
import DisabledCheckbox from "../../../components/DisabledCheckbox";

const getCounterpartyCellData = (
	rowData: RowDataType<Counterparty.Model>,
	columnId: string,
	lang: Language,
	t: TFunction<string[], undefined, string[]>,
) => {
	const model = rowData as Counterparty.Model;

	switch (columnId) {
		case "name":
			return model.additionalFields?.name;

		case "edrpou":
			return model.additionalFields?.edrpou;

		case "overallBalance":
			return (
				<BalanceOutput
					balanceType="main"
					value={model.paymentAccounts}
				/>
			);

		case "disconnectionThreshold":
			return (
				<BalanceOutput
					balanceType="threshold"
					value={model?.paymentAccounts}
				/>
			);

		case "overallBonusBalance":
			return (
				<BalanceOutput
					balanceType="bonus"
					value={model.paymentAccounts}
				/>
			);

		case "orderCount":
			return <></>;

		case "contactPerson":
			return model.additionalFields?.contactName;

		case "phones":
			return model.additionalFields?.phones.join(", ");

		case "email":
			return model.additionalFields?.email;

		case "company":
			return model.company?.name[lang];

		case "registrationBranch":
			return model?.defaultTaxiService?.settlement[lang];

		case "registrationDate":
			return new Date(model.createdAt).toLocaleDateString();

		case "firstTripDate":
			return <></>;

		case "lastTripDate":
			return <></>;

		case "mobileApp":
			return (
				<DisabledCheckbox
					value={model.accessAllEmployeeToPersonalApp}
				/>
			);

		case "corporateAccount":
			return (
				<DisabledCheckbox value={model.isAccessToCorporateAccount} />
			);

		case "notes":
			return model.additionalFields?.notes;

		// case "status":
		// 	return t(`statuses.${model.status}`);

		case "group":
			return model.counterpartyGroup?.name;

		default:
			return null;
	}
};

export default getCounterpartyCellData;
