import React, { PropsWithChildren, ReactNode, forwardRef } from "react";

import Root from "./components/Root";
import Label from "./components/Label";

const FieldsContainer = forwardRef<FieldsContainer.Ref, FieldsContainer.Props>(
	({ children, label, labelColor, additionalFields, ...props }, ref) => (
		<Root ref={ref} label={label} {...props}>
			<Label gaps="10px*" align="center" color={labelColor}>
				<span>{label ?? ""}</span>
				{additionalFields && <span>{additionalFields}</span>}
			</Label>
			{children}
		</Root>
	),
);

declare namespace FieldsContainer {
	type Ref = HTMLDivElement;

	interface Props extends PropsWithChildren, Root.Props {
		label?: string;
		labelColor?: string;
		additionalFields?: ReactNode;
	}
}

export default FieldsContainer;
