import { Dispatch } from "react";
import { react } from "uikit";
import RoleId from ".";

interface Context {
	value: RoleId.Value;

	setError: Dispatch<boolean>;
}

export default class Controller extends react.ControllerBase<Context> {
	validate() {
		const isValueValid = this.context?.value.length !== 0;

		if (!isValueValid) this.context?.setError(true);

		return isValueValid;
	}
}
