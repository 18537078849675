import React, { Dispatch, useCallback, useLayoutEffect, useMemo } from "react";
import { CheckBox } from "uikit";
import { RowDataType } from "rsuite-table";

import {
	useColumns,
	useVisibleColumns,
} from "../../../../../../../../../../../../../../hooks/useTableSettings";
import { useCurrencyGlobalSettings } from "../../../../../../../../../../../../../../hooks";
import LightTable, {
	UseTableOptions,
	useTableOptions,
} from "../../../../../../../../../../../../../../components/LightTable";
import TableSettings from "../../../../../../../../../../../../../../components/TableSettings";
import DisabledCheckbox from "../../../../../../../../../../../../../../components/DisabledCheckbox";
import { ValuePaymentAccount } from "../../../../../../../../../../../../../../types/PaymentAccount";
import formatNumber from "../../../../../../../../../../../../../../utils/formatNumber";
import {
	StyledRow,
	StyledSpan,
} from "../../../../../../../../../../../../../../components/common";
import Main from "../../../../tabs/Main";
import Accounts from "../..";

import Root from "./components/Root";
import Status from "./components/Status";

const Content: React.FC<Content.Props> = ({
	value,
	selected,
	setSelected,
	onEdit,
	saveHandler,
	additional,
	editorTable,
	onChangeTable,
	height,
}) => {
	const currencyGlobalSettings = useCurrencyGlobalSettings();

	const columns = useColumns("counterpartyCheck");
	const { columnIds, setColumnIds } = useVisibleColumns(
		"customers.counterpartyCheck",
		"counterpartyCheck",
	);

	const { ctrlPressed, onKeyUp, onKeyDown } = useTableOptions({
		value: editorTable,
		setValue: onChangeTable,
	});

	useLayoutEffect(() => {
		if (value.length) {
			const id = value?.[0]?.checkId;
			if (id) setSelected([id]);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const updateSelected = useCallback(
		(checkId: number, force = false) => {
			if (ctrlPressed || force) {
				if (selected.includes(checkId)) {
					setSelected(selected.filter((item) => checkId !== item));
				} else {
					setSelected([...selected, checkId]);
				}
			} else {
				selected.includes(checkId)
					? setSelected([])
					: setSelected([checkId]);
			}
		},
		[ctrlPressed, selected, setSelected],
	);
	const getBalance = useCallback(
		(
			{
				paymentAccounts,
				paymentTransactions,
				additionalFields,
			}: Accounts.Check,
			type: ValuePaymentAccount | "threshold" = "main",
		): string | undefined => {
			if (paymentAccounts?.length) {
				const balance = paymentAccounts?.find(
					(item) => item?.type === type,
				)?.amount;

				if (type === "threshold" && balance === undefined) {
					const value =
						additionalFields?.deactivationThreshold?.value;
					return formatNumber(value ?? 0);
				}
				return formatNumber(balance ?? 0);
			}
			if (paymentTransactions) {
				const value = paymentTransactions?.[type];
				if (!value) return "0";
				return formatNumber(
					value.reduce((acc, item) => (acc += item.amount), 0),
				);
			}
			return "0";
		},
		[],
	);

	const getOrderCount = useCallback(({ rideCounter }: Accounts.Check) => {
		const total = rideCounter?.total ?? 0;
		const success = rideCounter?.success ?? 0;
		const failed = rideCounter?.failed ?? 0;
		return (
			<StyledRow alignItems="center">
				<StyledSpan font={{ fw: "700" }} colors="#03a9f4">
					{total}
				</StyledSpan>
				<StyledSpan font={{ fw: "700" }} colors="#647079" p="0 2px">
					/
				</StyledSpan>
				<StyledSpan font={{ fw: "700" }} colors="#4caf50">
					{success}
				</StyledSpan>
				<StyledSpan font={{ fw: "700" }} colors="#647079" p="0 2px">
					/
				</StyledSpan>
				<StyledSpan font={{ fw: "700" }} colors="#f83528">
					{failed}
				</StyledSpan>
			</StyledRow>
		);
	}, []);

	const data = useMemo(
		() =>
			columnIds.map((columnId) => {
				const column = columns.find((col) => col.id === columnId);
				if (!column) return null;

				return (
					<LightTable.Column
						sortable
						resizable
						key={columnId}
						verticalAlign="middle"
						flexGrow={1}
					>
						<LightTable.HeaderCell style={{ padding: "0 20px" }}>
							{column.label}
						</LightTable.HeaderCell>
						<LightTable.Cell
							verticalAlign="middle"
							style={{ padding: "0 20px" }}
							dataKey={column.id}
							fullText
						>
							{(rowData: RowDataType<Accounts.Check>) => {
								const model = rowData as Accounts.Check;
								try {
									switch (columnId) {
										case "status":
											return (
												<Status
													model={model}
													value={value}
													saveHandler={saveHandler}
												/>
											);

										case "edrpou":
											return additional?.edrpou;

										case "counterpartyName":
											return additional?.name;

										case "checkNumber":
											return model?.additionalFields
												?.checkValue;

										case "balance":
											return (
												<>
													{`${getBalance(
														model,
														"main",
													)} ${currencyGlobalSettings}`}
												</>
											);

										case "tripThreshold":
											return (
												<>
													{`${getBalance(
														model,
														"threshold",
													)} ${currencyGlobalSettings}`}
												</>
											);

										case "bonusBalance":
											return (
												<>
													{`${getBalance(
														model,
														"bonus",
													)} ${currencyGlobalSettings}`}
												</>
											);

										case "orderCount":
											return <>{getOrderCount(model)}</>;

										case "discountPlan":
											return <></>;

										case "additionalDiscountPlan":
											return <></>;

										case "tariff":
											return <></>;

										case "registrationDate":
											return rowData.createdAt
												? new Date(
														rowData.createdAt,
												  ).toLocaleDateString()
												: new Date().toLocaleDateString();

										case "activationDate":
											return new Date(
												rowData.activationDate,
											).toLocaleDateString();

										case "firstTripDate":
											return <></>;

										case "lastTripDate":
											return <></>;

										case "mobileApp":
											return (
												<DisabledCheckbox
													value={
														model.accessAllEmployeeToPersonalApp
													}
												/>
											);

										case "corporateAccount":
											return (
												<DisabledCheckbox
													value={
														rowData.isAccessToCorporateAccount
													}
												/>
											);

										case "notes":
											return rowData.additionalFields
												?.notes;

										default:
											return null;
									}
								} catch (error) {
									return <></>;
								}
							}}
						</LightTable.Cell>
					</LightTable.Column>
				);
			}),
		[
			additional?.edrpou,
			additional?.name,
			columnIds,
			columns,
			currencyGlobalSettings,
			getBalance,
			getOrderCount,
			saveHandler,
			value,
		],
	);

	const getClassName = useCallback(
		(rowData) =>
			rowData?.checkId && selected.includes(rowData?.checkId)
				? "selected"
				: "",
		[selected],
	);

	const handleOnRowClick = useCallback(
		(rowData) => {
			if (!rowData) return;
			updateSelected(rowData?.checkId);
		},
		[updateSelected],
	);

	const handleOnChangeSelected = useCallback(
		(isAllSelected: boolean) => {
			if (isAllSelected) {
				setSelected(value?.map((item) => item?.checkId ?? -1));
			} else {
				setSelected([]);
			}
		},
		[setSelected, value],
	);

	const handleContentEdit = useCallback(
		(rowData) => {
			if (!rowData) return;
			onEdit?.(rowData?.checkId);
		},
		[onEdit],
	);

	return (
		<Root sizes="1fr auto!">
			<div tabIndex={-1} onKeyDown={onKeyDown} onKeyUp={onKeyUp}>
				<LightTable
					data={value}
					virtualized
					fillHeight
					rowHeight={46}
					shouldUpdateScroll={false}
					rowClassName={getClassName}
					onRowClick={handleOnRowClick}
					onRowDoubleClick={handleContentEdit}
					height={height - 145}
				>
					<LightTable.Column verticalAlign="middle" width={50}>
						<LightTable.HeaderCell style={{ padding: "0 25px" }}>
							<CheckBox
								value={
									selected.length === value.length &&
									value.length !== 0
								}
								onChange={handleOnChangeSelected}
							/>
						</LightTable.HeaderCell>
						<LightTable.Cell
							verticalAlign="middle"
							style={{ padding: "0 25px" }}
						>
							{(data) => (
								<CheckBox
									value={selected.includes(data?.checkId)}
									onChange={() => {
										updateSelected(data?.checkId, true);
									}}
								/>
							)}
						</LightTable.Cell>
					</LightTable.Column>
					{data}
				</LightTable>
			</div>

			<TableSettings
				value={columnIds}
				defaultValue={columnIds}
				columns={columns}
				onChange={setColumnIds}
			/>
		</Root>
	);
};

declare namespace Content {
	interface Props {
		value: Accounts.Check[];
		selected: number[];
		setSelected: Dispatch<number[]>;
		onEdit?: Dispatch<number>;
		saveHandler?: (check: Accounts.Check, index?: number | null) => void;
		additional: Main.AdditionalFields;
		editorTable: UseTableOptions["editor"];
		onChangeTable: UseTableOptions["onChange"];
		height: number;
	}

	type ColumnType = {
		id: string;
		label: string;
		width?: number;
		justify?: "center";
	};
}

export default Content;
