import React, { memo, useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Icon, Row, theme } from "uikit";

import { useCurrencyGlobalSettings } from "../../../../hooks";
import formatNumber from "../../../../utils/formatNumber";
import { PaymentAccount } from "../../../../types/PaymentAccount";
import { updateBalance } from "../../../../utils/updateBalances";
import { PaymentProviderAccessorType } from "../../../../services/Payment/types";
import BalanceModal from "../../../BalanceModal";
import Label from "../Label";

const Balance: React.FC<Balance.Props> = memo(
	({ ownerId, owner, value, disabled, label, colorMainBalance }) => {
		const { t } = useTranslation();
		const currencyGlobalSettings = useCurrencyGlobalSettings();

		const [showModal, setShowModal] = useState(false);
		const [error, setError] = useState(false);
		const [isSave, setIsSave] = useState<boolean>(false);

		const formattedNumber = useMemo(
			() => formatNumber(value.amount ?? 0),
			[value.amount],
		);

		const handleOpenModal = useCallback(() => {
			setShowModal(true);
		}, []);

		const handleCloseModal = useCallback(() => {
			setShowModal(false);
		}, []);

		const handleSubmit = useCallback(
			async (data: { amount: number; description: string }) => {
				const { amount, description } = data;

				if (!description) {
					setError(true);
					return;
				}
				try {
					if (value.type === "card") {
						value.amount = amount;
						value.description = description;
						setShowModal(false);
					}

					if (!amount || !ownerId || value.type === "card") return;

					const updatedAccount: PaymentAccount = {
						...value,
						isEdited: !!amount,
						amount,
						description,
					};
					if (isSave) return;
					setIsSave(true);

					const res = await updateBalance(
						updatedAccount,
						ownerId,
						owner,
					);

					if (res) {
						setShowModal(false);
						setIsSave(false);
					}
				} catch (error) {
					console.error(error);
					setShowModal(false);
				}
			},
			[isSave, owner, ownerId, value],
		);

		const colors = useMemo(() => {
			if (colorMainBalance) return [colorMainBalance];
			return [theme.colors.success];
		}, [colorMainBalance]);

		const translationTexts = useMemo(
			() => ({
				text: t("account.balance.str201") ?? "",
				balance: currencyGlobalSettings,
			}),
			[t, currencyGlobalSettings],
		);

		return (
			<>
				<Row align="center" gaps="4px 11.5px">
					<Icon id="wallet" size={16} colors={colors} />
					<Label style={{ color: colorMainBalance }}>
						{formattedNumber} {translationTexts.balance}.
					</Label>
					<Button.Button
						disabled={disabled}
						text={label ?? translationTexts.text}
						style={{
							minHeight: "32px",
						}}
						onClick={handleOpenModal}
					/>
				</Row>
				{showModal && (
					<BalanceModal
						error={error}
						isSave={isSave}
						onClose={handleCloseModal}
						onSubmit={handleSubmit}
						defaultAmount={
							value.type === "card" ? value.amount : undefined
						}
						defaultDescription={
							value.type === "card"
								? value?.description
								: undefined
						}
					/>
				)}
			</>
		);
	},
);

declare namespace Balance {
	interface Props {
		value: Value;
		ownerId: number | undefined;
		owner: PaymentProviderAccessorType;
		label?: string;
		disabled?: boolean;
		colorMainBalance?: string;
	}

	type Value = PaymentAccount;
}

export default Balance;
