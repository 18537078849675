import React, { Dispatch, memo, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Column, Tabs } from "uikit";
import moment from "moment";

import { getTabsModeOne } from "../../../../../../../../../../utils";

import Root from "./components/Root";
import Title from "./components/Title";
import InformationContainer from "./components/InformationContainer";
import SubTitle from "./components/SubTitle";
import LockedBy from "./components/LockedBy";

const Header = memo<Header.Props>(
	({
		tab,

		title,
		lockedBy,
		createdAt,

		onChangeTab,
	}) => {
		const { t } = useTranslation();

		const tabs = useMemo(() => {
			const selectedTabs: Parameters<typeof getTabsModeOne>[1] = [
				"main",
				"schedules",
				"executor-select",
				"history",
			];

			return getTabsModeOne(t, selectedTabs);
		}, [t]);
		return (
			<Root sizes="auto!*">
				<InformationContainer
					align="center"
					justify="space-between"
					gaps="10px"
				>
					<Title>{title}</Title>
					<Column align="end" gaps="15px">
						{lockedBy ? (
							<LockedBy>
								{t(
									"pages.mainPage.pages.accounts.tabs.workShifts.editModal.header.str200",
								) ?? ""}{" "}
								{lockedBy}
							</LockedBy>
						) : (
							<></>
						)}
						{createdAt ? (
							<SubTitle>
								{`${
									t(
										"pages.mainPage.pages.accounts.tabs.workShifts.editModal.header.str201",
									) ?? ""
								}: ${moment(createdAt).format("DD.MM.YYYY")}`}
							</SubTitle>
						) : (
							<></>
						)}
					</Column>
				</InformationContainer>
				<Tabs
					value={tab}
					options={tabs}
					variant="panels"
					onChange={(newTab) =>
						onChangeTab(newTab.toString() as Header.Tab)
					}
				/>
			</Root>
		);
	},
);

declare namespace Header {
	type Tab = "main" | "schedules" | "executor-select" | "history";

	interface Props {
		tab: Tab;

		title: string;
		lockedBy?: string;
		createdAt?: string;

		onChangeTab: Dispatch<Tab>;
	}
}

export default Header;
