import Modal from "../components/Modal";

const defaultValue: Modal.Value = {
	level: undefined,
	phone: undefined,
	description: undefined,
	companyIds: [],
};

export default defaultValue;
