import styled from "styled-components";
import { Column } from "uikit";

const Root = styled(Column)`
	width: 500px;

	border-radius: 5px;

	background-color: white;

	overflow: hidden;
`;

export default Root;
