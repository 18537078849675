import { react } from "uikit";
import { validatePhone } from "../../../../../../../../../../../../../../../../../utils/validatePhone";

interface Context {
	phones: string[];

	onPhoneErrors: (errors: boolean[]) => void;
}

export default class Controller extends react.ControllerBase<Context> {
	validate() {
		const phones = this.context?.phones || [];

		const isPhonesValid = phones?.every(
			(phone) => validatePhone(phone?.trim?.()) === true,
		);

		if (!isPhonesValid) {
			this.context?.onPhoneErrors(
				phones.map((phone) => validatePhone(phone?.trim?.()) !== true),
			);
		}

		return isPhonesValid;
	}
}
