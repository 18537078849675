import EntityStatus from "../../../../../../../types/EntityStatus";
import Modal from "../components/Modal";

const defaultValue: Modal.Value = {
	name: {},
	additionalFields: {
		contactPersonName: "",
		contactPersonPhone: "",
		contactPersonEmail: "",
		orderNotes: "",
		notes: "",
	},
	agentGroupId: undefined,
	isArbitraryRewardAllowed: false,
	isRewardAllowed: false,
	isAccessToAccount: false,
	isAccessToApp: false,
	login: null,
	password: null,
	code: { value: "", keyword: "" },
	paymentAccounts: [],
	paymentTransactions: {
		main: [],
		bonus: [],
	},
	counterpartyId: null,
	checkId: null,
	balanceId: null,
	status: {
		level: EntityStatus.ACTIVE,
		description: "",
	},
	agentToRewards: [],
	phones: [],
	addresses: [],
};

export default defaultValue;
