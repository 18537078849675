import React from "react";
import FilesTab from "../../../../../../../FilesTab";
import File from "../../../../../../../../../../../../../../../services/File";

const Files: React.FC<Files.Props> = ({ value, onChange }) => (
	<FilesTab
		visible={true}
		value={value}
		onChange={onChange}
		showPersonalFiles={true}
	/>
);

declare namespace Files {
	interface Props {
		value: Value;
		onChange: (value: Value) => void;
	}

	interface Value {
		personalFiles: File.Model[];
		otherFiles: File.Model[];
	}
}

export default Files;
