import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import AgentGroup from "../../../../../../services/AgentGroup";
import Agent from "../../../../../../services/Agent";
import useModelSubscribe2 from "../../../../../../hooks/useModelSubscribe2";
import mapByKey from "../../../../../../utils/mapByKey";
import DefaultPageHeader from "../../../../../../components/DefaultPageHeader";
import DeleteModal from "../../../../../../components/DeleteModal";
import { useTableOptions } from "../../../../../../components/LightTable";
import { TabKeys, ARR_ACCESS_PART_KEY } from "../../constants/access";

import Root from "./components/Root";
import Content from "./components/Content";
import Modal from "./components/Modal";
import defaultValue from "./constants/defaultValue";
import tPath from "./constants/tPath";

const AgentGroups: React.FC = () => {
	const { t } = useTranslation();

	const { editor, onChange, limit, sort, query } = useTableOptions();

	const {
		limit: limitAgent,
		sort: sortAgent,
		editor: editorAgent,
		onChange: onChangeAgent,
		query: queryAgent,
	} = useTableOptions();

	const optionsAgentGroup = useMemo(() => {
		const order = {};
		if (sort.dataKey) order[sort.dataKey] = sort.sortType;
		const payload: AgentGroup.SubscribeOptions = {
			limit,
			order,
			query,
		};
		return payload;
	}, [limit, sort.dataKey, sort.sortType, query]);

	const optionsAgent = useMemo(() => {
		const order = {};
		if (sortAgent.dataKey) order[sortAgent.dataKey] = sortAgent.sortType;
		const payload: Agent.SubscribeOptions = {
			limit: limitAgent,
			order,
			query: queryAgent,
		};
		return payload;
	}, [limitAgent, sortAgent.dataKey, sortAgent.sortType, queryAgent]);

	const { models } = useModelSubscribe2(optionsAgentGroup, AgentGroup);
	const { models: agents } = useModelSubscribe2(optionsAgent, Agent);

	const [showModal, setShowModal] = useState(false);
	const [showDeleteModal, setShowDeleteModal] = useState(false);

	const [selected, setSelected] = useState<number[]>([]);

	const [editingItem, setEditingItem] = useState<Modal.Value>(defaultValue);

	const modelItemById = useMemo(() => mapByKey(models, "id"), [models]);
	const agentById = useMemo(() => mapByKey(agents, "id"), [agents]);

	const edit = useCallback(
		(id: number) => {
			const item = modelItemById[id];
			setEditingItem(item);

			setShowModal(true);
			setSelected([]);
		},
		[modelItemById],
	);

	const editHeaderHandler = useCallback(() => {
		edit(selected[0]);
	}, [edit, selected]);

	const editContentHandler = useCallback(
		(model: AgentGroup.Modified) => edit(model.id),
		[edit],
	);

	const addHandler = useCallback(() => {
		setEditingItem(defaultValue);
		setShowModal(true);
	}, []);

	const preDeleteHandler = useCallback(() => {
		setShowDeleteModal(true);
	}, []);

	const deleteHandler = useCallback(async () => {
		await AgentGroup.delete(selected);
		setSelected([]);
		setShowDeleteModal(false);
	}, [selected]);

	const cancelHandler = useCallback(() => {
		setShowModal(false);
		setEditingItem(defaultValue);
	}, []);

	const cancelDeleteHandler = useCallback(() => {
		setShowDeleteModal(false);
		setSelected([]);
	}, []);

	const saveHandler = useCallback(
		async (model: AgentGroup.New | AgentGroup.Modified) => {
			let isAllOk = true;

			if ("id" in model && model.id) {
				isAllOk = await AgentGroup.update(model);
			} else {
				isAllOk = await AgentGroup.store(model as AgentGroup.New);
			}

			if (isAllOk) {
				setShowModal(false);
				setEditingItem(defaultValue);
			}
		},
		[],
	);

	return (
		<Root sizes="auto! 1fr" gaps="16px" maxedWidth maxedHeight>
			<DefaultPageHeader
				accessName={ARR_ACCESS_PART_KEY[TabKeys.AGENT_GROUPS]}
				canEdit={selected.length === 1}
				canDelete={!!selected.length}
				onAdd={addHandler}
				onDelete={preDeleteHandler}
				onEdit={editHeaderHandler}
			/>
			<Content
				value={models}
				selected={selected}
				setSelected={setSelected}
				onEdit={editContentHandler}
				editorTable={editor}
				onChangeTable={onChange}
			/>
			{showModal && (
				<Modal
					agents={agents}
					agentById={agentById}
					value={editingItem}
					onCancel={cancelHandler}
					onSave={saveHandler}
					editorTable={editorAgent}
					onChangeTable={onChangeAgent}
					headerTitle={
						editingItem.id
							? editingItem.name
							: t(`${tPath}.modal.title`)
					}
				/>
			)}
			{showDeleteModal && (
				<DeleteModal
					label={t(`${tPath}.deleteModal.title`) || ""}
					onCancel={cancelDeleteHandler}
					onConfirm={deleteHandler}
				/>
			)}
		</Root>
	);
};

export default AgentGroups;
