import React, {
	Dispatch,
	RefAttributes,
	SetStateAction,
	memo,
	useCallback,
	useEffect,
	useState,
} from "react";
import { useTranslation } from "react-i18next";
import { Option, TextSelect, react } from "uikit";
import { DebouncedState } from "use-debounce/lib/useDebouncedCallback";
import Map from "../../../../../../../../../../../../../../../../../../services/Map";
import LabeledField from "../../../../../../../../../../../../../../../../../../components/LabeledField";
import Controller from "./Controller";

const SearchHouseBase = react.withController<SearchHouse.PropsBase, Controller>(
	({
		disabled,
		street,
		house,
		setHouse,
		houseFocused,
		setHouseFocused,
		debouncedSearchRequest,
		controller,
	}) => {
		const { t } = useTranslation();
		const [error, setError] = useState(false);
		const [houseQuery, setHouseQuery] = useState("");
		const [houseOptions, setHouseOptions] = useState<SearchHouse.Options>(
			[],
		);

		useEffect(() => {
			if (house) setHouseQuery(house.number ?? "");
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, []);

		const handleHouseQueryChange = useCallback(
			async (query: string) => {
				setHouseQuery(query);
				await debouncedSearchRequest(
					`${street?.streetType ?? ""} ${
						street?.street ?? ""
					}, ${query}`,
					"address",
					setHouseOptions,
				);
			},
			[debouncedSearchRequest, street?.street, street?.streetType],
		);

		const handleHouseSelect = useCallback(
			(newHouse: Option<Map.Search.Object>) => {
				setError(false);
				setHouse(newHouse.value);
				setHouseFocused(false);
			},
			[setHouse, setHouseFocused],
		);

		const handleFocusHouse = useCallback(() => {
			setHouseFocused(true);
		}, [setHouseFocused]);

		const handleBlurHouse = useCallback(() => {
			setHouseFocused(false);
		}, [setHouseFocused]);

		controller.setContext({ value: house, setError });

		return (
			<LabeledField
				label={
					t(
						"pages.mainPage.pages.customers.tabs.agents.modal.content.tabs.addresses.modal.content.searchHouse.str0",
					) ?? ""
				}
			>
				<TextSelect
					disabled={!street || disabled}
					placeholder={
						t(
							"pages.mainPage.pages.customers.tabs.agents.modal.content.tabs.addresses.modal.content.searchHouse.str0",
						) ?? ""
					}
					error={error}
					value={houseQuery}
					onChange={handleHouseQueryChange}
					options={houseOptions}
					onSelect={handleHouseSelect}
					focused={houseFocused}
					onFocus={handleFocusHouse}
					onBlur={handleBlurHouse}
				/>
			</LabeledField>
		);
	},
	Controller,
);

const SearchHouse = memo(SearchHouseBase);

declare namespace SearchHouse {
	type Ref = Controller | null;

	interface PropsBase {
		disabled?: boolean;
		street: Map.Search.Object | null;
		house: Value;
		setHouse: Dispatch<Value>;
		houseFocused: boolean;
		setHouseFocused: Dispatch<SetStateAction<boolean>>;
		debouncedSearchRequest: DebouncedState<
			(
				query: string,
				type: Map.Search.Type,
				setTextSelectOptions: Dispatch<SetStateAction<Options>>,
			) => Promise<void>
		>;
	}
	type Props = PropsBase & RefAttributes<Ref>;

	type Value = Map.Search.Object | null;
	type Options = Option<Map.Search.Object>[];
}

export default SearchHouse;
