import { react } from "uikit";
import { Dispatch } from "react";
import Authorization from ".";

interface Context {
	value: Authorization.Value;

	setLoginError: Dispatch<boolean>;
	setPasswordError: Dispatch<boolean>;
}

export default class Controller extends react.ControllerBase<Context> {
	validate() {
		const isLoginValid = !!this.context?.value.login;
		const isPasswordValid =
			!!this.context?.value.password ||
			typeof this.context?.value.password === "undefined";

		if (!isLoginValid) this.context?.setLoginError(true);
		if (!isPasswordValid) this.context?.setPasswordError(true);

		return isLoginValid && isPasswordValid;
	}
}
