import React, { Dispatch, useMemo } from "react";
import { Option, Select } from "uikit";
import { useTranslation } from "react-i18next";
import LabeledField from "../../../../../../../../../../../../../../../../../components/LabeledField";
import useObjectEditor from "../../../../../../../../../../../../../../../../../hooks/useObjectEditor";
import Gender from "../../../../../../../../../../../../../../../../../types/Gender";

declare namespace GenderSelect {
	interface Value {
		gender?: Gender;
	}

	interface Props {
		value: Value;
		onChange: Dispatch<Value>;
	}
}

const options: Option<Gender>[] = [
	{
		key: "unknown",
		label: "genders.unknown",
		value: "unknown",
	},
	{
		key: "male",
		label: "genders.male",
		value: "male",
	},
	{
		key: "female",
		label: "genders.female",
		value: "female",
	},
];

const GenderSelect: React.FC<GenderSelect.Props> = ({ value, onChange }) => {
	const { t } = useTranslation();

	const valueEditor = useObjectEditor(value, onChange);
	const gender = valueEditor.useGetter("gender");
	const setGender = valueEditor.useSetter("gender");

	const localizedOptions = useMemo(
		() =>
			options.map((option) => ({
				...option,
				label: t(option.label),
			})),
		[t],
	);

	return (
		<LabeledField
			label={
				t(`mainPage.customers.clients.main.personalData.gender`) ||
				"Gender"
			}
		>
			<Select<Gender>
				value={gender}
				onSelect={(option) => {
					switch (option.value) {
						case "male":
						case "female":
							setGender(option.value);
							break;

						default:
							setGender("unknown");
					}
				}}
				options={localizedOptions}
				placeholder={t(`genders.unknown`) || "Unspecified"}
			/>
		</LabeledField>
	);
};

export default GenderSelect;
