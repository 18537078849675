import translationPath from "../../../../../../../../../../../constants/tPath";

const rewardPath = `${translationPath}.modal.tabs.reward`;

export interface ErrorResponse {
	message: string;
	status: 400 | 404 | 401 | 403 | 500;
	baseText: string;
	translation: string;
	canRetry: boolean;
}

type RequestErrorContext = "create" | "update";

export const unknownErrors: Record<RequestErrorContext, ErrorResponse> = {
	create: {
		message: "",
		status: 400,
		baseText: "Failed to create a new reward. Please try again.",
		translation: `${rewardPath}.errors.create`,
		canRetry: true,
	},
	update: {
		message: "",
		status: 400,
		baseText: "Failed to change the reward. Please try again.",
		translation: `${rewardPath}.errors.update`,
		canRetry: true,
	},
};

const errors: ErrorResponse[] = [
	{
		message:
			"The sum of percent and maxAmountFromOrderInPercent must be less than or equal to 100",
		status: 400,
		baseText:
			"The sum of percent and maxAmountFromOrderInPercent must be less than or equal to 100",
		translation: `${rewardPath}.errors.percent`,
		canRetry: false,
	},
];

/**
 * Retrieve prepared error by message
 * @param message Response error message from server
 * @param isEdit Flag to determine if the error is from edit or create
 */
export const getErrorByMessage = (message: string, isEdit: boolean) => {
	const error = errors.find((error) => error.message === message);
	if (!error) {
		const context = isEdit ? "update" : "create";
		return unknownErrors[context];
	}
	return error;
};

export default errors;
