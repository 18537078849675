import styled from "styled-components";
import { Column, theme } from "uikit";

const Root = styled(Column)<Root.Props>`
	${({ visible }) => (visible ? "" : "display: none;")}

	width: 100%;
	height: 100%;
	min-width: 900px;
	min-height: 500px;
	border-radius: ${theme.borderRadius.m};
	background-color: white;
	overflow: hidden;
`;

declare namespace Root {
	interface Props {
		visible: boolean;
	}
}

export default Root;
