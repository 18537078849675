import React from "react";
import { CheckBoxWithContent } from "uikit";
import { useTranslation } from "react-i18next";
import useObjectEditor from "../../../../../../../../../../../../../../../hooks/useObjectEditor";
import Client from "../../../../../../../../../../../../../../../services/Client";

declare namespace PersonalCabinet {
	type Value = Pick<Client.Model, "isAllowedCabinet">;

	interface Props {
		value: Value;

		onChange: (value: Value) => void;
	}
}

const PersonalCabinet: React.FC<PersonalCabinet.Props> = ({
	value,
	onChange,
}) => {
	const { t } = useTranslation();

	const valueEditor = useObjectEditor(value, onChange);
	const personalCabinet = valueEditor.useGetter("isAllowedCabinet");
	const setPersonalCabinet = valueEditor.useSetter("isAllowedCabinet");

	return (
		<CheckBoxWithContent
			gap="10px"
			value={personalCabinet}
			onChange={setPersonalCabinet}
			disabled
		>
			{t(`mainPage.customers.clients.options.access.personalCabinet`)}
		</CheckBoxWithContent>
	);
};

export default PersonalCabinet;
