import React, { Dispatch, RefAttributes, memo, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Column, react } from "uikit";
import { noop } from "lodash";

import { Executor } from "../../../../../../../../../../../../services";
import Language from "../../../../../../../../../../../../services/Language";
import useObjectEditor from "../../../../../../../../../../../../hooks/useObjectEditor";
import FieldsContainer from "../../../../../../../../../../../../components/FieldsContainer";
import {
	CheckBoxWithText,
	StyledColumn,
} from "../../../../../../../../../../../../components/common";
import TabRoot from "../TabRoot";

import InternalController from "./Controller";
import ServiceIds from "./components/ServiceIds";
import OrderPaymentTypes from "./components/OrderPaymentTypes";
import OrderSourceTypes from "./components/OrderSourceTypes";

const OptionBase = react.withController<
	OptionTab.PropsBase,
	OptionTab.Controller
>(({ value, disabled, visible, language, roleIds, onChange }) => {
	const { t } = useTranslation();
	const valueEditor = useObjectEditor(value, onChange);

	const taxiServiceId = valueEditor.useGetter("taxiServiceId");
	const serviceIds = valueEditor.useGetter("serviceIds");
	const serviceIdsOnChange = valueEditor.useSetter("serviceIds");

	const permitsEditor = valueEditor.usePropertyEditor("permits");

	const isPaymentCommissionsThroughPaymentSystem = permitsEditor.useGetter(
		"isPaymentCommissionsThroughPaymentSystem",
	);
	const isPaymentCommissionsThroughPaymentSystemOnChange =
		permitsEditor.useSetter("isPaymentCommissionsThroughPaymentSystem");

	const isAcceptanceOfCustomerPaymentsToPersonalAccount =
		permitsEditor.useGetter(
			"isAcceptanceOfCustomerPaymentsToPersonalAccount",
		);
	const isAcceptanceOfCustomerPaymentsToPersonalAccountOnChange =
		permitsEditor.useSetter(
			"isAcceptanceOfCustomerPaymentsToPersonalAccount",
		);

	const isAllowToSelectCarClassesInApp = permitsEditor.useGetter(
		"isAllowToSelectCarClassesInApp",
	);
	const isAllowToSelectCarClassesInAppOnChange = permitsEditor.useSetter(
		"isAllowToSelectCarClassesInApp",
	);

	const translateTexts = useMemo(() => {
		const tPath =
			"pages.mainPage.pages.accounts.tabs.executors.editModal.content.optionTab";

		return {
			checkBoxTitle: t(`${tPath}.str0`) ?? "",
			checkBox1: `${tPath}.str200`,
			checkBox2: `${tPath}.str201`,
			checkBox3: `${tPath}.str202`,
		};
	}, [t]);

	return (
		<TabRoot hasPaddings visible={visible}>
			<Column sizes="auto!*" gaps="19px*" maxedWidth maxedHeight>
				<ServiceIds
					value={serviceIds}
					disabled={disabled}
					language={language}
					roleIds={roleIds}
					onChange={serviceIdsOnChange}
					taxiServiceId={taxiServiceId}
				/>
				<OrderPaymentTypes
					value={[]}
					disabled={disabled}
					onChange={noop}
				/>
				<OrderSourceTypes
					value={[]}
					disabled={disabled}
					onChange={noop}
				/>
				<FieldsContainer label={translateTexts.checkBoxTitle}>
					<StyledColumn gap="10px">
						<CheckBoxWithText
							title={translateTexts.checkBox1}
							disabled
							value={isPaymentCommissionsThroughPaymentSystem}
							onChange={
								isPaymentCommissionsThroughPaymentSystemOnChange
							}
						/>
						<CheckBoxWithText
							title={translateTexts.checkBox2}
							disabled
							value={
								isAcceptanceOfCustomerPaymentsToPersonalAccount
							}
							onChange={
								isAcceptanceOfCustomerPaymentsToPersonalAccountOnChange
							}
						/>
						<CheckBoxWithText
							title={translateTexts.checkBox3}
							value={isAllowToSelectCarClassesInApp}
							onChange={isAllowToSelectCarClassesInAppOnChange}
						/>
					</StyledColumn>
				</FieldsContainer>
				<div style={{ height: 5 }} />
			</Column>
		</TabRoot>
	);
}, InternalController);

const OptionTab = memo(OptionBase);

declare namespace OptionTab {
	type Ref = InternalController | null;

	type Controller = InternalController;

	interface Value {
		serviceIds: number[];
		permits: Executor.Model["permits"];
		taxiServiceId?: number;
	}

	interface PropsBase {
		value: Value;

		disabled: boolean;
		visible: boolean;
		language: Language;
		roleIds: number[];

		onChange: Dispatch<Value>;
	}

	type Props = PropsBase & RefAttributes<Ref>;
}

export default OptionTab;
