import React, { memo } from "react";
import { useTranslation } from "react-i18next";
import Root from "./components/Root";
import Title from "./components/Title";
import InformationContainer from "./components/InformationContainer";

const Header = memo<Header.Props>(({ isNew }) => {
	const { t } = useTranslation();
	return (
		<Root sizes="auto!*">
			<InformationContainer
				align="center"
				justify="space-between"
				gaps="10px"
			>
				<Title>
					{isNew
						? t(
								"pages.mainPage.pages.accounts.tabs.workShifts.editModal.content.schedulesTab.editModal.header.str200",
						  ) ?? ""
						: t(
								"pages.mainPage.pages.accounts.tabs.workShifts.editModal.content.schedulesTab.editModal.header.str201",
						  ) ?? ""}
				</Title>
			</InformationContainer>
		</Root>
	);
});

declare namespace Header {
	interface Props {
		isNew: boolean;
	}
}

export default Header;
