import React from "react";
import HistoryTab from "../../../../../../../HistoryTab";

const History: React.FC<History.Props> = ({ value }) => (
	<HistoryTab visible={true} value={value} />
);

declare namespace History {
	interface Props {
		value: any;
	}
}

export default History;
