import React, { Dispatch, SetStateAction, useCallback, useMemo } from "react";
import { CheckBox } from "uikit";
import { RowDataType } from "rsuite-table";
import { useTranslation } from "react-i18next";

import Transaction from "../../../../services/Transaction";
import {
	useColumns,
	useVisibleColumns,
} from "../../../../hooks/useTableSettings";
import formatNumber from "../../../../utils/formatNumber";
import LightTable, { useTableOptions } from "../../../LightTable";
import { StyledColumn, StyledP, StyledRow } from "../../../common";

import TableSettings from "../../../TableSettings";
import Root from "./components/Root";
import Type from "./components/Type";
import TransactionAuthor from "./components/TransactionAuthor";
import PaymentAccountType from "./components/PaymentAccountType";
import Comment from "./components/Comment";

const Content: React.FC<Content.Props> = ({
	tableFrom,
	value,
	selected,
	setSelected,
}) => {
	const { t } = useTranslation();
	const { ctrlPressed, onKeyUp, onKeyDown, sort, onSort, onScroll, lang } =
		useTableOptions();

	const updateSelected = useCallback(
		(item: RowDataType<Transaction.Model>, force = false) => {
			const model = item as Transaction.Model;
			if (ctrlPressed || force) {
				if (selected.includes(model?.id)) {
					setSelected(selected.filter((id) => model?.id !== id));
				} else {
					setSelected([...selected, model?.id]);
				}
			} else {
				selected.includes(model.id)
					? setSelected([])
					: setSelected([model.id]);
			}
		},
		[ctrlPressed, selected, setSelected],
	);

	const columns = useColumns("transaction");
	const { columnIds, setColumnIds } = useVisibleColumns(
		tableFrom,
		"transaction",
	);

	const data = useMemo(
		() =>
			columnIds.map((columnId) => {
				const column = columns.find((col) => col.id === columnId);
				if (!column) return null;

				return (
					<LightTable.Column
						key={columnId}
						verticalAlign="middle"
						flexGrow={1}
						resizable
						sortable
					>
						<LightTable.HeaderCell>
							{column.label}
						</LightTable.HeaderCell>
						<LightTable.Cell
							style={{ padding: "0 15px" }}
							verticalAlign="middle"
							fullText
							dataKey={column.id}
						>
							{(rowData) => {
								const data = rowData as Transaction.Model;

								const {
									amount,
									metadata,
									operation,
									targetAction,
									createdAt,
									paymentAccount,
								} = data;

								switch (columnId) {
									case "date":
										if (!createdAt) return <></>;
										return (
											<StyledColumn gap="5px">
												<div>
													{new Date(
														createdAt,
													)?.toLocaleTimeString?.()}
												</div>
												<div>
													{new Date(
														createdAt,
													)?.toLocaleDateString?.()}
												</div>
											</StyledColumn>
										);

									case "type":
										return (
											<Type
												value={{
													metadata,
													targetAction,
												}}
											/>
										);

									case "paymentAccountType":
										return (
											<PaymentAccountType
												value={{
													type: paymentAccount?.type,
												}}
											/>
										);
									case "orderNumber":
										return (
											<StyledP>
												{operation?.order
													?.orderNumber ?? ""}
											</StyledP>
										);
									case "comment":
										return (
											<Comment
												operation={operation}
												metadata={metadata}
												lang={lang}
											/>
										);

									case "balanceBefore":
										return (
											<StyledP>
												{formatNumber(
													metadata.accountAmountBefore,
												)}
											</StyledP>
										);

									case "sum":
										return (
											<StyledP>
												{formatNumber(amount)}
											</StyledP>
										);

									case "balanceAfter":
										return (
											<StyledP>
												{formatNumber(
													metadata.accountAmountAfter,
												)}
											</StyledP>
										);

									case "notes":
										return (
											<StyledP>
												{operation.description || ""}
											</StyledP>
										);

									case "transactionAuthor":
										return (
											<TransactionAuthor
												operation={operation}
											/>
										);

									default:
										return null;
								}
							}}
						</LightTable.Cell>
					</LightTable.Column>
				);
			}),
		[columnIds, columns, t],
	);

	const getClassName = useCallback(
		(item) => (item && selected.includes(item.id) ? "selected" : ""),
		[selected],
	);

	const handleAllSelectedChange = useCallback(
		(isAllSelected) => {
			if (isAllSelected) {
				setSelected(value.map(({ id }) => id));
			} else {
				setSelected([]);
			}
		},
		[setSelected, value],
	);

	return (
		<Root sizes="100% auto!">
			<StyledRow
				w="100%"
				overY="auto"
				tabIndex={-1}
				onKeyDown={onKeyDown}
				onKeyUp={onKeyUp}
			>
				<LightTable
					data={value}
					virtualized
					shouldUpdateScroll={false}
					fillHeight
					rowHeight={46}
					style={{ width: "100%" }}
					rowClassName={getClassName}
					onRowClick={updateSelected}
					sortColumn={sort?.dataKey}
					sortType={sort?.sortType}
					onScroll={onScroll}
					onSortColumn={onSort}
				>
					<LightTable.Column verticalAlign="middle" width={32}>
						<LightTable.HeaderCell>
							<CheckBox
								value={
									selected.length === value.length &&
									value.length !== 0
								}
								onChange={handleAllSelectedChange}
							/>
						</LightTable.HeaderCell>
						<LightTable.Cell verticalAlign="middle">
							{(item) => (
								<CheckBox
									value={selected.includes(item.id)}
									onChange={() => {
										updateSelected(item, true);
									}}
								/>
							)}
						</LightTable.Cell>
					</LightTable.Column>
					{data}
				</LightTable>
			</StyledRow>
			<TableSettings
				value={columnIds}
				defaultValue={columnIds}
				columns={columns}
				onChange={setColumnIds}
			/>
		</Root>
	);
};

declare namespace Content {
	interface Props {
		tableFrom: string;
		value: Transaction.Model[];
		selected: number[];
		setSelected: Dispatch<SetStateAction<number[]>>;
	}
}

export default Content;
