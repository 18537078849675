import styled from "styled-components";
import { Column } from "uikit";

const Block = styled(Column)<Block.Props>`
	background-color: white;
	overflow: auto;
`;

declare namespace Block {
	interface Props extends Column.Props {}
}

export default Block;
