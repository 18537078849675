import React, { Dispatch, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Button, Icon, Row, theme } from "uikit";
import LabeledField from "../../../../../../../../../../../../../../components/LabeledField";
import Item from "./components/Item";
import useObjectEditor from "../../../../../../../../../../../../../../hooks/useObjectEditor";

const Insurances: React.FC<Insurances.Props> = ({
	value,

	disabled,

	onChange,

	onShowFileListModal,
}) => {
	const valueEditor = useObjectEditor(value, onChange);

	const addButtonOnClick = useCallback(() => {
		valueEditor.push({
			name: "",
			data: "",
		});
	}, [valueEditor]);

	const { t } = useTranslation();
	return (
		<>
			{value.map((item, index) => (
				<LabeledField
					key={index}
					label={`${
						t(
							"pages.mainPage.pages.accounts.tabs.car.editModal.content.additionalDataTab.insurances.str200",
						) ?? ""
					} ${index + 1}`}
				>
					<Row align="center" gaps="8px*" sizes="1fr 32px">
						<Item
							value={item}
							disabled={disabled}
							onChange={(newItem) => {
								valueEditor.set(index, newItem);
							}}
							onShowFileListModal={onShowFileListModal}
						/>
						{index !== 0 ? (
							<Button.Button
								variant="secondary"
								icon={
									<Icon
										id="trash"
										size={20}
										colors={[theme.colors.secondary]}
									/>
								}
								onClick={() => {
									valueEditor.remove(index);
								}}
							/>
						) : null}
					</Row>
				</LabeledField>
			))}
			<Button.Button
				icon={
					<Icon id="plus" size={16} colors={[theme.colors.white]} />
				}
				onClick={addButtonOnClick}
			/>
		</>
	);
};

declare namespace Insurances {
	type Item = Item.Value;

	type Value = Item[];

	interface Props {
		value: Value;

		disabled: boolean;

		onChange: Dispatch<Value>;

		onShowFileListModal: () => void;
	}
}

export default Insurances;
