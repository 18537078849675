import React, {
	Dispatch,
	RefAttributes,
	useCallback,
	useMemo,
	useState,
} from "react";
import { useTranslation } from "react-i18next";
import { MultiSelect, Option, react } from "uikit";
import CarPark from "../../../../../../../../../../../../../../../../services/CarPark";

import useModelSubscribe from "../../../../../../../../../../../../../../../../hooks/useModelSubscribe2";

import mapByKey from "../../../../../../../../../../../../../../../../utils/mapByKey";
import LabeledField from "../../../../../../../../../../../../../../../../components/LabeledField";
import InternalController from "./Controller";

const CarParkIds = react.withController<
	CarParkIds.PropsBase,
	CarParkIds.Controller
>(
	({
		value,

		disabled,
		taxiServiceIds,

		onChange,
	}) => {
		const { t } = useTranslation();
		const modelData = useModelSubscribe({ taxiServiceIds }, CarPark);

		const items = useMemo(() => modelData.models, [modelData.models]);

		const [error, setError] = useState(false);

		const selectOptions = useMemo<Option<number>[]>(
			() =>
				items.map((role) => ({
					key: role.id,
					label: role.name,
					value: role.id,
				})),
			[items],
		);

		const selectOptionByKey = useMemo(
			() => mapByKey(selectOptions, "key"),
			[selectOptions],
		);

		const selectOnChange = useCallback(
			(keys: any[]) => {
				setError(false);

				onChange(keys.map((key) => selectOptionByKey[key].value));
			},
			[onChange, selectOptionByKey],
		);

		return (
			<LabeledField
				label={
					t(
						"pages.mainPage.pages.accounts.tabs.users.editModal.content.mainTab.workData.carParkIds.str0",
					) ?? ""
				}
			>
				<MultiSelect
					value={value}
					disabled={disabled || items.length === 0}
					error={error}
					placeholder={
						t(
							"pages.mainPage.pages.accounts.tabs.users.editModal.content.mainTab.workData.carParkIds.str1",
						) ?? ""
					}
					options={selectOptions}
					onChange={selectOnChange}
				/>
			</LabeledField>
		);
	},
	InternalController,
);

declare namespace CarParkIds {
	type Ref = InternalController | null;

	type Controller = InternalController;

	type Value = number[];

	interface PropsBase {
		value: Value;

		disabled: boolean;
		taxiServiceIds: number[];

		onChange: Dispatch<Value>;
	}

	type Props = PropsBase & RefAttributes<Ref>;
}

export default CarParkIds;
