import { saveAs } from "file-saver";
import ExcelJS from "exceljs";
import JSPDF from "jspdf";
import JSZip from "jszip";
import autoTable, { RowInput, UserOptions } from "jspdf-autotable";
import { DatePicker } from "uikit";

import { DateRange } from "../../types/DataRange";
import IResponseWithItems from "../../types/IResponse";
import {
	formatDate,
	formatMoney,
} from "../../pages/Reports/pages/AccountingReports/utils/formatForFile";
import {
	TransactionAction,
	TransactionMetadataContext,
} from "../Transaction/types";
import { Base, Language } from "..";

class AgentBillReport extends Base {
	static fromResponse(data: any): AgentBillReport.Model {
		return data || [];
	}

	public static async getAgentBillReport(
		params: AgentBillReport.SearchOptions,
	): Promise<AgentBillReport.GetAgentBillReportResponse | null> {
		try {
			if (!params.dateRange) return null;

			const response = await this.request((prpc) =>
				prpc.theirsModel.report.getAgentBillReport(params),
			);

			console.log("[getAgentBillReport] getAgentBillReport res", {
				response,
			});
			if (!response) return null;

			return {
				...response,
				items: response?.items?.map(this.fromResponse),
			};
		} catch (error) {
			console.log("[getAgentBillReport] Error", error);

			return null;
		}
	}

	public static exportPDF = async (
		data: AgentBillReport.Model.Item[],
		options: {
			tableColumn: string[];
			columnStyles: UserOptions["columnStyles"];
			// get width from table
			width: number;
			// get height from table
			height: number;

			ColumnId: any;
			columnIds: any;
			textTranslations: Record<string, string>;
			headerTitle: string;
		},
	) => {
		if (!data?.length) return;
		const {
			tableColumn = [],
			width = 3500,
			height = 780,

			columnStyles,
			ColumnId,
			columnIds,
			textTranslations,
			headerTitle,
		} = options;

		const totalWidth = columnStyles
			? Object.values(columnStyles).reduce(
					(sum, { cellWidth }) => sum + +(cellWidth || 0),
					0,
			  ) +
			  Object.keys(columnStyles).length * (5.1 / 3.56) +
			  0.1 / 3 // добавляем небольшие отступы
			: width; // если columnStyles нет, используйте дефолтную ширину

		const realWidth = totalWidth || width;
		const doc = new JSPDF({
			orientation: "landscape",
			format: [realWidth, height],
		});

		let currentY = 10;
		let _dateFrom;
		let _dateTo;

		data.forEach(({ transactions, dateRange, counter }) => {
			doc.setFont("Roboto-Regular", "normal");

			const textName = transactions?.[0]?.name || "";

			const dateFrom = formatDate({
				date: dateRange.from,
				isTitleFormat: true,
			});

			const dateTo = formatDate({
				date: dateRange.to,
				isTitleFormat: true,
			});

			if (!_dateFrom) _dateFrom = dateFrom;
			if (!_dateTo) _dateTo = dateTo;

			// const nameTab = headerTitle;

			// Получаем ширину страницы
			const pageWidth = doc.internal.pageSize.getWidth();
			const centerX = pageWidth / 2;

			const textArray = [
				// `${textCompany} ${textFill}`,
				// `${nameTab}`,
				`${textName} ${textTranslations.forPeriod} ${textTranslations.from} ${dateFrom} ${textTranslations.to} ${dateTo}`,
			];

			// Проходим по каждой строке текста и выводим ее по центру
			textArray.forEach((text) => {
				// Получаем ширину текста
				const textWidth = doc.getTextWidth(text);

				// Вычисляем позицию для центрирования текста
				const textX = centerX - textWidth / 2;

				// Рисуем текст
				doc.text(text, textX, currentY);

				// Увеличиваем Y для следующей строки
				currentY += 10; // Например, отступ в 10 единиц между строками
			});

			const tableRows = transactions.map((order) => {
				const columnIdSet = new Set(columnIds);

				const retval: RowInput = [];

				if (columnIdSet.has(ColumnId.Date)) {
					retval.push({
						content: formatDate({ date: order.createdAt }),
					});
				}

				if (columnIdSet.has(ColumnId.OperationType)) {
					retval.push({
						content: `${order.type ?? " "}`,
					});
				}

				if (columnIdSet.has(ColumnId.Source)) {
					retval.push({
						content: `${order.targetAction ?? " "}`,
					});
				}

				if (columnIdSet.has(ColumnId.Order)) {
					retval.push({
						content: `${order.orderNumber ?? " "}`,
					});
				}

				if (columnIdSet.has(ColumnId.OperationDescription)) {
					retval.push({
						content: `${order.description ?? " "}`,
					});
				}

				if (columnIdSet.has(ColumnId.User)) {
					retval.push({
						content: `${order.name ?? " "}`,
					});
				}

				if (columnIdSet.has(ColumnId.AmountUah)) {
					retval.push({
						content: `${formatMoney(order.amount)}`,
					});
				}

				if (columnIdSet.has(ColumnId.BalanceUah)) {
					retval.push({
						content: `${formatMoney(order.accountAmountAfter)}`,
					});
				}

				return [...retval] as RowInput;
			});

			// Генерация таблицы с помощью autoTable
			autoTable(doc, {
				head: [tableColumn],
				body: tableRows,
				startY: currentY,
				styles: {
					fontSize: 12, // Размер шрифта
					cellPadding: 5, // Внутренние отступы
					lineWidth: 0.1, // Толщина линии границы
					lineColor: [0, 0, 0], // Цвет границы (черный)
					font: "Roboto-Regular",
					fontStyle: "normal",
				},
				columnStyles,
				headStyles: {
					fillColor: [41, 128, 185], // Цвет фона заголовка
					textColor: [255, 255, 255], // Цвет текста заголовка
					fontSize: 10,
				},
				margin: { top: 20 }, // Отступы сверху
				tableLineWidth: 0, // Толщина линии таблицы
				tableLineColor: [0, 0, 0], // Цвет линии таблицы
				theme: "grid",
			});

			currentY = doc?.lastAutoTable?.finalY;

			// const currency = transactions?.[0]?.currency?.name || "";

			doc.setFont("Roboto-Regular", "normal");
			doc.text(
				[`${textTranslations.feeForPeriod}: ${counter.amount ?? ""}`],
				30,
				currentY + 20,
			);
			currentY += 50;
		});

		const namefile = `${headerTitle ?? ""} - ${_dateFrom ?? ""} - ${
			_dateTo ?? ""
		}.pdf`;

		// Сохранение PDF
		doc.save(namefile);
	};

	public static exportExcel = async (
		_data: AgentBillReport.Model.Item[],
		options: {
			tableColumn: string[];
			columnStyles: UserOptions["columnStyles"];
			width: number;
			height: number;
			ColumnId: any;
			columnIds: any;
			textTranslations: Record<string, string>;
			headerTitle: string;
			isZip: boolean;
			dateFrom: DatePicker.Value;
			dateTo: DatePicker.Value;
		},
	) => {
		if (!_data?.length) return;

		const {
			tableColumn = [],
			width = 3500,
			height = 780,
			columnStyles,
			ColumnId,
			columnIds,
			textTranslations,
			headerTitle,
			isZip,
			dateFrom,
			dateTo,
		} = options;

		const _dateFrom = formatDate({
			date: dateFrom ?? new Date(),
			isTitleFormat: true,
		});
		const _dateTo = formatDate({
			date: dateTo ?? new Date(),
			isTitleFormat: true,
		});

		const namefile = `${headerTitle ?? ""} - ${_dateFrom ?? ""} - ${
			_dateTo ?? ""
		}`;

		const zip = new JSZip();
		const workbook = new ExcelJS.Workbook(); // Создаем новую книгу Excel

		const clearWorksheets = () => {
			workbook.worksheets.forEach((sheet) =>
				workbook.removeWorksheet(sheet.name),
			);
		};

		// Функция для создания и добавления отчета
		const createReport = async (
			data: AgentBillReport.Model.Item[],
			index: number,
		) => {
			const worksheet = workbook.addWorksheet(namefile); // Добавляем новый лист для каждого отчета

			tableColumn.forEach((colIndex, index) => {
				const cellWidth: number = +(
					columnStyles?.[index]?.cellWidth ?? 80
				); // Получаем ширину из columnStyles, если не определено - используем 80

				if (typeof cellWidth === "number" && cellWidth > 0) {
					// Устанавливаем ширину колонки
					worksheet.getColumn(index + 1).width = Math.round(
						cellWidth / 2.5,
					); // Используем индекс + 1, так как индексация начинается с 1
				} else {
					console.warn(
						`2 Width for column ${colIndex} is undefined or invalid: cellWidth`,
						cellWidth,
					);
				}
			});

			data.forEach(({ transactions, dateRange, counter }) => {
				const textName = transactions?.[0]?.name || "";

				const dateFrom = formatDate({
					date: dateRange.from,
					isTitleFormat: true,
				});
				const dateTo = formatDate({
					date: dateRange.to,
					isTitleFormat: true,
				});

				const emptyCellsHeader = Math.floor(
					(tableColumn.length - 1) / 2,
				);

				// Функция для добавления заголовка в Excel
				const addHeaderRow = (
					text: string,
					emptyCellsHeader: number,
				) => {
					const headerRow = worksheet.addRow([text]); // Добавляем строку для текущего элемента массива
					headerRow.font = {
						// bold: true,
						size: 10,
						name: "Arial",
					}; // Жирный шрифт для шапки
					headerRow.alignment = {
						horizontal: "left", // Выровнено по левому краю
						vertical: "middle",
						wrapText: false, // Отключаем перенос текста
					};

					// Установка высоты строки, чтобы текст был полностью виден
					headerRow.height = 30; // Устанавливаем высоту строки, вы можете изменить значение по необходимости

					// Объединение ячеек (от текущей ячейки до 6-й вправо)
					worksheet.mergeCells(
						`A${headerRow.number}:F${headerRow.number}`,
					);

					// Выравнивание содержимого объединенной ячейки по центру
					headerRow.getCell(1).alignment = {
						horizontal: "center",
						vertical: "middle",
					};
				};

				// Массив заголовков
				const textheaderTable = [
					`${textName} ${textTranslations.forPeriod} ${textTranslations.from} ${dateFrom} ${textTranslations.to} ${dateTo}`,
				];

				// Добавляем заголовки
				textheaderTable.forEach((text, index) => {
					addHeaderRow(text, emptyCellsHeader);
				});

				// Применяем стили к заголовкам
				const titleRow = worksheet.addRow(tableColumn);
				titleRow.font = {
					// bold: true,
					name: "Calibri",
					size: 11,
					color: { argb: "ffffff" },
				};
				titleRow.alignment = {
					horizontal: "center",
					vertical: "middle",
					wrapText: true,
				};
				titleRow.fill = {
					type: "pattern",
					pattern: "solid",
					fgColor: { argb: "4baac6" },
				}; // Устанавливаем цвет ячейки заголовков
				titleRow.border = {
					top: { style: "thin", color: { argb: "FF000000" } },
					bottom: { style: "thin", color: { argb: "FF000000" } },
				};

				// Рассчитываем высоту строки на основе содержимого заголовка

				const maxHeight = Math.min(
					3, // Ограничение не более 3 строк
					Math.max(
						...tableColumn.map((cell) => {
							if (typeof cell === "string") {
								// Пример: вычисляем высоту, основываясь на длине текста
								return Math.ceil(cell.length / 10); // Здесь 15 — это количество символов на строку
							}
							return 1; // Минимальная высота для нестроковых значений
						}),
					),
				);

				// Устанавливаем высоту строки
				titleRow.height = maxHeight * 13; // Пример: 15 — это базовая высота строки, можно настроить по своему усмотрению

				transactions.forEach((order, index) => {
					const row: (string | number | undefined | Date | null)[] =
						[];

					const columnIdSet = new Set(columnIds);

					if (columnIdSet.has(ColumnId.Date)) {
						const whenDate = order.createdAt
							? new Date(order.createdAt)
							: null; // Преобразуем в миллисекунды
						row.push(whenDate);
					}

					if (columnIdSet.has(ColumnId.OperationType))
						row.push(order.type || "");

					if (columnIdSet.has(ColumnId.Source))
						row.push(order.targetAction || "");

					if (columnIdSet.has(ColumnId.Order))
						row.push(order.orderNumber || "");

					if (columnIdSet.has(ColumnId.OperationDescription))
						row.push(order.description || "");

					if (columnIdSet.has(ColumnId.User))
						row.push(order.name || "");

					if (columnIdSet.has(ColumnId.AmountUah)) {
						const formattedPrice = formatMoney(order.amount); // Получаем форматированную строку
						row.push(parseFloat(formattedPrice)); // Преобразуем строку в число
					}

					if (columnIdSet.has(ColumnId.BalanceUah)) {
						const formattedPrice = formatMoney(
							order.accountAmountAfter,
						); // Получаем форматированную строку
						row.push(parseFloat(formattedPrice)); // Преобразуем строку в число
					}

					function isOdd(num: number): boolean {
						return num % 2 !== 0; // Проверяем, нечетное ли число
					}

					const newRow = worksheet.addRow(row);
					newRow.alignment = {
						horizontal: "center",
						vertical: "middle",
						wrapText: true,
					}; // Централизация строк
					newRow.font = { name: "Arial", size: 10 }; // Применяем стиль к строкам

					// Функция для получения индекса столбца
					const getColumnIndex = (columnId) =>
						Array.from(columnIdSet).indexOf(columnId) + 1;

					// Форматирование ячейки для Date
					if (columnIdSet.has(ColumnId.Date)) {
						const createdCell = newRow.getCell(
							getColumnIndex(ColumnId.Date),
						);

						createdCell.numFmt = "DD.MM.YYYY HH:mm:ss"; // Формат даты и времени
						createdCell.alignment = { wrapText: true };
					}

					// Форматирование ячейки для AmountUah
					if (columnIdSet.has(ColumnId.AmountUah)) {
						const amountCell = newRow.getCell(
							getColumnIndex(ColumnId.AmountUah),
						);
						const currency = "₴"; // ! Here we need a dynamic currency. We need to get the currency value from the back
						const currencyFormat =
							{
								"₴": "[$₴-uk-UA]",
								"₼": "[$₼-az-AZ]",
								zł: "[$zł-pl-PL]",
							}[currency] || "[$₴-uk-UA]";

						amountCell.numFmt = `#,#0.00 ${currencyFormat} ; -#,##0.00 ${currencyFormat} ; "-"?? ${currencyFormat} ; @_`;
						amountCell.alignment = { wrapText: true }; // Включаем перенос текста
					}

					// Форматирование ячейки для BalanceUah
					if (columnIdSet.has(ColumnId.BalanceUah)) {
						const amountCell = newRow.getCell(
							getColumnIndex(ColumnId.BalanceUah),
						);
						const currency = "₴"; // ! Here we need a dynamic currency. We need to get the currency value from the back
						const currencyFormat =
							{
								"₴": "[$₴-uk-UA]",
								"₼": "[$₼-az-AZ]",
								zł: "[$zł-pl-PL]",
							}[currency] || "[$₴-uk-UA]";

						amountCell.numFmt = `#,#0.00 ${currencyFormat} ; -#,##0.00 ${currencyFormat} ; "-"?? ${currencyFormat} ; @_`;
						amountCell.alignment = { wrapText: true }; // Включаем перенос текста
					}

					if (isOdd(index)) {
						newRow.fill = {
							type: "pattern",
							pattern: "solid",
							fgColor: { argb: "d9d9d9" },
						}; // Устанавливаем цвет ячейки заголовков
					}
					if (transactions.length - 1 === index) {
						newRow.border = {
							bottom: {
								style: "thin",
								color: { argb: "FF000000" },
							},
						};
					}
					const maxHeight = Math.min(
						3, // Ограничение не более 3 строк
						Math.max(
							...row.map((cell) => {
								if (typeof cell === "string") {
									// Пример: вычисляем высоту, основываясь на длине текста
									return Math.ceil(cell.length / 10); // Здесь 15 — это количество символов на строку
								}
								return 1; // Минимальная высота для нестроковых значений
							}),
						),
					);

					// Устанавливаем высоту строки
					newRow.height = maxHeight * 13; // Пример: 15 — это базовая высота строки, можно настроить по своему усмотрению

					newRow.alignment = {
						horizontal: "center", // Горизонтальное центрирование
						vertical: "middle", // Вертикальное центрирование
						wrapText: true, // Перенос текста
					};
				});

				// // Итоговые строки с количеством заказов и суммами
				// const currency = transactions?.[0]?.currency?.name || "";

				const footerThree = worksheet.addRow([
					`${textTranslations.feeForPeriod}:`,
					counter.amount || 0,
				]);

				footerThree.eachCell((cell) => {
					cell.border = {
						top: { style: "thin" },
						left: { style: "thin" },
						bottom: { style: "thin" },
						right: { style: "thin" },
					};
					cell.alignment = {
						horizontal: "center",
						vertical: "middle",
					};
					cell.font = { name: "Calibri", size: 11 };
					cell.fill = {
						type: "pattern",
						pattern: "solid",
						fgColor: { argb: "F9F9F9" },
					};
				});

				// Добавляем пустую строку для разделения между разными отчетами
				worksheet.addRow([]);
				worksheet.addRow([]);
			});

			const buffer = await workbook.xlsx.writeBuffer();

			if (isZip) {
				// Генерация файла Excel

				const agentName = data?.[0]?.transactions?.[0]?.name || "";

				zip.file(`${agentName ?? "Report"}.xlsx`, buffer); // Добавляем файл в ZIP-архив
			} else {
				const blob = new Blob([buffer], {
					type: "application/octet-stream",
				});
				saveAs(blob, nameFileExcelOnly);
			}
		};

		const nameFileZip = `${namefile}.zip`;
		const nameFileExcelOnly = `${namefile}.xlsx`;

		// Сохранение файла Excel

		if (isZip) {
			// Создаем отчеты
			for (let index = 0; index < _data.length; index++) {
				clearWorksheets(); // Очищаем существующие листы перед добавлением нового
				await createReport([_data[index]], index);
			}

			// Сохранение ZIP-файла
			const zipContent = await zip.generateAsync({ type: "blob" });
			saveAs(zipContent, nameFileZip);

			// zip.file("data.xlsx", buffer);
			// const content = await zip.generateAsync({ type: "blob" });
			// saveAs(content, nameFileZip);
		} else {
			createReport(_data, 0);
			// const blob = new Blob([buffer], {
			// 	type: "application/octet-stream",
			// });
			// saveAs(blob, nameFileExcelOnly);
		}
	};
}

declare namespace AgentBillReport {
	type Model = AgentBillReport.Model.Item;

	interface GetAgentBillReportResponse extends IResponseWithItems<Model> {}

	interface SearchOptions {
		agentIds?: number[];
		taxiServiceIds?: number[];
		dateRange: DateRange;
		lang: Language;
	}

	namespace Model {
		interface Counter {
			amount: number;
		}
		interface TransactionItem {
			id: number;
			agentId: number;
			orderNumber: number;
			createdAt: string | Date;
			// src/pages/MainPage/pages/Archives/tabs/Transactions/components/Content/components
			type: TransactionMetadataContext | string;
			amount: number;
			// src/pages/MainPage/pages/Archives/tabs/Transactions/components/Content/components
			comment: string;
			accountAmountAfter: number;
			accountAmountBefore: number;
			description: string;
			// src/pages/MainPage/pages/Archives/tabs/Transactions/components/Content/components
			targetAction: TransactionAction | string;
			name: string;
		}
		interface Item {
			dateRange: DateRange;
			transactions: TransactionItem[];
			counter: Counter;
		}
	}
}

export default AgentBillReport;
