import React, { useEffect } from "react";
import { NotUndefined } from "object-hash";

import useModelSubscribe from "../../hooks/useModelSubscribe2";

declare namespace MountableSubscription {
	interface Props<SubscribeOptions extends NotUndefined, Model> {
		subscribeOptions: SubscribeOptions;
		Service: useModelSubscribe.Service<SubscribeOptions, Model>;
		options?: useModelSubscribe.Options<Model>;

		onLoadingChange?: (value: boolean) => void;
		onModelsChange?: (value: Model[]) => void;
		onMetadataChange?: (value: any) => void;
	}
}

function MountableSubscription<SubscribeOptions extends NotUndefined, Model>({
	subscribeOptions,
	Service,
	options = { debounce: 300 },

	onLoadingChange,
	onModelsChange,
	onMetadataChange,
}: MountableSubscription.Props<SubscribeOptions, Model>) {
	const { loading, models, metadataState } = useModelSubscribe(
		subscribeOptions,
		Service,
		options,
	);

	useEffect(() => {
		onLoadingChange?.(loading);
	}, [loading, onLoadingChange]);

	useEffect(() => {
		onModelsChange?.(models);
	}, [models, onModelsChange]);

	useEffect(() => {
		const total = (metadataState.total as number) ?? 0;

		onMetadataChange?.({
			...metadataState,
			total,
		});
	}, [metadataState, onMetadataChange]);

	return <></>;
}

export default MountableSubscription;
