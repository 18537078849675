import React, { RefAttributes, memo, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { TextBox, react } from "uikit";
import LabeledField from "../../../../../../../../../../../../../../../../components/LabeledField";
import { useTypedSelector } from "../../../../../../../../../../../../../../../../redux/store";
import { IName } from "../../../../../../../../../../../../../../../../types/IName";
import tPath from "../../../../../../../../../../constants/tPath";
import Controller from "./Controller";

const path = `${tPath}.modal.tabs.main.mainData.name`;

const NameBase = react.withController<Name.PropsBase, Controller>(
	({ value, onChange, controller }) => {
		const { t } = useTranslation();
		const lang = useTypedSelector((state) => state.session.language);

		const [nameErr, setNameErr] = useState(false);

		const handleNameUpdate = useCallback(
			(newName: string) => {
				setNameErr(false);
				onChange({ [lang]: newName });
			},
			[lang, onChange],
		);

		controller.setContext({ value, setNameErr });

		return (
			<LabeledField label={t(path) || ""}>
				<TextBox.TextBox
					error={nameErr}
					placeholder={t(path) || ""}
					value={value[lang]}
					onChange={handleNameUpdate}
				/>
			</LabeledField>
		);
	},
	Controller,
);

const Name = memo(NameBase);

declare namespace Name {
	type Ref = Controller | null;

	interface PropsBase {
		value: IName;
		onChange: (x: IName) => void;
	}
	type Props = PropsBase & RefAttributes<Ref>;
}

export default Name;
