import React from "react";
import { CheckBoxWithContent } from "uikit";
import { useTranslation } from "react-i18next";
import useObjectEditor from "../../../../../../../../../../../../../../../hooks/useObjectEditor";
import Client from "../../../../../../../../../../../../../../../services/Client";

declare namespace SMSNotifications {
	type Value = Pick<Client.Model, "smsNotifications">;

	interface Props {
		value: Value;

		onChange: (value: Value) => void;
	}
}

const SMSNotifications: React.FC<SMSNotifications.Props> = ({
	value,
	onChange,
}) => {
	const { t } = useTranslation();

	const valueEditor = useObjectEditor(value, onChange);
	const smsNotifications = valueEditor.useGetter("smsNotifications");
	const setSMSNotifications = valueEditor.useSetter("smsNotifications");

	return (
		<CheckBoxWithContent
			disabled
			gap="10px"
			value={smsNotifications}
			onChange={setSMSNotifications}
		>
			{t(
				`mainPage.customers.clients.options.permissions.smsNotifications`,
			)}
		</CheckBoxWithContent>
	);
};

export default SMSNotifications;
