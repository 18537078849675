import { Option } from "uikit";

// eslint-disable-next-line no-shadow
export enum EmployeesTabs {
	MAIN = "main",
	ORDERS = "orders",
	TRANSACTIONS = "transactions",
	FILES = "files",
	HISTORY = "history",
}

export const tabs: Option<string>[] = [
	{
		key: EmployeesTabs.MAIN,
		value: EmployeesTabs.MAIN,
		label: "",
	},
	{
		key: EmployeesTabs.ORDERS,
		value: EmployeesTabs.ORDERS,
		label: "",
	},
	{
		key: EmployeesTabs.TRANSACTIONS,
		value: EmployeesTabs.TRANSACTIONS,
		label: "",
	},
	{
		key: EmployeesTabs.FILES,
		value: EmployeesTabs.FILES,
		label: "",
	},
	{
		key: EmployeesTabs.HISTORY,
		value: EmployeesTabs.HISTORY,
		label: "",
	},
];
