import { react } from "uikit";
import Description from ".";

interface Context {
	value: Description.Value;
	setError: (x: boolean) => void;
}

export default class Controller extends react.ControllerBase<Context> {
	validate() {
		const isValid = !!this.context?.value?.trim();

		if (!isValid) this.context?.setError(true);

		return isValid;
	}
}
