/* eslint-disable react-hooks/rules-of-hooks */

import React, {
	Dispatch,
	RefAttributes,
	memo,
	useCallback,
	useState,
} from "react";
import { useTranslation } from "react-i18next";
import { Row, TextBox, react } from "uikit";
import useObjectEditor from "../../../../../../../../../../../../../../../../hooks/useObjectEditor";
import LabeledField from "../../../../../../../../../../../../../../../../components/LabeledField";

import InternalController from "./Controller";

const FullName = memo(
	react.withController<FullName.PropsBase, FullName.Controller>(
		({ controller, value, disabled, onChange }) => {
			const { t } = useTranslation();
			const valueEditor = useObjectEditor(value, onChange);

			const [firstNameError, setFirstNameError] = useState(false);

			const lastNameOnChange = useCallback(
				(lastName: string) => {
					valueEditor.set("lastName", lastName);
				},
				[valueEditor],
			);

			const firstNameOnChange = useCallback(
				(firstName: string) => {
					setFirstNameError(false);

					valueEditor.set("firstName", firstName);
				},
				[valueEditor],
			);

			const fatherNameOnChange = useCallback(
				(fatherName: string) =>
					valueEditor.set("fatherName", fatherName),
				[valueEditor],
			);

			controller.setContext({ value, setFirstNameError });

			return (
				<Row sizes="275.5px*" gaps="8px*">
					<LabeledField
						label={
							t(
								"pages.mainPage.pages.accounts.tabs.executors.editModal.content.mainTab.personData.fullName.str0",
							) ?? ""
						}
					>
						<TextBox.TextBox
							value={valueEditor.get("lastName")}
							disabled={disabled}
							style={{
								minHeight: "32px",
								flex: "1 0 0",
							}}
							autoComplete="one-time-code"
							placeholder={
								t(
									"pages.mainPage.pages.accounts.tabs.executors.editModal.content.mainTab.personData.fullName.str0",
								) ?? ""
							}
							onChange={lastNameOnChange}
						/>
					</LabeledField>
					<LabeledField
						label={
							t(
								"pages.mainPage.pages.accounts.tabs.executors.editModal.content.mainTab.personData.fullName.str2",
							) ?? ""
						}
					>
						<TextBox.TextBox
							value={valueEditor.get("firstName")}
							disabled={disabled}
							style={{
								minHeight: "32px",
								flex: "1 0 0",
							}}
							autoComplete="one-time-code"
							placeholder={
								t(
									"pages.mainPage.pages.accounts.tabs.executors.editModal.content.mainTab.personData.fullName.str2",
								) ?? ""
							}
							error={firstNameError}
							onChange={firstNameOnChange}
						/>
					</LabeledField>
					<LabeledField
						label={
							t(
								"pages.mainPage.pages.accounts.tabs.executors.editModal.content.mainTab.personData.fullName.str4",
							) ?? ""
						}
					>
						<TextBox.TextBox
							value={valueEditor.get("fatherName")}
							disabled={disabled}
							style={{
								minHeight: "32px",
								flex: "1 0 0",
							}}
							autoComplete="one-time-code"
							placeholder={
								t(
									"pages.mainPage.pages.accounts.tabs.executors.editModal.content.mainTab.personData.fullName.str4",
								) ?? ""
							}
							onChange={fatherNameOnChange}
						/>
					</LabeledField>
				</Row>
			);
		},
		InternalController,
	),
);

declare namespace FullName {
	type Ref = InternalController | null;

	type Controller = InternalController;

	interface Value {
		firstName: string;
		lastName?: string;
		fatherName?: string;
	}

	interface PropsBase {
		value: Value;

		disabled: boolean;

		onChange: Dispatch<Value>;
	}

	type Props = PropsBase & RefAttributes<Ref>;
}

export default FullName;
