import React, {
	RefAttributes,
	memo,
	useCallback,
	useLayoutEffect,
	useState,
} from "react";
import { react, useRefWithSetter } from "uikit";
import { useTranslation } from "react-i18next";

import Counterparty from "../../../../../../../../services/Counterparty";
import useObjectEditor from "../../../../../../../../hooks/useObjectEditor";
import { NonEditableProperties } from "../../../../../../../../types/NonEditableProperties";
import EditModal from "../../../../../../../../components/EditModal";
import DeleteModal from "../../../../../../../../components/DeleteModal";
import tPath from "../../constants/tPath";

import InternalController from "./Controller";
import { CounterpartiesTabs } from "./constants/tabs";
import Header from "./components/Header";
import Content from "./components/Content";
import Root from "./components/Root";
import Footer from "./components/Footer";

const ModalBase = react.withController<Modal.PropsBase, Modal.Controller>(
	({ controller, groups, value, onCancel, onSave, onChange }) => {
		const [contentRef, setContentRef] =
			useRefWithSetter<Content.Ref | null>(null);

		const { t } = useTranslation();

		const [tab, setTab] = useState<CounterpartiesTabs>(
			CounterpartiesTabs.MAIN,
		);

		const valueEditor = useObjectEditor(value, onChange);
		const setChecks = valueEditor.useSetter("checks");

		useLayoutEffect(() => {
			if (value.checks) {
				setChecks(value.checks);
			}
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [value.checks]);

		const contentValues = valueEditor.usePicker([
			"additionalFields",
			"personalFiles",
			"otherFiles",
			"checks",
			"accessAllEmployeeToPersonalApp",
			"addCustomerAsEmployeeAfterOrder",
			"corporateLogin",
			"corporatePassword",
			"isAccessToCorporateAccount",
			"companyId",
			"defaultTaxiServiceId",
			"counterpartyGroupId",
			"paymentAccounts",
			"paymentTransactions",
			"allowGeneralBonusBalance",
			"ignoreGeneralThresholdBalance",
		]);

		const footerValues = valueEditor.usePicker(["active"]);

		const assignValue = valueEditor.useAssigner();

		const [infoModalLabel, setInfoModalLabel] = useState("");

		const handleCloseInfoModal = useCallback(() => {
			setInfoModalLabel("");
		}, [setInfoModalLabel]);

		const modalOnSave = useCallback(() => {
			if (!contentRef.current?.validate()) return;
			if (!value.checks.length) {
				const text = t([`${tPath}.warn.check`]);
				setInfoModalLabel(text);
				return;
			}
			if (!value.checks.every((c) => !!c.checkCards.length)) {
				const text = t([`${tPath}.warn.card`]);
				setInfoModalLabel(text);
				return;
			}

			onSave(value as Counterparty.New | Counterparty.Model);
		}, [contentRef, onSave, value, t]);

		controller.setContext({ contentRef });
		return (
			<>
				<EditModal
					onCancel={onCancel}
					onSave={modalOnSave}
					footer={
						<Footer value={footerValues} onChange={assignValue} />
					}
				>
					<Root>
						<Header
							tab={tab}
							title={
								value.additionalFields?.name
									? `${t(`${tPath}.modal.editTitle`)} "${
											value.additionalFields?.name
									  }"`
									: t(`${tPath}.modal.title`)
							}
							createdAt={value.createdAt}
							onChangeTab={setTab}
						/>
						<Content
							ref={setContentRef}
							id={value.id}
							groups={groups}
							value={contentValues}
							tab={tab}
							onChange={assignValue}
						/>
					</Root>
				</EditModal>
				{infoModalLabel.length && (
					<DeleteModal
						label={infoModalLabel}
						onConfirm={handleCloseInfoModal}
						onCancel={() => {
							setInfoModalLabel("");
						}}
						isCancel={false}
					/>
				)}
			</>
		);
	},
	InternalController,
);
const Modal = memo(ModalBase);

declare namespace Modal {
	type Ref = InternalController | null;
	type Controller = InternalController;

	interface PropsBase {
		onChange: (value: any) => void;
		groups: Content.Props["groups"];
		value: Value;
		onCancel: () => void;
		onSave: (value: Counterparty.New | Counterparty.Model) => void;
	}
	type Props = PropsBase & RefAttributes<Ref>;

	interface Value extends Content.Value, Partial<NonEditableProperties> {
		active: boolean;
	}
}

export default Modal;
