import { Dispatch, SetStateAction } from "react";
import { Option } from "uikit";
import Map from "../../../../../../../../../../../../../../../../services/Map";
import { settlementToString } from "../../../../../../../../../../../../../../../../utils/settlementToString";

type Options = Option<Map.Search.Object>[];

export default async function search(
	query: string,
	optionsToRequest: Map.Search.Options,
	setTextSelectOptions: Dispatch<SetStateAction<Options>>,
): Promise<void> {
	const res = await Map.search(query, optionsToRequest);
	const { objects } = res;

	if (objects.length) {
		const newOptions: Options = objects.map((obj) => ({
			key: obj.id,
			value: obj,
			label: getOptionLabels(optionsToRequest.searchType, obj),
		}));
		setTextSelectOptions(newOptions);
	}
}

function getOptionLabels(
	searchType: Map.Search.Options["searchType"],
	obj: Map.Search.Object,
): string {
	switch (searchType) {
		case "street":
			return `${obj.streetType ?? ""} ${obj.street}`;

		case "settlement":
			return settlementToString(obj);

		case "address":
			return obj.number ?? "";

		default:
			return "";
	}
}
