import React, {
	Dispatch,
	RefAttributes,
	memo,
	useState,
	useCallback,
} from "react";
import { Column, Row, TextBox, react, useRefWithSetter } from "uikit";

import LabeledField from "../../../../../../../../../../../../../../../components/LabeledField";
import useObjectEditor from "../../../../../../../../../../../../../../../hooks/useObjectEditor";
import Nullable from "../../../../../../../../../../../../../../../types/Nullable";
import Gender from "../../../../../../../../../../../../../../../types/Gender";

import FullName from "./components/FullName";
import Phones from "./components/Phones";
import GenderSelect from "./components/GenderSelect";
import Birthday from "./components/Birthday";
import InternalController from "./Controller";
import { validateEmail } from "../../../../../../../../../../../../../../../utils/validateEmail";

declare namespace PersonData {
	type Ref = InternalController;

	type Controller = InternalController;

	interface Value extends FullName.Value {
		phones: Phones.Value;
		email?: string;
		birthday: Nullable<Date>;
		gender: Gender;
	}

	interface PropsBase {
		value: Value;
		onChange: Dispatch<Value>;
	}

	type Props = PropsBase & RefAttributes<Ref>;
}

const PersonDataBase = react.withController<
	PersonData.PropsBase,
	PersonData.Controller
>(({ controller, value, onChange }) => {
	const [phonesRef, setPhonesRef] = useRefWithSetter<Phones.Ref | null>(null);

	const valueEditor = useObjectEditor(value, onChange);

	const email = valueEditor.useGetter("email");
	const setEmail = valueEditor.useSetter("email");

	const onEmailChange = useCallback(
		(newValue: string) => {
			const isValid = validateEmail(newValue);
			setEmail(newValue);
			setEmailError(!isValid);
		},
		[setEmail],
	);

	const phones = valueEditor.useGetter("phones");
	const setPhones = valueEditor.useSetter("phones");
	const [phonesErr, setPhonesErr] = useState<boolean[]>([]);

	const picker = valueEditor.usePicker([
		"firstName",
		"lastName",
		"fatherName",
		"gender",
		"birthday",
	]);
	const assigner = valueEditor.useAssigner();

	const [emailError, setEmailError] = useState(false);

	controller.setContext({
		phonesRef,
		email,
		onEmailError: setEmailError,
	});

	return (
		<Column gaps="8px*">
			<Row gaps="8px*" sizes="275.5px*">
				<FullName value={picker} onChange={assigner} />

				<Row gaps="8px*">
					<Birthday value={picker} onChange={assigner} />
					<GenderSelect value={picker} onChange={assigner} />
				</Row>
			</Row>

			<Row gaps="8px*" sizes="275.5px*" align="flex-end">
				<LabeledField label="E-mail">
					<TextBox.TextBox
						style={{
							minHeight: "32px",
							flex: "1 0 0",
						}}
						type="email"
						placeholder="E-mail"
						autoComplete="one-time-code"
						value={email}
						onChange={onEmailChange}
						error={emailError}
					/>
				</LabeledField>

				<Phones
					ref={setPhonesRef}
					max={3}
					value={phones}
					onChange={setPhones}
					errors={phonesErr}
				/>
			</Row>

			<Row gaps="8px*" sizes="275.5px*"></Row>
		</Column>
	);
}, InternalController);

const PersonData = memo(PersonDataBase);

export default PersonData;
