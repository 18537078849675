import React, { Dispatch, SetStateAction, memo } from "react";
import { Row } from "uikit";
import DateRange from "./components/DateRange";
import PopupFilter from "./components/PopupFilter";
import Transaction from "../../../../services/Transaction";
import useObjectEditor from "../../../../hooks/useObjectEditor";

const Header: React.FC<Header.Props> = memo(({ options, setOptions }) => {
	const editor = useObjectEditor(options, setOptions);

	const dateRange = editor.useGetter("dateRange");
	const setDateRange = editor.useSetter("dateRange");

	return (
		<Row
			style={{ padding: "15px 15px 5px 15px" }}
			justify="center"
			gaps="20px*"
		>
			<Row gaps="15px*" justify="center" align="center">
				<DateRange value={dateRange} onChange={setDateRange} />
				<PopupFilter />
			</Row>
		</Row>
	);
});

declare namespace Header {
	interface Props {
		options: Transaction.SubscribeOptions;
		setOptions: Dispatch<SetStateAction<Transaction.SubscribeOptions>>;
	}
}

export default Header;
