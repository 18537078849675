/* eslint-disable no-shadow */
import React, {
	Dispatch,
	ReactElement,
	useCallback,
	useMemo,
	useRef,
} from "react";
import { UseTranslationResponse, useTranslation } from "react-i18next";
import { CheckBox, Column } from "uikit";
import { RowDataType, SortType } from "rsuite-table";
import { clone, isUndefined } from "lodash";
import moment from "moment";

import KeyBinder from "../../../../../../../../../../services/KeyBinder";
import Language from "../../../../../../../../../../services/Language";
import Car from "../../../../../../../../../../services/Car";
import {
	useVisibleColumns,
	useWidths,
} from "../../../../../../../../../../hooks/useTableSettings";
import Map from "../../../../../../../../../../redux/services/Map";
import LightTable from "../../../../../../../../../../components/LightTable";
import TableSettings from "../../../../../../../../../../components/TableSettings";
import {
	TabKeys,
	ARR_ACCESS_PART_KEY,
} from "../../../../../../constants/access";

import { getColumns, defaultColumnIds } from "./constants";

interface ColumnContext {
	language: Language;

	width: number;
	t: UseTranslationResponse<"translation", undefined>[0];
	onResize: (width?: number, columnId?: string) => void;
}

type CellContent = (rowData: RowDataType, rowIndex?: number) => React.ReactNode;

const Columns: Record<string, (context: ColumnContext) => ReactElement> = {
	parkNumber: ({ width, onResize, t }) => (
		<LightTable.Column
			width={width}
			fixed
			sortable
			resizable
			onResize={onResize}
		>
			<LightTable.HeaderCell>
				{t(
					"pages.mainPage.pages.accounts.tabs.car.content.modelTable.str100",
				) ?? ""}
			</LightTable.HeaderCell>
			<LightTable.Cell dataKey="parkNumber">
				{
					((item: Car.Model) =>
						item.parkNumber) as unknown as CellContent
				}
			</LightTable.Cell>
		</LightTable.Column>
	),

	aliases: ({ width, onResize, t }) => (
		<LightTable.Column width={width} sortable resizable onResize={onResize}>
			<LightTable.HeaderCell verticalAlign="middle">
				{t(
					"pages.mainPage.pages.accounts.tabs.car.content.modelTable.str150",
				) ?? ""}
			</LightTable.HeaderCell>
			<LightTable.Cell verticalAlign="middle" dataKey="aliases">
				{
					((item: Car.Model) =>
						item.executors
							?.map((executor) => executor.alias)
							.join(", ") ?? "") as CellContent
				}
			</LightTable.Cell>
		</LightTable.Column>
	),

	brand: ({ width, onResize, t }) => (
		<LightTable.Column width={width} sortable resizable onResize={onResize}>
			<LightTable.HeaderCell>
				{t(
					"pages.mainPage.pages.accounts.tabs.car.content.modelTable.str101",
				) ?? ""}
			</LightTable.HeaderCell>
			<LightTable.Cell dataKey="brand">
				{
					((item: Car.Model) =>
						item.model?.carBaseTypeToCarBrand?.carBrand
							?.name) as unknown as CellContent
				}
			</LightTable.Cell>
		</LightTable.Column>
	),

	model: ({ width, onResize, t }) => (
		<LightTable.Column width={width} sortable resizable onResize={onResize}>
			<LightTable.HeaderCell>
				{t(
					"pages.mainPage.pages.accounts.tabs.car.content.modelTable.str102",
				) ?? ""}
			</LightTable.HeaderCell>
			<LightTable.Cell dataKey="model">
				{
					((item: Car.Model) =>
						item.model?.name ?? "") as unknown as CellContent
				}
			</LightTable.Cell>
		</LightTable.Column>
	),

	class: ({ language, width, onResize, t }) => (
		<LightTable.Column width={width} sortable resizable onResize={onResize}>
			<LightTable.HeaderCell>
				{t(
					"pages.mainPage.pages.accounts.tabs.car.content.modelTable.str103",
				) ?? ""}
			</LightTable.HeaderCell>
			<LightTable.Cell dataKey="class">
				{
					((item: Car.Model) =>
						item.class?.name?.[language] ??
						"") as unknown as CellContent
				}
			</LightTable.Cell>
		</LightTable.Column>
	),

	bodyType: ({ width, onResize, t }) => (
		<LightTable.Column width={width} sortable resizable onResize={onResize}>
			<LightTable.HeaderCell>
				{t(
					"pages.mainPage.pages.accounts.tabs.car.content.modelTable.str104",
				) ?? ""}
			</LightTable.HeaderCell>
			<LightTable.Cell dataKey="bodyType">
				{
					((item: Car.Model) =>
						item.bodyType?.name ?? "") as unknown as CellContent
				}
			</LightTable.Cell>
		</LightTable.Column>
	),

	registeredAt: ({ width, onResize, t }) => (
		<LightTable.Column width={width} sortable resizable onResize={onResize}>
			<LightTable.HeaderCell>
				{t(
					"pages.mainPage.pages.accounts.tabs.car.content.modelTable.str105",
				) ?? ""}
			</LightTable.HeaderCell>
			<LightTable.Cell dataKey="registeredAt">
				{
					((item: Car.Model) =>
						moment(item.createdAt).format(
							"DD.MM.YYYY",
						)) as unknown as CellContent
				}
			</LightTable.Cell>
		</LightTable.Column>
	),

	responsibleDispatcher: ({ width, onResize, t }) => (
		<LightTable.Column width={width} sortable resizable onResize={onResize}>
			<LightTable.HeaderCell>
				{t(
					"pages.mainPage.pages.accounts.tabs.car.content.modelTable.str151",
				) ?? ""}
			</LightTable.HeaderCell>
			<LightTable.Cell dataKey="responsibleDispatcher">
				{
					((item: Car.Model) =>
						[
							item.responsibleDispatcher?.person?.lastName ?? "",
							item.responsibleDispatcher?.person?.firstName ?? "",
							item.responsibleDispatcher?.person?.fatherName ??
								"",
						]
							.join(" ")
							.trim()) as unknown as CellContent
				}
			</LightTable.Cell>
		</LightTable.Column>
	),

	company: ({ language, width, onResize, t }) => (
		<LightTable.Column width={width} sortable resizable onResize={onResize}>
			<LightTable.HeaderCell>
				{t(
					"pages.mainPage.pages.accounts.tabs.car.content.modelTable.str106",
				) ?? ""}
			</LightTable.HeaderCell>
			<LightTable.Cell dataKey="company">
				{
					((item: Car.Model) =>
						item.taxiService?.company?.name?.[
							language
						]) as unknown as CellContent
				}
			</LightTable.Cell>
		</LightTable.Column>
	),

	taxiService: ({ language, width, onResize, t }) => (
		<LightTable.Column width={width} sortable resizable onResize={onResize}>
			<LightTable.HeaderCell>
				{t(
					"pages.mainPage.pages.accounts.tabs.car.content.modelTable.str107",
				) ?? ""}
			</LightTable.HeaderCell>
			<LightTable.Cell dataKey="taxiService">
				{
					((item: Car.Model) =>
						item.taxiService?.settlement?.[
							language
						]) as unknown as CellContent
				}
			</LightTable.Cell>
		</LightTable.Column>
	),

	color: ({ language, width, onResize, t }) => (
		<LightTable.Column width={width} sortable resizable onResize={onResize}>
			<LightTable.HeaderCell>
				{t(
					"pages.mainPage.pages.accounts.tabs.car.content.modelTable.str108",
				) ?? ""}
			</LightTable.HeaderCell>
			<LightTable.Cell dataKey="color">
				{
					((item: Car.Model) =>
						item.color?.name?.[language] ??
						"") as unknown as CellContent
				}
			</LightTable.Cell>
		</LightTable.Column>
	),

	notes: ({ width, onResize, t }) => (
		<LightTable.Column width={width} resizable onResize={onResize}>
			<LightTable.HeaderCell>
				{t(
					"pages.mainPage.pages.accounts.tabs.car.content.modelTable.str109",
				) ?? ""}
			</LightTable.HeaderCell>
			<LightTable.Cell dataKey="notes">
				{((item: Car.Model) => item.notes) as unknown as CellContent}
			</LightTable.Cell>
		</LightTable.Column>
	),
};

const ModelTable: React.FC<ModelTable.Props> = ({
	selected,
	sort,
	loading,
	data,
	language,
	onChangeSelected,
	onChangeSort,
	onEdit,
	onLoadMore,
}) => {
	const { t } = useTranslation();
	const columns = useMemo(() => getColumns(t), [t]);

	const { columnIds, setColumnIds } = useVisibleColumns(
		"accounts.vehicles",
		"vehicle",
	);
	const { widths, setWidths } = useWidths("accounts.vehicles", "vehicle");

	const canLoadMoreRef = useRef(true);
	const tableRef = useRef<LightTable.Ref | null>(null);

	const tableRowClassName = useCallback(
		(item: Car.Model) => {
			if (selected.includes(item?.id ?? "")) return "selected";

			if (!item?.active) return "not-active";

			return "";
		},
		[selected],
	);

	const tableOnScroll = useMemo(() => {
		canLoadMoreRef.current = true;

		return (x: number, y: number) => {
			if (!canLoadMoreRef.current) return;

			const contextHeight = data.length * 44;
			const top = Math.abs(y);
			const tableContainerHeight =
				tableRef.current?.root.getBoundingClientRect().height ?? 0;

			if (contextHeight - top - tableContainerHeight < 300) {
				onLoadMore();

				canLoadMoreRef.current = false;
			}
		};
	}, [data.length, onLoadMore]);

	const setItemSelection = useCallback(
		(id: number, value: boolean) => {
			if (value) {
				const newSelected = clone(selected);

				newSelected.push(id);

				onChangeSelected(newSelected);
			} else
				onChangeSelected(
					selected.filter((selectedId) => selectedId !== id),
				);
		},
		[onChangeSelected, selected],
	);

	const tableOnRowClick = useCallback(
		(rowData) =>
			KeyBinder.isControlPressed
				? setItemSelection(rowData.id, !selected.includes(rowData.id))
				: onChangeSelected(
						selected.includes(rowData.id) ? [] : [rowData.id],
				  ),
		[onChangeSelected, selected, setItemSelection],
	);

	return (
		<Column sizes="1fr auto!" maxedWidth maxedHeight>
			<LightTable
				ref={tableRef}
				fillHeight
				virtualized
				shouldUpdateScroll={false}
				headerHeight={44}
				rowHeight={44}
				sortColumn={sort.column}
				sortType={sort.type}
				loading={loading}
				data={data}
				rowClassName={tableRowClassName}
				onScroll={tableOnScroll}
				onRowClick={tableOnRowClick}
				onRowDoubleClick={onEdit}
				accessName={ARR_ACCESS_PART_KEY[TabKeys.TRANSPORT]}
				onSortColumn={(column, type) => onChangeSort({ column, type })}
			>
				<LightTable.Column width={36}>
					<LightTable.HeaderCell verticalAlign="middle">
						<CheckBox
							value={
								selected.length === data.length &&
								data.length !== 0
							}
							onChange={(value) =>
								onChangeSelected(
									value ? data.map((item) => item.id) : [],
								)
							}
						/>
					</LightTable.HeaderCell>
					<LightTable.Cell verticalAlign="middle">
						{(rowData) => (
							<CheckBox
								value={selected.includes(rowData.id)}
								onChange={(selected) =>
									KeyBinder.isControlPressed
										? setItemSelection(rowData.id, selected)
										: onChangeSelected(
												selected ? [rowData.id] : [],
										  )
								}
							/>
						)}
					</LightTable.Cell>
				</LightTable.Column>

				{columnIds.map((columnId) =>
					Columns[columnId]({
						language,
						width: widths[columnId],
						onResize: (width, columnId) => {
							if (isUndefined(width) || isUndefined(columnId))
								return;

							setWidths({
								...widths,
								[columnId]: width,
							});
						},
						t,
					}),
				)}
			</LightTable>

			<TableSettings
				value={defaultColumnIds}
				defaultValue={defaultColumnIds}
				columns={columns}
				onChange={setColumnIds}
			/>
		</Column>
	);
};

declare namespace ModelTable {
	interface Sort {
		column?: string;
		type?: SortType;
	}

	interface Props {
		selected: number[];
		sort: Sort;

		loading: boolean;
		data: (unknown & { id: number })[];
		language: Map.Language;

		onChangeSelected: Dispatch<number[]>;
		onChangeSort: Dispatch<Sort>;

		onEdit: Dispatch<number>;
		onLoadMore: () => void;
	}
}

export default ModelTable;
