import { RefObject } from "react";
import { react } from "uikit";
import Phones from "./components/Phones";
import validateEmail from "../../../../../../../../../../../../../../../utils/validateEmail";

interface Context {
	phonesRef: RefObject<Phones.Ref | null>;

	email: string | undefined;

	onEmailError: (error: boolean) => void;
}

export default class Controller extends react.ControllerBase<Context> {
	validate() {
		const arePhonesValid = !!this.context?.phonesRef.current?.validate();

		const email = this.context?.email;
		const isEmailValid = !email || validateEmail(email);

		if (!isEmailValid) this.context?.onEmailError(true);

		return arePhonesValid && isEmailValid;
	}
}
