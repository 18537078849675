import styled from "styled-components";
import { Row, theme } from "uikit";

const Root = styled(Row)`
	padding: 7px 16px;

	background: ${theme.colors.button_secondary};
`;

export default Root;
