import { react } from "uikit";

import { validatePhone } from "../../../../../../../../../../../../../../utils/validatePhone";

import Phone from ".";

interface Context {
	value: Phone.Value;
	setError: (error: "wrong" | "notfound" | null) => void;
}

export default class Controller extends react.ControllerBase<Context> {
	validate() {
		const phone = this.context?.value?.trim() || "";

		if (!phone) this.context?.setError("notfound");

		const isValid = validatePhone(phone);

		if (!isValid) this.context?.setError("wrong");

		return isValid;
	}
}
