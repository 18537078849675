/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable no-shadow */

import React, { useCallback, useEffect, useMemo, useState } from "react";
import { isEqual } from "lodash";

import DeleteModal from "../../../../../../components/DeleteModal";
import { useTypedSelector } from "../../../../../../redux/store";
import useModelSubscribe from "../../../../../../hooks/useModelSubscribe";
import Service from "../../../../../../services/WorkShift";
import mapByKey from "../../../../../../utils/mapByKey";
import useTaxiServiceIdsDecoder from "../../../../../../hooks/useTaxiServiceIdsDecoder";
import useGetCompanyIdsByTaxiServiceIds from "../../../../../../hooks/useGetCompanyIdsByTaxiServiceIds";
import Schedule from "../../../../../../services/Schedule";
import { useSort } from "../../../../../../hooks/useTableSettings";

import Content from "./components/Content";
import Header from "./components/Header";
import EditModal from "./components/EditModal";
import Root from "./components/Root";

const WorkShifts: React.FC = () => {
	const settingsLanguage = useTypedSelector(
		(state) => state.settings.map.mapLanguage,
	);

	const getCompanyIdsByTaxiServiceIds = useGetCompanyIdsByTaxiServiceIds();
	const decodeTaxiServiceIds = useTaxiServiceIdsDecoder();

	const [headerFilters, setHeaderFilters] = useState<Header.Filters.Value>({
		search: "",
	});

	const modelSubscriptionOptions = useMemo<Service.SubscribeOptions>(
		() => ({
			language: settingsLanguage,
			query: headerFilters.search,
		}),
		[settingsLanguage, headerFilters.search],
	);

	const modelData = useModelSubscribe(modelSubscriptionOptions, Service);

	const modelItems = useMemo(
		() => modelData?.cache ?? [],
		[modelData?.cache],
	);

	const modelItemById = useMemo(
		() => mapByKey(modelItems, "id"),
		[modelItems],
	);

	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const [editingItem, setEditingItem] = useState<EditModal.Value | null>(
		null,
	);
	const [selected, setSelected] = useState<number[]>([]);

	const { sortMethod: sort, setSortMethod: setSort } = useSort(
		"accounts.workShifts",
		"workShift",
	);

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const edit = useCallback(
		(id: number) => {
			const item = modelItemById[id];

			setEditingItem({
				id: item.id,

				executorIds: item.executorIds,
				companyIds: getCompanyIdsByTaxiServiceIds(item.taxiServiceIds),
				taxiServiceIds: item.taxiServiceIds,

				name: item.name,
				description: item.description,
				openAndCloseType: item.openAndCloseType,

				isSpentMinuteRangeEnabled: item.isSpentMinuteRangeEnabled,
				minimumMinutesSpent: item.minimumMinutesSpent,
				maximumMinutesSpent: item.maximumMinutesSpent,

				isLunchTimeEnabled: item.isLunchTimeEnabled,
				lunchTime: item.lunchTime,

				isSchedulesEnabled: item.isSchedulesEnabled,
				schedules: item.schedules,

				active: item.active,
				default: item.default,

				createdAt: item.createdAt ?? undefined,
			});
		},
		[getCompanyIdsByTaxiServiceIds, modelItemById],
	);

	const headerCanEdit = useMemo(
		() => selected.length === 1,
		[selected.length],
	);

	const headerCanDelete = useMemo(
		() =>
			selected.length !== 0 &&
			!selected.some((id) => modelItemById[id].default),
		[modelItemById, selected],
	);

	const headerOnAdd = useCallback(() => {
		setEditingItem({
			executorIds: [],
			companyIds: [],
			taxiServiceIds: [],

			name: "",
			description: "",
			openAndCloseType: undefined,

			isSpentMinuteRangeEnabled: false,
			minimumMinutesSpent: 0,
			maximumMinutesSpent: 0,

			isLunchTimeEnabled: false,
			lunchTime: 0,

			isSchedulesEnabled: false,
			schedules: [],

			active: true,
			default: false,
		});
	}, []);

	const headerOnEdit = useCallback(() => {
		edit(selected[0]);
	}, [edit, selected]);

	const headerOnDelete = useCallback(() => {
		setShowDeleteModal(true);
	}, []);

	const contentOnEdit = useCallback((item) => edit(item.id), [edit]);

	const contentOnLoadMore = useCallback(() => {}, []);

	const content = useMemo(
		() => (
			<>
				<Header
					filters={headerFilters}
					canEdit={headerCanEdit}
					canDelete={headerCanDelete}
					onChangeFilters={setHeaderFilters}
					onAdd={headerOnAdd}
					onEdit={headerOnEdit}
					onDelete={headerOnDelete}
				/>
				<Content
					selected={selected}
					sort={sort}
					loading={false}
					data={modelItems}
					language={settingsLanguage}
					onChangeSelected={setSelected}
					onChangeSort={setSort}
					onEdit={contentOnEdit}
					onLoadMore={contentOnLoadMore}
				/>
			</>
		),
		[
			contentOnEdit,
			contentOnLoadMore,
			headerCanDelete,
			headerCanEdit,
			headerFilters,
			headerOnAdd,
			headerOnDelete,
			headerOnEdit,
			modelItems,
			selected,
			setSort,
			settingsLanguage,
			sort,
		],
	);

	const editModalOnCancel = useCallback(() => {
		setEditingItem(null);
	}, []);

	const editModalOnSave = useCallback(
		async (newItem: EditModal.Value.Validated) => {
			if (isEqual(editingItem, newItem)) {
				setEditingItem(null);

				return;
			}

			const scheduleIds = (
				await Promise.all(
					newItem.schedules.map((schedule) => {
						const scheduleBase = editingItem?.schedules.find(
							(createdSchedule) =>
								createdSchedule.id === schedule.id,
						);

						if (scheduleBase) {
							if (isEqual(schedule, scheduleBase))
								return schedule.id;

							return Schedule.update(schedule).then(
								(schedule) => schedule?.id,
							);
						}

						return Schedule.store(schedule).then(
							(schedule) => schedule?.id,
						);
					}),
				)
			).filter(
				((scheduleId) => scheduleId !== null) as (
					scheduleId: number | undefined,
				) => scheduleId is number,
			);

			const newProperties: Service.Model.New = {
				executorIds: newItem.executorIds,
				taxiServiceIds: decodeTaxiServiceIds(
					newItem.companyIds,
					newItem.taxiServiceIds,
				),

				name: newItem.name,
				description: newItem.description,
				openAndCloseType: newItem.openAndCloseType,

				isSpentMinuteRangeEnabled: newItem.isSpentMinuteRangeEnabled,
				minimumMinutesSpent: newItem.minimumMinutesSpent,
				maximumMinutesSpent: newItem.maximumMinutesSpent,

				isLunchTimeEnabled: newItem.isLunchTimeEnabled,
				lunchTime: newItem.lunchTime,

				isSchedulesEnabled: newItem.isSchedulesEnabled,
				scheduleIds,

				active: newItem.active,
				default: newItem.default,
			};

			if (typeof newItem.id === "number")
				await Service.update({
					id: newItem.id,

					...newProperties,
				});
			else await Service.store(newProperties);

			setEditingItem(null);
		},
		[decodeTaxiServiceIds, editingItem],
	);

	const deleteModalOnCancel = useCallback(() => {
		setShowDeleteModal(false);
	}, []);

	const deleteModalOnConfirm = useCallback(async () => {
		setShowDeleteModal(false);

		await Promise.all(
			selected.map((id) =>
				Schedule.destroy(
					modelItemById[id].schedules.map((schedule) => schedule.id),
					{ deprecate: false },
				).then(() => Service.destroy(id, { deprecate: false })),
			),
		);

		Schedule.deprecateAll();
		Service.deprecateAll();

		setSelected([]);
	}, [modelItemById, selected]);

	useEffect(() => {
		Service.deprecateAll();
	}, []);

	return (
		<Root sizes="auto! 1fr" gaps="16px*" maxedWidth maxedHeight>
			{content}
			{editingItem && (
				<EditModal
					value={editingItem}
					language={settingsLanguage}
					onCancel={editModalOnCancel}
					onSave={editModalOnSave}
				/>
			)}
			{showDeleteModal && (
				<DeleteModal
					onCancel={deleteModalOnCancel}
					onConfirm={deleteModalOnConfirm}
				/>
			)}
		</Root>
	);
};

export default WorkShifts;
