import React, { memo } from "react";
import { useTranslation } from "react-i18next";
import { Row, Column, CheckBox } from "uikit";
import { borderStyles, columnGaps } from "../../../constants/styles";

const Fifth: React.FC<Fifth.Props> = memo(() => {
	const { t } = useTranslation();
	return (
		<Row sizes="auto 1fr" gaps="30px*" style={borderStyles}>
			<Column gaps={columnGaps}>
				<CheckBox
					disabled
					label={
						t(
							"pages.mainPage.pages.accounts.tabs.executorTeams.modal.content.tabs.main.blocks.fifth.str0",
						) ?? ""
					}
				/>
				<CheckBox
					disabled
					label={
						t(
							"pages.mainPage.pages.accounts.tabs.executorTeams.modal.content.tabs.main.blocks.fifth.str1",
						) ?? ""
					}
				/>
				<CheckBox
					disabled
					label={
						t(
							"pages.mainPage.pages.accounts.tabs.executorTeams.modal.content.tabs.main.blocks.fifth.str2",
						) ?? ""
					}
				/>
				<CheckBox
					disabled
					label={
						t(
							"pages.mainPage.pages.accounts.tabs.executorTeams.modal.content.tabs.main.blocks.fifth.str3",
						) ?? ""
					}
				/>
				<CheckBox
					disabled
					label={
						t(
							"pages.mainPage.pages.accounts.tabs.executorTeams.modal.content.tabs.main.blocks.fifth.str200",
						) ?? ""
					}
				/>
				<CheckBox
					disabled
					label={
						t(
							"pages.mainPage.pages.accounts.tabs.executorTeams.modal.content.tabs.main.blocks.fifth.str202",
						) ?? ""
					}
				/>
			</Column>
			<Column gaps={columnGaps}>
				<CheckBox
					disabled
					label={
						t(
							"pages.mainPage.pages.accounts.tabs.executorTeams.modal.content.tabs.main.blocks.fifth.str204",
						) ?? ""
					}
				/>
				<CheckBox
					disabled
					label={
						t(
							"pages.mainPage.pages.accounts.tabs.executorTeams.modal.content.tabs.main.blocks.fifth.str206",
						) ?? ""
					}
				/>
				<CheckBox
					disabled
					label={
						t(
							"pages.mainPage.pages.accounts.tabs.executorTeams.modal.content.tabs.main.blocks.fifth.str208",
						) ?? ""
					}
				/>
				<CheckBox
					disabled
					label={
						t(
							"pages.mainPage.pages.accounts.tabs.executorTeams.modal.content.tabs.main.blocks.fifth.str210",
						) ?? ""
					}
				/>
				<CheckBox
					disabled
					label={
						t(
							"pages.mainPage.pages.accounts.tabs.executorTeams.modal.content.tabs.main.blocks.fifth.str4",
						) ?? ""
					}
				/>
			</Column>
		</Row>
	);
});

declare namespace Fifth {
	interface Props {}
}

export default Fifth;
