import React, { Dispatch, memo, useMemo } from "react";
import { Option } from "uikit";
import { useTranslation } from "react-i18next";
import LabeledField from "../../../../../../../../../../../../../../../../components/LabeledField";
import SelectWithModal from "../../../../../../../../../../../../../../../../components/SelectWithModal";
import CounterpartyGroupService from "../../../../../../../../../../../../../../../../services/CounterpartyGroup";
import tPath from "../../../../../../../../../../constants/tPath";
import Counterparty from "../../../../../../../../../../../../../../../../services/Counterparty";

const path = `${tPath}.modal.tabs.main.other.counterpartyGroup`;

const CounterpartyGroup: React.FC<CounterpartyGroup.Props> = memo((props) => {
	const { groups, value, onChange } = props;
	const { t } = useTranslation();

	const options = useMemo<Option<number>[]>(
		() =>
			groups.map((group) => ({
				key: group.id,
				value: group.id,
				label: group.name,
			})),
		[groups],
	);

	return (
		<LabeledField label={t(`${path}.title`) || ""}>
			<SelectWithModal
				disabled={!groups.length}
				title={t(`${path}.selectGroup`) || ""}
				options={options}
				value={value}
				onChange={onChange as any}
			/>
		</LabeledField>
	);
});

declare namespace CounterpartyGroup {
	interface Props {
		groups: CounterpartyGroupService.Model[];
		value: Value;
		onChange: Dispatch<Value>;
	}

	type Value = Counterparty.Model["counterpartyGroupId"] | undefined;
}

export default CounterpartyGroup;
