import React, { Dispatch, RefAttributes, memo, useLayoutEffect } from "react";
import { react } from "uikit";

import CarClass from "../../../../../../../../../../../../services/CarClass";
import Language from "../../../../../../../../../../../../services/Language";
import { useTypedSelector } from "../../../../../../../../../../../../redux/store";
import useObjectEditor from "../../../../../../../../../../../../hooks/useObjectEditor";
import { StyledGrid } from "../../../../../../../../../../../../components/common";
import TabRoot from "../TabRoot";

import InternalController from "./Controller";
import ServiceCar from "./components/ServiceCar";
import DistributableCarClass from "./components/DistributableCarClass";
import BroadcastableCarClass from "./components/BroadcastableCarClass";

const Option = react.withController<OptionTab.PropsBase, OptionTab.Controller>(
	({ value, disabled, visible, language, onChange }) => {
		const valueEditor = useObjectEditor(value, onChange);
		const { models } = useTypedSelector(
			(state) => state.preferencesReducer.carClasses.data,
		);

		const taxiServiceId = valueEditor.useGetter("taxiServiceId");
		const carClass = valueEditor.useGetter("carClass");
		const serviceIdsValue = valueEditor.useGetter("serviceIds");
		const serviceIdsOnChange = valueEditor.useSetter("serviceIds");

		const distributableCarClass = valueEditor.useGetter(
			"distributableCarClass",
		);
		const distributableCarClassOnChange = valueEditor.useSetter(
			"distributableCarClass",
		);
		const distributableCarClassIds = valueEditor.useGetter(
			"distributableCarClassIds",
		);
		const distributableCarClassIdsOnChange = valueEditor.useSetter(
			"distributableCarClassIds",
		);

		const broadcastableCarClass = valueEditor.useGetter(
			"broadcastableCarClass",
		);
		const broadcastableCarClassOnChange = valueEditor.useSetter(
			"broadcastableCarClass",
		);
		const broadcastableCarClassId = valueEditor.useGetter(
			"broadcastableCarClassIds",
		);
		const broadcastableCarClassIdsOnChange = valueEditor.useSetter(
			"broadcastableCarClassIds",
		);

		useLayoutEffect(() => {
			if (!taxiServiceId) serviceIdsOnChange([]);

			if (
				carClass?.compatibleCarClasses &&
				Array.isArray(carClass?.compatibleCarClasses) &&
				carClass?.distributionCompatibleMode !==
					CarClass.CompatibleMode.WITH_ALL &&
				taxiServiceId
			) {
				distributableCarClassOnChange(carClass.compatibleCarClasses);
			} else if (
				carClass?.distributionCompatibleMode ===
					CarClass.CompatibleMode.WITH_ALL &&
				taxiServiceId
			) {
				const carClassIds = [...models].filter(
					(item) =>
						item.taxiServiceIds?.includes(taxiServiceId) &&
						carClass.id !== item.id,
				);
				distributableCarClassOnChange(carClassIds);
			} else distributableCarClassOnChange([]);

			if (
				carClass?.compatibleCarClassesToBroadcastable &&
				Array.isArray(carClass?.compatibleCarClassesToBroadcastable) &&
				carClass?.broadcastingCompatibleMode !==
					CarClass.CompatibleMode.WITH_ALL &&
				taxiServiceId
			) {
				broadcastableCarClassOnChange(
					carClass.compatibleCarClassesToBroadcastable,
				);
			} else if (
				carClass?.broadcastingCompatibleMode ===
					CarClass.CompatibleMode.WITH_ALL &&
				taxiServiceId
			) {
				const carClassIds = [...models].filter(
					(item) =>
						item.taxiServiceIds?.includes(taxiServiceId) &&
						carClass.id !== item.id,
				);
				broadcastableCarClassOnChange(carClassIds);
			} else broadcastableCarClassOnChange([]);
		}, [
			carClass?.compatibleCarClasses,
			carClass?.compatibleCarClassesToBroadcastable,
			broadcastableCarClassOnChange,
			distributableCarClassOnChange,
			taxiServiceId,
			serviceIdsOnChange,
			carClass?.distributionCompatibleMode,
			carClass?.broadcastingCompatibleMode,
			carClass?.id,
			models,
		]);

		return (
			<TabRoot hasPaddings visible={visible}>
				<StyledGrid areas="" gap="1rem">
					<ServiceCar
						value={serviceIdsValue}
						onChange={serviceIdsOnChange}
						carClass={taxiServiceId ? carClass : undefined}
						disabled={disabled}
						language={language}
						taxiServiceId={taxiServiceId}
					/>
					<DistributableCarClass
						value={distributableCarClassIds}
						onChange={distributableCarClassIdsOnChange}
						distributableCarClass={distributableCarClass}
						disabled={disabled}
						language={language}
					/>

					<BroadcastableCarClass
						value={broadcastableCarClassId}
						onChange={broadcastableCarClassIdsOnChange}
						broadcastableCarClass={broadcastableCarClass}
						disabled={disabled}
						language={language}
					/>
				</StyledGrid>
			</TabRoot>
		);
	},
	InternalController,
);

const OptionTab = memo(Option);

declare namespace OptionTab {
	type Ref = InternalController | null;

	type Controller = InternalController;

	interface Value {
		serviceIds: number[];
		carClass?: CarClass.Model;
		distributableCarClass?: CarClass.Model[];
		distributableCarClassIds: number[];
		broadcastableCarClass?: CarClass.Model[];
		broadcastableCarClassIds: number[];
		taxiServiceId?: number;
	}

	interface PropsBase {
		value: Value;

		disabled: boolean;
		visible: boolean;
		language: Language;

		onChange: Dispatch<Value>;
	}

	type Props = PropsBase & RefAttributes<Ref>;
}

export default OptionTab;
