import { Option } from "uikit";

// eslint-disable-next-line no-shadow
export enum Tabs {
	MAIN = "main",
	ORDER_CANCELLATION = "orderCancellation",
	EXECUTORS = "executors",
	HISTORY = "history",
}

const tabs: Option<string>[] = [
	{
		key: Tabs.MAIN,
		value: Tabs.MAIN,
		label: "",
	},
	{
		key: Tabs.ORDER_CANCELLATION,
		value: Tabs.ORDER_CANCELLATION,
		label: "",
	},
	{
		key: Tabs.EXECUTORS,
		value: Tabs.EXECUTORS,
		label: "",
	},
	{
		key: Tabs.HISTORY,
		value: Tabs.HISTORY,
		label: "",
	},
];

export default tabs;
