/* eslint-disable import/no-unresolved */
/* eslint-disable no-shadow */
import React, {
	RefAttributes,
	useCallback,
	useEffect,
	useMemo,
	useState,
} from "react";
import { useTranslation } from "react-i18next";
import { react, useRefWithSetter } from "uikit";

import Dispatcher from "../../../../../../../../services/Dispatcher";
import Map from "../../../../../../../../redux/services/Map";
import useObjectEditor from "../../../../../../../../hooks/useObjectEditor";
import { useTypedSelector } from "../../../../../../../../redux/store";
import useKeepCardsOpen from "../../../../../../../../hooks/useKeepCardsOpen";
import useCardSubscriber from "../../../../../../../../hooks/useCardSubscriber";
import EditModalBase from "../../../../../../../../components/EditModal";
import WithoutNullableKeys from "../../../../../../../../types/WithoutNullableKeys";

import Root from "./components/Root";
import Header from "./components/Header";
import Content from "./components/Content";
import InternalController from "./Controller";

const EditModal = react.withController<
	EditModal.PropsBase,
	EditModal.Controller
>(({ controller, value, language, onCancel, onSave }) => {
	const { t } = useTranslation();

	const [contentRef, setContentRef] = useRefWithSetter<Content.Ref | null>(
		null,
	);

	const cardIds = useMemo(
		() => (typeof value.id === "number" ? [value.id] : []),
		[value.id],
	);

	useKeepCardsOpen(cardIds, Dispatcher.Card);

	const cardSession = useCardSubscriber(Dispatcher.Card);

	const currentCard = useMemo(
		() =>
			cardSession?.cards.find((card) => cardIds.includes(card.id)) ??
			null,
		[cardIds, cardSession?.cards],
	);

	const currentCardLocked = useMemo(
		() => currentCard?.locked === true,
		[currentCard?.locked],
	);

	const currentIP = useTypedSelector((state) => state.currentIP.value) ?? "";

	const [history, setHistory] = useState<Dispatcher.History>([]);

	useEffect(() => {
		(async () => {
			if (value.id) setHistory(await Dispatcher.getHistory(value.id));
		})();
	}, [value.id]);

	controller.setContext({ contentRef });

	const [tab, setTab] = useState<Header.Tab>("main");
	const [internalValue, setInternalValue] = useState(value);

	const valueEditor = useObjectEditor(internalValue, setInternalValue);

	const fullName = useMemo(
		() =>
			`${internalValue.lastName} ${internalValue.firstName} ${internalValue.fatherName}`.trim(),
		[
			internalValue.fatherName,
			internalValue.firstName,
			internalValue.lastName,
		],
	);

	const headerTitle = useMemo(
		() =>
			typeof value.id === "number"
				? fullName
				: t(
						"pages.mainPage.pages.accounts.tabs.users.editModal.str200",
				  ) ?? "",
		[fullName, t, value.id],
	);

	const headerLockedBy = useMemo(
		() =>
			currentCard?.locked
				? [
						currentCard.lockedBy.person?.lastName ?? "",
						currentCard.lockedBy.person?.firstName ?? "",
						currentCard.lockedBy.person?.fatherName ?? "",
				  ]
						.join(" ")
						.trim()
				: undefined,
		[currentCard?.locked, currentCard?.lockedBy],
	);

	const contentOnChange = valueEditor.useAssigner();

	const modalContent = useMemo(
		() => (
			<Root sizes="auto! 1fr">
				<Header
					tab={tab}
					title={headerTitle}
					lockedBy={headerLockedBy}
					createdAt={value.createdAt}
					onChangeTab={setTab}
				/>
				<Content
					ref={setContentRef}
					value={internalValue}
					type={tab}
					disabled={currentCardLocked}
					language={language}
					history={history}
					currentIP={currentIP}
					onChange={contentOnChange}
				/>
			</Root>
		),
		[
			contentOnChange,
			currentCardLocked,
			currentIP,
			headerLockedBy,
			headerTitle,
			history,
			internalValue,
			language,
			setContentRef,
			tab,
			value.createdAt,
		],
	);

	const modalOnSave = useCallback(() => {
		if (!contentRef.current?.validate()) return;

		onSave(valueEditor.value as EditModal.Value.Validated);
	}, [contentRef, onSave, valueEditor.value]);

	return (
		<EditModalBase
			canSave={!currentCardLocked}
			onCancel={onCancel}
			onSave={modalOnSave}
		>
			{modalContent}
		</EditModalBase>
	);
}, InternalController);

declare namespace EditModal {
	type Ref = InternalController | null;

	type Controller = InternalController;

	interface Value extends Content.Value {
		id?: number;

		createdAt?: string;
	}

	namespace Value {
		type ValidationPropertyNames =
			| "firstName"
			| "login"
			| "password"
			| "taxiServiceIds"
			| "defaultTaxiServiceId";

		type Validated = Omit<Value, ValidationPropertyNames> &
			WithoutNullableKeys<Required<Pick<Value, ValidationPropertyNames>>>;
	}

	interface PropsBase {
		value: Value;
		language: Map.Language;

		onCancel: () => void;
		onSave: (value: Value.Validated) => void;
	}

	type Props = PropsBase & RefAttributes<Ref>;
}

export default EditModal;
