import React, { Dispatch, RefAttributes, memo, SetStateAction } from "react";
import { react } from "uikit";

import {
	ACCESS_TO_THE_COMPONENT,
	DisplayFields,
} from "../../../../../../../../../../../../constants/access";
import { mergeAccessDefault } from "../../../../../../../../../../../../access";
import { TabRoot } from "..";

import InternalController from "./Controller";
import TreeSelect from "./TreeSelect";

const PermissionsTab = memo(
	react.withController<PermissionsTab.PropsBase, PermissionsTab.Controller>(
		({ value, visible, selectOnChangeAccess, isMainRole, disabled }) => {
			const updatedAccess = mergeAccessDefault(
				ACCESS_TO_THE_COMPONENT,
				value.displayFields,
			);

			return (
				<TabRoot hasPaddings={false} visible={visible}>
					<TreeSelect
						value={updatedAccess}
						onChange={selectOnChangeAccess}
						isMainRole={isMainRole}
						disabled={disabled}
					/>
				</TabRoot>
			);
		},
		InternalController,
	),
);

declare namespace PermissionsTab {
	type Ref = InternalController | null;

	type Controller = InternalController;

	interface Value {
		displayFields: DisplayFields;
	}

	interface PropsBase {
		value: Value;
		visible: boolean;
		selectOnChangeAccess: Dispatch<SetStateAction<DisplayFields>>;
		isMainRole: boolean;
		disabled: boolean;
	}

	type Props = PropsBase & RefAttributes<Ref>;
}

export default PermissionsTab;
