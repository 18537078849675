import React, { Dispatch, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { CheckBoxWithContent } from "uikit";

import useObjectEditor from "../../../../../../../../../../hooks/useObjectEditor";
import { StyledRow } from "../../../../../../../../../../components/common";

const Footer: React.FC<Footer.Props> = ({
	value,
	disabled,
	assignableTo,
	onChange,
	isMainRole,
}) => {
	const { t } = useTranslation();
	const valueEditor = useObjectEditor(value, onChange);

	const activeOnChange = useCallback(
		(active: boolean) => {
			valueEditor.set("active", active);
		},
		[valueEditor],
	);

	const defaultOnChange = useCallback(
		(defaultValue: boolean) => {
			valueEditor.set("default", defaultValue);
			valueEditor.set("active", true);
		},
		[valueEditor],
	);

	const defaultLabelText = useMemo(() => {
		let result = `${
			t(
				"pages.mainPage.pages.accounts.tabs.roles.editModal.footer.str0",
			) ?? ""
		} `;

		if (assignableTo === "dispatcher")
			result +=
				t(
					"pages.mainPage.pages.accounts.tabs.roles.editModal.footer.str1",
				) ?? "";
		else if (assignableTo === "executor")
			result +=
				t(
					"pages.mainPage.pages.accounts.tabs.roles.editModal.footer.str2",
				) ?? "";

		return result;
	}, [assignableTo, t]);

	return (
		<StyledRow gap="24px">
			<CheckBoxWithContent
				gap="10px"
				disabled={valueEditor.get("default") || isMainRole || disabled}
				value={valueEditor.get("active")}
				onChange={activeOnChange}
			>
				{t(
					"pages.mainPage.pages.accounts.tabs.roles.editModal.footer.str150",
				) ?? ""}
			</CheckBoxWithContent>
			<CheckBoxWithContent
				gap="10px"
				disabled={disabled}
				value={valueEditor.get("default")}
				onChange={defaultOnChange}
			>
				{defaultLabelText}
			</CheckBoxWithContent>
		</StyledRow>
	);
};

declare namespace Footer {
	interface Value {
		active: boolean;
		default: boolean;
	}

	interface Props {
		value: Value;

		disabled: boolean;
		assignableTo?: "dispatcher" | "executor";

		onChange: Dispatch<Value>;
		isMainRole: boolean;
	}
}

export default Footer;
