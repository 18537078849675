import React, {
	Dispatch,
	RefAttributes,
	memo,
	useCallback,
	useState,
} from "react";
import { useTranslation } from "react-i18next";
import { CheckBox, Column, TextBox, react } from "uikit";

import useObjectEditor from "../../../../../../../../../../../../../../../../hooks/useObjectEditor";
import validatePhone, {
	countryCodePhone,
} from "../../../../../../../../../../../../../../../../utils/validatePhone";
import { IPhone } from "../../../../../../../../../../../../../../../../types/IPhone";
import LabeledField from "../../../../../../../../../../../../../../../../components/LabeledField";
import { AutodetectPhone } from "../../../../../../../../../../../../hooks";

import Controller from "./Controller";

const ContentBase = react.withController<Content.PropsBase, Controller>(
	({
		value,
		onChange,
		controller,
		allPhones,
		setErrorData,
		setShowErrorModal,
	}) => {
		const { t } = useTranslation();

		const editor = useObjectEditor(value, onChange);

		const isAgent = editor.useGetter("isAgent");
		const setIsAgent = editor.useSetter("isAgent");

		const number = editor.useGetter("number");
		const setNumber = editor.useSetter("number");
		const [error, setError] = useState(false);
		const setNumberHandler = useCallback(
			(newNumber: string) => {
				const phone = countryCodePhone(newNumber, "uk");
				const value = validatePhone(phone) !== true;

				if (value) setError(true);
				else setError(false);
				setNumber(newNumber);
			},
			[setNumber],
		);

		// const group = editor.useGetter("group");
		// const setGroup = editor.useSetter("group");
		// const setGroupHandler = useCallback(
		// 	(bool: boolean) => {
		// 		setGroup(bool ? 0 : 1);
		// 	},
		// 	[setGroup],
		// );

		controller.setContext({
			value,
			setError,
			allPhones,
			setErrorData,
			setShowErrorModal,
		});

		return (
			<Column style={{ padding: 20 }} gaps="20px*">
				<LabeledField
					label={
						t(
							"pages.mainPage.pages.customers.tabs.agents.modal.content.tabs.phones.modal.content.str0",
						) ?? ""
					}
				>
					<TextBox.TextBox
						placeholder={
							t(
								"pages.mainPage.pages.customers.tabs.agents.modal.content.tabs.phones.modal.content.str1",
							) ?? ""
						}
						error={error}
						value={number}
						onChange={setNumberHandler}
					/>
				</LabeledField>
				<CheckBox
					label={
						t(
							"pages.mainPage.pages.customers.tabs.agents.modal.content.tabs.phones.modal.content.str2",
						) ?? ""
					}
					value={isAgent}
					onChange={setIsAgent}
				/>
				{/* <CheckBox
				label={t("pages.mainPage.pages.customers.tabs.agents.modal.content.tabs.phones.modal.content.str3") ?? ""}
				value={!group}
				onChange={setGroupHandler}
			/> */}
			</Column>
		);
	},
	Controller,
);

const Content = memo(ContentBase);

declare namespace Content {
	type Ref = Controller | null;

	interface PropsBase {
		value: Value;
		onChange: Dispatch<Value>;
		setErrorData: (value: React.SetStateAction<AutodetectPhone[]>) => void;
		allPhones: AutodetectPhone[];
		setShowErrorModal: React.Dispatch<React.SetStateAction<boolean>>;
	}
	type Props = PropsBase & RefAttributes<Ref>;

	interface Value extends Omit<IPhone, "id"> {
		isAgent: boolean;
		id?: number | string;
	}
}

export default Content;
