import React, { RefAttributes, memo, useMemo } from "react";
import { react, useRefWithSetter } from "uikit";

import useObjectEditor from "../../../../../../../../../../hooks/useObjectEditor";
import TransactionsTab from "../../../../../../../../../../components/TransactionsTab";
import { StyledColumn } from "../../../../../../../../../../components/common";
import { ModalTabs } from "../../constants/tabs";

import Main from "./tabs/Main";
import Reward from "./tabs/Reward";
import Phones from "./tabs/Phones";
import Addresses from "./tabs/Addresses";
import Controller from "./Controller";
import Orders from "./tabs/Orders";

const ContentBase = react.withController<Content.PropsBase, Controller>(
	({ counterparties, groups, tab, agentId, value, onChange, controller }) => {
		const [mainRef, setMainRef] = useRefWithSetter<Main.Ref | null>(null);

		const editor = useObjectEditor(value, onChange);

		const mainTabValue = editor.usePicker([
			"name",
			"additionalFields",
			"isAccessToApp",
			"isAccessToAccount",
			"login",
			"password",
			"code",
			"paymentAccounts",
			"paymentTransactions",
			"status",
			"companyId",
			"defaultTaxiServiceId",
			"latestTaxiServiceId",
			"agentGroupId",
			"counterpartyId",
			"checkId",
			"balanceId",
		]);

		const agentToRewards = editor.usePicker([
			"agentToRewards",
			"isArbitraryRewardAllowed",
			"isRewardAllowed",
		]);

		const phonesTabValue = editor.usePicker(["phones"]);

		const addressesTabValue = editor.usePicker(["addresses"]);

		const paymentAccounts = editor.useGetter("paymentAccounts");

		const defaultTaxiServiceId = editor.useGetter("defaultTaxiServiceId");

		const paymentAccountIds = useMemo(() => {
			if (!paymentAccounts?.length) return [];

			return paymentAccounts.map((item) => item.id);
		}, [paymentAccounts]);

		controller.setContext({ mainRef });
		return (
			<StyledColumn gap="12px" scrollbar overY="scroll">
				<Main
					visible={tab === ModalTabs.MAIN}
					agentId={agentId}
					hasPaddings
					ref={setMainRef}
					counterparties={counterparties}
					groups={groups}
					value={mainTabValue}
					onChange={editor.assign}
				/>
				<Reward
					visible={tab === ModalTabs.REWARD}
					value={agentToRewards}
					onChange={editor.assign}
					agentId={agentId}
				/>
				<Phones
					visible={tab === ModalTabs.PHONES}
					value={phonesTabValue}
					onChange={editor.assign}
				/>
				<Addresses
					visible={tab === ModalTabs.ADDRESSES}
					value={addressesTabValue}
					onChange={editor.assign}
				/>

				<Orders
					agentId={agentId}
					defaultTaxiServiceId={defaultTaxiServiceId}
					visible={tab === ModalTabs.ORDERS}
				/>
				<TransactionsTab
					visible={tab === ModalTabs.TRANSACTIONS}
					options={{
						paymentAccountIds,
					}}
					isSubscribe={!!paymentAccountIds?.length}
					tableFrom="customers.agents"
				/>
			</StyledColumn>
		);
	},
	Controller,
);

const Content = memo(ContentBase);

declare namespace Content {
	type Ref = Controller | null;

	interface Value
		extends Main.Value,
			Reward.Value,
			Phones.Value,
			Addresses.Value {}

	interface PropsBase {
		counterparties: Main.Props["counterparties"];
		groups: Main.Props["groups"];
		agentId: Main.Props["agentId"];
		tab: ModalTabs;
		value: Value;
		onChange: (value: Value) => void;
	}

	type Props = PropsBase & RefAttributes<Ref>;
}

export default Content;
