import React, { memo, useCallback, useState } from "react";
import { Button, Icon } from "uikit";
import BalanceModal from "../../../../../../../../../../components/BalanceModal";
import { PaymentAccount } from "../../../../../../../../../../types/PaymentAccount";
import { updateBalance } from "../../../../../../../../../../utils/updateBalances";
import { PaymentProviderAccessorType } from "../../../../../../../../../../services/Payment/types";
import Agent from "../../../../../../../../../../services/Agent";

const Balance: React.FC<Balance.Props> = memo((props) => {
	const { agent, paymentAccount: account, iconId } = props;

	const [showModal, setShowModal] = useState(false);

	const handleOpen = useCallback(() => {
		setShowModal(true);
	}, []);
	const handleClose = useCallback(() => {
		setShowModal(false);
	}, []);

	const handleSubmit = useCallback(
		async ({ amount, description }) => {
			if (amount === 0 || !account || !agent) return;

			const res = await updateBalance(
				{
					...account,
					isEdited: true,
					amount,
					description,
				},
				agent.companyId,
				PaymentProviderAccessorType.COMPANY,
			);
			if (res) setShowModal(false);
		},
		[account, agent],
	);

	return (
		<div>
			<Button.Button
				variant="secondary"
				disabled={!agent}
				onClick={handleOpen}
				icon={<Icon id={iconId} size={20} />}
			/>
			{showModal && (
				<BalanceModal onClose={handleClose} onSubmit={handleSubmit} />
			)}
		</div>
	);
});

declare namespace Balance {
	interface Props {
		agent: Agent.Model | false;
		paymentAccount?: PaymentAccount;
		iconId: "balance" | "bonus-balance";
	}
}

export default Balance;
