import styled from "styled-components";
import { Column } from "uikit";

const Root = styled(Column)`
	width: 1200px;
	height: 86vh;

	min-width: 900px;
	min-height: 500px;
	max-height: 900px

	border-radius: 5px;

	background-color: white;

	overflow: hidden;
`;

export default Root;
