import { react } from "uikit";
import { RefObject } from "react";
import StatusSelect from "./components/StatusSelect";

interface Context {
	statusSelectRef: RefObject<StatusSelect.Ref | null>;
}

export default class Controller extends react.ControllerBase<Context> {
	validate() {
		const isStatusSelectValid =
			this.context?.statusSelectRef.current?.validate();

		return isStatusSelectValid;
	}
}
