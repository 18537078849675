import { RewardCalculationMethod } from "../../../../../../../../../../../../../../../services/Agent";
import Modal from "../components/Modal";

const defaultValue: Modal.Value = {
	active: true,
	default: false,
	name: "",
	percent: 0,
	amount: 0,
	maxAmountFromOrderInPercent: 0,
	rewardCalculationMethod: RewardCalculationMethod.ADD_TO_ORDER,
};

export default defaultValue;
