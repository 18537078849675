import React, { memo } from "react";
import { useTranslation } from "react-i18next";
import { Row, Column, CheckBox } from "uikit";

import { borderStyles, columnGaps } from "../../../constants/styles";

const Second: React.FC<Second.Props> = memo(() => {
	const { t } = useTranslation();
	return (
		<Row sizes="1fr 1fr" style={borderStyles}>
			<Column gaps={columnGaps}>
				<CheckBox
					disabled
					label={
						t(
							"pages.mainPage.pages.accounts.tabs.executorTeams.modal.content.tabs.main.blocks.second.str0",
						) ?? ""
					}
				/>
				<CheckBox
					disabled
					label={
						t(
							"pages.mainPage.pages.accounts.tabs.executorTeams.modal.content.tabs.main.blocks.second.str1",
						) ?? ""
					}
				/>
				{/* <CheckBox
					disabled
					label={
						t(
							"pages.mainPage.pages.accounts.tabs.executorTeams.modal.content.tabs.main.blocks.second.str2",
						) ?? ""
					}
				/>
				<CheckBox
					disabled
					label={
						t(
							"pages.mainPage.pages.accounts.tabs.executorTeams.modal.content.tabs.main.blocks.second.str3",
						) ?? ""
					}
				/> */}
				{/* <CheckBox
					disabled
					label={
						t(
							"pages.mainPage.pages.accounts.tabs.executorTeams.modal.content.tabs.main.blocks.second.str4",
						) ?? ""
					}
				/> */}
				<CheckBox
					disabled
					label={
						t(
							"pages.mainPage.pages.accounts.tabs.executorTeams.modal.content.tabs.main.blocks.second.str5",
						) ?? ""
					}
				/>
			</Column>
			<Column gaps={columnGaps}>
				{/* <CheckBox
					disabled
					label={
						t(
							"pages.mainPage.pages.accounts.tabs.executorTeams.modal.content.tabs.main.blocks.second.str6",
						) ?? ""
					}
				/> */}
				<CheckBox
					disabled
					label={
						t(
							"pages.mainPage.pages.accounts.tabs.executorTeams.modal.content.tabs.main.blocks.second.str7",
						) ?? ""
					}
				/>
				<CheckBox
					disabled
					label={
						t(
							"pages.mainPage.pages.accounts.tabs.executorTeams.modal.content.tabs.main.blocks.second.str200",
						) ?? ""
					}
				/>
				<CheckBox
					disabled
					label={
						t(
							"pages.mainPage.pages.accounts.tabs.executorTeams.modal.content.tabs.main.blocks.second.str8",
						) ?? ""
					}
				/>
				<CheckBox
					disabled
					label={
						t(
							"pages.mainPage.pages.accounts.tabs.executorTeams.modal.content.tabs.main.blocks.second.str9",
						) ?? ""
					}
				/>
			</Column>
		</Row>
	);
});

declare namespace Second {
	interface Props {}
}

export default Second;
