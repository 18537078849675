import CheckBoxSelect from "../../../../../../../../../../../../../../components/CheckBoxSelect";

export const options: CheckBoxSelect.Option<
	"phone" | "application" | "web-site"
>[] = [
	{
		key: "phone",
		label: "Телефон",
	},
	{
		key: "application",
		label: "Приложение",
	},
	{
		key: "web-site",
		label: "Сайт",
	},
];

export default {
	options,
};
