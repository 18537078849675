import React, { memo } from "react";
import { useTranslation } from "react-i18next";

import useObjectEditor from "../../../../../../hooks/useObjectEditor";
import FieldsContainer from "../../../../../../components/FieldsContainer";
import { StyledColumn } from "../../../../../../components/common";
import tPath from "../../constants/tPath";

import RideMinuteCost from "./components/RideMinuteCost";
import Item from "./components/Item";

const Waiting: React.FC<Waiting.Props> = memo(({ root, value, onChange }) => {
	const { t } = useTranslation();
	const valueEditor = useObjectEditor(value, onChange);

	const waitingMinuteCost = valueEditor.useGetter("waitingMinuteCost");
	const setWaitingMinuteCost = valueEditor.useSetter("waitingMinuteCost");

	const idleMinuteCost = valueEditor.useGetter("idleMinuteCost");
	const setIdleMinuteCost = valueEditor.useSetter("idleMinuteCost");

	const rideMinuteCost = valueEditor.useGetter("rideMinuteCost");
	const setRideMinuteCost = valueEditor.useSetter("rideMinuteCost");

	return (
		<FieldsContainer
			label={t(`${tPath}.mainModal.tabs.main.waiting.blockName`) || ""}
			gaps="10px*"
		>
			<StyledColumn gap="10px" w="max-content">
				<Item
					disabled={root}
					label="waitingMinuteCost"
					value={waitingMinuteCost}
					onChange={setWaitingMinuteCost}
				/>
				<Item
					disabled={root}
					label="idleMinuteCost"
					value={idleMinuteCost}
					onChange={setIdleMinuteCost}
				/>
			</StyledColumn>
			<RideMinuteCost
				disabled={root}
				value={rideMinuteCost}
				onChange={setRideMinuteCost}
			/>
		</FieldsContainer>
	);
});

declare namespace Waiting {
	interface Props {
		root: boolean;
		value: Value;
		onChange: (x: Partial<Value>) => void;
	}

	interface Value {
		waitingMinuteCost: Item.Value;
		idleMinuteCost: Item.Value;
		rideMinuteCost: RideMinuteCost.Value;
	}
}

export default Waiting;
