import React, { memo } from "react";

import useObjectEditor from "../../../../../../../../../../../../hooks/useObjectEditor";
import {
	SuspenseLoader,
	StyledRow,
} from "../../../../../../../../../../../../components/common";

import TabRoot from "../../components/TabRoot";

const History: React.FC<History.Props> = memo(
	({ visible, value, onChange }) => {
		const editor = useObjectEditor(value, onChange);

		return (
			<TabRoot hasPaddings visible={visible}>
				<StyledRow position="absolute" top="50%" left="50%">
					<SuspenseLoader />
				</StyledRow>
			</TabRoot>
		);
	},
);

declare namespace History {
	interface Props {
		visible: boolean;
		value: Value;
		onChange: (x: Value) => void;
	}

	interface Value {}
}

export default History;
