import React, { memo } from "react";

import OrderTab from "../../../../../../../../../../../tabs/OrderTab";
import Order from "../../../../../../../../../../../../../../../../../../services/Order";

const Orders: React.FC<Orders.Props> = memo(
	({ visible, entityId, options }) => (
		<OrderTab
			entityId={entityId}
			visible={visible}
			options={options}
			saveKey="customers.counterparties.tabs.accounts.tabs.orders"
		/>
	),
);

declare namespace Orders {
	interface Props {
		visible: boolean;
		entityId: number | undefined;
		options: Order.SubscribeOptions;
	}
}

export default Orders;
