import React from "react";
import { Icon, Overlayer, Row, theme } from "uikit";

const Rating: React.FC<Rating.Props> = ({ value }) => (
	<Row align="center" gaps="10px*">
		<Overlayer
			overlay={
				<Row
					style={{
						width: `${((value / 5) * 100).toFixed(2)}%`,
						overflow: "hidden",
					}}
					sizes="auto!*"
					align="center"
					gaps="2px*"
				>
					<Icon id="star" size={16} colors={["#ffb300"]} />
					<Icon id="star" size={16} colors={["#ffb300"]} />
					<Icon id="star" size={16} colors={["#ffb300"]} />
					<Icon id="star" size={16} colors={["#ffb300"]} />
					<Icon id="star" size={16} colors={["#ffb300"]} />
				</Row>
			}
		>
			<Row align="center" gaps="2px*">
				<Icon
					id="star"
					size={16}
					colors={[theme.colors.disabled_text]}
				/>
				<Icon
					id="star"
					size={16}
					colors={[theme.colors.disabled_text]}
				/>
				<Icon
					id="star"
					size={16}
					colors={[theme.colors.disabled_text]}
				/>
				<Icon
					id="star"
					size={16}
					colors={[theme.colors.disabled_text]}
				/>
				<Icon
					id="star"
					size={16}
					colors={[theme.colors.disabled_text]}
				/>
			</Row>
		</Overlayer>
		{value.toFixed(2)}
	</Row>
);

declare namespace Rating {
	type Value = number;

	interface Props {
		value: Value;
	}
}

export default Rating;
