/* eslint-disable no-shadow */

import React, { Dispatch, RefAttributes, useMemo } from "react";
import { react, useRefWithSetter } from "uikit";

import Language from "../../../../../../../../../../services/Language";
import useObjectEditor from "../../../../../../../../../../hooks/useObjectEditor";

import InternalController from "./Controller";
import Root from "./components/Root";
import MainTab from "./components/MainTab";
import PassportTab from "./components/PassportTab";
import FilesTab from "./components/FilesTab";
import AccessTab from "./components/AccessTab";
import HistoryTab from "./components/HistoryTab";

const Content = react.withController<Content.PropsBase, Content.Controller>(
	({
		controller,
		value,
		type,
		disabled,
		language,
		history,
		currentIP,
		onChange,
	}) => {
		const [mainTabRef, setMainTabRef] =
			useRefWithSetter<MainTab.Ref | null>(null);

		controller.setContext({ mainTabRef });

		const valueEditor = useObjectEditor(value, onChange);

		const mainTabValue = valueEditor.usePicker([
			"alias",
			"companyIds",
			"softPhoneSIPIds",
			"hardPhoneSIPIds",
			"defaultTaxiServiceId",
			"email",
			"fatherName",
			"firstName",
			"lastName",
			"login",
			"notes",
			"password",
			"phones",
			"roleIds",
			"carParkIds",
			"status",
			"taxiServiceIds",
		]);

		const passportTabValue = valueEditor.usePicker([
			"series",
			"number",
			"issuedAt",
			"issuedBy",
			"taxId",
			"birthAt",
			"residenceAddress",
			"livingAddress",
			"passportFiles",
		]);

		const filesTabValue = valueEditor.usePicker([
			"passportFiles",
			"personalPhotos",
			"otherFiles",
		]);

		const accessTabValue = valueEditor.usePicker(["allowedIpAddresses"]);

		const anyTabOnChange = valueEditor.useAssigner();

		const content = useMemo(
			() => (
				<>
					<MainTab
						ref={setMainTabRef}
						value={mainTabValue}
						disabled={disabled}
						visible={type === "main"}
						language={language}
						onChange={anyTabOnChange}
					/>
					<PassportTab
						value={passportTabValue}
						disabled={disabled}
						visible={type === "passport"}
						onChange={anyTabOnChange}
					/>
					<FilesTab
						value={filesTabValue}
						disabled={disabled}
						visible={type === "files"}
						onChange={anyTabOnChange}
					/>
					<HistoryTab
						value={history}
						visible={type === "history"}
						language={language}
					/>
					<AccessTab
						value={accessTabValue}
						disabled={disabled}
						visible={type === "access"}
						currentIP={currentIP}
						onChange={anyTabOnChange}
					/>
				</>
			),
			[
				setMainTabRef,
				mainTabValue,
				disabled,
				type,
				language,
				anyTabOnChange,
				passportTabValue,
				filesTabValue,
				history,
				accessTabValue,
				currentIP,
			],
		);

		return <Root>{content}</Root>;
	},
	InternalController,
);

declare namespace Content {
	type Ref = InternalController | null;

	type Controller = InternalController;

	type Type = "main" | "passport" | "files" | "history" | "access";

	type Value = MainTab.Value &
		PassportTab.Value &
		FilesTab.Value &
		AccessTab.Value;

	interface PropsBase {
		value: Value;

		type: Type;
		disabled: boolean;
		language: Language;
		history: HistoryTab.Value;
		currentIP: string;

		onChange: Dispatch<Value>;
	}

	type Props = PropsBase & RefAttributes<Ref>;
}

export default Content;
