import React, { Dispatch, memo, useCallback, useMemo, useState } from "react";
import { cloneDeep } from "lodash";
import { useTranslation } from "react-i18next";
import { Column } from "uikit";
import { v4 as uuid } from "uuid";

import useObjectEditor from "../../../../../../../../../../../../hooks/useObjectEditor";
import DefaultPageHeader from "../../../../../../../../../../../../components/DefaultPageHeader";
import DeleteModal from "../../../../../../../../../../../../components/DeleteModal";
import AutodetectPhoneErrorModal from "../../../../../../../../components/AutodetectPhoneErrorModal";
import {
	useAutodetectPhones,
	AutodetectPhone,
} from "../../../../../../../../hooks";
import TabRoot from "../../components/TabRoot";

import tPath from "./constants/tPath";
import Content from "./components/Content";
import defaultValue from "./constants/defaultValue";
import Modal from "./components/Modal";

const Phones: React.FC<Phones.Props> = memo(
	({ visible, hasPaddings, value, onChange }) => {
		const { t } = useTranslation();

		const { autodetectPhones } = useAutodetectPhones();

		const translationText = useMemo(
			() => ({
				addition: t(`${tPath}.addition`),
				deletion: t(`${tPath}.deletion`),
				thisAgent: t(`${tPath}.thisAgent`),
				thisCard: t(`${tPath}.thisCard`),
			}),
			[t],
		);

		const editor = useObjectEditor(value, onChange);

		const phones = editor.useGetter("phones");
		const setPhones = editor.useSetter("phones");

		const [actualDefaultValue] = useState(defaultValue);

		const [showModal, setShowModal] = useState(false);
		const [showDeleteModal, setShowDeleteModal] = useState(false);
		const [showErrorModal, setShowErrorModal] = useState<boolean>(false);
		const [selected, setSelected] = useState<number[]>([]);
		const [errorData, setErrorData] = useState<AutodetectPhone[]>([]);
		const [editingItem, setEditingItem] =
			useState<Modal.Value>(actualDefaultValue);
		const [indexEditingItem, setIndexEditingItem] = useState<number | null>(
			null,
		);

		const edit = useCallback(
			(index: number) => {
				const item = phones[index];

				setEditingItem(cloneDeep(item));
				setShowModal(true);
				setSelected([]);
			},
			[phones],
		);

		const editHeaderHandler = useCallback(() => {
			setIndexEditingItem(selected[0]);
			edit(selected[0]);
		}, [edit, selected]);

		const editContentHandler = useCallback(
			(index: number) => {
				setIndexEditingItem(index);
				edit(index);
			},
			[edit],
		);

		const addHandler = useCallback(() => {
			const id = uuid();
			setEditingItem({ ...actualDefaultValue, id });
			setShowModal(true);
		}, [actualDefaultValue]);

		const preDeleteHandler = useCallback(() => {
			setShowDeleteModal(true);
		}, []);

		const cancelDelete = useCallback(() => {
			setShowDeleteModal(false);
			setSelected([]);
		}, []);

		const deleteHandler = useCallback(() => {
			const newRewards = phones.filter((_, i) => !selected.includes(i));
			setPhones(newRewards);
			setSelected([]);
			setShowDeleteModal(false);
		}, [phones, selected, setPhones]);

		const cancelHandler = useCallback(() => {
			setShowModal(false);
			setEditingItem(actualDefaultValue);
		}, [actualDefaultValue]);

		const allPhones = useMemo(() => {
			const thisAgentPhoneList = phones
				?.filter((item) => item.isAgent === true)
				?.map((phone) => ({
					value: phone.number,
					phoneId: phone.id,
					isAgent: phone.isAgent,
					agentName: translationText.thisAgent,
					cardName: translationText.thisCard,
				}));

			return [...thisAgentPhoneList, ...autodetectPhones];
		}, [
			autodetectPhones,
			phones,
			translationText.thisAgent,
			translationText.thisCard,
		]);

		const saveHandler = useCallback(
			(model: Modal.Value, index: number | null = null) => {
				const internalIndex = index ?? indexEditingItem;

				if (internalIndex != null) {
					const newModels = [...phones];
					newModels[internalIndex] = model;
					setPhones(newModels);
				} else {
					setPhones([...phones, model]);
				}

				setShowModal(false);
				setEditingItem(actualDefaultValue);
				setIndexEditingItem(null);
			},
			[actualDefaultValue, phones, indexEditingItem, setPhones],
		);

		return (
			<TabRoot visible={visible} hasPaddings={hasPaddings}>
				<Column sizes="auto! 1fr" gaps="16px" maxedWidth maxedHeight>
					<DefaultPageHeader
						canEdit={selected.length === 1}
						canDelete={!!selected.length}
						onAdd={addHandler}
						onDelete={preDeleteHandler}
						onEdit={editHeaderHandler}
						padding="20px 20px 0 20px"
					/>
					<Content
						value={phones}
						selected={selected}
						setSelected={setSelected}
						onEdit={editContentHandler}
						saveHandler={saveHandler}
					/>
					{showModal && (
						<Modal
							value={editingItem}
							onCancel={cancelHandler}
							onSave={saveHandler}
							headerTitle={translationText.addition}
							setErrorData={setErrorData}
							allPhones={allPhones}
							setShowErrorModal={setShowErrorModal}
						/>
					)}
					{showDeleteModal && (
						<DeleteModal
							label={translationText.deletion}
							onCancel={cancelDelete}
							onConfirm={deleteHandler}
						/>
					)}
					{showErrorModal && (
						<AutodetectPhoneErrorModal
							value={errorData}
							show={showErrorModal}
							setShowModal={setShowErrorModal}
						/>
					)}
				</Column>
			</TabRoot>
		);
	},
);

declare namespace Phones {
	interface Props extends TabRoot.Props {
		value: Value;
		onChange: Dispatch<Value>;
	}

	interface Value {
		phones: Modal.Value[];
	}
}

export default Phones;
