import React, {
	Dispatch,
	RefAttributes,
	useCallback,
	useMemo,
	useState,
} from "react";
import { useTranslation } from "react-i18next";
import { MultiSelect, Option, react } from "uikit";
import SIP from "../../../../../../../../../../../../../../../../services/SIP";

import useModelSubscribe from "../../../../../../../../../../../../../../../../hooks/useModelSubscribe2";

import mapByKey from "../../../../../../../../../../../../../../../../utils/mapByKey";
import LabeledField from "../../../../../../../../../../../../../../../../components/LabeledField";
import InternalController from "./Controller";

const SIPIds = react.withController<SIPIds.PropsBase, SIPIds.Controller>(
	({
		value,

		disabled,
		type,

		onChange,
	}) => {
		const { t } = useTranslation();
		const subscriptionOptions = useMemo(() => {
			const result: SIP.SubscribeOptions = {};

			// if (type === "soft-phone") result.isSoftPhone = true;
			// else if (type === "hard-phone") result.isSoftPhone = false;

			return result;
		}, []);

		const modelData = useModelSubscribe(subscriptionOptions, SIP);

		const items = useMemo(() => modelData.models, [modelData.models]);

		const [error, setError] = useState(false);

		const selectOptions = useMemo<Option<number>[]>(
			() =>
				items
					.filter((item) => {
						if (
							(type === "soft-phone" && item.isSoftPhone) ||
							(type === "hard-phone" && !item.isSoftPhone)
						)
							return true;

						return false;
					})
					.map((item) => ({
						key: item.id,
						label: item.value,
						value: item.id,
					})),
			[items, type],
		);

		const selectOptionByKey = useMemo(
			() => mapByKey(selectOptions, "key"),
			[selectOptions],
		);

		const selectOnChange = useCallback(
			(keys: any[]) => {
				setError(false);

				onChange(keys.map((key) => selectOptionByKey[key].value));
			},
			[onChange, selectOptionByKey],
		);

		return (
			<LabeledField
				label={
					type === "soft-phone"
						? t(
								"pages.mainPage.pages.accounts.tabs.users.editModal.content.mainTab.workData.sIPIds.str200",
						  ) ?? ""
						: t(
								"pages.mainPage.pages.accounts.tabs.users.editModal.content.mainTab.workData.sIPIds.str201",
						  ) ?? ""
				}
			>
				<MultiSelect
					value={value}
					disabled={disabled}
					error={error}
					placeholder={
						t(
							"pages.mainPage.pages.accounts.tabs.users.editModal.content.mainTab.workData.sIPIds.str0",
						) ?? ""
					}
					options={selectOptions}
					onChange={selectOnChange}
				/>
			</LabeledField>
		);
	},
	InternalController,
);

declare namespace SIPIds {
	type Ref = InternalController | null;

	type Controller = InternalController;

	type Value = number[];

	interface PropsBase {
		value: Value;

		disabled: boolean;
		type?: "soft-phone" | "hard-phone";

		onChange: Dispatch<Value>;
	}

	type Props = PropsBase & RefAttributes<Ref>;
}

export default SIPIds;
