import React from "react";
import { useTranslation } from "react-i18next";
import { Row, CheckBox, MultiSelect } from "uikit";

const Third: React.FC<Third.Props> = () => {
	const { t } = useTranslation();
	return (
		<Row sizes="auto 1fr" gaps="10px*">
			<CheckBox
				disabled
				label={
					t(
						"pages.mainPage.pages.accounts.tabs.executorTeams.modal.content.tabs.main.blocks.third.str0",
					) ?? ""
				}
			/>
			<MultiSelect
				disabled
				placeholder={
					t(
						"pages.mainPage.pages.accounts.tabs.executorTeams.modal.content.tabs.main.blocks.third.str1",
					) ?? ""
				}
				options={[
					{
						key: 1,
						value: 1,
						label:
							t(
								"pages.mainPage.pages.accounts.tabs.executorTeams.modal.content.tabs.main.blocks.third.str200",
							) ?? "",
					},
					{
						key: 2,
						value: 2,
						label: t(
							"pages.mainPage.pages.accounts.tabs.executorTeams.modal.content.tabs.main.blocks.third.str201",
						),
					},
				]}
			/>
		</Row>
	);
};

declare namespace Third {
	interface Props {}
}

export default Third;
