import React, {
	Dispatch,
	memo,
	useCallback,
	useLayoutEffect,
	useMemo,
} from "react";
import { useTranslation } from "react-i18next";

import { Column, Option, Row, Select, theme } from "uikit";

import ExecutorGroup from "../../../../../../../../../../../../../../services/ExecutorGroup";
import useObjectEditor from "../../../../../../../../../../../../../../hooks/useObjectEditor";
import { useExecutorTeamsContext } from "../../../../../../../../../../../context/ExecutorTeamsContext";
import LabeledField from "../../../../../../../../../../../../../../components/LabeledField";

const borderStyles = {
	border: `1px solid ${theme.colors.color_border_basic}`,
	borderRadius: theme.borderRadius.m,
	padding: 10,
};

const Selects: React.FC<Selects.Props> = memo(({ value, onChange }) => {
	const { t } = useTranslation();
	const { executorsApps, language } = useExecutorTeamsContext();

	const valueEditor = useObjectEditor(value, onChange);

	const executorAppId = valueEditor.useGetter("executorAppId");
	const setExecutorAppId = valueEditor.useSetter("executorAppId");

	useLayoutEffect(() => {
		if (!executorAppId) {
			const exist = executorsApps.find((item) => item.root === true);
			if (exist) setExecutorAppId(exist.id);
		}
	}, [executorAppId, executorsApps, setExecutorAppId]);

	const handleExecutorAppIdSelect = useCallback(
		(id) => {
			if (id) setExecutorAppId(id);
		},
		[setExecutorAppId],
	);

	const executorAppOptions = useMemo<Option<number | string>[]>(
		() =>
			executorsApps?.map((item) => ({
				value: item.id,
				key: item.id,
				label: item.name?.[language] || "",
			})),
		[executorsApps, language],
	);

	return (
		<Row sizes="49% 49%" justify="space-between" style={borderStyles}>
			<Column gaps="15px*">
				<LabeledField
					label={
						t(
							"pages.mainPage.pages.accounts.tabs.executorTeams.modal.content.tabs.main.selects.str0",
						) ?? ""
					}
				>
					<Select
						disabled
						placeholder={
							t(
								"pages.mainPage.pages.accounts.tabs.executorTeams.modal.content.tabs.main.selects.str1",
							) ?? ""
						}
					/>
				</LabeledField>
				<LabeledField
					label={
						t(
							"pages.mainPage.pages.accounts.tabs.executorTeams.modal.content.tabs.main.selects.str2",
						) ?? ""
					}
				>
					<Select
						disabled={!executorAppOptions.length}
						value={executorAppId}
						options={executorAppOptions}
						onChange={handleExecutorAppIdSelect}
						placeholder={
							t(
								"pages.mainPage.pages.accounts.tabs.executorTeams.modal.content.tabs.main.selects.str3",
							) ?? ""
						}
					/>
				</LabeledField>
			</Column>
			<Column gaps="15px*">
				<LabeledField
					label={
						t(
							"pages.mainPage.pages.accounts.tabs.executorTeams.modal.content.tabs.main.selects.str4",
						) ?? ""
					}
				>
					<Select
						disabled
						placeholder={
							t(
								"pages.mainPage.pages.accounts.tabs.executorTeams.modal.content.tabs.main.selects.str5",
							) ?? ""
						}
					/>
				</LabeledField>
				<LabeledField
					label={
						t(
							"pages.mainPage.pages.accounts.tabs.executorTeams.modal.content.tabs.main.selects.str6",
						) ?? ""
					}
				>
					<Select
						disabled
						placeholder={
							t(
								"pages.mainPage.pages.accounts.tabs.executorTeams.modal.content.tabs.main.selects.str7",
							) ?? ""
						}
					/>
				</LabeledField>
			</Column>
		</Row>
	);
});

declare namespace Selects {
	interface Value extends Pick<ExecutorGroup.Model, "executorAppId"> {}

	interface Props {
		value: Value;
		onChange: Dispatch<Value>;
	}
}

export default Selects;
