import styled from "styled-components";
import { theme } from "uikit";

const Label = styled.div`
	font-family: "Lato";
	font-style: normal;
	font-weight: 600;
	font-size: 12px;
	line-height: 14px;

	color: ${theme.colors.secondary};
`;

export default Label;
