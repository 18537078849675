import React, { Dispatch, memo, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Column, Tabs } from "uikit";
import moment from "moment";

import { getTabsModeOne } from "../../../../../../../../../../utils";
import Rating from "../../../../../../../../../../components/Rating";

import Root from "./components/Root";
import Title from "./components/Title";
import InformationContainer from "./components/InformationContainer";
import SubTitle from "./components/SubTitle";
import LockedBy from "./components/LockedBy";

const Header: React.FC<Header.Props> = memo(
	({
		tab,

		title,
		lockedBy,
		createdAt,
		rating,

		onChangeTab,
	}) => {
		const { t } = useTranslation();

		const tabs = useMemo(() => {
			const selectedTabs: Parameters<typeof getTabsModeOne>[1] = [
				"main",
				"options",
				"additional-data",
				"cars",
				"files",
				"comments",
				"orders",
				"transactions",
				"history",
				"customers",
			];

			return getTabsModeOne(t, selectedTabs);
		}, [t]);

		return (
			<Root sizes="auto!*">
				<InformationContainer
					align="center"
					justify="space-between"
					gaps="10px"
				>
					<Title>{title}</Title>
					<Column align="end" gaps="15px*">
						{lockedBy ? (
							<LockedBy>
								{t(
									"pages.mainPage.pages.accounts.tabs.executors.editModal.header.str200",
								) ?? ""}{" "}
								{lockedBy}
							</LockedBy>
						) : (
							<></>
						)}
						{createdAt ? (
							<>
								<SubTitle>
									{`${
										t(
											"pages.mainPage.pages.accounts.tabs.executors.editModal.header.str201",
										) ?? ""
									}: ${moment(createdAt).format(
										"DD.MM.YYYY HH:mm",
									)}`}
								</SubTitle>
								<Rating value={rating ?? 0} />
							</>
						) : (
							<></>
						)}
					</Column>
				</InformationContainer>
				<Tabs
					value={tab}
					options={tabs}
					variant="panels"
					onChange={(newTab) =>
						onChangeTab(newTab.toString() as Header.Tab)
					}
				/>
			</Root>
		);
	},
);

declare namespace Header {
	type Tab =
		| "main"
		| "options"
		| "additional-data"
		| "cars"
		| "files"
		| "comments"
		| "orders"
		| "transactions"
		| "history"
		| "customers";

	interface Props {
		tab: Tab;

		title: string;
		lockedBy?: string;
		createdAt?: string;
		rating?: number;

		onChangeTab: Dispatch<Tab>;
	}
}

export default Header;
