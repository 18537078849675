import { TFunction } from "i18next";

interface Column {
	id: string;
	label: string;
}

const getColumns = (t: TFunction): Column[] => [
	{
		id: "active",
		label:
			t(
				"pages.mainPage.pages.accounts.tabs.carParks.content.modelTable.active",
			) ?? "",
	},
	{
		id: "name",
		label:
			t(
				"pages.mainPage.pages.accounts.tabs.carParks.content.modelTable.name",
			) ?? "",
	},
	{
		id: "taxiServices",
		label:
			t(
				"pages.mainPage.pages.accounts.tabs.carParks.content.modelTable.taxiServices",
			) ?? "",
	},
	{
		id: "createdAt",
		label:
			t(
				"pages.mainPage.pages.accounts.tabs.carParks.content.modelTable.createdAt",
			) ?? "",
	},
];

const defaultColumnIds = ["active", "name", "taxiServices", "createdAt"];

export { getColumns, defaultColumnIds };
