import { react } from "uikit";
import { Dispatch, SetStateAction } from "react";

import Main from ".";

interface Context {
	value: Main.Value;
	cardNumberError: string | boolean;
	setCardNumberError: Dispatch<SetStateAction<string | boolean>>;
	setCardNumber: (value: string) => void;

	setKeywordError: Dispatch<SetStateAction<boolean>>;
	setKeyword: (value: string) => void;
}

export default class Controller extends react.ControllerBase<Context> {
	validate() {
		const cardNumberError = this.context?.cardNumberError;
		const cardNumber = this.context?.value.code.value.trim();
		const isValidCardNumber = !!cardNumber?.length;
		const isValidKeyword = !!this.context?.value.code.keyword.trim().length;

		if (!isValidCardNumber) {
			this.context?.setCardNumberError(true);
			this.context?.setCardNumber("");
		}
		if (!isValidKeyword) {
			this.context?.setKeywordError(true);
			this.context?.setKeyword("");
		}

		return isValidCardNumber && isValidKeyword && !cardNumberError;
	}
}
