import styled from "styled-components";
import { theme } from "uikit";

const Label = styled.div`
	color: ${theme.colors.success};
	font-family: Lato;
	font-size: 13px;
	font-style: normal;
	font-weight: 700;
	line-height: 16px;
`;

export default Label;
