import React from "react";
import HistoryTab from "../../../../../tabs/HistoryTab";

const History: React.FC<History.Props> = ({ visible, value }) => (
	<HistoryTab visible={visible} value={value} />
);

declare namespace History {
	interface Props {
		visible: boolean;
		value: any;
	}
}

export default History;
