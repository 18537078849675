import React, { Dispatch, memo, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";

import Counterparty from "../../../../../../../../../../../../../../services/Counterparty";
import ConfirmModal from "../../../../../../../../../../../../../../components/DeleteModal";
import EditModal from "../../../../../../../../../../../../../../components/EditModal";
import { UseTableOptions } from "../../../../../../../../../../../../../../components/LightTable";

import Header from "./components/Header";
import Root from "./components/Root";
import Table from "./components/Table";

const Modal: React.FC<Modal.Props> = memo((props) => {
	const {
		onChangeTable,
		editorTable,
		counterparties,
		counterpartyById,
		value,
		onCancel,
		onSave,
	} = props;

	const { t } = useTranslation();

	const [selected, setSelected] = useState<Modal.Value>(value);
	const [showConfirmModal, setShowConfirmModal] = useState(false);

	const handleSubmit = useCallback(() => {
		const agentHasGroup = selected.some(
			(id) => counterpartyById[id].counterpartyGroupId,
		);

		if (agentHasGroup) {
			setShowConfirmModal(true);
			return;
		}

		onSave(selected);
		setSelected([]);
	}, [counterpartyById, onSave, selected]);

	const cancelHandler = useCallback(() => {
		setShowConfirmModal(false);
	}, []);

	const confirmHandler = useCallback(() => {
		onSave(selected);
		setShowConfirmModal(false);
		setSelected([]);
	}, [onSave, selected]);

	return (
		<EditModal onCancel={onCancel} onSave={handleSubmit}>
			<Root sizes="auto 1fr">
				<Header
					title={
						t(
							"pages.mainPage.pages.customers.tabs.counterpartyGroups.modal.content.main.modal.str0",
						) ?? ""
					}
				/>

				<Table
					value={counterparties}
					selected={selected}
					setSelected={setSelected}
					editorTable={editorTable}
					onChangeTable={onChangeTable}
					showFilter
				/>
			</Root>
			{showConfirmModal && (
				<ConfirmModal
					label={
						t(
							"pages.mainPage.pages.customers.tabs.counterpartyGroups.modal.content.main.modal.str1",
						) ?? ""
					}
					onCancel={cancelHandler}
					onConfirm={confirmHandler}
				/>
			)}
		</EditModal>
	);
});

declare namespace Modal {
	interface Props {
		counterparties: Counterparty.Model[];
		counterpartyById: Record<number, Counterparty.Model>;
		editorTable: UseTableOptions["editor"];
		onChangeTable: UseTableOptions["onChange"];
		value: Value;
		onCancel: () => void;
		onSave: Dispatch<Value>;
	}

	type Value = number[];
}

export default Modal;
