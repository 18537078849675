import { Dispatch, useCallback } from "react";
import File from "../../../../../../../../../../../../../services/File";
import useObjectEditor from "../../../../../../../../../../../../../hooks/useObjectEditor";

type FilePropertyName<Value> = keyof {
	[K in keyof Value as Value[K] extends File.Model[]
		? K
		: never]: File.Model[];
};

function useFileEditor<Value>(
	valueEditor: ReturnType<typeof useObjectEditor<Value>>,
	propertyName: FilePropertyName<Value>,
) {
	const filesValue = valueEditor.useGetter(
		propertyName as keyof Value,
	) as File.Model[];
	const setFilesValue = valueEditor.useSetter(
		propertyName as keyof Value,
	) as Dispatch<File.Model[]>;

	const filesOnChange = useCallback(
		(newFiles: File.Model[]) => {
			newFiles.forEach((file) => file.upload());

			setFilesValue(newFiles);
		},
		[setFilesValue],
	);

	return [filesValue, filesOnChange] as [
		typeof filesValue,
		typeof filesOnChange,
	];
}

export default useFileEditor;
