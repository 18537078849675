import React, { Dispatch, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { clone } from "lodash";

import ColumnSetupModal from "../ColumnSetupModal";

import { Button, Root } from "./components";

const TableSettings: React.FC<TableSettings.Props> = ({
	value,
	defaultValue,
	columns,
	disabledColumnsIds = [],
	onChange,
}) => {
	const { t } = useTranslation();
	const [showColumnModal, setShowColumnModal] = useState(false);

	const resetButtonOnClick = useCallback(
		() =>
			defaultValue &&
			onChange?.(
				clone(
					defaultValue?.filter(
						(id) => id !== "company" && id !== "taxiService",
					),
				),
			),
		[defaultValue, onChange],
	);
	const editButtonOnClick = useCallback(() => setShowColumnModal(true), []);

	const columnSetupModalOnClose = useCallback(
		() => setShowColumnModal(false),
		[],
	);

	const columnSetupModalOnSubmit = useCallback(
		(columnSetupModalValue: ColumnSetupModal.Value) => {
			onChange?.(columnSetupModalValue);
			setShowColumnModal(false);
		},
		[onChange],
	);

	return (
		<>
			<Root align="center" gaps="16px">
				<Button
					icon="reset"
					label={t("tableSettings.str0") ?? ""}
					onClick={resetButtonOnClick}
				/>
				<Button
					icon="table-edit"
					label={t("tableSettings.str1") ?? ""}
					onClick={editButtonOnClick}
				/>
			</Root>

			{showColumnModal && (
				<ColumnSetupModal
					columns={columns}
					disabledColumns={disabledColumnsIds}
					value={value}
					onClose={columnSetupModalOnClose}
					onSubmit={columnSetupModalOnSubmit}
				/>
			)}
		</>
	);
};

declare namespace TableSettings {
	type Column = ColumnSetupModal.Column;
	type Value = ColumnSetupModal.Value;

	interface Props {
		value?: Value;

		defaultValue?: Value;
		columns?: Column[];
		disabledColumnsIds?: Column["id"][];

		onChange?: any;
	}
}

export default TableSettings;
