import React, { Dispatch, memo, useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Icon, InputGroup, Row, TextBox, theme } from "uikit";
import { clamp, clone } from "lodash";

import LabeledField from "../../../../../../../../../../../../../../../../../../../../components/LabeledField";
import useObjectEditor from "../../../../../../../../../../../../../../../../../../../../hooks/useObjectEditor";
import {
	countryCodePhone,
	validatePhone,
} from "../../../../../../../../../../../../../../../../../../../../utils/validatePhone";

const Phones = memo<Phones.Props>(
	({ value, min = 1, max = Number.MAX_SAFE_INTEGER, onChange }) => {
		const { t } = useTranslation();
		const internalValue = useMemo(
			() =>
				value?.length < min
					? clone(value).concat(Array(min - value.length).fill(""))
					: clone(value)?.slice(0, max),
			[max, min, value],
		);
		const [errors, setError] = useState({
			0: false,
			1: false,
		});

		const valueEditor = useObjectEditor(internalValue, onChange);

		const addPhone = useCallback(() => {
			valueEditor.push("");
		}, [valueEditor]);

		const onChangePhone = useCallback(
			(newPhone: string, index: number) => {
				const modifyPhone = countryCodePhone(newPhone, "uk");
				const isValid = validatePhone(modifyPhone);

				setError((prev) => ({
					...prev,
					[index]: !isValid,
				}));

				valueEditor.set(index, modifyPhone);
			},
			[valueEditor],
		);

		const renderField = useCallback(
			(index: number) => {
				const isFirstItem = index === 0;
				const isLastItem = index === valueEditor.value.length - 1;
				const isMaxedItem = index === (max ?? 0) - 1;
				const hasMoreThanOneItem = valueEditor.value.length > 1;

				const label = isFirstItem
					? t(
							"pages.mainPage.pages.customers.tabs.counterparties.modal.content.accounts.modal.content.tabs.main.phones.str200",
					  ) ?? ""
					: t(
							"pages.mainPage.pages.customers.tabs.counterparties.modal.content.accounts.modal.content.tabs.main.phones.str201",
					  ) ?? "";

				let sizes = "";

				if (isFirstItem && !hasMoreThanOneItem) sizes = "1fr 32px";
				else if (!isLastItem || (isLastItem && isMaxedItem))
					sizes = "1fr";
				else if (isLastItem) sizes = "1ft 32px";

				return (
					<Row
						key={index}
						gaps="8px*"
						sizes={sizes}
						align="end"
						justify="stretch"
					>
						<LabeledField label={label}>
							{!isFirstItem ? (
								<InputGroup.InputGroup
									sizes="1fr 32px!"
									error={errors[index]}
								>
									<TextBox.TextBox
										error={errors[index]}
										value={valueEditor.get(index) ?? ""}
										autoComplete="one-time-code"
										type="phone"
										style={{
											minHeight: "32px",
											flex: "1 0 0",
										}}
										placeholder={label}
										onChange={(newPhone) => {
											onChangePhone(newPhone, index);
										}}
									/>
									<Button.Button
										icon={
											<Icon
												id="minus"
												size={16}
												colors={[theme.colors.white]}
											/>
										}
										style={{
											borderTopLeftRadius: 0,
											borderBottomLeftRadius: 0,
										}}
										onClick={() =>
											valueEditor.remove(index)
										}
									/>
								</InputGroup.InputGroup>
							) : (
								<TextBox.TextBox
									error={errors[index]}
									value={valueEditor.get(index) ?? ""}
									autoComplete="one-time-code"
									type="phone"
									style={{
										minHeight: "32px",
										flex: "1 0 0",
									}}
									placeholder={label}
									onChange={(newPhone) => {
										onChangePhone(newPhone, index);
									}}
								/>
							)}
						</LabeledField>
						{isLastItem && !isMaxedItem && (
							<Button.Button
								icon={
									<Icon
										id="plus"
										size={16}
										colors={[theme.colors.white]}
									/>
								}
								onClick={addPhone}
							/>
						)}
					</Row>
				);
			},
			[valueEditor, max, errors, addPhone, onChangePhone, t],
		);

		return (
			<>
				{Array(clamp(valueEditor?.value?.length, min, max))
					.fill(null)
					.map((_, index) => renderField(index))}
			</>
		);
	},
);

declare namespace Phones {
	type Value = string[];

	interface Props {
		value: Value;
		min?: number;
		max?: number;
		onChange: Dispatch<Value>;
	}
}

export default Phones;
