import React, { memo, useCallback, useState } from "react";
import { Button, Icon } from "uikit";
import { useTranslation } from "react-i18next";

import Client from "../../../../../../../../../../services/Client";
import { PaymentProviderAccessorType } from "../../../../../../../../../../services/Payment/types";
import { updateBalance } from "../../../../../../../../../../utils/updateBalances";
import { PaymentAccount } from "../../../../../../../../../../types/PaymentAccount";
import BalanceModal from "../../../../../../../../../../components/BalanceModal";
import {
	Popup,
	StyledP,
} from "../../../../../../../../../../components/common";

const Balance: React.FC<Balance.Props> = memo(
	({ client, paymentAccount: account, iconId, title }) => {
		const { t } = useTranslation();
		const [showModal, setShowModal] = useState(false);

		const handleOpen = useCallback(() => {
			setShowModal(true);
		}, []);
		const handleClose = useCallback(() => {
			setShowModal(false);
		}, []);

		const handleSubmit = useCallback(
			async ({ amount, description }) => {
				if (amount === 0 || !account || !client) return;

				const res = await updateBalance(
					{
						...account,
						isEdited: true,
						amount,
						description,
					},
					client.companyId,
					PaymentProviderAccessorType.COMPANY,
				);
				if (res) setShowModal(false);
			},
			[account, client],
		);

		return (
			<Popup
				useHoverControl
				trackerId={"client_call_id"}
				offset={{ x: 32, y: 33 }}
				tracker={
					<div>
						<Button.Button
							variant="secondary"
							disabled={!client}
							onClick={handleOpen}
							icon={<Icon id={iconId} size={20} />}
						/>
						{showModal && (
							<BalanceModal
								onClose={handleClose}
								onSubmit={handleSubmit}
							/>
						)}
					</div>
				}
			>
				{!showModal && (
					<StyledP
						br="4px"
						bgC="#000000"
						colors="#ffffff"
						p="5px 10px"
					>
						{t([title || ""]) || ""}
					</StyledP>
				)}
			</Popup>
		);
	},
);

declare namespace Balance {
	interface Props {
		client: Client.Model | false;
		paymentAccount?: PaymentAccount;
		iconId: "balance" | "bonus-balance";
		title?: string;
	}
}

export default Balance;
