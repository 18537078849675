import React, { Dispatch, RefAttributes, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Column, react } from "uikit";
import InternalController from "./Controller";
import File from "../../../../../../../../../services/File";
import Counterparty from "../../../../../../../../../services/Counterparty";
import useObjectEditor from "../../../../../../../../../hooks/useObjectEditor";
import FileListWithTitle from "../../../../../../../../../components/FileListWithTitle";

import Root from "../components/Root";

declare namespace FilesTab {
	type Ref = InternalController | null;
	type Controller = InternalController;

	interface PropsBase {
		visible: boolean;
		value: Value;
		onChange: Dispatch<Value>;
		showPersonalFiles?: boolean;
	}
	type Props = PropsBase & RefAttributes<Ref>;

	type Value = Pick<Counterparty.Model, "personalFiles" | "otherFiles">;
}

const FilesTab = react.withController<FilesTab.PropsBase, FilesTab.Controller>(
	({ visible, value, onChange, showPersonalFiles = false }) => {
		const { t } = useTranslation();
		const valueEditor = useObjectEditor(value, onChange);

		const personalFiles = valueEditor.useGetter("personalFiles");
		const setPersonalFiles = valueEditor.useSetter("personalFiles");

		const otherFiles = valueEditor.useGetter("otherFiles");
		const setOtherFiles = valueEditor.useSetter("otherFiles");

		const personalFilesOnChange = useCallback(
			(newFiles: File.Model[]) => {
				newFiles.forEach((file) => file.upload());
				setPersonalFiles(newFiles);
			},
			[setPersonalFiles],
		);

		const otherFilesOnChange = useCallback(
			(newFiles: File.Model[]) => {
				newFiles.forEach((file) => file.upload());
				setOtherFiles(newFiles);
			},
			[setOtherFiles],
		);

		return (
			<Root visible={visible} hasPaddings>
				<Column>
					<Column gaps="25px*" sizes="250px*">
						{showPersonalFiles && (
							<FileListWithTitle
								title={
									t(
										"pages.mainPage.pages.customers.tabs.counterparties.tabs.filesTab.str0",
									) ?? ""
								}
								value={personalFiles}
								onChange={personalFilesOnChange}
							/>
						)}
						<FileListWithTitle
							title={
								t(
									"pages.mainPage.pages.customers.tabs.counterparties.tabs.filesTab.str1",
								) ?? ""
							}
							value={otherFiles}
							onChange={otherFilesOnChange}
						/>
					</Column>
				</Column>
			</Root>
		);
	},
	InternalController,
);

export default FilesTab;
