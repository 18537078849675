import Content from "./index";

export enum Columns {
	AUTODETECTION = "autodetection",
	SETTLEMENT = "settlement",
	STREET = "street",
	HOUSE = "house",
}

export const columns: Content.ColumnType[] = [
	{ id: Columns.AUTODETECTION, label: "" },
	{ id: Columns.SETTLEMENT, label: "" },
	{ id: Columns.STREET, label: "" },
	{ id: Columns.HOUSE, label: "" },
];

export const defaultColumnIds = columns.map(({ id }) => id);
