import React, { Dispatch, memo } from "react";
import { useTranslation } from "react-i18next";
import { MultilineTextBox, Row } from "uikit";
import useObjectEditor from "../../../../../../../../../../../../../../hooks/useObjectEditor";

const Notes: React.FC<Notes.Props> = memo(({ value, onChange }) => {
	const editor = useObjectEditor(value, onChange);
	const { t } = useTranslation();

	const orderNotes = editor.useGetter("orderNotes");
	const setOrderNotes = editor.useSetter("orderNotes");
	const executorNotes = editor.useGetter("executorNotes");
	const setExecutorNotes = editor.useSetter("executorNotes");
	const notes = editor.useGetter("notes");
	const setNotes = editor.useSetter("notes");

	return (
		<Row gaps="10px*" sizes="1fr 1fr 1fr" style={{ flex: "1 1 auto" }}>
			<MultilineTextBox
				value={orderNotes ?? ""}
				onChange={setOrderNotes}
				placeholder={
					t(
						"pages.mainPage.pages.customers.tabs.agents.modal.content.tabs.main.notes.str0",
					) ?? ""
				}
			/>
			<MultilineTextBox
				value={executorNotes ?? ""}
				onChange={setExecutorNotes}
				placeholder={
					t(
						"pages.mainPage.pages.customers.tabs.agents.modal.content.tabs.main.notes.str0",
					) ?? ""
				}
			/>
			<MultilineTextBox
				value={notes ?? ""}
				onChange={setNotes}
				placeholder={
					t(
						"pages.mainPage.pages.customers.tabs.agents.modal.content.tabs.main.notes.str0",
					) ?? ""
				}
			/>
		</Row>
	);
});

declare namespace Notes {
	interface Value {
		orderNotes?: string;
		executorNotes?: string;
		notes?: string;
	}

	interface Props {
		value: Value;
		onChange: Dispatch<Value>;
	}
}

export default Notes;
