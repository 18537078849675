import React, {
	Dispatch,
	RefAttributes,
	useCallback,
	useMemo,
	useState,
} from "react";
import { useTranslation } from "react-i18next";
import { Option, Select, react } from "uikit";
import CarPark from "../../../../../../../../../../../../../../../../services/CarPark";
import useModelSubscribe from "../../../../../../../../../../../../../../../../hooks/useModelSubscribe2";
import LabeledField from "../../../../../../../../../../../../../../../../components/LabeledField";

import mapByKey from "../../../../../../../../../../../../../../../../utils/mapByKey";

import InternalController from "./Controller";

const CarParkId = react.withController<
	CarParkId.PropsBase,
	CarParkId.Controller
>(
	({
		controller,

		value,

		disabled,
		taxiServiceId,

		onChange,
	}) => {
		const { t } = useTranslation();
		const taxiServiceIds = useMemo(
			() => (typeof taxiServiceId === "number" ? [taxiServiceId] : []),
			[taxiServiceId],
		);

		const modelData = useModelSubscribe({ taxiServiceIds }, CarPark);

		const items = useMemo(() => modelData.models, [modelData.models]);

		const [error, setError] = useState(false);

		const selectOptions = useMemo<Option<number>[]>(
			() =>
				items.map((item) => ({
					key: item.id,
					label: item.name,
					value: item.id,
				})),
			[items],
		);

		const selectOptionByKey = useMemo(
			() => mapByKey(selectOptions, "key"),
			[selectOptions],
		);

		const selectOnChange = useCallback(
			(key: any) => {
				setError(false);

				onChange(selectOptionByKey[key].value);
			},
			[onChange, selectOptionByKey],
		);

		controller.setContext({ value, setError });

		return (
			<LabeledField
				label={
					t(
						"pages.mainPage.pages.accounts.tabs.car.editModal.content.mainTab.workData.carParkId.str0",
					) ?? ""
				}
			>
				<Select
					value={value}
					disabled={selectOptions.length === 0 || disabled}
					error={error}
					placeholder={
						t(
							"pages.mainPage.pages.accounts.tabs.car.editModal.content.mainTab.workData.carParkId.str0",
						) ?? ""
					}
					options={selectOptions}
					onChange={selectOnChange}
				/>
			</LabeledField>
		);
	},
	InternalController,
);

declare namespace CarParkId {
	type Ref = InternalController | null;

	type Controller = InternalController;

	type Value = number | undefined;
	type NewValue = number;

	interface PropsBase {
		value: Value;

		disabled: boolean;
		taxiServiceId?: number;

		onChange: Dispatch<NewValue>;
	}

	type Props = PropsBase & RefAttributes<Ref>;
}

export default CarParkId;
