import { Option } from "uikit";
import objectToString from "./objectToString";
import Map from "../../../../../../../../../../../../../../../../../../services/Map";

const getTextSelectOptions = (obj: Map.Search.Object[]): Option<any>[] =>
	obj?.map((o, i) => ({
		key: i,
		label: objectToString(o.type, o),
		value: {
			country: o.country,
			countryCode: o.countryCode,
			coordinates: o.coordinates,

			settlementType: o.settlementType,
			settlement: o.settlement,
			district: o.district,
			region: o.region,

			streetType: o.streetType,
			street: o.street,
			houses: o.houses?.map((house) => house?.number) ?? [],
			name: o.name,
			number: o.number,
		},
	}));

export default getTextSelectOptions;
