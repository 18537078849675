import React, { RefAttributes, memo, useMemo } from "react";
import { Row, react, useRefWithSetter } from "uikit";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import TaxiService from "../../../../../../../../../../../../../../services/TaxiService2";
import { useTypedSelector } from "../../../../../../../../../../../../../../redux/store";
import useObjectEditor from "../../../../../../../../../../../../../../hooks/useObjectEditor";
import LabeledField from "../../../../../../../../../../../../../../components/LabeledField";
import useModelSubscribe from "../../../../../../../../../../../../../../hooks/useModelSubscribe2";
import FieldsContainer from "../../../../../../../../../../../../../../components/FieldsContainer";

import CompanySelect from "./components/CompanySelect";
import TaxiServiceSelect from "./components/TaxiServiceSelect";
import Status from "./components/Status";
import CounterpartyConnect from "./components/CounterpartyConnect";
import AgentGroup from "./components/AgentGroup";
import Controller from "./Controller";

const StyledPadding = styled.div`
	padding-inline: 12px;
	padding-block: 6px;
`;

const OtherBase = react.withController<Other.PropsBase, Controller>((props) => {
	const { controller, counterparties, groups, disabled, value, onChange } =
		props;

	const { t } = useTranslation();
	const lang = useTypedSelector((state) => state.session.language);

	const [companySelectRef, setCompanySelectRef] =
		useRefWithSetter<CompanySelect.Ref | null>(null);
	const [taxiServiceSelectRef, setTaxiServiceSelectRef] =
		useRefWithSetter<TaxiServiceSelect.Ref | null>(null);
	const [counterpartyConnectRef, setCounterpartyConnectRef] =
		useRefWithSetter<CounterpartyConnect.Ref>(null);
	controller.setContext({
		companySelectRef,
		taxiServiceSelectRef,
		counterpartyConnectRef,
	});

	const editor = useObjectEditor(value, onChange);

	const status = editor.useGetter("status");
	const setStatus = editor.useSetter("status");

	const companyId = editor.useGetter("companyId");
	const setCompanyId = editor.useSetter("companyId");

	const agentGroupId = editor.useGetter("agentGroupId");
	const setAgentGroupId = editor.useSetter("agentGroupId");

	const defaultTaxiServiceId = editor.useGetter("defaultTaxiServiceId");
	const setDefaultTaxiServiceId = editor.useSetter("defaultTaxiServiceId");
	const latestTaxiServiceId = editor.useGetter("latestTaxiServiceId");

	const counterpartyConnectValue = editor.usePicker([
		"counterpartyId",
		"checkId",
		"balanceId",
	]);

	const taxiServiceOptions = useMemo(
		() => ({
			companyIds: companyId ? [companyId] : undefined,
		}),
		[companyId],
	);

	const { models: taxiServices } = useModelSubscribe(
		taxiServiceOptions,
		TaxiService,
	);

	const latestTaxiService = useMemo(
		() =>
			taxiServices?.find((c) => c.id === latestTaxiServiceId)?.settlement[
				lang
			],
		[lang, latestTaxiServiceId, taxiServices],
	);

	const assigner = editor.useAssigner();

	return (
		<FieldsContainer label={t("component_other") || ""} gaps="16px*">
			<Row gaps="8px*" sizes="1fr*" align="start">
				<Status value={status} onChange={setStatus} />
				<CompanySelect
					ref={setCompanySelectRef}
					disabled={disabled}
					value={companyId}
					onChange={setCompanyId}
				/>
				<TaxiServiceSelect
					ref={setTaxiServiceSelectRef}
					disabled={disabled || !companyId}
					companyId={companyId}
					value={defaultTaxiServiceId}
					onChange={setDefaultTaxiServiceId}
				/>
				<LabeledField label={t(`lastRideCity`) || ""}>
					<StyledPadding>
						{latestTaxiService ?? (t(`no_data`) || "")}
					</StyledPadding>
				</LabeledField>
			</Row>
			<Row gaps="8px*" align="center" sizes="1fr*">
				<CounterpartyConnect
					ref={setCounterpartyConnectRef}
					counterparties={counterparties}
					value={counterpartyConnectValue}
					onChange={assigner}
				/>
				<AgentGroup
					groups={groups}
					value={agentGroupId}
					onChange={setAgentGroupId}
				/>
			</Row>
		</FieldsContainer>
	);
}, Controller);

const Other = memo(OtherBase);

declare namespace Other {
	type Ref = Controller;

	interface PropsBase {
		disabled?: boolean;
		counterparties: CounterpartyConnect.Props["counterparties"];
		groups: AgentGroup.Props["groups"];
		value: Value;
		onChange: (x: Value) => void;
	}
	type Props = PropsBase & RefAttributes<Ref>;

	interface Value extends CounterpartyConnect.Value {
		status: Status.Value;
		companyId?: CompanySelect.Value;
		defaultTaxiServiceId?: number;
		latestTaxiServiceId?: number;
		agentGroupId: AgentGroup.Value;
	}
}

export default Other;
