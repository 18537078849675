import { react } from "uikit";
import { RefObject } from "react";
import Name from "./components/Name";

interface Context {
	nameRef: RefObject<Name.Ref>;
}

export default class Controller extends react.ControllerBase<Context> {
	validate() {
		const isValidName = !!this.context?.nameRef.current?.validate();

		return isValidName;
	}
}
