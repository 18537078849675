import React, { Dispatch, useCallback, useMemo } from "react";
import { Column, Row, Tabs } from "uikit";
import { useTranslation } from "react-i18next";

import tPath from "../../../../constants/tPath";
import { CounterpartiesTabs, getTabs } from "../../constants/tabs";

import Title from "./components/Title";
import Root from "./components/Root";

const Header: React.FC<Header.Props> = ({
	tab,
	title,
	createdAt,
	onChangeTab,
}) => {
	const { t } = useTranslation();

	const tabs = useMemo(() => getTabs(t), [t]);

	const updateTabHandler = useCallback(
		(newTab) => {
			onChangeTab(newTab);
		},
		[onChangeTab],
	);

	return (
		<Root sizes="auto!*">
			<Row
				style={{ padding: "15px 20px" }}
				align="center"
				justify="space-between"
				gaps="10px"
			>
				<Title>{title}</Title>
				<Column align="end" gaps="15px">
					<Row gaps="7px">
						<span>{t(`${tPath}.modal.createdAt`)}:</span>
						<span style={{ color: "black", fontWeight: "600" }}>
							{createdAt
								? new Date(createdAt).toLocaleDateString()
								: new Date().toLocaleDateString()}
						</span>
					</Row>
				</Column>
			</Row>
			<Tabs
				value={tab}
				options={tabs}
				variant="panels"
				onChange={updateTabHandler}
			/>
		</Root>
	);
};

declare namespace Header {
	interface Props {
		tab: CounterpartiesTabs;
		title: string;
		createdAt?: string;
		onChangeTab: Dispatch<CounterpartiesTabs>;
	}
}

export default Header;
