import React, { memo } from "react";

import OrderTab from "../../../../../tabs/OrderTab";
import Order from "../../../../../../../../../../../../services/Order";

const Orders: React.FC<Orders.Props> = memo(
	({ entityId, visible, options }) => (
		<OrderTab
			entityId={entityId}
			visible={visible}
			options={options}
			saveKey="customers.counterparties.tabs.orders"
		/>
	),
);

declare namespace Orders {
	interface Props {
		entityId: number | undefined;
		visible: boolean;
		options: Order.SubscribeOptions;
	}
}

export default Orders;
