import styled from "styled-components";
import { theme } from "uikit";

const Root = styled.div<Root.Props>`
	${({ visible }) => (visible ? "" : "display: none;")}
	width: 100%;
	height: 100%;

	padding: ${({ hasPaddings }) => (hasPaddings ? "24px" : "0")};
	overflow: auto;
	${theme.scrollbar}
`;

declare namespace Root {
	interface Props {
		visible: boolean;
		hasPaddings: boolean;
	}
}

export default Root;
