import React, { Dispatch, RefAttributes, useMemo } from "react";
import { react, useRefWithSetter } from "uikit";
import { isUndefined } from "lodash";

import Language from "../../../../../../../../../../services/Language";
import useObjectEditor from "../../../../../../../../../../hooks/useObjectEditor";
import ExecutorSelectTab from "../../../../../../../../../../components/ExecutorSelectTab";

import InternalController from "./Controller";
import Root from "./components/Root";
import FilesTab from "./components/FilesTab";
import HistoryTab from "./components/HistoryTab";
import MainTab from "./components/MainTab";
import OptionTab from "./components/OptionTab";
import AdditionalDataTab from "./components/AdditionalDataTab";

const Content = react.withController<Content.PropsBase, Content.Controller>(
	({
		controller,

		value,
		type,
		disabled,
		language,
		originalTaxiServiceId,
		originalParkNumber,
		allowedTaxiServiceId,
		currentId,
		history,

		onChange,
	}) => {
		const [mainTabRef, setMainTabRef] =
			useRefWithSetter<MainTab.Ref | null>(null);

		controller.setContext({ mainTabRef });

		const valueEditor = useObjectEditor(value, onChange);

		const mainTabValue = valueEditor.usePicker([
			"carColorId",
			"carBaseTypeId",
			"carBodyTypeId",
			"carBrandId",
			"carModelId",
			"carClassId",
			"parkNumber",
			"registrationNumber",
			"vehicleNumber",
			"manufactureYear",
			"seats",
			"taxiServiceId",
			"carParkId",
			"responsibleDispatcherId",
			"notes",
			"carClass",
			"distributableCarClass",
			"broadcastableCarClass",
			"distributableCarClassIds",
			"broadcastableCarClassIds",
		]);

		const optionTabValue = valueEditor.usePicker([
			"serviceIds",
			"carClass",
			"distributableCarClass",
			"broadcastableCarClass",
			"distributableCarClassIds",
			"broadcastableCarClassIds",
			"taxiServiceId",
		]);

		const additionalDataValue = valueEditor.usePicker([
			"registrationCertificate",
			"registrationCertificateFiles",
			"licenseCard",
			"licenseCardFiles",
			"radioStation",
			"taximeter",
			"insurances",
			"insuranceFiles",
			"wcInsuranceFiles",
		]);

		const executorSelectTabValue = valueEditor.usePicker(["executorIds"]);

		const carTaxiServiceId = valueEditor.useGetter("taxiServiceId");

		const filesTabValue = valueEditor.usePicker([
			"otherFiles",
			"transportFiles",
			"registrationCertificateFiles",
			"insuranceFiles",
			"licenseCardFiles",
		]);

		const taxiServiceId = useMemo(() => {
			if (!isUndefined(allowedTaxiServiceId)) {
				return allowedTaxiServiceId;
			}
			return carTaxiServiceId;
		}, [allowedTaxiServiceId, carTaxiServiceId]);

		const anyTabOnChange = valueEditor.useAssigner();

		const content = useMemo(
			() => (
				<>
					<MainTab
						ref={setMainTabRef}
						value={mainTabValue}
						disabled={disabled}
						visible={type === "main"}
						language={language}
						originalTaxiServiceId={originalTaxiServiceId}
						originalParkNumber={originalParkNumber}
						allowedTaxiServiceId={allowedTaxiServiceId}
						currentId={currentId}
						onChange={anyTabOnChange}
					/>
					<OptionTab
						value={optionTabValue}
						disabled={disabled}
						visible={type === "options"}
						language={language}
						onChange={anyTabOnChange}
					/>
					<AdditionalDataTab
						value={additionalDataValue}
						disabled={disabled}
						visible={type === "additional-data"}
						onChange={anyTabOnChange}
					/>
					<ExecutorSelectTab
						value={executorSelectTabValue}
						disabled={disabled}
						visible={type === "executors"}
						language={language}
						allowedTaxiServiceIds={
							typeof taxiServiceId === "number"
								? [taxiServiceId]
								: []
						}
						onChange={anyTabOnChange}
					/>
					<FilesTab
						value={filesTabValue}
						disabled={disabled}
						visible={type === "files"}
						onChange={anyTabOnChange}
					/>
					<HistoryTab
						value={history}
						visible={type === "history"}
						language={language}
					/>
				</>
			),
			[
				setMainTabRef,
				mainTabValue,
				disabled,
				type,
				language,
				originalTaxiServiceId,
				originalParkNumber,
				currentId,
				anyTabOnChange,
				optionTabValue,
				additionalDataValue,
				executorSelectTabValue,
				taxiServiceId,
				filesTabValue,
				history,
				allowedTaxiServiceId,
			],
		);

		return <Root>{content}</Root>;
	},
	InternalController,
);

declare namespace Content {
	type Ref = InternalController | null;

	type Controller = InternalController;

	type Type =
		| "main"
		| "options"
		| "additional-data"
		| "executors"
		| "files"
		| "history";

	type Value = MainTab.Value &
		OptionTab.Value &
		AdditionalDataTab.Value &
		ExecutorSelectTab.Value &
		FilesTab.Value;

	interface PropsBase {
		value: Value;

		type: Type;
		disabled: boolean;
		language: Language;
		originalTaxiServiceId?: number;
		originalParkNumber?: string;
		allowedTaxiServiceId?: number;
		currentId?: number;
		history: HistoryTab.Value;

		onChange: Dispatch<Value>;
	}

	type Props = PropsBase & RefAttributes<Ref>;
}

export default Content;
