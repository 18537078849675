import React, { Dispatch, RefAttributes, memo } from "react";
import { useTranslation } from "react-i18next";
import { Row, TextBox, react, useRefWithSetter as useRefWithSet } from "uikit";

import FieldsContainer from "../../../../../../../../../../../../../../components/FieldsContainer";
import tPath from "../../../../../../../../constants/tPath";
import useObjectEditor from "../../../../../../../../../../../../../../hooks/useObjectEditor";
import LabeledField from "../../../../../../../../../../../../../../components/LabeledField";
import { IName } from "../../../../../../../../../../../../../../types/IName";

import Controller from "./Controller";
import Name from "./components/Name";

const mainData = `${tPath}.modal.tabs.main.mainData`;
const mainDataTitle = `${mainData}.title`;
const tContactName = `${mainData}.contactName`;
const tContactPhone = `${mainData}.phone`;
const tEmail = `${mainData}.email`;

const MainDataBase = react.withController<MainData.PropsBase, Controller>(
	({ name, setName, value, onChange, controller }) => {
		const { t } = useTranslation();

		const [nameRef, setNameRef] = useRefWithSet<Name.Ref | null>(null);

		const editor = useObjectEditor(value, onChange);

		const contactPersonName = editor.useGetter("contactPersonName");
		const setContactPersonName = editor.useSetter("contactPersonName");

		const contactPersonPhone = editor.useGetter("contactPersonPhone");
		const setContactPersonPhone = editor.useSetter("contactPersonPhone");

		const contactPersonEmail = editor.useGetter("contactPersonEmail");
		const setContactPersonEmail = editor.useSetter("contactPersonEmail");

		controller.setContext({ nameRef });
		return (
			<FieldsContainer label={t(mainDataTitle) || ""}>
				<Row gaps="8px*" sizes="1fr*">
					<Name ref={setNameRef} value={name} onChange={setName} />
					<LabeledField label={t(tContactName) || ""}>
						<TextBox.TextBox
							placeholder={t(tContactName) || ""}
							value={contactPersonName}
							onChange={setContactPersonName}
						/>
					</LabeledField>
					<LabeledField label={t(tContactPhone) || ""}>
						<TextBox.TextBox
							placeholder={t(tContactPhone) || ""}
							value={contactPersonPhone}
							onChange={setContactPersonPhone}
						/>
					</LabeledField>
					<LabeledField label={t(tEmail) || ""}>
						<TextBox.TextBox
							placeholder={t(tEmail) || ""}
							value={contactPersonEmail}
							onChange={setContactPersonEmail}
						/>
					</LabeledField>
				</Row>
			</FieldsContainer>
		);
	},
	Controller,
);
const MainData = memo(MainDataBase);

declare namespace MainData {
	type Ref = Controller | null;

	interface PropsBase {
		name: IName;
		setName: Dispatch<IName>;
		value: Value;
		onChange: Dispatch<Value>;
	}
	type Props = PropsBase & RefAttributes<Ref>;

	interface Value {
		contactPersonName?: string;
		contactPersonPhone?: string;
		contactPersonEmail?: string;
	}
}

export default MainData;
