import React, { Dispatch, RefAttributes, memo, useMemo } from "react";
import { react, useRefWithSetter } from "uikit";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import InternalController from "./Controller";
import LabeledField from "../../../../../../../../../../../../../../components/LabeledField";
import useModelSubscribe from "../../../../../../../../../../../../../../hooks/useModelSubscribe";
import useObjectEditor from "../../../../../../../../../../../../../../hooks/useObjectEditor";
import { useTypedSelector } from "../../../../../../../../../../../../../../redux/store";
import TaxiService from "../../../../../../../../../../../../../../services/TaxiService";
import CompanySelect from "./components/CompanySelect";

import TaxiServiceSelect from "./components/TaxiServiceSelect";
import tPath from "../../../../../../../../constants/tPath";
import CounterpartyGroup from "./components/CounterpartyGroup";

const StyledPadding = styled.div`
	padding-inline: 12px;
	padding-block: 6px;
`;

const OtherBase = react.withController<Other.PropsBase, Other.Controller>(
	({ controller, groups, disabled = false, value, onChange }) => {
		const { t } = useTranslation();
		const lang = useTypedSelector((state) => state.session.language);

		const [companySelectRef, setCompanySelectRef] =
			useRefWithSetter<CompanySelect.Ref | null>(null);
		const [taxiServiceSelectRef, setTaxiServiceSelectRef] =
			useRefWithSetter<TaxiServiceSelect.Ref | null>(null);

		controller.setContext({
			companySelectRef,
			taxiServiceSelectRef,
		});

		const valueEditor = useObjectEditor(value, onChange);

		const companyId = valueEditor.useGetter("companyId");
		const setCompanyId = valueEditor.useSetter("companyId");

		const groupId = valueEditor.useGetter("counterpartyGroupId");
		const setGroupId = valueEditor.useSetter("counterpartyGroupId");

		const defaultTaxiServiceId = valueEditor.useGetter(
			"defaultTaxiServiceId",
		);
		const setDefaultTaxiServiceId = valueEditor.useSetter(
			"defaultTaxiServiceId",
		);

		const taxiServices = useModelSubscribe(
			useMemo(
				() => ({
					companyIds: companyId ? [companyId] : undefined,
				}),
				[companyId],
			),
			TaxiService,
		)?.cache;

		const latestTaxiServiceId = valueEditor.useGetter(
			"latestTaxiServiceId",
		);
		const latestTaxiService = useMemo(
			() =>
				taxiServices?.find((c) => c.id === latestTaxiServiceId)
					?.settlement[lang],
			[lang, latestTaxiServiceId, taxiServices],
		);

		return (
			<>
				<CompanySelect
					ref={setCompanySelectRef}
					disabled={disabled}
					value={companyId}
					onChange={setCompanyId}
				/>
				<TaxiServiceSelect
					ref={setTaxiServiceSelectRef}
					companyId={companyId}
					taxiServices={taxiServices}
					disabled={!companyId}
					value={defaultTaxiServiceId}
					onChange={setDefaultTaxiServiceId}
				/>
				<LabeledField
					label={
						t(`${tPath}.modal.tabs.main.other.lastRideCity`) || ""
					}
				>
					<StyledPadding>
						{latestTaxiService ||
							t(`${tPath}.modal.tabs.main.other.no_data`) ||
							""}
					</StyledPadding>
				</LabeledField>
				<CounterpartyGroup
					groups={groups}
					value={groupId}
					onChange={setGroupId}
				/>
			</>
		);
	},
	InternalController,
);

const Other = memo(OtherBase);

declare namespace Other {
	type Ref = InternalController;
	type Controller = InternalController;

	interface PropsBase {
		groups: CounterpartyGroup.Props["groups"];
		disabled?: boolean;
		value: Value;
		onChange: Dispatch<Value>;
	}
	type Props = PropsBase & RefAttributes<Ref>;
	interface Value {
		companyId: CompanySelect.Value;
		defaultTaxiServiceId: TaxiServiceSelect.Value;
		latestTaxiServiceId?: number;
		counterpartyGroupId: CounterpartyGroup.Value;
	}
}

export default Other;
