import React, { Dispatch, memo, useCallback } from "react";
import { Button, Icon, InputGroup, Row, theme } from "uikit";
import { useTranslation } from "react-i18next";

import SearchIconBorders from "../../../../../../../../components/SearchIconBorders";
import SearchTextBox from "../../../../../../../../components/SearchTextBox";

const Filters = memo<Filters.Props>(({ value, onChange }) => {
	const { t } = useTranslation();

	const searchTextBoxOnChange = useCallback(
		(search: string) => {
			onChange?.(search);
		},
		[onChange],
	);

	return (
		<Row sizes="270px!" gaps="10px*" align="center">
			<InputGroup.InputGroup sizes="auto 1fr">
				<SearchIconBorders>
					<Icon
						id="search2"
						size={16}
						colors={[theme.colors.disabled_text]}
					/>
				</SearchIconBorders>
				<SearchTextBox
					placeholder={`${t([
						`enter_search_query`,
						"Enter your search term...",
					])}`}
					value={value}
					onChange={searchTextBoxOnChange}
				/>
			</InputGroup.InputGroup>

			<Button.Button
				disabled
				variant="secondary"
				icon={<Icon id="filters" size={20} />}
				onClick={() => {}}
			/>
		</Row>
	);
});

declare namespace Filters {
	interface Props {
		value: Value;
		onChange: Dispatch<Value>;
	}

	type Value = string;
}

export default Filters;
