import React, { Dispatch, memo, useCallback, useState } from "react";
import { InputBorders, Row } from "uikit";
import { useTranslation } from "react-i18next";
import CustomInputModal from "./components/CustomInputModal";
import LabeledField from "../../../../../../../../../../../../../../../../components/LabeledField";
import EntityStatus from "../../../../../../../../../../../../../../../../types/EntityStatus";

const Status: React.FC<Status.Props> = memo(({ value, onChange }) => {
	const { t } = useTranslation();
	const [open, setOpen] = useState(false);

	const handleOpen = useCallback(() => {
		setOpen(true);
	}, []);

	const handleClose = useCallback(() => {
		setOpen(false);
	}, []);

	return (
		<LabeledField label={t(`status`) || ""}>
			<Row gaps="5px*" align="center">
				<div
					style={{ flexGrow: 1, cursor: "pointer" }}
					onClick={handleOpen}
				>
					<InputBorders.InputBorders>
						{t(`statuses.${value?.level}`)}
					</InputBorders.InputBorders>
				</div>
			</Row>
			{open && (
				<CustomInputModal
					value={value}
					onSubmit={onChange}
					onClose={handleClose}
				/>
			)}
		</LabeledField>
	);
});

declare namespace Status {
	interface Props {
		value: Value;
		onChange: Dispatch<Value>;
	}
	interface Value {
		level: EntityStatus;
		description: string;
	}
}

export default Status;
