import React from "react";
import { CheckBoxWithContent } from "uikit";
import { useTranslation } from "react-i18next";
import useObjectEditor from "../../../../../../../../../../../../../../../hooks/useObjectEditor";
import Client from "../../../../../../../../../../../../../../../services/Client";

declare namespace MobileApp {
	type Value = Pick<Client.Model, "isAllowedApp">;

	interface Props {
		value: Value;

		onChange: (value: Value) => void;
	}
}

const MobileApp: React.FC<MobileApp.Props> = ({ value, onChange }) => {
	const { t } = useTranslation();

	const valueEditor = useObjectEditor(value, onChange);
	const mobileApp = valueEditor.useGetter("isAllowedApp");
	const setMobileApp = valueEditor.useSetter("isAllowedApp");

	return (
		<CheckBoxWithContent
			gap="10px"
			value={mobileApp}
			onChange={setMobileApp}
		>
			{t(`mainPage.customers.clients.options.access.mobileApp`)}
		</CheckBoxWithContent>
	);
};

export default MobileApp;
