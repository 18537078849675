/* eslint-disable no-case-declarations */
import React, { Dispatch, useCallback, useMemo, useState } from "react";
import { CheckBox, Column, Row } from "uikit";
import { RowDataType } from "rsuite-table";

import Client from "../../../../../../../../../../../../../services/Client";
import LightTable, {
	UseTableOptions,
	useTableOptions,
} from "../../../../../../../../../../../../../components/LightTable";
import TableSettings from "../../../../../../../../../../../../../components/TableSettings";

import { Columns, columns, defaultColumnsId } from "./constants";
import Root from "./components/Root";
import Filters from "./components/Filters";

const Content: React.FC<Content.Props> = ({
	clients,
	selected = [],
	setSelected,
	onChangeTable,
	editorTable,
}) => {
	const {
		ctrlPressed,
		onKeyUp,
		onKeyDown,
		sort,
		onSort,
		onScroll,
		tableRef,
		setQuery,
		query,
	} = useTableOptions({ value: editorTable, setValue: onChangeTable });

	const [columnsId, setColumnsId] = useState(defaultColumnsId);

	const updateSelected = useCallback(
		(client, checkbox = false) => {
			if (ctrlPressed || checkbox) {
				if (selected?.includes?.(client?.id)) {
					setSelected(selected.filter((id) => client?.id !== id));
				} else {
					const arr = Array.isArray(selected) ? selected : [];
					setSelected([...arr, client.id]);
				}
			} else {
				selected.includes(client.id)
					? setSelected([])
					: setSelected([client.id]);
			}
		},
		[setSelected, selected, ctrlPressed],
	);

	const getPhone = useCallback((data: Client.Model) => {
		const phones =
			data?.person?.phones?.map(({ number }) => number)?.join(", ") || "";
		return <>{phones}</>;
	}, []);

	const renderColumns = useMemo(
		() =>
			columnsId.map((columnId) => {
				const column = columns.find((col) => col.id === columnId);

				if (!column) return null;

				return (
					<LightTable.Column
						sortable
						resizable
						key={columnId}
						verticalAlign="middle"
						width={column?.width}
						flexGrow={column?.width ? 0 : 1}
					>
						<LightTable.HeaderCell style={{ padding: "0 25px" }}>
							{column.label}
						</LightTable.HeaderCell>
						<LightTable.Cell
							verticalAlign="middle"
							style={{ padding: "0 25px" }}
							dataKey={column.id}
							fullText
						>
							{(rowData: RowDataType<Client.Model>) => {
								const data = rowData as Client.Model;

								switch (columnId) {
									case Columns.SURNAME:
										return <>{data?.person?.lastName}</>;

									case Columns.NAME:
										return <>{data?.person?.firstName}</>;

									case Columns.FATHER_NAME:
										return <>{data?.person?.fatherName}</>;

									case Columns.PHONES:
										return getPhone(data);

									default:
										return <></>;
								}
							}}
						</LightTable.Cell>
					</LightTable.Column>
				);
			}),
		[columnsId, getPhone],
	);

	const getClassName = useCallback(
		(rowData) =>
			rowData && selected.includes(rowData.id) ? "selected" : "",
		[selected],
	);

	const handleOnChangeSelected = useCallback(
		(isAllSelected) => {
			if (isAllSelected) {
				setSelected(clients.map(({ id }) => id));
			} else {
				setSelected([]);
			}
		},
		[clients, setSelected],
	);

	const data = useMemo(() => clients, [clients]);

	return (
		<Column>
			<Row
				align="center"
				sizes="500px"
				justify="flex-end"
				style={{ padding: 10 }}
			>
				<Filters value={query} onChange={setQuery} />
			</Row>
			<Root sizes="1fr auto!" onKeyDown={onKeyDown} onKeyUp={onKeyUp}>
				<LightTable
					ref={tableRef}
					data={clients}
					virtualized
					fillHeight
					rowHeight={46}
					shouldUpdateScroll={false}
					rowClassName={getClassName}
					onRowClick={updateSelected}
					sortColumn={sort?.dataKey}
					sortType={sort?.sortType}
					onScroll={onScroll}
					onSortColumn={onSort}
				>
					<LightTable.Column verticalAlign="middle" width={50}>
						<LightTable.HeaderCell style={{ padding: "0 25px" }}>
							<CheckBox
								value={
									selected.length === data.length &&
									data.length !== 0
								}
								onChange={handleOnChangeSelected}
							/>
						</LightTable.HeaderCell>
						<LightTable.Cell
							verticalAlign="middle"
							style={{ padding: "0 25px" }}
						>
							{(rowData) => (
								<CheckBox
									value={selected?.includes(rowData.id)}
									onChange={() => {
										updateSelected(rowData, true);
									}}
								/>
							)}
						</LightTable.Cell>
					</LightTable.Column>

					{renderColumns}
				</LightTable>

				<TableSettings
					value={columnsId}
					defaultValue={defaultColumnsId}
					columns={columns}
					onChange={setColumnsId}
				/>
			</Root>
		</Column>
	);
};

declare namespace Content {
	interface Props {
		clients: Client.Model[];
		selected: number[];
		setSelected: Dispatch<number[]>;
		editorTable: UseTableOptions["editor"];
		onChangeTable: UseTableOptions["onChange"];
	}

	type ColumnType = {
		id: string;
		label: string;
		width?: number;
		justify?: "center";
	};
}

export default Content;
