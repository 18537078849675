import Content from "./index";

export enum Columns {
	AUTODETECTION = "autodetection",
	PHONE = "phone",
}

export const columns: Content.ColumnType[] = [
	{ id: Columns.AUTODETECTION, label: "", width: 130 },
	{ id: Columns.PHONE, label: "" },
];

export const defaultColumnIds = columns.map(({ id }) => id);
