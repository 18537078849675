import React, {
	Dispatch,
	RefAttributes,
	memo,
	useCallback,
	useMemo,
} from "react";
import { react, useRefWithSetter } from "uikit";

import useObjectEditor from "../../../../../../../../../../../../../hooks/useObjectEditor";
import { ModelId } from "../../../../../../../../../../../../../types/ModelId";

import Accounts from "../../../../../../Modal/components/Content/tabs/Accounts";
import { EmployeesTabs } from "../../constants/tabs";

import Main from "./tabs/Main";
import Header from "../Header";
import Orders from "./tabs/Orders";
import Transactions from "./tabs/Transactions";
import Files from "./tabs/Files";
import History from "./tabs/History";

import Root from "./components/Root";
import InternalController from "./Controller";

const ContentBase = react.withController<Content.PropsBase, Content.Controller>(
	({
		transactionTableFrom,
		id,
		value,
		onChange,
		tab,
		controller,
		companyId,
		taxiServiceId,
		counterpartyId,
		checks,
	}) => {
		const [mainTabRef, setMainTabRef] = useRefWithSetter<Main.Ref | null>(
			null,
		);
		controller.setContext({ mainTabRef });

		const valueEditor = useObjectEditor(value, onChange);

		const mainTabValue = valueEditor.usePicker([
			"phones",
			"additionalFields",
			"isAccessToCorporateAccount",
			"isAccessToCorporateApp",
			"login",
			"password",
			"customer",
			"isCode",
			"code",
			"isOneTimePassword",
			"oneTimePassword",
		]);

		const files = valueEditor.usePicker(["otherFiles", "personalFiles"]);

		const updateAllData = valueEditor.useAssigner();

		const options = useMemo(
			() => ({ employeeIds: id ? [id] : undefined }),
			[id],
		);

		const getActualTab = useCallback(
			(currentTab: Header.Tab) => {
				switch (currentTab) {
					case EmployeesTabs.MAIN:
						return (
							<Main
								ref={setMainTabRef}
								value={mainTabValue}
								onChange={updateAllData}
								employeeId={id}
								companyId={companyId}
								taxiServiceId={taxiServiceId}
								counterpartyId={counterpartyId}
								checks={checks}
							/>
						);

					case EmployeesTabs.ORDERS:
						return <Orders employeeId={id} options={options} />;

					case EmployeesTabs.TRANSACTIONS:
						return (
							<Transactions
								visible
								tableFrom={transactionTableFrom}
							/>
						);

					case EmployeesTabs.FILES:
						return <Files value={files} onChange={updateAllData} />;

					case EmployeesTabs.HISTORY:
						return <History value={[]} />;

					default:
						return <></>;
				}
			},
			[
				setMainTabRef,
				mainTabValue,
				updateAllData,
				id,
				companyId,
				taxiServiceId,
				counterpartyId,
				checks,
				options,
				transactionTableFrom,
				files,
			],
		);

		return <Root>{getActualTab(tab)}</Root>;
	},
	InternalController,
);
const Content = memo(ContentBase);

declare namespace Content {
	type Ref = InternalController | null;
	type Controller = InternalController;

	interface PropsBase {
		transactionTableFrom: string;
		id?: ModelId;
		value: Value;
		onChange: Dispatch<Value>;
		tab: Header.Tab;
		companyId?: number;
		taxiServiceId?: number;
		counterpartyId?: number;
		checks?: Accounts.Check[];
	}
	type Props = PropsBase & RefAttributes<Ref>;

	interface Value extends Main.Value, Files.Value {}
}

export default Content;
