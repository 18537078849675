import React, { Dispatch, useCallback } from "react";
import { Icon, theme } from "uikit";
import Root from "./components/Root";

const ShowPasswordButton: React.FC<ShowPasswordButton.Props> = ({
	value,

	disabled,

	onChange,
}) => {
	const rootOnClick = useCallback(() => {
		if (disabled) return;

		onChange(!value);
	}, [disabled, onChange, value]);

	return (
		<Root disabled={disabled} onClick={rootOnClick}>
			<Icon
				id="shown-in-mobile"
				size={16}
				colors={[
					value ? theme.colors.primary : theme.colors.disabled_text,
				]}
			/>
		</Root>
	);
};

declare namespace ShowPasswordButton {
	type Value = boolean;

	interface Props {
		value: Value;

		disabled: boolean;

		onChange: Dispatch<Value>;
	}
}

export default ShowPasswordButton;
