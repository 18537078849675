import React from "react";

import { RegisteredAtCellContent as Content } from "../components";

import base from "./base";

import { Column, ColumnId } from ".";

function registeredAt({ width, onResize }: Column.Options) {
	return base(ColumnId.RegisteredAt, (item) => <Content item={item} />, {
		width,
		onResize: onResize as (
			columnWidth?: number | undefined,
			dataKey?: string | undefined,
		) => void,
	});
}

export default registeredAt;
