import React, { Dispatch, memo, useMemo } from "react";
import { Option } from "uikit";
import { useTranslation } from "react-i18next";

import ClientGroupService from "../../../../../../../../../../../../../../../../../services/ClientGroup";
import LabeledField from "../../../../../../../../../../../../../../../../../components/LabeledField";
import SelectWithModal from "../../../../../../../../../../../../../../../../../components/SelectWithModal";
import Client from "../../../../../../../../../../../../../../../../../services/Client";

const path = `mainPage.customers.clients.main.additionalData.clientGroup`;

const ClientGroup: React.FC<ClientGroup.Props> = memo((props) => {
	const { groups, value, onChange } = props;

	const { t } = useTranslation();

	const options = useMemo<Option<number>[]>(
		() =>
			groups.map((group) => ({
				key: group.id,
				value: group.id,
				label: group.name,
			})),
		[groups],
	);

	return (
		<LabeledField label={t(`${path}.title`) || ""}>
			<SelectWithModal
				disabled={!groups.length}
				title={t(`${path}.selectGroup`) || ""}
				options={options}
				value={value}
				onChange={onChange as any}
			/>
		</LabeledField>
	);
});

declare namespace ClientGroup {
	interface Props {
		groups: ClientGroupService.Model[];
		value: Value;
		onChange: Dispatch<Value>;
	}

	type Value = Client.Model["customerGroupId"];
}

export default ClientGroup;
