import React, { memo } from "react";
import { useTranslation } from "react-i18next";
import { MultilineTextBox } from "uikit";
import useObjectEditor from "../../../../../../../../../../../../../../../hooks/useObjectEditor";
import { StyledRow } from "../../../../../../../../../../../../../../../components/common";

const Notes: React.FC<Notes.Props> = memo(({ value, onChange }) => {
	const { t } = useTranslation();
	const valueEditor = useObjectEditor(value, onChange);

	const executorNotes = valueEditor.useGetter("executorNotes");
	const setExecutorNotes = valueEditor.useSetter("executorNotes");
	const orderNotes = valueEditor.useGetter("orderNotes");
	const setOrderNotes = valueEditor.useSetter("orderNotes");
	const notes = valueEditor.useGetter("notes");
	const setNotes = valueEditor.useSetter("notes");

	return (
		<StyledRow w="100%" gap="10px" justify="space-between">
			<MultilineTextBox
				style={{ flexGrow: 1 }}
				value={executorNotes ?? ""}
				onChange={setExecutorNotes}
				placeholder={
					t(
						"pages.mainPage.pages.customers.tabs.clients.editModal.content.tabs.mainTab.notes.str0",
					) ?? ""
				}
			/>
			<MultilineTextBox
				style={{ flexGrow: 1 }}
				value={orderNotes ?? ""}
				onChange={setOrderNotes}
				placeholder={
					t(
						"pages.mainPage.pages.customers.tabs.clients.editModal.content.tabs.mainTab.notes.str0",
					) ?? ""
				}
			/>
			<MultilineTextBox
				style={{ flexGrow: 1 }}
				value={notes ?? ""}
				onChange={setNotes}
				placeholder={
					t(
						"pages.mainPage.pages.customers.tabs.clients.editModal.content.tabs.mainTab.notes.str0",
					) ?? ""
				}
			/>
		</StyledRow>
	);
});

declare namespace Notes {
	interface Props {
		value: Value;
		onChange: (value: Value) => void;
	}

	interface Value {
		orderNotes?: string;
		notes?: string;
		executorNotes?: string;
	}
}

export default Notes;
