import styled from "styled-components";
import { Column, theme } from "uikit";

const Root = styled(Column)`
	background-color: white;
	border: 1px solid ${theme.colors.color_border_basic};
	border-top-right-radius: ${theme.borderRadius.m};
`;

export default Root;
