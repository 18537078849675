import React, { memo, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";

import Transaction from "../../../../../../services/Transaction";
import {
	TransactionMetadataAction,
	TransactionMetadataContext,
} from "../../../../../../services/Transaction/types";
import { Language } from "../../../../../../services";
import { StyledP, StyledRow } from "../../../../../common";

const Comment: React.FC<Comment.Props> = memo(
	({ operation, metadata, lang }) => {
		const { t } = useTranslation();
		const translationPath = "transactionComment";

		const formatAction = useCallback(
			(action?: string) => {
				if (action === TransactionMetadataAction.CHARGE) {
					return t([
						`${translationPath}.${TransactionMetadataAction.CHARGE}`,
						"Removal",
					]);
				}
				if (action === TransactionMetadataAction.REFUND) {
					return t([
						`${translationPath}.${TransactionMetadataAction.CHARGE}`,
						"Return",
					]);
				}
				if (action === TransactionMetadataAction.RECALCULATE) {
					return t([
						`${translationPath}.${TransactionMetadataAction.RECALCULATE}`,
						"Recalculation",
					]);
				}
				return "";
			},
			[t],
		);

		const formatContext = useCallback(
			(context?: string) => {
				if (context === TransactionMetadataContext.SUBSCRIPTION_FEE) {
					return t([
						`${translationPath}.${TransactionMetadataContext.SUBSCRIPTION_FEE}`,
						"subscription fee",
					]);
				}

				if (context === TransactionMetadataContext.COMMISSION_FEE) {
					return t([
						`${translationPath}.${TransactionMetadataContext.COMMISSION_FEE}`,
						"commissions",
					]);
				}

				if (
					context ===
					TransactionMetadataContext.DISCOUNTED_COMMISSION_FEE
				) {
					return t([
						`${translationPath}.${TransactionMetadataContext.DISCOUNTED_COMMISSION_FEE}`,
						"preferential commission",
					]);
				}

				return "";
			},
			[t],
		);

		const additionalComment = useMemo(() => {
			const name =
				operation?.metadata?.entity?.name?.[lang] ||
				metadata?.entity?.name?.[lang] ||
				"";
			const action = formatAction(
				operation?.metadata?.action || metadata?.action,
			);

			const context = formatContext(
				operation?.metadata?.context || metadata?.context,
			);

			const period = t([`${translationPath}.period`, "for the period"]);
			const from = t([`${translationPath}.from`, "from"]);
			const to = t([`${translationPath}.to`, "to"]);

			const timeFrom =
				operation?.metadata?.entity?.period?.from ||
				metadata?.entity?.period?.from;

			const formatTimeFrom = timeFrom
				? new Date(timeFrom)?.toLocaleDateString?.()
				: "";

			const timeTo =
				operation?.metadata?.entity?.period?.to ||
				metadata?.entity?.period?.to;

			const formatTimeTo = timeTo
				? new Date(timeTo)?.toLocaleDateString?.()
				: "";

			return `${name ? action : ""}${name ? context : ""}${name || ""} ${
				name && timeFrom && timeTo ? period : ""
			}${name && timeFrom && timeTo ? from : ""} ${
				name && timeFrom && timeTo ? formatTimeFrom : ""
			}${name && timeFrom && timeTo ? to : ""}${
				name && timeFrom && timeTo ? formatTimeTo : ""
			}`.trim();
		}, [
			operation?.metadata?.entity?.name,
			operation?.metadata?.entity?.period?.from,
			operation?.metadata?.entity?.period?.to,
			operation?.metadata?.action,
			operation?.metadata?.context,
			lang,
			metadata?.entity?.name,
			metadata?.entity?.period?.from,
			metadata?.entity?.period?.to,
			metadata?.action,
			metadata?.context,
			formatAction,
			formatContext,
			t,
		]);

		return (
			<StyledRow alignItems="center" gap="8px" w="100%">
				<StyledP>
					{metadata.comment
						? t([
								`transactionTypeComment.${metadata.comment}`,
								`transactionTypeComment.${metadata.context}`,
						  ])
						: metadata.comment || ""}
				</StyledP>
				{additionalComment && <StyledP>{additionalComment}</StyledP>}
			</StyledRow>
		);
	},
);

declare namespace Comment {
	interface Props {
		operation: Transaction.Model["operation"];
		metadata: Transaction.Model["metadata"];
		lang: Language;
	}
}

export default Comment;
