/* eslint-disable no-param-reassign */
/* eslint-disable no-shadow */

import React, { useMemo, useState } from "react";
import { Column } from "uikit";
import { isUndefined } from "lodash";
import ModelTable from "./components/ModelTable";
import TabRoot from "../TabRoot";
import Language from "../../../../../../../../../../../../services/Language";
import useModelSubscribe from "../../../../../../../../../../../../hooks/useModelSubscribe2";
import ExecutorFeedback from "../../../../../../../../../../../../services/ExecutorFeedback";

const tableColumnToSortColumn = {
	createdAt: "createdAt",
	rating: "rating",
	comment: "comment",
	customer: "customerId",
	orderNumber: "orderId",
};

const FeedbackTab: React.FC<FeedbackTab.Props> = ({
	executorId,
	language,
	visible,
}) => {
	const [sort, setSort] = useState<ModelTable.Sort>({});

	const subscriptionOptions =
		useMemo<ExecutorFeedback.SubscribeOptions>(() => {
			const options: ExecutorFeedback.SubscribeOptions = {};

			options.executorId = executorId;

			if (sort.type && sort.column) {
				options.order = {
					[tableColumnToSortColumn[sort.column]]: sort.type,
				};
			}

			return options;
		}, [executorId, sort.column, sort.type]);

	const feedbackData = useModelSubscribe(
		subscriptionOptions,
		ExecutorFeedback,
	);

	return (
		<TabRoot hasPaddings={false} visible={visible}>
			<Column sizes="1fr" maxedWidth maxedHeight>
				<ModelTable
					loading={false}
					data={isUndefined(executorId) ? [] : feedbackData.models}
					sort={sort}
					language={language}
					onChangeSort={setSort}
				/>
			</Column>
		</TabRoot>
	);
};

declare namespace FeedbackTab {
	interface Props {
		executorId?: number;
		language: Language;
		visible: boolean;
	}
}

export default FeedbackTab;
