import React, { Dispatch, memo, useCallback, useMemo, useState } from "react";
import { Column, Icon } from "uikit";
import { useTranslation } from "react-i18next";

import Client from "../../../../../../../../../../../../services/Client";
import DefaultPageHeader from "../../../../../../../../../../../../components/DefaultPageHeader";
import DefaultPageHeaderButton from "../../../../../../../../../../../../components/DefaultPageHeaderButton";
import {
	useTableOptions,
	UseTableOptions,
} from "../../../../../../../../../../../../components/LightTable";
import {
	Popup,
	StyledP,
} from "../../../../../../../../../../../../components/common";

import Table from "./components/Table";
import Modal from "./components/Modal";

const Main: React.FC<Main.Props> = memo((props) => {
	const { clients, clientById, value, onChange, editorTable, onChangeTable } =
		props;

	const { t } = useTranslation();

	const { setQuery } = useTableOptions({
		value: editorTable,
		setValue: onChangeTable,
	});

	const [showModal, setShowModal] = useState(false);

	const selectedClients = useMemo(
		() => clients.filter((client) => value.includes(client.id)),
		[clients, value],
	);

	const [selected, setSelected] = useState<number[]>([]);

	const editHandler = useCallback(() => {
		setShowModal(true);
		setSelected([]);
	}, []);

	const unpinHandler = useCallback(async () => {
		onChange(
			selectedClients
				.filter((client) => !selected.includes(client.id))
				.map(({ id }) => id),
		);
		setSelected([]);
	}, [onChange, selected, selectedClients]);

	const cancelHandler = useCallback(() => {
		setShowModal(false);
		setQuery("");
	}, [setQuery]);

	const saveHandler = useCallback(
		(newIds: number[]) => {
			const payload = [...value, ...newIds];
			onChange(payload);
			setShowModal(false);
			setQuery("");
		},
		[onChange, setQuery, value],
	);

	const dataClients = useMemo(
		() => [...clients].filter((item) => !value.includes(item.id)),
		[clients, value],
	);

	return (
		<Column sizes="auto 1fr" gaps="8px*">
			<DefaultPageHeader
				buttons={
					<>
						<Popup
							useHoverControl
							trackerId={"client_table_add_button_id"}
							offset={{ x: 32, y: 33 }}
							tracker={
								<DefaultPageHeaderButton
									id="client_table_add_button_id"
									variant={"primary"}
									icon={<Icon id="pencil" size={16} />}
									onClick={editHandler}
								/>
							}
						>
							<StyledP
								br="4px"
								bgC="#000000"
								colors="#ffffff"
								p="5px 10px"
							>
								{t("table.buttons.add")}
							</StyledP>
						</Popup>
						<Popup
							useHoverControl
							trackerId={"client_table_delete_button_id"}
							offset={{ x: 32, y: 33 }}
							tracker={
								<DefaultPageHeaderButton
									id="client_table_delete_button_id"
									disabled={!selected.length}
									variant={"secondary"}
									icon={<Icon id="trash" size={20} />}
									onClick={unpinHandler}
								/>
							}
						>
							<StyledP
								br="4px"
								bgC="#000000"
								colors="#ffffff"
								p="5px 10px"
							>
								{t("table.buttons.delete")}
							</StyledP>
						</Popup>
					</>
				}
			/>
			<Table
				value={selectedClients}
				selected={selected}
				setSelected={setSelected}
				editorTable={editorTable}
				onChangeTable={onChangeTable}
				defaultColumns={[
					"lastName",
					"firstName",
					"fatherName",
					"phones",
					"company",
					"defaultTaxiService",
				]}
			/>
			{showModal && (
				<Modal
					clients={dataClients}
					clientById={clientById}
					value={value}
					onCancel={cancelHandler}
					onSave={saveHandler}
					editorTable={editorTable}
					onChangeTable={onChangeTable}
				/>
			)}
		</Column>
	);
});

declare namespace Main {
	interface Props {
		clients: Client.Model[];
		clientById: Modal.Props["clientById"];
		value: Value;
		onChange: Dispatch<Value>;
		editorTable: UseTableOptions["editor"];
		onChangeTable: UseTableOptions["onChange"];
	}

	type Value = Modal.Value;
}

export default Main;
