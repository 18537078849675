/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable no-param-reassign */
/* eslint-disable no-shadow */

import React, { Dispatch, RefAttributes, memo, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Column, react } from "uikit";

import File from "../../../../../../../../../../../../services/File";
import useObjectEditor from "../../../../../../../../../../../../hooks/useObjectEditor";

import FileListWithTitle from "../../../../../../../../../../../../components/FileListWithTitle";
import InternalController from "./Controller";
import TabRoot from "../TabRoot";

const FilesTab = memo(
	react.withController<FilesTab.PropsBase, FilesTab.Controller>(
		({ value, disabled, visible, onChange }) => {
			const { t } = useTranslation();
			const valueEditor = useObjectEditor(value, onChange);

			const otherFilesValue = valueEditor.useGetter("otherFiles");

			const otherFilesOnChange = useCallback(
				(otherFiles: File.Model[]) => {
					otherFiles.forEach((file) => file.upload());

					valueEditor.set("otherFiles", otherFiles);
				},
				[valueEditor],
			);

			const transportFilesValue = valueEditor.useGetter("transportFiles");

			const transportFilesOnChange = useCallback(
				(transportFiles: File.Model[]) => {
					transportFiles.forEach((file) => file.upload());

					valueEditor.set("transportFiles", transportFiles);
				},
				[valueEditor],
			);

			const registrationCertificateFilesValue = valueEditor.useGetter(
				"registrationCertificateFiles",
			);

			const registrationCertificateFilesOnChange = useCallback(
				(registrationCertificateFiles: File.Model[]) => {
					registrationCertificateFiles.forEach((file) =>
						file.upload(),
					);

					valueEditor.set(
						"registrationCertificateFiles",
						registrationCertificateFiles,
					);
				},
				[valueEditor],
			);

			const insuranceFilesValue = valueEditor.useGetter("insuranceFiles");

			const insuranceFilesOnChange = useCallback(
				(insuranceFiles: File.Model[]) => {
					insuranceFiles.forEach((file) => file.upload());

					valueEditor.set("insuranceFiles", insuranceFiles);
				},
				[valueEditor],
			);

			const licenseCardFilesValue =
				valueEditor.useGetter("licenseCardFiles");

			const licenseCardFilesOnChange = useCallback(
				(licenseCardFiles: File.Model[]) => {
					licenseCardFiles.forEach((file) => file.upload());

					valueEditor.set("licenseCardFiles", licenseCardFiles);
				},
				[valueEditor],
			);

			return (
				<TabRoot hasPaddings visible={visible}>
					<Column
						gaps="24px*"
						sizes="250px!* 0.015625px!"
						maxedWidth
						maxedHeight
					>
						<FileListWithTitle
							value={otherFilesValue}
							disabled={disabled}
							title={
								t(
									"pages.mainPage.pages.accounts.tabs.car.editModal.content.filesTab.str0",
								) ?? ""
							}
							onChange={otherFilesOnChange}
						/>
						<FileListWithTitle
							value={transportFilesValue}
							disabled={disabled}
							title={
								t(
									"pages.mainPage.pages.accounts.tabs.car.editModal.content.filesTab.str1",
								) ?? ""
							}
							onChange={transportFilesOnChange}
						/>
						<FileListWithTitle
							value={registrationCertificateFilesValue}
							disabled={disabled}
							title={
								t(
									"pages.mainPage.pages.accounts.tabs.car.editModal.content.filesTab.str2",
								) ?? ""
							}
							onChange={registrationCertificateFilesOnChange}
						/>
						<FileListWithTitle
							value={insuranceFilesValue}
							disabled={disabled}
							title={
								t(
									"pages.mainPage.pages.accounts.tabs.car.editModal.content.filesTab.str3",
								) ?? ""
							}
							onChange={insuranceFilesOnChange}
						/>
						<FileListWithTitle
							value={licenseCardFilesValue}
							disabled={disabled}
							title={
								t(
									"pages.mainPage.pages.accounts.tabs.car.editModal.content.filesTab.str4",
								) ?? ""
							}
							onChange={licenseCardFilesOnChange}
						/>
						<div />
					</Column>
				</TabRoot>
			);
		},
		InternalController,
	),
);

declare namespace FilesTab {
	type Ref = InternalController | null;

	type Controller = InternalController;

	interface Value {
		otherFiles: File.Model[];
		transportFiles: File.Model[];
		registrationCertificateFiles: File.Model[];
		insuranceFiles: File.Model[];
		licenseCardFiles: File.Model[];
	}

	interface PropsBase {
		value: Value;

		disabled: boolean;
		visible: boolean;

		onChange: Dispatch<Value>;
	}

	type Props = PropsBase & RefAttributes<Ref>;
}

export default FilesTab;
