import { Option } from "uikit";
import { TFunction } from "i18next";

import tPath from "../../../constants/tPath";

// eslint-disable-next-line no-shadow
export enum CounterpartiesTabs {
	MAIN = "main",
	// ACCOUNTS = "accounts",
	EMPLOYEES = "employees",
	ORDERS = "orders",
	TRANSACTIONS = "transactions",
	FILES = "files",
	HISTORY = "history",
}

export const getTabs = (t: TFunction): Option<string>[] => [
	{
		key: CounterpartiesTabs.MAIN,
		value: CounterpartiesTabs.MAIN,
		label: t(`${tPath}.tabs.main`) ?? "",
	},
	// {
	// 	key: CounterpartiesTabs.ACCOUNTS,
	// 	value: CounterpartiesTabs.ACCOUNTS,
	// 	label: t(`${tPath}.tabs.accounts`) ?? "",
	// },
	{
		key: CounterpartiesTabs.EMPLOYEES,
		value: CounterpartiesTabs.EMPLOYEES,
		label: t(`${tPath}.tabs.employees`) ?? "",
	},
	{
		key: CounterpartiesTabs.ORDERS,
		value: CounterpartiesTabs.ORDERS,
		label: t(`${tPath}.tabs.orders`) ?? "",
	},
	{
		key: CounterpartiesTabs.TRANSACTIONS,
		value: CounterpartiesTabs.TRANSACTIONS,
		label: t(`${tPath}.tabs.transactions`) ?? "",
	},
	{
		key: CounterpartiesTabs.FILES,
		value: CounterpartiesTabs.FILES,
		label: t(`${tPath}.tabs.files`) ?? "",
	},
	{
		key: CounterpartiesTabs.HISTORY,
		value: CounterpartiesTabs.HISTORY,
		label: t(`${tPath}.tabs.history`) ?? "",
	},
]; // tabs
