import React, { Dispatch, useCallback, useMemo, useState } from "react";
import { cloneDeep, compact } from "lodash";
import { useTranslation } from "react-i18next";

import Reward from "../../../../../../../../../../../../../../services/Reward";
import DefaultPageHeader from "../../../../../../../../../../../../../../components/DefaultPageHeader";
import DeleteModal from "../../../../../../../../../../../../../../components/DeleteModal";
import { StyledGrid } from "../../../../../../../../../../../../../../components/common";

import defaultValue from "./constants/defaultValue";
import Content from "./components/Content";
import Modal from "./components/Modal";

const Main: React.FC<Main.Props> = ({
	agentToRewards,
	setAgentToRewards,
	agentId,
}) => {
	const { t } = useTranslation();

	const [actualDefaultValue] = useState(defaultValue);
	const [showModal, setShowModal] = useState<boolean>(false);
	const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
	const [selected, setSelected] = useState<number[]>([]);

	const [editingItem, setEditingItem] =
		useState<Modal.Value>(actualDefaultValue);

	const dataTable = useMemo(
		() => compact([...agentToRewards].map((item) => item.reward)),
		[agentToRewards],
	);

	const updateReward = useCallback(
		async (
			model: Reward.Model | Modal.Value,
			ownerId?: number | null | undefined,
		) => {
			const { id } = model;
			if (id) {
				const payload: Reward.Modified = {
					...model,
					id,
					agentId: ownerId,
				};
				await Reward.update(payload);
			}
		},
		[],
	);

	const edit = useCallback(
		(id: number) => {
			const item = dataTable.find((item) => item.id === id);

			if (item) {
				setEditingItem(cloneDeep(item));
				setShowModal(true);
				setSelected([]);
			}
		},
		[dataTable],
	);

	const editHeaderHandler = useCallback(() => {
		edit(selected[0]);
	}, [edit, selected]);

	const editContentHandler = useCallback(
		(id: number) => {
			edit(id);
		},
		[edit],
	);

	const addHandler = useCallback(() => {
		setEditingItem(actualDefaultValue);
		setShowModal(true);
	}, [actualDefaultValue]);

	const preDeleteHandler = useCallback(() => {
		setShowDeleteModal(true);
	}, []);

	const cancelDelete = useCallback(() => {
		setShowDeleteModal(false);
		setSelected([]);
	}, []);

	const deleteHandler = useCallback(async () => {
		if (!selected.length) return;
		const newRewards = [...agentToRewards].filter((item) => {
			if (!item?.reward?.id) return true;
			return !selected.includes(item?.reward?.id);
		});

		const isActive = newRewards.find(
			(item) => item.reward?.active === true,
		);
		const isDefault = newRewards.find(
			(item) => item.reward?.default === true,
		);

		if (!isActive || !isDefault) {
			if (!isActive && !isDefault && newRewards.length) {
				const firstItem = newRewards[0];
				if (firstItem) {
					const cloneItem = cloneDeep(firstItem);
					cloneItem.reward.active = true;
					cloneItem.reward.default = true;
					const { id } = cloneItem.reward;
					if (id) {
						updateReward(cloneItem.reward, agentId);

						const modifyArr = [...newRewards].filter(
							(item) => item.reward?.id !== id,
						);

						setAgentToRewards([cloneItem, ...modifyArr]);
					}
				}
			}

			if (isActive && !isDefault) {
				const payload = [...newRewards].map((item) => {
					if (
						item?.reward?.id === isActive?.reward?.id &&
						!isDefault
					) {
						const cloneItem = cloneDeep(item);
						cloneItem.reward.default = true;
						updateReward(cloneItem.reward, agentId);
						return cloneItem;
					}
					return item;
				});

				setAgentToRewards(payload);
			}
		} else {
			setAgentToRewards(newRewards);
		}
		await Reward.delete(selected);
		setSelected([]);
		setShowDeleteModal(false);
	}, [agentId, agentToRewards, selected, setAgentToRewards, updateReward]);

	const cancelHandler = useCallback(() => {
		setShowModal(false);
		setEditingItem(actualDefaultValue);
	}, [actualDefaultValue]);

	const saveHandler = useCallback(
		async (model: Reward.Model) => {
			const payload = [...agentToRewards];
			const isActive = payload.find(
				(item) =>
					item.reward?.active === true &&
					item.reward?.id !== model?.id,
			);
			const isDefault = payload.find(
				(item) =>
					item.reward?.default === true &&
					item.reward?.id !== model?.id,
			);

			const exist = payload.find((item) => item.reward?.id === model?.id);
			const cloneModel = cloneDeep(model);

			if (!isActive || payload.length === 0) {
				cloneModel.active = true;
				await updateReward(cloneModel, agentId);
			}
			if (!isDefault && !cloneModel.default) {
				cloneModel.active = true;
				cloneModel.default = true;
				await updateReward(cloneModel, agentId);
			}

			if (exist) {
				const newModels = payload.map((item) => {
					const cloneItem = cloneDeep(item);

					if (
						cloneModel.default &&
						cloneItem?.reward?.default &&
						isDefault
					) {
						cloneItem.reward.default = false;
						updateReward(cloneItem.reward, agentId);
					}

					if (
						isActive?.reward.id === item.reward.id &&
						!isDefault &&
						payload.length === 0
					) {
						cloneItem.reward.default = true;
						updateReward(cloneItem.reward, agentId);
					}

					if (cloneItem.reward?.id === cloneModel.id) {
						return { ...cloneItem, reward: cloneModel };
					}

					return cloneItem;
				});
				setAgentToRewards(newModels);
			} else if (cloneModel.default) {
				const modifyPayload = payload.map((item) => {
					const payload = {
						...item,
						reward: { ...(item.reward || {}), default: false },
					};
					updateReward(payload.reward, agentId);

					return payload;
				});

				setAgentToRewards([...modifyPayload, { reward: cloneModel }]);
			} else {
				setAgentToRewards([...payload, { reward: cloneModel }]);
			}
		},
		[agentId, agentToRewards, setAgentToRewards, updateReward],
	);

	return (
		<StyledGrid
			areas=""
			rows="auto 1fr"
			br="5px"
			over="hidden"
			w="100%"
			h="100%"
		>
			<DefaultPageHeader
				canEdit={selected.length === 1}
				canDelete={!!selected.length}
				onAdd={addHandler}
				onDelete={preDeleteHandler}
				onEdit={editHeaderHandler}
			/>
			<Content
				value={agentToRewards}
				selected={selected}
				setSelected={setSelected}
				onEdit={editContentHandler}
			/>

			{showModal && (
				<Modal
					agentId={agentId}
					value={editingItem}
					onCancel={cancelHandler}
					onSave={saveHandler}
					headerTitle={
						t(
							"pages.mainPage.pages.customers.tabs.agents.modal.content.tabs.reward.main.str200",
						) ?? ""
					}
				/>
			)}
			{showDeleteModal && (
				<DeleteModal
					label={
						t(
							"pages.mainPage.pages.customers.tabs.agents.modal.content.tabs.reward.main.str201",
						) ?? ""
					}
					onCancel={cancelDelete}
					onConfirm={deleteHandler}
				/>
			)}
		</StyledGrid>
	);
};

declare namespace Main {
	interface Props {
		agentId?: number;
		agentToRewards: Content.Value[];
		setAgentToRewards: Dispatch<Content.Value[]>;
	}

	type Reward = Modal.Value;
}

export default Main;
