import React, { Dispatch, memo, useCallback, useMemo } from "react";
import { Column, Row, Tabs } from "uikit";
import { useTranslation } from "react-i18next";

import { AccountsTabs, tabs } from "../../constants/tabs";
import tPath from "../../../../constants/tPath";

import Root from "./components/Root";
import Title from "./components/Title";
import InformationContainer from "./components/InformationContainer";

const Header = memo<Header.Props>(({ tab, title, createdAt, onChangeTab }) => {
	const { t } = useTranslation();
	const tTabs = useMemo(
		() =>
			tabs.map((internalTab) => ({
				...internalTab,
				label: t(`${tPath}.modal.tabs.${internalTab.key}.title`),
			})),
		[t],
	);

	const updateTabHandler = useCallback(
		(newTab) => {
			onChangeTab(newTab);
		},
		[onChangeTab],
	);

	return (
		<Root sizes="auto!*">
			<InformationContainer
				align="center"
				justify="space-between"
				gaps="10px"
			>
				<Title>{title}</Title>
				<Column align="end" gaps="15px">
					<Row gaps="7px">
						<span>{t(`createdAt`)}:</span>
						<span style={{ color: "black", fontWeight: "600" }}>
							{createdAt
								? new Date(createdAt).toLocaleDateString()
								: new Date().toLocaleDateString()}
						</span>
					</Row>
				</Column>
			</InformationContainer>
			<Tabs
				value={tab}
				options={tTabs}
				variant="panels"
				onChange={updateTabHandler}
			/>
		</Root>
	);
});

declare namespace Header {
	type Tab = AccountsTabs;
	interface Props {
		tab: Tab;
		title: string;
		createdAt?: string;
		onChangeTab: Dispatch<AccountsTabs>;
	}
}

export default Header;
