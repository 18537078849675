import React, { RefAttributes, memo } from "react";
import { useTranslation } from "react-i18next";
import { CheckBox, Column, Row, TextBox, react, useRefWithSetter } from "uikit";

import useObjectEditor from "../../../../../../../../../../../../../../hooks/useObjectEditor";
import FieldsContainer from "../../../../../../../../../../../../../../components/FieldsContainer";
import LabeledField from "../../../../../../../../../../../../../../components/LabeledField";

import Code from "./components/Code";
import Controller from "./Controller";

const AccessBase = react.withController<Access.PropsBase, Controller>(
	({ value, onChange, controller }) => {
		const { t } = useTranslation();
		const [codeRef, setCodeRef] = useRefWithSetter<Code.Ref | null>(null);

		const editor = useObjectEditor(value, onChange);

		const isAccessToApp = editor.useGetter("isAccessToApp");
		const setIsAccessToApp = editor.useSetter("isAccessToApp");
		const isAccessToAccount = editor.useGetter("isAccessToAccount");
		const setIsAccessToAccount = editor.useSetter("isAccessToAccount");
		const login = editor.useGetter("login");
		const setLogin = editor.useSetter("login");
		const password = editor.useGetter("password");
		const setPassword = editor.useSetter("password");
		const code = editor.useGetter("code");
		const setCode = editor.useSetter("code");

		controller.setContext({ codeRef });

		return (
			<FieldsContainer
				label={
					t(
						"pages.mainPage.pages.customers.tabs.agents.modal.content.tabs.main.access.str0",
					) ?? ""
				}
			>
				<Row gaps="16px*" sizes="1fr 1fr">
					<Column gaps="8px*">
						<CheckBox
							label={
								t(
									"pages.mainPage.pages.customers.tabs.agents.modal.content.tabs.main.access.str1",
								) ?? ""
							}
							value={isAccessToApp}
							onChange={setIsAccessToApp}
						/>
						<CheckBox
							label={
								t(
									"pages.mainPage.pages.customers.tabs.agents.modal.content.tabs.main.access.str2",
								) ?? ""
							}
							value={isAccessToAccount}
							onChange={setIsAccessToAccount}
						/>
						<Row gaps="8px*" sizes="1fr 1fr">
							<LabeledField
								label={
									t(
										"pages.mainPage.pages.customers.tabs.agents.modal.content.tabs.main.access.str3",
									) ?? ""
								}
							>
								<TextBox.TextBox
									placeholder={
										t(
											"pages.mainPage.pages.customers.tabs.agents.modal.content.tabs.main.access.str3",
										) ?? ""
									}
									value={login ?? ""}
									onChange={setLogin}
								/>
							</LabeledField>
							<LabeledField
								label={
									t(
										"pages.mainPage.pages.customers.tabs.agents.modal.content.tabs.main.access.str5",
									) ?? ""
								}
							>
								<TextBox.TextBox
									placeholder={
										t(
											"pages.mainPage.pages.customers.tabs.agents.modal.content.tabs.main.access.str5",
										) ?? ""
									}
									value={password ?? ""}
									onChange={setPassword}
								/>
							</LabeledField>
						</Row>
					</Column>
					<Code ref={setCodeRef} value={code} onChange={setCode} />
				</Row>
			</FieldsContainer>
		);
	},
	Controller,
);

const Access = memo(AccessBase);

declare namespace Access {
	type Ref = Controller | null;

	interface Value {
		isAccessToApp: boolean;
		isAccessToAccount: boolean;
		login: string | null;
		password: string | null;
		code: Code.Value;
	}

	interface PropsBase {
		value: Value;
		onChange: (x: Value) => void;
	}
	type Props = PropsBase & RefAttributes<Ref>;
}

export default Access;
