import { Dispatch } from "react";
import { react } from "uikit";
import CarBaseTypeId from ".";

interface Context {
	value: CarBaseTypeId.Value;

	setError: Dispatch<boolean>;
}

export default class Controller extends react.ControllerBase<Context> {
	validate() {
		const isValueValid = typeof this.context?.value !== "undefined";

		if (!isValueValid) this.context?.setError(true);

		return isValueValid;
	}
}
