import React, { Dispatch, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Icon, InputGroup, TextBox, theme } from "uikit";

import File from "../../../../../../../../../../../../../../services/File";
import useObjectEditor from "../../../../../../../../../../../../../../hooks/useObjectEditor";
import LabeledField from "../../../../../../../../../../../../../../components/LabeledField";
import FileListModal from "../../../../../../../../../../../../../../components/FileListModal";

const RegistrationCertificate: React.FC<RegistrationCertificate.Props> = ({
	value,

	disabled,

	onChange,
}) => {
	const { t } = useTranslation();
	const valueEditor = useObjectEditor(value, onChange);

	const registrationCertificateValue = valueEditor.useGetter(
		"registrationCertificate",
	);
	const registrationCertificateOnChange = valueEditor.useSetter(
		"registrationCertificate",
	);

	const registrationCertificateFilesValue = valueEditor.useGetter(
		"registrationCertificateFiles",
	);
	const registrationCertificateFilesOnChange = valueEditor.useSetter(
		"registrationCertificateFiles",
	);

	const [showFileModal, setShowFileModal] = useState(false);

	const fileButtonOnClick = useCallback(() => {
		setShowFileModal(true);
	}, []);

	const fileListModalOnClose = useCallback(() => {
		setShowFileModal(false);
	}, []);

	const fileListModalOnSubmit = useCallback(
		(files: File.Model[]) => {
			registrationCertificateFilesOnChange(files);
			setShowFileModal(false);
		},
		[registrationCertificateFilesOnChange],
	);

	return (
		<>
			<LabeledField
				label={
					t(
						"pages.mainPage.pages.accounts.tabs.car.editModal.content.additionalDataTab.registrationCertificate.str0",
					) ?? ""
				}
			>
				<InputGroup.InputGroup disabled={disabled} sizes="1fr 32px!">
					<TextBox.TextBox
						value={registrationCertificateValue}
						disabled={disabled}
						placeholder={
							t(
								"pages.mainPage.pages.accounts.tabs.car.editModal.content.additionalDataTab.registrationCertificate.str0",
							) ?? ""
						}
						onChange={registrationCertificateOnChange}
					/>
					<Button.Button
						style={{
							borderTopLeftRadius: 0,
							borderBottomLeftRadius: 0,
						}}
						disabled={disabled}
						icon={
							<Icon
								id="copy"
								size={16}
								colors={[
									disabled
										? theme.colors.secondary
										: theme.colors.white,
								]}
							/>
						}
						onClick={fileButtonOnClick}
					/>
				</InputGroup.InputGroup>
			</LabeledField>
			{showFileModal ? (
				<FileListModal
					value={registrationCertificateFilesValue}
					title={
						t(
							"pages.mainPage.pages.accounts.tabs.car.editModal.content.additionalDataTab.registrationCertificate.str0",
						) ?? ""
					}
					onSubmit={fileListModalOnSubmit}
					onClose={fileListModalOnClose}
				/>
			) : undefined}
		</>
	);
};

declare namespace RegistrationCertificate {
	interface Value {
		registrationCertificate: string;
		registrationCertificateFiles: File.Model[];
	}

	interface Props {
		value: Value;

		disabled: boolean;

		onChange: Dispatch<Value>;
	}
}

export default RegistrationCertificate;
