/* eslint-disable import/no-unresolved */
/* eslint-disable no-shadow */
import React, {
	RefAttributes,
	useCallback,
	useEffect,
	useMemo,
	useState,
} from "react";
import { react, useRefWithSetter } from "uikit";
import { isUndefined } from "lodash";
import { useTranslation } from "react-i18next";

import Card from "../../../../../../../../services/Card";
import Executor from "../../../../../../../../services/Executor";
import ExecutorFeedback from "../../../../../../../../services/ExecutorFeedback";
import Map from "../../../../../../../../redux/services/Map";
import WithoutNullableKeys from "../../../../../../../../types/WithoutNullableKeys";
import useObjectEditor from "../../../../../../../../hooks/useObjectEditor";
import EditModalBase from "../../../../../../../../components/EditModal";

import InternalController from "./Controller";
import { ExecutorProvider } from "./context";
import Root from "./components/Root";
import Header from "./components/Header";
import Content from "./components/Content";
import Footer from "./components/Footer";

const EditModal = react.withController<
	EditModal.PropsBase,
	EditModal.Controller
>(({ controller, value, language, onCancel, onSave }) => {
	const { t } = useTranslation();
	const [contentRef, setContentRef] = useRefWithSetter<Content.Ref | null>(
		null,
	);

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const originalTaxiServiceId = useMemo(() => value.taxiServiceId, []);

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const originalAlias = useMemo(() => value.alias, []);

	// const cardIds = useMemo(
	// 	() => (typeof value.id === "number" ? [value.id] : []),
	// 	[value.id],
	// );

	// useKeepCardsOpen(cardIds, Car.Card);

	// const cardSession = useCardSubscriber(Car.Card);

	const currentCard =
		null as Card.Session.Card | null; /* useMemo<Card.Session.Card | null>(
		() =>
			cardSession?.cards.find((card) => cardIds.includes(card.id)) ??
			null,
		[cardIds, cardSession?.cards],
	); */

	const currentCardLocked = useMemo(
		() => currentCard?.locked === true,
		[currentCard?.locked],
	);

	const [history, setHistory] = useState<Executor.History>([]);

	useEffect(() => {
		(async () => {
			if (value.id) setHistory(await Executor.getHistory(value.id));
		})();
	}, [value.id]);

	controller.setContext({ contentRef });

	const [tab, setTab] = useState<Header.Tab>("main");
	const [rating, setRating] = useState<number | undefined>(undefined);
	const [internalValue, setInternalValue] = useState(value);

	useEffect(() => {
		(async () => {
			if (isUndefined(value.id)) return;

			const rating = await ExecutorFeedback.getRating(value.id);

			setRating(rating);
		})();
	}, [value.id]);

	const valueEditor = useObjectEditor(internalValue, setInternalValue);

	const setPaymentAccounts = valueEditor.useSetter("paymentAccounts");
	const executorRateIdOnChange = valueEditor.useSetter("executorRateId");

	useEffect(() => {
		executorRateIdOnChange(value.executorRateId);
	}, [executorRateIdOnChange, value.executorRateId]);

	useEffect(() => {
		setPaymentAccounts(value.paymentAccounts);
	}, [setPaymentAccounts, value.paymentAccounts]);

	const fullName = useMemo(() => {
		const name = internalValue.name ?? "";
		const alias = internalValue.alias ?? "";

		return `${name}  ${alias ? `(${alias})` : ""}`;
	}, [internalValue.alias, internalValue.name]);

	const headerTitle = useMemo(
		() =>
			typeof value.id === "number"
				? fullName
				: t(
						"pages.mainPage.pages.accounts.tabs.executors.editModal.str200",
				  ),
		[fullName, value.id, t],
	);

	const headerLockedBy = useMemo(
		() =>
			currentCard?.locked
				? [
						currentCard.lockedBy.person?.lastName ?? "",
						currentCard.lockedBy.person?.firstName ?? "",
						currentCard.lockedBy.person?.fatherName ?? "",
				  ]
						.join(" ")
						.trim()
				: undefined,
		[currentCard?.locked, currentCard?.lockedBy],
	);

	const assignValue = valueEditor.useAssigner();

	const modalContent = useMemo(
		() => (
			<Root sizes="auto! 1fr">
				<Header
					tab={tab}
					title={headerTitle}
					lockedBy={headerLockedBy}
					createdAt={value.createdAt}
					rating={rating}
					onChangeTab={setTab}
				/>
				<Content
					ref={setContentRef}
					executorId={value.id}
					value={internalValue}
					type={tab}
					disabled={currentCardLocked}
					language={language}
					originalTaxiServiceId={originalTaxiServiceId}
					originalAlias={originalAlias}
					history={history}
					onChange={assignValue}
				/>
			</Root>
		),
		[
			assignValue,
			currentCardLocked,
			headerLockedBy,
			headerTitle,
			history,
			internalValue,
			language,
			originalAlias,
			originalTaxiServiceId,
			rating,
			setContentRef,
			tab,
			value.createdAt,
			value.id,
		],
	);

	const footerValue = valueEditor.usePicker(["active"]);

	const modalFooter = useMemo(
		() => (
			<Footer
				value={footerValue}
				disabled={currentCard?.locked === true}
				onChange={assignValue}
			/>
		),
		[footerValue, currentCard?.locked, assignValue],
	);

	const modalOnSave = useCallback(async () => {
		if (!(await contentRef.current?.validate())) return;

		onSave(valueEditor.value as EditModal.Value.Validated);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [contentRef, onSave]);

	const taxiServiceId = useMemo(
		() =>
			internalValue.taxiServiceId ? [internalValue.taxiServiceId] : [],
		[internalValue.taxiServiceId],
	);

	return (
		<EditModalBase
			footer={modalFooter}
			canSave={!currentCardLocked}
			onCancel={onCancel}
			onSave={modalOnSave}
		>
			<ExecutorProvider taxiServiceIds={taxiServiceId}>
				{modalContent}
			</ExecutorProvider>
		</EditModalBase>
	);
}, InternalController);

declare namespace EditModal {
	type Ref = InternalController | null;

	type Controller = InternalController;

	interface Value extends Footer.Value, Content.Value {
		id?: number;

		name?: string;

		createdAt?: string;
	}

	namespace Value {
		type ValidationPropertyNames =
			| "password"
			| "taxiServiceId"
			| "responsibleDispatcherId";

		type Validated = Omit<Value, ValidationPropertyNames> &
			WithoutNullableKeys<Required<Pick<Value, ValidationPropertyNames>>>;
	}

	interface PropsBase {
		value: Value;
		language: Map.Language;

		onCancel: () => void;
		onSave: (value: Value.Validated) => void;
	}

	type Props = PropsBase & RefAttributes<Ref>;
}

export default EditModal;
