import React from "react";

import { CreatedAtCellContent as Content } from "../components";

import base from "./base";

import { Column, ColumnId } from ".";

function createdAt({ width, onResize }: Column.Options) {
	return base(ColumnId.CreatedAt, (item) => <Content item={item} />, {
		width,

		onResize: onResize as (
			columnWidth?: number | undefined,
			dataKey?: string | undefined,
		) => void,
	});
}

export default createdAt;
