import React, { Dispatch, memo, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Column, Row, Tabs } from "uikit";
import tPath from "../../../../constants/tPath";
import Root from "./components/Root";
import Title from "./components/Title";
import { ModalTabs, tabs } from "../../constants/tabs";

const Header: React.FC<Header.Props> = memo(
	({ tab, title, createdAt, setTab }) => {
		const { t } = useTranslation();

		const tTabs = useMemo(
			() =>
				tabs.map((internalTab) => ({
					...internalTab,
					label: t(`${tPath}.modal.tabs.${internalTab.key}.title`),
				})),
			[t],
		);

		return (
			<Root sizes="auto!*">
				<Row
					style={{ padding: "16px 20px" }}
					align="center"
					justify="space-between"
					gaps="10px"
				>
					<Title>{title}</Title>
					<Column align="end" gaps="15px">
						<Row gaps="7px">
							<span>{t(`createdAt`)}:</span>
							<span style={{ color: "black", fontWeight: "600" }}>
								{createdAt
									? new Date(createdAt).toLocaleDateString()
									: new Date().toLocaleDateString()}
							</span>
						</Row>
					</Column>
				</Row>
				<Tabs
					value={tab}
					options={tTabs}
					variant="panels"
					onChange={setTab as any}
				/>
			</Root>
		);
	},
);

declare namespace Header {
	interface Props {
		tab: ModalTabs;
		title: string;
		createdAt?: string;
		setTab: Dispatch<ModalTabs>;
	}
}

export default Header;
