import { Option } from "uikit";

// eslint-disable-next-line no-shadow
export enum AccountsTabs {
	MAIN = "main",
	EMPLOYEES = "employees",
	ORDERS = "orders",
	TRANSACTIONS = "transactions",
	FILES = "files",
	HISTORY = "history",
}

export const tabs: Option<string>[] = [
	{
		key: AccountsTabs.MAIN,
		value: AccountsTabs.MAIN,
		label: "",
	},
	{
		key: AccountsTabs.EMPLOYEES,
		value: AccountsTabs.EMPLOYEES,
		label: "",
	},
	{
		key: AccountsTabs.ORDERS,
		value: AccountsTabs.ORDERS,
		label: "",
	},
	{
		key: AccountsTabs.TRANSACTIONS,
		value: AccountsTabs.TRANSACTIONS,
		label: "",
	},
	{
		key: AccountsTabs.FILES,
		value: AccountsTabs.FILES,
		label: "",
	},
	{
		key: AccountsTabs.HISTORY,
		value: AccountsTabs.HISTORY,
		label: "",
	},
];
