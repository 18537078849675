import React, { Dispatch, RefAttributes, useMemo } from "react";
import { react, useRefWithSetter } from "uikit";

import useObjectEditor from "../../../../../../../../../../hooks/useObjectEditor";
import { ModelId } from "../../../../../../../../../../types/ModelId";
import { PaymentAccount } from "../../../../../../../../../../types/PaymentAccount";
import { CounterpartiesTabs as Tabs } from "../../constants/tabs";

import Main from "./tabs/Main";
import Orders from "./tabs/Orders";
import History from "./tabs/History";
import Employees from "./tabs/Employees";
import Files from "./tabs/Files";
import Transactions from "./tabs/Transactions";
import Accounts from "./components/Accounts";
import Root from "./components/Root";
import InternalController from "./Controller";

const Content = react.withController<Content.PropsBase, Content.Controller>(
	({ controller, groups, id, value, tab, onChange }) => {
		const [mainTabRef, setMainTabRef] = useRefWithSetter<Main.Ref | null>(
			null,
		);
		controller.setContext({ mainTabRef });

		const valueEditor = useObjectEditor(value, onChange);

		const mainTabValue = valueEditor.usePicker([
			"additionalFields",
			"accessAllEmployeeToPersonalApp",
			"addCustomerAsEmployeeAfterOrder",
			"isAccessToCorporateAccount",
			"corporateLogin",
			"corporatePassword",
			"latestTaxiServiceId",
			"companyId",
			"defaultTaxiServiceId",
			"counterpartyGroupId",
			"paymentAccounts",
			"paymentTransactions",
			"allowGeneralBonusBalance",
			"ignoreGeneralThresholdBalance",
		]);

		const checks = valueEditor.useGetter("checks");
		const setChecks = valueEditor.useSetter("checks");

		const allowGeneralBonusBalance = valueEditor.useGetter(
			"allowGeneralBonusBalance",
		);
		const ignoreGeneralThresholdBalance = valueEditor.useGetter(
			"ignoreGeneralThresholdBalance",
		);

		const additionalFieldsToChecks = valueEditor.get("additionalFields");

		const files = valueEditor.usePicker(["otherFiles", "personalFiles"]);

		const assigner = valueEditor.useAssigner();

		const options = useMemo(
			() => ({ counterpartyIds: id ? [id] : undefined }),
			[id],
		);

		const paymentAccounts = useMemo(() => {
			const payload: PaymentAccount[] = [];
			if (mainTabValue.paymentAccounts.length) {
				payload.push(...mainTabValue.paymentAccounts);
			}
			if (checks) {
				checks.forEach((check) => {
					if (check.paymentAccounts.length) {
						payload.push(...check.paymentAccounts);
					}
				});
			}
			return payload;
		}, [checks, mainTabValue.paymentAccounts]);

		const content = useMemo(
			() => (
				<>
					<Main
						visible={tab === Tabs.MAIN}
						ref={setMainTabRef}
						groups={groups}
						counterpartyId={id}
						value={mainTabValue}
						onChange={assigner}
					>
						<Accounts
							visible={tab === Tabs.MAIN}
							companyId={mainTabValue.companyId}
							taxiServiceId={mainTabValue.defaultTaxiServiceId}
							counterpartyId={id}
							generalPaymentAccounts={value.paymentAccounts}
							generalPaymentTransactions={
								value.paymentTransactions
							}
							checks={checks}
							setChecks={setChecks}
							additional={additionalFieldsToChecks}
							ignoreGeneralThresholdBalance={
								ignoreGeneralThresholdBalance
							}
							allowGeneralBonusBalance={allowGeneralBonusBalance}
						/>
					</Main>
					<Employees
						visible={tab === Tabs.EMPLOYEES}
						checks={checks}
						onChange={setChecks}
						companyId={mainTabValue.companyId}
						taxiServiceId={mainTabValue.defaultTaxiServiceId}
						counterpartyId={id}
					/>
					<Orders
						entityId={id}
						visible={tab === Tabs.ORDERS}
						options={options}
					/>
					<Transactions
						visible={tab === Tabs.TRANSACTIONS}
						paymentAccounts={paymentAccounts}
					/>
					<Files
						visible={tab === Tabs.FILES}
						value={files}
						onChange={assigner}
					/>
					<History visible={tab === Tabs.HISTORY} value={[]} />
				</>
			),
			[
				tab,
				setMainTabRef,
				groups,
				id,
				mainTabValue,
				assigner,
				value.paymentAccounts,
				value.paymentTransactions,
				checks,
				setChecks,
				additionalFieldsToChecks,
				ignoreGeneralThresholdBalance,
				allowGeneralBonusBalance,
				options,
				paymentAccounts,
				files,
			],
		);

		return <Root>{content}</Root>;
	},
	InternalController,
);

declare namespace Content {
	type Ref = InternalController | null;
	type Controller = InternalController;

	interface PropsBase {
		id?: ModelId;
		groups: Main.Props["groups"];
		value: Value;
		onChange: Dispatch<Value>;
		tab: Tabs;
	}
	type Props = PropsBase & RefAttributes<Ref>;

	interface Value extends Main.Value, Files.Value {
		checks: Accounts.Check[];
		rideCounter?: {
			total: number;
			failed?: number;
			success?: number;
			cancelled?: number;
		};
	}
}

export default Content;
