import React, { useMemo } from "react";

import Order from "../../../../../../../../../../../../services/Order";
import { useGetCompanies } from "../../../../../../../../../../../../hooks/index";

import TabRoot from "../../components/TabRoot";
import OrderTab from "../OrderTab";

const Orders: React.FC<Orders.Props> = ({
	agentId,
	visible,
	hasPaddings,
	defaultTaxiServiceId,
}): JSX.Element => {
	useGetCompanies();

	const subscribeOptions = useMemo<Order.SubscribeOptions>(
		() => ({
			taxiServiceIds: defaultTaxiServiceId ? [defaultTaxiServiceId] : [],
			agentIds: agentId ? [agentId] : undefined,
		}),
		[agentId, defaultTaxiServiceId],
	);

	return (
		<TabRoot visible={visible} hasPaddings={hasPaddings}>
			<OrderTab
				entityId={agentId}
				visible={!!visible}
				options={subscribeOptions}
				saveKey="customers.agents.tabs.orders"
			/>
		</TabRoot>
	);
};

declare namespace Orders {
	interface Props extends TabRoot.Props {
		agentId: number | undefined;
		defaultTaxiServiceId: number | undefined;
	}
}

export default Orders;
