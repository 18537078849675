import React, {
	Dispatch,
	RefAttributes,
	memo,
	useCallback,
	useMemo,
} from "react";
import { useTranslation } from "react-i18next";
import { react, useRefWithSetter } from "uikit";

import useObjectEditor from "../../../../../../../../../../../../../../../../hooks/useObjectEditor";
import mapByKey from "../../../../../../../../../../../../../../../../utils/mapByKey";
import LabeledField from "../../../../../../../../../../../../../../../../components/LabeledField";

import CounterpartySelect from "./components/CounterpartySelect";
import CheckSelect from "./components/CheckSelect";
import BalanceSelect from "./components/BalanceSelect";
import Controller from "./Controller";

const CounterpartyConnectBase = react.withController<
	CounterpartyConnect.PropsBase,
	Controller
>(({ counterparties, value, onChange, controller }) => {
	const { t } = useTranslation();
	const [balanceSelectRef, setBalanceSelectRef] =
		useRefWithSetter<BalanceSelect.Ref>(null);

	const editor = useObjectEditor(value, onChange);

	const counterpartyId = editor.useGetter("counterpartyId");
	const setCounterpartyId = editor.useSetter("counterpartyId");

	const checkId = editor.useGetter("checkId");
	const setCheckId = editor.useSetter("checkId");

	const balanceId = editor.useGetter("balanceId");
	const setBalanceId = editor.useSetter("balanceId");

	const handelCounterpartyId = useCallback(
		(value: CounterpartySelect.Value) => {
			setCounterpartyId(value);
			setCheckId(null);
			setBalanceId(null);
		},
		[setBalanceId, setCheckId, setCounterpartyId],
	);

	const handelCheckId = useCallback(
		(value: CheckSelect.Value) => {
			setCheckId(value);
			setBalanceId(null);
		},
		[setBalanceId, setCheckId],
	);

	const counterpartyById = useMemo(
		() => mapByKey(counterparties, "id"),
		[counterparties],
	);

	const counterparty = useMemo(() => {
		if (!counterpartyId) return null;
		return counterpartyById[counterpartyId];
	}, [counterpartyById, counterpartyId]);

	const checks = useMemo(() => {
		if (!counterpartyId) return [];
		return counterpartyById[counterpartyId]?.checks;
	}, [counterpartyId, counterpartyById]);

	const checkById = useMemo(() => {
		if (!checks) return null;
		return mapByKey(checks, "id");
	}, [checks]);

	const check = useMemo(() => {
		if (!checkId) return null;
		return checkById?.[checkId] || null;
	}, [checkById, checkId]);

	controller.setContext({ balanceSelectRef });
	return (
		<>
			<LabeledField
				label={
					t(
						"pages.mainPage.pages.customers.tabs.agents.modal.content.tabs.main.other.counterpartyConnect.str0",
					) ?? ""
				}
			>
				<CounterpartySelect
					counterparties={counterparties}
					value={counterpartyId}
					onChange={handelCounterpartyId}
				/>
			</LabeledField>
			<LabeledField
				label={
					t(
						"pages.mainPage.pages.customers.tabs.agents.modal.content.tabs.main.other.counterpartyConnect.str1",
					) ?? ""
				}
			>
				<CheckSelect
					counterparty={counterparty}
					checks={checks}
					checkId={checkId}
					setCheckId={handelCheckId}
				/>
			</LabeledField>
			<LabeledField
				label={
					t(
						"pages.mainPage.pages.customers.tabs.agents.modal.content.tabs.main.other.counterpartyConnect.str2",
					) ?? ""
				}
			>
				<BalanceSelect
					ref={setBalanceSelectRef}
					check={check}
					value={balanceId}
					onChange={setBalanceId}
				/>
			</LabeledField>
		</>
	);
}, Controller);

const CounterpartyConnect = memo(CounterpartyConnectBase);

declare namespace CounterpartyConnect {
	type Ref = Controller | null;

	interface PropsBase {
		counterparties: CounterpartySelect.Props["counterparties"];
		value: Value;
		onChange: Dispatch<Value>;
	}
	type Props = PropsBase & RefAttributes<Ref>;

	interface Value {
		counterpartyId: CounterpartySelect.Value;
		checkId: CheckSelect.Props["checkId"];
		balanceId: BalanceSelect.Props["value"];
	}
}

export default CounterpartyConnect;
