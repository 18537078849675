import { react } from "uikit";
import { RefObject } from "react";
import SearchSettlement from "./components/SearchSettlement";
import SearchStreet from "./components/SearchStreet";
import SearchHouse from "./components/SearchHouse";

interface Context {
	searchSettlementRef: RefObject<SearchSettlement.Ref>;
	searchStreetRef: RefObject<SearchStreet.Ref>;
	searchHouseRef: RefObject<SearchHouse.Ref>;
}

export default class Controller extends react.ControllerBase<Context> {
	validate() {
		const isValidSearchSettlement =
			!!this.context?.searchSettlementRef.current?.validate();
		const isValidSearchStreet =
			!!this.context?.searchStreetRef.current?.validate();
		const isValidSearchHouse =
			!!this.context?.searchHouseRef.current?.validate();

		return (
			isValidSearchSettlement && isValidSearchStreet && isValidSearchHouse
		);
	}
}
