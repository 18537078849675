import React, { memo, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Column, Option, Select, theme } from "uikit";
import styled from "styled-components";
import Message from "../../../../../../../../../../../../services/Message";
import Agent from "../../../../../../../../../../../../services/Agent";
import SMSProvider from "../../../../../../../../../../../../services/SMSProvider";
import { SmsProviderType } from "../../../../../../../../../../../../services/SMSProvider/types";
import useObjectEditor from "../../../../../../../../../../../../hooks/useObjectEditor";
import LabeledField from "../../../../../../../../../../../../components/LabeledField";
import EditModal from "../../../../../../../../../../../../components/EditModal";

import { ModelId } from "../../../../../../../../../../../../types/ModelId";

import Phones from "./components/Phones";
import Template from "./components/Template";
import defaultValue from "./defaultValue";

const Root = styled(Column)`
	padding: 25px;
	min-width: 550px;
	min-height: 400px;
`;

const Header = styled(Column)`
	padding-left: 20px;
	height: 50px;
	background: ${theme.colors.button_secondary};
	justify-content: center;

	font-size: 20px;
	line-height: 1;
	font-weight: 700;
	color: ${theme.colors.primary};
`;

const Modal: React.FC<Modal.Props> = memo((props) => {
	const { agents, providers, templates, onClose, onSubmit } = props;
	const { t } = useTranslation();
	const [value, setValue] = useState<Modal.Value>(defaultValue);

	const editor = useObjectEditor(value, setValue);

	const providerId = editor.useGetter("providerId");
	const setProviderId = editor.useSetter("providerId");

	const contacts = editor.useGetter("contacts");
	const setContacts = editor.useSetter("contacts");

	const sendData = editor.useGetter("data");
	const setSendData = editor.useSetter("data");
	const [sendDataError, setSendDataError] = useState({
		templateId: false,
		text: false,
	});

	const [provOptions, setProvOptions] = useState<Option<number>[]>([]);

	useEffect(() => {
		const newOptions: Option<number>[] = providers.map((p) => ({
			key: p.id,
			value: p.id,
			label: p.name,
		}));
		setProvOptions(newOptions);

		const mainProvider = providers.find(
			(p) => p.type === SmsProviderType.MAIN,
		);
		if (mainProvider) {
			setProviderId(mainProvider.id);
		}
	}, [providers, setProviderId]);

	const handleSubmit = useCallback(() => {
		const { smsTemplateId, text } = sendData;
		if (smsTemplateId == null) {
			setSendDataError({ ...sendDataError, templateId: true });
			return;
		}
		if (!smsTemplateId && !text) {
			setSendDataError({ ...sendDataError, text: true });
			return;
		}

		const data: Template.Value = {} as Template.Value;
		if (smsTemplateId) {
			data.smsTemplateId = smsTemplateId;
		} else {
			data.text = text;
		}

		const submitData: Message.Send = {
			contacts: contacts.map((contact) => ({
				...contact,
				smsProviderId: providerId as number,
			})),
			data,
		};

		onSubmit(submitData);
	}, [contacts, onSubmit, providerId, sendData, sendDataError]);

	return (
		<EditModal
			onCancel={onClose}
			submitText={
				t(
					"pages.mainPage.pages.customers.tabs.agents.headerAdditional.sendingSMS.modal.str0",
				) ?? ""
			}
			footerJustify="center"
			onSave={handleSubmit}
		>
			<Header>
				{t(
					"pages.mainPage.pages.customers.tabs.agents.headerAdditional.sendingSMS.modal.str100",
				) ?? ""}
			</Header>
			<Root maxedHeight maxedWidth gaps="20px*">
				<LabeledField
					label={`${
						t(
							"pages.mainPage.pages.customers.tabs.agents.headerAdditional.sendingSMS.modal.str200",
						) ?? ""
					} SMS`}
				>
					<Select
						placeholder={`${
							t(
								"pages.mainPage.pages.customers.tabs.agents.headerAdditional.sendingSMS.modal.str201",
							) ?? ""
						} SMS ${
							t(
								"pages.mainPage.pages.customers.tabs.agents.headerAdditional.sendingSMS.modal.str202",
							) ?? ""
						}`}
						options={provOptions}
						value={providerId ?? undefined}
						onChange={setProviderId as any}
					/>
				</LabeledField>
				<Phones
					agents={agents}
					value={contacts}
					onChange={setContacts}
				/>
				<Template
					error={sendDataError}
					setError={setSendDataError}
					templates={templates}
					value={sendData}
					onChange={setSendData}
				/>
			</Root>
		</EditModal>
	);
});

declare namespace Modal {
	interface Props {
		agents: Agent.Model[];
		providers: SMSProvider.Model[];
		templates: Template.Props["templates"];
		onClose: () => void;
		onSubmit: (x: Message.Send) => void;
	}

	interface Value {
		providerId?: ModelId;
		contacts: Phones.Value[];
		data: Template.Value;
	}
}

export default Modal;
