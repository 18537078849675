import { react } from "uikit";
import { Dispatch } from "react";
import Phones from ".";

interface Context {
	value: Phones.Value;

	setError: Dispatch<boolean>;
}

export default class Controller extends react.ControllerBase<Context> {
	validate() {
		const isValueValid = !!this.context?.value[0];

		if (!isValueValid) this.context?.setError(true);

		return isValueValid;
	}
}
