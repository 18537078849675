import React, { memo } from "react";

import OrderTab from "../../../../../../../../../../../../tabs/OrderTab";
import Order from "../../../../../../../../../../../../../../../../../../../services/Order";

const Orders: React.FC<Orders.Props> = memo(({ cardId, visible, value }) => (
	<OrderTab
		entityId={cardId}
		visible={visible}
		options={value}
		saveKey="customers.counterparties.tabs.accounts.tabs.cards.tabs.orders"
	/>
));

declare namespace Orders {
	interface Props {
		cardId: number | undefined;
		visible: boolean;
		value: Order.SubscribeOptions;
	}
}

export default Orders;
