import React, {
	RefAttributes,
	memo,
	useCallback,
	useEffect,
	useMemo,
	useState,
} from "react";
import { CheckBox, Row, react, useRefWithSetter } from "uikit";
import { useTranslation } from "react-i18next";

import useObjectEditor from "../../../../../../../../../../../hooks/useObjectEditor";
import { ModelId } from "../../../../../../../../../../../types/ModelId";
import { NonEditableProperties } from "../../../../../../../../../../../types/NonEditableProperties";
import EditModal from "../../../../../../../../../../../components/EditModal";

import Accounts from "../../../../Modal/components/Content/tabs/Accounts";
import { EmployeesTabs } from "./constants/tabs";
import Content from "./components/Content";
import Root from "./components/Root";
import Header from "./components/Header";
import tPath from "../../constants/tPath";

import InternalController from "./Controller";

const ModalBase = react.withController<Modal.PropsBase, Modal.Controller>(
	({
		transactionTableFrom,
		value,
		onSave,
		onCancel,
		controller,
		companyId,
		taxiServiceId,
		counterpartyId,
		checks,
	}) => {
		const [contentRef, setContentRef] =
			useRefWithSetter<Content.Ref | null>(null);
		controller.setContext({ contentRef });

		const { t } = useTranslation();

		const [tab, setTab] = useState<Header.Tab>(EmployeesTabs.MAIN);

		const [internalValue, setInternalValue] = useState(value);

		const handleSubmit = useCallback(() => {
			if (!contentRef.current?.validate()) return;

			onSave(internalValue);
		}, [contentRef, internalValue, onSave]);

		const valueEditor = useObjectEditor(internalValue, setInternalValue);

		const createdAt = valueEditor.get("createdAt");

		const active = valueEditor.useGetter("active");
		const setActive = valueEditor.useSetter("active");

		const defaultTaxiServiceId = valueEditor.useGetter(
			"defaultTaxiServiceId",
		);
		const setDefaultTaxiServiceId = valueEditor.useSetter(
			"defaultTaxiServiceId",
		);

		useEffect(() => {
			if (taxiServiceId && defaultTaxiServiceId !== taxiServiceId) {
				setDefaultTaxiServiceId(taxiServiceId);
			}
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [taxiServiceId]);

		const contentValues = valueEditor.usePicker([
			"phones",
			"additionalFields",
			"isAccessToCorporateApp",
			"isAccessToCorporateAccount",
			"login",
			"password",
			"customer",
			"otherFiles",
			"personalFiles",
			"isCode",
			"code",
			"isOneTimePassword",
			"oneTimePassword",
		]);

		const contentOnChange = valueEditor.useAssigner();

		return (
			<EditModal
				onCancel={onCancel}
				onSave={handleSubmit}
				footer={
					<Row align="center">
						<Row gaps="30px*">
							<CheckBox
								label={t(`${tPath}.modal.active`) || ""}
								value={active}
								onChange={setActive}
							/>
						</Row>
					</Row>
				}
			>
				<Root sizes="auto! 1fr">
					<Header
						title={
							value.customer?.name
								? `${t(`${tPath}.modal.editTitle`)} "${
										value.customer.name
								  }"`
								: t(`${tPath}.modal.title`)
						}
						createdAt={createdAt}
						tab={tab}
						onChangeTab={setTab}
					/>
					<Content
						transactionTableFrom={transactionTableFrom}
						ref={setContentRef}
						id={typeof value.id === "number" ? value.id : undefined}
						tab={tab}
						value={contentValues}
						onChange={contentOnChange}
						companyId={companyId}
						taxiServiceId={taxiServiceId}
						counterpartyId={counterpartyId}
						checks={checks}
					/>
				</Root>
			</EditModal>
		);
	},
	InternalController,
);
const Modal = memo(ModalBase);

declare namespace Modal {
	type Ref = InternalController | null;
	type Controller = InternalController;

	interface PropsBase {
		transactionTableFrom: string;
		value: Value;
		onSave: (employee: Value) => void;
		onCancel: () => void;
		companyId?: number;
		taxiServiceId?: number;
		counterpartyId?: number;
		checks?: Accounts.Check[];
	}
	type Props = PropsBase & RefAttributes<Ref>;

	interface Value
		extends Content.Value,
			Omit<Partial<NonEditableProperties>, "id"> {
		active: boolean;
		id?: ModelId | string;
		defaultTaxiServiceId?: number;
	}
}

export default Modal;
