import React, { memo } from "react";
import { useTranslation } from "react-i18next";
import Transaction from "../../../../../../services/Transaction";

const PaymentAccountType: React.FC<Type.Props> = memo(({ value }) => {
	const { t } = useTranslation();

	const { type } = value;
	return <div>{type ? t([`paymentAccount.${type}`, ""]) : ""}</div>;
});

declare namespace Type {
	interface Props {
		value: Value;
	}

	interface Value {
		type: Transaction.Model["paymentAccount"]["type"];
	}
}

export default PaymentAccountType;
