import React, { memo } from "react";

import { StyledRow } from "../../../../../../../../../../components/common";
import { ValueLanguage } from "../../../../../../../../../../assets/languages/langs";

const CompaniesBase: React.FC<CompaniesBase.Props> = ({
	taxiServices = [],
	language,
}) => {
	const companies: string[] = taxiServices?.map(
		(item) => item.company?.name?.[language],
	);

	const uniqueCompanies: string[] = Array.from(new Set(companies));

	return (
		<StyledRow alignItems="center" gap="8px">
			{uniqueCompanies.map((company, index) => (
				<StyledRow alignItems="center" key={index}>
					{company}
					{index !== uniqueCompanies.length - 1 && ","}
				</StyledRow>
			))}
		</StyledRow>
	);
};

declare namespace CompaniesBase {
	interface Props {
		taxiServices: any[];
		language: ValueLanguage;
	}
}

export const Companies = memo(CompaniesBase);

export default CompaniesBase;
