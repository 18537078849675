// eslint-disable-next-line no-shadow
export enum Columns {
	PHONE = "phone",
	SURNAME = "surname",
	NAME = "name",
	FATHER_NAME = "fatherName",
	DESCRIPTION = "description",
	LEVEL = "level",
	COMPANIES = "companies",
}

interface Column {
	id: Columns;
	label: string;
	width?: number;
}

export const columns: Column[] = [
	{ id: Columns.PHONE, label: "", width: 120 },
	{ id: Columns.SURNAME, label: "", width: 150 },
	{ id: Columns.NAME, label: "", width: 150 },
	{ id: Columns.FATHER_NAME, label: "", width: 150 },
	{ id: Columns.DESCRIPTION, label: "", width: 300 },
	{ id: Columns.LEVEL, label: "", width: 140 },
	{ id: Columns.COMPANIES, label: "" },
];

export const defaultColumnsId = columns.map(({ id }) => id);
