import { useCallback, useMemo } from "react";

import { useTypedDispatch, useTypedSelector } from "../../../../../redux/store";
import {
	State,
	customersAction as customers,
} from "../../../../../redux/reducers/Customers";
import { Customers } from "../../../../../redux/reducers/Customers/interface";

export type AutodetectPhone = Customers.CustomerAutodetectPhone;

export type UseAutodetectPhones = {
	data: State["autodetectPhone"]["data"];
	autodetectPhones: State["autodetectPhone"]["data"]["autodetectPhones"];
	errors: State["autodetectPhone"]["errors"];

	/** Set AutodetectPhones */
	setAutodetectPhones: (
		data: State["autodetectPhone"]["data"]["autodetectPhones"],
	) => void;
	/** Set AutodetectPhone */
	addAutodetectPhone: (
		data: State["autodetectPhone"]["data"]["autodetectPhones"][0],
	) => void;
	/** Update AutodetectPhone */
	updateAutodetectPhone: (
		data: State["autodetectPhone"]["data"]["autodetectPhones"][0],
	) => void;
	/** Update AutodetectPhone */
	removeAutodetectPhone: (
		data: State["autodetectPhone"]["data"]["autodetectPhones"][0]["value"],
	) => void;

	reset: () => void;
};

/** `useAutodetectPhones`
 ** Hook to get all the autodetectPhones report inside the redux store customers.
 ** And has functions for changing the state of autodetectPhones.
 */
export const useAutodetectPhones = (): UseAutodetectPhones => {
	const dispatch = useTypedDispatch();
	const data = useTypedSelector(
		(state) => state.customers.autodetectPhone.data,
	);
	const autodetectPhones = useTypedSelector(
		(state) => state.customers.autodetectPhone.data.autodetectPhones,
	);
	const errors = useTypedSelector(
		(state) => state.customers.autodetectPhone.errors,
	);

	const setAutodetectPhones = useCallback<
		UseAutodetectPhones["setAutodetectPhones"]
	>(
		(data) => {
			dispatch(customers.autodetectPhone.data.setAutodetectPhones(data));
		},
		[dispatch],
	);
	const addAutodetectPhone = useCallback<
		UseAutodetectPhones["addAutodetectPhone"]
	>(
		(data) => {
			dispatch(customers.autodetectPhone.data.addAutodetectPhone(data));
		},
		[dispatch],
	);

	const updateAutodetectPhone = useCallback<
		UseAutodetectPhones["updateAutodetectPhone"]
	>(
		(data) => {
			dispatch(
				customers.autodetectPhone.data.updateAutodetectPhone(data),
			);
		},
		[dispatch],
	);
	const removeAutodetectPhone = useCallback<
		UseAutodetectPhones["removeAutodetectPhone"]
	>(
		(data) => {
			dispatch(
				customers.autodetectPhone.data.removeAutodetectPhone(data),
			);
		},
		[dispatch],
	);

	const reset = useCallback<UseAutodetectPhones["reset"]>(() => {
		dispatch(customers.autodetectPhone.data.reset({}));
	}, [dispatch]);

	return useMemo<UseAutodetectPhones>(
		() => ({
			data,
			autodetectPhones,
			errors,

			setAutodetectPhones,
			addAutodetectPhone,
			updateAutodetectPhone,
			removeAutodetectPhone,
			reset,
		}),
		[
			data,
			errors,
			autodetectPhones,
			reset,
			setAutodetectPhones,
			addAutodetectPhone,
			updateAutodetectPhone,
			removeAutodetectPhone,
		],
	);
};

export default useAutodetectPhones;
