import { react } from "uikit";
import { Dispatch, SetStateAction } from "react";

import Code from ".";

interface Context {
	value: Code.Value;
	setCardNumberError: Dispatch<SetStateAction<string | boolean>>;
	setKeywordError: Dispatch<SetStateAction<boolean>>;
	cardNumberError: string | boolean;
}

export default class Controller extends react.ControllerBase<Context> {
	validate() {
		const isExistCard = this.context?.cardNumberError;
		const isValidCardNumber = !!this.context?.value.value.trim().length;
		if (!isValidCardNumber) this.context?.setCardNumberError?.(true);

		const isValidKeyword = !!this.context?.value.keyword.trim().length;
		if (!isValidKeyword) this.context?.setKeywordError?.(true);

		return isValidCardNumber && isValidKeyword && !isExistCard;
	}
}
