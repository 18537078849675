import { react } from "uikit";

import { IName } from "../../../../../../../../../../../../../../../../types/IName";
import { findValueByLanguageKey } from "../../../../../../../../../../../../../../../../assets/languages/langs";

interface Context {
	value: IName;
	setNameErr: (err: boolean) => void;
}

export default class Controller extends react.ControllerBase<Context> {
	validate() {
		const name = this.context?.value;

		const isValidName = findValueByLanguageKey(name);

		if (!isValidName) this.context?.setNameErr(true);

		return isValidName;
	}
}
