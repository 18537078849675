import React, { Dispatch, useLayoutEffect, useMemo, useState } from "react";

import Service from "../../../../../../../../../../../../../../services/Service";
import Language from "../../../../../../../../../../../../../../services/Language";
import CarClass from "../../../../../../../../../../../../../../services/CarClass";
import useModelSubscribe from "../../../../../../../../../../../../../../hooks/useModelSubscribe2";
import CheckBoxSelect from "../../../../../../../../../../../../../../components/CheckBoxSelect";

const ServiceIds: React.FC<ServiceIds.Props> = ({
	value,
	disabled,
	carClass,
	language,
	onChange,
	taxiServiceId,
}) => {
	const [serviceAvailableIds, setServiceAvailableIds] = useState<number[]>(
		[],
	);

	const serviceData = useModelSubscribe(
		{
			availableForCar: true,
			taxiServiceIds: taxiServiceId ? [taxiServiceId] : [],
		},
		Service,
	);

	const services = useMemo(
		() =>
			serviceData?.models?.filter((item) =>
				serviceAvailableIds.includes(item.id),
			) ?? [],
		[serviceAvailableIds, serviceData?.models],
	);

	useLayoutEffect(() => {
		setServiceAvailableIds(carClass?.serviceAvailableIds || []);
	}, [carClass?.serviceAvailableIds]);

	const serviceOptions = useMemo(
		() =>
			services.map((carClass) => ({
				key: carClass.id,
				label: carClass.name?.[language],
			})),
		[services, language],
	);

	return (
		<CheckBoxSelect
			title="pages.mainPage.pages.accounts.tabs.car.editModal.content.optionTab.serviceIds.str0"
			disabled={disabled}
			value={value}
			options={serviceOptions}
			onChange={onChange}
			verticalScroll={true}
			h={{ max: "200px" }}
			p="2px"
			rows={{ auto: "21px" }}
		/>
	);
};

declare namespace ServiceIds {
	type Value = number[];

	interface Props {
		value: Value;
		onChange: Dispatch<Value>;

		taxiServiceId?: number;
		carClass?: CarClass.Model;

		disabled: boolean;
		language: Language;
	}
}

export default ServiceIds;
