/* eslint-disable no-param-reassign */
/* eslint-disable no-shadow */

import React, { RefAttributes, useMemo } from "react";
import { react } from "uikit";

import Client from "../../../../../../../../../services/Client";
import HistoryTable from "../../../../../../../../../components/HistoryTable";
import { Entry } from "../../../../../../../../../components/HistoryTable/hooks/useFieldLocalizer";
import {
	StyledRow,
	SuspenseLoader,
} from "../../../../../../../../../components/common";

import InternalController from "./Controller";
import useChangeFormatter from "./hooks/useChangeFormatter";
import Root from "../components/Root";

const HistoryTab = react.withController<
	HistoryTab.PropsBase,
	HistoryTab.Controller
>(({ visible, value }) => {
	const formatChange = useChangeFormatter();

	const data = useMemo<HistoryTable.Item[]>(
		() =>
			value
				.map((item) => {
					const changes = item.changes
						.map((change) => formatChange(change))
						.filter((change) => !!change) as Entry[];

					return {
						...item,
						changes,
					};
				})
				.filter(
					(item) =>
						item.action !== "update" || item.changes.length !== 0,
				),
		[formatChange, value],
	);

	return (
		<Root visible={visible} hasPaddings={false}>
			<StyledRow position="absolute" top="50%" left="50%">
				<SuspenseLoader />
			</StyledRow>
			{/* <Column sizes="1fr" maxedWidth maxedHeight>
				<HistoryTable
					loading={false}
					onLoadMore={noop}
					data={data}
					tableSettingsSaveSlot="customers.clients.modal.history"
				/>
			</Column> */}
		</Root>
	);
}, InternalController);

declare namespace HistoryTab {
	type Ref = InternalController | null;
	type Controller = InternalController;

	interface PropsBase {
		visible: boolean;
		value: Value;
	}
	type Props = PropsBase & RefAttributes<Ref>;

	type Value = Client.History;
}

export default HistoryTab;
