import { TFunction } from "i18next";

interface Column {
	id: string;
	label: string;
}

const getColumns = (t: TFunction): Column[] => [
	{
		id: "parkNumber",
		label: t("carTable.parkNumber") ?? "",
	},
	{
		id: "aliases",
		label: t("carTable.aliases") ?? "",
	},
	{
		id: "brand",
		label: t("carTable.brand") ?? "",
	},
	{
		id: "model",
		label: t("carTable.model") ?? "",
	},
	{
		id: "class",
		label: t("carTable.class") ?? "",
	},
	{
		id: "bodyType",
		label: t("carTable.bodyType") ?? "",
	},
	{
		id: "registeredAt",
		label: t("carTable.registeredAt") ?? "",
	},
	{
		id: "responsibleDispatcher",
		label: t("carTable.responsibleDispatcher") ?? "",
	},
	{
		id: "company",
		label: t("carTable.company") ?? "",
	},
	{
		id: "taxiService",
		label: t("carTable.taxiService") ?? "",
	},
	{
		id: "color",
		label: t("carTable.color") ?? "",
	},
	{
		id: "notes",
		label: t("carTable.notes") ?? "",
	},
]; // columns

const defaultColumnIds = [
	"parkNumber",
	"aliases",
	"brand",
	"model",
	"class",
	"bodyType",
	"registeredAt",
	"responsibleDispatcher",
	"company",
	"taxiService",
	"color",
	"notes",
];

export { defaultColumnIds, getColumns };
