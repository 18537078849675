import styled from "styled-components";
import { Row, theme } from "uikit";

const Label = styled(Row)`
	position: absolute;

	top: -9px;

	padding: 0px 5px;

	background: #ffffff;

	font-family: "Lato";
	font-style: normal;
	font-weight: 800;
	font-size: 12px;
	line-height: 18px;

	color: ${(props) => props.color || theme.colors.primary};
`;

export default Label;
