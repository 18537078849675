import React, { Dispatch, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { CheckBoxWithContent } from "uikit";
import { uniqBy } from "lodash";

import Service from "../../../../../../../../../../../../../../services/Service";
import Language from "../../../../../../../../../../../../../../services/Language";
import useObjectEditor from "../../../../../../../../../../../../../../hooks/useObjectEditor";
import FieldsContainer from "../../../../../../../../../../../../../../components/FieldsContainer";
import { StyledGrid } from "../../../../../../../../../../../../../../components/common";
import { useExecutorContext } from "../../../../../../context";

const ServiceIds: React.FC<ServiceIds.Props> = ({
	value,
	onChange,

	disabled,
	language,
	roleIds,
	taxiServiceId,
}) => {
	const { t } = useTranslation();
	const { executorRole, services } = useExecutorContext();

	const currentRoles = useMemo(
		() => executorRole.filter((role) => roleIds.includes(role.id) || []),
		[roleIds, executorRole],
	);

	const availableServices = useMemo(
		() =>
			uniqBy(
				currentRoles
					.flatMap((role) => role.services)
					.filter((service) => {
						const exist = services.find(
							(item) => item.id === service?.id,
						);

						if (!exist) return false;
						if (
							!exist.taxiServices?.some(
								(item) => item.id === taxiServiceId,
							)
						)
							return false;

						if (service?.active && service.deletedAt === null) {
							return true;
						}
						return false;
					}) as Service.Model[],
				(service) => service.id,
			),
		[currentRoles, services, taxiServiceId],
	);

	const valueEditor = useObjectEditor(value, onChange);

	return (
		<FieldsContainer
			label={
				t(
					"pages.mainPage.pages.accounts.tabs.executors.editModal.content.optionTab.serviceIds.str0",
				) ?? ""
			}
			style={{ minHeight: "47px" }}
		>
			<StyledGrid
				areas=""
				gap="12px"
				columns="repeat(auto-fill, minmax(200px, 1fr))"
				rows={{
					auto: "21px",
				}}
				css={`
					div > div {
					}
					div > div > div:nth-child(2) {
						display: flex;
						flex-wrap: nowrap;
						gap: 10px;
					}
				`}
			>
				{availableServices.map((service) => (
					<CheckBoxWithContent
						key={service.id}
						value={valueEditor.includes(service.id)}
						gap="10px"
						disabled={disabled}
						onChange={(serviceEnable) => {
							if (serviceEnable) valueEditor.pushUniq(service.id);
							else valueEditor.removeByValue(service.id);
						}}
					>
						{service.name?.[language]}
					</CheckBoxWithContent>
				))}
			</StyledGrid>
		</FieldsContainer>
	);
};

declare namespace ServiceIds {
	type Value = number[];

	interface Props {
		value: Value;
		taxiServiceId?: number;
		disabled: boolean;
		language: Language;
		roleIds: number[];

		onChange: Dispatch<Value>;
	}
}

export default ServiceIds;
