import React, { memo, useCallback, useState } from "react";
import { useRefWithSetter } from "uikit";

import AgentGroup from "../../../../../../../../services/AgentGroup";
import useObjectEditor from "../../../../../../../../hooks/useObjectEditor";
import { NonEditableProperties } from "../../../../../../../../types/NonEditableProperties";
import EditModal from "../../../../../../../../components/EditModal";

import Content from "./components/Content";
import Header from "./components/Header";
import Root from "./components/Root";

const Modal: React.FC<Modal.Props> = memo((props) => {
	const {
		editorTable,
		onChangeTable,
		agents,
		agentById,
		value,
		onCancel,
		onSave,
		headerTitle,
	} = props;

	const [contentRef, setContentRef] = useRefWithSetter<Content.Ref>(null);

	const [internalValue, setInternalValue] = useState(value);
	const editor = useObjectEditor(internalValue, setInternalValue);

	const contentValues = editor.usePicker(["name", "agentIds", "notes"]);

	const handleSubmit = useCallback(() => {
		if (!contentRef.current?.validate()) return;

		onSave(internalValue);
	}, [contentRef, internalValue, onSave]);

	return (
		<EditModal onCancel={onCancel} onSave={handleSubmit}>
			<Root sizes="auto! 1fr">
				<Header title={headerTitle} createdAt={value.createdAt} />
				<Content
					ref={setContentRef}
					agents={agents}
					agentById={agentById}
					value={contentValues}
					onChange={editor.assign}
					editorTable={editorTable}
					onChangeTable={onChangeTable}
				/>
			</Root>
		</EditModal>
	);
});

declare namespace Modal {
	interface Props {
		agents: Content.Props["agents"];
		agentById: Content.Props["agentById"];
		editorTable: Content.Props["editorTable"];
		onChangeTable: Content.Props["onChangeTable"];
		value: Value;
		headerTitle: string;
		onCancel: () => void;
		onSave: (model: AgentGroup.New | AgentGroup.Modified) => Promise<void>;
	}

	interface Value extends Content.Value, Partial<NonEditableProperties> {}
}

export default Modal;
