/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable no-shadow */

import React, {
	Dispatch,
	Key,
	RefAttributes,
	memo,
	useCallback,
	useMemo,
	useState,
} from "react";
import { useTranslation } from "react-i18next";
import {
	Column,
	MultilineTextBox,
	Row,
	Select,
	TextBox,
	react,
	useRefWithSetter,
} from "uikit";

import Service from "../../../../../../../../../../../../services/Service";
import Role from "../../../../../../../../../../../../services/Role";
import Language from "../../../../../../../../../../../../services/Language";
import FieldsContainer from "../../../../../../../../../../../../components/FieldsContainer";
import useObjectEditor from "../../../../../../../../../../../../hooks/useObjectEditor";
import useModelSubscribe from "../../../../../../../../../../../../hooks/useModelSubscribe2";
import useTaxiServiceIdsDecoder from "../../../../../../../../../../../../hooks/useTaxiServiceIdsDecoder";
import LabeledField from "../../../../../../../../../../../../components/LabeledField";
import mapByKey from "../../../../../../../../../../../../utils/mapByKey";
import { TabRoot } from "..";

import InternalController from "./Controller";
import getAssignOptions from "./constants";
import Access from "./components/Access";
import Services from "./components/Services";

const MainTab = memo(
	react.withController<MainTab.PropsBase, MainTab.Controller>(
		({
			controller,
			value,
			disabled,
			visible,
			language,
			onChange,
			isMainRole,
		}) => {
			const { t } = useTranslation();

			const assignOptions = useMemo(() => getAssignOptions(t), [t]);

			const [accessRef, setAccessRef] =
				useRefWithSetter<Access.Ref | null>(null);

			const decodeTaxiServiceIds = useTaxiServiceIdsDecoder();

			const valueEditor = useObjectEditor(value, onChange);

			const companyIds = valueEditor.get("companyIds");
			const taxiServiceIds = valueEditor.get("taxiServiceIds");

			const decodedTaxiServiceIds = useMemo(
				() => decodeTaxiServiceIds(companyIds, taxiServiceIds),
				[companyIds, decodeTaxiServiceIds, taxiServiceIds],
			);

			const serviceData = useModelSubscribe(
				{
					taxiServiceIds: decodedTaxiServiceIds,
					availableForExecutor: true,
				},
				Service,
			);
			const services = useMemo(
				() => serviceData?.models ?? [],
				[serviceData?.models],
			);

			const serviceById = useMemo(
				() => mapByKey(services, "id"),
				[services],
			);

			const assignValue = valueEditor.useAssigner();

			const nameValue = valueEditor.useGetter("name");

			const [nameError, setNameError] = useState(false);

			const nameOnChange = useCallback(
				(name: string) => {
					setNameError(false);

					valueEditor.set("name", name);
				},
				// eslint-disable-next-line react-hooks/exhaustive-deps
				[valueEditor.set],
			);

			const [assignableToError, setAssignableToError] = useState(false);

			const assignableToValue = valueEditor.useGetter("assignableTo");

			const assignableToOnChange = useCallback(
				(assignableToValue: Key) => {
					setAssignableToError(false);

					assignValue({
						assignableTo: assignableToValue as Role.AssignableTo,
						default: false,

						serviceIds:
							assignableToValue === "dispatcher"
								? []
								: valueEditor.get("serviceIds"),

						executorIds:
							assignableToValue === "executor"
								? valueEditor.get("executorIds")
								: [],

						dispatcherIds:
							assignableToValue === "dispatcher"
								? valueEditor.get("dispatcherIds")
								: [],
					});
				},
				// eslint-disable-next-line react-hooks/exhaustive-deps
				[assignValue],
			);

			const serviceIdsValue = valueEditor.useGetter("serviceIds");

			const serviceIdsOnChange = valueEditor.useSetter("serviceIds");

			const accessValue = valueEditor.usePicker([
				"companyIds",
				"taxiServiceIds",
			]);

			const accessOnChange = useCallback(
				(accessValue: Access.Value) => {
					const decodedTaxiServiceIds = decodeTaxiServiceIds(
						accessValue.companyIds,
						accessValue.taxiServiceIds,
					);

					const value: Partial<MainTab.Value> = {
						...accessValue,
					};

					const serviceIds = serviceIdsValue;

					value.serviceIds = serviceIds.filter((serviceId) =>
						serviceById?.[serviceId]?.taxiServices?.find(
							(taxiService) =>
								decodedTaxiServiceIds.includes(taxiService.id),
						),
					);

					assignValue(value);
				},
				[
					assignValue,
					decodeTaxiServiceIds,
					serviceById,
					serviceIdsValue,
				],
			);

			const descriptionValue = valueEditor.useGetter("description");

			const descriptionOnChange = valueEditor.useSetter("description");
			const serviceIdsDisabled = useMemo(
				() => assignableToValue === "dispatcher" || !assignableToValue,
				[assignableToValue],
			);

			controller.setContext({
				value,

				setNameError,
				setAssignableToError,

				accessRef,
			});

			const firstRow = useMemo(
				() => (
					<Row gaps="8px*" sizes="1fr*">
						<LabeledField
							label={
								t(
									"pages.mainPage.pages.accounts.tabs.roles.editModal.content.mainTab.str0",
								) ?? ""
							}
						>
							<TextBox.TextBox
								value={nameValue}
								disabled={isMainRole || disabled}
								style={{
									minHeight: "32px",
									flex: "1 0 0",
								}}
								autoComplete="one-time-code"
								placeholder={
									t(
										"pages.mainPage.pages.accounts.tabs.roles.editModal.content.mainTab.str0",
									) ?? ""
								}
								error={nameError}
								onChange={nameOnChange}
							/>
						</LabeledField>
						<Access
							ref={setAccessRef}
							value={accessValue}
							disabled={disabled}
							language={language}
							onChange={accessOnChange}
						/>
					</Row>
				),
				[
					t,
					nameValue,
					isMainRole,
					disabled,
					nameError,
					nameOnChange,
					setAccessRef,
					accessValue,
					language,
					accessOnChange,
				],
			);

			const secondRow = useMemo(
				() => (
					<Row gaps="8px*" sizes="1fr 2fr">
						<LabeledField
							label={
								t(
									"pages.mainPage.pages.accounts.tabs.roles.editModal.content.mainTab.str2",
								) ?? ""
							}
						>
							<Select
								value={assignableToValue}
								disabled={isMainRole || disabled}
								style={{
									minHeight: "32px",
									flex: "1 0 0",
								}}
								placeholder={
									t(
										"pages.mainPage.pages.accounts.tabs.roles.editModal.content.mainTab.str2",
									) ?? ""
								}
								error={assignableToError}
								options={assignOptions}
								onChange={assignableToOnChange}
							/>
						</LabeledField>

						<Services
							value={serviceIdsValue}
							disabled={serviceIdsDisabled || disabled}
							taxiServiceIds={decodedTaxiServiceIds}
							language={language}
							onChange={serviceIdsOnChange}
						/>
					</Row>
				),
				[
					assignOptions,
					assignableToError,
					assignableToOnChange,
					assignableToValue,
					decodedTaxiServiceIds,
					disabled,
					isMainRole,
					language,
					serviceIdsDisabled,
					serviceIdsOnChange,
					serviceIdsValue,
					t,
				],
			);

			return (
				<TabRoot hasPaddings visible={visible}>
					<Column
						sizes="auto! 1fr"
						gaps="19px*"
						maxedWidth
						maxedHeight
					>
						<FieldsContainer
							label={
								t(
									"pages.mainPage.pages.accounts.tabs.roles.editModal.content.mainTab.str4",
								) ?? ""
							}
						>
							<Column gaps="8px*">
								{firstRow}
								{secondRow}
							</Column>
						</FieldsContainer>
						<MultilineTextBox
							value={descriptionValue}
							disabled={disabled}
							placeholder={
								t(
									"pages.mainPage.pages.accounts.tabs.roles.editModal.content.mainTab.str5",
								) ?? ""
							}
							onChange={descriptionOnChange}
						/>
					</Column>
				</TabRoot>
			);
		},
		InternalController,
	),
);

declare namespace MainTab {
	type Ref = InternalController | null;

	type Controller = InternalController;

	interface Value extends Access.Value {
		dispatcherIds: number[];
		executorIds: number[];
		serviceIds: number[];

		name: string;

		assignableTo?: Role.AssignableTo;
		default: boolean;

		description: string;
	}

	interface PropsBase {
		value: Value;

		disabled: boolean;
		visible: boolean;
		language: Language;

		onChange: Dispatch<Value>;
		isMainRole: boolean;
	}

	type Props = PropsBase & RefAttributes<Ref>;
}

export default MainTab;
