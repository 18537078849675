import React, { memo, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Column } from "uikit";

import useObjectEditor from "../../../../../../../../../../../../../../hooks/useObjectEditor";
import MinimumOrderValueBlock from "../../../../../../../../../../components/MinimumOrderValueBlock";
import HourlyService from "../../../../../../../../../../components/HourlyService";
import Waiting from "../../../../../../../../../../components/Waiting";
import SurchargesBlock from "../../../../../../../../../../components/SurchargesBlock";
import { surchargesOptions } from "../../../../../../../../../Additional/constants/surchargeOptions";
import { BaseRateAdjustmentType } from "../../../../../../../../../../../../../../services/Tariff/enums";
import FieldsContainer from "../../../../../../../../../../../../../../components/FieldsContainer";
import { StyledColumn } from "../../../../../../../../../../../../../../components/common";
import BaseSurcharge from "../../../../../../../../../../components/SurchargesBlock/components/BaseSurcharge";

import MainSettings from "./components/MainSettings";

const Tariffs: React.FC<Tariffs.Props> = memo(({ value, onChange }) => {
	const { t } = useTranslation();

	const valueEditor = useObjectEditor(value, onChange);

	const mainTariffSettings = valueEditor.usePicker([
		"boardingCost",
		"settlementCostPerKm",
		"suburbanCostPerKm",
		"roundTripCostPerKm",
		"settlementPickupCost",
		"suburbanPickupCost",
		"pickupCostCalculation",
	]);

	const minimumOrderCost = valueEditor.usePicker([
		"minOrderCost",
		"minPreliminaryOrderCost",
	]);

	// const hourlyCost = valueEditor.usePicker([
	// 	"minHourlyRate",
	// 	"hourlyServiceInterval",
	// 	"hourlyServiceCost",
	// 	"suburbanCostPerKm",
	// 	"settlementCostPerKm",
	// ]);

	const waiting = valueEditor.usePicker([
		"idleMinuteCost",
		"rideMinuteCost",
		"waitingMinuteCost",
	]);

	const surchargeToOrderSum = valueEditor.useGetter("surchargeToOrderSum");
	const setSurchargeToOrderSum = valueEditor.useSetter("surchargeToOrderSum");

	const options = useMemo(
		() =>
			surchargesOptions.map((option) => ({
				...option,
				label: t(
					`settings.tariffs.main.mainModal.tabs.main.surcharges.options.${option.key}`,
				),
			})),
		[t],
	);

	const surchargeToOrderSumOptions = useMemo(
		() =>
			options.filter(
				(o) =>
					o.key === BaseRateAdjustmentType.SURCHARGE_TO_ORDER_SUM ||
					o.key === BaseRateAdjustmentType.COEFFICIENT_TO_ORDER_SUM,
			),
		[options],
	);

	const assigner = valueEditor.useAssigner();

	return (
		<Column gaps="25px*">
			<MainSettings value={mainTariffSettings} onChange={assigner} />
			<MinimumOrderValueBlock
				root={false}
				value={minimumOrderCost}
				onChange={assigner}
			/>
			{/* <HourlyService
				root={false}
				value={hourlyCost}
				onChange={assigner}
			/> */}
			<Waiting root={false} value={waiting} onChange={assigner} />
			<FieldsContainer
				label={
					t(
						`settings.tariffs.main.mainModal.tabs.main.surcharges.blockName`,
					) || ""
				}
				gaps="10px*"
			>
				<StyledColumn w="max-content" gap="10px">
					<BaseSurcharge
						options={surchargeToOrderSumOptions}
						label="surchargeToOrderSum"
						value={surchargeToOrderSum}
						onChange={setSurchargeToOrderSum}
					/>
				</StyledColumn>
			</FieldsContainer>
		</Column>
	);
});

declare namespace Tariffs {
	interface Props {
		value: Value;
		onChange: (value: Value) => void;
	}

	interface Value
		extends MainSettings.Value,
			MinimumOrderValueBlock.Value,
			HourlyService.Value,
			Pick<SurchargesBlock.Surcharges, "surchargeToOrderSum">,
			Waiting.Value {
		ignoreCoefficient: boolean;
	}
}

export default Tariffs;
