/* eslint-disable no-shadow */

import React, {
	Dispatch,
	RefAttributes,
	useCallback,
	useMemo,
	useState,
} from "react";
import { useTranslation } from "react-i18next";
import { Option, react } from "uikit";
import { isUndefined } from "lodash";
import CarBodyType from "../../../../../../../../../../../../../../../../services/CarBodyType";

import useModelSubscribe from "../../../../../../../../../../../../../../../../hooks/useModelSubscribe2";
import LabeledField from "../../../../../../../../../../../../../../../../components/LabeledField";
import SelectWithModal from "../../../../../../../../../../../../../../../../components/SelectWithModal";
import InternalController from "./Controller";

const CarBodyTypeId = react.withController<
	CarBodyTypeId.Props,
	CarBodyTypeId.Controller
>(
	({
		controller,

		value,

		disabled,
		carModelId,

		onChange,
	}) => {
		const { t } = useTranslation();
		const [error, setError] = useState(false);

		const modelData = useModelSubscribe(
			{
				carModelIds: isUndefined(carModelId) ? undefined : [carModelId],
			},
			CarBodyType,
		);

		const items = useMemo(() => modelData.models, [modelData.models]);

		const selectOptions = useMemo<Option<number>[]>(
			() =>
				items.map((item) => ({
					key: item.id,
					label: item.name,
					value: item.id,
				})),
			[items],
		);

		const selectOnChange = useCallback(
			(newValue: SelectWithModal.Value<number>) => {
				setError(false);

				onChange(newValue as number | undefined);
			},
			[onChange],
		);

		controller.setContext({ value, setError });

		return (
			<LabeledField
				label={
					t(
						"pages.mainPage.pages.accounts.tabs.car.editModal.content.mainTab.mainData.carBodyTypeId.str0",
					) ?? ""
				}
			>
				<SelectWithModal
					value={value}
					disabled={disabled}
					error={error}
					title={
						t(
							"pages.mainPage.pages.accounts.tabs.car.editModal.content.mainTab.mainData.carBodyTypeId.str0",
						) ?? ""
					}
					options={selectOptions}
					onChange={selectOnChange}
				/>
			</LabeledField>
		);
	},
	InternalController,
);

declare namespace CarBodyTypeId {
	type Ref = InternalController | null;

	type Controller = InternalController;

	type Value = number | undefined;

	interface PropsBase {
		value: Value;

		disabled: boolean;
		carModelId?: number;

		onChange: Dispatch<Value>;
	}

	type Props = PropsBase & RefAttributes<Ref>;
}

export default CarBodyTypeId;
