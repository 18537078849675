/* eslint-disable no-shadow */

import React, { Key, useCallback, useMemo } from "react";
import { Option, useInternal } from "uikit";
import Draggable from "react-draggable";

import Modal from "../Modal";

import Header from "./components/Header";
import Root from "./components/Root";
import Content from "./components/Content";
import Footer from "./components/Footer";

const SelectModal = <OptionValue, ValueType extends Key>({
	value,

	title,
	options,

	onChange,

	onClose,
	onSubmit,
}: SelectModal.Props<OptionValue, ValueType>) => {
	const [internalValue, setInternalValue] = useInternal(value);

	const headerOnClose = useCallback(() => {
		onClose?.();
	}, [onClose]);

	const contentOnChange = useCallback(
		(contentValue: Content.Value<ValueType>) => {
			setInternalValue(contentValue);
			onChange?.(contentValue);
		},
		[onChange, setInternalValue],
	);

	const footerOnCancel = useCallback(() => {
		onClose?.();
	}, [onClose]);

	const footerOnSave = useCallback(() => {
		onSubmit?.(internalValue);
	}, [internalValue, onSubmit]);

	const content = useMemo(
		() => (
			<Root sizes="auto! 1fr auto!">
				<Header title={title} onClose={headerOnClose} />
				<Content
					value={internalValue}
					options={options ?? []}
					onChange={contentOnChange}
				/>
				<Footer onCancel={footerOnCancel} onSave={footerOnSave} />
			</Root>
		),
		[
			contentOnChange,
			footerOnCancel,
			footerOnSave,
			headerOnClose,
			internalValue,
			options,
			title,
		],
	);

	return (
		<Modal dimmed>
			<Draggable handle=".draggable-area">{content}</Draggable>
		</Modal>
	);
};

declare namespace SelectModal {
	type Value<Type extends Key> = Type | undefined;

	interface Props<OptionValue, ValueType extends Key> {
		value?: Value<ValueType>;

		title?: string;
		options?: Option<OptionValue>[];

		onChange?: (value: Value<ValueType>) => void;

		onClose?: () => void;
		onSubmit?: (value: Value<ValueType>) => void;
	}
}

export default SelectModal;
