import React, { memo } from "react";
import { ToggleButton } from "uikit";

const DisabledToggleButton: React.FC<DisabledToggleButton.Props> = memo(
	({ value }) => (
		<div style={{ pointerEvents: "none" }}>
			<ToggleButton.ToggleButton value={value} />
		</div>
	),
);

declare namespace DisabledToggleButton {
	interface Props {
		value: boolean;
	}
}

export default DisabledToggleButton;
