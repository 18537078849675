import React, { useMemo, lazy, Suspense } from "react";
import { Tabs } from "uikit";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

import { useTypedSelector } from "../../../../redux/store";
import useActiveTab from "../../../../hooks/useActiveTab";
import { hasAccess } from "../../../../access";
import { TabOptions } from "../../../../types";
import BasicPageLayout from "../../../../components/BasicPageLayout";
import { SuspenseLoader } from "../../../../components/common";

import { TabKeys, TabAccessNames } from "./constants/access";

const ExpandableTabs = styled(Tabs)`
	& > * {
		white-space: normal;
		word-wrap: break-word;
		height: auto;
		padding: 11px 10px;
	}
`;

const LazyDispatchersActivity = lazy<React.FC<SalaryReports.Props>>(
	async () => {
		const elem = await import(
			"./tabs/DispatchersActivity" /* webpackChunkName: "salary-reports-dispatchers-activity" */
		);
		return elem;
	},
);

const SalaryReports: React.FC = () => {
	const { t } = useTranslation();

	const personalRole = useTypedSelector(
		(state) => state.account.personalRole,
	);

	const tabs: TabOptions.Array = useMemo(
		() =>
			[
				{
					key: TabKeys.DISPATCHERS_ACRIVITY,
					label: t(
						"mainPage.reports.salary.dispatchersActivity.title",
					),
					value: {
						render: () => (
							<Suspense fallback={<SuspenseLoader />}>
								<LazyDispatchersActivity
									headerTitle={t(
										"mainPage.reports.salary.dispatchersActivity.title",
									)}
								/>
							</Suspense>
						),
					},
					accessName: TabAccessNames[TabKeys.DISPATCHERS_ACRIVITY],
				},
			].filter((btn) => hasAccess(btn.accessName, personalRole)),
		[t, personalRole],
	);

	const { activeKey, activeTab, setActiveKey } = useActiveTab(tabs);

	return (
		<BasicPageLayout
			navigation={
				<ExpandableTabs
					value={activeKey}
					onChange={(newActive) => setActiveKey(newActive)}
					options={tabs}
					variant="vertical"
				/>
			}
			content={activeTab.value.render()}
		/>
	);
};

declare namespace SalaryReports {
	interface Props {
		headerTitle: string;
		general?: boolean;
	}
}

export default SalaryReports;
