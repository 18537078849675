/* eslint-disable no-param-reassign */
/* eslint-disable no-shadow */

import React, { RefAttributes, useMemo } from "react";
import { react } from "uikit";

import Client from "../../../../../../../../../../../../../services/Client";
import HistoryTable from "../../../../../../../../../../../../../components/HistoryTable";
import { Entry } from "../../../../../../../../../../../../../components/HistoryTable/hooks/useFieldLocalizer";
import {
	StyledRow,
	SuspenseLoader,
} from "../../../../../../../../../../../../../components/common";

import TabRoot from "../../TabRoot";
import useChangeFormatter from "./hooks/useChangeFormatter";
import InternalController from "./Controller";

const HistoryTab = react.withController<
	HistoryTab.PropsBase,
	HistoryTab.Controller
>(({ value, visible }) => {
	const formatChange = useChangeFormatter();

	const data = useMemo<HistoryTable.Item[]>(
		() =>
			value
				.map((item) => {
					const changes = item.changes
						.map((change) => formatChange(change))
						.filter((change) => !!change) as Entry[];

					return {
						...item,
						changes,
					};
				})
				.filter(
					(item) =>
						item.action !== "update" || item.changes.length !== 0,
				),
		[formatChange, value],
	);

	return (
		<TabRoot hasPaddings={false} visible={visible}>
			{/* <Column sizes="1fr" maxedWidth maxedHeight>
				<HistoryTable
					loading={false}
					onLoadMore={noop}
					data={data}
					tableSettingsSaveSlot="customers.clients.modal.history"
				/>
			</Column> */}
			<StyledRow position="absolute" top="50%" left="50%">
				<SuspenseLoader />
			</StyledRow>
		</TabRoot>
	);
}, InternalController);

declare namespace HistoryTab {
	type Ref = InternalController | null;

	type Controller = InternalController;

	type Value = Client.History;

	interface PropsBase {
		value: Value;
		visible: boolean;
	}

	type Props = PropsBase & RefAttributes<Ref>;
}

export default HistoryTab;
