import { RefObject } from "react";
import { react } from "uikit";
import MainTab from "./components/MainTab";

interface Context {
	mainTabRef: RefObject<MainTab.Ref | null>;
}

export default class Controller extends react.ControllerBase<Context> {
	async validate() {
		const isMainTabValid =
			!!(await this.context?.mainTabRef.current?.validate());

		return isMainTabValid;
	}
}
