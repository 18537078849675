import React, { Dispatch, RefAttributes, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Option, Select, react } from "uikit";
import Language from "../../../../../../../../../../../../../../../../services/Language";
import TaxiService from "../../../../../../../../../../../../../../../../services/TaxiService";
import LabeledField from "../../../../../../../../../../../../../../../../components/LabeledField";
import useModelSubscribe from "../../../../../../../../../../../../../../../../hooks/useModelSubscribe";
import mapByKey from "../../../../../../../../../../../../../../../../utils/mapByKey";
import InternalController from "./Controller";

const TaxiServiceId = react.withController<
	TaxiServiceId.PropsBase,
	TaxiServiceId.Controller
>(
	({
		value,

		disabled,
		error,
		language,
		companyId,

		onChange,
	}) => {
		const { t } = useTranslation();
		const modelData = useModelSubscribe({}, TaxiService);

		const items = useMemo(() => modelData?.cache ?? [], [modelData?.cache]);

		const selectOptions = useMemo<Option<number>[]>(
			() =>
				items
					.filter(
						(item) =>
							typeof companyId !== "number" ||
							item.company?.id === companyId,
					)
					.map((item) => ({
						key: item.id,
						label: item.settlement[language],
						value: item.id,
					})),
			[companyId, items, language],
		);

		const selectOptionByKey = useMemo(
			() => mapByKey(selectOptions, "key"),
			[selectOptions],
		);

		const selectOnChange = useCallback(
			(key: any) => {
				onChange(selectOptionByKey[key].value);
			},
			[onChange, selectOptionByKey],
		);

		return (
			<LabeledField
				label={
					t(
						"pages.mainPage.pages.accounts.tabs.car.editModal.content.mainTab.workData.taxiServiceId.str0",
					) ?? ""
				}
			>
				<Select
					value={value}
					disabled={disabled}
					error={error}
					placeholder={
						t(
							"pages.mainPage.pages.accounts.tabs.car.editModal.content.mainTab.workData.taxiServiceId.str0",
						) ?? ""
					}
					options={selectOptions}
					onChange={selectOnChange}
				/>
			</LabeledField>
		);
	},
	InternalController,
);

declare namespace TaxiServiceId {
	type Ref = InternalController | null;

	type Controller = InternalController;

	type Value = number | undefined;
	type NewValue = number;

	interface PropsBase {
		value: Value;

		disabled: boolean;
		error: boolean;
		language: Language;
		companyId?: number;

		onChange: Dispatch<NewValue>;
	}

	type Props = PropsBase & RefAttributes<Ref>;
}

export default TaxiServiceId;
