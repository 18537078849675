import React from "react";
import { Column, react } from "uikit";

declare namespace Content {
	interface Value {
		thereWillBeFilters?: never;
	}

	interface PropsBase {}
}

const Content = react.inputify<Content.PropsBase, Content.Value>(
	// eslint-disable-next-line arrow-body-style
	(/* { value = {}, onChange} */) => {
		return (
			<Column gaps="12px*">
				<div>There will be filters</div>
			</Column>
		);
	},
);

export default Content;
