import React, { Dispatch, memo } from "react";
import FilesTab from "../../../../../../../../../../../../tabs/FilesTab";
import File from "../../../../../../../../../../../../../../../../../../../services/File";

const Files: React.FC<Files.Props> = memo(({ visible, value, onChange }) => (
	<FilesTab visible={visible} value={value} onChange={onChange} />
));

declare namespace Files {
	interface Props {
		visible: boolean;
		value: Value;
		onChange: Dispatch<Value>;
	}

	interface Value {
		personalFiles: File.Model[];
		otherFiles: File.Model[];
	}
}

export default Files;
