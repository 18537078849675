/* eslint-disable no-shadow */

import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useInternal } from "uikit";

import Car from "../../../../../../../../../../../../../../services/Car";
import Language from "../../../../../../../../../../../../../../services/Language";
import InputModal from "../../../../../../../../../../../../../../components/InputModal";
import CarSelectTab from "../../../../../../../../../../../../../../components/CarSelectTab";

import Root from "./components/Root";

const CarSelectModal: React.FC<CarSelectModal.Props> = ({
	value,

	language,
	allowedTaxiServiceIds,
	taxiServiceId,

	onClose,
	onSubmit,
	subscribeType,
}) => {
	const { t } = useTranslation();
	const [internalValue, setInternalValue] = useInternal(value);

	const inputModalOnSubmit = useCallback(() => {
		onSubmit?.(internalValue ?? []);
	}, [internalValue, onSubmit]);

	const carSelectTabValue = useMemo(
		() => ({ carIds: internalValue }),
		[internalValue],
	);

	const carSelectTabOnChange = useCallback(
		(carSelectTabValue: CarSelectTab.Value) =>
			setInternalValue(carSelectTabValue.carIds),
		[setInternalValue],
	);

	return (
		<InputModal
			draggable
			title={
				t(
					"pages.mainPage.pages.accounts.tabs.executors.editModal.content.carSelectTab.carSelectModal.str0",
				) ?? ""
			}
			onClose={onClose}
			onSubmit={inputModalOnSubmit}
		>
			<Root sizes="600px!">
				<CarSelectTab
					value={carSelectTabValue}
					disabled={false}
					visible
					language={language}
					allowedTaxiServiceIds={allowedTaxiServiceIds}
					onChange={carSelectTabOnChange}
					taxiServiceId={taxiServiceId}
					subscribeType={subscribeType}
				/>
			</Root>
		</InputModal>
	);
};

declare namespace CarSelectModal {
	type Value = number[];

	interface Props {
		value: Value;

		language: Language;
		allowedTaxiServiceIds: number[];
		taxiServiceId?: number;
		subscribeType?: Car.SubscribeOptions["subscribeType"];

		onClose?: () => void;
		onSubmit?: (value: Value) => void;
	}
}

export default CarSelectModal;
