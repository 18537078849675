import { RefObject } from "react";
import { react } from "uikit";
import MainTab from "./components/tabs/MainTab";
import OptionsTab from "./components/tabs/OptionsTab";

interface Context {
	mainTabRef: RefObject<MainTab.Ref | null>;
	optionsTabRef: RefObject<OptionsTab.Ref | null>;
}

export default class Controller extends react.ControllerBase<Context> {
	validate() {
		const isMainTabValid = !!this.context?.mainTabRef.current?.validate();
		const isOptionsTabValid =
			!!this.context?.optionsTabRef.current?.validate();

		return isMainTabValid && isOptionsTabValid;
	}
}
