import React, { Dispatch, RefAttributes } from "react";
import { Column, react, useRefWithSetter } from "uikit";
import { useTranslation } from "react-i18next";

import useObjectEditor from "../../../../../../../../../../../../../hooks/useObjectEditor";
import Account from "../../../../../../../../../../../../../components/Account";

import TabRoot from "../../TabRoot";
import PersonData from "./components/PersonalData";
import AdditionalData from "./components/AdditionalData";
import Other from "./components/Other";
import Notes from "./components/Notes";
import InternalController from "./Controller";
import FieldsContainer from "../../../../../../../../../../../../../components/FieldsContainer";
import { PaymentProviderAccessorType } from "../../../../../../../../../../../../../services/Payment/types";

declare namespace MainTab {
	type Ref = InternalController | null;
	type Controller = InternalController;

	interface Value
		extends AdditionalData.Value,
			PersonData.Value,
			Other.Value,
			Account.Value,
			Notes.Value {
		id?: number;
	}

	interface PropsBase {
		clientId?: number;
		groups: AdditionalData.Props["groups"];
		visible: boolean;
		value: Value;
		onChange: Dispatch<Value>;
	}

	type Props = PropsBase & RefAttributes<Ref>;
}

const MainTab = react.withController<MainTab.PropsBase, MainTab.Controller>(
	({ controller, clientId, groups, value, visible, onChange }) => {
		const { t } = useTranslation();

		const [personDataRef, setPersonDataRef] =
			useRefWithSetter<PersonData.Ref | null>(null);
		const [additionalDataRef, setAdditionalDataRef] =
			useRefWithSetter<AdditionalData.Ref | null>(null);

		controller.setContext({
			personDataRef,
			additionalDataRef,
		});

		const valueEditor = useObjectEditor(value, onChange);
		const picker = valueEditor.usePicker([
			"customerGroupId",
			"companyId",
			"defaultTaxiServiceId",
			"latestTaxiServiceId",
			"mainRateId",
			"additionalRateId",

			"firstName",
			"lastName",
			"fatherName",

			"email",
			"phones",

			"gender",
			"birthday",

			"carClassId",
		]);

		const account = valueEditor.usePicker([
			"paymentAccounts",
			"paymentTransactions",
		]);

		const notesData = valueEditor.usePicker([
			"notes",
			"orderNotes",
			"executorNotes",
		]);

		const assigner = valueEditor.useAssigner();

		return (
			<TabRoot hasPaddings visible={visible}>
				<div style={{ width: "100%", height: "100%" }}>
					<Column
						sizes="auto!* 1fr"
						gaps="19px*"
						maxedWidth
						maxedHeight
					>
						<FieldsContainer
							label={
								t(
									`mainPage.customers.clients.main.personalData.title`,
								) || "Personal information"
							}
						>
							<PersonData
								ref={setPersonDataRef}
								value={picker}
								onChange={assigner}
							/>
						</FieldsContainer>

						<Account
							entityId={clientId}
							owner={PaymentProviderAccessorType.COMPANY}
							ownerId={picker.companyId}
							value={account}
							onChange={assigner}
						/>

						<FieldsContainer
							label={
								t(
									`mainPage.customers.clients.main.additionalData.title`,
								) || "Additional information"
							}
						>
							<AdditionalData
								ref={setAdditionalDataRef}
								groups={groups}
								value={picker}
								onChange={assigner}
								isCompanyEditable={
									valueEditor.get("id") != null
								}
							/>
						</FieldsContainer>

						<Notes value={notesData} onChange={assigner} />
					</Column>
				</div>
			</TabRoot>
		);
	},
	InternalController,
);

export default MainTab;
