import styled from "styled-components";

const Root = styled.button<Root.Props>`
	all: unset;

	position: absolute;

	right: 8px;
	top: 50%;

	transform: translateY(-50%);

	cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};

	pointer-events: all;
`;

declare namespace Root {
	interface Props {
		disabled?: boolean;
	}
}

export default Root;
