import { useCallback } from "react";

import { useTypedSelector } from "../redux/store";

function useCompanyIdByTaxiServiceIdDecoder() {
	const { models: taxiServices } = useTypedSelector(
		(state) => state.taxiServices,
	);

	const decoder = useCallback(
		(taxiServiceId: number) =>
			taxiServices.find((taxiService) => taxiService.id === taxiServiceId)
				?.company?.id,
		[taxiServices],
	);

	return decoder;
}

export default useCompanyIdByTaxiServiceIdDecoder;
