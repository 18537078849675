/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable no-shadow */

import React, {
	Dispatch,
	RefAttributes,
	memo,
	useCallback,
	useState,
} from "react";
import { useTranslation } from "react-i18next";
import {
	Button,
	Column,
	DatePicker,
	Icon,
	InputBorder,
	InputGroup,
	Row,
	TextBox,
	react,
	theme,
} from "uikit";
import File from "../../../../../../../../../../../../services/File";
import FieldsContainer from "../../../../../../../../../../../../components/FieldsContainer";
import LabeledField from "../../../../../../../../../../../../components/LabeledField";
import useObjectEditor from "../../../../../../../../../../../../hooks/useObjectEditor";

import useDatePickerLocale from "../../../../../../../../../../../../hooks/useDatePickerLocale";
import FileListModal from "../../../../../../../../../../../../components/FileListModal";

import TabRoot from "../TabRoot";
import InternalController from "./Controller";
import LicenseCard from "./components/LicenseCard";
import RegistrationCertificate from "./components/RegistrationCertificate";
import Insurances from "./components/Insurances";

const AdditionalDataTab = memo(
	react.withController<
		AdditionalDataTab.PropsBase,
		AdditionalDataTab.Controller
	>(({ value, disabled, visible, onChange }) => {
		const { t } = useTranslation();
		const locale = useDatePickerLocale();

		const [
			showAdditionalInsuranceFileListModal,
			setShowAdditionalInsuranceFileModal,
		] = useState(false);

		const [showWcInsuranceFileModal, setShowWcInsuranceFileModal] =
			useState(false);

		const valueEditor = useObjectEditor(value, onChange);

		const registrationCertificateValue = valueEditor.usePicker([
			"registrationCertificate",
			"registrationCertificateFiles",
		]);

		const licenseCardValue = valueEditor.usePicker([
			"licenseCard",
			"licenseCardFiles",
		]);

		const assignValue = valueEditor.useAssigner();

		const radioStationValue = valueEditor.useGetter("radioStation");
		const radioStationOnChange = valueEditor.useSetter("radioStation");

		const taximeterValue = valueEditor.useGetter("taximeter");
		const taximeterOnChange = valueEditor.useSetter("taximeter");

		const insurancesEditor = valueEditor.usePropertyEditor("insurances");

		const wcInsuranceEditor = insurancesEditor.usePropertyEditor("wc");

		const wcInsuranceSeriesValue = wcInsuranceEditor.useGetter("series");
		const wcInsuranceSeriesOnChange = wcInsuranceEditor.useSetter("series");

		const wcInsuranceNumberValue = wcInsuranceEditor.useGetter("number");
		const wcInsuranceNumberOnChange = wcInsuranceEditor.useSetter("number");

		const wcInsuranceExpirationDateValue =
			wcInsuranceEditor.useGetter("expirationDate");
		const wcInsuranceExpirationDateOnChange =
			wcInsuranceEditor.useSetter("expirationDate");

		const additionalInsurancesValue =
			insurancesEditor.useGetter("additional");
		const additionalInsurancesOnChange =
			insurancesEditor.useSetter("additional");

		const insuranceFilesValue = valueEditor.useGetter("insuranceFiles");
		const insuranceFilesOnChange = valueEditor.useSetter("insuranceFiles");

		const additionalInstanceFileListModalShowButtonOnClick =
			useCallback(() => {
				setShowAdditionalInsuranceFileModal(true);
			}, []);

		const additionalInstanceFileListModalOnSubmit = useCallback(
			(files: File.Model[]) => {
				insuranceFilesOnChange(files);

				setShowAdditionalInsuranceFileModal(false);
			},
			[insuranceFilesOnChange],
		);

		const additionalInstanceFileListModalOnClose = useCallback(() => {
			setShowAdditionalInsuranceFileModal(false);
		}, []);

		const wcInsuranceFilesValue = valueEditor.useGetter("wcInsuranceFiles");
		const wcInsuranceFilesOnChange =
			valueEditor.useSetter("wcInsuranceFiles");

		const wcInsuranceFileListModalShowButtonOnClick = useCallback(() => {
			setShowWcInsuranceFileModal(true);
		}, []);

		const wcInsuranceFileModalOnSubmit = useCallback(
			(files: File.Model[]) => {
				wcInsuranceFilesOnChange(files);

				setShowWcInsuranceFileModal(false);
			},
			[wcInsuranceFilesOnChange],
		);

		const wcInsuranceFileModalOnClose = useCallback(() => {
			setShowWcInsuranceFileModal(false);
		}, []);

		return (
			<>
				<TabRoot hasPaddings visible={visible}>
					<Column sizes="auto!*" gaps="19px*" maxedWidth maxedHeight>
						<FieldsContainer
							label={
								t(
									"pages.mainPage.pages.accounts.tabs.car.editModal.content.additionalDataTab.str0",
								) ?? ""
							}
						>
							<Row align="center" gaps="8px*" sizes="1fr*">
								<RegistrationCertificate
									value={registrationCertificateValue}
									disabled={disabled}
									onChange={assignValue}
								/>
								<LicenseCard
									value={licenseCardValue}
									disabled={disabled}
									onChange={assignValue}
								/>
								<LabeledField
									label={
										t(
											"pages.mainPage.pages.accounts.tabs.car.editModal.content.additionalDataTab.str1",
										) ?? ""
									}
								>
									<TextBox.TextBox
										value={radioStationValue}
										disabled={disabled}
										placeholder={
											t(
												"pages.mainPage.pages.accounts.tabs.car.editModal.content.additionalDataTab.str1",
											) ?? ""
										}
										onChange={radioStationOnChange}
									/>
								</LabeledField>
								<LabeledField
									label={
										t(
											"pages.mainPage.pages.accounts.tabs.car.editModal.content.additionalDataTab.str3",
										) ?? ""
									}
								>
									<TextBox.TextBox
										value={taximeterValue}
										disabled={disabled}
										placeholder={
											t(
												"pages.mainPage.pages.accounts.tabs.car.editModal.content.additionalDataTab.str3",
											) ?? ""
										}
										onChange={taximeterOnChange}
									/>
								</LabeledField>
							</Row>
						</FieldsContainer>
						<FieldsContainer
							label={
								t(
									"pages.mainPage.pages.accounts.tabs.car.editModal.content.additionalDataTab.str5",
								) ?? ""
							}
						>
							<Column gaps="19px*" maxedWidth maxedHeight>
								<LabeledField
									label={
										t(
											"pages.mainPage.pages.accounts.tabs.car.editModal.content.additionalDataTab.str6",
										) ?? ""
									}
								>
									<InputGroup.InputGroup sizes="100px! 1fr 130px! 32px!">
										<TextBox.TextBox
											value={wcInsuranceSeriesValue}
											disabled={disabled}
											placeholder={
												t(
													"pages.mainPage.pages.accounts.tabs.car.editModal.content.additionalDataTab.str7",
												) ?? ""
											}
											onChange={wcInsuranceSeriesOnChange}
										/>
										<InputBorder.InputBorder />
										<TextBox.TextBox
											value={wcInsuranceNumberValue}
											disabled={disabled}
											placeholder={
												t(
													"pages.mainPage.pages.accounts.tabs.car.editModal.content.additionalDataTab.str8",
												) ?? ""
											}
											onChange={wcInsuranceNumberOnChange}
										/>
										<InputBorder.InputBorder />
										<DatePicker
											value={
												wcInsuranceExpirationDateValue
											}
											placeholder={
												t(
													"pages.mainPage.pages.accounts.tabs.car.editModal.content.additionalDataTab.str9",
												) ?? ""
											}
											disabled={disabled}
											locale={locale}
											onChange={
												wcInsuranceExpirationDateOnChange
											}
										/>
										<Button.Button
											style={{
												borderTopLeftRadius: 0,
												borderBottomLeftRadius: 0,
											}}
											disabled={disabled}
											icon={
												<Icon
													id="copy"
													size={16}
													colors={[
														theme.colors.white,
													]}
												/>
											}
											onClick={
												wcInsuranceFileListModalShowButtonOnClick
											}
										/>
									</InputGroup.InputGroup>
								</LabeledField>
								<Insurances
									value={additionalInsurancesValue}
									disabled={disabled}
									onChange={additionalInsurancesOnChange}
									onShowFileListModal={
										additionalInstanceFileListModalShowButtonOnClick
									}
								/>
							</Column>
						</FieldsContainer>
						<div style={{ height: 5 }} />
					</Column>
				</TabRoot>
				{showAdditionalInsuranceFileListModal ? (
					<FileListModal
						value={insuranceFilesValue}
						title={
							t(
								"pages.mainPage.pages.accounts.tabs.car.editModal.content.additionalDataTab.str5",
							) ?? ""
						}
						onSubmit={additionalInstanceFileListModalOnSubmit}
						onClose={additionalInstanceFileListModalOnClose}
					/>
				) : undefined}
				{showWcInsuranceFileModal ? (
					<FileListModal
						value={wcInsuranceFilesValue}
						title={
							t(
								"pages.mainPage.pages.accounts.tabs.car.editModal.content.additionalDataTab.str6",
							) ?? ""
						}
						onSubmit={wcInsuranceFileModalOnSubmit}
						onClose={wcInsuranceFileModalOnClose}
					/>
				) : undefined}
			</>
		);
	}, InternalController),
);

declare namespace AdditionalDataTab {
	type Ref = InternalController | null;

	type Controller = InternalController;

	interface Value extends RegistrationCertificate.Value, LicenseCard.Value {
		radioStation: string;
		taximeter: string;

		insurances: {
			wc: {
				series: string;
				number: string;
				expirationDate?: Date | null;
			};
			additional: Insurances.Value;
		};

		insuranceFiles: File.Model[];
		wcInsuranceFiles: File.Model[];
	}

	interface PropsBase {
		value: Value;

		disabled: boolean;
		visible: boolean;

		onChange: Dispatch<Value>;
	}

	type Props = PropsBase & RefAttributes<Ref>;
}

export default AdditionalDataTab;
