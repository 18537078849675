import React from "react";

import { PhonesCellContent as Content } from "../components";

import base from "./base";

import { Column, ColumnId } from ".";

function phones({ width, onResize }: Column.Options) {
	return base(ColumnId.Phones, (item) => <Content item={item} />, {
		width,
		onResize: onResize as (
			columnWidth?: number | undefined,
			dataKey?: string | undefined,
		) => void,
	});
}

export default phones;
