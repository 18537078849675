import React, { Dispatch, useMemo } from "react";
import { TextBox } from "uikit";
import { useTranslation } from "react-i18next";
import LabeledField from "../../../../../../../../../../../../../../../../../components/LabeledField";
import useObjectEditor from "../../../../../../../../../../../../../../../../../hooks/useObjectEditor";
import Person from "../../../../../../../../../../../../../../../../../services/Person";

declare namespace FullName {
	type Value = Pick<Person.Model, "firstName" | "lastName" | "fatherName">;

	interface Props {
		value: Value;

		onChange: Dispatch<Value>;
	}
}

const FullName: React.FC<FullName.Props> = ({ value, onChange }) => {
	const { t } = useTranslation();

	const locale = useMemo(
		() => ({
			name:
				t(`mainPage.customers.clients.main.personalData.name`) ||
				"Name",
			surname:
				t(`mainPage.customers.clients.main.personalData.surname`) ||
				"Surname",
			fatherName:
				t(`mainPage.customers.clients.main.personalData.fatherName`) ||
				"Father name",
		}),
		[t],
	);

	const valueEditor = useObjectEditor(value, onChange);

	const name = valueEditor.useGetter("firstName");
	const setName = valueEditor.useSetter("firstName");

	const surname = valueEditor.useGetter("lastName");
	const setSurname = valueEditor.useSetter("lastName");

	const fatherName = valueEditor.useGetter("fatherName");
	const setFatherName = valueEditor.useSetter("fatherName");

	return (
		<>
			<LabeledField label={locale.surname}>
				<TextBox.TextBox
					style={{
						minHeight: "32px",
						flex: "1 0 0",
					}}
					autoComplete="one-time-code"
					placeholder={locale.surname}
					value={surname}
					onChange={setSurname}
				/>
			</LabeledField>

			<LabeledField label={locale.name}>
				<TextBox.TextBox
					style={{
						minHeight: "32px",
						flex: "1 0 0",
					}}
					autoComplete="one-time-code"
					placeholder={locale.name}
					value={name}
					onChange={setName}
				/>
			</LabeledField>

			<LabeledField label={locale.fatherName}>
				<TextBox.TextBox
					style={{
						minHeight: "32px",
						flex: "1 0 0",
					}}
					autoComplete="one-time-code"
					placeholder={locale.fatherName}
					value={fatherName}
					onChange={setFatherName}
				/>
			</LabeledField>
		</>
	);
};

export default FullName;
