/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable no-param-reassign */
/* eslint-disable no-shadow */
import React, {
	Dispatch,
	RefAttributes,
	memo,
	useCallback,
	useMemo,
	useState,
} from "react";
import { useTranslation } from "react-i18next";
import { CheckBoxWithContent, Column, react } from "uikit";
import { isEqual, isUndefined, noop } from "lodash";
import InternalController from "./Controller";
import Language from "../../../../../../../../../../../../services/Language";
import Schedule from "../../../../../../../../../../../../services/Schedule";

import useObjectEditor from "../../../../../../../../../../../../hooks/useObjectEditor";

import DeleteModal from "../../../../../../../../../../../../components/DeleteModal";
import getTimezoneOffset from "../../../../../../../../../../../../utils/getTimezoneOffset";
import mod from "../../../../../../../../../../../../utils/mod";

import TabRoot from "../TabRoot";
import Paddings from "./components/Paddings";
import ModelTable from "./components/ModelTable";
import EditModal from "./components/EditModal";

const dayDuration = 24 * 60 * 60 * 1e3;

const SchedulesTab = memo(
	react.withController<SchedulesTab.PropsBase, SchedulesTab.Controller>(
		({ value, visible, disabled, language, onChange }) => {
			const { t } = useTranslation();
			const valueEditor = useObjectEditor(value, onChange);

			const schedulesEditor = valueEditor.usePropertyEditor("schedules");

			const [selected, setSelected] = useState<number[]>([]);
			const [sort, setSort] = useState<ModelTable.Sort>({});

			const [showDeleteModal, setShowDeleteModal] = useState(false);
			const [editingItem, setEditingItem] =
				useState<EditModal.Value | null>(null);

			const isSchedulesEnabledValue =
				valueEditor.useGetter("isSchedulesEnabled");

			const isSchedulesEnabledOnChange =
				valueEditor.useSetter("isSchedulesEnabled");

			const tableCanAdd = useMemo(
				() => isSchedulesEnabledValue,
				[isSchedulesEnabledValue],
			);

			const tableCanEdit = useMemo(
				() => isSchedulesEnabledValue && selected.length === 1,
				[isSchedulesEnabledValue, selected.length],
			);

			const tableCanDelete = useMemo(
				() => isSchedulesEnabledValue && selected.length !== 0,
				[isSchedulesEnabledValue, selected.length],
			);

			const tableOnAdd = useCallback(() => {
				setEditingItem({
					startTime: mod(getTimezoneOffset(), 0, dayDuration),
					endTime: mod(
						dayDuration - 1 + getTimezoneOffset(),
						0,
						dayDuration,
					),

					monday: false,
					tuesday: false,
					wednesday: false,
					thursday: false,
					friday: false,
					saturday: false,
					sunday: false,
				});
			}, []);

			const tableOnEdit = useCallback(
				(item?: SchedulesTab.Item) => {
					if (isUndefined(item))
						item = schedulesEditor.get(selected[0]);

					setEditingItem({
						id: item.id,

						startTime: item.startTime,
						endTime: item.endTime,

						monday: item.monday,
						tuesday: item.tuesday,
						wednesday: item.wednesday,
						thursday: item.thursday,
						friday: item.friday,
						saturday: item.saturday,
						sunday: item.sunday,
					});
				},
				[schedulesEditor, selected],
			);

			const tableOnDelete = useCallback(() => {
				setShowDeleteModal(true);
			}, []);

			const editModalOnCancel = useCallback(() => {
				setEditingItem(null);
			}, []);

			const editModalOnSave = useCallback(
				async (newItem: EditModal.Value) => {
					setEditingItem(null);

					if (isEqual(editingItem, newItem)) return;

					const processedItem: SchedulesTab.Item = {
						id: newItem.id ?? Date.now(),

						startTime: newItem.startTime,
						endTime: newItem.endTime,

						monday: newItem.monday,
						tuesday: newItem.tuesday,
						wednesday: newItem.wednesday,
						thursday: newItem.thursday,
						friday: newItem.friday,
						saturday: newItem.saturday,
						sunday: newItem.sunday,
					};

					if (isUndefined(newItem.id))
						schedulesEditor.push(processedItem);
					else
						schedulesEditor.set(
							schedulesEditor.value.findIndex(
								(item) => item.id === newItem.id,
							),
							processedItem,
						);
				},
				[editingItem, schedulesEditor],
			);

			const deleteModalOnCancel = useCallback(() => {
				setShowDeleteModal(false);
			}, []);

			const deleteModalOnConfirm = useCallback(async () => {
				setShowDeleteModal(false);

				schedulesEditor.removeByValues(
					selected
						.map((scheduleId) =>
							schedulesEditor.value.find(
								(schedule) => schedule.id === scheduleId,
							),
						)
						.filter(
							(schedule) => !isUndefined(schedule),
						) as SchedulesTab.Item[],
				);

				setSelected([]);
			}, [schedulesEditor, selected]);

			return (
				<>
					<TabRoot hasPaddings={false} visible={visible}>
						<Column sizes="1fr" maxedWidth maxedHeight>
							<Paddings>
								<CheckBoxWithContent
									value={isSchedulesEnabledValue}
									// disabled={disabled}
									disabled
									gap="18px"
									onChange={isSchedulesEnabledOnChange}
								>
									{t(
										"pages.mainPage.pages.accounts.tabs.workShifts.editModal.content.schedulesTab.str150",
									) ?? ""}
								</CheckBoxWithContent>
							</Paddings>
							<ModelTable
								selected={selected}
								sort={sort}
								enabled={isSchedulesEnabledValue && !disabled}
								loading={false}
								data={schedulesEditor.value}
								language={language}
								onChangeSelected={setSelected}
								onChangeSort={setSort}
								onLoadMore={noop}
								canAdd={tableCanAdd}
								canEdit={tableCanEdit}
								canDelete={tableCanDelete}
								onAdd={tableOnAdd}
								onEdit={tableOnEdit}
								onDelete={tableOnDelete}
							/>
						</Column>
					</TabRoot>
					{editingItem && (
						<EditModal
							value={editingItem}
							onCancel={editModalOnCancel}
							onSave={editModalOnSave}
						/>
					)}
					{showDeleteModal && (
						<DeleteModal
							onCancel={deleteModalOnCancel}
							onConfirm={deleteModalOnConfirm}
						/>
					)}
				</>
			);
		},
		InternalController,
	),
);

declare namespace SchedulesTab {
	type Ref = InternalController | null;

	type Controller = InternalController;

	type Day = Schedule.Model.Day;

	interface Item extends Record<Day, boolean> {
		id: number;

		startTime: number;
		endTime: number;
	}

	interface Value {
		isSchedulesEnabled: boolean;
		schedules: Item[];
	}

	interface PropsBase {
		value: Value;

		disabled: boolean;
		visible: boolean;
		language: Language;

		onChange: Dispatch<Value>;
	}

	type Props = PropsBase & RefAttributes<Ref>;
}

export default SchedulesTab;
