import { react } from "uikit";
import { RefObject } from "react";

import Main from "./tabs/Main";

interface Context {
	mainRef: RefObject<Main.Ref>;
}

export default class Controller extends react.ControllerBase<Context> {
	validate() {
		const isValidMain = !!this.context?.mainRef.current?.validate();

		return isValidMain;
	}
}
