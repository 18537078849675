import { TFunction } from "i18next";

import CheckBoxSelect from "../../../../../../../../../../../../../../components/CheckBoxSelect";

const getOptions = (
	t: TFunction,
): CheckBoxSelect.Option<
	"cash" | "non-cash" | "card" | "bonuses" | "agents"
>[] => [
	{
		key: "cash",
		label:
			t(
				"pages.mainPage.pages.accounts.tabs.executors.editModal.content.optionTab.orderPaymentTypes.cash",
			) ?? "",
	},
	{
		key: "non-cash",
		label:
			t(
				"pages.mainPage.pages.accounts.tabs.executors.editModal.content.optionTab.orderPaymentTypes.nonCash",
			) ?? "",
	},
	{
		key: "card",
		label:
			t(
				"pages.mainPage.pages.accounts.tabs.executors.editModal.content.optionTab.orderPaymentTypes.card",
			) ?? "",
	},
	{
		key: "bonuses",
		label:
			t(
				"pages.mainPage.pages.accounts.tabs.executors.editModal.content.optionTab.orderPaymentTypes.bonuses",
			) ?? "",
	},
	{
		key: "agents",
		label:
			t(
				"pages.mainPage.pages.accounts.tabs.executors.editModal.content.optionTab.orderPaymentTypes.agents",
			) ?? "",
	},
];

export type OptionsKey = ReturnType<typeof getOptions>[number]["key"];

export { getOptions };
