import React, { useMemo } from "react";
import moment from "moment";

import { Dispatcher } from "../../../../../../../../../../../../services";
import { CellContentRoot } from "../../../../../../../../../../../../components/LightTable";

const BirthAtCellContent: React.FC<BirthAtCellContent.Props> = ({ item }) => {
	const content = useMemo(
		() => (item.birthAt ? moment(item.birthAt).format("DD.MM.YYYY") : ""),
		[item?.birthAt],
	);

	return (
		<CellContentRoot alignItems="center" w="100%" h="100%">
			{content}
		</CellContentRoot>
	);
};

declare namespace BirthAtCellContent {
	interface Props {
		item: Dispatcher.Model;
	}
}

export default BirthAtCellContent;
