/* eslint-disable no-shadow */

import React, {
	Dispatch,
	RefAttributes,
	memo,
	useCallback,
	useMemo,
	useState,
} from "react";
import { Column, Row, react, useRefWithSetter } from "uikit";
import { isUndefined } from "lodash";

import Language from "../../../../../../../../../../../../../../services/Language";
import useObjectEditor from "../../../../../../../../../../../../../../hooks/useObjectEditor";
import useCompanyIdByTaxiServiceIdDecoder from "../../../../../../../../../../../../../../hooks/useCompanyIdByTaxiServiceIdDecoder";
import { useExecutorContext } from "../../../../../../context";

import InternalController from "./Controller";
import RoleIds from "./components/RoleIds";
import ExecutorTeamId from "./components/ExecutorTeamId";
import CompanyId from "./components/CompanyId";
import TaxiServiceId from "./components/TaxiServiceId";
import ResponsibleDispatcherId from "./components/ResponsibleDispatcherId";
import WorkShiftId from "./components/WorkShiftId";
import ExecutorRateId from "./components/ExecutorRateId";
import Alias from "./components/Alias";

const WorkDataBase = react.withController<
	WorkData.PropsBase,
	WorkData.Controller
>(
	({
		controller,
		value,
		disabled,
		language,

		originalTaxiServiceId,
		originalAlias,

		onChange,
	}) => {
		const { taxiServices } = useExecutorContext();

		const [aliasRef, setAliasRef] = useRefWithSetter<Alias.Ref | null>(
			null,
		);
		const [roleIdsRef, setRoleIdsRef] =
			useRefWithSetter<RoleIds.Ref | null>(null);
		const [responsibleDispatcherIdRef, setResponsibleDispatcherIdRef] =
			useRefWithSetter<ResponsibleDispatcherId.Ref | null>(null);

		const decodeCompanyIdByTaxiServiceId =
			useCompanyIdByTaxiServiceIdDecoder();

		const [taxiServiceIdError, setTaxiServiceIdError] = useState(false);

		const valueEditor = useObjectEditor(value, onChange);

		const setCarIds = valueEditor.useSetter("carIds");
		const aliasValue = valueEditor.useGetter("alias");
		const aliasOnChange = valueEditor.useSetter("alias");

		const taxiServiceIdValue = valueEditor.useGetter("taxiServiceId");
		const setTaxiServiceIdValue = valueEditor.useSetter("taxiServiceId");

		const companyIdByTaxiServiceId = useMemo(
			() =>
				typeof taxiServiceIdValue === "number"
					? decodeCompanyIdByTaxiServiceId(taxiServiceIdValue)
					: undefined,
			[decodeCompanyIdByTaxiServiceId, taxiServiceIdValue],
		);

		const [companyIdValue, setCompanyIdValue] = useState(
			companyIdByTaxiServiceId,
		);

		const taxiServiceIdOnChange = useCallback(
			(newTaxiServiceId?: number) => {
				setTaxiServiceIdError(false);
				setTaxiServiceIdValue(newTaxiServiceId);
				setCarIds([]);
				if (isUndefined(newTaxiServiceId)) return;

				setCompanyIdValue(
					decodeCompanyIdByTaxiServiceId(newTaxiServiceId),
				);
			},
			[
				decodeCompanyIdByTaxiServiceId,
				setCompanyIdValue,
				setTaxiServiceIdValue,
				setCarIds,
			],
		);

		const companyIdOnChange = useCallback(
			(newCompanyId: number) => {
				setCompanyIdValue(newCompanyId);

				if (
					taxiServices.find(
						(taxiService) => taxiService.id === taxiServiceIdValue,
					)?.company?.id !== newCompanyId &&
					taxiServiceIdValue !== undefined
				)
					taxiServiceIdOnChange(undefined);
			},
			[
				setCompanyIdValue,
				taxiServiceIdOnChange,
				taxiServiceIdValue,
				taxiServices,
			],
		);

		const roleIdsValue = valueEditor.useGetter("roleIds");
		const roleIdsOnChange = valueEditor.useSetter("roleIds");

		const responsibleDispatcherIdValue = valueEditor.useGetter(
			"responsibleDispatcherId",
		);
		const responsibleDispatcherIdOnChange = valueEditor.useSetter(
			"responsibleDispatcherId",
		);

		const workShiftIdValue = valueEditor.useGetter("workShiftId");
		const workShiftIdOnChange = valueEditor.useSetter("workShiftId");

		const executorTeamIdValue = valueEditor.useGetter("executorTeamId");
		const executorTeamIdOnChange = valueEditor.useSetter("executorTeamId");

		const executorRateIdValue = valueEditor.useGetter("executorRateId");
		const executorRateIdOnChange = valueEditor.useSetter("executorRateId");

		controller.setContext({
			value,

			setTaxiServiceIdError,

			aliasRef,
			roleIdsRef,
			responsibleDispatcherIdRef,
		});

		return (
			<Column gaps="8px*">
				<Row gaps="8px*" sizes="1fr*">
					<CompanyId
						value={companyIdValue ?? companyIdByTaxiServiceId}
						disabled={disabled}
						error={
							isUndefined(companyIdValue) && taxiServiceIdError
						}
						language={language}
						onChange={companyIdOnChange}
					/>
					<TaxiServiceId
						value={taxiServiceIdValue}
						disabled={disabled}
						error={taxiServiceIdError}
						language={language}
						companyId={companyIdValue ?? companyIdByTaxiServiceId}
						onChange={taxiServiceIdOnChange}
						executorRateIdValue={executorRateIdValue}
						executorRateIdOnChange={executorRateIdOnChange}
					/>
					<Alias
						ref={setAliasRef}
						value={aliasValue}
						originalTaxiServiceId={originalTaxiServiceId}
						taxiServiceId={value.taxiServiceId}
						originalValue={originalAlias}
						currentExecutorId={value.id}
						disabled={disabled}
						onChange={aliasOnChange}
					/>
					<RoleIds
						ref={setRoleIdsRef}
						value={roleIdsValue}
						disabled={disabled}
						taxiServiceIds={[]}
						onChange={roleIdsOnChange}
					/>
				</Row>
				<Row gaps="8px*" sizes="1fr*">
					<ResponsibleDispatcherId
						ref={setResponsibleDispatcherIdRef}
						value={responsibleDispatcherIdValue}
						disabled={disabled}
						taxiServiceId={taxiServiceIdValue}
						onChange={responsibleDispatcherIdOnChange}
					/>
					<WorkShiftId
						value={workShiftIdValue}
						disabled={disabled}
						onChange={workShiftIdOnChange}
					/>
					<ExecutorTeamId
						value={executorTeamIdValue}
						language={language}
						disabled={disabled}
						taxiServiceIds={[]}
						onChange={executorTeamIdOnChange}
					/>
					<ExecutorRateId
						value={executorRateIdValue}
						disabled={disabled}
						onChange={executorRateIdOnChange}
						taxiServiceId={taxiServiceIdValue}
					/>
				</Row>
			</Column>
		);
	},
	InternalController,
);

const WorkData = memo(WorkDataBase);

declare namespace WorkData {
	type Ref = InternalController | null;

	type Controller = InternalController;

	interface Value {
		id?: number;

		alias: string;
		taxiServiceId?: TaxiServiceId.Value;
		roleIds: RoleIds.Value;
		responsibleDispatcherId?: ResponsibleDispatcherId.Value;
		workShiftId?: WorkShiftId.Value;
		executorTeamId?: ExecutorTeamId.Value;
		executorRateId?: ExecutorRateId.Value;
		carIds: number[];
	}

	interface PropsBase {
		value: Value;

		disabled: boolean;
		language: Language;
		originalTaxiServiceId?: number;
		originalAlias?: string;

		onChange: Dispatch<Value>;
	}

	type Props = PropsBase & RefAttributes<Ref>;
}

export default WorkData;
