import React, { memo, useMemo } from "react";

import { PaymentAccount } from "../../../../../../../../../../types/PaymentAccount";
import formatNumber from "../../../../../../../../../../utils/formatNumber";

const BalanceOutput: React.FC<BalanceOutput.Props> = memo(
	({ value, balanceType }) => {
		const balance = useMemo(
			() => value.find((a) => a.type === balanceType),
			[balanceType, value],
		);

		return <div>{formatNumber(balance?.amount ?? 0)}</div>;
	},
);

declare namespace BalanceOutput {
	interface Props {
		value: PaymentAccount[];
		balanceType: PaymentAccount["type"];
	}
}

export default BalanceOutput;
