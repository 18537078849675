import React, {
	Dispatch,
	RefAttributes,
	SetStateAction,
	memo,
	useCallback,
	useEffect,
	useState,
} from "react";
import { useTranslation } from "react-i18next";
import { Option, TextSelect, react } from "uikit";
import { DebouncedState } from "use-debounce/lib/useDebouncedCallback";
import Map from "../../../../../../../../../../../../../../../../../../services/Map";
import LabeledField from "../../../../../../../../../../../../../../../../../../components/LabeledField";
import { useTypedSelector } from "../../../../../../../../../../../../../../../../../../redux/store";
import { settlementToString } from "../../../../../../../../../../../../../../../../../../utils/settlementToString";
import Controller from "./Controller";

type Options = Option<Map.Search.Object>[];

const SearchSettlementBase = react.withController<
	SearchSettlement.PropsBase,
	Controller
>((props) => {
	const { t } = useTranslation();
	const {
		settlement,
		setSettlement,
		setStreetFocused,
		debouncedSearchRequest,
		controller,
	} = props;

	const { minSearchQueryLength } = useTypedSelector((s) => s.settings.map);

	const [error, setError] = useState(false);
	const [settlementQuery, setSettlementQuery] = useState("");
	const [settlementOptions, setSettlementOptions] = useState<Options>([]);

	useEffect(() => {
		if (settlement) setSettlementQuery(settlementToString(settlement));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleSettlementQueryChange = useCallback(
		(query: string) => {
			setSettlementQuery(query);
			if (query.trim().length >= minSearchQueryLength) {
				debouncedSearchRequest(
					query,
					"settlement",
					setSettlementOptions,
				);
			}
		},
		[debouncedSearchRequest, minSearchQueryLength],
	);
	const handleSettlementSelect = useCallback(
		(newSettlement: Option<Map.Search.Object>) => {
			setError(false);
			setSettlement(newSettlement.value);
			setStreetFocused(true);
		},
		[setSettlement, setStreetFocused],
	);

	controller.setContext({ value: settlement, setError });

	return (
		<LabeledField
			label={
				t(
					"pages.mainPage.pages.customers.tabs.agents.modal.content.tabs.addresses.modal.content.searchSettlement.str0",
				) ?? ""
			}
		>
			<TextSelect
				placeholder={
					t(
						"pages.mainPage.pages.customers.tabs.agents.modal.content.tabs.addresses.modal.content.searchSettlement.str1",
					) ?? ""
				}
				error={error}
				value={settlementQuery}
				onChange={handleSettlementQueryChange}
				options={settlementOptions}
				onSelect={handleSettlementSelect}
			/>
		</LabeledField>
	);
}, Controller);

const SearchSettlement = memo(SearchSettlementBase);

declare namespace SearchSettlement {
	type Ref = Controller | null;

	interface PropsBase {
		settlement: Value;
		setSettlement: Dispatch<Value>;
		setStreetFocused: Dispatch<SetStateAction<boolean>>;
		debouncedSearchRequest: DebouncedState<
			(
				query: string,
				type: Map.Search.Type,
				setTextSelectOptions: Dispatch<SetStateAction<Options>>,
			) => Promise<void>
		>;
	}
	type Props = PropsBase & RefAttributes<Ref>;

	type Value = Map.Search.Object | null;
}

export default SearchSettlement;
