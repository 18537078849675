import React, { Dispatch } from "react";
import "rsuite/dist/rsuite.min.css";

import { Role } from "../../../../../../../../services";
import { Language } from "../../../../../../../../assets/languages/langs";

import ModelTable from "./components/ModelTable";
import Root from "./components/Root";

const Content: React.FC<Content.Props> = ({
	loading,
	language,
	data,
	selected,
	sort,

	onChangeSelected,
	onChangeSort,

	onEdit,
	onLoadMore,
}) => (
	<Root sizes="1fr">
		<ModelTable
			selected={selected}
			sort={sort}
			loading={loading}
			data={data}
			language={language}
			onChangeSelected={onChangeSelected}
			onChangeSort={onChangeSort}
			onEdit={onEdit}
			onLoadMore={onLoadMore}
		/>
	</Root>
);

declare namespace Content {
	type Sort = ModelTable.Sort;

	interface Props {
		selected: number[];
		sort: Sort;

		data: Role.Model[];

		loading: boolean;
		language: Language;

		onChangeSelected: Dispatch<number[]>;
		onChangeSort: Dispatch<Sort>;

		onEdit: Dispatch<number>;
		onLoadMore: () => void;
	}
}

export default Content;
