import React, { Dispatch, useMemo } from "react";
import { Column } from "uikit";
import Root from "./components/Root";
import Item from "./components/Item";
import VirtualList from "../../../../../VirtualList";
import Placeholder from "./components/Placeholder";
import Loader from "../../../../../Loader";

type Key = string | number;
export interface Option<T> {
	key: Key;
	label: string;
	value: T;
}

// eslint-disable-next-line prettier/prettier
function List<OptionValue>({
	value,
	options,
	loading,
	onChange,
	onChangeViewBox,
}: List.Props<OptionValue>) {
	const placeholder = useMemo(() => <Placeholder />, []);

	const cachedItems = useMemo(
		() =>
			options.map((option) =>
				option ? (
					// eslint-disable-next-line react/jsx-key
					<Item
						value={value === option.key}
						label={option.label}
						onChange={(itemValue) => {
							if (itemValue) onChange(option.key);
							else onChange(undefined);
						}}
					/>
				) : (
					placeholder
				),
			),
		[onChange, options, placeholder, value],
	);

	return (
		<Root align="stretch" sizes="1fr">
			<Loader active={loading} dimmed>
				<Column style={{ height: "100%" }} sizes="1fr">
					<VirtualList
						totalItems={options.length}
						itemHeight={54}
						onChangeViewBox={onChangeViewBox}
					>
						{(index) => cachedItems[index]}
					</VirtualList>
				</Column>
			</Loader>
		</Root>
	);
}

declare namespace List {
	type Value = Key | undefined;

	interface Props<OptionValue> {
		value: Value;

		options: (Option<OptionValue> | null)[];
		loading: boolean;

		onChange: Dispatch<Value>;
		onChangeViewBox: (offset: number, count: number) => void;
	}
}

export default List;
