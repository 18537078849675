/* eslint-disable no-shadow */
import Base from "../Base";

class Code extends Base {
	public static async getInfo(value: string) {
		try {
			const res = await this.request((prpc) =>
				prpc.theirsModel.code.getCodeInfo(value),
			);
			return res;
		} catch (err) {
			console.error(err);
			return false;
		}
	}
}

declare namespace Code {}

export default Code;
