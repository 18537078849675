/* eslint-disable import/no-unresolved */
/* eslint-disable no-shadow */
import React, {
	RefAttributes,
	useCallback,
	useEffect,
	useLayoutEffect,
	useMemo,
	useState,
} from "react";
import { useTranslation } from "react-i18next";
import { isEqual } from "lodash";
import { react, useRefWithSetter } from "uikit";

import Card from "../../../../../../../../services/Card";
import Dispatcher from "../../../../../../../../services/Dispatcher";
import Map from "../../../../../../../../redux/services/Map";
import EditModalBase from "../../../../../../../../components/EditModal";
import WithoutNullableKeys from "../../../../../../../../types/WithoutNullableKeys";
import useObjectEditor from "../../../../../../../../hooks/useObjectEditor";

import InternalController from "./Controller";
import Root from "./components/Root";
import Header from "./components/Header";
import Content from "./components/Content";
import Footer from "./components/Footer";

const EditModal = react.withController<
	EditModal.PropsBase,
	EditModal.Controller
>(
	({
		controller,
		value,
		language,
		onCancel,
		onSave,
		executorTaxiServiceId,
	}) => {
		const [contentRef, setContentRef] =
			useRefWithSetter<Content.Ref | null>(null);
		const { t } = useTranslation();

		// eslint-disable-next-line react-hooks/exhaustive-deps
		const originalTaxiServiceId = useMemo(() => value.taxiServiceId, []);
		const allowedTaxiServiceId = useMemo(
			() => executorTaxiServiceId,
			[executorTaxiServiceId],
		);

		// eslint-disable-next-line react-hooks/exhaustive-deps
		const originalParkNumber = useMemo(() => value.parkNumber, []);

		const currentId = useMemo(() => value.id, [value.id]);

		// const cardIds = useMemo(
		// 	() => (typeof value.id === "number" ? [value.id] : []),
		// 	[value.id],
		// );

		// useKeepCardsOpen(cardIds, Car.Card);

		// const cardSession = useCardSubscriber(Car.Card);

		const currentCard = null as Card.Session.Card | null;
		// useMemo<Card.Session.Card | null>(
		// 	() =>
		// 		cardSession?.cards.find((card) => cardIds.includes(card.id)) ??
		// 		null,
		// 	[cardIds, cardSession?.cards],
		// );

		const currentCardLocked = useMemo(
			() => currentCard?.locked === true,
			[currentCard?.locked],
		);

		const [history, setHistory] = useState<Dispatcher.History>([]);

		useEffect(() => {
			(async () => {
				// if (value.id) setHistory(await Dispatcher.getHistory(value.id));
			})();
		}, [value.id]);

		controller.setContext({ contentRef });

		const [tab, setTab] = useState<Header.Tab>("main");
		const [internalValue, setInternalValue] = useState(value);

		const valueEditor = useObjectEditor(internalValue, setInternalValue);

		const setTaxiServiceId = valueEditor.useSetter("taxiServiceId");
		const carClassOnChange = valueEditor.useSetter("carClass");
		const carClassIdOnChange = valueEditor.useSetter("carClassId");
		const serviceIdsOnChange = valueEditor.useSetter("serviceIds");
		const distributableCarClassOnChange = valueEditor.useSetter(
			"distributableCarClass",
		);
		const distributableCarClassIdsOnChange = valueEditor.useSetter(
			"distributableCarClassIds",
		);
		const broadcastableCarClassOnChange = valueEditor.useSetter(
			"broadcastableCarClass",
		);
		const broadcastableCarClassIdsOnChange = valueEditor.useSetter(
			"broadcastableCarClassIds",
		);

		useLayoutEffect(() => {
			if (!value?.id && executorTaxiServiceId) {
				setTaxiServiceId(executorTaxiServiceId);
			}
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [value?.id, executorTaxiServiceId]);

		useLayoutEffect(() => {
			const { carClassId } = value;
			if (carClassId !== internalValue.carClassId) {
				carClassIdOnChange(carClassId);
			}
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [value?.carClass, carClassIdOnChange]);

		useLayoutEffect(() => {
			const { carClass } = value;
			if (carClass && isEqual(carClass?.id, internalValue.carClass?.id)) {
				carClassOnChange(carClass);
			}
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [value?.carClass, carClassOnChange]);

		useLayoutEffect(() => {
			const { carClass, serviceIds } = value;

			if (carClass && isEqual(carClass?.id, internalValue.carClass?.id)) {
				serviceIdsOnChange(serviceIds);
			}
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [value?.carClass, serviceIdsOnChange]);

		useLayoutEffect(() => {
			const { carClass, distributableCarClass } = value;

			if (carClass && isEqual(carClass?.id, internalValue.carClass?.id)) {
				distributableCarClassOnChange(distributableCarClass);
			}
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [value?.carClass, distributableCarClassOnChange]);

		useLayoutEffect(() => {
			const { carClass, distributableCarClassIds } = value;

			if (carClass && isEqual(carClass?.id, internalValue.carClass?.id)) {
				distributableCarClassIdsOnChange(distributableCarClassIds);
			}
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [value?.carClass, distributableCarClassIdsOnChange]);

		useLayoutEffect(() => {
			const { carClass, broadcastableCarClass } = value;

			if (carClass && isEqual(carClass?.id, internalValue.carClass?.id)) {
				broadcastableCarClassOnChange(broadcastableCarClass);
			}
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [value?.carClass, broadcastableCarClassOnChange]);

		useLayoutEffect(() => {
			const { carClass, broadcastableCarClassIds } = value;

			if (carClass && isEqual(carClass?.id, internalValue.carClass?.id)) {
				broadcastableCarClassIdsOnChange(broadcastableCarClassIds);
			}
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [value?.carClass, broadcastableCarClassIdsOnChange]);

		const fullName = useMemo(
			() => internalValue.name ?? "",
			[internalValue.name],
		);

		const headerTitle = useMemo(
			() =>
				typeof value.id === "number"
					? fullName
					: t(
							"pages.mainPage.pages.accounts.tabs.car.editModal.str200",
					  ) ?? "",
			[fullName, value.id, t],
		);

		const headerLockedBy = useMemo(
			() =>
				currentCard?.locked
					? [
							currentCard.lockedBy.person?.lastName ?? "",
							currentCard.lockedBy.person?.firstName ?? "",
							currentCard.lockedBy.person?.fatherName ?? "",
					  ]
							.join(" ")
							.trim()
					: undefined,
			[currentCard?.locked, currentCard?.lockedBy],
		);

		const assignValue = valueEditor.useAssigner();

		const modalContent = useMemo(
			() => (
				<Root sizes="auto! 1fr">
					<Header
						tab={tab}
						title={headerTitle}
						lockedBy={headerLockedBy}
						createdAt={value.createdAt}
						onChangeTab={setTab}
					/>
					<Content
						ref={setContentRef}
						value={internalValue}
						type={tab}
						disabled={currentCardLocked}
						language={language}
						originalTaxiServiceId={originalTaxiServiceId}
						originalParkNumber={originalParkNumber}
						allowedTaxiServiceId={allowedTaxiServiceId}
						currentId={currentId}
						history={history}
						onChange={assignValue}
					/>
				</Root>
			),
			[
				assignValue,
				currentCardLocked,
				currentId,
				headerLockedBy,
				headerTitle,
				history,
				internalValue,
				language,
				originalParkNumber,
				originalTaxiServiceId,
				setContentRef,
				tab,
				value.createdAt,
				allowedTaxiServiceId,
			],
		);

		const footerValue = valueEditor.usePicker(["active"]);

		const modalFooter = useMemo(
			() => (
				<Footer
					value={footerValue}
					disabled={currentCard?.locked === true}
					onChange={assignValue}
				/>
			),
			[footerValue, currentCard?.locked, assignValue],
		);

		const modalOnSave = useCallback(async () => {
			if (!(await contentRef.current?.validate())) return;

			onSave(valueEditor.value as EditModal.Value.Validated);
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [contentRef, onSave]);

		return (
			<EditModalBase
				footer={modalFooter}
				canSave={!currentCardLocked}
				onCancel={onCancel}
				onSave={modalOnSave}
			>
				{modalContent}
			</EditModalBase>
		);
	},
	InternalController,
);

declare namespace EditModal {
	type Ref = InternalController | null;

	type Controller = InternalController;

	interface Value extends Footer.Value, Content.Value {
		id?: number;
		name?: string;
		createdAt?: string;
	}

	namespace Value {
		type ValidationPropertyNames =
			| "parkNumber"
			| "carModelId"
			| "taxiServiceId"
			| "responsibleDispatcherId";

		type Validated = Omit<Value, ValidationPropertyNames> &
			WithoutNullableKeys<Required<Pick<Value, ValidationPropertyNames>>>;
	}

	interface PropsBase {
		value: Value;
		language: Map.Language;
		executorTaxiServiceId?: number;
		onCancel: () => void;
		onSave: (value: Value.Validated) => void;
	}

	type Props = PropsBase & RefAttributes<Ref>;
}

export default EditModal;
