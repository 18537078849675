/* eslint-disable no-shadow */

import React, { Dispatch, useCallback, useMemo } from "react";
import { useInternal } from "uikit";
import Draggable from "react-draggable";

import Modal from "../Modal";

import Header from "./components/Header";
import Root from "./components/Root";
import Content from "./components/Content";
import Footer from "./components/Footer";

type Key = string | number;
interface Option<T> {
	key: Key;
	label: string;
	value: T;
}

function SelectModal2<OptionValue>({
	value,
	filters,

	title,
	options,
	loading = false,

	onChange,
	onChangeFilters,

	onClose,
	onSubmit,
}: SelectModal2.Props<OptionValue>) {
	const [internalValue, setInternalValue] = useInternal(value);

	const internalFilters = useMemo(() => filters ?? {}, [filters]);

	const headerOnClose = useCallback(() => {
		onClose?.();
	}, [onClose]);

	const contentOnChange = useCallback(
		(contentValue: Content.Value) => {
			setInternalValue(contentValue);
			onChange?.(contentValue);
		},
		[onChange, setInternalValue],
	);

	const footerOnCancel = useCallback(() => {
		onClose?.();
	}, [onClose]);

	const footerOnSave = useCallback(() => {
		onSubmit?.(internalValue);
	}, [internalValue, onSubmit]);

	const content = useMemo(
		() => (
			<Root sizes="auto! 1fr auto!">
				<Header title={title} onClose={headerOnClose} />
				<Content
					value={internalValue}
					filters={internalFilters}
					options={options ?? []}
					loading={loading}
					onChange={contentOnChange}
					onChangeFilters={onChangeFilters}
				/>
				<Footer onCancel={footerOnCancel} onSave={footerOnSave} />
			</Root>
		),
		[
			contentOnChange,
			footerOnCancel,
			footerOnSave,
			headerOnClose,
			internalFilters,
			internalValue,
			loading,
			onChangeFilters,
			options,
			title,
		],
	);

	return (
		<Modal dimmed>
			<Draggable handle=".draggable-area">{content}</Draggable>
		</Modal>
	);
}

declare namespace SelectModal2 {
	type Value = Key | undefined;
	type Filters = Content.Filters;

	interface Props<OptionValue> {
		value?: Value;
		filters?: Filters;

		title?: string;
		options?: (Option<OptionValue> | null)[];
		loading?: boolean;

		onChange?: Dispatch<Value>;
		onChangeFilters: Dispatch<Filters>;

		onClose?: () => void;
		onSubmit?: (value: Value) => void;
	}
}

export default SelectModal2;
