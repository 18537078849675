import React, {
	Dispatch,
	SetStateAction,
	useCallback,
	useLayoutEffect,
	useMemo,
} from "react";
import { CheckBox } from "uikit";
import { RowDataType } from "rsuite-table";

import {
	useColumns,
	useVisibleColumns,
} from "../../../../../../../../../../../../../../../../../../../../hooks/useTableSettings";
import Counterparty from "../../../../../../../../../../../../../../../../../../../../services/Counterparty";
import LightTable, {
	useTableOptions,
} from "../../../../../../../../../../../../../../../../../../../../components/LightTable";
import TableSettings from "../../../../../../../../../../../../../../../../../../../../components/TableSettings";
import {
	StyledGrid,
	StyledGridItem,
} from "../../../../../../../../../../../../../../../../../../../../components/common";
import BalanceOutput from "../../../../../../../../../../../../../Content/components/BalanceOutput";

const Table: React.FC<Table.Props> = ({ tableData, selected, setSelected }) => {
	const { sort, onSort, onScroll, tableRef, lang, setDataLength } =
		useTableOptions({
			itemLimit: 2,
		});

	useLayoutEffect(() => {
		setDataLength(tableData.length);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [tableData.length]);

	const columns = useColumns("counterparty");
	const { columnIds, setColumnIds } = useVisibleColumns(
		"customers.agent.selectCounterpartyTable",
		"counterparty",
	);

	const updateSelected = useCallback(
		(item: RowDataType<Counterparty.Model>) => {
			setSelected((prev) => (prev === item.id ? null : item.id));
		},
		[setSelected],
	);

	const data = useMemo(
		() =>
			columnIds.map((columnId) => {
				const column = columns.find((col) => col.id === columnId);
				if (!column) return null;

				return (
					<LightTable.Column
						resizable
						sortable
						key={columnId}
						verticalAlign="middle"
						flexGrow={1}
					>
						<LightTable.HeaderCell style={{ padding: "0 20px" }}>
							{column.label}
						</LightTable.HeaderCell>
						<LightTable.Cell
							verticalAlign="middle"
							style={{ padding: "0 20px" }}
							dataKey={column.id}
							fullText
						>
							{(rowData: RowDataType<Counterparty.Model>) => {
								const model = rowData as Counterparty.Model;

								switch (columnId) {
									case "name":
										return model.additionalFields?.name;

									case "edrpou":
										return model.additionalFields?.edrpou;

									case "overallBalance":
										return (
											<BalanceOutput
												balanceType="main"
												value={model.paymentAccounts}
											/>
										);

									case "disconnectionThreshold":
										return (
											<BalanceOutput
												balanceType="threshold"
												value={model?.paymentAccounts}
											/>
										);

									case "overallBonusBalance":
										return (
											<BalanceOutput
												balanceType="bonus"
												value={model.paymentAccounts}
											/>
										);

									case "orderCount":
										return (
											<>{model.rideCounter?.total ?? 0}</>
										);

									case "contactPerson":
										return (
											<>
												{model.additionalFields
													?.contactName || ""}
											</>
										);

									case "phones":
										return model.additionalFields?.phones.join(
											", ",
										);

									case "email":
										return (
											model.additionalFields?.email || ""
										);

									case "company":
										return (
											model.company?.name?.[lang] || ""
										);

									case "registrationBranch":
										return model?.defaultTaxiService
											?.settlement[lang];

									case "registrationDate":
										return new Date(
											model.createdAt,
										).toLocaleDateString();

									case "firstTripDate":
										return <></>;

									case "lastTripDate":
										return <></>;

									case "mobileApp":
										return (
											<div
												style={{
													display: "flex",
													justifyContent: "center",
												}}
											>
												<CheckBox
													disabled
													value={
														model.accessAllEmployeeToPersonalApp
													}
												/>
											</div>
										);

									case "corporateAccount":
										return (
											<div
												style={{
													display: "flex",
													justifyContent: "center",
												}}
											>
												<CheckBox
													disabled
													value={
														model.isAccessToCorporateAccount
													}
												/>
											</div>
										);

									case "notes":
										return model.additionalFields?.notes;

									// case "status":
									// 	return t(`statuses.${model.status}`);

									default:
										return null;
								}
							}}
						</LightTable.Cell>
					</LightTable.Column>
				);
			}),
		[columnIds, columns, lang],
	);

	const getRowClassName = useCallback(
		(item: RowDataType<Counterparty.Model>) =>
			item && selected === item.id ? "selected" : "",
		[selected],
	);

	const areas = useMemo(() => `'table' 'settings' `, []);
	return (
		<StyledGrid
			areas={areas}
			rows="1fr 32px"
			w={{ min: "1000px" }}
			h={{
				min: "500px",
				max: "600px",
			}}
			br="5px"
			over="hidden"
		>
			<StyledGridItem area="table" w="100%" h="100%" tabIndex={-1}>
				<LightTable
					ref={tableRef}
					data={tableData}
					virtualized
					fillHeight
					rowHeight={44}
					shouldUpdateScroll={false}
					rowClassName={getRowClassName}
					onRowClick={updateSelected}
					sortColumn={sort?.dataKey}
					sortType={sort?.sortType}
					onScroll={onScroll}
					onSortColumn={onSort}
				>
					{data}
				</LightTable>
			</StyledGridItem>
			<StyledGridItem area="settings" w="100%" h="100%" zi="1">
				<TableSettings
					value={columnIds}
					defaultValue={columnIds}
					columns={columns}
					onChange={setColumnIds}
				/>
			</StyledGridItem>
		</StyledGrid>
	);
};

declare namespace Table {
	interface Props {
		tableData: Counterparty.Model[];
		selected: number | null;
		setSelected: Dispatch<SetStateAction<number | null>>;
	}
}

export default Table;
