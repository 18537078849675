/* eslint-disable no-shadow */
import React, { Dispatch, memo, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import {
	Button,
	Column,
	DatePicker,
	Icon,
	Row,
	Stepper,
	TextBox,
	theme,
} from "uikit";
import { extend, isUndefined } from "lodash";

import File from "../../../../../../../../../../../../../../services/File";
import useObjectEditor from "../../../../../../../../../../../../../../hooks/useObjectEditor";
import useDatePickerLocale from "../../../../../../../../../../../../../../hooks/useDatePickerLocale";
import Nullable from "../../../../../../../../../../../../../../types/Nullable";
import FileListModal from "../../../../../../../../../../../../../../components/FileListModal";
import Optional from "../../../../../../../../../../../../../../types/Optional";
import FieldsContainer from "../../../../../../../../../../../../../../components/FieldsContainer";
import LabeledField from "../../../../../../../../../../../../../../components/LabeledField";

const property: unknown = null;

namespace DrivingLicenseValue {
	export const properties = {
		drivingLicenseSeries: property as string,
		drivingLicenseNumber: property as string,
		drivingLicenseCategory: property as string,
		drivingLicenseIssuedAt: property as Date | undefined,
		drivingExperience: property as number | undefined,
		drivingLicenseFiles: property as File.Model[],
	};

	export const propertyNames = Object.keys(
		properties,
	) as (keyof typeof properties)[];
}

const DrivingLicenseBase = memo(
	({ value, disabled, onChange }: DrivingLicense.Props) => {
		const { t } = useTranslation();
		const locale = useDatePickerLocale();

		const valueEditor = useObjectEditor(value, onChange);

		const drivingLicenseSeriesValue = valueEditor.useGetter(
			"drivingLicenseSeries",
		);
		const drivingLicenseSeriesOnChange = valueEditor.useSetter(
			"drivingLicenseSeries",
		);

		const drivingLicenseNumberValue = valueEditor.useGetter(
			"drivingLicenseNumber",
		);
		const drivingLicenseNumberOnChange = valueEditor.useSetter(
			"drivingLicenseNumber",
		);

		const drivingLicenseCategoryValue = valueEditor.useGetter(
			"drivingLicenseCategory",
		);
		const drivingLicenseCategoryOnChange = valueEditor.useSetter(
			"drivingLicenseCategory",
		);

		const drivingLicenseIssuedAtValue = valueEditor.useGetter(
			"drivingLicenseIssuedAt",
		);
		const setDrivingLicenseIssuedAtValue = valueEditor.useSetter(
			"drivingLicenseIssuedAt",
		);

		const drivingLicenseIssuedAtOnChange = useCallback(
			(issuedAt: Nullable<Date>) => {
				setDrivingLicenseIssuedAtValue(issuedAt || undefined);
			},
			[setDrivingLicenseIssuedAtValue],
		);

		const drivingExperienceValue =
			valueEditor.useGetter("drivingExperience");
		const setDrivingExperienceValue =
			valueEditor.useSetter("drivingExperience");

		const drivingExperienceOnChange = useCallback(
			(value: number | undefined) => {
				if (!isUndefined(value)) setDrivingExperienceValue(value);
			},
			[setDrivingExperienceValue],
		);

		const drivingLicenseFilesValue = valueEditor.useGetter(
			"drivingLicenseFiles",
		);
		const drivingLicenseFilesOnChange = valueEditor.useSetter(
			"drivingLicenseFiles",
		);

		const [showFileModal, setShowFileModal] = useState(false);

		const fileButtonOnClick = useCallback(() => {
			setShowFileModal(true);
		}, []);

		const fileListModalOnClose = useCallback(() => {
			setShowFileModal(false);
		}, []);

		const fileListModalOnSubmit = useCallback(
			(files: File.Model[]) => {
				console.log(files);
				drivingLicenseFilesOnChange(files);
				setShowFileModal(false);
			},
			[drivingLicenseFilesOnChange],
		);

		return (
			<>
				<FieldsContainer
					label={
						t(
							"pages.mainPage.pages.accounts.tabs.executors.editModal.content.additionalDataTab.drivingLicense.str0",
						) ?? ""
					}
				>
					<Column gaps="8px*">
						<Row
							align="end"
							gaps="8px*"
							sizes="100px 1fr 128px*2 200px"
						>
							<LabeledField
								label={
									t(
										"pages.mainPage.pages.accounts.tabs.executors.editModal.content.additionalDataTab.drivingLicense.str1",
									) ?? ""
								}
							>
								<TextBox.TextBox
									value={drivingLicenseSeriesValue}
									disabled={disabled}
									maxLength={2}
									style={{
										minHeight: "32px",
										flex: "1 0 0",
									}}
									placeholder={
										t(
											"pages.mainPage.pages.accounts.tabs.executors.editModal.content.additionalDataTab.drivingLicense.str1",
										) ?? ""
									}
									onChange={drivingLicenseSeriesOnChange}
								/>
							</LabeledField>
							<LabeledField
								label={
									t(
										"pages.mainPage.pages.accounts.tabs.executors.editModal.content.additionalDataTab.drivingLicense.str3",
									) ?? ""
								}
							>
								<TextBox.TextBox
									value={drivingLicenseNumberValue}
									disabled={disabled}
									maxLength={9}
									style={{
										minHeight: "32px",
										flex: "1 0 0",
									}}
									placeholder={
										t(
											"pages.mainPage.pages.accounts.tabs.executors.editModal.content.additionalDataTab.drivingLicense.str3",
										) ?? ""
									}
									onChange={drivingLicenseNumberOnChange}
								/>
							</LabeledField>
							<LabeledField
								label={
									t(
										"pages.mainPage.pages.accounts.tabs.executors.editModal.content.additionalDataTab.drivingLicense.str5",
									) ?? ""
								}
							>
								<TextBox.TextBox
									value={drivingLicenseCategoryValue}
									disabled={disabled}
									style={{
										minHeight: "32px",
										flex: "1 0 0",
									}}
									placeholder={
										t(
											"pages.mainPage.pages.accounts.tabs.executors.editModal.content.additionalDataTab.drivingLicense.str5",
										) ?? ""
									}
									onChange={drivingLicenseCategoryOnChange}
								/>
							</LabeledField>
							<LabeledField
								label={
									t(
										"pages.mainPage.pages.accounts.tabs.executors.editModal.content.additionalDataTab.drivingLicense.str7",
									) ?? ""
								}
							>
								<DatePicker
									value={drivingLicenseIssuedAtValue}
									disabled={disabled}
									locale={locale}
									placeholder={
										t(
											"pages.mainPage.pages.accounts.tabs.executors.editModal.content.additionalDataTab.drivingLicense.str7",
										) ?? ""
									}
									onChange={drivingLicenseIssuedAtOnChange}
								/>
							</LabeledField>
							<LabeledField
								label={
									t(
										"pages.mainPage.pages.accounts.tabs.executors.editModal.content.additionalDataTab.drivingLicense.str9",
									) ?? ""
								}
							>
								<Column sizes="32px!">
									<Stepper
										value={drivingExperienceValue}
										disabled={disabled}
										width="200px"
										onChange={drivingExperienceOnChange}
									/>
								</Column>
							</LabeledField>
							<Button.Button
								disabled={disabled}
								icon={
									<Icon
										id="copy"
										size={16}
										colors={[
											disabled
												? theme.colors.secondary
												: theme.colors.white,
										]}
									/>
								}
								onClick={fileButtonOnClick}
							/>
						</Row>
					</Column>
				</FieldsContainer>{" "}
				{showFileModal ? (
					<FileListModal
						value={drivingLicenseFilesValue}
						title={
							t(
								"pages.mainPage.pages.accounts.tabs.executors.editModal.content.additionalDataTab.drivingLicense.str0",
							) ?? ""
						}
						onSubmit={fileListModalOnSubmit}
						onClose={fileListModalOnClose}
					/>
				) : undefined}
			</>
		);
	},
);

const DrivingLicense = extend(DrivingLicenseBase, {
	Value: DrivingLicenseValue,
});

declare namespace DrivingLicense {
	type Value = Optional<typeof DrivingLicenseValue.properties>;

	interface Props {
		value: Value;

		disabled: boolean;

		onChange: Dispatch<Value>;
	}
}

export default DrivingLicense;
