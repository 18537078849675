import React, {
	Dispatch,
	RefAttributes,
	useCallback,
	useLayoutEffect,
	useMemo,
	useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useDebouncedCallback } from "use-debounce";
import { Column, Row, TextBox, react } from "uikit";

import Code from "../../../../../../../../../../../../../../../../../../../services/Code";
import useObjectEditor from "../../../../../../../../../../../../../../../../../../../hooks/useObjectEditor";
import FieldsContainer from "../../../../../../../../../../../../../../../../../../../components/FieldsContainer";
import LabeledField from "../../../../../../../../../../../../../../../../../../../components/LabeledField";
import ImprovedDatePicker from "../../../../../../../../../../../../../../../../../../../components/ImprovedDatePicker";
import { NonEditableProperties } from "../../../../../../../../../../../../../../../../../../../types/NonEditableProperties";
import TripLimit from "../../../../../../../../../../TripLimit";
import Notes from "../../../../../../../../../tabs/Main/components/Notes";
import TabRoot from "../../components/TabRoot";

import Permissions from "./components/Permissions";
import InternalController from "./Controller";

const Main = react.withController<Main.PropsBase, Main.Controller>(
	({ visible, controller, value, onChange, cardId }) => {
		const { t } = useTranslation();
		const valueEditor = useObjectEditor(value, onChange);

		const permissionsValue = valueEditor.usePicker([
			"accessAllEmployeeToPersonalApp",
			"isAccessToCorporateAccount",
			"corporateLogin",
			"corporatePassword",
			"isLoginOnlyByEmployee",
		]);

		const activationDate = valueEditor.useGetter("activationDate");
		const setActivationDate = valueEditor.useSetter("activationDate");

		const completionDate = valueEditor.useGetter("completionDate");
		const setCompletionDate = valueEditor.useSetter("completionDate");

		const assigner = valueEditor.useAssigner();

		const additionalFields = valueEditor.useGetter("additionalFields");
		const setAdditionalFields = valueEditor.useSetter("additionalFields");
		const additionalFieldsEditor = useObjectEditor(
			additionalFields,
			setAdditionalFields,
		);

		const code = valueEditor.useGetter("code");
		const setCode = valueEditor.useSetter("code");
		const codeEditor = useObjectEditor(code, setCode);

		const cardNumber = codeEditor.useGetter("value");
		const setCardNumber = codeEditor.useSetter("value");
		const [cardNumberError, setCardNumberError] = useState<
			string | boolean
		>(false);
		const handleCardNumberChange = useCallback(
			(card: string) => {
				setCardNumberError(false);
				setCardNumber(card);
			},
			[setCardNumber],
		);

		const keyword = codeEditor.useGetter("keyword");
		const setKeyword = codeEditor.useSetter("keyword");
		const [keywordError, setKeywordError] = useState(false);
		const handleKeywordChange = useCallback(
			(newKeyword: string) => {
				setKeywordError(false);
				setKeyword(newKeyword);
			},
			[setKeyword],
		);

		const tripLimit = additionalFieldsEditor.useGetter("tripLimit");
		const setTripLimit = additionalFieldsEditor.useSetter("tripLimit");
		const notesValue = additionalFieldsEditor.usePicker([
			"orderNotes",
			"notes",
			"executorNotes",
		]);
		const additionalFieldsAssigner = additionalFieldsEditor.useAssigner();

		const checkCode = useDebouncedCallback(async (str: string) => {
			const res = await Code.getInfo(str);
			if (res.checkCard && res.checkCard?.id !== cardId) {
				setCardNumberError("exist");
			}
			if (res?.agent || res?.employee) setCardNumberError("exist");
		}, 200);

		useLayoutEffect(() => {
			if (code.value) checkCode(code.value);
		}, [checkCode, code.value]);

		const labelCard = useMemo(() => {
			const textError = t(`mainPage.customers.agents.errors.card_exist`);
			const label =
				t(
					"pages.mainPage.pages.customers.tabs.counterparties.modal.content.accounts.cards.modal.content.tabs.main.str0",
				) ?? "";
			if (cardNumberError === "exist") return textError;
			return label;
		}, [cardNumberError, t]);

		controller.setContext({
			value,
			cardNumberError,
			setCardNumberError,
			setCardNumber,
			setKeywordError,
			setKeyword,
		});

		return (
			<TabRoot hasPaddings visible={visible}>
				<div>
					<Column gaps="25px*" maxedWidth maxedHeight>
						<FieldsContainer gaps="15px*">
							<Row gaps="10px*" sizes="1fr 1fr">
								<LabeledField
									label={labelCard}
									style={{
										color:
											cardNumberError === "exist"
												? "red"
												: "",
									}}
								>
									<TextBox.TextBox
										error={!!cardNumberError}
										placeholder={
											t(
												"pages.mainPage.pages.customers.tabs.counterparties.modal.content.accounts.cards.modal.content.tabs.main.str1",
											) ?? ""
										}
										value={cardNumber}
										onChange={handleCardNumberChange}
									/>
								</LabeledField>
								<LabeledField
									label={
										t(
											"pages.mainPage.pages.customers.tabs.counterparties.modal.content.accounts.cards.modal.content.tabs.main.str2",
										) ?? ""
									}
								>
									<TextBox.TextBox
										error={keywordError}
										placeholder={
											t(
												"pages.mainPage.pages.customers.tabs.counterparties.modal.content.accounts.cards.modal.content.tabs.main.str3",
											) ?? ""
										}
										value={keyword}
										onChange={handleKeywordChange}
									/>
								</LabeledField>
							</Row>
							<Row gaps="8px*" sizes="132.875px!*">
								<LabeledField
									label={
										t(
											"pages.mainPage.pages.customers.tabs.counterparties.modal.content.accounts.cards.modal.content.tabs.main.str4",
										) ?? ""
									}
								>
									<ImprovedDatePicker
										value={activationDate}
										onChange={setActivationDate}
									/>
								</LabeledField>
								<LabeledField
									label={
										t(
											"pages.mainPage.pages.customers.tabs.counterparties.modal.content.accounts.cards.modal.content.tabs.main.str5",
										) ?? ""
									}
								>
									<ImprovedDatePicker
										value={completionDate}
										onChange={setCompletionDate}
									/>
								</LabeledField>
							</Row>
						</FieldsContainer>
						<Permissions
							value={permissionsValue}
							onChange={assigner}
						/>
						<TripLimit value={tripLimit} onChange={setTripLimit} />
						<Notes
							value={notesValue}
							onChange={additionalFieldsAssigner}
						/>
					</Column>
				</div>
			</TabRoot>
		);
	},
	InternalController,
);

declare namespace Main {
	type Ref = InternalController | null;
	type Controller = InternalController;

	interface PropsBase {
		visible: boolean;
		value: Value;
		onChange: Dispatch<Value>;
		cardId?: number;
	}
	type Props = PropsBase & RefAttributes<Ref>;

	interface Value extends Permissions.Value {
		additionalFields: AdditionalFields;
		code: Partial<NonEditableProperties> & {
			value: string;
			keyword: string;
		};
		activationDate: Date;
		completionDate?: Date;
	}
	interface AdditionalFields extends Notes.Value {
		tripLimit: TripLimit.Value;
	}
}

export default Main;
