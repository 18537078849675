import React, {
	Dispatch,
	SetStateAction,
	memo,
	useCallback,
	useEffect,
	useMemo,
} from "react";
import { useTranslation } from "react-i18next";
import { MultiSelect } from "uikit";
import Client from "../../../../../../../../../../../../../../services/Client";
import { ModelId } from "../../../../../../../../../../../../../../types/ModelId";

import getOptions from "../../../../../../../../../../../../../../utils/generateOptions";
import LabeledField from "../../../../../../../../../../../../../../components/LabeledField";

const MAIN_PHONE_GROUP = 0;

const Phones: React.FC<Phones.Props> = memo(
	({ error, setError, clients, value, onChange }) => {
		const { t } = useTranslation();
		const selectValue = useMemo(
			() => value.map((item) => item.phone),
			[value],
		);

		const allPhones = useMemo(
			() => clients.flatMap((c) => c.person?.phones),
			[clients],
		);

		const mainPhones = useMemo(
			() =>
				clients.flatMap((c) =>
					c.person?.phones?.find((p) => p.group === MAIN_PHONE_GROUP),
				),
			[clients],
		);

		const phoneOptions = useMemo(
			() =>
				clients.length === 1
					? getOptions(allPhones.map((p) => p?.number as string))
					: getOptions(mainPhones.map((p) => p?.number as string)),
			[allPhones, clients.length, mainPhones],
		);

		const handlePhonesChange = useCallback(
			(newPhones) => {
				setError(false);
				onChange(
					newPhones.map((phone) => ({
						taxiServiceId: clients[0]
							.defaultTaxiServiceId as number,
						phone,
					})),
				);
			},
			[clients, onChange, setError],
		);

		useEffect(() => {
			if (clients.length === 1) {
				const mainPhone = clients[0].person?.phones?.find(
					(p) => p?.group === MAIN_PHONE_GROUP,
				);
				if (mainPhone)
					onChange([
						{
							taxiServiceId: clients[0]
								?.defaultTaxiServiceId as number,
							phone: mainPhone.number,
						},
					]);
			} else {
				const newContacts = clients.flatMap((c) => ({
					taxiServiceId: c.defaultTaxiServiceId,
					phone: c.person?.phones?.[0].number,
				}));

				onChange(newContacts as Phones.Value[]);
			}
		}, [clients, onChange]);

		useEffect(() => {
			console.log("error:", error);
		}, [error]);

		return (
			<LabeledField
				label={
					selectValue.length === 1
						? t(
								"pages.mainPage.pages.customers.tabs.clients.header.sendingSMS.modal.phones.str200",
						  ) ?? ""
						: t(
								"pages.mainPage.pages.customers.tabs.clients.header.sendingSMS.modal.phones.str201",
						  ) ?? ""
				}
			>
				<MultiSelect
					error={error}
					disabled={clients.length !== 1}
					placeholder={
						t(
							"pages.mainPage.pages.customers.tabs.clients.header.sendingSMS.modal.phones.str0",
						) ?? ""
					}
					options={phoneOptions}
					value={selectValue}
					onChange={handlePhonesChange}
				/>
			</LabeledField>
		);
	},
);

declare namespace Phones {
	interface Props {
		error: boolean;
		setError: Dispatch<SetStateAction<boolean>>;
		clients: Client.Model[];
		value: Value[];
		onChange: (x: Value[]) => void;
	}

	interface Value {
		phone: string;
		taxiServiceId: ModelId;
	}
}

export default Phones;
