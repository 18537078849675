import styled from "styled-components";
import { Row } from "uikit";

const InformationContainer = styled(Row)`
	padding: 12px 15px;

	box-sizing: content-box;
`;

export default InformationContainer;
