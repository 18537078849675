import styled from "styled-components";
import { Column } from "uikit";

const Root = styled(Column)`
	width: 900x;
	height: 500px;
	min-width: 900px;
	min-height: 500px;
	border-radius: 5px;
	overflow: hidden;
`;

export default Root;
