import React, { Dispatch } from "react";
import { CheckBoxWithContent } from "uikit";
import Placeholder from "../Placeholder";

const Item: React.FC<Item.Props> = ({ value, label, onChange }) => (
	<Placeholder onClick={() => onChange(!value)}>
		<CheckBoxWithContent value={value} gap="10px" onChange={onChange}>
			{label}
		</CheckBoxWithContent>
	</Placeholder>
);

declare namespace Item {
	type Value = boolean;

	interface Props {
		value: Value;

		label: string;

		onChange: Dispatch<Value>;
	}
}

export default Item;
