import React, { RefAttributes, useMemo, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";

import { CheckBox, react, Row } from "uikit";
import { isEqual } from "lodash";
import { useDebouncedCallback } from "use-debounce";

import Reward from "../../../../../../../../../../../../../../../../services/Reward";
import useObjectEditor from "../../../../../../../../../../../../../../../../hooks/useObjectEditor";
import { NonEditableProperties } from "../../../../../../../../../../../../../../../../types/NonEditableProperties";
import EditModal from "../../../../../../../../../../../../../../../../components/EditModal";
import DeleteModal from "../../../../../../../../../../../../../../../../components/DeleteModal";

import { ErrorResponse, getErrorByMessage } from "./constants/errors";
import Root from "./components/Root";
import Header from "./components/Header";
import Content from "./components/Content";
import InternalController from "./Controller";

const Modal: React.FC<Modal.Props> = react.withController<
	Modal.PropsBase,
	Modal.Controller
>(({ value, onCancel, onSave, headerTitle, agentId }) => {
	const { t } = useTranslation();
	const [isSave, setIsSave] = useState<boolean>(false);
	const [showClarificationModal, setShowClarificationModal] =
		useState<boolean>(false);

	const [requestError, setRequestError] = useState<ErrorResponse | null>(
		null,
	);

	const [internalValue, setInternalValue] = useState(value);
	const editor = useObjectEditor(internalValue, setInternalValue);

	const contentData = editor.usePicker([
		"name",
		"percent",
		"amount",
		"maxAmountFromOrderInPercent",
		"rewardCalculationMethod",
		"id",
		"agentId",
	]);

	const active = editor.useGetter("active");
	const setActive = editor.useSetter("active");

	const isDefault = editor.useGetter("default");
	const setIsDefault = editor.useSetter("default");

	const debounceSave = useDebouncedCallback(() => {
		onCancel();
	}, 200);

	const handleSubmit = useCallback(async () => {
		const model = internalValue;
		if (isEqual(model, value)) {
			onCancel();
			return;
		}

		if (isSave) return;

		setIsSave(true);
		if ("id" in model && model.id) {
			const payload: Reward.Modified = {
				...model,
				id: model.id,
				agentId,
			};
			const res = await Reward.update(payload);
			setIsSave(false);
			if (res?.id) {
				onSave(res);
				debounceSave();
			}

			if (res?.error) {
				const { message } = res.data;
				setRequestError(getErrorByMessage(message, true));
				setShowClarificationModal(true);
			}
		} else {
			const payload: Reward.New = { ...model, agentId };
			const res = await Reward.store(payload as Reward.New);
			setIsSave(false);

			if (res?.id) {
				onSave(res);
				debounceSave();
			}

			if (res?.error) {
				const { message } = res.data;
				setRequestError(getErrorByMessage(message, false));
				setShowClarificationModal(true);
			}
		}
		setIsSave(false);
	}, [internalValue, value, isSave, onCancel, agentId, onSave, debounceSave]);

	const clarificationModalLabel = useMemo(() => {
		if (!requestError) return "";
		return t([requestError.translation, requestError.baseText]);
	}, [t, requestError]);

	return (
		<EditModal
			onCancel={onCancel}
			onSave={handleSubmit}
			canSave
			loading={isSave}
			footer={
				<Row align="center" gaps="16px*">
					<CheckBox
						label={
							t(
								"pages.mainPage.pages.customers.tabs.agents.modal.content.tabs.reward.main.modal.str0",
							) ?? ""
						}
						value={active}
						onChange={setActive}
						disabled={isDefault}
					/>
					<CheckBox
						label={
							t(
								"pages.mainPage.pages.customers.tabs.agents.modal.content.tabs.reward.main.modal.str1",
							) ?? ""
						}
						disabled={!active}
						value={isDefault}
						onChange={setIsDefault}
					/>
				</Row>
			}
		>
			<Root sizes="auto! 1fr">
				<Header title={headerTitle} />
				<Content value={contentData} onChange={editor.assign} />
			</Root>

			{showClarificationModal && (
				<DeleteModal
					label={clarificationModalLabel}
					onCancel={() => {
						setShowClarificationModal(false);
						setRequestError(null);
					}}
					confirmButtonLabel={
						requestError?.canRetry
							? t(
									"pages.mainPage.pages.customers.tabs.agents.modal.content.tabs.reward.main.modal.str201",
							  ) ?? ""
							: t(
									"pages.mainPage.pages.customers.tabs.agents.modal.content.tabs.reward.main.modal.str202",
							  ) ?? ""
					}
					onConfirm={() => {
						if (requestError?.canRetry) {
							handleSubmit();
						}
						setShowClarificationModal(false);
						setRequestError(null);
					}}
				/>
			)}
		</EditModal>
	);
}, InternalController);

declare namespace Modal {
	type Ref = InternalController | null;
	type Controller = InternalController;

	interface Value
		extends Content.Value,
			Partial<Pick<NonEditableProperties, "id">> {
		active: boolean;
		default: boolean;
		agentId?: number | null;
	}
	interface PropsBase {
		value: Value;
		headerTitle: string;
		agentId?: number;
		onCancel: () => void;
		onSave: (model: Reward.Model) => void;
	}

	type Props = PropsBase & RefAttributes<Ref>;
}

export default Modal;
