import React, { Dispatch, memo, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Column, DatePicker, Icon, Row, TextBox, theme } from "uikit";
import { extend } from "lodash";

import File from "../../../../../../../../../../../../../../services/File";
import useDatePickerLocale from "../../../../../../../../../../../../../../hooks/useDatePickerLocale";
import useObjectEditor from "../../../../../../../../../../../../../../hooks/useObjectEditor";
import Nullable from "../../../../../../../../../../../../../../types/Nullable";
import FileListModal from "../../../../../../../../../../../../../../components/FileListModal";
import Optional from "../../../../../../../../../../../../../../types/Optional";
import FieldsContainer from "../../../../../../../../../../../../../../components/FieldsContainer";
import LabeledField from "../../../../../../../../../../../../../../components/LabeledField";

const property: unknown = null;

namespace IEValue {
	export const properties = {
		IESeries: property as string,
		IECertificateNumber: property as string,
		IERegisteredAt: property as Date | undefined,
		IEUSREOU: property as string,
		IEIBAN: property as string,
		IEFiles: property as File.Model[],
	};

	export const propertyNames = Object.keys(
		properties,
	) as (keyof typeof properties)[];
}

const IEBase = memo(({ value, disabled, onChange }: IE.Props) => {
	const { t } = useTranslation();
	const locale = useDatePickerLocale();

	const valueEditor = useObjectEditor(value, onChange);

	const IESeriesValue = valueEditor.useGetter("IESeries");
	const IESeriesOnChange = valueEditor.useSetter("IESeries");

	const IECertificateNumberValue = valueEditor.useGetter(
		"IECertificateNumber",
	);
	const IECertificateNumberOnChange = valueEditor.useSetter(
		"IECertificateNumber",
	);

	const IERegisteredAtValue = valueEditor.useGetter("IERegisteredAt");
	const setIERegisteredAtValue = valueEditor.useSetter("IERegisteredAt");

	const IERegisteredAtOnChange = useCallback(
		(issuedAt: Nullable<Date>) => {
			setIERegisteredAtValue(issuedAt || undefined);
		},
		[setIERegisteredAtValue],
	);

	const IEUSREOUValue = valueEditor.useGetter("IEUSREOU");
	const IEUSREOUOnChange = valueEditor.useSetter("IEUSREOU");

	const IEIBANValue = valueEditor.useGetter("IEIBAN");
	const IEIBANOnChange = valueEditor.useSetter("IEIBAN");

	const IEFilesValue = valueEditor.useGetter("IEFiles");
	const IEFilesOnChange = valueEditor.useSetter("IEFiles");

	const [showFileModal, setShowFileModal] = useState(false);

	const fileButtonOnClick = useCallback(() => {
		setShowFileModal(true);
	}, []);

	const fileListModalOnClose = useCallback(() => {
		setShowFileModal(false);
	}, []);

	const fileListModalOnSubmit = useCallback(
		(files: File.Model[]) => {
			console.log(files);
			IEFilesOnChange(files);
			setShowFileModal(false);
		},
		[IEFilesOnChange],
	);

	return (
		<>
			<FieldsContainer
				label={
					t(
						"pages.mainPage.pages.accounts.tabs.executors.editModal.content.additionalDataTab.iE.str0",
					) ?? ""
				}
			>
				<Column gaps="8px*">
					<Row gaps="8px*" sizes="100px 1fr 128px">
						<LabeledField
							label={
								t(
									"pages.mainPage.pages.accounts.tabs.executors.editModal.content.additionalDataTab.iE.str1",
								) ?? ""
							}
						>
							<TextBox.TextBox
								value={IESeriesValue}
								disabled={disabled}
								maxLength={2}
								style={{
									minHeight: "32px",
									flex: "1 0 0",
								}}
								placeholder={
									t(
										"pages.mainPage.pages.accounts.tabs.executors.editModal.content.additionalDataTab.iE.str1",
									) ?? ""
								}
								onChange={IESeriesOnChange}
							/>
						</LabeledField>
						<LabeledField
							label={
								t(
									"pages.mainPage.pages.accounts.tabs.executors.editModal.content.additionalDataTab.iE.str3",
								) ?? ""
							}
						>
							<TextBox.TextBox
								value={IECertificateNumberValue}
								disabled={disabled}
								maxLength={9}
								style={{
									minHeight: "32px",
									flex: "1 0 0",
								}}
								placeholder={
									t(
										"pages.mainPage.pages.accounts.tabs.executors.editModal.content.additionalDataTab.iE.str3",
									) ?? ""
								}
								onChange={IECertificateNumberOnChange}
							/>
						</LabeledField>
						<LabeledField
							label={
								t(
									"pages.mainPage.pages.accounts.tabs.executors.editModal.content.additionalDataTab.iE.str5",
								) ?? ""
							}
						>
							<DatePicker
								value={IERegisteredAtValue}
								disabled={disabled}
								locale={locale}
								placeholder={
									t(
										"pages.mainPage.pages.accounts.tabs.executors.editModal.content.additionalDataTab.iE.str5",
									) ?? ""
								}
								onChange={IERegisteredAtOnChange}
							/>
						</LabeledField>
					</Row>
					<Row align="end" gaps="8px*" sizes="300px 1fr 32px!">
						<LabeledField
							label={
								t(
									"pages.mainPage.pages.accounts.tabs.executors.editModal.content.additionalDataTab.iE.str7",
								) ?? ""
							}
						>
							<TextBox.TextBox
								value={IEUSREOUValue}
								disabled={disabled}
								style={{
									minHeight: "32px",
									flex: "1 0 0",
								}}
								placeholder={
									t(
										"pages.mainPage.pages.accounts.tabs.executors.editModal.content.additionalDataTab.iE.str7",
									) ?? ""
								}
								onChange={IEUSREOUOnChange}
							/>
						</LabeledField>
						<LabeledField
							label={`${
								t(
									"pages.mainPage.pages.accounts.tabs.executors.editModal.content.additionalDataTab.iE.str200",
								) ?? ""
							} IBAN`}
						>
							<TextBox.TextBox
								value={IEIBANValue}
								disabled={disabled}
								style={{
									minHeight: "32px",
									flex: "1 0 0",
								}}
								placeholder={`${
									t(
										"pages.mainPage.pages.accounts.tabs.executors.editModal.content.additionalDataTab.iE.str200",
									) ?? ""
								} IBAN`}
								onChange={IEIBANOnChange}
							/>
						</LabeledField>
						<Button.Button
							disabled={disabled}
							icon={
								<Icon
									id="copy"
									size={16}
									colors={[
										disabled
											? theme.colors.secondary
											: theme.colors.white,
									]}
								/>
							}
							onClick={fileButtonOnClick}
						/>
					</Row>
				</Column>
			</FieldsContainer>{" "}
			{showFileModal ? (
				<FileListModal
					value={IEFilesValue}
					title={
						t(
							"pages.mainPage.pages.accounts.tabs.executors.editModal.content.additionalDataTab.iE.str0",
						) ?? ""
					}
					onSubmit={fileListModalOnSubmit}
					onClose={fileListModalOnClose}
				/>
			) : undefined}
		</>
	);
});

const IE = extend(IEBase, {
	Value: IEValue,
});

declare namespace IE {
	type Value = Optional<typeof IEValue.properties>;

	interface Props {
		value: Value;

		disabled: boolean;

		onChange: Dispatch<Value>;
	}
}

export default IE;
