import React from "react";
import { useTranslation } from "react-i18next";
import { Row, Column, CheckBox } from "uikit";

import { borderStyles, columnGaps } from "../../../constants/styles";

const Fourth: React.FC<Fourth.Props> = () => {
	const { t } = useTranslation();
	return (
		<Row sizes="1fr 1fr" style={borderStyles}>
			<Column gaps={columnGaps}>
				<CheckBox
					disabled
					label={
						t(
							"pages.mainPage.pages.accounts.tabs.executorTeams.modal.content.tabs.main.blocks.fourth.str200",
						) ?? ""
					}
				/>
				<CheckBox
					disabled
					label={
						t(
							"pages.mainPage.pages.accounts.tabs.executorTeams.modal.content.tabs.main.blocks.fourth.str202",
						) ?? ""
					}
				/>
				<CheckBox
					disabled
					label={
						t(
							"pages.mainPage.pages.accounts.tabs.executorTeams.modal.content.tabs.main.blocks.fourth.str204",
						) ?? ""
					}
				/>
			</Column>
			<Column gaps={columnGaps}>
				<CheckBox
					disabled
					label={
						t(
							"pages.mainPage.pages.accounts.tabs.executorTeams.modal.content.tabs.main.blocks.fourth.str206",
						) ?? ""
					}
				/>
				<CheckBox
					disabled
					label={
						t(
							"pages.mainPage.pages.accounts.tabs.executorTeams.modal.content.tabs.main.blocks.fourth.str208",
						) ?? ""
					}
				/>
				<CheckBox
					disabled
					label={
						t(
							"pages.mainPage.pages.accounts.tabs.executorTeams.modal.content.tabs.main.blocks.fourth.str210",
						) ?? ""
					}
				/>
			</Column>
		</Row>
	);
};

declare namespace Fourth {
	interface Props {}
}

export default Fourth;
