import { Dispatch, RefObject, SetStateAction } from "react";
import { react } from "uikit";
import Main from "./tabs/Main";
import Header from "../Header";
import { CardsTabs } from "./tabs";

interface Context {
	mainTabRef: RefObject<Main.Ref | null>;
	setTab: Dispatch<SetStateAction<Header.Tab>>;
}

export default class Controller extends react.ControllerBase<Context> {
	validate() {
		const isMainTabValid = !!this.context?.mainTabRef.current?.validate();

		if (!isMainTabValid) this.context?.setTab(CardsTabs.MAIN);

		return isMainTabValid;
	}
}
