import { react } from "uikit";
import EntityStatus from "../../../../../../../../../../../../../../../../../../../../types/EntityStatus";

interface Context {
	status: EntityStatus;
	onStatusError: (error: boolean) => void;
}

export default class Controller extends react.ControllerBase<Context> {
	validate() {
		const isStatusValid = !!this.context?.status;

		if (!isStatusValid) this.context?.onStatusError(true);

		return isStatusValid;
	}
}
