import { useTranslation } from "react-i18next";
import { useCallback } from "react";

import { useTypedSelector } from "../../../../../../../../../../../../../../redux/store";
import Client from "../../../../../../../../../../../../../../services/Client";
import { Entry } from "../../../../../../../../../../../../../../components/HistoryTable/hooks/useFieldLocalizer";
import Nullable from "../../../../../../../../../../../../../../types/Nullable";

export default function useChangeFormatter() {
	const { t } = useTranslation();
	const language = useTypedSelector((state) => state.session.language);

	const localizeGender = useCallback(
		(gender: Nullable<string>) => {
			// eslint-disable-next-line no-param-reassign
			gender = gender || "unknown";
			return t(`genders.${gender}`) || gender;
		},
		[t],
	);

	return useCallback(
		(entry: Client.History.Entry): Entry | null => {
			switch (entry.field) {
				case "name":
				case "surname":
				case "fatherName":
					return {
						field: entry.field,
						type: "string",
						previous: entry.previous,
						actual: entry.actual,
					};

				case "gender":
					return {
						field: entry.field,
						type: "string",
						actual: localizeGender(entry.actual),
						previous: localizeGender(entry.previous),
					};

				case "status":
					return {
						field: entry.field,
						type: "string",
						actual: t(`statuses.${entry.actual}`) || entry.actual,
						previous:
							t(`statuses.${entry.previous}`) || entry.previous,
					};

				case "carClass":
					return {
						field: entry.field,
						type: "string",
						actual: entry.actual?.[language],
						previous: entry.previous?.[language],
					};

				case "smsNotifications":
					return {
						field: entry.field,
						type: "toggle",
						actual: entry.actual,
						previous: entry.previous,
					};

				case "birthday":
					return {
						field: entry.field,
						type: "date",
						actual: entry.actual,
						previous: entry.previous,
					};

				case "emails": {
					return {
						field: entry.field,
						type: "array",
						actual: entry.actual?.map((v) => String(v.value)),
						previous: entry.previous?.map((v) => String(v.value)),
					};
				}

				case "phones":
					return {
						field: entry.field,
						type: "array",
						actual: entry.actual?.map((v) => String(v.number)),
						previous: entry.previous?.map((v) => String(v.number)),
					};

				default:
					return null;
			}
		},
		[language, localizeGender, t],
	);
}
