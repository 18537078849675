/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { useCallback } from "react";

import Car from "../../../../../../../services/Car";
import EditModal from "../components/EditModal";

function useCarEditModalSaver() {
	const editModalOnSave = useCallback(
		async (newItem: EditModal.Value.Validated) => {
			let otherFileIds: number[] = [];
			let transportFileIds: number[] = [];
			let registrationCertificateFileIds: number[] = [];
			let licenseCardFileIds: number[] = [];
			let insuranceFileIds: number[] = [];
			if (typeof newItem.id === "number") {
				otherFileIds = newItem.otherFiles
					.filter((file) => file.id)
					.map((file) => file.id!);

				otherFileIds = otherFileIds.concat(
					(
						await Promise.all(
							newItem.otherFiles
								.filter((file) => !file.id)
								.map((file) => file.upload()),
						)
					)
						.filter(Boolean)
						.map((file) => file!.id!),
				);

				transportFileIds = newItem.transportFiles
					.filter((file) => file.id)
					.map((file) => file.id!);

				transportFileIds = transportFileIds.concat(
					(
						await Promise.all(
							newItem.transportFiles
								.filter((file) => !file.id)
								.map((file) => file.upload()),
						)
					)
						.filter(Boolean)
						.map((file) => file!.id!),
				);

				registrationCertificateFileIds =
					newItem.registrationCertificateFiles
						.filter((file) => file.id)
						.map((file) => file.id!);

				registrationCertificateFileIds =
					registrationCertificateFileIds.concat(
						(
							await Promise.all(
								newItem.registrationCertificateFiles
									.filter((file) => !file.id)
									.map((file) => file.upload()),
							)
						)
							.filter(Boolean)
							.map((file) => file!.id!),
					);

				licenseCardFileIds = newItem.licenseCardFiles
					.filter((file) => file.id)
					.map((file) => file.id!);

				licenseCardFileIds = licenseCardFileIds.concat(
					(
						await Promise.all(
							newItem.licenseCardFiles
								.filter((file) => !file.id)
								.map((file) => file.upload()),
						)
					)
						.filter(Boolean)
						.map((file) => file!.id!),
				);

				insuranceFileIds = newItem.insuranceFiles
					.filter((file) => file.id)
					.map((file) => file.id!);

				insuranceFileIds = insuranceFileIds.concat(
					(
						await Promise.all(
							newItem.insuranceFiles
								.filter((file) => !file.id)
								.map((file) => file.upload()),
						)
					)
						.filter(Boolean)
						.map((file) => file!.id!),
				);
			} else {
				otherFileIds = (
					await Promise.all(
						newItem.otherFiles.map((file) => file.upload()),
					)
				)
					.filter(Boolean)
					.map((file) => file!.id!);

				transportFileIds = (
					await Promise.all(
						newItem.transportFiles.map((file) => file.upload()),
					)
				)
					.filter(Boolean)
					.map((file) => file!.id!);

				registrationCertificateFileIds = (
					await Promise.all(
						newItem.registrationCertificateFiles.map((file) =>
							file.upload(),
						),
					)
				)
					.filter(Boolean)
					.map((file) => file!.id!);

				licenseCardFileIds = (
					await Promise.all(
						newItem.licenseCardFiles.map((file) => file.upload()),
					)
				)
					.filter(Boolean)
					.map((file) => file!.id!);

				insuranceFileIds = (
					await Promise.all(
						newItem.insuranceFiles.map((file) => file.upload()),
					)
				)
					.filter(Boolean)
					.map((file) => file!.id!);
			}

			const newProperties: Car.Model.New = {
				parkNumber: newItem.parkNumber,
				registrationNumber: newItem.registrationNumber,
				vehicleNumber: newItem.vehicleNumber,
				manufactureYear: newItem.manufactureYear,
				seats: newItem.seats,
				colorId: newItem.carColorId,
				modelId: newItem.carModelId,
				bodyTypeId: newItem.carBodyTypeId,
				classId: newItem.carClassId,
				taxiServiceId: newItem.taxiServiceId,
				parkId: newItem.carParkId,
				responsibleDispatcherId: newItem.responsibleDispatcherId,
				active: newItem.active,
				notes: newItem.notes,
				serviceIds: newItem.serviceIds,
				registrationCertificate: newItem.registrationCertificate,
				licenseCard: newItem.licenseCard,
				radioStation: newItem.radioStation,
				taximeter: newItem.taximeter,
				insurances: newItem.insurances,
				executorIds: newItem.executorIds,
				otherFileIds,
				transportFileIds,
				registrationCertificateFileIds,
				licenseCardFileIds,
				insuranceFileIds,
				distributableCarClassIds: newItem.distributableCarClassIds,
				broadcastableCarClassIds: newItem.broadcastableCarClassIds,
			};

			if (typeof newItem.id === "number") {
				const res = await Car.update({
					id: newItem.id,
					...newProperties,
				});

				return res;
			}

			const res = await Car.store(newProperties);
			return res;
		},
		[],
	);

	return editModalOnSave;
}

export default useCarEditModalSaver;
