/* eslint-disable no-shadow */
import React, { memo, useCallback, useMemo, useState } from "react";
import { Button, DateAndTimePicker, Icon, Row } from "uikit";
import { useTranslation } from "react-i18next";

import Transaction from "../../../../../../services/Transaction";
import useDatePickerLocale from "../../../../../../hooks/useDatePickerLocale";

const DateRange: React.FC<DateRange.Props> = memo(({ value, onChange }) => {
	const { t } = useTranslation();
	const locale = useDatePickerLocale();

	const [from, setFrom] = useState(value?.from as Date);
	const [to, setTo] = useState(value?.to as Date);

	const handleFromChange = useCallback((newFrom) => {
		setFrom(newFrom);
	}, []);

	const handleToChange = useCallback((newTo) => {
		setTo(newTo);
	}, []);

	const handleSubmit = useCallback(() => {
		onChange({ from, to });
	}, [onChange, from, to]);

	const disabledSearch = useMemo(() => {
		const isEqual = !(value?.from !== from || value?.to !== to);
		return isEqual;
	}, [from, to, value?.from, value?.to]);

	return (
		<Row gaps="7px*" align="center">
			<div>{t(`from`)}</div>
			<DateAndTimePicker
				value={from}
				onChange={handleFromChange}
				maxDate={new Date()}
				locale={locale}
			/>
			<div>{t(`to`)}</div>
			<DateAndTimePicker
				value={to}
				onChange={handleToChange}
				minDate={from}
				locale={locale}
			/>
			<Button.Button
				disabled={disabledSearch}
				onClick={handleSubmit}
				icon={<Icon id="search" size={20} />}
			/>
		</Row>
	);
});

declare namespace DateRange {
	interface Props {
		value: Value;
		onChange: (x: Value) => void;
	}

	type Value = Transaction.SubscribeOptions["dateRange"];
}

export default DateRange;
