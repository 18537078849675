import React, { Dispatch, memo, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Row, TextBox, ToggleButton } from "uikit";

import useObjectEditor from "../../../../../../../../../../../../../../../../../hooks/useObjectEditor";
import FieldsContainer from "../../../../../../../../../../../../../../../../../components/FieldsContainer";
import tPath from "../../../../../../../../constants/tPath";

const path = `${tPath}.modal.tabs.main.code`;

const Code: React.FC<Code.Props> = memo(
	({ isCode, setIsCode, value, onChange }) => {
		const { t } = useTranslation();
		const editor = useObjectEditor(value, onChange);

		const codeValue = editor.useGetter("value");
		const setCodeValue = editor.useSetter("value");

		const keyword = editor.useGetter("keyword");
		const setKeyword = editor.useSetter("keyword");

		const onChangeCodeValue = useCallback(
			(value: string) => {
				const data = value.trim().length ? value : undefined;
				setCodeValue(data as string);
			},
			[setCodeValue],
		);

		const onChangeKeywordValue = useCallback(
			(value: string) => {
				const data = value.trim().length ? value : undefined;
				setKeyword(data as string);
			},
			[setKeyword],
		);

		return (
			<FieldsContainer
				label={t(`${path}.title`) || ""}
				additionalFields={
					<ToggleButton.ToggleButton
						value={isCode}
						onChange={setIsCode}
					/>
				}
				gaps="10px*"
			>
				<Row style={{ margin: "8px 5px" }} gaps="8px*" sizes="1fr 1fr">
					<TextBox.TextBox
						placeholder={t(`${path}.card`) || ""}
						value={codeValue}
						onChange={onChangeCodeValue}
					/>
					<TextBox.TextBox
						placeholder={t(`${path}.keyword`) || ""}
						value={keyword}
						onChange={onChangeKeywordValue}
					/>
				</Row>
			</FieldsContainer>
		);
	},
);

declare namespace Code {
	interface Props {
		isCode: boolean;
		setIsCode: Dispatch<boolean>;
		value: Value;
		onChange: Dispatch<Value>;
	}

	interface Value {
		value: string;
		keyword: string;
	}
}

export default Code;
