import React, { RefAttributes, memo, useCallback, useState } from "react";
import { react, useRefWithSetter } from "uikit";

import CustomersBlacklist from "../../../../../../../../services/CustomersBlacklist";
import useObjectEditor from "../../../../../../../../hooks/useObjectEditor";
import { NonEditableProperties } from "../../../../../../../../types/NonEditableProperties";
import EditModal from "../../../../../../../../components/EditModal";

import { CustomersBlacklistTabs as Tabs } from "./constants/tabs";
import Content from "./components/Content";
import Root from "./components/Root";
import Header from "./components/Header";
import InternalController from "./Controller";

const ModalBase = react.withController<Modal.PropsBase, Modal.Controller>(
	({ headerTitle, value, onCancel, onSave, controller }) => {
		const [contentRef, setContentRef] =
			useRefWithSetter<Content.Ref | null>(null);
		controller.setContext({ contentRef });

		const [tab, setTab] = useState(Tabs.MAIN);

		const [internalValue, setInternalValue] = useState(value);

		const valueEditor = useObjectEditor(internalValue, setInternalValue);

		const contentValues = valueEditor.usePicker([
			"companyIds",
			"phone",
			"description",
			"surname",
			"name",
			"fatherName",
			"level",
		]);

		const contentOnChange = valueEditor.useAssigner();

		const handleSubmit = useCallback(() => {
			if (!contentRef.current?.validate()) return;

			onSave(internalValue as any);
		}, [contentRef, internalValue, onSave]);

		return (
			<EditModal onCancel={onCancel} onSave={handleSubmit}>
				<Root sizes="auto! 1fr">
					<Header
						title={headerTitle}
						tab={tab}
						setTab={setTab}
						createdAt={value.createdAt}
					/>
					<Content
						id={value.id}
						tab={tab}
						ref={setContentRef}
						value={contentValues}
						onChange={contentOnChange}
					/>
				</Root>
			</EditModal>
		);
	},
	InternalController,
);
const Modal = memo(ModalBase);

declare namespace Modal {
	type Ref = InternalController | null;
	type Controller = InternalController;

	interface PropsBase {
		headerTitle: string;
		value: Value;
		onCancel: () => void;
		onSave: (
			item: CustomersBlacklist.New | CustomersBlacklist.Modified,
		) => Promise<void>;
	}
	type Props = PropsBase & RefAttributes<Ref>;

	type Value = Content.Value & Partial<NonEditableProperties>;
}

export default Modal;
