import React from "react";
import FilesTab from "../../../../../../../../../../../tabs/FilesTab";
import File from "../../../../../../../../../../../../../../../../../../services/File";

const Files: React.FC<Files.Props> = ({ visible, value, onChange }) => (
	<FilesTab visible={visible} value={value} onChange={onChange} />
);

declare namespace Files {
	interface Props {
		visible: boolean;
		value: Value;
		onChange: (value: Value) => void;
	}

	interface Value {
		personalFiles: File.Model[];
		otherFiles: File.Model[];
	}
}

export default Files;
