/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable no-param-reassign */
/* eslint-disable no-shadow */

import React, { Dispatch, RefAttributes, memo, useCallback } from "react";
import { Flex, react } from "uikit";

import useObjectEditor from "../../../../../../../../../../../../hooks/useObjectEditor";
import { SuspenseLoader } from "../../../../../../../../../../../../components/common";
import TabRoot from "../TabRoot";

import InternalController from "./Controller";

const AccessTab = memo(
	react.withController<AccessTab.PropsBase, AccessTab.Controller>(
		({ value, disabled, visible, currentIP, onChange }) => {
			const valueEditor = useObjectEditor(value, onChange);

			const allowedIpAddressesOnChange = useCallback(
				(allowedIpAddresses: string[]) =>
					valueEditor.set("allowedIpAddresses", allowedIpAddresses),
				[valueEditor],
			);

			return (
				<TabRoot hasPaddings visible={visible}>
					{/* <Column gaps="19px*" sizes="auto!" maxedWidth maxedHeight>
						<FieldsContainer label="Description">
							<Row
								align="center"
								justify="space-between"
								gaps="10px"
							>
								<div>
									IP addresses are defined in the format
									&quot;X.X.X.X&quot; where X is a number or
									&apos;*&apos;. The &apos;*&apos; symbol
									means any number.
								</div>
								<div>
									Current IP address:&nbsp;
									<b>{currentIP}</b>
								</div>
							</Row>
						</FieldsContainer>
						<FieldsContainer label="Allowed IP addresses">
							<Row wrap="wrap" gaps="8px*" sizes="217.2px* 32px">
								<IPAddresses
									value={valueEditor.get(
										"allowedIpAddresses",
									)}
									disabled={disabled}
									onChange={allowedIpAddressesOnChange}
								/>
							</Row>
						</FieldsContainer>
					</Column> */}
					<Flex
						align="center"
						justify="center"
						maxedWidth
						maxedHeight
					>
						<SuspenseLoader />
					</Flex>
				</TabRoot>
			);
		},
		InternalController,
	),
);

declare namespace AccessTab {
	type Ref = InternalController | null;

	type Controller = InternalController;

	interface Value {
		allowedIpAddresses: string[];
	}

	interface PropsBase {
		value: Value;

		disabled: boolean;
		visible: boolean;
		currentIP: string;

		onChange: Dispatch<Value>;
	}

	type Props = PropsBase & RefAttributes<Ref>;
}

export default AccessTab;
