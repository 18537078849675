import React, { Dispatch, memo, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Column, DatePicker, Row, TextBox } from "uikit";
import { extend } from "lodash";

import FieldsContainer from "../../../../../../../../../../../../../../components/FieldsContainer";
import LabeledField from "../../../../../../../../../../../../../../components/LabeledField";
import useDatePickerLocale from "../../../../../../../../../../../../../../hooks/useDatePickerLocale";
import useObjectEditor from "../../../../../../../../../../../../../../hooks/useObjectEditor";
import Nullable from "../../../../../../../../../../../../../../types/Nullable";
import Optional from "../../../../../../../../../../../../../../types/Optional";

const property: unknown = null;

namespace BankCardValue {
	export const properties = {
		bankCardNumber: property as string,
		bankCardExpiresAt: property as Date | undefined,
		bankCardNote: property as string,
	};

	export const propertyNames = Object.keys(
		properties,
	) as (keyof typeof properties)[];
}

const BankCardBase = memo(({ value, disabled, onChange }: BankCard.Props) => {
	const { t } = useTranslation();
	const locale = useDatePickerLocale();

	const valueEditor = useObjectEditor(value, onChange);

	const bankCardNumberValue = valueEditor.useGetter("bankCardNumber");
	const bankCardNumberOnChange = valueEditor.useSetter("bankCardNumber");

	const bankCardExpiresAtValue = valueEditor.useGetter("bankCardExpiresAt");
	const setBankCardExpiresAtValue =
		valueEditor.useSetter("bankCardExpiresAt");

	const bankCardExpiresAtOnChange = useCallback(
		(issuedAt: Nullable<Date>) => {
			setBankCardExpiresAtValue(issuedAt || undefined);
		},
		[setBankCardExpiresAtValue],
	);

	const bankCardNoteValue = valueEditor.useGetter("bankCardNote");
	const bankCardNoteOnChange = valueEditor.useSetter("bankCardNote");

	return (
		<FieldsContainer
			label={
				t(
					"pages.mainPage.pages.accounts.tabs.executors.editModal.content.additionalDataTab.bankCard.str0",
				) ?? ""
			}
		>
			<Column gaps="8px*">
				<Row gaps="8px*" sizes="3fr 128px 1fr">
					<LabeledField
						label={
							t(
								"pages.mainPage.pages.accounts.tabs.executors.editModal.content.additionalDataTab.bankCard.str1",
							) ?? ""
						}
					>
						<TextBox.TextBox
							value={bankCardNumberValue}
							disabled={disabled}
							maxLength={16}
							style={{
								minHeight: "32px",
								flex: "1 0 0",
							}}
							placeholder="XXXX XXXX XXXX XXXX"
							onChange={bankCardNumberOnChange}
						/>
					</LabeledField>
					<LabeledField
						label={
							t(
								"pages.mainPage.pages.accounts.tabs.executors.editModal.content.additionalDataTab.bankCard.str2",
							) ?? ""
						}
					>
						<DatePicker
							value={bankCardExpiresAtValue}
							disabled={disabled}
							locale={locale}
							placeholder={
								t(
									"pages.mainPage.pages.accounts.tabs.executors.editModal.content.additionalDataTab.bankCard.str2",
								) ?? ""
							}
							onChange={bankCardExpiresAtOnChange}
						/>
					</LabeledField>
					<LabeledField
						label={
							t(
								"pages.mainPage.pages.accounts.tabs.executors.editModal.content.additionalDataTab.bankCard.str4",
							) ?? ""
						}
					>
						<TextBox.TextBox
							value={bankCardNoteValue}
							disabled={disabled}
							style={{
								minHeight: "32px",
								flex: "1 0 0",
							}}
							placeholder={
								t(
									"pages.mainPage.pages.accounts.tabs.executors.editModal.content.additionalDataTab.bankCard.str4",
								) ?? ""
							}
							onChange={bankCardNoteOnChange}
						/>
					</LabeledField>
				</Row>
			</Column>
		</FieldsContainer>
	);
});

const BankCard = extend(BankCardBase, {
	Value: BankCardValue,
});

declare namespace BankCard {
	type Value = Optional<typeof BankCardValue.properties>;

	interface Props {
		value: Value;

		disabled: boolean;

		onChange: Dispatch<Value>;
	}
}

export default BankCard;
