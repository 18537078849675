/* eslint-disable import/no-unresolved */
/* eslint-disable no-shadow */

import React, { RefAttributes, useCallback, useMemo } from "react";
import { react, useInternal, useRefWithSetter } from "uikit";
import { isUndefined } from "lodash";
import InternalController from "./Controller";
import Root from "./components/Root";
import Header from "./components/Header";
import Content from "./components/Content";
import useObjectEditor from "../../../../../../../../../../../../../../hooks/useObjectEditor";
import EditModalBase from "../../../../../../../../../../../../../../components/EditModal";

const EditModal = react.withController<
	EditModal.PropsBase,
	EditModal.Controller
>(({ controller, value, onCancel, onSave }) => {
	const [contentRef, setContentRef] = useRefWithSetter<Content.Ref | null>(
		null,
	);

	const [internalValue, setInternalValue] = useInternal(value);

	const internalValueEditor = useObjectEditor(
		internalValue,
		setInternalValue,
	);

	const internalValueAssign = internalValueEditor.useAssigner();

	controller.setContext({ contentRef });

	const modalContent = useMemo(
		() => (
			<Root sizes="auto! 1fr">
				<Header isNew={isUndefined(value.id)} />
				<Content
					ref={setContentRef}
					value={internalValue}
					onChange={internalValueAssign}
				/>
			</Root>
		),
		[internalValue, internalValueAssign, setContentRef, value.id],
	);
	const modalOnSave = useCallback(() => {
		if (!contentRef.current?.validate()) return;

		onSave(internalValueEditor.value);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [contentRef, onSave]);

	return (
		<EditModalBase onCancel={onCancel} onSave={modalOnSave}>
			{modalContent}
		</EditModalBase>
	);
}, InternalController);

declare namespace EditModal {
	type Ref = InternalController | null;

	type Controller = InternalController;

	interface Value extends Content.Value {
		id?: number;
	}

	interface PropsBase {
		value: Value;

		onCancel: () => void;
		onSave: (value: Value) => void;
	}

	type Props = PropsBase & RefAttributes<Ref>;
}

export default EditModal;
