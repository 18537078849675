import React, { memo, useCallback, useMemo, useState } from "react";
import { Button, Icon } from "uikit";
import useModelSubscribe from "../../../../../../../../../../hooks/useModelSubscribe2";
import Modal from "./components/Modal";
import Message from "../../../../../../../../../../services/Message";
import PushNotice from "../../../../../../../../../../services/PushNotice";
import Executor from "../../../../../../../../../../services/Executor";

interface Props {
	executors: Executor.Model[];
}

const SendingPush: React.FC<Props> = memo(({ executors }) => {
	const { models: templates } = useModelSubscribe({}, PushNotice);

	const notOrdersTemplates = useMemo(
		() => templates.filter((t) => !t.orderTemplate),
		[templates],
	);

	const [showModal, setShowModal] = useState(false);

	const handleShowModal = useCallback(() => {
		setShowModal(true);
	}, []);

	const handleCloseModal = useCallback(() => {
		setShowModal(false);
	}, []);

	const handleSend = useCallback(async (value: Message.SendPushNotice) => {
		const res = await Message.sendPushNotice(value);
		if (res) {
			setShowModal(false);
		}
	}, []);

	const disabled = useMemo(() => executors.length === 0, [executors]);

	return (
		<div>
			<Button.Button
				disabled={disabled}
				variant="secondary"
				onClick={handleShowModal}
				icon={<Icon id="push" size={32} />}
			/>
			{showModal && (
				<Modal
					executors={executors}
					templates={notOrdersTemplates}
					onClose={handleCloseModal}
					onSubmit={handleSend}
				/>
			)}
		</div>
	);
});

export default SendingPush;
