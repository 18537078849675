import { react } from "uikit";
import { Dispatch, SetStateAction } from "react";
import Content from ".";

interface Context {
	value: Content.Value;
	setNameError: Dispatch<SetStateAction<boolean>>;
}

export default class Controller extends react.ControllerBase<Context> {
	validate() {
		const isNameValid = !!this.context?.value.name.trim().length;

		if (!isNameValid) this.context?.setNameError(true);

		return isNameValid;
	}
}
