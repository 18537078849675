import React, { Suspense } from "react";

import {
	StyledColumn,
	SuspenseLoader,
} from "../../../../../../components/common";

const Executors: React.FC = () => (
	<Suspense fallback={<SuspenseLoader />}>
		<StyledColumn justify="center" alignItems="center" w="100%" h="100%">
			<SuspenseLoader />
		</StyledColumn>
	</Suspense>
);

export default Executors;
