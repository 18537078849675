import React from "react";
import { isString } from "lodash";

import { Role } from "../../../../../../../../../../../../services";
import { CellContentRoot } from "../../../../../../../../../../../../components/LightTable";

const DescriptionCellContent: React.FC<DescriptionCellContent.Props> = ({
	item,
}) => (
	<CellContentRoot alignItems="center" w="100%" h="100%">
		{isString(item.description)
			? item.description.split("\n").map((line, index) => (
					<React.Fragment key={index}>
						{line}
						<br />
					</React.Fragment>
			  ))
			: ""}
	</CellContentRoot>
);

declare namespace DescriptionCellContent {
	interface Props {
		item: Role.Model;
	}
}

export default DescriptionCellContent;
