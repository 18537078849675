import React, { HTMLAttributes, memo } from "react";
import styled from "styled-components";
import { Column, theme } from "uikit";

const TabRootBase = styled.div<TabRoot.Props>`
	${({ visible }) => (visible ? "" : "display: none;")}
	width: 100%;
	height: 100%;
	padding: ${({ hasPaddings }) => (hasPaddings ? "24px" : "0")};
	overflow: auto;
	${theme.scrollbar}
`;

const TabRoot: React.FC<TabRoot.Props> = memo(({ children, ...props }) => (
	<TabRootBase {...props}>
		<Column gaps="25px*" maxedWidth maxedHeight>
			{children}
		</Column>
	</TabRootBase>
));

declare namespace TabRoot {
	interface Props extends HTMLAttributes<HTMLDivElement> {
		hasPaddings?: boolean;
		visible: boolean;
	}
}

export default TabRoot;
