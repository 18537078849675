import { RefObject } from "react";
import { react } from "uikit";

import {
	CompaniesAndTaxiServicesBase,
	NameBase,
} from "../../../../../../../../../../../../components/common";

interface Context {
	nameRef: RefObject<NameBase.Ref | null>;
	companiesAndTaxiServicesRef: RefObject<CompaniesAndTaxiServicesBase.Ref | null>;
}

export default class Controller extends react.ControllerBase<Context> {
	validate() {
		const isCompaniesAndTaxiServicesValid =
			this.context?.companiesAndTaxiServicesRef?.current?.validate();
		const isNameValid = this.context?.nameRef?.current?.validate();

		return isCompaniesAndTaxiServicesValid && isNameValid;
	}
}
