/* eslint-disable import/no-unresolved */
/* eslint-disable no-shadow */

import React, {
	RefAttributes,
	useCallback,
	useEffect,
	useMemo,
	useState,
} from "react";
import { useTranslation } from "react-i18next";
import { react, useRefWithSetter } from "uikit";
import { isUndefined } from "lodash";

import Map from "../../../../../../../../redux/services/Map";
import EditModalBase from "../../../../../../../../components/EditModal";
import WithoutNullableKeys from "../../../../../../../../types/WithoutNullableKeys";
import useObjectEditor from "../../../../../../../../hooks/useObjectEditor";
import Dispatcher from "../../../../../../../../services/Dispatcher";
import mod from "../../../../../../../../utils/mod";
import useKeepCardsOpen from "../../../../../../../../hooks/useKeepCardsOpen";
import WorkShift from "../../../../../../../../services/WorkShift";
import useCardSubscriber from "../../../../../../../../hooks/useCardSubscriber";

import InternalController from "./Controller";
import Footer from "./components/Footer";
import SchedulesTab from "./components/Content/components/SchedulesTab";
import Root from "./components/Root";
import Header from "./components/Header";
import Content from "./components/Content";

function areDayTimesIntersected(
	startTime1: number,
	endTime1: number,
	startTime2: number,
	endTime2: number,
) {
	return (
		(startTime1 < endTime1
			? startTime2 > startTime1 || startTime2 < endTime1
			: startTime2 > startTime1 && startTime2 < endTime1) ||
		(startTime1 < endTime1
			? endTime2 > startTime1 || endTime2 < endTime1
			: endTime2 > startTime1 && endTime2 < endTime1) ||
		(startTime2 < endTime2
			? startTime1 > startTime2 || startTime1 < endTime2
			: startTime1 > startTime2 && startTime1 < endTime2) ||
		(startTime2 < endTime2
			? endTime1 > startTime2 || endTime1 < endTime2
			: endTime1 > startTime2 && endTime1 < endTime2) ||
		(startTime1 === startTime2 && endTime1 === endTime2)
	);
}

const dayDuration = 24 * 60 * 60 * 1e3;

const EditModal = react.withController<
	EditModal.PropsBase,
	EditModal.Controller
>(({ controller, value, language, onCancel, onSave }) => {
	const { t } = useTranslation();

	const { text0, text1, text2, text3, text4, text5 }: Record<string, string> =
		useMemo(() => {
			const getTranslation = (key: string) =>
				t(
					`pages.mainPage.pages.accounts.tabs.workShifts.editModal.${key}`,
				) ?? "";

			return {
				text0: getTranslation("str200"),
				text1: getTranslation("str201"),
				text2: getTranslation("str202"),
				text3: getTranslation("str203"),
				text4: getTranslation("str204"),
				text5: getTranslation("str205"),
			};
		}, [t]);

	const [contentRef, setContentRef] = useRefWithSetter<Content.Ref | null>(
		null,
	);
	const [history, setHistory] = useState<Dispatcher.History>([]);
	const [tab, setTab] = useState<Header.Tab>("main");
	const [internalValue, setInternalValue] = useState(value);
	const [footerError, setFooterError] = useState("");

	const cardIds = useMemo(
		() => (typeof value.id === "number" ? [value.id] : []),
		[value.id],
	);

	useKeepCardsOpen(cardIds, WorkShift.Card);

	const cardSession = useCardSubscriber(WorkShift.Card);

	const currentCard = useMemo(
		() =>
			cardSession?.cards.find((card) => cardIds.includes(card.id)) ??
			null,
		[cardIds, cardSession?.cards],
	);

	controller.setContext({ contentRef });

	const valueEditor = useObjectEditor(internalValue, setInternalValue);

	const assignValue = valueEditor.useAssigner();

	const name = valueEditor.useGetter("name");

	const headerTitle = useMemo(
		() => (typeof value.id === "number" ? name : text0),
		[name, text0, value.id],
	);

	const headerLockedBy = useMemo(
		() =>
			currentCard?.locked
				? [
						currentCard.lockedBy.person?.lastName ?? "",
						currentCard.lockedBy.person?.firstName ?? "",
						currentCard.lockedBy.person?.fatherName ?? "",
				  ]
						.join(" ")
						.trim()
				: undefined,
		[currentCard?.locked, currentCard?.lockedBy],
	);

	const modalContent = useMemo(
		() => (
			<Root sizes="auto! 1fr">
				<Header
					tab={tab}
					title={headerTitle}
					lockedBy={headerLockedBy}
					createdAt={value.createdAt}
					onChangeTab={setTab}
				/>
				<Content
					ref={setContentRef}
					value={internalValue}
					disabled={currentCard?.locked === true}
					type={tab}
					language={language}
					history={history}
					onChange={assignValue}
				/>
			</Root>
		),
		[
			assignValue,
			currentCard?.locked,
			headerLockedBy,
			headerTitle,
			history,
			internalValue,
			language,
			setContentRef,
			tab,
			value.createdAt,
		],
	);

	const footerValue = valueEditor.usePicker(["active", "default"]);

	const modalFooter = useMemo(
		() => (
			<Footer
				value={footerValue}
				error={footerError}
				onChange={assignValue}
			/>
		),
		[assignValue, footerError, footerValue],
	);

	useEffect(() => {
		// (async () => {
		// 	if (value.id) setHistory(await Dispatcher.getHistory(value.id));
		// })();
	}, [value.id]);

	const modalOnSave = useCallback(() => {
		if (!contentRef.current?.validate()) return;

		const value = valueEditor.value as EditModal.Value.Validated;

		if (value.isSchedulesEnabled) {
			const intersectPairs = value.schedules
				.map(
					(schedule1) =>
						[
							schedule1,
							value.schedules.find((schedule2) =>
								areDayTimesIntersected(
									schedule1.startTime,
									schedule1.endTime,
									schedule2.startTime,
									schedule2.endTime,
								),
							),
						] as [SchedulesTab.Item, SchedulesTab.Item | undefined],
				)
				.filter(
					(([, schedule2]) => !isUndefined(schedule2)) as (
						schedulePair: [
							SchedulesTab.Item,
							SchedulesTab.Item | undefined,
						],
					) => schedulePair is [SchedulesTab.Item, SchedulesTab.Item],
				)
				.filter(
					([schedule1, schedule2]) =>
						schedule1.id !== schedule2.id &&
						((schedule1.monday && schedule2.monday) ||
							(schedule1.tuesday && schedule2.tuesday) ||
							(schedule1.wednesday && schedule2.wednesday) ||
							(schedule1.thursday && schedule2.thursday) ||
							(schedule1.friday && schedule2.friday) ||
							(schedule1.saturday && schedule2.saturday) ||
							(schedule1.sunday && schedule2.sunday)),
				);

			if (intersectPairs.length) {
				setFooterError(text1);

				return;
			}
		}

		if (value.isSpentMinuteRangeEnabled) {
			if (value.maximumMinutesSpent < value.minimumMinutesSpent) {
				setFooterError(text2);

				return;
			}

			if (value.isSchedulesEnabled) {
				const scheduleDurations = value.schedules.map(
					(schedule) =>
						mod(
							schedule.endTime - schedule.startTime,
							0,
							dayDuration,
						) /
						1e3 /
						60,
				);

				if (
					scheduleDurations.find(
						(scheduleDuration) =>
							scheduleDuration < value.minimumMinutesSpent ||
							scheduleDuration > value.maximumMinutesSpent,
					)
				) {
					setFooterError(text3);

					return;
				}
			}

			if (
				value.isLunchTimeEnabled &&
				value.lunchTime > value.maximumMinutesSpent
			) {
				setFooterError(text4);

				return;
			}
		}

		if (value.isLunchTimeEnabled) {
			const scheduleDurations = value.schedules.map(
				(schedule) =>
					mod(schedule.endTime - schedule.startTime, 0, dayDuration) /
					1e3 /
					60,
			);

			if (
				scheduleDurations.find(
					(scheduleDuration) => value.lunchTime > scheduleDuration,
				)
			) {
				setFooterError(text5);

				return;
			}
		}

		onSave(value);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [contentRef, onSave]);

	return (
		<EditModalBase
			footer={modalFooter}
			onCancel={onCancel}
			onSave={modalOnSave}
		>
			{modalContent}
		</EditModalBase>
	);
}, InternalController);

declare namespace EditModal {
	type Ref = InternalController | null;

	type Controller = InternalController;

	interface Value extends Content.Value, Footer.Value {
		id?: number;

		name: string;

		createdAt?: string;
	}

	namespace Value {
		type ValidationPropertyNames = "openAndCloseType";

		type Validated = Omit<Value, ValidationPropertyNames> &
			WithoutNullableKeys<Required<Pick<Value, ValidationPropertyNames>>>;
	}

	interface PropsBase {
		value: Value;
		language: Map.Language;

		onCancel: () => void;
		onSave: (value: Value.Validated) => void;
	}

	type Props = PropsBase & RefAttributes<Ref>;
}

export default EditModal;
