import { react } from "uikit";

interface Context {
	companyId?: number;

	onCompanyError: (error: boolean) => void;
}

export default class Controller extends react.ControllerBase<Context> {
	validate() {
		const isCompanyIdValid = this.context?.companyId != null;

		if (!isCompanyIdValid) this.context?.onCompanyError(true);

		return isCompanyIdValid;
	}
}
