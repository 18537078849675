import React, { memo } from "react";
import { useTranslation } from "react-i18next";

import FieldsContainer from "../../../../../../components/FieldsContainer";
import useObjectEditor from "../../../../../../hooks/useObjectEditor";
import tPath from "../../constants/tPath";

import MinHourlyRate from "./components/MinHourlyRate";
import HourlyServiceInterval from "./components/HourlyServiceInterval";
import HourlyServiceCost from "./components/HourlyServiceCost";

const HourlyService: React.FC<HourlyService.Props> = memo(
	({ root, value, onChange }) => {
		const { t } = useTranslation();

		const valueEditor = useObjectEditor(value, onChange);

		const minHourlyRate = valueEditor.useGetter("minHourlyRate");
		const setMinHourlyRate = valueEditor.useSetter("minHourlyRate");

		const hourlyServiceCost = valueEditor.useGetter("hourlyServiceCost");
		const setHourlyServiceCost = valueEditor.useSetter("hourlyServiceCost");

		const hourlyInterval = valueEditor.useGetter("hourlyServiceInterval");
		const setHourlyInterval = valueEditor.useSetter(
			"hourlyServiceInterval",
		);

		const hourlyServiceInterval = useObjectEditor(
			hourlyInterval,
			setHourlyInterval,
		);

		const hourlyServiceAction = hourlyServiceInterval.useGetter("active");
		const setHourlyServiceAction =
			hourlyServiceInterval.useSetter("active");

		return (
			<FieldsContainer
				label={
					t(`${tPath}.mainModal.tabs.main.hourlyService.blockName`) ||
					""
				}
				gaps="15px*"
			>
				<MinHourlyRate
					hourlyServiceAction={hourlyServiceAction}
					setHourlyServiceAction={setHourlyServiceAction}
					root={root}
					value={minHourlyRate}
					onChange={setMinHourlyRate}
				/>
				<HourlyServiceInterval
					root={root}
					value={hourlyInterval}
					onChange={setHourlyInterval}
				/>
				<HourlyServiceCost
					disabledAdditionalKm={minHourlyRate.appliesToEntireRoute}
					root={root}
					value={hourlyServiceCost}
					onChange={setHourlyServiceCost}
				/>
			</FieldsContainer>
		);
	},
);

declare namespace HourlyService {
	interface Props {
		root: boolean;
		value: Value;
		onChange: (value: Value) => void;
	}

	interface Value {
		minHourlyRate: MinHourlyRate.Value;
		hourlyServiceInterval: HourlyServiceInterval.Value;
		hourlyServiceCost: HourlyServiceCost.Value;
	}
}

export default HourlyService;
