/* eslint-disable react-hooks/rules-of-hooks */
import React, {
	Dispatch,
	RefAttributes,
	memo,
	useCallback,
	useMemo,
	useState,
} from "react";
import { useTranslation } from "react-i18next";
import { Column, Row, Option, Select, TextBox, react } from "uikit";

import CustomerRate from "../../../../../../../../../../../../../../../../../../services/CustomerRate";
import { useTypedSelector } from "../../../../../../../../../../../../../../../../../../redux/store";
import { useModelSubscribe } from "../../../../../../../../../../../../../../../../../../hooks";
import useObjectEditor from "../../../../../../../../../../../../../../../../../../hooks/useObjectEditor";
import { validateEmail } from "../../../../../../../../../../../../../../../../../../utils/validateEmail";
import FieldsContainer from "../../../../../../../../../../../../../../../../../../components/FieldsContainer";
import LabeledField from "../../../../../../../../../../../../../../../../../../components/LabeledField";
import ImprovedDatePicker from "../../../../../../../../../../../../../../../../../../components/ImprovedDatePicker";
import TripLimit from "../../../../../../../../../TripLimit";
import Notes from "../../../../../../../../tabs/Main/components/Notes";
import tPath from "../../../../../../constants/tPath";
import TabRoot from "../../components/TabRoot";

import InternalController from "./Controller";
import Phones from "./components/Phones";
import Permissions from "./components/Permissions";
import Account from "./components/Account";

// const path = `${tPath}.modal.tabs.main`;
const mainData = `${tPath}.modal.tabs.main.mainData`;
const additional = `${tPath}.modal.tabs.main.additional`;

const Main = memo(
	react.withController<Main.PropsBase, Main.Controller>((props) => {
		const {
			visible,
			controller,
			companyId,
			checkId,
			generalPaymentAccounts,
			generalPaymentTransactions,
			ignoreGeneralThresholdBalance,
			allowGeneralBonusBalance,
			value,
			onChange,
		} = props;

		const { t } = useTranslation();
		const language = useTypedSelector((state) => state.session.language);

		const mainCustomerRateData = useModelSubscribe(
			{ type: CustomerRate.Model.Type.Main },
			CustomerRate,
		);

		const additionalCustomerRateData = useModelSubscribe(
			{ type: CustomerRate.Model.Type.Additional },
			CustomerRate,
		);

		const notActive = useMemo(() => t("actives.false"), [t]);

		const valueEditor = useObjectEditor(value, onChange);

		const accountValue = valueEditor.usePicker(
			[
				"paymentAccounts",
				"paymentTransactions",
				"useGeneralMainBalance",
				"useGeneralBonusBalance",
				"useGeneralThresholdBalance",
				"ignoreThresholdBalance",
				"allowBonusBalance",
			],
			"json",
		);

		const activationDate = valueEditor.useGetter("activationDate");
		const setActivationDate = valueEditor.useSetter("activationDate");

		const completionDate = valueEditor.useGetter("completionDate");
		const setCompletionDate = valueEditor.useSetter("completionDate");

		const permissionsValue = valueEditor.usePicker([
			"accessAllEmployeeToPersonalApp",
			"isAccessToCorporateAccount",
			"corporateLogin",
			"corporatePassword",
		]);

		const assigner = valueEditor.useAssigner();

		const additionalFields = valueEditor.useGetter("additionalFields");
		const setAdditionalFields = valueEditor.useSetter("additionalFields");
		const additionalFieldsEditor = useObjectEditor(
			additionalFields,
			setAdditionalFields,
		);
		const checkValue = additionalFieldsEditor.useGetter("checkValue");
		const setCheckValue = additionalFieldsEditor.useSetter("checkValue");
		const [checkValueError, setCheckValueError] = useState(false);
		const handleCheckValueChange = useCallback(
			(newCheckValue) => {
				setCheckValueError(false);
				setCheckValue(newCheckValue);
			},
			[setCheckValue],
		);

		const contactName = additionalFieldsEditor.useGetter("contactName");
		const setContactName = additionalFieldsEditor.useSetter("contactName");
		const email = additionalFieldsEditor.useGetter("email");
		const setEmail = additionalFieldsEditor.useSetter("email");
		const phones = additionalFieldsEditor.useGetter("phones");
		const setPhones = additionalFieldsEditor.useSetter("phones");
		const address = additionalFieldsEditor.useGetter("address");
		const setAddress = additionalFieldsEditor.useSetter("address");
		const tripLimit = additionalFieldsEditor.useGetter("tripLimit");
		const setTripLimit = additionalFieldsEditor.useSetter("tripLimit");

		const notesData = additionalFieldsEditor.usePicker([
			"notes",
			"orderNotes",
			"executorNotes",
		]);

		const [errors, setError] = useState({
			email: false,
		});

		const errorsEditor = useObjectEditor(errors, setError);

		const emailErr = errorsEditor.useGetter("email");
		const setEmailError = errorsEditor.useSetter("email");

		const onChangeEmail = useCallback(
			(str: string) => {
				const isValid = validateEmail(str);
				setEmailError(!isValid);
				setEmail(str);
			},
			[setEmail, setEmailError],
		);

		const additionalFieldsAssigner = additionalFieldsEditor.useAssigner();

		const cleared = useMemo(
			() => ({
				key: 0,
				label:
					t(
						"pages.mainPage.pages.accounts.tabs.executors.editModal.content.mainTab.workData.executorTeamId.str1",
					) ?? "",
				value: 0,
			}),
			[t],
		);

		const mainRateIdValue = valueEditor.useGetter("mainRateId");
		const mainRateIdOnChange = valueEditor.useSetter("mainRateId");

		const mainCustomerRateOptions = useMemo<Option<number>[]>(() => {
			if (
				!mainCustomerRateData?.models ||
				!mainCustomerRateData?.models?.length
			) {
				return [];
			}

			const models = mainCustomerRateData.models.filter((item) => {
				if (item.active) return true;
				if (item.id === mainRateIdValue) return true;
				return false;
			});

			if (!models?.length) return [];

			const items = models.map((customerRate) => ({
				key: customerRate.id,
				value: customerRate.id,
				label: customerRate.active
					? customerRate.name[language]
					: `${customerRate.name[language]} (${notActive})`,
			}));

			return [cleared, ...items];
		}, [
			mainCustomerRateData.models,
			cleared,
			mainRateIdValue,
			language,
			notActive,
		]);

		const selectMainRateId = useCallback(
			(value) => {
				const find = [...mainCustomerRateOptions].find(
					(item) => item.key === value,
				);

				if (find === undefined) {
					mainRateIdOnChange(undefined);
					return;
				}

				if (find.value === 0) {
					mainRateIdOnChange(0);
					return;
				}

				mainRateIdOnChange(find.value);
			},
			[mainRateIdOnChange, mainCustomerRateOptions],
		);

		const additionalRateIdValue = valueEditor.useGetter("additionalRateId");
		const additionalRateIdOnChange =
			valueEditor.useSetter("additionalRateId");

		const additionalCustomerRateOptions = useMemo<Option<number>[]>(() => {
			if (
				!additionalCustomerRateData?.models ||
				!additionalCustomerRateData?.models?.length
			) {
				return [];
			}

			const models = additionalCustomerRateData.models.filter((item) => {
				if (item.active) return true;
				if (item.id === additionalRateIdValue) return true;
				return false;
			});

			if (!models?.length) return [];

			const items = models.map((customerRate) => ({
				key: customerRate.id,
				value: customerRate.id,
				label: customerRate.active
					? customerRate.name[language]
					: `${customerRate.name[language]} (${notActive})`,
			}));

			return [cleared, ...items];
		}, [
			language,
			additionalCustomerRateData.models,
			cleared,
			additionalRateIdValue,
			notActive,
		]);

		const selectAdditionalRateId = useCallback(
			(value) => {
				const find = [...additionalCustomerRateOptions].find(
					(item) => item.key === value,
				);

				if (find === undefined) {
					additionalRateIdOnChange(undefined);
					return;
				}

				if (find.value === 0) {
					additionalRateIdOnChange(0);
					return;
				}
				additionalRateIdOnChange(find.value);
			},
			[additionalCustomerRateOptions, additionalRateIdOnChange],
		);

		controller.setContext({ value, setCheckValueError, setEmailError });

		return (
			<TabRoot visible={visible} hasPaddings>
				<Column>
					<Column
						sizes="auto!*3 1fr*2 auto!"
						gaps="25px*"
						maxedWidth
						maxedHeight
					>
						<FieldsContainer label={t(`${mainData}.title`) || ""}>
							<Column gaps="8px*">
								<Row gaps="8px*" sizes="1fr* 2fr">
									<LabeledField
										label={t(`${mainData}.check`) || ""}
									>
										<TextBox.TextBox
											style={{
												minHeight: "32px",
												flex: "1 0 0",
											}}
											autoFocus
											autoComplete="one-time-code"
											placeholder={
												t(`${mainData}.check`) || ""
											}
											value={checkValue}
											onChange={handleCheckValueChange}
											error={checkValueError}
										/>
									</LabeledField>
									<LabeledField
										label={
											t(`${mainData}.contactName`) || ""
										}
									>
										<TextBox.TextBox
											style={{
												minHeight: "32px",
												flex: "1 0 0",
											}}
											autoComplete="one-time-code"
											placeholder={
												t(`${mainData}.contactName`) ||
												""
											}
											value={contactName ?? ""}
											onChange={setContactName}
										/>
									</LabeledField>
									<LabeledField
										label={t(`${mainData}.address`) || ""}
									>
										<TextBox.TextBox
											style={{
												minHeight: "32px",
												flex: "1 0 0",
											}}
											value={address ?? ""}
											onChange={setAddress}
											autoComplete="one-time-code"
											placeholder={
												t(`${mainData}.address`) || ""
											}
										/>
									</LabeledField>
								</Row>
								<Row gaps="8px*" sizes="1fr auto">
									<Row
										gaps="8px*"
										sizes={
											phones?.length === 2
												? "1fr*"
												: "280.05px*"
										}
									>
										<LabeledField
											label={t(`${mainData}.email`) || ""}
										>
											<TextBox.TextBox
												error={emailErr}
												value={email ?? ""}
												style={{
													minHeight: "32px",
													flex: "1 0 0",
												}}
												type="email"
												placeholder={
													t(`${mainData}.email`) || ""
												}
												onChange={onChangeEmail}
											/>
										</LabeledField>
										<Phones
											value={phones}
											max={2}
											onChange={setPhones}
										/>
									</Row>
									<Row gaps="8px*" sizes="132.875px!*">
										<LabeledField
											label={
												t(
													`${mainData}.activationDate`,
												) || ""
											}
										>
											<ImprovedDatePicker
												value={activationDate}
												onChange={setActivationDate}
											/>
										</LabeledField>
										<LabeledField
											label={
												t(`${mainData}.completeDate`) ||
												""
											}
										>
											<ImprovedDatePicker
												value={completionDate}
												onChange={setCompletionDate}
											/>
										</LabeledField>
									</Row>
								</Row>
							</Column>
						</FieldsContainer>
						<Permissions
							value={permissionsValue}
							onChange={assigner}
						/>
						<Account
							generalPaymentAccounts={generalPaymentAccounts}
							generalPaymentTransactions={
								generalPaymentTransactions
							}
							ignoreGeneralThresholdBalance={
								ignoreGeneralThresholdBalance
							}
							allowGeneralBonusBalance={allowGeneralBonusBalance}
							entityId={checkId}
							companyId={companyId}
							value={accountValue}
							onChange={assigner}
						/>
						<TripLimit value={tripLimit} onChange={setTripLimit} />

						<FieldsContainer gaps="10px*">
							<Row gaps="10px*" sizes="1fr 1fr 1fr">
								<LabeledField
									label={
										t(`${additional}.discountPlan`) || ""
									}
								>
									{/* <Select /> */}
									<Select
										value={mainRateIdValue}
										options={mainCustomerRateOptions}
										disabled={
											mainCustomerRateOptions.length === 0
										}
										onSelect={(option) =>
											selectMainRateId(option?.key)
										}
										placeholder={cleared.label}
									/>
								</LabeledField>
								<LabeledField
									label={
										t(`${additional}.extraDiscountPlan`) ||
										""
									}
								>
									{/* <Select /> */}
									<Select
										value={additionalRateIdValue}
										options={additionalCustomerRateOptions}
										disabled={
											additionalCustomerRateOptions.length ===
											0
										}
										onSelect={(option) =>
											selectAdditionalRateId(option?.key)
										}
										placeholder={cleared.label}
									/>
								</LabeledField>
								<LabeledField
									label={t(`${additional}.tariff`) || ""}
								>
									{/* TODO: is not work */}
									<Select disabled />
								</LabeledField>
							</Row>
						</FieldsContainer>
						<Notes
							value={notesData}
							onChange={additionalFieldsAssigner}
						/>
					</Column>
				</Column>
			</TabRoot>
		);
	}, InternalController),
);

declare namespace Main {
	type Ref = InternalController | null;
	type Controller = InternalController;

	interface PropsBase {
		companyId: number | undefined;
		checkId: number | undefined;
		visible: boolean;
		generalPaymentAccounts: Account.Props["generalPaymentAccounts"];
		generalPaymentTransactions: Account.Props["generalPaymentTransactions"];
		allowGeneralBonusBalance: boolean;
		ignoreGeneralThresholdBalance: boolean;
		value: Value;
		onChange: Dispatch<Value>;
	}
	type Props = PropsBase & RefAttributes<Ref>;

	interface Value extends Permissions.Value, Account.Value {
		additionalFields: AdditionalFields;
		activationDate: Date;
		completionDate?: Date;
		mainRateId?: number;
		additionalRateId?: number;
	}

	interface AdditionalFields extends Notes.Value {
		checkValue: string;
		contactName?: string;
		phones: string[];
		address?: string;
		email?: string;
		orderNotes?: string;
		notes?: string;
		executorNotes?: string;
		tripLimit: TripLimit.Value;

		deactivationThreshold: {
			value: number;
			description: string;
		};
	}
}

export default Main;
