import React from "react";
import { useTranslation } from "react-i18next";

import { Row, Button } from "uikit";
import Root from "./components/Root";

const Footer: React.FC<Footer.Props> = ({ onCancel, onSave }) => {
	const { t } = useTranslation();
	return (
		<Root align="center" justify="right">
			<Row gaps="16px">
				<Button.Button
					variant="secondary"
					text={t("selectModal2.footer.str0") ?? ""}
					onClick={onCancel}
				/>
				<Button.Button
					text={t("selectModal2.footer.str1") ?? ""}
					onClick={onSave}
				/>
			</Row>
		</Root>
	);
};

declare namespace Footer {
	interface Props {
		onCancel: () => void;
		onSave: () => void;
	}
}

export default Footer;
