import React, { memo } from "react";
import OrderTab from "../../../../../../../OrderTab";
import Order from "../../../../../../../../../../../../../../../services/Order";

const Orders: React.FC<Orders.Props> = memo(({ employeeId, options }) => (
	<OrderTab
		entityId={employeeId}
		visible={true}
		options={options}
		saveKey="customers.counterparties.tabs.employees"
	/>
));

declare namespace Orders {
	interface Props {
		employeeId: number | undefined;
		options: Order.SubscribeOptions;
	}
}

export default Orders;
