import React, { Dispatch, memo, useMemo } from "react";
import { Option } from "uikit";
import { useTranslation } from "react-i18next";

import LabeledField from "../../../../../../../../../../../../../../../../components/LabeledField";
import SelectWithModal from "../../../../../../../../../../../../../../../../components/SelectWithModal";
import AgentGroupService from "../../../../../../../../../../../../../../../../services/AgentGroup";
import tPath from "../../../../../../../../../../constants/tPath";
import Agent from "../../../../../../../../../../../../../../../../services/Agent";

const path = `${tPath}.modal.tabs.main.other.agentGroup`;

const AgentGroup: React.FC<AgentGroup.Props> = memo(
	({ groups, value, onChange }) => {
		const { t } = useTranslation();

		const options = useMemo<Option<number>[]>(
			() =>
				groups.map((group) => ({
					key: group.id,
					value: group.id,
					label: group.name,
				})),
			[groups],
		);

		return (
			<LabeledField label={t(`${path}.title`) || ""}>
				<SelectWithModal
					disabled={!groups?.length}
					title={t(`${path}.selectGroup`) || ""}
					options={options}
					value={value}
					onChange={onChange as any}
				/>
			</LabeledField>
		);
	},
);

declare namespace AgentGroup {
	interface Props {
		groups: AgentGroupService.Model[];
		value: Value;
		onChange: Dispatch<Value>;
	}

	type Value = Agent.Model["agentGroupId"];
}

export default AgentGroup;
