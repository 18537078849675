import React from "react";
import "rsuite/dist/rsuite.min.css";

import Root from "./components/Root";
import ModelTable from "./components/ModelTable";

const Content: React.FC<Content.Props> = (props) => (
	<Root sizes="1fr">
		<ModelTable {...props} />
	</Root>
);

declare namespace Content {
	type Props = ModelTable.Props;
}

export default Content;
