import styled from "styled-components";
import { Column, theme } from "uikit";

const Root = styled(Column)`
	width: 85vw;
	height: 90vh;

	min-width: 900px;
	min-height: 500px;

	border-radius: ${theme.borderRadius.m};

	background-color: white;

	overflow: hidden;
`;

export default Root;
