/* eslint-disable no-shadow */
/* eslint-disable no-param-reassign */

import * as ModelEvent from "@node-elion/syncron";
import { LatLngLiteral } from "leaflet";

import ServiceSubscribeOptionsBase from "../../types/ServiceSubscribeOptionsBase";
import Base from "../Base";
import Subscription from "../../types/Subscription";
import SubscriptionPool from "../../redux/services/SubscriptionPool";

declare namespace FavoriteAddress {
	interface SubscribeOptions
		extends ServiceSubscribeOptionsBase<FavoriteAddress.Model> {
		customerId?: number;
	}

	interface Address {
		coordinates: LatLngLiteral;

		flat: string;
		name: string;
		type: string;
		house: string;
		region: string;
		sector: string;
		street: string;
		country: string;
		district: string;
		entrance: string;
		settlement: string;
		streetType: string;
		countryCode: string;
		settlementType: string;
	}

	interface Model {
		key: string;
		rideNumber: number;
		taxiService: object;
		timestamp: number;
		address: Address;
	}

	interface SharedOptions {
		deprecate?: boolean;
	}
}

class FavoriteAddress extends Base {
	static defaultSharedOptions: FavoriteAddress.SharedOptions = {
		deprecate: true,
	};

	static fromResponse(data: any): FavoriteAddress.Model {
		return {
			key: data.key,
			rideNumber: data.rideNumber,
			taxiService: data.taxiService,
			timestamp: data.timestamp,
			address: data.address,
		};
	}

	public static async subscribe(
		options: FavoriteAddress.SubscribeOptions,
		onUpdate: Subscription.OnUpdate<FavoriteAddress.Model>,
	): Promise<Subscription<FavoriteAddress.SubscribeOptions> | null> {
		const modelEventConstructor = new ModelEvent.ModelEventConstructor({
			onUpdate: (state) => {
				onUpdate({
					...state,
					models: state.models.map(this.fromResponse),
				});
			},
		});
		const subscription = await SubscriptionPool.add(
			(prpc) =>
				prpc.theirsModel.customer.subscribeFavoriteAddresses({
					params: this.optionsToRequest(options),
					ping: () => true,
					onEvent: (events) => {
						modelEventConstructor.onEvent(events);
					},
					onError: (error) => {
						console.error(error);
					},
				}),
			{
				name: "FavoriteAddress.subscribe",
				metadata: this.optionsToRequest(options),
			},
		);

		return {
			unsubscribe: () => subscription.unsubscribe(),
			update: (options: FavoriteAddress.SubscribeOptions) =>
				subscription.update(this.optionsToRequest(options)),
		} as Subscription<FavoriteAddress.SubscribeOptions>;
	}

	private static optionsToRequest(options: FavoriteAddress.SubscribeOptions) {
		return {
			offset: options.offset,
			limit: options.limit,
			order: options.order,
			query: options.query,

			customerId: options.customerId,
		};
	}
}

export default FavoriteAddress;
