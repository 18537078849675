import { TFunction } from "i18next";

const getAssignOptions = (t: TFunction) => [
	{
		key: "executor",
		value: "executor",
		label:
			t(
				"pages.mainPage.pages.accounts.tabs.roles.editModal.content.mainTab.executor",
			) ?? "",
	},
	{
		key: "dispatcher",
		value: "dispatcher",
		label:
			t(
				"pages.mainPage.pages.accounts.tabs.roles.editModal.content.mainTab.dispatcher",
			) ?? "",
	},
];

export default getAssignOptions;
