/* eslint-disable no-param-reassign */
/* eslint-disable no-shadow */

import React from "react";
import { Flex } from "uikit";
import TabRoot from "../TabRoot";
import { SuspenseLoader } from "../../../../../../../../../../../../components/common";

const ClientsTab: React.FC<ClientsTab.Props> = ({ visible }) => (
	<TabRoot hasPaddings={false} visible={visible}>
		<Flex align="center" justify="center" maxedWidth maxedHeight>
			<SuspenseLoader />
		</Flex>
	</TabRoot>
);

declare namespace ClientsTab {
	interface Props {
		visible: boolean;
	}
}

export default ClientsTab;
