import React, { Dispatch, memo, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import Client from "../../../../../../../../../../../../../../services/Client";
import EditModal from "../../../../../../../../../../../../../../components/EditModal";
import ConfirmModal from "../../../../../../../../../../../../../../components/DeleteModal";
import { UseTableOptions } from "../../../../../../../../../../../../../../components/LightTable";

import Table from "../Table";

import Header from "./components/Header";
import Root from "./components/Root";

const Modal: React.FC<Modal.Props> = memo((props) => {
	const {
		clients,
		clientById,
		value,
		onCancel,
		onSave,
		onChangeTable,
		editorTable,
	} = props;
	const { t } = useTranslation();
	const [selected, setSelected] = useState<Modal.Value>(value);
	const [showConfirmModal, setShowConfirmModal] = useState(false);

	const handleSubmit = useCallback(() => {
		const clientHasGroup = selected.some(
			(id) => clientById[id].customerGroupId,
		);

		if (clientHasGroup) {
			setShowConfirmModal(true);
			return;
		}

		onSave(selected);
		setSelected([]);
	}, [clientById, onSave, selected]);

	const cancelHandler = useCallback(() => {
		setShowConfirmModal(false);
	}, []);

	const confirmHandler = useCallback(() => {
		onSave(selected);
		setShowConfirmModal(false);
		setSelected([]);
	}, [onSave, selected]);

	return (
		<EditModal onCancel={onCancel} onSave={handleSubmit}>
			<Root sizes="auto 1fr">
				<Header
					title={
						t(
							"pages.mainPage.pages.customers.tabs.clientGroups.modal.content.main.modal.str0",
						) ?? ""
					}
				/>

				<Table
					defaultColumns={[
						"lastName",
						"firstName",
						"fatherName",
						"phones",
						"group",
						"company",
						"defaultTaxiService",
					]}
					localStorageKey="modalSelect"
					value={clients}
					selected={selected}
					setSelected={setSelected}
					editorTable={editorTable}
					onChangeTable={onChangeTable}
					showFilter
				/>
			</Root>
			{showConfirmModal && (
				<ConfirmModal
					label={
						t(
							"pages.mainPage.pages.customers.tabs.clientGroups.modal.content.main.modal.str1",
						) ?? ""
					}
					onCancel={cancelHandler}
					onConfirm={confirmHandler}
				/>
			)}
		</EditModal>
	);
});

declare namespace Modal {
	interface Props {
		clients: Client.Model[];
		clientById: Record<number, Client.Model>;
		value: Value;
		onCancel: () => void;
		onSave: Dispatch<Value>;
		editorTable: UseTableOptions["editor"];
		onChangeTable: UseTableOptions["onChange"];
	}

	type Value = number[];
}

export default Modal;
