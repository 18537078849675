import React, { ReactNode, memo } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Button, theme } from "uikit";

import Modal from "../Modal";
import { StyledRow, Style, StyledColumn } from "../common";

const ModalRoot = styled(StyledColumn)<{ width?: string }>`
	width: ${(props) => props.width ?? "320px"};
	padding: 20px 20px 10px 20px;
	gap: 20px 20px 0 20px;
	border-radius: ${theme.borderRadius.m};
	background-color: white;
`;

const ModalMessage = styled.div`
	text-align: center;
	font-family: "Lato";
	font-style: normal;
	font-weight: 700;
	font-size: 16px;
	line-height: 21px;
`;

const InfoModal: React.FC<InfoModal.Props> = memo(
	({ disabled = false, width, label, onConfirm, content, ...props }) => {
		const { t } = useTranslation();
		return (
			<Modal dimmed>
				<ModalRoot width={width} justify="center" {...props}>
					{label && <ModalMessage>{label}</ModalMessage>}

					{content}

					<StyledRow gap="10px" justify="center" m="auto 0 0 0">
						<Button.Button
							disabled={disabled}
							text={t("infoModal.str0") ?? ""}
							onClick={onConfirm}
						/>
					</StyledRow>
				</ModalRoot>
			</Modal>
		);
	},
);

declare namespace InfoModal {
	interface Props extends Style.Row {
		width?: string;
		disabled?: boolean;
		label?: string | ReactNode;
		onConfirm: () => void;
		content?: ReactNode;
	}
}

export default InfoModal;
