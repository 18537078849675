import styled from "styled-components";
import { Column, theme } from "uikit";

const TabRoot = styled(Column)<TabRoot.Props>`
	${({ visible }) => (visible ? "" : "display: none;")}
	width: 100%;
	height: 100%;
	padding: ${({ hasPaddings }) => (hasPaddings ? "24px" : "0")};
	overflow: auto;
	${theme.scrollbar}
`;

declare namespace TabRoot {
	interface Props {
		hasPaddings: boolean;
		visible: boolean;
	}
}

export default TabRoot;
