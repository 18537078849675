import React, { memo, useCallback, useState } from "react";
import { useRefWithSetter } from "uikit";

import useObjectEditor from "../../../../../../../../../../../../../../hooks/useObjectEditor";
import EditModal from "../../../../../../../../../../../../../../components/EditModal";
import { AutodetectPhone } from "../../../../../../../../../../hooks";

import Root from "./components/Root";
import Header from "./components/Header";
import Content from "./components/Content";

const Modal: React.FC<Modal.Props> = memo((props) => {
	const {
		value,
		onCancel,
		onSave,
		headerTitle,
		allPhones,
		setErrorData,
		setShowErrorModal,
	} = props;

	const [contentRef, setContentRef] = useRefWithSetter<Content.Ref>(null);

	const [internalValue, setInternalValue] = useState(value);
	const editor = useObjectEditor(internalValue, setInternalValue);

	const contentData = editor.usePicker(["isAgent", "number", "group", "id"]);

	const handleSubmit = useCallback(() => {
		if (!contentRef.current?.validate()) return;

		onSave(internalValue);
	}, [contentRef, internalValue, onSave]);

	return (
		<EditModal onCancel={onCancel} onSave={handleSubmit}>
			<Root sizes="auto! 1fr">
				<Header title={headerTitle} />
				<Content
					ref={setContentRef}
					value={contentData}
					onChange={editor.assign}
					setErrorData={setErrorData}
					allPhones={allPhones}
					setShowErrorModal={setShowErrorModal}
				/>
			</Root>
		</EditModal>
	);
});

declare namespace Modal {
	interface Props {
		value: Value;
		headerTitle: string;
		onCancel: () => void;
		onSave: (x: Value, index?: number | null) => void;

		setErrorData: (value: React.SetStateAction<AutodetectPhone[]>) => void;
		allPhones: AutodetectPhone[];
		setShowErrorModal: React.Dispatch<React.SetStateAction<boolean>>;
	}

	interface Value extends Content.Value {}
}

export default Modal;
