import React, {
	Dispatch,
	RefAttributes,
	memo,
	useCallback,
	useMemo,
	useState,
} from "react";
import { Option, Select, react } from "uikit";
import { Skeleton } from "@mui/material";
import { useTranslation } from "react-i18next";
import Controller from "./Controller";
import LabeledField from "../../../../../../../../../../../../../../../../components/LabeledField";
import TaxiService from "../../../../../../../../../../../../../../../../services/TaxiService";
import useModelSubscribe from "../../../../../../../../../../../../../../../../hooks/useModelSubscribe";
import { useTypedSelector } from "../../../../../../../../../../../../../../../../redux/store";

const TaxiServiceSelectBase = react.withController<
	TaxiServiceSelect.PropsBase,
	Controller
>(({ companyId, controller, disabled, value, onChange }) => {
	const { t } = useTranslation();
	const lang = useTypedSelector((state) => state.session.language);

	const onTaxiServiceIdChange = useCallback(
		(newValue) => {
			onChange(newValue);
			setTaxiServiceIdError(false);
		},
		[onChange],
	);

	const [taxiServiceIdError, setTaxiServiceIdError] = useState(false);

	controller.setContext({
		taxiServiceId: value,
		onTaxiServiceIdError: setTaxiServiceIdError,
	});

	const taxiServices = useModelSubscribe(
		useMemo(
			() => ({
				companyIds: companyId != null ? [companyId] : undefined,
			}),
			[companyId],
		),
		TaxiService,
	);

	const options = useMemo<Option<TaxiService.Model["id"]>[]>(() => {
		const options =
			companyId != null &&
			taxiServices?.cache.map((taxiService) => ({
				key: taxiService.id,
				label: taxiService.settlement[lang],
				value: taxiService.id,
			}));

		return options || [];
	}, [companyId, taxiServices?.cache, lang]);

	return (
		<LabeledField label={t(`registrationCity`) || ""}>
			{!taxiServices ? (
				<Skeleton variant="rectangular" width={275.5} height={32} />
			) : (
				<Select
					disabled={disabled}
					value={value}
					onChange={onTaxiServiceIdChange}
					options={options}
					placeholder={
						companyId
							? t(`no_data`) || "No data"
							: t(`selectCompanyFirst`) || ""
					}
					error={taxiServiceIdError}
				/>
			)}
		</LabeledField>
	);
}, Controller);

const TaxiServiceSelect = memo(TaxiServiceSelectBase);

declare namespace TaxiServiceSelect {
	type Ref = Controller;

	interface PropsBase {
		companyId?: number;
		disabled?: boolean;
		value: Value;
		onChange: Dispatch<Value>;
	}
	type Props = PropsBase & RefAttributes<Ref>;

	type Value = number | undefined;
}

export default TaxiServiceSelect;
