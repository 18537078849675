import React, { Dispatch, memo, useCallback, useMemo, useState } from "react";
import { Column, InputBorders } from "uikit";
import { useTranslation } from "react-i18next";

import Counterparty from "../../../../../../../../../../../../../../../../../../services/Counterparty";
import InputModal from "../../../../../../../../../../../../../../../../../../components/InputModal";
import mapByKey from "../../../../../../../../../../../../../../../../../../utils/mapByKey";
import tPath from "../../../../../../../../../../../../constants/tPath";

import Text from "./components/Text";
import Table from "./components/Table";

const CounterpartySelect: React.FC<CounterpartySelect.Props> = memo(
	({ counterparties, value, onChange }) => {
		const { t } = useTranslation();
		const modelItemById = useMemo(
			() => mapByKey(counterparties, "id"),
			[counterparties],
		);

		const [isModalOpen, setIsModalOpen] = useState(false);

		const handleOpen = useCallback(() => {
			setIsModalOpen(true);
		}, []);

		const handleClose = useCallback(() => {
			setIsModalOpen(false);
		}, []);

		const name = value ? modelItemById[value]?.additionalFields.name : null;

		const [selected, setSelected] = useState<number | null>(value);

		const onSubmit = useCallback(() => {
			onChange(selected);
			setIsModalOpen(false);
		}, [onChange, selected]);

		return (
			<Column>
				<div onClick={handleOpen} style={{ cursor: "pointer" }}>
					<InputBorders.InputBorders
						style={{ height: 32 }}
						disabled={!counterparties.length}
					>
						<Text isPlaceholder={!name || name.length === 0}>
							{name ??
								t([
									`${tPath}.modal.tabs.main.other.counterparty`,
								])}
						</Text>
					</InputBorders.InputBorders>
				</div>
				{isModalOpen && (
					<InputModal
						draggable
						onClose={handleClose}
						onSubmit={onSubmit}
					>
						<Table
							tableData={counterparties}
							selected={selected}
							setSelected={setSelected}
						/>
					</InputModal>
				)}
			</Column>
		);
	},
);

declare namespace CounterpartySelect {
	interface Props {
		counterparties: Counterparty.Model[];
		value: Value;
		onChange: Dispatch<Value>;
	}

	type Value = number | null;
}

export default CounterpartySelect;
