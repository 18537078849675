import React, { Dispatch } from "react";
import { useTranslation } from "react-i18next";
import { CheckBoxWithContent, Row } from "uikit";
import useObjectEditor from "../../../../../../../../../../hooks/useObjectEditor";
import Error from "./components/Error";

const Footer: React.FC<Footer.Props> = ({
	value,
	disabled,
	error,
	onChange,
}) => {
	const valueEditor = useObjectEditor(value, onChange);

	const activeOnChange = valueEditor.useSetter("active");

	const { t } = useTranslation();
	return (
		<Row
			align="center"
			justify="space-between"
			gaps="24px"
			sizes="1fr auto!"
		>
			<Row gaps="24px">
				<CheckBoxWithContent
					value={valueEditor.get("active")}
					disabled={disabled}
					gap="10px"
					onChange={activeOnChange}
				>
					{t(
						"pages.mainPage.pages.accounts.tabs.carParks.editModal.footer.str150",
					) ?? ""}
				</CheckBoxWithContent>
			</Row>
			<Error>{error}</Error>
		</Row>
	);
};

declare namespace Footer {
	interface Value {
		active: boolean;
	}

	interface Props {
		value: Value;

		disabled: boolean;
		error: string;

		onChange: Dispatch<Value>;
	}
}

export default Footer;
