import React from "react";

import { Role } from "../../../../../../../../../../../../services";
import { CellContentRoot } from "../../../../../../../../../../../../components/LightTable";

const NameCellContent: React.FC<NameCellContent.Props> = ({ item }) => (
	<CellContentRoot alignItems="center" w="100%" h="100%">
		{item.name ?? ""}
	</CellContentRoot>
);

declare namespace NameCellContent {
	interface Props {
		item: Role.Model;
	}
}

export default NameCellContent;
