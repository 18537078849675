import React, { Dispatch, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Select } from "uikit";

import { getStatuses } from "../../../../../../../../../../../../../../../../utils";
import LabeledField from "../../../../../../../../../../../../../../../../components/LabeledField";

const Status: React.FC<Status.Props> = ({ value, disabled, onChange }) => {
	const { t } = useTranslation();

	const statuses = useMemo(() => getStatuses(t), [t]);
	return (
		<LabeledField
			label={
				t(
					"pages.mainPage.pages.accounts.tabs.users.editModal.content.mainTab.workData.status.str0",
				) ?? ""
			}
		>
			<Select
				value={value}
				disabled={disabled}
				placeholder={
					t(
						"pages.mainPage.pages.accounts.tabs.users.editModal.content.mainTab.workData.status.str0",
					) ?? ""
				}
				options={statuses}
				onSelect={(option) => onChange(option.value)}
			/>
		</LabeledField>
	);
};

declare namespace Status {
	type Value = "active" | "blocked" | "dismissed";

	interface Props {
		value?: Value;

		disabled: boolean;

		onChange: Dispatch<Value>;
	}
}

export default Status;
