/* eslint-disable no-shadow */

import React, { Dispatch, Key, useCallback, useMemo, useState } from "react";
import { InputBorders, Option, useInternal } from "uikit";
import PropsOf from "uikit/src/types/PropsOf";

import SelectModal from "../SelectModal";

import Root from "./components/Root";
import Text from "./components/Text";

// eslint-disable-next-line prettier/prettier
const SelectWithModal = <OptionValue, ValueType extends Key>({
	value,

	disabled,

	title,
	options,

	onChange,

	...props
}: SelectWithModal.Props<OptionValue, ValueType>) => {
	const [internalValue, setInternalValue] = useInternal(value);

	const [showModal, setShowModal] = useState(false);

	const optionByKey = useMemo(
		() =>
			options?.reduce((accumulator, option: any) => {
				accumulator[option.key] = option;

				return accumulator;
			}, {} as Record<any, Option<unknown>>) ?? {},
		[options],
	);

	const valueText = useMemo(
		() => (internalValue ? optionByKey[internalValue]?.label ?? "" : ""),
		[internalValue, optionByKey],
	);

	const hasPlaceholder = useMemo(
		() => valueText.length === 0,
		[valueText.length],
	);

	const text = useMemo(() => valueText || (title ?? ""), [title, valueText]);

	const rootOnClick = useCallback(
		() => !disabled && setShowModal(true),
		[disabled],
	);

	const modalOnSubmit = useCallback(
		(value: SelectModal.Value<ValueType>) => {
			onChange?.(value);
			setInternalValue(value);
			setShowModal(false);
		},
		[onChange, setInternalValue],
	);

	const modalOnClose = useCallback(() => setShowModal(false), []);

	return (
		<Root disabled={!!disabled} onClick={rootOnClick}>
			<InputBorders.InputBorders {...props} disabled={disabled}>
				<Text isPlaceholder={hasPlaceholder}>{text}</Text>
			</InputBorders.InputBorders>
			{showModal && (
				<SelectModal
					value={internalValue}
					title={title}
					options={options}
					onSubmit={modalOnSubmit}
					onClose={modalOnClose}
				/>
			)}
		</Root>
	);
};

declare namespace SelectWithModal {
	type Value<Type extends Key> = Type | undefined;

	export interface PropsBase<OptionValue, ValueType extends Key> {
		value?: Value<ValueType>;

		title?: string;
		options?: Option<OptionValue>[];

		onChange?: Dispatch<Value<ValueType>>;
	}

	type Props<OptionValue, ValueType extends Key> = Omit<
		PropsOf<typeof InputBorders.InputBorders>,
		keyof PropsBase<OptionValue, ValueType>
	> &
		PropsBase<OptionValue, ValueType>;
}

export default SelectWithModal;
