import React, { memo, useRef } from "react";
import styled from "styled-components";
import { theme } from "uikit";
import Person from "../../../../../../../../../../services/Person";
import useOnClickOutside from "../../../../../../../../../../hooks/useOnClickOutside";

const CallArrayModalContainer = styled.div`
	position: absolute;
	z-index: 10;
	top: 100%;
	background: white;
	width: max-content;
	border: 1px solid rgba(33, 51, 63, 0.1);
	border-radius: ${theme.borderRadius.m};
	margin-top: 3px;
	padding: 10px;
	box-shadow: 0px 3px 6px rgba(33, 51, 63, 0.15);

	.CallArrayModal__items {
		display: flex;
		flex-direction: column;

		.CallArrayModal__item {
			padding: 5px 10px;
			border-radius: ${theme.borderRadius.m};
			cursor: pointer;
			color: #647079;

			:hover {
				background: #ebebeb;
			}
		}
	}
`;

const CallArrayModal: React.FC<CallArrayModal.Props> = memo(
	({ phones, onClose, onSubmit }) => {
		const modalContainerRef = useRef<HTMLDivElement>(null);

		useOnClickOutside(modalContainerRef, onClose);

		return (
			<CallArrayModalContainer ref={modalContainerRef}>
				<div className="CallArrayModal__items">
					{phones?.map(({ number }) => (
						<div
							key={number}
							className="CallArrayModal__item"
							onClick={() => {
								onSubmit(number);
								onClose();
							}}
						>
							{number}
						</div>
					))}
				</div>
			</CallArrayModalContainer>
		);
	},
);

declare namespace CallArrayModal {
	interface Props {
		phones?: Person.Model.Phone[];
		onClose: () => void;
		onSubmit: (phone: string) => void;
	}
}

export default CallArrayModal;
