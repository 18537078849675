import { react } from "uikit";
import { Dispatch } from "react";
import FullName from ".";

interface Context {
	value: FullName.Value;

	setFirstNameError: Dispatch<boolean>;
}

export default class Controller extends react.ControllerBase<Context> {
	validate() {
		const isFirstNameValid = !!this.context?.value.firstName;

		if (!isFirstNameValid) this.context?.setFirstNameError(true);

		return isFirstNameValid;
	}
}
