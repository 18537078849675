import styled from "styled-components";
import { Column } from "uikit";

const Root = styled(Column)`
	padding-top: 16px;
	width: 100%;
	height: 100%;
`;

export default Root;
