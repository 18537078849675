import React, { memo, useState } from "react";
import { Column } from "uikit";
import styled from "styled-components";
import moment from "moment";

import Transaction from "../../services/Transaction";
import MountableSubscription from "../MountableSubscription";

import Header from "./components/Header";
import Content from "./components/Content";

interface StyledColumnProps extends Column.Props {
	visible: boolean;
}
const StyledColumn = styled(Column)<StyledColumnProps>`
	${({ visible }) => (visible ? "" : "display: none;")}
`;

const TransactionsTab: React.FC<TransactionsTab.Props> = memo((props) => {
	const {
		tableFrom,
		options = {},
		isSubscribe,
		maxedWidth,
		maxedHeight,
		...otherProps
	} = props;

	const [newOptions, setNewOptions] = useState<Transaction.SubscribeOptions>({
		dateRange: {
			from: moment().startOf("day").toDate(),
			to: moment().endOf("day").toDate(),
		},
		...options,
	});

	const [transactions, setTransactions] = useState<Transaction.Model[]>([]);

	const [selected, setSelected] = useState<number[]>([]);

	return (
		<StyledColumn
			maxedWidth={maxedWidth ?? true}
			maxedHeight={maxedHeight ?? true}
			{...otherProps}
		>
			{isSubscribe && (
				<MountableSubscription
					Service={Transaction}
					subscribeOptions={newOptions}
					onModelsChange={setTransactions}
				/>
			)}
			<Column sizes="auto 1fr" maxedWidth maxedHeight>
				<Header options={newOptions} setOptions={setNewOptions} />
				<Content
					tableFrom={tableFrom}
					value={transactions}
					selected={selected}
					setSelected={setSelected}
				/>
			</Column>
		</StyledColumn>
	);
});

declare namespace TransactionsTab {
	interface Props extends StyledColumnProps {
		tableFrom: Content.Props["tableFrom"];
		options?: Transaction.SubscribeOptions;
		isSubscribe?: boolean;
	}
}

export default TransactionsTab;
