/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable no-shadow */

import React, {
	Dispatch,
	RefAttributes,
	memo,
	useCallback,
	useMemo,
	useState,
} from "react";
import { useTranslation } from "react-i18next";
import {
	Column,
	MultilineTextBox,
	Row,
	TextBox,
	react,
	useRefWithSetter,
} from "uikit";
import Language from "../../../../../../../../../../../../services/Language";
import FieldsContainer from "../../../../../../../../../../../../components/FieldsContainer";
import useObjectEditor from "../../../../../../../../../../../../hooks/useObjectEditor";

import LabeledField from "../../../../../../../../../../../../components/LabeledField";
import Access from "./components/Access";
import TabRoot from "../TabRoot";
import InternalController from "./Controller";

const MainTab = memo(
	react.withController<MainTab.PropsBase, MainTab.Controller>(
		({ controller, value, disabled, visible, onChange }) => {
			const { t } = useTranslation();
			const [accessRef, setAccessRef] =
				useRefWithSetter<Access.Ref | null>(null);

			const valueEditor = useObjectEditor(value, onChange);

			const nameValue = valueEditor.useGetter("name");

			const [nameError, setNameError] = useState(false);

			const nameOnChange = useCallback(
				(name: string) => {
					setNameError(false);

					valueEditor.set("name", name);
				},
				// eslint-disable-next-line react-hooks/exhaustive-deps
				[valueEditor.set],
			);

			const accessValue = valueEditor.usePicker([
				"companyIds",
				"taxiServiceIds",
			]);

			const accessOnChange = valueEditor.useAssigner();

			const descriptionValue = valueEditor.useGetter("description");

			const descriptionOnChange = valueEditor.useSetter("description");

			controller.setContext({
				value,

				setNameError,

				accessRef,
			});

			const firstRow = useMemo(
				() => (
					<Row gaps="8px*" sizes="1fr*">
						<LabeledField
							label={
								t(
									"pages.mainPage.pages.accounts.tabs.carParks.editModal.content.mainTab.str0",
								) ?? ""
							}
						>
							<TextBox.TextBox
								value={nameValue}
								style={{
									minHeight: "32px",
									flex: "1 0 0",
								}}
								disabled={disabled}
								autoComplete="one-time-code"
								placeholder={
									t(
										"pages.mainPage.pages.accounts.tabs.carParks.editModal.content.mainTab.str0",
									) ?? ""
								}
								error={nameError}
								onChange={nameOnChange}
							/>
						</LabeledField>
						<Access
							ref={setAccessRef}
							value={accessValue}
							disabled={disabled}
							language={"uk"}
							onChange={accessOnChange}
						/>
					</Row>
				),
				[
					accessOnChange,
					accessValue,
					disabled,
					nameError,
					nameOnChange,
					nameValue,
					setAccessRef,
					t,
				],
			);

			return (
				<TabRoot hasPaddings visible={visible}>
					<Column
						sizes="auto!* 1fr"
						gaps="19px*"
						maxedWidth
						maxedHeight
					>
						<FieldsContainer
							label={
								t(
									"pages.mainPage.pages.accounts.tabs.carParks.editModal.content.mainTab.str2",
								) ?? ""
							}
						>
							<Column gaps="8px*">{firstRow}</Column>
						</FieldsContainer>
						<MultilineTextBox
							value={descriptionValue}
							disabled={disabled}
							placeholder={
								t(
									"pages.mainPage.pages.accounts.tabs.carParks.editModal.content.mainTab.str3",
								) ?? ""
							}
							onChange={descriptionOnChange}
						/>
					</Column>
				</TabRoot>
			);
		},
		InternalController,
	),
);

declare namespace MainTab {
	type Ref = InternalController | null;

	type Controller = InternalController;

	interface Value extends Access.Value {
		name: string;
		description: string;
	}

	interface PropsBase {
		value: Value;

		disabled: boolean;
		visible: boolean;
		language: Language;

		onChange: Dispatch<Value>;
	}

	type Props = PropsBase & RefAttributes<Ref>;
}

export default MainTab;
