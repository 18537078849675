import React, {
	Dispatch,
	useCallback,
	useEffect,
	useMemo,
	useState,
} from "react";
import { useTranslation } from "react-i18next";
import { Column, InputBorders } from "uikit";

import Counterparty from "../../../../../../../../../../../../../../../../../../services/Counterparty";
import mapByKey from "../../../../../../../../../../../../../../../../../../utils/mapByKey";
import InputModal from "../../../../../../../../../../../../../../../../../../components/InputModal";

import tPath from "../../../../../../../../../../../../constants/tPath";
import Text from "./components/Text";
import Table from "./components/Table";

const CheckSelect: React.FC<CheckSelect.Props> = ({
	checks,
	checkId,
	setCheckId,
	counterparty,
}) => {
	const { t } = useTranslation();
	const modelItemById = useMemo(() => mapByKey(checks ?? [], "id"), [checks]);

	const [isModalOpen, setIsModalOpen] = useState(false);

	const handleOpen = useCallback(() => {
		if (!checks) return;
		setIsModalOpen(true);
	}, [checks]);

	const handleClose = useCallback(() => {
		setIsModalOpen(false);
	}, []);

	const name = useMemo(
		() =>
			checkId
				? modelItemById[checkId]?.additionalFields.checkValue
				: null,
		[checkId, modelItemById],
	);

	const [selected, setSelected] = useState<number | null>(checkId);

	const onSubmit = useCallback(() => {
		setCheckId(selected);
		setIsModalOpen(false);
	}, [setCheckId, selected]);

	useEffect(() => {
		if (!checks) {
			setCheckId(null);
		}
	}, [checks, setCheckId]);

	return (
		<Column>
			<div
				onClick={handleOpen}
				style={{ cursor: checks ? "pointer" : "not-allowed" }}
			>
				<InputBorders.InputBorders
					style={{ height: 32 }}
					disabled={!counterparty}
				>
					<Text isPlaceholder={!name || name.length === 0}>
						{name ?? t([`${tPath}.modal.tabs.main.other.check`])}
					</Text>
				</InputBorders.InputBorders>
			</div>
			{isModalOpen && (
				<InputModal draggable onClose={handleClose} onSubmit={onSubmit}>
					<Table
						tableData={checks ?? []}
						additional={counterparty?.additionalFields}
						selected={selected}
						setSelected={setSelected}
					/>
				</InputModal>
			)}
		</Column>
	);
};

declare namespace CheckSelect {
	interface Props {
		counterparty: Counterparty.Model | null;
		checks: Counterparty.Model["checks"];
		checkId: Value;
		setCheckId: Dispatch<Value>;
	}

	type Value = number | null;
}

export default CheckSelect;
