import styled from "styled-components";
import { theme } from "uikit";

const Title = styled.div`
	height: 20px;

	font-family: "Lato";
	font-style: normal;
	font-weight: 700;
	font-size: 16px;
	line-height: 20px;

	color: ${theme.colors.primary};
`;

export default Title;
