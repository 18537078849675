import React, { memo } from "react";
import { useTranslation } from "react-i18next";
import { Row, Column, CheckBox } from "uikit";
import { borderStyles, columnGaps } from "../../../constants/styles";

const Seventh: React.FC<Seventh.Props> = memo(() => {
	const { t } = useTranslation();
	return (
		<Row sizes="1fr 1fr" style={borderStyles}>
			<Column gaps={columnGaps}>
				<CheckBox
					disabled
					label={
						t(
							"pages.mainPage.pages.accounts.tabs.executorTeams.modal.content.tabs.main.blocks.seventh.str200",
						) ?? ""
					}
				/>
				<CheckBox
					disabled
					label={
						t(
							"pages.mainPage.pages.accounts.tabs.executorTeams.modal.content.tabs.main.blocks.seventh.str0",
						) ?? ""
					}
				/>
				<CheckBox
					disabled
					label={
						t(
							"pages.mainPage.pages.accounts.tabs.executorTeams.modal.content.tabs.main.blocks.seventh.str1",
						) ?? ""
					}
				/>
				<CheckBox
					disabled
					label={
						t(
							"pages.mainPage.pages.accounts.tabs.executorTeams.modal.content.tabs.main.blocks.seventh.str2",
						) ?? ""
					}
				/>
				<CheckBox
					disabled
					label={
						t(
							"pages.mainPage.pages.accounts.tabs.executorTeams.modal.content.tabs.main.blocks.seventh.str3",
						) ?? ""
					}
				/>
				<CheckBox
					disabled
					label={
						t(
							"pages.mainPage.pages.accounts.tabs.executorTeams.modal.content.tabs.main.blocks.seventh.str4",
						) ?? ""
					}
				/>
			</Column>
			<Column gaps={columnGaps}>
				<CheckBox
					disabled
					label={
						t(
							"pages.mainPage.pages.accounts.tabs.executorTeams.modal.content.tabs.main.blocks.seventh.str201",
						) ?? ""
					}
				/>
				<CheckBox
					disabled
					label={
						t(
							"pages.mainPage.pages.accounts.tabs.executorTeams.modal.content.tabs.main.blocks.seventh.str202",
						) ?? ""
					}
				/>
				<CheckBox
					disabled
					label={
						t(
							"pages.mainPage.pages.accounts.tabs.executorTeams.modal.content.tabs.main.blocks.seventh.str203",
						) ?? ""
					}
				/>
				<CheckBox
					disabled
					label={
						t(
							"pages.mainPage.pages.accounts.tabs.executorTeams.modal.content.tabs.main.blocks.seventh.str5",
						) ?? ""
					}
				/>
				<CheckBox
					disabled
					label={
						t(
							"pages.mainPage.pages.accounts.tabs.executorTeams.modal.content.tabs.main.blocks.seventh.str6",
						) ?? ""
					}
				/>
				<CheckBox
					disabled
					label={
						t(
							"pages.mainPage.pages.accounts.tabs.executorTeams.modal.content.tabs.main.blocks.seventh.str7",
						) ?? ""
					}
				/>
			</Column>
		</Row>
	);
});

declare namespace Seventh {
	interface Props {}
}

export default Seventh;
