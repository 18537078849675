import React, { RefAttributes, memo } from "react";
import { Column, react, useRefWithSetter } from "uikit";

import Agent from "../../../../../../../../../../../../services/Agent";
import { PaymentProviderAccessorType } from "../../../../../../../../../../../../services/Payment/types";
import useObjectEditor from "../../../../../../../../../../../../hooks/useObjectEditor";
import Account from "../../../../../../../../../../../../components/Account";
import TabRoot from "../../components/TabRoot";

import MainData from "./components/MainData";
import Access from "./components/Access";
import Other from "./components/Other";
import Notes from "./components/Notes";
import Name from "./components/MainData/components/Name";
import Controller from "./Controller";

const MainBase: React.FC<Main.Props> = react.withController<
	Main.PropsBase,
	Controller
>((props) => {
	const {
		visible,
		hasPaddings,
		agentId,
		counterparties,
		groups,
		value,
		onChange,
		controller,
	} = props;

	const [mainDataRef, setMainDataRef] = useRefWithSetter<MainData.Ref | null>(
		null,
	);
	const [otherRef, setOtherRef] = useRefWithSetter<Other.Ref | null>(null);
	const [accessRef, setAccessRef] = useRefWithSetter<Access.Ref | null>(null);

	const editor = useObjectEditor(value, onChange);

	const name = editor.useGetter("name");
	const setName = editor.useSetter("name");

	const accountValue = editor.usePicker([
		"paymentAccounts",
		"paymentTransactions",
	]);

	const accessData = editor.usePicker(
		["isAccessToApp", "isAccessToAccount", "login", "password", "code"],
		"json",
	);

	const otherData = editor.usePicker(
		[
			"status",
			"companyId",
			"defaultTaxiServiceId",
			"latestTaxiServiceId",
			"agentGroupId",
			"counterpartyId",
			"checkId",
			"balanceId",
		],
		"json",
	);

	const additionalFields = editor.useGetter("additionalFields");
	const setAdditionalFields = editor.useSetter("additionalFields");

	const additEditor = useObjectEditor(additionalFields, setAdditionalFields);

	const mainData = additEditor.usePicker(
		["contactPersonName", "contactPersonPhone", "contactPersonEmail"],
		"json",
	);
	const notes = additEditor.usePicker(
		["orderNotes", "notes", "executorNotes"],
		"json",
	);

	controller.setContext({ mainDataRef, otherRef, accessRef });
	return (
		<TabRoot hasPaddings={hasPaddings} visible={visible}>
			<Column gaps="24px*">
				<MainData
					ref={setMainDataRef}
					name={name}
					setName={setName}
					value={mainData}
					onChange={additEditor.assign}
				/>
				<Account
					entityId={agentId}
					owner={PaymentProviderAccessorType.COMPANY}
					ownerId={otherData.companyId}
					value={accountValue}
					onChange={editor.assign}
				/>
				<Access
					ref={setAccessRef}
					value={accessData}
					onChange={editor.assign}
				/>
				<Other
					ref={setOtherRef}
					disabled={!!agentId}
					counterparties={counterparties}
					groups={groups}
					value={otherData}
					onChange={editor.assign}
				/>
				<Notes value={notes} onChange={additEditor.assign} />
			</Column>
		</TabRoot>
	);
}, Controller);

const Main = memo(MainBase);

declare namespace Main {
	type Ref = Controller | null;
	interface PropsBase extends TabRoot.Props {
		counterparties: Other.Props["counterparties"];
		groups: Other.Props["groups"];
		agentId: number | undefined;
		value: Value;
		onChange: (value: Value) => void;
	}
	type Props = PropsBase & RefAttributes<Ref>;

	interface Value extends Other.Value, Access.Value {
		name: Name.Props["value"];
		additionalFields: Agent.Model["additionalFields"];
		paymentTransactions?: Account.Value["paymentTransactions"];
		paymentAccounts: Account.Value["paymentAccounts"];
	}
}

export default Main;
