import React, { memo, useCallback, useEffect, useState } from "react";
import { useRefWithSetter } from "uikit";
import { useTranslation } from "react-i18next";

import Agent from "../../../../../../../../services/Agent";
import useObjectEditor from "../../../../../../../../hooks/useObjectEditor";
import { NonEditableProperties } from "../../../../../../../../types/NonEditableProperties";
import EditModal from "../../../../../../../../components/EditModal";
import InfoModal from "../../../../../../../../components/InfoModal";
import {
	StyledColumn,
	StyledGrid,
} from "../../../../../../../../components/common";
import tPath from "../../constants/tPath";

import { ModalTabs as Tabs } from "./constants/tabs";
import Content from "./components/Content";
import Header from "./components/Header";

const Modal: React.FC<Modal.Props> = memo((props) => {
	const {
		counterparties,
		groups,
		value,
		onCancel,
		onSave,
		headerTitle,
		loading,
	} = props;
	const { t } = useTranslation();
	const [contentRef, setContentRef] = useRefWithSetter<Content.Ref | null>(
		null,
	);

	const [infoModalLabel, setInfoModalLabel] = useState(false);
	const handleCloseInfoModal = useCallback(() => {
		setInfoModalLabel(false);
	}, [setInfoModalLabel]);

	const [tab, setTab] = useState<Tabs>(Tabs.MAIN);

	const [intrnalValue, setIntrnalValue] = useState(value);

	const editor = useObjectEditor(intrnalValue, setIntrnalValue);

	useEffect(() => {
		if (value && value.id) {
			const { paymentAccounts, agentToRewards } = value;

			setIntrnalValue({
				...intrnalValue,
				paymentAccounts,
				agentToRewards,
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [value?.agentToRewards, value?.paymentAccounts]);

	const contentValues = editor.usePicker([
		"name",
		"additionalFields",
		"isAccessToApp",
		"isAccessToAccount",
		"login",
		"password",
		"code",
		"paymentAccounts",
		"paymentTransactions",
		"status",
		"companyId",
		"defaultTaxiServiceId",
		"latestTaxiServiceId",
		"agentToRewards",
		"phones",
		"addresses",
		"agentGroupId",
		"counterpartyId",
		"checkId",
		"balanceId",
		"isArbitraryRewardAllowed",
		"isRewardAllowed",
	]);

	const handleSubmit = useCallback(() => {
		if (!contentRef.current?.validate()) return;

		const isCode = !!intrnalValue.code.keyword && !!intrnalValue.code.value;
		const isPhones = intrnalValue.phones.some((p) => p.isAgent);
		const isAddresses = intrnalValue.addresses.some((p) => p.isAgent);
		if (!isCode && !isPhones && !isAddresses) {
			setInfoModalLabel(true);
			return;
		}

		onSave(intrnalValue as Agent.New | Agent.Modified);
	}, [contentRef, intrnalValue, onSave]);

	return (
		<EditModal
			loading={loading}
			canSave={!loading}
			onCancel={onCancel}
			onSave={handleSubmit}
		>
			<StyledGrid
				areas=""
				rows="auto 1fr"
				br="5px"
				over="hidden"
				w={{ width: "1200px", min: "900px" }}
				h={{ height: "86vh", min: "500px" }}
			>
				<Header
					title={headerTitle}
					createdAt={value.createdAt}
					tab={tab}
					setTab={setTab}
				/>
				<Content
					ref={setContentRef}
					agentId={value.id}
					groups={groups}
					counterparties={counterparties}
					tab={tab}
					value={contentValues}
					onChange={editor.assign}
				/>
				{infoModalLabel && (
					<InfoModal
						width="400px"
						label={
							<StyledColumn
								style={{ whiteSpace: "nowrap" }}
								gap="16px"
								p="0 0 10px 0"
							>
								<div>{t(`${tPath}.warns.title`)}</div>
								<StyledColumn gap="8px" alignItems="flex-start">
									<div>
										{t(`${tPath}.warns.card_and_key`)}
									</div>
									<div>{t(`${tPath}.warns.phone`)}</div>
									<div>{t(`${tPath}.warns.address`)}</div>
								</StyledColumn>

								<div>{t(`${tPath}.errors.title`)}</div>

								<StyledColumn gap="8px" alignItems="flex-start">
									<div>{t(`${tPath}.errors.card_exist`)}</div>
								</StyledColumn>
							</StyledColumn>
						}
						onConfirm={handleCloseInfoModal}
					/>
				)}
			</StyledGrid>
		</EditModal>
	);
});

declare namespace Modal {
	interface Value extends Content.Value, Partial<NonEditableProperties> {}

	interface Props {
		counterparties: Content.Props["counterparties"];
		groups: Content.Props["groups"];
		value: Value;
		headerTitle: string;
		onCancel: () => void;
		onSave: (model: Agent.New | Agent.Modified) => Promise<void>;
		loading: boolean;
	}
}

export default Modal;
