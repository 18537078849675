import React, { memo, useMemo } from "react";
import { Row } from "uikit";

import Agent from "../../../../../../../../services/Agent";
import { useTypedSelector } from "../../../../../../../../redux/store";
import DefaultPageHeader from "../../../../../../../../components/DefaultPageHeader";
import {
	generateAccessName,
	AccessKey,
	hasAccess,
} from "../../../../../../../../access";
import { TabKeys, ARR_ACCESS_PART_KEY } from "../../../../constants/access";

import Call from "./components/Call";
import Balance from "./components/Balance";
import SendingPush from "./components/SendingPush";
import SendingSMS from "./components/SendingSMS";

const HeaderAdditional: React.FC<HeaderAdditional.Props> = memo((props) => {
	const { agents } = props;

	const personalRole = useTypedSelector(
		(state) => state.account.personalRole,
	);

	const AccessBtnGroup = useMemo(() => {
		const ACCESS_TAB = ARR_ACCESS_PART_KEY[TabKeys.AGENTS];

		const retval = {
			call: hasAccess(
				generateAccessName(...ACCESS_TAB, AccessKey.CALL),
				personalRole,
			),
			sendingSMS: hasAccess(
				generateAccessName(...ACCESS_TAB, AccessKey.SENDING_SMS),
				personalRole,
			),
			sendingPush: hasAccess(
				generateAccessName(...ACCESS_TAB, AccessKey.SENDEING_PUSH),
				personalRole,
			),
			balance: hasAccess(
				generateAccessName(...ACCESS_TAB, AccessKey.BALANSE),
				personalRole,
			),
			bonusBalance: hasAccess(
				generateAccessName(...ACCESS_TAB, AccessKey.BONUS_BALANSE),
				personalRole,
			),
		};

		return retval;
	}, [personalRole]);

	const mainAccount = agents[0]?.paymentAccounts.find(
		(a) => a.type === "main",
	);
	const bonusAccount = agents[0]?.paymentAccounts.find(
		(a) => a.type === "bonus",
	);

	const agent = agents.length === 1 && agents[0];

	return (
		<Row gaps="50px*" style={{ marginLeft: 50 }}>
			<Row align="center" gaps="10px*">
				{AccessBtnGroup.call && <Call agents={agents} />}
				{AccessBtnGroup.sendingSMS && <SendingSMS agents={agents} />}
				{AccessBtnGroup.sendingPush && <SendingPush agents={agents} />}
			</Row>
			<Row align="center" gaps="10px*">
				{AccessBtnGroup.balance && (
					<Balance
						agent={agent}
						paymentAccount={mainAccount}
						iconId="balance"
					/>
				)}
				{AccessBtnGroup.bonusBalance && (
					<Balance
						agent={agent}
						paymentAccount={bonusAccount}
						iconId="bonus-balance"
					/>
				)}
			</Row>
		</Row>
	);
});

declare namespace HeaderAdditional {
	interface Props extends DefaultPageHeader.Props {
		agents: Agent.Model[];
	}

	interface Value {}
}

export default HeaderAdditional;
