import React, { useCallback } from "react";
import {
	Button,
	Float,
	Popover as PopoverBase,
	Row,
	react,
	useInternal,
} from "uikit";
import { useTranslation } from "react-i18next";
import Content from "./components/Content";
import Header from "./components/Header";
import Root from "./components/Root";

const defaultValue: Content.Value = {};

function additionalFiltersOffsetCallback({
	tracker,
	float,
}: Float.OffsetCallbackOptions) {
	if (!float || !tracker) return { x: 0, y: 0 };

	const trackerSize = tracker.getSize();

	// TODO: fix useSize in Float/index.tsx, idk
	const floatSize = { width: 320, height: 242 }; // float.getSize();

	return {
		x: -Number(floatSize.width) + Number(trackerSize.width) + 13,
		y: Number(trackerSize.height) + 13,
	};
}

declare namespace Popover {
	type Value = Content.Value;

	interface PropsBase {
		open: boolean;

		onClose: () => void;
	}
}

const Popover = react.inputify<Popover.PropsBase, Popover.Value>(
	({ value, open, onChange, onClose }) => {
		const { t } = useTranslation();

		const [internalValue, setInternalValue] = useInternal(
			value ?? defaultValue,
		);

		const apply = useCallback(() => {
			onChange(internalValue);
			onClose();
		}, [internalValue, onChange, onClose]);

		const cancel = useCallback(() => {
			const prevValue = value ?? defaultValue;

			setInternalValue(prevValue);
			onChange(prevValue);
			onClose();
		}, [onChange, onClose, setInternalValue, value]);

		return (
			<PopoverBase
				open={open}
				containerId="root"
				trackerId="filters"
				offset={additionalFiltersOffsetCallback}
				onClose={onClose}
			>
				<Root align="stretch" gaps="16px 20px">
					<Header onClose={onClose} />

					<Content
						value={internalValue}
						onChange={setInternalValue}
					/>

					<Row gaps="20px" justify="end">
						<Button.Button
							text={t("filter.apply") || "Apply filter"}
							onClick={apply}
						/>

						<Button.Button
							text={t("cancel") || "Cancel"}
							onClick={cancel}
						/>
					</Row>
				</Root>
			</PopoverBase>
		);
	},
);

export default Popover;
