import React, { Dispatch } from "react";
import { useTranslation } from "react-i18next";

import CheckBoxSelect from "../../../../../../../../../../../../../../components/CheckBoxSelect";

import { options } from "./constants";

const OrderSourceTypes: React.FC<OrderSourceTypes.Props> = ({
	value,
	// disabled,
	onChange,
}) => {
	const { t } = useTranslation();
	return (
		<CheckBoxSelect
			value={value}
			disabled
			title={
				t(
					"pages.mainPage.pages.accounts.tabs.executors.editModal.content.optionTab.orderSourceTypes.str0",
				) ?? ""
			}
			options={options}
			onChange={onChange}
		/>
	);
};

declare namespace OrderSourceTypes {
	type Value = (typeof options)[number]["key"][];

	interface Props {
		value: Value;

		disabled: boolean;

		onChange: Dispatch<Value>;
	}
}

export default OrderSourceTypes;
