import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import ExecutorGroup from "../../../../../../services/ExecutorGroup";
import mapByKey from "../../../../../../utils/mapByKey";
import DeleteModal from "../../../../../../components/DeleteModal";
import DefaultPageHeader from "../../../../../../components/DefaultPageHeader";
import { useExecutorTeamsContext } from "../../../context";
import { TabKeys, ARR_ACCESS_PART_KEY } from "../../constants/access";

import translationPath from "./constants/translationPath";
import defaultValue from "./constants/defaultValue";
import Root from "./components/Root";
import Modal from "./components/Modal";
import Content from "./components/Content";

const ExecutorTeams: React.FC = () => {
	const { t } = useTranslation();
	const { language, models, destroy, store, update, getExecutorIds } =
		useExecutorTeamsContext();

	const [showModal, setShowModal] = useState(false);
	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const [showClarificationModal, setShowClarificationModal] = useState(false);
	const [isSave, setIsSave] = useState<boolean>(false);

	const [editingItem, setEditingItem] = useState<Modal.Value>(defaultValue);

	const [selected, setSelected] = useState<number[]>([]);

	const modelItemById = useMemo(() => mapByKey(models, "id"), [models]);

	const edit = useCallback(
		(id: number) => {
			const item = modelItemById[id];

			const executorIds = getExecutorIds(id);
			setEditingItem({ ...item, executorIds });
			setShowModal(true);
			setSelected([]);
		},
		[modelItemById, getExecutorIds],
	);

	const editHeaderHandler = useCallback(() => {
		edit(selected[0]);
	}, [edit, selected]);

	const editContentHandler = useCallback(
		(executorTeam: ExecutorGroup.Model.Modified) => edit(executorTeam.id),
		[edit],
	);

	const addHandler = useCallback(() => {
		setShowModal(true);
	}, []);

	const preDeleteHandler = useCallback(() => {
		setShowDeleteModal(true);
	}, []);

	const deleteHandler = useCallback(() => {
		destroy(selected);
		setSelected([]);
		setShowDeleteModal(false);
	}, [destroy, selected]);

	const cancelDeleteHandler = useCallback(() => {
		setShowDeleteModal(false);
		setSelected([]);
	}, []);

	const cancelHandler = useCallback(() => {
		setShowModal(false);
		setEditingItem(defaultValue);
	}, []);

	const saveHandler = useCallback(
		async (
			data: ExecutorGroup.Model.New | ExecutorGroup.Model.Modified,
		) => {
			let isAllOk = true;
			if (isSave) return;

			if ("id" in data && data.id) {
				setIsSave(true);
				const res = await update(data);
				isAllOk = !!res;
			} else {
				setIsSave(true);
				const res = await store(data as ExecutorGroup.Model.New);
				isAllOk = !!res;
			}

			if (isAllOk) {
				setIsSave(false);
				setShowModal(false);
			} else setIsSave(false);
		},
		[isSave, store, update],
	);

	const headerTitle = useMemo(() => {
		const name = editingItem?.name?.[language] || "";
		return editingItem?.id
			? `${t(`${translationPath}.modal.title.edit`)} "${
					name.length > 20 ? `${name.slice(0, 20)}...` : name
			  }"`
			: t(`${translationPath}.modal.title.add`);
	}, [editingItem.id, editingItem.name, language, t]);

	const labelDeleteModal = useMemo(
		() =>
			selected.length === 1
				? t(`${translationPath}.deleteModal.title`) || ""
				: t(`${translationPath}.deleteModal.title2`) || "",
		[selected.length, t],
	);

	return (
		<Root sizes="auto! 1fr" gaps="16px" maxedWidth maxedHeight>
			<DefaultPageHeader
				canEdit={selected.length === 1}
				canDelete={!!selected.length}
				onAdd={addHandler}
				onDelete={preDeleteHandler}
				onEdit={editHeaderHandler}
				accessName={ARR_ACCESS_PART_KEY[TabKeys.EXECUTOR_TEAMS]}
			/>
			<Content
				selected={selected}
				setSelected={setSelected}
				onEdit={editContentHandler}
				saveHandler={saveHandler}
			/>
			{showModal && (
				<Modal
					onCancel={cancelHandler}
					onSave={saveHandler}
					value={editingItem}
					headerTitle={headerTitle}
					createdAt={new Date().toLocaleDateString()}
					showClarificationModal={showClarificationModal}
					setShowClarificationModal={setShowClarificationModal}
				/>
			)}
			{showDeleteModal && (
				<DeleteModal
					label={labelDeleteModal}
					onCancel={cancelDeleteHandler}
					onConfirm={deleteHandler}
				/>
			)}
		</Root>
	);
};

export default ExecutorTeams;
