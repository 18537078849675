import React, { memo, useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Icon } from "uikit";

import useModelSubscribe from "../../../../../../../../../../hooks/useModelSubscribe2";
import SMSProvider from "../../../../../../../../../../services/SMSProvider";
import SMSTemplate from "../../../../../../../../../../services/SMSTemplate";
import Client from "../../../../../../../../../../services/Client";
import Message from "../../../../../../../../../../services/Message";
import {
	Popup,
	StyledP,
} from "../../../../../../../../../../components/common";

import Modal from "./components/Modal";

interface Props {
	clients: Client.Model[];
}

const SendingSMS: React.FC<Props> = memo(({ clients }) => {
	const { t } = useTranslation();
	const { models: providers } = useModelSubscribe({}, SMSProvider);
	const { models: templates } = useModelSubscribe({}, SMSTemplate);

	const notOrdersTemplates = useMemo(
		() => templates.filter((t) => !t.orderTemplate),
		[templates],
	);

	const [showModal, setShowModal] = useState(false);

	const handleShowModal = useCallback(() => {
		setShowModal(true);
	}, []);

	const handleCloseModal = useCallback(() => {
		setShowModal(false);
	}, []);

	const handleSend = useCallback(async (value: Message.Send) => {
		const res = await Message.send(value);
		if (res) {
			setShowModal(false);
		}
	}, []);

	return (
		<Popup
			useHoverControl
			trackerId={"client_call_id"}
			offset={{ x: 32, y: 33 }}
			tracker={
				<div>
					<Button.Button
						disabled={clients.length === 0}
						variant="secondary"
						onClick={handleShowModal}
						icon={<Icon id="sms" size={20} />}
					/>
					{showModal && (
						<Modal
							clients={clients}
							providers={providers}
							templates={notOrdersTemplates}
							onClose={handleCloseModal}
							onSubmit={handleSend}
						/>
					)}
				</div>
			}
		>
			{!showModal && (
				<StyledP br="4px" bgC="#000000" colors="#ffffff" p="5px 10px">
					{t(
						"pages.mainPage.pages.customers.tabs.clients.header.sendingSMS.str200",
					) ?? ""}
				</StyledP>
			)}
		</Popup>
	);
});

export default SendingSMS;
