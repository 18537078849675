import React, {
	Dispatch,
	RefAttributes,
	useCallback,
	useMemo,
	useState,
} from "react";
import { useTranslation } from "react-i18next";
import { Option, Select, react } from "uikit";

import Language from "../../../../../../../../../../../../../../../../services/Language";
import mapByKey from "../../../../../../../../../../../../../../../../utils/mapByKey";
import LabeledField from "../../../../../../../../../../../../../../../../components/LabeledField";
import { useExecutorContext } from "../../../../../../../../context";

import InternalController from "./Controller";

const ExecutorTeamId = react.withController<
	ExecutorTeamId.PropsBase,
	ExecutorTeamId.Controller
>(
	({
		value,
		onChange,

		language,
		disabled,
	}) => {
		const { t } = useTranslation();
		const { executorsGroup } = useExecutorContext();
		const [error, setError] = useState(false);

		const selectOptions = useMemo<Option<number>[]>(() => {
			if (!executorsGroup || !executorsGroup.length) return [];

			const cleared = {
				key: 0,
				label:
					t(
						"pages.mainPage.pages.accounts.tabs.executors.editModal.content.mainTab.workData.executorTeamId.str1",
					) ?? "",
				value: 0,
			};

			const items = [...executorsGroup].map((item) => ({
				key: item.id,
				label: item.name?.[language],
				value: item.id,
			}));

			return [cleared, ...items];
		}, [executorsGroup, language, t]);

		const selectOptionByKey = useMemo(
			() => mapByKey(selectOptions, "key"),
			[selectOptions],
		);

		const selectOnChange = useCallback(
			(key) => {
				setError(false);

				if (key === 0) {
					onChange(0);
					return;
				}
				onChange(selectOptionByKey[key].value);
			},
			[onChange, selectOptionByKey],
		);

		return (
			<LabeledField
				label={
					t(
						"pages.mainPage.pages.accounts.tabs.executors.editModal.content.mainTab.workData.executorTeamId.str0",
					) ?? ""
				}
			>
				<Select
					value={value}
					disabled={disabled || executorsGroup.length === 0}
					error={error}
					placeholder={
						t(
							"pages.mainPage.pages.accounts.tabs.executors.editModal.content.mainTab.workData.executorTeamId.str1",
						) ?? ""
					}
					options={selectOptions}
					onChange={selectOnChange}
				/>
			</LabeledField>
		);
	},
	InternalController,
);

declare namespace ExecutorTeamId {
	type Ref = InternalController | null;

	type Controller = InternalController;

	type Value = number | undefined;

	interface PropsBase {
		value: Value;

		language: Language;
		disabled: boolean;
		taxiServiceIds: number[];

		onChange: Dispatch<Value>;
	}

	type Props = PropsBase & RefAttributes<Ref>;
}

export default ExecutorTeamId;
