import React, { memo, useCallback } from "react";
import { ToggleButton } from "uikit";
import Modal from "../../../Modal";

const Switcher: React.FC<Switcher.Props> = memo((props) => {
	const { value, index, saveHandler } = props;

	const onChange = useCallback(
		(isAgent: boolean) => {
			saveHandler({ ...value, isAgent }, index);
		},
		[saveHandler, value, index],
	);

	return (
		<div
			onClick={(event) => event.stopPropagation()}
			style={{ maxWidth: "max-content" }}
		>
			<ToggleButton.ToggleButton
				value={value.isAgent}
				onChange={onChange}
			/>
		</div>
	);
});

declare namespace Switcher {
	interface Props {
		value: Modal.Value;
		index: number;
		saveHandler: (model: Modal.Value, index?: number | null) => void;
	}
}

export default Switcher;
