import React, { memo } from "react";
import { useTranslation } from "react-i18next";
import { CheckBox, Column, Row, Stepper } from "uikit";

import { StyledColumn } from "../../../../../../../../../../../../components/common";

import GeneralBlock from "./components/GeneralBlock";

const OrderCancellation: React.FC<OrderCancellation.Props> = memo(
	// eslint-disable-next-line arrow-body-style, @typescript-eslint/no-unused-vars
	({ value, onChange }) => {
		const { t } = useTranslation();
		const mainColGaps = "12px*";
		const rowGaps = "8px*";

		// const lang = useTypedSelector((state) => state.session.language);

		return (
			<StyledColumn w="100%" h="100%" gap="20px" p="20px">
				<Column gaps="25px*">
					<Column gaps={mainColGaps}>
						<Row align="center" justify="space-between">
							<Row gaps={rowGaps} align="center">
								<span>
									{t(
										"pages.mainPage.pages.accounts.tabs.executorTeams.modal.content.tabs.orderCancellation.str100",
									) ?? ""}
								</span>
								<Stepper disabled />
								<span>
									{t(
										"pages.mainPage.pages.accounts.tabs.executorTeams.modal.content.tabs.orderCancellation.str200",
									) ?? ""}
									:
								</span>
							</Row>
							<CheckBox
								disabled
								label={
									t(
										"pages.mainPage.pages.accounts.tabs.executorTeams.modal.content.tabs.orderCancellation.str0",
									) ?? ""
								}
							/>
						</Row>
						<GeneralBlock />
					</Column>
					<Column gaps={mainColGaps}>
						<Row align="center" justify="space-between">
							<Row gaps={rowGaps} align="center">
								<span>
									{t(
										"pages.mainPage.pages.accounts.tabs.executorTeams.modal.content.tabs.orderCancellation.str101",
									) ?? ""}
								</span>
								<Stepper disabled />
								<span>
									{t(
										"pages.mainPage.pages.accounts.tabs.executorTeams.modal.content.tabs.orderCancellation.str201",
									) ?? ""}
									:
								</span>
							</Row>
							<CheckBox
								disabled
								label={
									t(
										"pages.mainPage.pages.accounts.tabs.executorTeams.modal.content.tabs.orderCancellation.str0",
									) ?? ""
								}
							/>
						</Row>
						<GeneralBlock />
					</Column>
					<Column
						gaps={mainColGaps}
						style={{ padding: "0 0 20px 0" }}
					>
						<Row align="center" justify="space-between">
							<span>
								{t(
									"pages.mainPage.pages.accounts.tabs.executorTeams.modal.content.tabs.orderCancellation.str202",
								) ?? ""}
								:
							</span>
							<CheckBox
								disabled
								label={
									t(
										"pages.mainPage.pages.accounts.tabs.executorTeams.modal.content.tabs.orderCancellation.str0",
									) ?? ""
								}
							/>
						</Row>
						<GeneralBlock />
					</Column>
				</Column>
			</StyledColumn>
		);
	},
);

declare namespace OrderCancellation {
	interface Props {
		value: any;
		onChange: (value: any) => void;
	}
}

export default OrderCancellation;
