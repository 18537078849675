import React from "react";
import { RowDataType } from "rsuite-table";
import { TFunction } from "i18next";
import BalanceOutput from "../../../components/BalanceOutput";
import DisabledCheckbox from "../../../components/DisabledCheckbox";
import Agent from "../../../services/Agent";
import Language from "../../../services/Language";

const getAgentCellData = (
	rowData: RowDataType<Agent.Model>,
	columnId: string,
	lang: Language,
	t: TFunction<string[], undefined, string[]>,
) => {
	const {
		name,
		additionalFields: additFields,
		isAccessToApp,
		isAccessToAccount,
		status,
		paymentAccounts,
		company,
		defaultTaxiService: branch,
		agentGroup,
	} = rowData as Agent.Model;

	switch (columnId) {
		case "title":
			return name?.[lang];

		case "contactPersonName":
			return additFields.contactPersonName;

		case "contactPersonPhone":
			return additFields.contactPersonPhone;

		case "mobileApp":
			return <DisabledCheckbox value={isAccessToApp} />;

		case "cabinet":
			return <DisabledCheckbox value={isAccessToAccount} />;

		case "status":
			return t(`statuses.${status.level}`);

		case "balance":
			return <BalanceOutput value={paymentAccounts} balanceType="main" />;

		case "bonusBalance":
			return (
				<BalanceOutput value={paymentAccounts} balanceType="bonus" />
			);

		case "company":
			return company?.name[lang];

		case "taxiService":
			return branch?.settlement[lang];

		case "group":
			return agentGroup?.name;

		default:
			return null;
	}
};

export default getAgentCellData;
