/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable no-param-reassign */
/* eslint-disable no-shadow */

import React, { Dispatch, RefAttributes, memo, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Column, react } from "uikit";
import File from "../../../../../../../../../../../../services/File";
import useObjectEditor from "../../../../../../../../../../../../hooks/useObjectEditor";
import FileListWithTitle from "../../../../../../../../../../../../components/FileListWithTitle";
import TabRoot from "../TabRoot";
import InternalController from "./Controller";

const FilesTab = memo(
	react.withController<FilesTab.PropsBase, FilesTab.Controller>(
		({ value, disabled, visible, onChange }) => {
			const { t } = useTranslation();
			const valueEditor = useObjectEditor(value, onChange);

			const otherFiles = valueEditor.useGetter("otherFiles");
			const passportFiles = valueEditor.useGetter("passportFiles");
			const personalPhotos = valueEditor.useGetter("personalPhotos");

			const otherFilesOnChange = useCallback(
				(otherFiles: File.Model[]) => {
					otherFiles.forEach((file) => file.upload());

					valueEditor.set("otherFiles", otherFiles);
				},
				[valueEditor],
			);

			const passportFilesOnChange = useCallback(
				(passportFiles: File.Model[]) => {
					passportFiles.forEach((file) => file.upload());

					valueEditor.set("passportFiles", passportFiles);
				},
				[valueEditor],
			);

			const personalPhotosOnChange = useCallback(
				(personalPhotos: File.Model[]) => {
					personalPhotos.forEach((file) => file.upload());

					valueEditor.set("personalPhotos", personalPhotos);
				},
				[valueEditor],
			);

			return (
				<TabRoot hasPaddings visible={visible}>
					<Column
						gaps="24px*"
						sizes="250px!* 0.015625px!"
						maxedWidth
						maxedHeight
					>
						<FileListWithTitle
							value={otherFiles}
							disabled={disabled}
							title={
								t(
									"pages.mainPage.pages.accounts.tabs.users.editModal.content.filesTab.str0",
								) ?? ""
							}
							onChange={otherFilesOnChange}
						/>
						<FileListWithTitle
							value={passportFiles}
							disabled={disabled}
							title={
								t(
									"pages.mainPage.pages.accounts.tabs.users.editModal.content.filesTab.str1",
								) ?? ""
							}
							onChange={passportFilesOnChange}
						/>
						<FileListWithTitle
							value={personalPhotos}
							disabled={disabled}
							title={
								t(
									"pages.mainPage.pages.accounts.tabs.users.editModal.content.filesTab.str2",
								) ?? ""
							}
							onChange={personalPhotosOnChange}
						/>
						<div />
					</Column>
				</TabRoot>
			);
		},
		InternalController,
	),
);

declare namespace FilesTab {
	type Ref = InternalController | null;

	type Controller = InternalController;

	interface Value {
		passportFiles: File.Model[];
		personalPhotos: File.Model[];
		otherFiles: File.Model[];
	}

	interface PropsBase {
		value: Value;

		disabled: boolean;
		visible: boolean;

		onChange: Dispatch<Value>;
	}

	type Props = PropsBase & RefAttributes<Ref>;
}

export default FilesTab;
