/* eslint-disable no-param-reassign */
/* eslint-disable no-shadow */

import React, { Dispatch, RefAttributes, useCallback, useMemo } from "react";
import { CheckBoxWithContent, Column, Row, TimeInput, react } from "uikit";
import { isUndefined } from "lodash";
import { useTranslation } from "react-i18next";
import Schedule from "../../../../../../../../../../../../../../../../services/Schedule";

import useObjectEditor from "../../../../../../../../../../../../../../../../hooks/useObjectEditor";
import FieldsContainer from "../../../../../../../../../../../../../../../../components/FieldsContainer";

import Nullable from "../../../../../../../../../../../../../../../../types/Nullable";
import Root from "./components/Root";
import InternalController from "./Controller";
import { days } from "./constants";

const Content = react.withController<Content.PropsBase, Content.Controller>(
	({ controller, value, onChange }) => {
		const [t] = useTranslation();

		const valueEditor = useObjectEditor(value, onChange);

		const setDay = useCallback(
			(day: Content.Day, value: boolean) => {
				valueEditor.set(day, value);
			},
			[valueEditor],
		);

		const getDay = useCallback(
			(day: Content.Day) => valueEditor.get(day),
			[valueEditor],
		);

		const fromValue = valueEditor.useGetter("startTime");

		const fromOnChange = valueEditor.useSetter("startTime");

		const toValue = valueEditor.useGetter("endTime");

		const toOnChange = valueEditor.useSetter("endTime");

		const processedFromValue = useMemo(
			() => (isUndefined(fromValue) ? undefined : new Date(fromValue)),
			[fromValue],
		);

		const processedFromOnChange = useCallback(
			(date: Nullable<Date>) => {
				if (date) fromOnChange(date.getTime());
			},
			[fromOnChange],
		);

		const processedToValue = useMemo(
			() => (isUndefined(toValue) ? undefined : new Date(toValue)),
			[toValue],
		);

		const processedToOnChange = useCallback(
			(date: Nullable<Date>) => {
				if (date) {
					const dateTime =
						(date.getHours() * 3.6e3 +
							date.getMinutes() * 60 +
							date.getSeconds() +
							date.getTimezoneOffset() * 60) *
						1e3;

					toOnChange(dateTime);
				}
			},
			[toOnChange],
		);

		const content = useMemo(
			() => (
				<Column gaps="24px*">
					<FieldsContainer
						label={
							t(
								"pages.mainPage.pages.accounts.tabs.workShifts.editModal.content.schedulesTab.editModal.content.str0",
							) ?? ""
						}
					>
						<Row wrap="wrap" gaps="24px*">
							{days.map((day) => (
								<CheckBoxWithContent
									key={day}
									value={getDay(day)}
									gap="10px"
									onChange={(value) => setDay(day, value)}
								>
									{t(`days-of-week.${day}`)}
								</CheckBoxWithContent>
							))}
						</Row>
					</FieldsContainer>
					<FieldsContainer
						label={
							t(
								"pages.mainPage.pages.accounts.tabs.workShifts.editModal.content.schedulesTab.editModal.content.str1",
							) ?? ""
						}
					>
						<Row wrap="wrap" align="center" gaps="10px*">
							{t(
								"pages.mainPage.pages.accounts.tabs.workShifts.editModal.content.schedulesTab.editModal.content.str130",
							) ?? ""}
							<TimeInput
								value={processedFromValue}
								onChange={processedFromOnChange}
							/>
							{t(
								"pages.mainPage.pages.accounts.tabs.workShifts.editModal.content.schedulesTab.editModal.content.str131",
							) ?? ""}
							<TimeInput
								value={processedToValue}
								onChange={processedToOnChange}
							/>
						</Row>
					</FieldsContainer>
				</Column>
			),
			[
				getDay,
				processedFromOnChange,
				processedFromValue,
				processedToOnChange,
				processedToValue,
				setDay,
				t,
			],
		);

		return <Root>{content}</Root>;
	},
	InternalController,
);

declare namespace Content {
	type Ref = InternalController | null;

	type Controller = InternalController;

	type Day = Schedule.Model.Day;

	interface Value extends Record<Day, boolean> {
		startTime: number;
		endTime: number;
	}

	interface PropsBase {
		value: Value;

		onChange: Dispatch<Value>;
	}

	type Props = PropsBase & RefAttributes<Ref>;
}

export default Content;
