import {
	Dispatch,
	MutableRefObject,
	SetStateAction,
	useCallback,
	useMemo,
	useRef,
	useState,
} from "react";
import { SortType } from "rsuite-table";
import { useTranslation } from "react-i18next";
import moment from "moment";

import { useTypedSelector } from "../../../redux/store";
import useObjectEditor from "../../../hooks/useObjectEditor";
import { ValueLanguage } from "../../../assets/languages/langs";
import LightTable from "..";

export interface Filter extends Record<string, any> {
	active: boolean | undefined | null;
	isDefault: boolean | undefined | null;
	limit: number;
	offset: number;
	query: string;
	isDeleted: boolean | undefined;
	dateRange: {
		dateFrom: Date;
		dateTo: Date;
	};
}

export interface PayloadOption extends Record<string, any> {
	isActive: boolean;
	isNotActive: boolean;
	ctrlPressed: boolean;
	height: number;
	width: number;
	widthScroll: number;
	heightScroll: number;
	dataLength: number;
}

export interface Sort extends Record<string, any> {
	dataKey: string;
	sortType: SortType | undefined;
}

export interface Editor extends Record<string, any> {
	sort: Sort;
	payloadOption: PayloadOption;
	filter: Filter;
}

export const defaultFilter: Filter = {
	active: undefined,
	isDefault: undefined,
	isDeleted: undefined,
	query: "",
	limit: 30,
	offset: 0,
	dateRange: {
		dateFrom: moment().startOf("day").toDate(),
		dateTo: moment().endOf("day").toDate(),
	},
};

export const defaultPayloadOption: PayloadOption = {
	isActive: true,
	isNotActive: true,
	ctrlPressed: false,
	height: -1,
	width: -1,
	widthScroll: 0,
	heightScroll: 0,
	dataLength: 0,
};

export const defaultSort: Sort = {
	dataKey: "",
	sortType: "asc",
};

export const defaultEditor: Editor = {
	sort: defaultSort,
	payloadOption: defaultPayloadOption,
	filter: defaultFilter,
};
export interface CalendarLocale {
	moment?: string | undefined;
	submitButton?: string | undefined;
	cancelButton?: string | undefined;
	todayButton?: string | undefined;
}

export interface UseTableOptions {
	filter: Filter;
	sort: Sort;
	payloadOption: PayloadOption;

	locale: CalendarLocale;
	lang: ValueLanguage;
	tableRef: MutableRefObject<LightTable.Ref | null>;

	query: Filter["query"];
	active: Filter["active"];
	dateRange: Filter["dateRange"];
	limit: Filter["limit"];
	isDeleted: Filter["isDeleted"];

	ctrlPressed: PayloadOption["ctrlPressed"];
	isActive: PayloadOption["isActive"];
	isNotActive: PayloadOption["isNotActive"];
	height: PayloadOption["height"];
	width: PayloadOption["width"];
	widthScroll: PayloadOption["widthScroll"];
	heightScroll: PayloadOption["heightScroll"];
	dataLength: PayloadOption["dataLength"];

	editor: Editor;
	setEditor: Dispatch<SetStateAction<Editor>>;
	onChange: <Fields extends keyof Editor>(
		fields: Partial<Editor> | Pick<Editor, Fields>,
	) => void;

	setFilter: (value: Filter) => void;
	setSort: (value: Sort) => void;
	setPayloadOption: (value: PayloadOption) => void;

	setQuery: (value: Filter["query"]) => void;
	setLimit: (value: Filter["limit"]) => void;
	setActive: (value: Filter["active"]) => void;
	setIsDefault: (value: Filter["default"]) => void;
	setDateRange: (value: Filter["dateRange"]) => void;
	setIsDeleted: (value: Filter["isDeleted"]) => void;

	setIsActive: (value: PayloadOption["isActive"]) => void;
	setIsNotActive: (value: PayloadOption["isNotActive"]) => void;
	setHeight: (value: PayloadOption["height"]) => void;
	setWidth: (value: PayloadOption["width"]) => void;
	setWidthScroll: (value: PayloadOption["widthScroll"]) => void;
	setHeightScroll: (value: PayloadOption["heightScroll"]) => void;
	setDataLength: (value: PayloadOption["dataLength"]) => void;

	reset: () => void;
	onActive: (type: "yes" | "no") => void;
	onSort: (dataKey: Sort["dataKey"], sortType: Sort["sortType"]) => void;
	onScroll: (x: number, y: number) => void;
	onKeyDown: (event: React.KeyboardEvent) => React.KeyboardEvent<Element>;
	onKeyUp: (event: React.KeyboardEvent) => React.KeyboardEvent<Element>;
}

export interface UseTableOptionsProps {
	itemLimit?: number;
	heightRow?: number;
	scrollHeight?: number;
	value?: Editor;
	setValue?: <Fields extends keyof Editor>(
		fields: Partial<Editor> | Pick<Editor, Fields>,
	) => void;
	setValueEditor?: Dispatch<SetStateAction<Editor>>;
}

export const useTableOptions = (
	data?: UseTableOptionsProps,
): UseTableOptions => {
	const { t } = useTranslation();
	const lang = useTypedSelector((state) => state.session.language);
	const tableRef = useRef<LightTable.Ref | null>(null);

	const {
		itemLimit,
		heightRow,
		scrollHeight,
		value,
		setValue,
		setValueEditor,
	} = useMemo(
		() => ({
			itemLimit: 2,
			heightRow: 44,
			scrollHeight: 300,
			...data,
		}),
		[data],
	);

	// editor
	const [editor, setEditor] = useState<Editor>(defaultEditor);
	const valueEditor = useObjectEditor(
		value ?? editor,
		setValue ?? setValueEditor ?? setEditor,
	);
	const onChange = valueEditor.useAssigner();

	const payloadOption = valueEditor.useGetter("payloadOption");
	const setPayloadOption = valueEditor.useSetter("payloadOption");
	const sort = valueEditor.useGetter("sort");
	const setSort = valueEditor.useSetter("sort");
	const filter = valueEditor.useGetter("filter");
	const setFilter = valueEditor.useSetter("filter");

	// filter
	const filterEditor = useObjectEditor(filter, setFilter);

	const active = filterEditor.useGetter("active");
	const setActive = filterEditor.useSetter("active");
	const isDefault = filterEditor.useGetter("isDefault");
	const setIsDefault = filterEditor.useSetter("isDefault");

	const limit = filterEditor.useGetter("limit");
	const setLimit = filterEditor.useSetter("limit");
	const dateRange = filterEditor.useGetter("dateRange");
	const setDateRange = filterEditor.useSetter("dateRange");
	const query = filterEditor.useGetter("query");
	const setQuery = filterEditor.useSetter("query");
	const isDeleted = filterEditor.useGetter("isDeleted");
	const setIsDeleted = filterEditor.useSetter("isDeleted");

	// payload
	const payloadOptionEditor = useObjectEditor(
		payloadOption,
		setPayloadOption,
	);

	const isActive = payloadOptionEditor.useGetter("isActive");
	const setIsActive = payloadOptionEditor.useSetter("isActive");
	const isNotActive = payloadOptionEditor.useGetter("isNotActive");
	const setIsNotActive = payloadOptionEditor.useSetter("isNotActive");
	const ctrlPressed = payloadOptionEditor.useGetter("ctrlPressed");
	const setCtrlPressed = payloadOptionEditor.useSetter("ctrlPressed");
	const height = payloadOptionEditor.useGetter("height");
	const setHeight = payloadOptionEditor.useSetter("height");
	const width = payloadOptionEditor.useGetter("width");
	const setWidth = payloadOptionEditor.useSetter("width");
	const widthScroll = payloadOptionEditor.useGetter("widthScroll");
	const setWidthScroll = payloadOptionEditor.useSetter("widthScroll");
	const heightScroll = payloadOptionEditor.useGetter("heightScroll");
	const setHeightScroll = payloadOptionEditor.useSetter("heightScroll");
	const dataLength = payloadOptionEditor.useGetter("dataLength");
	const setDataLength = payloadOptionEditor.useSetter("dataLength");

	const onSort = useCallback<UseTableOptions["onSort"]>(
		(dataKey, sortType) => {
			setSort({ dataKey, sortType });
		},
		[setSort],
	);

	const onActive = useCallback(
		(type: "yes" | "no") => {
			if (type === "yes") {
				if (!isActive && isNotActive) {
					setActive(undefined);
					setIsActive(true);
				} else if (isActive && isNotActive) {
					setActive(false);
					setIsActive(false);
				} else if (isActive && !isNotActive) {
					setActive(null);
					setIsActive(false);
				} else {
					setActive(true);
					setIsActive(true);
				}
				return;
			}

			if (type === "no") {
				if (isActive && !isNotActive) {
					setActive(undefined);
					setIsNotActive(true);
				} else if (isActive && isNotActive) {
					setActive(true);
					setIsNotActive(false);
				} else if (!isActive && isNotActive) {
					setActive(null);
					setIsNotActive(false);
				} else {
					setActive(false);
					setIsNotActive(true);
				}
			}
		},
		[setActive, isActive, setIsActive, isNotActive, setIsNotActive],
	);

	const onScroll = useCallback<UseTableOptions["onScroll"]>(
		(x, y) => {
			const top = Math.round(Math.abs(y));
			const left = Math.round(Math.abs(x));

			setWidthScroll(left);
			setHeightScroll(top);

			if (width >= left && height === top) return;
			if (width < left && width !== 999999999) setWidth(left);
			if (top === 0) return;
			if (top > 0 && width < 999999999) setWidth(999999999);

			const contextHeight = dataLength * heightRow;
			const tableContainerHeight =
				tableRef.current?.root.getBoundingClientRect().height ?? 0;
			const valueHeight = contextHeight - top - tableContainerHeight;

			if (valueHeight < scrollHeight) {
				if (height - 9 >= top) return;
				setHeight(top);
				setLimit(limit + itemLimit);
			}
		},
		[
			setWidthScroll,
			setHeightScroll,
			width,
			height,
			setWidth,
			dataLength,
			heightRow,
			scrollHeight,
			setHeight,
			setLimit,
			limit,
			itemLimit,
		],
	);

	const onKeyDown = useCallback<UseTableOptions["onKeyDown"]>(
		(event) => {
			if (event.ctrlKey && !ctrlPressed) setCtrlPressed(true);
			return event;
		},
		[setCtrlPressed, ctrlPressed],
	);

	const onKeyUp = useCallback<UseTableOptions["onKeyUp"]>(
		(event) => {
			if (!event.ctrlKey && ctrlPressed) setCtrlPressed(false);
			return event;
		},
		[setCtrlPressed, ctrlPressed],
	);

	const reset = useCallback(() => {
		onChange(defaultEditor);
	}, [onChange]);

	const locale = useMemo<UseTableOptions["locale"]>(
		() => ({
			moment: lang,
			submitButton: t(`ok`) || "Ok",
			cancelButton: t(`cancel`) || "Cancel",
			todayButton: t(`today`) || "Today",
		}),
		[lang, t],
	);

	return useMemo<UseTableOptions>(
		() => ({
			editor,
			setEditor,
			onChange,
			filter,
			sort,
			payloadOption,
			limit,
			query,
			lang,
			active,
			isDefault,
			isActive,
			isNotActive,
			tableRef,
			ctrlPressed,
			dateRange,
			height,
			width,
			widthScroll,
			heightScroll,
			dataLength,
			isDeleted,
			setIsDeleted,
			reset,
			onActive,
			onSort,
			onScroll,
			onKeyDown,
			onKeyUp,
			setSort,
			setLimit,
			setFilter,
			setActive,
			setIsDefault,
			setIsActive,
			setIsNotActive,
			setCtrlPressed,
			setDateRange,
			setWidth,
			setHeight,
			setWidthScroll,
			setHeightScroll,
			setPayloadOption,
			setDataLength,
			setQuery,
			locale,
		}),
		[
			locale,
			editor,
			onChange,
			filter,
			sort,
			payloadOption,
			limit,
			query,
			lang,
			active,
			isActive,
			isNotActive,
			isDefault,
			ctrlPressed,
			dateRange,
			height,
			width,
			widthScroll,
			heightScroll,
			dataLength,
			isDeleted,
			setIsDeleted,
			reset,
			onActive,
			onSort,
			onScroll,
			onKeyDown,
			onKeyUp,
			setSort,
			setLimit,
			setFilter,
			setActive,
			setIsActive,
			setIsDefault,
			setIsNotActive,
			setCtrlPressed,
			setDateRange,
			setWidth,
			setHeight,
			setWidthScroll,
			setHeightScroll,
			setPayloadOption,
			setDataLength,
			setQuery,
		],
	);
};
