import { react } from "uikit";
import MainData from ".";
import { validateEmail } from "../../../../../../../../../../../../../../utils/validateEmail";
import { validatePhone } from "../../../../../../../../../../../../../../utils/validatePhone";

interface Context {
	value: MainData.Value;
	setNameError: (err: boolean) => void;
	setEmailError: (err: boolean) => void;
}

export default class Controller extends react.ControllerBase<Context> {
	validate() {
		const isValidName = !!this.context?.value.name.trim();
		const phones = this.context?.value.phones;
		const email = this.context?.value.email;

		const isValidEmail = email ? validateEmail(email.trim()) : true;
		const isValidPhones = phones?.length
			? phones?.every((phone) => validatePhone(phone) === true)
			: true;

		if (!isValidName) this.context?.setNameError(true);
		if (!isValidEmail) this.context?.setEmailError(true);

		return isValidName && isValidEmail && isValidPhones;
	}
}
