import React, {
	Dispatch,
	RefAttributes,
	useCallback,
	useMemo,
	useState,
} from "react";
import { useTranslation } from "react-i18next";
import { MultiSelect, Option, react } from "uikit";

import Role from "../../../../../../../../../../../../../../../../services/Role";
import { useTypedSelector } from "../../../../../../../../../../../../../../../../redux/store";
import useModelSubscribe from "../../../../../../../../../../../../../../../../hooks/useModelSubscribe2";
import mapByKey from "../../../../../../../../../../../../../../../../utils/mapByKey";
import LabeledField from "../../../../../../../../../../../../../../../../components/LabeledField";

import InternalController from "./Controller";

const RoleIds = react.withController<RoleIds.PropsBase, RoleIds.Controller>(
	({
		controller,

		value,

		disabled,
		taxiServiceIds,

		onChange,
	}) => {
		const { t } = useTranslation();

		const language = useTypedSelector((state) => state.session.language);

		const roleData = useModelSubscribe(
			{ taxiServiceIds, assignableTo: "dispatcher", language },
			Role,
		);

		const roles = useMemo(() => roleData.models, [roleData.models]);

		const [error, setError] = useState(false);

		const selectOptions = useMemo<Option<number>[]>(
			() =>
				roles.map((role) => ({
					key: role.id,
					label: role.name,
					value: role.id,
				})),
			[roles],
		);

		const selectOptionByKey = useMemo(
			() => mapByKey(selectOptions, "key"),
			[selectOptions],
		);

		const selectOnChange = useCallback(
			(keys: any[]) => {
				setError(false);

				onChange(keys.map((key) => selectOptionByKey[key].value));
			},
			[onChange, selectOptionByKey],
		);
		controller.setContext({ value, setError });

		return (
			<LabeledField
				label={
					t(
						"pages.mainPage.pages.accounts.tabs.users.editModal.content.mainTab.workData.roleIds.str0",
					) ?? ""
				}
			>
				<MultiSelect
					value={value}
					disabled={disabled}
					error={error}
					placeholder={
						t(
							"pages.mainPage.pages.accounts.tabs.users.editModal.content.mainTab.workData.roleIds.str1",
						) ?? ""
					}
					options={selectOptions}
					onChange={selectOnChange}
				/>
			</LabeledField>
		);
	},
	InternalController,
);

declare namespace RoleIds {
	type Ref = InternalController | null;

	type Controller = InternalController;

	type Value = number[];

	interface PropsBase {
		value: Value;

		disabled: boolean;
		taxiServiceIds: number[];

		onChange: Dispatch<Value>;
	}

	type Props = PropsBase & RefAttributes<Ref>;
}

export default RoleIds;
