import React, { Dispatch, RefAttributes, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { react } from "uikit";
import LabeledField from "../../../../../../../../../../../../../../../../components/LabeledField";

import SelectWithModal from "../../../../../../../../../../../../../../../../components/SelectWithModal";
import CarBrandSelect from "../../../../../../../../../../../../../../../../components/CarBrandSelect";
import InternalController from "./Controller";

const CarBrandId = react.withController<
	CarBrandId.Props,
	CarBrandId.Controller
>(
	({
		controller,

		value,

		disabled,
		carBaseTypeId,

		onChange,
	}) => {
		const { t } = useTranslation();
		const [error, setError] = useState(false);

		const selectOnChange = useCallback(
			(newValue: SelectWithModal.Value<number>) => {
				setError(false);

				onChange(newValue as number | undefined);
			},
			[onChange],
		);

		controller.setContext({ value, setError });

		return (
			<LabeledField
				label={
					t(
						"pages.mainPage.pages.accounts.tabs.car.editModal.content.mainTab.mainData.carBrandId.str0",
					) ?? ""
				}
			>
				<CarBrandSelect
					value={value}
					disabled={disabled}
					carBaseTypeId={carBaseTypeId}
					error={error}
					onChange={selectOnChange}
				/>
			</LabeledField>
		);
	},
	InternalController,
);

declare namespace CarBrandId {
	type Ref = InternalController | null;

	type Controller = InternalController;

	type Value = number | undefined;

	interface PropsBase {
		value: Value;

		disabled: boolean;
		carBaseTypeId?: number;

		onChange: Dispatch<Value>;
	}

	type Props = PropsBase & RefAttributes<Ref>;
}

export default CarBrandId;
