import React, { useMemo } from "react";
import moment from "moment";

import { Role } from "../../../../../../../../../../../../services";
import { CellContentRoot } from "../../../../../../../../../../../../components/LightTable";

const CreatedAtCellContent: React.FC<CreatedAtCellContent.Props> = ({
	item,
}) => {
	const content = useMemo(
		() =>
			item.createdAt ? moment(item.createdAt).format("DD.MM.YYYY") : "",
		[item?.createdAt],
	);

	return (
		<CellContentRoot alignItems="center" w="100%" h="100%">
			{content}
		</CellContentRoot>
	);
};

declare namespace CreatedAtCellContent {
	interface Props {
		item: Role.Model;
	}
}

export default CreatedAtCellContent;
