import React, { memo, useMemo } from "react";
import { useTranslation } from "react-i18next";

import InfoModal from "../../../../../../components/InfoModal";
import {
	Divider,
	StyledColumn,
	StyledP,
	StyledRow,
} from "../../../../../../components/common";
import { AutodetectPhone } from "../../hooks";

const AutodetectPhoneErrorModalBase: React.FC<
	AutodetectPhoneErrorModal.Props
> = ({ value, show, setShowModal }) => {
	const { t } = useTranslation();
	const tPath = `mainPage.customers.errors.autodetectPhone`;

	const title = useMemo(() => t([`${tPath}.title`]), [t, tPath]);

	const contentError = useMemo(() => {
		const agentText = t([`${tPath}.agent`]);
		const conterpartyText = t([`${tPath}.contragent`]);
		const checkText = t([`${tPath}.contragent_check`]);
		const cardText = t([`${tPath}.contragent_card`]);
		const nameText = t([`${tPath}.full_name`]);
		const phoneText = t([`${tPath}.phone`, "phone"]);
		const duplicate = t([`${tPath}.duplicate`, "duplicate"]);
		return (
			<StyledColumn gap="10px" p="20px 0">
				{value.map((item, key) => (
					<StyledColumn key={key} gap="5px">
						{item.isDuplicate && (
							<StyledRow gap="5px">
								<StyledP>{`${duplicate}`}</StyledP>
							</StyledRow>
						)}
						{item.isCounterparty && (
							<>
								<StyledRow gap="5px">
									<StyledP
										font={{ fw: "500" }}
									>{`${conterpartyText}:`}</StyledP>
									<StyledP>{`${item.counterpartyName}`}</StyledP>
								</StyledRow>
								<StyledRow gap="5px">
									<StyledP
										font={{ fw: "500" }}
									>{`${checkText}:`}</StyledP>
									<StyledP>{`${item.checkValueName}`}</StyledP>
								</StyledRow>
								<StyledRow gap="5px">
									<StyledP
										font={{ fw: "500" }}
									>{`${cardText}:`}</StyledP>
									<StyledP>{`${item.cardName}`}</StyledP>
								</StyledRow>

								<StyledRow gap="5px">
									<StyledP
										font={{ fw: "500" }}
									>{`${nameText}:`}</StyledP>
									<StyledP>{`${item.surname}`}</StyledP>
									<StyledP>{`${item.name}`}</StyledP>
									<StyledP>{`${item.fatherName}`}</StyledP>
								</StyledRow>
							</>
						)}
						{item.isAgent && (
							<>
								<StyledRow gap="5px">
									<StyledP
										font={{ fw: "500" }}
									>{`${agentText}:`}</StyledP>
									<StyledP>{`${item.agentName}`}</StyledP>
								</StyledRow>
								<StyledRow gap="5px">
									<StyledP
										font={{ fw: "500" }}
									>{`${cardText}:`}</StyledP>
									<StyledP>{`${item.cardName}`}</StyledP>
								</StyledRow>
							</>
						)}
						<StyledRow gap="5px">
							<StyledP
								font={{ fw: "500" }}
							>{`${phoneText}:`}</StyledP>
							<StyledP>{`${item.value}`}</StyledP>
						</StyledRow>
						<Divider side="top" />
					</StyledColumn>
				))}
			</StyledColumn>
		);
	}, [t, tPath, value]);

	if (!show) return <></>;

	return (
		<InfoModal
			w="100%"
			width="max-content"
			justify="start"
			label={title}
			content={contentError}
			onConfirm={() => setShowModal?.(false)}
		/>
	);
};
const AutodetectPhoneErrorModal = memo(AutodetectPhoneErrorModalBase);

declare namespace AutodetectPhoneErrorModal {
	interface Props {
		value: AutodetectPhone[];
		show?: boolean;
		setShowModal?: (value: React.SetStateAction<boolean>) => void;
	}
}

export default AutodetectPhoneErrorModal;
