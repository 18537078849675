import React, {
	Dispatch,
	RefAttributes,
	memo,
	useCallback,
	useMemo,
	useState,
} from "react";
import { Option, Select, react } from "uikit";
import { Skeleton } from "@mui/material";
import { useTranslation } from "react-i18next";
import InternalController from "./Controller";
import LabeledField from "../../../../../../../../../../../../../../../../components/LabeledField";
import { useTypedSelector } from "../../../../../../../../../../../../../../../../redux/store";
import TaxiService from "../../../../../../../../../../../../../../../../services/TaxiService";
import CompanySelect from "../CompanySelect";
import tPath from "../../../../../../../../../../constants/tPath";

declare namespace TaxiServiceSelect {
	type Ref = InternalController;
	type Controller = InternalController;

	interface PropsBase {
		companyId: CompanySelect.Value;
		taxiServices?: TaxiService.Model[];
		disabled: boolean;
		value: Value;
		onChange: Dispatch<Value>;
	}
	type Props = PropsBase & RefAttributes<Ref>;

	type Value = number;
}

const TaxiServiceSelectBase = react.withController<
	TaxiServiceSelect.PropsBase,
	TaxiServiceSelect.Controller
>(({ controller, taxiServices, companyId, disabled, value, onChange }) => {
	const { t } = useTranslation();
	const lang = useTypedSelector((state) => state.session.language);

	const [taxiServiceIdError, setTaxiServiceIdError] = useState(false);

	const onTaxiServiceIdChange = useCallback(
		(newValue) => {
			onChange(newValue);
			setTaxiServiceIdError(false);
		},
		[onChange],
	);

	controller.setContext({ value, setTaxiServiceIdError });

	const options = useMemo<Option<TaxiService.Model["id"]>[]>(() => {
		const options = taxiServices?.map((taxiService) => ({
			key: taxiService.id,
			label: taxiService.settlement[lang],
			value: taxiService.id,
		}));

		return options || [];
	}, [taxiServices, lang]);

	return (
		<LabeledField
			label={t(`${tPath}.modal.tabs.main.other.registrationCity`) || ""}
		>
			{!taxiServices ? (
				<Skeleton variant="rectangular" width={275.5} height={32} />
			) : (
				<Select
					disabled={disabled}
					value={value}
					onChange={onTaxiServiceIdChange}
					options={options}
					placeholder={
						companyId == null
							? t(`selectCompanyFirst`) || ""
							: t(
									`${tPath}.modal.tabs.main.other.chooseBranch`,
							  ) || ""
					}
					error={taxiServiceIdError}
				/>
			)}
		</LabeledField>
	);
}, InternalController);

const TaxiServiceSelect = memo(TaxiServiceSelectBase);

export default TaxiServiceSelect;
