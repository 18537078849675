import React, {
	RefAttributes,
	memo,
	useCallback,
	useMemo,
	useState,
} from "react";
import { TextBox, react } from "uikit";
import { useTranslation } from "react-i18next";

import { countryCodePhone } from "../../../../../../../../../../../../../../utils/validatePhone";
import LabeledField from "../../../../../../../../../../../../../../components/LabeledField";
import tPath from "../../../../../../../../constants/tPath";

import InternalController from "./Controller";

const path = `${tPath}.modal.tabs.main.phone`;

const PhoneBase = react.withController<Phone.PropsBase, Phone.Controller>(
	({ disabled, phone, setPhone, controller }) => {
		const { t } = useTranslation();

		const [error, setError] = useState<"wrong" | "notfound" | null>(null);

		const handlePhoneChange = useCallback(
			(newPhone: string) => {
				const modifyPhone = countryCodePhone(newPhone, "uk");
				setError(null);
				setPhone(modifyPhone);
			},
			[setPhone],
		);

		const errorText = useMemo(() => {
			if (error === "notfound") return t(`${path}.error`);
			if (error === "wrong") return t(`${path}.error1`);
			return "";
		}, [error, t]);

		controller.setContext({ value: phone, setError });
		return (
			<LabeledField label={t(`${path}.title`) || ""}>
				<TextBox.TextBox
					disabled={disabled}
					error={errorText}
					placeholder={t(`${path}.placeholder`) || ""}
					value={phone ?? undefined}
					onChange={handlePhoneChange}
				/>
			</LabeledField>
		);
	},
	InternalController,
);

const Phone = memo(PhoneBase);

declare namespace Phone {
	type Ref = InternalController | null;
	type Controller = InternalController;

	interface PropsBase {
		disabled?: boolean;
		phone: Value;
		setPhone: (x: Value) => void;
	}
	type Props = PropsBase & RefAttributes<Ref>;

	type Value = string | undefined;
}

export default Phone;
