import React, { Dispatch, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Icon, InputGroup, theme } from "uikit";
import useObjectEditor from "../../../../hooks/useObjectEditor";
import SearchIconBorders from "../../../SearchIconBorders";
import SearchTextBox from "../../../SearchTextBox";

import Root from "./components/Root";
import List from "./components/List";

type Key = string | number;
interface Option<T> {
	key: Key;
	label: string;
	value: T;
}

function Content<OptionValue>({
	value,
	filters,
	options,
	loading,
	onChange,
	onChangeFilters,
}: Content.Props<OptionValue>) {
	const filtersEditor = useObjectEditor(filters, onChangeFilters);

	const searchValue = filtersEditor.useGetter("search");
	const searchOnChange = filtersEditor.useSetter("search");

	const setOffset = filtersEditor.useSetter("offset");
	const setCount = filtersEditor.useSetter("count");

	const listOnChangeViewBox = useCallback(
		(offset: number, count: number) => {
			setOffset(offset);
			setCount(count);
		},
		[setCount, setOffset],
	);

	const { t } = useTranslation();
	return (
		<Root align="stretch" sizes="auto! 1fr" gaps="15px*">
			<InputGroup.InputGroup sizes="auto 1fr">
				<SearchIconBorders>
					<Icon
						id="search2"
						size={16}
						colors={[theme.colors.disabled_text]}
					/>
				</SearchIconBorders>
				<SearchTextBox
					placeholder={`${t("selectModal2.content.str200") ?? ""}...`}
					value={searchValue}
					onChange={searchOnChange}
				/>
			</InputGroup.InputGroup>
			<List
				value={value}
				options={options}
				loading={loading}
				onChange={onChange}
				onChangeViewBox={listOnChangeViewBox}
			/>
		</Root>
	);
}

declare namespace Content {
	type Value = List.Value;

	interface Filters {
		search?: string;
		offset?: number;
		count?: number;
	}

	interface Props<OptionValue> {
		value: Value;
		filters: Filters;

		options: (Option<OptionValue> | null)[];
		loading: boolean;

		onChange: Dispatch<Value>;
		onChangeFilters: Dispatch<Filters>;
	}
}

export default Content;
