/* eslint-disable no-shadow */

import React, {
	Dispatch,
	RefAttributes,
	memo,
	useCallback,
	useMemo,
	useState,
} from "react";
import { Key, Option, Select, react } from "uikit";
import { Skeleton } from "@mui/material";
import { useTranslation } from "react-i18next";

import { useTypedSelector } from "../../../../../../../../../../../../../../../../../../../redux/store";
import Company from "../../../../../../../../../../../../../../../../../../../services/Company";
import Client from "../../../../../../../../../../../../../../../../../../../services/Client";
import useObjectEditor from "../../../../../../../../../../../../../../../../../../../hooks/useObjectEditor";
import useModelSubscribe from "../../../../../../../../../../../../../../../../../../../hooks/useModelSubscribe";
import RequiredKeys from "../../../../../../../../../../../../../../../../../../../types/RequiredKeys";
import LabeledField from "../../../../../../../../../../../../../../../../../../../components/LabeledField";

import InternalController from "./Controller";

declare namespace CompanySelect {
	type Ref = InternalController;

	type Controller = InternalController;

	type Value = RequiredKeys<
		Partial<Pick<Client.Model, "companyId" | "carClassId">>
	>;

	interface PropsBase {
		disabled: boolean;

		value: Value;
		onChange: Dispatch<Value>;
	}

	type Props = PropsBase & RefAttributes<Ref>;
}

const CompanySelectBase = react.withController<
	CompanySelect.PropsBase,
	CompanySelect.Controller
>(({ controller, disabled, value, onChange }) => {
	const { t } = useTranslation();
	const language = useTypedSelector((state) => state.session.language);
	const companies = useModelSubscribe({}, Company);

	const options = useMemo<Option<Company.Model["id"]>[]>(
		() =>
			companies?.cache.map((company) => ({
				key: company.id,
				label: company.name[language],
				value: company.id,
			})) || [],
		[companies?.cache, language],
	);

	const valueEditor = useObjectEditor(value, onChange);

	const companyId = valueEditor.useGetter("companyId");
	const setCompanyId = valueEditor.useSetter("companyId");
	const setCarClassId = valueEditor.useSetter("carClassId");

	const onCompanyIdChange = useCallback(
		(newValue: Key) => {
			setCompanyId(newValue as typeof companyId);
			setCompanyError(false);
			setCarClassId(undefined);
		},
		[setCompanyId, setCarClassId],
	);

	const [companyError, setCompanyError] = useState(false);
	controller.setContext({
		companyId,
		onCompanyError: setCompanyError,
	});

	return (
		<LabeledField
			label={
				t(`mainPage.customers.clients.main.other.company`) || "Company"
			}
		>
			{!companies ? (
				<Skeleton variant="rectangular" width={275.5} height={32} />
			) : (
				<Select
					disabled={disabled}
					value={companyId}
					onChange={onCompanyIdChange}
					options={options}
					error={companyError}
					placeholder={
						t(`mainPage.customers.clients.main.other.no_data`) ||
						"No data"
					}
				/>
			)}
		</LabeledField>
	);
}, InternalController);

const CompanySelect = memo(CompanySelectBase);

export default CompanySelect;
