import React, { memo, useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Icon, Row, theme } from "uikit";
import { cloneDeep } from "lodash";

import { PaymentProviderAccessorType } from "../../../../../../../../../../../../../../../../../../../../../../services/Payment/types";
import formatNumber from "../../../../../../../../../../../../../../../../../../../../../../utils/formatNumber";
import { updateBalance } from "../../../../../../../../../../../../../../../../../../../../../../utils/updateBalances";
import { useCurrencyGlobalSettings } from "../../../../../../../../../../../../../../../../../../../../../../hooks";
import { PaymentAccount } from "../../../../../../../../../../../../../../../../../../../../../../types/PaymentAccount";
import BalanceModal from "../../../../../../../../../../../../../../../../../../../../../../components/BalanceModal";

import Label from "./components/Label";

const Balance: React.FC<Balance.Props> = memo((props) => {
	const {
		companyId,
		value,
		disabled,
		label,
		generalMainAmount,
		generalBonusAmount,
		generalThresholdBalance,
		isGeneralMainBalance,
		isGeneralBonusBalance,
		useGeneralThresholdBalance,
		ignoreThresholdBalance,
		allowBonusBalance,
		paymentAccount,
		paymentAccounts,
		onSubmit,
	} = props;
	const { t } = useTranslation();
	const currencyGlobalSettings = useCurrencyGlobalSettings();

	const [showModal, setShowModal] = useState(false);
	const [errorModal, setErrorModal] = useState<boolean>(false);

	const handleOpenModal = useCallback(() => {
		setShowModal(true);
	}, []);

	const handleCloseModal = useCallback(() => {
		setShowModal(false);
	}, []);

	const handleSubmit = useCallback(
		async (data: { amount: number; description: string }) => {
			try {
				const { amount, description } = data;

				if (!description) {
					setErrorModal(true);
					return;
				}
				if (errorModal) setErrorModal(false);

				if (value.type === "threshold") {
					value.amount = amount;
					value.description = description;
					handleCloseModal();
				}

				if (!amount || !companyId || value.type === "threshold") return;

				const updatedAccount: PaymentAccount = {
					...value,
					isEdited: !!amount,
					amount,
					description,
				};

				const res = await updateBalance(
					updatedAccount,
					companyId,
					PaymentProviderAccessorType.COMPANY,
				);

				const changedData = data;
				const findedIndex = paymentAccounts.findIndex(
					(paymentAcc) => paymentAcc.type === paymentAccount.type,
				);

				const copyPaymentAccounts = cloneDeep(paymentAccounts);

				copyPaymentAccounts[findedIndex].amount += changedData.amount;
				onSubmit(copyPaymentAccounts);
				setImmediate(() => {
					if (res) handleCloseModal();
				});
			} catch (err) {
				console.error("balance submit error", err);
			}
		},
		[
			companyId,
			errorModal,
			handleCloseModal,
			onSubmit,
			paymentAccount.type,
			paymentAccounts,
			value,
		],
	);
	const threshold = useMemo(() => {
		if (value.type === "threshold") {
			return {
				amount: value.amount,
				description: value.description,
			};
		}
		return {
			amount: undefined,
			description: undefined,
		};
	}, [value.amount, value.description, value.type]);

	const valueThreshold = useMemo(() => {
		if (!useGeneralThresholdBalance) return threshold;

		return { amount: generalThresholdBalance ?? 0, description: "" };
	}, [generalThresholdBalance, threshold, useGeneralThresholdBalance]);

	const disabledThreshold = useMemo(() => {
		if (useGeneralThresholdBalance) return true;
		if (ignoreThresholdBalance) return true;
		return false;
	}, [useGeneralThresholdBalance, ignoreThresholdBalance]);

	const disabledBonusCheckBox = useMemo(
		() =>
			isGeneralBonusBalance ||
			ignoreThresholdBalance ||
			!allowBonusBalance,
		[allowBonusBalance, ignoreThresholdBalance, isGeneralBonusBalance],
	);

	const disabledButton = useMemo(() => {
		if (value.type === "main") {
			return isGeneralMainBalance;
		}
		if (value.type === "bonus") {
			return disabledBonusCheckBox;
		}

		return disabledThreshold;
	}, [
		disabledBonusCheckBox,
		disabledThreshold,
		isGeneralMainBalance,
		value.type,
	]);

	const formattedNumber = useMemo(() => {
		if (value.type === "main") {
			if (isGeneralMainBalance) {
				return formatNumber(generalMainAmount ?? 0);
			}
			return formatNumber(value.amount ?? 0);
		}
		if (value.type === "bonus") {
			if (isGeneralBonusBalance) {
				return formatNumber(generalBonusAmount ?? 0);
			}
			return formatNumber(value.amount ?? 0);
		}

		return formatNumber(valueThreshold.amount ?? 0);
	}, [
		value.type,
		value.amount,
		valueThreshold.amount,
		isGeneralMainBalance,
		generalMainAmount,
		isGeneralBonusBalance,
		generalBonusAmount,
	]);

	return (
		<>
			<Row align="center" gaps="4px 11.5px">
				<Icon id="wallet" size={16} colors={[theme.colors.success]} />
				<Label>
					{formattedNumber} {currencyGlobalSettings}
				</Label>
				<Button.Button
					disabled={disabled || disabledButton}
					text={
						label ??
						t(
							"pages.mainPage.pages.customers.tabs.counterparties.modal.content.accounts.modal.content.tabs.main.account.balance.str201",
						) ??
						""
					}
					style={{
						minHeight: "32px",
					}}
					onClick={handleOpenModal}
				/>
			</Row>
			{showModal && (
				<BalanceModal
					error={errorModal}
					onClose={handleCloseModal}
					onSubmit={handleSubmit}
					defaultAmount={threshold.amount}
					defaultDescription={threshold.description}
				/>
			)}
		</>
	);
});

declare namespace Balance {
	interface Props {
		value: Value;
		companyId: number | undefined;
		label?: string;
		disabled?: boolean;
		generalMainAmount: number | undefined;
		generalBonusAmount: number | undefined;
		generalThresholdBalance: number | undefined;
		isGeneralMainBalance: boolean;
		isGeneralBonusBalance: boolean;
		ignoreThresholdBalance: boolean;
		useGeneralThresholdBalance: boolean;
		allowBonusBalance: boolean;
		paymentAccounts: PaymentAccount[];
		paymentAccount: PaymentAccount;
		onSubmit: (value: PaymentAccount[]) => void;
	}

	type Value = PaymentAccount;
}

export default Balance;
