import React, { Dispatch, RefAttributes, useMemo } from "react";
import { react, useRefWithSetter } from "uikit";

import useObjectEditor from "../../../../../../../../../../hooks/useObjectEditor";
import { CustomersBlacklistTabs as Tabs } from "../../constants/tabs";
import { ModelId } from "../../../../../../../../../../types/ModelId";

import Root from "./components/Root";
import InternalController from "./Controller";
import Main from "./tabs/Main";
import History from "./tabs/History";

const Content = react.withController<Content.PropsBase, Content.Controller>(
	({ controller, id, value, tab, onChange }) => {
		const [mainTabRef, setMainTabRef] = useRefWithSetter<Main.Ref | null>(
			null,
		);
		controller.setContext({ mainTabRef });

		const valueEditor = useObjectEditor(value, onChange);

		const mainTabValue = valueEditor.usePicker([
			"companyIds",
			"phone",
			"description",
			"surname",
			"name",
			"fatherName",
			"level",
		]);
		const assigner = valueEditor.useAssigner();

		const content = useMemo(
			() => (
				<>
					<Main
						id={id}
						visible={tab === Tabs.MAIN}
						ref={setMainTabRef}
						value={mainTabValue}
						onChange={assigner}
					/>
					<History
						visible={tab === Tabs.HISTORY}
						value={[]}
						onChange={() => {}}
					/>
				</>
			),
			[tab, setMainTabRef, mainTabValue, assigner, id],
		);

		return <Root>{content}</Root>;
	},
	InternalController,
);

declare namespace Content {
	type Ref = InternalController | null;
	type Controller = InternalController;

	interface PropsBase {
		id?: ModelId;
		value: Value;
		onChange: Dispatch<Value>;
		tab: Tabs;
	}
	type Props = PropsBase & RefAttributes<Ref>;

	interface Value extends Main.Value {}
}

export default Content;
