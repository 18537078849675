/* eslint-disable no-shadow */

import React, { memo } from "react";
import Root from "./components/Root";
import ControlButtons from "../../../../../../../../../../../../../../../PreferencesPages/components/ScreenDirectory/components/components/ControlButtons";

const Header = memo<Header.Props>((props) => (
	<Root gaps="10px*" align="center" maxedWidth>
		<ControlButtons {...props} />
	</Root>
));

declare namespace Header {
	interface Props extends ControlButtons.Props {}
}

export default Header;
