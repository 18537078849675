import Content from "./index";

export enum Columns {
	SURNAME = "surname",
	NAME = "name",
	FATHER_NAME = "fatherName",
	PHONES = "phone",
}

export const columns: Content.ColumnType[] = [
	{ id: Columns.SURNAME, label: "Фамилия", width: 150 },
	{ id: Columns.NAME, label: "Имя", width: 150 },
	{ id: Columns.FATHER_NAME, label: "Отчество", width: 150 },
	{ id: Columns.PHONES, label: "Телефоны" },
];

export const defaultColumnsId = columns.map(({ id }) => id);
