import React, {
	Dispatch,
	RefAttributes,
	useCallback,
	// useMemo,
	useState,
} from "react";
import { react } from "uikit";
import { useTranslation } from "react-i18next";
import { isNumber } from "lodash";

import LabeledField from "../../../../../../../../../../../../../../../../../components/LabeledField";
import SelectWithModal from "../../../../../../../../../../../../../../../../../components/SelectWithModal";
import { MultiSelectCarClassWithModal } from "../../../../../../../../../../../../../../../../../components/common";

import InternalController from "./Controller";

const CarClassId = react.withController<
	CarClassId.Props,
	CarClassId.Controller
>(
	({
		controller,
		taxiServiceId,
		value,
		onChange,

		disabled,
	}) => {
		const { t } = useTranslation();

		const [error, setError] = useState(false);

		const selectOnChange = useCallback(
			(newValue: SelectWithModal.Value<number>) => {
				setError(false);

				onChange(newValue as number | undefined);
			},
			[onChange],
		);

		controller.setContext({ value, setError });

		return (
			<LabeledField
				label={
					t(
						`mainPage.customers.clients.main.additionalData.vehicleClass`,
					) || "car class"
				}
			>
				<MultiSelectCarClassWithModal
					value={value ? [value] : []}
					disabled={
						disabled || !isNumber(taxiServiceId) || !taxiServiceId
					}
					error={error}
					onChange={(values) => selectOnChange(values.at(0))}
					titleText={
						t(
							"pages.mainPage.pages.accounts.tabs.car.editModal.content.mainTab.mainData.carClassIId.str0",
						) ?? ""
					}
					selectOnlyOne
					showSelectAll={false}
					subscribeOptions={{
						taxiServiceIds: isNumber(taxiServiceId)
							? [taxiServiceId]
							: [],
					}}
				/>
			</LabeledField>
		);
	},
	InternalController,
);

declare namespace CarClassId {
	type Ref = InternalController | null;

	type Controller = InternalController;

	type Value = number | undefined;

	interface PropsBase {
		value: Value;
		onChange: Dispatch<Value>;

		disabled?: boolean;
		taxiServiceId?: number;
	}

	type Props = PropsBase & RefAttributes<Ref>;
}

export default CarClassId;
