import React, { memo } from "react";
import { Column } from "uikit";

import useObjectEditor from "../../../../../../../../../../../../hooks/useObjectEditor";

import TabRoot from "../../components/TabRoot";
import Header from "./components/Header";
import Main from "./components/Main";

const Reward: React.FC<Reward.Props> = memo((props) => {
	const { visible, hasPaddings, value, onChange, agentId } = props;

	const editor = useObjectEditor(value, onChange);

	const agentToRewards = editor.useGetter("agentToRewards");
	const setRewards = editor.useSetter("agentToRewards");

	const headerValue = editor.usePicker([
		"isArbitraryRewardAllowed",
		"isRewardAllowed",
	]);

	const assigner = editor.useAssigner();

	return (
		<TabRoot hasPaddings={hasPaddings} visible={visible}>
			<Column gaps="24px*" maxedWidth maxedHeight>
				<Header value={headerValue} onChange={assigner} />
				<Main
					agentId={agentId}
					agentToRewards={agentToRewards}
					setAgentToRewards={setRewards}
				/>
			</Column>
		</TabRoot>
	);
});

declare namespace Reward {
	interface Value extends Header.Value {
		agentToRewards: Main.Props["agentToRewards"];
	}

	interface Props extends TabRoot.Props {
		agentId?: number;
		value: Value;
		onChange: (value: Value) => void;
	}
}

export default Reward;
