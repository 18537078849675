/* eslint-disable no-shadow */

import React, { Dispatch, RefAttributes, useMemo } from "react";
import { react, useRefWithSetter } from "uikit";
import Root from "./components/Root";
import MainTab from "./components/MainTab";
import Language from "../../../../../../../../../../services/Language";
import InternalController from "./Controller";
import useObjectEditor from "../../../../../../../../../../hooks/useObjectEditor";
import HistoryTab from "./components/HistoryTab";
import ExecutorSelectTab from "../../../../../../../../../../components/ExecutorSelectTab";
import DispatcherSelectTab from "../../../../../../../../../../components/DispatcherSelectTab";
import CarSelectTab from "../../../../../../../../../../components/CarSelectTab";
import useTaxiServiceIdsDecoder from "../../../../../../../../../../hooks/useTaxiServiceIdsDecoder";

const Content = react.withController<Content.PropsBase, Content.Controller>(
	({ controller, value, type, disabled, language, history, onChange }) => {
		const [mainTabRef, setMainTabRef] =
			useRefWithSetter<MainTab.Ref | null>(null);

		controller.setContext({ mainTabRef });

		const decodeTaxiServiceIds = useTaxiServiceIdsDecoder();

		const valueEditor = useObjectEditor(value, onChange);

		const companyIds = valueEditor.useGetter("companyIds");
		const taxiServiceIds = valueEditor.useGetter("taxiServiceIds");

		const decodedTaxiServiceIds = useMemo(
			() => decodeTaxiServiceIds(companyIds, taxiServiceIds),
			[companyIds, decodeTaxiServiceIds, taxiServiceIds],
		);

		const mainTabValue = valueEditor.usePicker([
			"companyIds",
			"taxiServiceIds",
			"name",
			"description",
		]);

		const dispatcherSelectTabValue = valueEditor.usePicker([
			"dispatcherIds",
		]);

		const executorSelectTabValue = valueEditor.usePicker(["executorIds"]);

		const carSelectTabValue = valueEditor.usePicker(["carIds"]);

		const anyTabOnChange = valueEditor.useAssigner();

		const content = useMemo(
			() => (
				<>
					<MainTab
						ref={setMainTabRef}
						value={mainTabValue}
						disabled={disabled}
						visible={type === "main"}
						language={language}
						onChange={anyTabOnChange}
					/>
					<DispatcherSelectTab
						value={dispatcherSelectTabValue}
						disabled={disabled}
						visible={type === "user-select"}
						language={language}
						allowedTaxiServiceIds={decodedTaxiServiceIds}
						onChange={anyTabOnChange}
					/>
					<ExecutorSelectTab
						value={executorSelectTabValue}
						disabled={disabled}
						visible={type === "executor-select"}
						language={language}
						allowedTaxiServiceIds={decodedTaxiServiceIds}
						onChange={anyTabOnChange}
					/>
					<CarSelectTab
						value={carSelectTabValue}
						disabled={disabled}
						visible={type === "car-select"}
						language={language}
						allowedTaxiServiceIds={decodedTaxiServiceIds}
						onChange={anyTabOnChange}
					/>
					<HistoryTab
						value={history}
						visible={type === "history"}
						language={language}
					/>
				</>
			),
			[
				setMainTabRef,
				mainTabValue,
				disabled,
				type,
				language,
				anyTabOnChange,
				dispatcherSelectTabValue,
				decodedTaxiServiceIds,
				executorSelectTabValue,
				carSelectTabValue,
				history,
			],
		);

		return <Root>{content}</Root>;
	},
	InternalController,
);

declare namespace Content {
	type Ref = InternalController | null;

	type Controller = InternalController;

	type Type =
		| "main"
		| "user-select"
		| "executor-select"
		| "car-select"
		| "history";

	interface Value
		extends MainTab.Value,
			DispatcherSelectTab.Value,
			ExecutorSelectTab.Value,
			CarSelectTab.Value {}

	interface PropsBase {
		value: Value;

		type: Type;
		disabled: boolean;
		language: Language;
		history: HistoryTab.Value;

		onChange: Dispatch<Value>;
	}

	type Props = PropsBase & RefAttributes<Ref>;
}

export default Content;
