import styled from "styled-components";
import { Column } from "uikit";

const Root = styled(Column)`
	width: 700px;

	padding: 24px;
`;

export default Root;
