import React, { Dispatch, memo, useCallback, useMemo, useState } from "react";
import { Column, Icon } from "uikit";
import { useTranslation } from "react-i18next";

import DefaultPageHeader from "../../../../../../../../../../../../components/DefaultPageHeader";
import DefaultPageHeaderButton from "../../../../../../../../../../../../components/DefaultPageHeaderButton";
import Agent from "../../../../../../../../../../../../services/Agent";
import {
	UseTableOptions,
	useTableOptions,
} from "../../../../../../../../../../../../components/LightTable";
import {
	Popup,
	StyledP,
} from "../../../../../../../../../../../../components/common";

import Table from "./components/Table";
import Modal from "./components/Modal";

const Main: React.FC<Main.Props> = memo(
	({ editorTable, onChangeTable, agents, agentById, value, onChange }) => {
		const { t } = useTranslation();
		const [showModal, setShowModal] = useState(false);

		const { setQuery } = useTableOptions({
			value: editorTable,
			setValue: onChangeTable,
		});
		const selectedAgents = useMemo(
			() => agents.filter((agent) => value.includes(agent.id)),
			[agents, value],
		);

		const [selected, setSelected] = useState<number[]>([]);

		const editHandler = useCallback(() => {
			setShowModal(true);
			setSelected([]);
		}, []);

		const unpinHandler = useCallback(async () => {
			onChange(
				selectedAgents
					.filter((agent) => !selected.includes(agent.id))
					.map(({ id }) => id),
			);
			setSelected([]);
		}, [onChange, selected, selectedAgents]);

		const cancelHandler = useCallback(() => {
			setShowModal(false);
			setQuery("");
		}, [setQuery]);

		const saveHandler = useCallback(
			(newIds: number[]) => {
				const payload = [...value, ...newIds];
				onChange(payload);
				setShowModal(false);
				setQuery("");
			},
			[onChange, setQuery, value],
		);

		const dataAgents = useMemo(
			() => [...agents].filter((item) => !value.includes(item.id)),
			[agents, value],
		);

		return (
			<Column sizes="auto 1fr" gaps="8px*">
				<DefaultPageHeader
					buttons={
						<>
							<Popup
								useHoverControl
								trackerId={"agent_table_add_button_id"}
								offset={{ x: 32, y: 33 }}
								tracker={
									<DefaultPageHeaderButton
										id="agent_table_add_button_id"
										variant={"primary"}
										icon={<Icon id="pencil" size={16} />}
										onClick={editHandler}
									/>
								}
							>
								<StyledP
									br="4px"
									bgC="#000000"
									colors="#ffffff"
									p="5px 10px"
								>
									{t("table.buttons.add")}
								</StyledP>
							</Popup>
							<Popup
								useHoverControl
								trackerId={"agent_table_delete_button_id"}
								offset={{ x: 32, y: 33 }}
								tracker={
									<DefaultPageHeaderButton
										id="agent_table_delete_button_id"
										disabled={!selected.length}
										variant={"secondary"}
										icon={<Icon id="trash" size={20} />}
										onClick={unpinHandler}
									/>
								}
							>
								<StyledP
									br="4px"
									bgC="#000000"
									colors="#ffffff"
									p="5px 10px"
								>
									{t("table.buttons.delete")}
								</StyledP>
							</Popup>
						</>
					}
				/>
				<Table
					value={selectedAgents}
					selected={selected}
					setSelected={setSelected}
					editorTable={editorTable}
					onChangeTable={onChangeTable}
				/>
				{showModal && (
					<Modal
						agents={dataAgents}
						agentById={agentById}
						value={value}
						onCancel={cancelHandler}
						onSave={saveHandler}
						editorTable={editorTable}
						onChangeTable={onChangeTable}
					/>
				)}
			</Column>
		);
	},
);

declare namespace Main {
	interface Props {
		agents: Agent.Model[];
		agentById: Modal.Props["agentById"];
		editorTable: UseTableOptions["editor"];
		onChangeTable: UseTableOptions["onChange"];
		value: Value;
		onChange: Dispatch<Value>;
	}

	type Value = Modal.Value;
}

export default Main;
