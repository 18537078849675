import React, { memo, useCallback, useMemo } from "react";
import { Row, Tabs, theme } from "uikit";
import { useTranslation } from "react-i18next";
import Title from "./components/Title";
import Root from "./components/Root";
import { CustomersBlacklistTabs, tabs } from "../../constants/tabs";
import tPath from "../../../../constants/tPath";

const Header: React.FC<Header.Props> = memo(
	({ tab, title, createdAt, setTab }) => {
		const { t } = useTranslation();

		const tTabs = useMemo(
			() =>
				tabs.map((internalTab) => ({
					...internalTab,
					label: t(`${tPath}.modal.tabs.${internalTab.key}.title`),
				})),
			[t],
		);

		const handleTabChange = useCallback(
			(newTab) => {
				setTab(newTab);
			},
			[setTab],
		);

		return (
			<Root sizes="auto!*">
				<Row
					style={{ padding: "16px 20px" }}
					align="center"
					justify="space-between"
					gaps="10px"
				>
					<Title>{title}</Title>
					<Row gaps="7px">
						<span>{t(`createdAt`)}:</span>
						<span
							style={{
								color: theme.colors.primary,
								fontWeight: 600,
							}}
						>
							{createdAt
								? new Date(createdAt).toLocaleDateString()
								: new Date().toLocaleDateString()}
						</span>
					</Row>
				</Row>
				<Tabs
					value={tab}
					options={tTabs}
					variant="panels"
					onChange={handleTabChange}
				/>
			</Root>
		);
	},
);

declare namespace Header {
	interface Props {
		tab: CustomersBlacklistTabs;
		setTab: (tab: CustomersBlacklistTabs) => void;
		title: string;
		createdAt?: string;
	}
}

export default Header;
