import React, { Dispatch, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { CheckBoxWithContent, Row } from "uikit";
import useObjectEditor from "../../../../../../../../../../hooks/useObjectEditor";
import Error from "./components/Error";

const Footer: React.FC<Footer.Props> = ({ value, error, onChange }) => {
	const valueEditor = useObjectEditor(value, onChange);

	const activeOnChange = useCallback(
		(active: boolean) => {
			valueEditor.set("active", active);
		},
		[valueEditor],
	);

	const defaultOnChange = useCallback(
		(defaultValue: boolean) => {
			valueEditor.set("default", defaultValue);
		},
		[valueEditor],
	);

	const { t } = useTranslation();
	return (
		<Row
			align="center"
			justify="space-between"
			gaps="24px"
			sizes="1fr auto!"
		>
			<Row gaps="24px">
				<CheckBoxWithContent
					gap="10px"
					value={valueEditor.get("active")}
					onChange={activeOnChange}
				>
					{t(
						"pages.mainPage.pages.accounts.tabs.workShifts.editModal.footer.str150",
					) ?? ""}
				</CheckBoxWithContent>
				<CheckBoxWithContent
					gap="10px"
					value={valueEditor.get("default")}
					onChange={defaultOnChange}
				>
					{t(
						"pages.mainPage.pages.accounts.tabs.workShifts.editModal.footer.str151",
					) ?? ""}
				</CheckBoxWithContent>
			</Row>
			<Error>{error}</Error>
		</Row>
	);
};

declare namespace Footer {
	interface Value {
		active: boolean;
		default: boolean;
	}

	interface Props {
		value: Value;

		error: string;

		onChange: Dispatch<Value>;
	}
}

export default Footer;
