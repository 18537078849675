/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable no-shadow */

import React, { Dispatch, RefAttributes, memo } from "react";
import { Column, react } from "uikit";

import useObjectEditor from "../../../../../../../../../../../../hooks/useObjectEditor";

import TabRoot from "../TabRoot";
import InternalController from "./Controller";
import Passport from "./components/Passport";
import DrivingLicense from "./components/DrivingLicense";
import BankCard from "./components/BankCard";
import IE from "./components/IE";

const AdditionalDataTab = memo(
	react.withController<
		AdditionalDataTab.PropsBase,
		AdditionalDataTab.Controller
	>(({ value, disabled, visible, onChange }) => {
		const valueEditor = useObjectEditor(value, onChange);

		const assignValue = valueEditor.useAssigner();

		const passportValue = valueEditor.usePicker(
			Passport.Value.propertyNames,
		);

		const drivingLicenseValue = valueEditor.usePicker(
			DrivingLicense.Value.propertyNames,
		);

		const bankCardValue = valueEditor.usePicker(
			BankCard.Value.propertyNames,
		);

		const IEValue = valueEditor.usePicker(IE.Value.propertyNames);

		return (
			<TabRoot hasPaddings visible={visible}>
				<Column sizes="auto!*" gaps="19px*" maxedWidth maxedHeight>
					<Passport
						value={passportValue}
						disabled={disabled}
						onChange={assignValue}
					/>
					<DrivingLicense
						value={drivingLicenseValue}
						disabled={disabled}
						onChange={assignValue}
					/>
					<BankCard
						value={bankCardValue}
						disabled={disabled}
						onChange={assignValue}
					/>
					<IE
						value={IEValue}
						disabled={disabled}
						onChange={assignValue}
					/>
					<div style={{ height: 5 }} />
				</Column>
			</TabRoot>
		);
	}, InternalController),
);

declare namespace AdditionalDataTab {
	type Ref = InternalController | null;

	type Controller = InternalController;

	interface Value
		extends Passport.Value,
			DrivingLicense.Value,
			BankCard.Value,
			IE.Value {}

	interface PropsBase {
		value: Value;

		disabled: boolean;
		visible: boolean;

		onChange: Dispatch<Value>;
	}

	type Props = PropsBase & RefAttributes<Ref>;
}

export default AdditionalDataTab;
