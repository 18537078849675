import React, {
	RefAttributes,
	useCallback,
	useEffect,
	useMemo,
	useState,
} from "react";
import { compact } from "lodash";
import { useTranslation } from "react-i18next";
import { v4 as uuid } from "uuid";
import { CheckBox, Row, react, useRefWithSetter } from "uikit";

import Agent from "../../../../../../../../../../../../../../../services/Agent";
import { useTypedSelector } from "../../../../../../../../../../../../../../../redux/store";
import useModelSubscribe from "../../../../../../../../../../../../../../../hooks/useModelSubscribe2";
import useObjectEditor from "../../../../../../../../../../../../../../../hooks/useObjectEditor";
import EditModal from "../../../../../../../../../../../../../../../components/EditModal";
import { NonEditableProperties } from "../../../../../../../../../../../../../../../types/NonEditableProperties";
import { PaymentAccount } from "../../../../../../../../../../../../../../../types/PaymentAccount";
import { useAutodetectPhones } from "../../../../../../../../../../../hooks";
import Accounts from "../../..";
import tPath from "../../constants/tPath";

import { CardsTabs } from "./components/Content/tabs";
import InternalController from "./Controller";
import Root from "./components/Root";
import Header from "./components/Header";
import Content from "./components/Content";

const Modal = react.withController<Modal.PropsBase, Modal.Controller>(
	({
		controller,
		value,
		onCancel,
		onSave,
		paymentAccounts,
		companyId,
		taxiServiceId,
		counterpartyId,
		cards,
		checks,
	}) => {
		const { t } = useTranslation();
		const { setAutodetectPhones } = useAutodetectPhones();
		const lang = useTypedSelector((state) => state.session.language);
		const [contentRef, setContentRef] =
			useRefWithSetter<Content.Ref | null>(null);

		const subscribeAgent = useModelSubscribe({}, Agent);

		const [tab, setTab] = useState<Header.Tab>(CardsTabs.MAIN);
		const [internalValue, setInternalValue] = useState(value);

		const valueEditor = useObjectEditor(internalValue, setInternalValue);

		const active = valueEditor.useGetter("active");
		const setActive = valueEditor.useSetter("active");

		const contentValue = valueEditor.usePicker([
			"code",
			"additionalFields",
			"otherFiles",
			"personalFiles",
			"accessAllEmployeeToPersonalApp",
			"isAccessToCorporateAccount",
			"activationDate",
			"completionDate",
			"corporateLogin",
			"corporatePassword",
			"employees",
			"isLoginOnlyByEmployee",
			"employeeDeleteIds",
		]);

		const assigner = valueEditor.useAssigner();

		const cardNumber = value?.code?.value;

		const modalOnSave = useCallback(async () => {
			const validate = contentRef.current?.validate();
			if (!validate) return;
			setAutodetectPhones([]);
			onSave(internalValue);
		}, [contentRef, internalValue, onSave, setAutodetectPhones]);

		const createdAt = valueEditor.get("createdAt");

		const modelsAgent = useMemo(
			() => subscribeAgent.models || [],
			[subscribeAgent],
		);

		useEffect(() => {
			const { employees, code } = internalValue;

			const counterpartiesPhoneList = compact(
				employees
					.map((employee) => {
						const { phones, customer } = employee;
						const existPhone = phones
							.filter((phone) => phone.isCounterparty === true)
							.map(({ id, value, isCounterparty }) => ({
								value,
								phoneId: id,
								customerId: customer.id,
								employeeId: employee.id || "",
								isEmployeeId: internalValue.id === employee.id,
								counterpartyName:
									t([
										`mainPage.customers.counterparties.modal.tabs.employees.modal.tabs.main.this_counterparty`,
									]) || "",
								checkValueName:
									t([
										`mainPage.customers.counterparties.modal.tabs.employees.modal.tabs.main.this_card`,
									]) || "",
								cardName: code.value || "",
								fatherName: customer.fatherName || "",
								name: customer.name || "",
								surname: customer.surname || "",
								isDuplicate: false,
								isCounterparty,
							}))
							?.flat();

						if (existPhone.length) return existPhone;
						return null;
					})
					?.flat(),
			);

			const agentPhoneList = modelsAgent
				.map((agent) =>
					agent?.phones
						?.filter((item) => item.isAgent === true)
						?.map((phone) => ({
							value: phone.number,
							phoneId: uuid(),
							isAgent: phone.isAgent,
							agentId: agent?.id,
							agentName: agent?.name?.[lang] || "",
							cardName: agent?.code?.value || "",
						})),
				)
				.flat();
			setAutodetectPhones([
				...counterpartiesPhoneList,
				...agentPhoneList,
			]);
		}, [internalValue, lang, modelsAgent, setAutodetectPhones, t]);

		controller.setContext({ contentRef });
		return (
			<EditModal
				footer={
					<Row align="center">
						<CheckBox
							label={t(`${tPath}.modal.active`) || ""}
							value={active}
							onChange={setActive}
						/>
					</Row>
				}
				onCancel={onCancel}
				onSave={modalOnSave}
			>
				<Root sizes="auto! 1fr">
					<Header
						tab={tab}
						title={
							cardNumber
								? `${t(
										`${tPath}.modal.editTitle`,
								  )} "${cardNumber}"`
								: t(`${tPath}.modal.title`)
						}
						createdAt={createdAt}
						onChangeTab={setTab}
					/>
					<Content
						ref={setContentRef}
						id={value.id}
						setTab={setTab}
						value={contentValue}
						onChange={assigner}
						tab={tab}
						paymentAccounts={paymentAccounts}
						companyId={companyId}
						taxiServiceId={taxiServiceId}
						counterpartyId={counterpartyId}
						cards={cards}
						checks={checks}
					/>
				</Root>
			</EditModal>
		);
	},
	InternalController,
);

declare namespace Modal {
	type Ref = InternalController | null;
	type Controller = InternalController;

	interface PropsBase {
		value: Value;
		onCancel: () => void;
		onSave: (value: Value) => void;
		paymentAccounts: PaymentAccount[];
		companyId?: number;
		taxiServiceId?: number;
		counterpartyId?: number;
		cards: Value[];
		checks?: Accounts.Check[];
	}
	type Props = PropsBase & RefAttributes<Ref>;

	interface Value extends Content.Value, Partial<NonEditableProperties> {
		active: boolean;
		checkId?: number;
		cardId?: number;
		rideCounter?: {
			total: number;
			failed: number;
			success: number;
			cancelled: number;
		};
		employeeDeleteIds?: number[];
	}
}

export default Modal;
