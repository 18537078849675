import React, { memo } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Icon, Row, theme } from "uikit";

const Label = styled.div`
	font-family: "Lato";
	font-style: normal;
	font-weight: 600;
	font-size: 18px;
	line-height: 22px;
	letter-spacing: 0.15px;
	color: ${theme.colors.primary};
`;

const Header: React.FC<Header.Props> = memo(({ onClose }) => {
	const { t } = useTranslation();
	return (
		<Row align="center" justify="space-between">
			<Label>
				{t(
					"transactionsTab.header.popupFilter.popover.header.str100",
				) ?? ""}
			</Label>
			<div onClick={onClose} style={{ cursor: "pointer" }}>
				<Icon id="close" size={12} colors={[theme.colors.secondary]} />
			</div>
		</Row>
	);
});

declare namespace Header {
	export interface Props {
		onClose: () => void;
	}
}

export default Header;
