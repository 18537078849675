import Modal from "../components/Modal";

const defaultValue: Modal.Value = {
	active: true,
	personalFiles: [],
	otherFiles: [],
	accessAllEmployeeToPersonalApp: false,
	isAccessToCorporateAccount: false,
	activationDate: new Date(),
	isLoginOnlyByEmployee: false,
	code: {
		value: "",
		keyword: "",
	},
	additionalFields: {
		tripLimit: {
			active: false,
			amountByPeriod: {
				active: false,
				amount: 0,
				customPeriod: 0,
			},
			amountByTrip: {
				active: false,
				maxAmountOfTrip: 0,
			},
			countByPeriod: {
				active: false,
				numberOfTrips: 0,
				customPeriod: 0,
			},
			distanceByPeriod: {
				active: false,
				distance: 0,
				customPeriod: 0,
			},
			availableChangeOrder: {
				active: false,
			},
		},
	},
	employees: [],
};

export default defaultValue;
