import { ColumnId } from "../../../../constants/tables/types";
import LightTable from "../../../LightTable";

export const columnProps: Record<
	ColumnId<"client">,
	Parameters<typeof LightTable.Column>[0]
> = {
	id: {
		width: 50,
	},

	rideCount: {
		width: 125,
	},
	balance: {
		width: 75,
	},
	bonusBalance: {
		width: 120,
	},
	bonusRides: {
		width: 150,
	},

	totalOrderCount: {
		width: 125,
	},
	completedOrderCount: {
		width: 125,
	},
	orderEstimationCount: {
		width: 125,
	},
	canceledOrderCount: {
		width: 125,
	},
	canceledOrderCountNoCarFound: {
		width: 125,
	},

	firstName: {
		flexGrow: 1,
	},
	lastName: {
		flexGrow: 1,
	},
	fatherName: {
		flexGrow: 1,
	},
	birthday: {
		width: 125,
	},
	gender: {
		width: 75,
	},
	app: {
		width: 90,
	},
	email: {
		flexGrow: 1,
	},
	phones: {
		flexGrow: 1,
	},
	mainLoyaltyProgram: {
		width: 100,
	},
	additionalLoyaltyProgram: {
		width: 100,
	},

	bonusCard: {
		width: 120,
	},
	tariff: {
		width: 150,
	},
	defaultCarClass: {
		width: 250,
	},

	status: {
		width: 140,
	},
	company: {
		width: 120,
	},
	defaultTaxiService: {
		width: 160,
	},
	latestTaxiService: {
		width: 180,
	},

	notes: {
		flexGrow: 1,
	},

	group: {
		flexGrow: 1,
	},

	createdAt: {
		width: 175,
	},
	updatedAt: {
		width: 175,
	},
	deletedAt: {
		width: 175,
	},
};

export const sortableColumns: Partial<Record<ColumnId<"client">, string>> = {
	id: "id",

	// TODO: ride counts here
	// rideCount: "rideCount",

	firstName: "name",
	lastName: "surname",
	fatherName: "fatherName",

	phones: "phone",

	status: "status",
	defaultTaxiService: "defaultTaxiService",
	latestTaxiService: "latestTaxiService",
	company: "company",

	createdAt: "createdAt",
	updatedAt: "updatedAt",
	deletedAt: "deletedAt",
};

Object.keys(sortableColumns).forEach((key) => {
	columnProps[key].sortable = true;
});
