import React, {
	Dispatch,
	RefAttributes,
	memo,
	useCallback,
	useMemo,
	useState,
} from "react";
import { Option, Select, react } from "uikit";
import { Skeleton } from "@mui/material";
import { useTranslation } from "react-i18next";

import Agent from "../../../../../../../../../../../../../../../../services/Agent";
import Company from "../../../../../../../../../../../../../../../../services/Company";
import { useTypedSelector } from "../../../../../../../../../../../../../../../../redux/store";
import useModelSubscribe from "../../../../../../../../../../../../../../../../hooks/useModelSubscribe";
import LabeledField from "../../../../../../../../../../../../../../../../components/LabeledField";

import Controller from "./Controller";

const CompanySelectBase = react.withController<
	CompanySelect.PropsBase,
	Controller
>(({ controller, disabled, value, onChange }) => {
	const { t } = useTranslation();
	const lang = useTypedSelector((state) => state.session.language);
	const companies = useModelSubscribe({}, Company);

	const options = useMemo<Option<CompanySelect.Value>[]>(
		() =>
			companies?.cache.map((company) => ({
				key: company.id,
				label: company.name?.[lang],
				value: company.id,
			})) || [],
		[companies?.cache, lang],
	);

	const onCompanyIdChange = useCallback(
		(newValue) => {
			onChange(newValue);
			setCompanyError(false);
		},
		[onChange],
	);

	const [companyError, setCompanyError] = useState(false);

	controller.setContext({
		companyId: value,
		onCompanyError: setCompanyError,
	});

	return (
		<LabeledField label={t(`company`) || ""}>
			{!companies ? (
				<Skeleton variant="rectangular" width={275.5} height={32} />
			) : (
				<Select
					disabled={disabled}
					value={value}
					onChange={onCompanyIdChange}
					options={options}
					error={companyError}
					placeholder={t(`no_data`) || ""}
				/>
			)}
		</LabeledField>
	);
}, Controller);

const CompanySelect = memo(CompanySelectBase);

declare namespace CompanySelect {
	type Ref = Controller;

	interface PropsBase {
		disabled?: boolean;
		value: Value;
		onChange: Dispatch<Value>;
	}
	type Props = PropsBase & RefAttributes<Ref>;

	type Value = Agent.Model["companyId"] | undefined;
}

export default CompanySelect;
