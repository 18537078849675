/* eslint-disable no-shadow */
import * as ModelEvent from "@node-elion/syncron";
import { Nullable } from "uikit";

import SubscriptionPool from "../../redux/services/SubscriptionPool";
import createLogger from "../../utils/logger.util";
import ServiceSubscribeOptionsBase from "../../types/ServiceSubscribeOptionsBase";
import Subscription from "../../types/Subscription";
import { NonEditableProperties } from "../../types/NonEditableProperties";
import {
	Base,
	SIPToDispatcher,
	TaxiServiceOld,
	Executor,
	Order,
	Client,
} from "..";

const logger = createLogger({ name: "Call" });

class Call extends Base {
	static fromResponse(data: any): Call.Model {
		const customer = data.customer
			? Client.fromResponse(data.customer)
			: null;
		const executor = data.executor
			? Executor.fromResponse(data.executor) || null
			: null;
		const dispatcherToSip = data.dispatcherToSip
			? SIPToDispatcher.fromResponse(data.dispatcherToSip)
			: null;

		const taxiService = data.taxiService
			? TaxiServiceOld.fromResponse(data.taxiService)
			: undefined;

		return {
			id: data.id,

			customer,
			executor,
			answerDate: data.answerDate,
			asteriskMasterUniqueId: data.asteriskMasterUniqueId,
			asteriskUniqueId: data.asteriskUniqueId,
			callToOrders: data.callToOrders,
			cardValue: data.cardValue,
			dialStatus: data.dialStatus,
			dispatcherToSip,
			durationInSeconds: data.durationInSeconds,
			endDate: data.endDate,
			operator: data.operator,
			phone: data.phone,
			sipUri: data.sipUri,
			startDate: data.startDate,
			taxiService,
			type: data.type,
			servicePhone: data.servicePhone,
			waitTimeInSeconds: data.waitTimeInSeconds,
			talkTimeInSeconds: data.talkTimeInSeconds,
			downloadSoundUrl: data.downloadSoundUrl,
			playSoundUrl: data.playSoundUrl,

			createdAt: data.createdAt,
			updatedAt: data.updatedAt,
			deletedAt: data.deletedAt,
		};
	}

	static toRequest(model: Call.Model.New | Call.Model.Modified): any {
		return {
			isSoftPhone: model.isSoftPhone,
		};
	}

	public static async getAll(object: Call.SubscribeOptions) {
		const data = await this.request((prpc) =>
			prpc.theirsModel.call.getAll(this.optionsToRequest(object)),
		);
		return data;
	}

	public static async setNoAnswerAsRecall(phone: string) {
		const data = await this.request((prpc) =>
			prpc.theirsModel.call.setNoAnswerAsRecall(phone),
		);
		return data;
	}

	public static async subscribe(
		options: Call.SubscribeOptions,
		onUpdate: Subscription.OnUpdate<Call.Model>,
	): Promise<Subscription<Call.SubscribeOptions> | null> {
		const modelEventConstructor = new ModelEvent.ModelEventConstructor({
			onUpdate: (state) => {
				console.log("[Call] subscribe", state);
				onUpdate({
					...state,
					models: state.models.map(this.fromResponse),
				});
			},
		});

		const subscription = await SubscriptionPool.add(
			(prpc) =>
				prpc.theirsModel.call.subscribe({
					params: this.optionsToRequest(options),
					ping: () => true,
					onEvent: async (events) => {
						await modelEventConstructor.onEvent(events);
					},
					onError: (error) => {
						logger.error("Subscribe to calls error: %o", error);
					},
				}),
			{ name: "Call.subscribe" },
		);

		return {
			unsubscribe: () => subscription.unsubscribe(),
			update: (options: Call.SubscribeOptions) =>
				subscription.update(this.optionsToRequest(options)),
		} as Subscription<Call.SubscribeOptions>;
	}

	private static optionsToRequest(options: Call.SubscribeOptions) {
		return {
			offset: options.offset,
			limit: options.limit,
			query: options.query,
			customerId: options.customerId,
			executorId: options.executorId,
			orderIds: options.orderIds,
			sipToDispatcherId: options.sipToDispatcherId,
			dispatcherId: options.dispatcherId,
			notAnswered: options.notAnswered,
			taxiServiceIds: options.taxiServiceIds,
			dateRange: options.dateRange,
			order: options.order,
		};
	}
}

declare namespace Call {
	export interface Model extends NonEditableProperties {
		answerDate: string;
		asteriskMasterUniqueId: string;
		asteriskUniqueId: string;
		callToOrders: Order.Model[];
		cardValue: string;
		customer: Client.Model | null;
		dialStatus: number;
		dispatcherToSip: SIPToDispatcher.Model | null;
		downloadSoundUrl: string;
		playSoundUrl: string;
		durationInSeconds: number;
		endDate: string;
		executor: Executor.Model | null;
		operator: string;
		servicePhone: string;
		waitTimeInSeconds: number;
		talkTimeInSeconds: number;
		phone: string;
		sipUri: string | null;
		startDate: string;
		taxiService?: TaxiServiceOld.Model;
		type: CallType;
	}

	type CallType =
		| "IN_COME"
		| "IN_ANSWER"
		| "IN_NO_ANSWER"
		| "OUT_COME"
		| "OUT_ANSWER"
		| "OUT_NO_ANSWER"
		| "NEW_CALL"
		| "IN_RECALLED";

	interface SubscribeOptions extends ServiceSubscribeOptionsBase<Call.Model> {
		customerId?: number;
		executorId?: number;
		orderIds?: number[];
		sipToDispatcherId?: number;
		dispatcherId?: number;
		notAnswered?: boolean;
		taxiServiceIds?: number[];
		dateRange?: {
			from: Date;
			to: Date;
		};
	}

	namespace Model {
		interface New {
			taxiServiceId: number;
			dispatcherId: number;
			sipId: number;

			isSoftPhone: string;
		}

		interface Modified {
			id: number;

			isSoftPhone: string;
		}

		interface Available {
			sipToDispatcherId: number;
			isSoftPhone: boolean;
			accountCode: string;
			sipValue: string;
			lastCheckMs: Nullable<string>;
		}
	}
}

export default Call;
