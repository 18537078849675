/* eslint-disable import/no-unresolved */
import React, {
	RefAttributes,
	useCallback,
	useEffect,
	useLayoutEffect,
	useMemo,
	useState,
} from "react";
import { useTranslation } from "react-i18next";
import { react, useRefWithSetter } from "uikit";

import Client from "../../../../../../../../services/Client";
import useObjectEditor from "../../../../../../../../hooks/useObjectEditor";
import EditModalBase from "../../../../../../../../components/EditModal";

import InternalController from "./Controller";
import Root from "./components/Root";
import Header from "./components/Header";
import Content from "./components/Content";
import Status from "./components/Status";

declare namespace EditModal {
	type Ref = InternalController | null;
	type Controller = InternalController;

	interface Value extends Content.Value {
		id?: number;
		createdAt?: string;
	}

	type Tab =
		| "main"
		| "options"
		| "addresses"
		| "orders"
		| "transactions"
		| "rating"
		| "files"
		| "executors"
		| "history";

	namespace Value {
		type ValidationPropertyNames = "firstName";

		type Validated = Omit<Value, ValidationPropertyNames> &
			Required<Pick<Value, ValidationPropertyNames>>;
	}

	interface PropsBase {
		groups: Content.Props["groups"];
		value: Value;
		counters: Header.Props["counters"];
		onCancel: () => void;
		onSave: (value: Value.Validated) => void;
	}

	type Props = PropsBase & RefAttributes<Ref>;
}

const EditModal = react.withController<
	EditModal.PropsBase,
	EditModal.Controller
>(({ controller, groups, value, onCancel, onSave, counters }) => {
	const { t } = useTranslation();
	const [contentRef, setContentRef] = useRefWithSetter<Content.Ref | null>(
		null,
	);
	const [statusRef, setStatusRef] = useRefWithSetter<Status.Ref | null>(null);

	const [history, setHistory] = useState<Client.History>([]);

	useEffect(() => {
		(async () => {
			if (value.id) {
				const history = await Client.getHistory(value.id);

				setHistory(history);
			}
		})();
	}, [value.id]);

	controller.setContext({ contentRef, statusRef });

	const [tab, setTab] = useState<EditModal.Tab>("main");
	const [internalValue, setInternalValue] = useState(value);

	useEffect(() => {
		setInternalValue(value);
	}, [value]);

	const valueEditor = useObjectEditor(internalValue, setInternalValue);

	const setCarClassId = valueEditor.useSetter("carClassId");

	useLayoutEffect(() => {
		setCarClassId(value.carClassId);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [value.carClassId]);

	const headerTitle = useMemo(
		() =>
			value.id == null
				? t(
						"pages.mainPage.pages.customers.tabs.clients.editModal.str200",
				  ) ?? ""
				: [
						internalValue.firstName,
						internalValue.lastName,
						internalValue.fatherName,
				  ]
						.filter((s) => !!s)
						.join(" ")
						.trim() ||
				  value.phones[0] ||
				  (t(
						"pages.mainPage.pages.customers.tabs.clients.editModal.str201",
				  ) ??
						""),
		[
			internalValue.firstName,
			internalValue.lastName,
			internalValue.fatherName,
			value.id,
			value.phones,
			t,
		],
	);

	const picker = valueEditor.usePicker(["status"]);

	const assigner = valueEditor.useAssigner();

	const modalContent = useMemo(
		() => (
			<Root sizes="auto! 1fr">
				<Header
					tab={tab}
					title={headerTitle}
					clientId={value.id}
					createdAt={value.createdAt}
					onChangeTab={setTab}
					counters={counters}
				/>
				<Content
					ref={setContentRef}
					clientId={value.id}
					groups={groups}
					value={internalValue}
					type={tab}
					history={history}
					onChange={assigner}
				/>
			</Root>
		),
		[
			tab,
			headerTitle,
			value.createdAt,
			value.id,
			counters,
			setContentRef,
			groups,
			internalValue,
			history,
			assigner,
		],
	);

	const modalOnSave = useCallback(() => {
		if (!contentRef.current?.validate()) return;

		onSave(internalValue as EditModal.Value.Validated);
	}, [contentRef, onSave, internalValue]);

	return (
		<EditModalBase
			onCancel={onCancel}
			onSave={modalOnSave}
			footer={
				<Status ref={setStatusRef} value={picker} onChange={assigner} />
			}
		>
			{modalContent}
		</EditModalBase>
	);
}, InternalController);

export default EditModal;
