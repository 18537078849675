import React, { Dispatch, memo, RefAttributes, useState } from "react";
import { Button, Row, react } from "uikit";
import { useTranslation } from "react-i18next";

import Client from "../../../../../../../../../../services/Client";
import useObjectEditor from "../../../../../../../../../../hooks/useObjectEditor";

import CustomInputModal from "./components/CustomInputModal";
import InternalController from "./Controller";

const StatusBase = react.withController<Status.PropsBase, Status.Controller>(
	({ value, onChange, controller }) => {
		const { t } = useTranslation();
		const [open, setOpen] = useState(false);

		const editor = useObjectEditor(value, onChange);

		const status = editor.useGetter("status");
		const setStatus = editor.useSetter("status");

		controller.setContext({ status });

		return (
			<Row gaps="1rem" align="center" style={{ width: "max-content" }}>
				<div>{t(`statuses.${status.level}`)}</div>
				<Button.Button
					onClick={() => {
						setOpen(true);
					}}
					text={
						t(
							"pages.mainPage.pages.customers.tabs.clients.editModal.status.str0",
						) ?? ""
					}
				/>

				{open && (
					<CustomInputModal
						value={status}
						onSubmit={setStatus}
						onClose={() => {
							setOpen(false);
						}}
					/>
				)}
			</Row>
		);
	},
	InternalController,
);

const Status = memo(StatusBase);

declare namespace Status {
	type Ref = InternalController;
	type Controller = InternalController;

	interface PropsBase {
		value: Value;
		onChange: Dispatch<Value>;
	}

	type Props = PropsBase & RefAttributes<Ref>;

	interface Value {
		status: Client.Model["status"];
	}
}

export default Status;
