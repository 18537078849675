import React, { Dispatch, useMemo } from "react";

import { ExecutorGroup } from "../../../../../../../../../../../../../../../services";
import { useObjectEditor } from "../../../../../../../../../../../../../../../hooks";
import {
	StyledGrid,
	StyledGridItem,
	CheckBoxWithText,
} from "../../../../../../../../../../../../../../../components/common";

const First: React.FC<First.Props> = ({ value, onChange }) => {
	const pathT = useMemo(
		() =>
			"pages.mainPage.pages.accounts.tabs.executorTeams.modal.content.tabs.main.blocks.first",
		[],
	);

	const valueEditor = useObjectEditor(value, onChange);

	const enableReOffer = valueEditor.useGetter("enableReOffer");
	const enableReOfferOnChange = valueEditor.useSetter("enableReOffer");

	const displayRequiredOffersInLiveTabAfterCancel = valueEditor.useGetter(
		"displayRequiredOffersInLiveTabAfterCancel",
	);
	const displayRequiredOffersInLiveTabAfterCancelOnChange =
		valueEditor.useSetter("displayRequiredOffersInLiveTabAfterCancel");

	const displayRequiredOffersInLiveTabAfterExpire = valueEditor.useGetter(
		"displayRequiredOffersInLiveTabAfterExpire",
	);
	const displayRequiredOffersInLiveTabAfterExpireOnChange =
		valueEditor.useSetter("displayRequiredOffersInLiveTabAfterExpire");

	const displayFreeWayOffersInLiveTabAfterCancel = valueEditor.useGetter(
		"displayFreeWayOffersInLiveTabAfterCancel",
	);
	const displayFreeWayOffersInLiveTabAfterCancelOnChange =
		valueEditor.useSetter("displayFreeWayOffersInLiveTabAfterCancel");

	const displayFreeWayOffersInLiveTabAfterExpire = valueEditor.useGetter(
		"displayFreeWayOffersInLiveTabAfterExpire",
	);
	const displayFreeWayOffersInLiveTabAfterExpireOnChange =
		valueEditor.useSetter("displayFreeWayOffersInLiveTabAfterExpire");

	const returnToParkingOnMandatoryOrderRefusal = valueEditor.useGetter(
		"returnToParkingOnMandatoryOrderRefusal",
	);
	const returnToParkingOnMandatoryOrderRefusalOnChange =
		valueEditor.useSetter("returnToParkingOnMandatoryOrderRefusal");

	const returnToParkingOnFreeWaveOrderRefusal = valueEditor.useGetter(
		"returnToParkingOnFreeWaveOrderRefusal",
	);
	const returnToParkingOnFreeWaveOrderRefusalOnChange = valueEditor.useSetter(
		"returnToParkingOnFreeWaveOrderRefusal",
	);

	const returnToParkingOnMandatoryOrderAfterExpire = valueEditor.useGetter(
		"returnToParkingOnMandatoryOrderAfterExpire",
	);
	const returnToParkingOnMandatoryOrderAfterExpireOnChange =
		valueEditor.useSetter("returnToParkingOnMandatoryOrderAfterExpire");

	const returnToParkingOnDispatcherRemovalFromMandatoryOrder =
		valueEditor.useGetter(
			"returnToParkingOnDispatcherRemovalFromMandatoryOrder",
		);
	const returnToParkingOnDispatcherRemovalFromMandatoryOrderOnChange =
		valueEditor.useSetter(
			"returnToParkingOnDispatcherRemovalFromMandatoryOrder",
		);

	const returnToParkingOnOrderCancellation = valueEditor.useGetter(
		"returnToParkingOnOrderCancellation",
	);
	const returnToParkingOnOrderCancellationOnChange = valueEditor.useSetter(
		"returnToParkingOnOrderCancellation",
	);

	return (
		<StyledGrid
			areas={`
			'returnToParkingOnOrderCancellation enableReOffer'
			'returnToParkingOnFreeWaveOrderRefusal displayRequiredOffersInLiveTabAfterCancel'
			'returnToParkingOnMandatoryOrderRefusal displayRequiredOffersInLiveTabAfterExpire'
			'returnToParkingOnMandatoryOrderAfterExpire displayFreeWayOffersInLiveTabAfterCancel'
			'returnToParkingOnDispatcherRemovalFromMandatoryOrder displayFreeWayOffersInLiveTabAfterExpire'
			`}
			columns="repeat(2,1fr)"
			rows="repeat(5, auto)"
			gap="10px"
			p="10px"
			w="100%"
			baseBorder
		>
			<StyledGridItem area="enableReOffer">
				<CheckBoxWithText
					title={`${pathT}.enableReOffer`}
					value={enableReOffer}
					onChange={enableReOfferOnChange}
				/>
			</StyledGridItem>
			<StyledGridItem area="displayRequiredOffersInLiveTabAfterCancel">
				<CheckBoxWithText
					title={`${pathT}.displayRequiredOffersInLiveTabAfterCancel`}
					value={displayRequiredOffersInLiveTabAfterCancel}
					onChange={displayRequiredOffersInLiveTabAfterCancelOnChange}
				/>
			</StyledGridItem>
			<StyledGridItem area="displayRequiredOffersInLiveTabAfterExpire">
				<CheckBoxWithText
					title={`${pathT}.displayRequiredOffersInLiveTabAfterExpire`}
					value={displayRequiredOffersInLiveTabAfterExpire}
					onChange={displayRequiredOffersInLiveTabAfterExpireOnChange}
				/>
			</StyledGridItem>
			<StyledGridItem area="displayFreeWayOffersInLiveTabAfterCancel">
				<CheckBoxWithText
					title={`${pathT}.displayFreeWayOffersInLiveTabAfterCancel`}
					value={displayFreeWayOffersInLiveTabAfterCancel}
					onChange={displayFreeWayOffersInLiveTabAfterCancelOnChange}
				/>
			</StyledGridItem>
			<StyledGridItem area="displayFreeWayOffersInLiveTabAfterExpire">
				<CheckBoxWithText
					title={`${pathT}.displayFreeWayOffersInLiveTabAfterExpire`}
					value={displayFreeWayOffersInLiveTabAfterExpire}
					onChange={displayFreeWayOffersInLiveTabAfterExpireOnChange}
				/>
			</StyledGridItem>
			<StyledGridItem area="returnToParkingOnMandatoryOrderRefusal">
				<CheckBoxWithText
					title={`${pathT}.returnToParkingOnMandatoryOrderRefusal`}
					value={returnToParkingOnMandatoryOrderRefusal}
					onChange={returnToParkingOnMandatoryOrderRefusalOnChange}
				/>
			</StyledGridItem>
			<StyledGridItem area="returnToParkingOnFreeWaveOrderRefusal">
				<CheckBoxWithText
					title={`${pathT}.returnToParkingOnFreeWaveOrderRefusal`}
					value={returnToParkingOnFreeWaveOrderRefusal}
					onChange={returnToParkingOnFreeWaveOrderRefusalOnChange}
				/>
			</StyledGridItem>
			<StyledGridItem area="returnToParkingOnMandatoryOrderAfterExpire">
				<CheckBoxWithText
					title={`${pathT}.returnToParkingOnMandatoryOrderAfterExpire`}
					value={returnToParkingOnMandatoryOrderAfterExpire}
					onChange={
						returnToParkingOnMandatoryOrderAfterExpireOnChange
					}
				/>
			</StyledGridItem>
			<StyledGridItem area="returnToParkingOnDispatcherRemovalFromMandatoryOrder">
				<CheckBoxWithText
					title={`${pathT}.returnToParkingOnDispatcherRemovalFromMandatoryOrder`}
					value={returnToParkingOnDispatcherRemovalFromMandatoryOrder}
					onChange={
						returnToParkingOnDispatcherRemovalFromMandatoryOrderOnChange
					}
				/>
			</StyledGridItem>

			<StyledGridItem area="returnToParkingOnOrderCancellation">
				<CheckBoxWithText
					title={`${pathT}.returnToParkingOnOrderCancellation`}
					value={returnToParkingOnOrderCancellation}
					onChange={returnToParkingOnOrderCancellationOnChange}
				/>
			</StyledGridItem>
		</StyledGrid>
	);
};

declare namespace First {
	interface Value extends ExecutorGroup.Configuration {}

	interface Props {
		value: Value;
		onChange: Dispatch<Value>;
	}
}

export default First;
