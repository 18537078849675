/* eslint-disable no-param-reassign */
import React, { Dispatch, memo, useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Icon, Row, theme } from "uikit";

import { useCurrencyGlobalSettings } from "../../../../hooks";
import formatNumber from "../../../../utils/formatNumber";
import { ITransaction } from "../../../../types/ITransaction";
import BalanceModal from "../../../BalanceModal";
import Label from "../Label";

const Transaction: React.FC<Transaction.Props> = memo((props) => {
	const { value, type, onChange, colorMainBalance } = props;
	const { t } = useTranslation();
	const currencyGlobalSettings = useCurrencyGlobalSettings();

	const [showModal, setShowModal] = useState(false);

	const formattedNumber = useMemo(() => {
		try {
			if (type === "threshold") {
				return formatNumber(value[value.length - 1].amount);
			}
			return formatNumber(
				value.reduce((acc, item) => (acc += item.amount), 0),
			);
		} catch (error) {
			console.error(error);
			return 0;
		}
	}, [type, value]);

	const handleOpenModal = useCallback(() => {
		setShowModal(true);
	}, []);

	const handleCloseModal = useCallback(() => {
		setShowModal(false);
	}, []);

	const handleSubmit = useCallback(
		(data: { amount: number; description: string }) => {
			const toRequest: ITransaction = { amount: data.amount };
			if (data.description) toRequest.description = data.description;
			onChange([...value, toRequest]);
			setShowModal(false);
		},
		[onChange, value],
	);

	const colors = useMemo(() => {
		if (colorMainBalance) return [colorMainBalance];
		return [theme.colors.success];
	}, [colorMainBalance]);

	const translationTexts = useMemo(
		() => ({
			text: t("account.transaction.str201") ?? "",
			transaction: currencyGlobalSettings,
		}),
		[currencyGlobalSettings, t],
	);

	return (
		<>
			<Row align="center" gaps="4px 11.5px">
				<Icon id="wallet" size={16} colors={colors} />
				<Label style={{ color: colorMainBalance }}>
					{formattedNumber} {translationTexts.transaction}.
				</Label>
				<Button.Button
					text={translationTexts.text}
					style={{
						minHeight: "32px",
					}}
					onClick={handleOpenModal}
				/>
			</Row>
			{showModal && (
				<BalanceModal
					onClose={handleCloseModal}
					onSubmit={handleSubmit}
					defaultAmount={
						type === "threshold"
							? value[value.length - 1]?.amount
							: undefined
					}
					defaultDescription={
						type === "threshold"
							? value[value.length - 1]?.description
							: undefined
					}
				/>
			)}
		</>
	);
});

declare namespace Transaction {
	interface Props {
		value: ITransaction[];
		type: string;
		onChange: Dispatch<ITransaction[]>;
		colorMainBalance?: string;
	}
}

export default Transaction;
