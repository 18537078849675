/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable no-param-reassign */
/* eslint-disable no-shadow */

import React, {
	Dispatch,
	RefAttributes,
	memo,
	useCallback,
	useEffect,
	useMemo,
	useState,
} from "react";
import { Column, react } from "uikit";
import { compact, isEqual, isNumber, isUndefined, uniq } from "lodash";
import { useTranslation } from "react-i18next";

import Language from "../../../../../../../../../../../../services/Language";
import CarBaseType from "../../../../../../../../../../../../services/CarBaseType";
import Car, {
	CarSubscribeType,
} from "../../../../../../../../../../../../services/Car";
import CarClass from "../../../../../../../../../../../../services/CarClass";
import { useTypedSelector } from "../../../../../../../../../../../../redux/store";
import useObjectEditor from "../../../../../../../../../../../../hooks/useObjectEditor";
import useModelSubscribe from "../../../../../../../../../../../../hooks/useModelSubscribe2";
import { useSort } from "../../../../../../../../../../../../hooks/useTableSettings";
import { mapByKey } from "../../../../../../../../../../../../utils";
import DefaultPageHeader from "../../../../../../../../../../../../components/DefaultPageHeader";
import CarTable from "../../../../../../../../../../../../components/CarTable";
import ControlButtons from "../../../../../../../../../../../../components/DefaultPageHeader/components/ControlButtons";
import DeleteModal from "../../../../../../../../../../../../components/DeleteModal";
import useCarEditModalSaver from "../../../../../../../Car/hooks/useCarEditModalSaver";
import CarEditModal from "../../../../../../../Car/components/EditModal";
import TabRoot from "../TabRoot";

import InternalController from "./Controller";
import CarSelectModal from "./components/CarSelectModal";

const CarSelectTab = memo(
	react.withController<CarSelectTab.PropsBase, CarSelectTab.Controller>(
		({
			value,

			visible,
			language,
			allowedTaxiServiceIds,

			onChange,
		}) => {
			const { t } = useTranslation();
			const currentUser = useTypedSelector((state) => state.account.user);

			const carClassData = useModelSubscribe({ default: true }, CarClass);
			const carModelData = useModelSubscribe(
				{
					ids: value.carIds,
					taxiServiceIds: allowedTaxiServiceIds,
					subscribeType: CarSubscribeType.CAR_PAGE,
				},
				Car,
			);

			const [selected, setSelected] = useState<number[]>([]);

			const { sortMethod: sort, setSortMethod: setSort } = useSort(
				"accounts.executors.modal.vehicle",
				"vehicle",
			);

			const carBaseTypeData = useModelSubscribe(
				{ default: true },
				CarBaseType,
			);

			const carBaseTypes = useMemo(
				() => carBaseTypeData.models,
				[carBaseTypeData.models],
			);

			const defaultCarBaseType = useMemo(
				() => carBaseTypes.find((carBaseType) => carBaseType.default),
				[carBaseTypes],
			);

			const defaultCarClass = useMemo(
				() =>
					carClassData.models.find(
						(carClass) => carClass.default && carClass.root,
					),
				[carClassData.models],
			);

			const [loading, setLoading] = useState<boolean>(false);
			const [showCarSelectModal, setShowCarSelectModal] = useState(false);
			const [showCarDeleteModal, setShowCarDeleteModal] = useState(false);
			const [carEditingItem, setCarEditingItem] =
				useState<CarEditModal.Value | null>(null);

			const valueEditor = useObjectEditor(value, onChange);

			const taxiServiceId = valueEditor.useGetter("taxiServiceId");

			const carIdsValue = valueEditor.useGetter("carIds");
			const carIdsOnChange = valueEditor.useSetter("carIds");
			const carIdsEditor = valueEditor.usePropertyEditor("carIds");

			const cars = useMemo(
				() => carModelData.models ?? [],
				[carModelData.models],
			);

			const modelItemById = useMemo(() => mapByKey(cars, "id"), [cars]);

			const canDelete = useMemo(
				() => selected.length !== 0,
				[selected.length],
			);

			const selectCarButtonOnClick = useCallback(() => {
				setShowCarSelectModal(true);
			}, []);

			const addCarButtonOnClick = useCallback(() => {
				setCarEditingItem({
					parkNumber: "",
					registrationNumber: "",
					vehicleNumber: "",
					manufactureYear: "",
					carClassId: defaultCarClass?.id,
					carBaseTypeId: defaultCarBaseType?.id,
					responsibleDispatcherId: currentUser?.id,

					active: true,

					notes: "",

					licenseCard: "",
					registrationCertificate: "",
					radioStation: "",
					taximeter: "",
					insurances: {
						wc: {
							series: "",
							number: "",
						},
						additional: [
							{
								name: "",
								data: "",
							},
						],
					},

					executorIds: [],

					otherFiles: [],
					transportFiles: [],
					registrationCertificateFiles: [],
					insuranceFiles: [],
					licenseCardFiles: [],
					wcInsuranceFiles: [],

					carClass: defaultCarClass,
					serviceIds: defaultCarClass?.serviceDefaultIds || [],
					distributableCarClass:
						defaultCarClass?.compatibleCarClasses || [],
					broadcastableCarClass:
						defaultCarClass?.compatibleCarClassesToBroadcastable ||
						[],
					distributableCarClassIds:
						defaultCarClass?.compatibleCarClassIds || [],
					broadcastableCarClassIds:
						defaultCarClass?.compatibleCarClassIdsToBroadcastable ||
						[],
				});
			}, [currentUser?.id, defaultCarBaseType?.id, defaultCarClass]);

			const getCar = useCallback(async (id: number | undefined) => {
				if (isNumber(id)) {
					const res = await Car.getById(id);
					return res;
				}
				return undefined;
			}, []);

			const edit = useCallback(
				async (id?: number) => {
					let selectedId = id;

					if (isUndefined(selectedId)) {
						if (selected.length !== 1) {
							return;
						}

						[selectedId] = selected;
					}

					setLoading(true);
					const selectedCar = await getCar(selectedId);

					if (!selectedCar) return;

					const distributableCarClassIds =
						selectedCar.distributableCarClass
							? compact(
									uniq(
										selectedCar.distributableCarClass.map(
											(item) => item.id,
										),
									),
							  )
							: [];
					const broadcastableCarClassIds =
						selectedCar.broadcastableCarClass
							? compact(
									uniq(
										selectedCar.broadcastableCarClass.map(
											(item) => item.id,
										),
									),
							  )
							: [];

					setCarEditingItem({
						id: selectedCar.id,

						name: `${
							selectedCar.model?.carBaseTypeToCarBrand?.carBrand
								?.name ?? ""
						} ${selectedCar.model?.name ?? ""}, ${
							selectedCar.color?.name?.[language] ?? ""
						} (${selectedCar.parkNumber})`,

						parkNumber: selectedCar.parkNumber,
						registrationNumber: selectedCar.registrationNumber,
						vehicleNumber: selectedCar?.vehicleNumber,
						manufactureYear: selectedCar?.manufactureYear,
						seats: selectedCar?.seats,

						carColorId: selectedCar.color?.id,
						carBaseTypeId:
							selectedCar.model?.carBaseTypeToCarBrand
								?.carBaseType?.id,
						carBrandId:
							selectedCar.model?.carBaseTypeToCarBrand?.carBrand
								?.id,
						carModelId: selectedCar.model?.id,
						carBodyTypeId: selectedCar.bodyType?.id,

						carClassId: selectedCar?.class?.id,
						carClass: selectedCar?.class,
						distributableCarClass:
							selectedCar?.distributableCarClass,
						broadcastableCarClass:
							selectedCar?.broadcastableCarClass,

						distributableCarClassIds,
						broadcastableCarClassIds,

						taxiServiceId: selectedCar.taxiService?.id,
						carParkId: selectedCar.park?.id,
						responsibleDispatcherId:
							selectedCar?.responsibleDispatcher?.id,

						notes: selectedCar?.notes,

						serviceIds:
							selectedCar?.services?.map(
								(service) => service.id,
							) ?? [],

						registrationCertificate:
							selectedCar?.registrationCertificate,
						licenseCard: selectedCar?.licenseCard,
						radioStation: selectedCar?.radioStation,
						taximeter: selectedCar?.taximeter,
						insurances: {
							wc: {
								series:
									selectedCar?.insurances?.wc?.series ?? "",
								number:
									selectedCar?.insurances?.wc?.number ?? "",
								expirationDate:
									selectedCar?.insurances?.wc?.expirationDate,
							},
							additional:
								selectedCar?.insurances?.additional?.map(
									(item) => ({
										name: item.name ?? "",
										data: item.data ?? "",
										expirationDate: item.expirationDate,
									}),
								) ?? [],
						},

						executorIds:
							selectedCar?.executors?.map(
								(executor) => executor.id,
							) ?? [],

						otherFiles: selectedCar?.otherFiles ?? [],
						transportFiles: selectedCar?.transportFiles ?? [],
						registrationCertificateFiles:
							selectedCar?.registrationCertificateFiles ?? [],
						insuranceFiles: selectedCar?.insuranceFiles ?? [],
						licenseCardFiles: selectedCar?.licenseCardFiles ?? [],
						wcInsuranceFiles: [],

						active: selectedCar?.active,

						createdAt: selectedCar?.createdAt ?? undefined,
					});
					setLoading(false);
				},
				[getCar, language, selected],
			);

			const deleteCarButtonOnClick = useCallback(() => {
				setShowCarDeleteModal(true);
			}, []);

			const carSelectModalOnClose = useCallback(() => {
				setShowCarSelectModal(false);
			}, []);

			const carSelectModalOnSubmit = useCallback(
				(carSelectModalValue: CarSelectModal.Value) => {
					carIdsOnChange(carSelectModalValue);
					setShowCarSelectModal(false);
				},
				[carIdsOnChange],
			);

			const carDeleteModalOnClose = useCallback(() => {
				setShowCarDeleteModal(false);
			}, []);

			const carDeleteModalOnConfirm = useCallback(() => {
				carIdsOnChange(
					carIdsValue.filter((carId) => !selected.includes(carId)),
				);
				setSelected([]);
				setShowCarDeleteModal(false);
			}, [carIdsOnChange, carIdsValue, selected]);

			const headerButtons = useMemo<ControlButtons.Button[]>(
				() => [
					{
						disabled: !taxiServiceId,
						variation: "primary",
						icon: { id: "notepad-edit", size: 20 },
						onClick: selectCarButtonOnClick,
					},
					{
						disabled: !taxiServiceId,
						variation: "primary",
						icon: { id: "plus", size: 16 },
						onClick: addCarButtonOnClick,
					},
					{
						variation: "secondary",
						icon: { id: "pencil", size: 20 },
						disabled: selected.length !== 1,
						onClick: () => {
							edit(selected[0]);
						},
					},
					{
						disabled: !canDelete,
						variation: "secondary",
						icon: { id: "minus", size: 20 },
						onClick: deleteCarButtonOnClick,
					},
				],
				[
					addCarButtonOnClick,
					canDelete,
					deleteCarButtonOnClick,
					edit,
					selectCarButtonOnClick,
					selected,
					taxiServiceId,
				],
			);

			const carEditModalOnCancel = useCallback(() => {
				setCarEditingItem(null);
			}, []);

			const editModalSaver = useCarEditModalSaver();

			const carEditModalOnSave = useCallback(
				async (newItem: CarEditModal.Value.Validated) => {
					if (isEqual(carEditingItem, newItem)) {
						setCarEditingItem(null);

						return;
					}

					setCarEditingItem(null);

					const car = await editModalSaver(newItem);

					if (!carIdsEditor.includes(car.id)) {
						carIdsEditor.push(car.id);
					}
				},
				[carEditingItem, carIdsEditor, editModalSaver],
			);

			useEffect(() => {
				if (carEditingItem?.id) {
					const car = modelItemById?.[carEditingItem.id];

					if (!car) {
						setCarEditingItem(null);
						return;
					}

					const carClassId = car.class?.id;
					const carClass = car.class;
					const serviceIds = car.services
						? compact(uniq(car.services.map((item) => item.id)))
						: [];
					const distributableCarClass =
						car.distributableCarClass || [];
					const broadcastableCarClass =
						car.broadcastableCarClass || [];
					const distributableCarClassIds = car.distributableCarClass
						? compact(
								uniq(
									distributableCarClass.map(
										(item) => item.id,
									),
								),
						  )
						: [];
					const broadcastableCarClassIds = broadcastableCarClass
						? compact(
								uniq(
									broadcastableCarClass.map(
										(item) => item.id,
									),
								),
						  )
						: [];

					if (car) {
						setCarEditingItem({
							...carEditingItem,
							carClassId,
							carClass,
							serviceIds,
							distributableCarClass,
							distributableCarClassIds,
							broadcastableCarClassIds,
						});
					}
				}
				// eslint-disable-next-line react-hooks/exhaustive-deps
			}, [cars]);

			return (
				<>
					<TabRoot
						hasPaddings
						paddings="16px 0 0 0"
						visible={visible}
					>
						<Column
							sizes="auto! 1fr"
							gaps="16px"
							maxedWidth
							maxedHeight
						>
							<DefaultPageHeader buttons={headerButtons} />
							<CarTable
								selected={selected}
								sort={sort}
								loading={false}
								data={cars}
								language={language}
								selectionType="tab"
								saveSlot="accounts.executors.modal.vehicle"
								onChangeSelected={setSelected}
								onChangeSort={setSort}
								onEdit={(item) => {
									edit(item.id);
								}}
								onLoadMore={() => {}}
							/>
						</Column>
					</TabRoot>
					{showCarSelectModal ? (
						<CarSelectModal
							value={carIdsValue}
							language={language}
							allowedTaxiServiceIds={allowedTaxiServiceIds}
							onClose={carSelectModalOnClose}
							onSubmit={carSelectModalOnSubmit}
							taxiServiceId={taxiServiceId}
							subscribeType={CarSubscribeType.CAR_PAGE}
						/>
					) : null}
					{carEditingItem && (
						<CarEditModal
							value={carEditingItem}
							language={language}
							onCancel={carEditModalOnCancel}
							onSave={carEditModalOnSave}
							executorTaxiServiceId={taxiServiceId}
						/>
					)}
					{showCarDeleteModal ? (
						<DeleteModal
							label={
								t(
									"pages.mainPage.pages.accounts.tabs.executors.editModal.content.carSelectTab.str200",
								) ?? ""
							}
							onCancel={carDeleteModalOnClose}
							onConfirm={carDeleteModalOnConfirm}
						/>
					) : null}
				</>
			);
		},
		InternalController,
	),
);

declare namespace CarSelectTab {
	type Ref = InternalController | null;

	type Controller = InternalController;

	interface Value {
		id?: number;

		carIds: number[];
		taxiServiceId?: number;
	}

	interface PropsBase {
		value: Value;

		disabled: boolean;
		visible: boolean;
		language: Language;
		allowedTaxiServiceIds: number[];

		onChange: Dispatch<Value>;
	}

	type Props = PropsBase & RefAttributes<Ref>;
}

export default CarSelectTab;
