import React, { useMemo } from "react";

import { Dispatcher } from "../../../../../../../../../../../../services";
import { CellContentRoot } from "../../../../../../../../../../../../components/LightTable";

const FullNameCellContent: React.FC<FullNameCellContent.Props> = ({ item }) => {
	const content = useMemo(() => {
		if (!item.person) {
			return "";
		}
		const { lastName, firstName, fatherName } = item.person;
		return `${lastName} ${firstName} ${fatherName}`.trim();
	}, [item.person]);

	return (
		<CellContentRoot alignItems="center" w="100%" h="100%">
			{content}
		</CellContentRoot>
	);
};

declare namespace FullNameCellContent {
	interface Props {
		item: Dispatcher.Model;
	}
}

export default FullNameCellContent;
