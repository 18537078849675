import { RefObject } from "react";
import { react } from "uikit";
import PersonData from "./components/PersonalData";
import AdditionalData from "./components/AdditionalData";

interface Context {
	personDataRef: RefObject<PersonData.Ref | null>;
	additionalDataRef: RefObject<AdditionalData.Ref | null>;
}

export default class Controller extends react.ControllerBase<Context> {
	validate() {
		const isPersonDataValid =
			!!this.context?.personDataRef.current?.validate();
		const isAdditionalDataRefValid =
			!!this.context?.additionalDataRef.current?.validate();

		return isPersonDataValid && isAdditionalDataRefValid;
	}
}
