import React, {
	Dispatch,
	RefAttributes,
	memo,
	useCallback,
	useLayoutEffect,
	useMemo,
	useState,
} from "react";
import { useTranslation } from "react-i18next";
import { Row, TextBox, react } from "uikit";
import { useDebouncedCallback } from "use-debounce";

import CodeInfo from "../../../../../../../../../../../../../../../../services/Code";
import useObjectEditor from "../../../../../../../../../../../../../../../../hooks/useObjectEditor";
import { NonEditableProperties } from "../../../../../../../../../../../../../../../../types/NonEditableProperties";
import LabeledField from "../../../../../../../../../../../../../../../../components/LabeledField";
import tPath from "../../../../../../../../../../constants/tPath";

import Controller from "./Controller";

// TODO: should be moved to the main components folder
const CodeBase = react.withController<Code.PropsBase, Controller>((props) => {
	const { t } = useTranslation();
	const { value, onChange, controller } = props;

	const editor = useObjectEditor(value, onChange);

	const cardId = editor.useGetter("id");
	const cardNumber = editor.useGetter("value");
	const setCardNumber = editor.useSetter("value");
	const [cardNumberError, setCardNumberError] = useState<string | boolean>(
		false,
	);
	const setCardNumberHandler = useCallback(
		(newCardNumber: string) => {
			setCardNumberError(false);
			setCardNumber(newCardNumber);
		},
		[setCardNumber],
	);

	const checkCode = useDebouncedCallback(async (str: string) => {
		const res = await CodeInfo.getInfo(str);

		if (res?.agent && res?.agent?.code?.id !== cardId) {
			setCardNumberError("exist");
		}
		if (res?.checkCard || res?.employee) {
			setCardNumberError("exist");
		}
	}, 200);

	useLayoutEffect(() => {
		if (cardNumber) checkCode(cardNumber);
	}, [checkCode, cardNumber]);

	const keyword = editor.useGetter("keyword");
	const setKeyword = editor.useSetter("keyword");
	const [keywordError, setKeywordError] = useState(false);
	const setkeywordHandler = useCallback(
		(newKeyword: string) => {
			setKeywordError(false);
			setKeyword(newKeyword);
		},
		[setKeyword],
	);

	const labelCard = useMemo(() => {
		const textError = t(`${tPath}.errors.card_exist`);
		const label =
			t(
				"pages.mainPage.pages.customers.tabs.agents.modal.content.tabs.main.access.code.str0",
			) ?? "";
		if (cardNumberError === "exist") return textError;
		return label;
	}, [cardNumberError, t]);

	controller.setContext({
		value,
		setCardNumberError,
		setKeywordError,
		cardNumberError,
	});
	return (
		<Row gaps="8px*" sizes="1fr 1fr" align="flex-end">
			<LabeledField
				label={labelCard}
				style={{ color: cardNumberError === "exist" ? "red" : "" }}
			>
				<TextBox.TextBox
					placeholder={
						t(
							"pages.mainPage.pages.customers.tabs.agents.modal.content.tabs.main.access.code.str1",
						) ?? ""
					}
					error={!!cardNumberError}
					value={cardNumber}
					onChange={setCardNumberHandler}
				/>
			</LabeledField>
			<LabeledField
				label={
					t(
						"pages.mainPage.pages.customers.tabs.agents.modal.content.tabs.main.access.code.str2",
					) ?? ""
				}
			>
				<TextBox.TextBox
					placeholder={
						t(
							"pages.mainPage.pages.customers.tabs.agents.modal.content.tabs.main.access.code.str3",
						) ?? ""
					}
					error={keywordError}
					value={keyword}
					onChange={setkeywordHandler}
				/>
			</LabeledField>
		</Row>
	);
}, Controller);

const Code = memo(CodeBase);

declare namespace Code {
	type Ref = Controller | null;

	interface PropsBase {
		value: Value;
		onChange: Dispatch<Value>;
	}
	type Props = PropsBase & RefAttributes<Ref>;

	interface Value extends Partial<NonEditableProperties> {
		value: string;
		keyword: string;
		id?: number;
	}
}

export default Code;
