import Modal from "../components/Modal";

const defaultValue: Modal.Value = {
	isAgent: false,
	number: "",
	group: 0,
	id: undefined,
};

export default defaultValue;
