import React, { memo, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { InputBorders } from "uikit";
import Agent from "../../../../../../../../../../../../../../services/Agent";
import LabeledField from "../../../../../../../../../../../../../../components/LabeledField";
import { ModelId } from "../../../../../../../../../../../../../../types/ModelId";

const Phones: React.FC<Phones.Props> = memo((props) => {
	const { agents, value, onChange } = props;

	const selectValue = useMemo(() => value.map((item) => item.phone), [value]);

	useEffect(() => {
		if (agents.length === 1) {
			const phone = agents[0].additionalFields.contactPersonPhone;
			if (phone) {
				onChange([
					{
						taxiServiceId: agents[0]?.defaultTaxiServiceId,
						phone,
					},
				]);
			}
		} else {
			const newContacts = agents.map((agent) => ({
				taxiServiceId: agent.defaultTaxiServiceId,
				phone: agent.additionalFields.contactPersonPhone as string,
			}));
			onChange(newContacts);
		}
	}, [agents, onChange]);

	const { t } = useTranslation();
	return (
		<LabeledField
			label={
				selectValue.length === 1
					? t(
							"pages.mainPage.pages.customers.tabs.agents.headerAdditional.sendingSMS.modal.phones.str200",
					  ) ?? ""
					: t(
							"pages.mainPage.pages.customers.tabs.agents.headerAdditional.sendingSMS.modal.phones.str201",
					  ) ?? ""
			}
		>
			<InputBorders.InputBorders>
				<div>{selectValue.join(", ")}</div>
			</InputBorders.InputBorders>
		</LabeledField>
	);
});

declare namespace Phones {
	interface Props {
		agents: Agent.Model[];
		value: Value[];
		onChange: (x: Value[]) => void;
	}

	interface Value {
		phone: string;
		taxiServiceId: ModelId;
	}
}

export default Phones;
