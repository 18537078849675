import { Dispatch, SetStateAction } from "react";
import { react } from "uikit";

import { AutodetectPhone } from "../../../../../../../../../../../../hooks";
import { validatePhone } from "../../../../../../../../../../../../../../../../utils/validatePhone";

import Content from ".";

interface Context {
	value: Content.Value;
	setError: Dispatch<SetStateAction<boolean>>;

	setErrorData: (value: React.SetStateAction<AutodetectPhone[]>) => void;
	allPhones: AutodetectPhone[];
	setShowErrorModal: React.Dispatch<React.SetStateAction<boolean>>;
}

export default class Controller extends react.ControllerBase<Context> {
	validate() {
		const id = this.context?.value?.id;
		const allPhones = this.context?.allPhones || [];
		const setErrorData = this.context?.setErrorData;
		const setShowErrorModal = this.context?.setShowErrorModal;
		const phone = this.context?.value?.number?.trim() || "";
		const isAgent = !!this.context?.value?.isAgent;
		const isValid = !!this.context?.value?.number?.trim?.().length;

		if (!isValid) this.context?.setError(true);
		if (!phone) this.context?.setError(true);
		const isPhonesValid = validatePhone(phone);
		const exist = isAgent
			? allPhones.find(
					(item) => item.value === phone && item.phoneId !== id,
			  )
			: isAgent;

		if (exist) {
			setErrorData?.([exist]);
			setShowErrorModal?.(true);
		}

		return isValid && phone && isPhonesValid && !exist;
	}
}
