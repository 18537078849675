/* eslint-disable no-param-reassign */
/* eslint-disable no-shadow */

import React, { Dispatch, RefAttributes, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Column, react } from "uikit";

import File from "../../../../../../../../../../../../../services/File";
import Client from "../../../../../../../../../../../../../services/Client";
import useObjectEditor from "../../../../../../../../../../../../../hooks/useObjectEditor";
import FileListWithTitle from "../../../../../../../../../../../../../components/FileListWithTitle";
import TabRoot from "../../TabRoot";
import InternalController from "./Controller";

declare namespace FilesTab {
	type Ref = InternalController | null;

	type Controller = InternalController;

	type Value = Pick<Client.Model, "personalFiles" | "otherFiles">;

	interface PropsBase {
		value: Value;
		onChange: Dispatch<Value>;

		visible: boolean;
	}

	type Props = PropsBase & RefAttributes<Ref>;
}

const FilesTab = react.withController<FilesTab.PropsBase, FilesTab.Controller>(
	({ value, visible, onChange }) => {
		const { t } = useTranslation();
		const valueEditor = useObjectEditor(value, onChange);

		const personalFiles = valueEditor.useGetter("personalFiles");
		const otherFiles = valueEditor.useGetter("otherFiles");

		const otherFilesOnChange = useCallback(
			(otherFiles: File.Model[]) => {
				otherFiles.forEach((file) => file.upload());

				valueEditor.set("otherFiles", otherFiles);
			},
			[valueEditor],
		);

		const personalFilesOnChange = useCallback(
			(personalFiles: File.Model[]) => {
				personalFiles.forEach((file) => {
					file.isPublicImage = true;
					file.upload();
				});

				valueEditor.set("personalFiles", personalFiles);
			},
			[valueEditor],
		);

		return (
			<TabRoot hasPaddings visible={visible}>
				<Column
					gaps="24px*"
					sizes="250px!* 0.015625px!"
					maxedWidth
					maxedHeight
				>
					<FileListWithTitle
						title={
							t(
								"pages.mainPage.pages.customers.tabs.clients.editModal.content.tabs.filesTab.str0",
							) ?? ""
						}
						value={personalFiles}
						onChange={personalFilesOnChange}
					/>

					<FileListWithTitle
						title={
							t(
								"pages.mainPage.pages.customers.tabs.clients.editModal.content.tabs.filesTab.str1",
							) ?? ""
						}
						value={otherFiles}
						onChange={otherFilesOnChange}
					/>

					<div />
				</Column>
			</TabRoot>
		);
	},
	InternalController,
);

export default FilesTab;
