import React, { memo } from "react";
import { useTranslation } from "react-i18next";
import { Row, Column, CheckBox, RadioButton, Stepper } from "uikit";
import {
	borderStyles,
	columnGaps,
	marginLeft,
	rowGaps,
} from "../../../constants/styles";

const Eighth: React.FC<Eighth.Props> = memo(() => {
	const { t } = useTranslation();
	return (
		<Column gaps={columnGaps} style={borderStyles}>
			<CheckBox
				disabled
				label={
					t(
						"pages.mainPage.pages.accounts.tabs.executorTeams.modal.content.tabs.main.blocks.eighth.str200",
					) ?? ""
				}
			/>
			<Column style={marginLeft} gaps="5px*">
				<RadioButton.RadioButton
					disabled
					label={
						t(
							"pages.mainPage.pages.accounts.tabs.executorTeams.modal.content.tabs.main.blocks.eighth.str0",
						) ?? ""
					}
				/>
				<RadioButton.RadioButton
					disabled
					label={
						t(
							"pages.mainPage.pages.accounts.tabs.executorTeams.modal.content.tabs.main.blocks.eighth.str1",
						) ?? ""
					}
				/>
				<Row style={{ ...marginLeft, ...borderStyles }} gaps="30px*">
					<CheckBox
						disabled
						label={
							t(
								"pages.mainPage.pages.accounts.tabs.executorTeams.modal.content.tabs.main.blocks.eighth.str2",
							) ?? ""
						}
					/>
					<CheckBox
						disabled
						label={
							t(
								"pages.mainPage.pages.accounts.tabs.executorTeams.modal.content.tabs.main.blocks.eighth.str201",
							) ?? ""
						}
					/>
					<CheckBox
						disabled
						label={
							t(
								"pages.mainPage.pages.accounts.tabs.executorTeams.modal.content.tabs.main.blocks.eighth.str202",
							) ?? ""
						}
					/>
				</Row>
			</Column>
			<Row style={marginLeft} gaps="30px*">
				<Row gaps={rowGaps} align="center">
					<CheckBox
						disabled
						label={
							t(
								"pages.mainPage.pages.accounts.tabs.executorTeams.modal.content.tabs.main.blocks.eighth.str3",
							) ?? ""
						}
					/>
					<Stepper disabled />
					<span>
						{t(
							"pages.mainPage.pages.accounts.tabs.executorTeams.modal.content.tabs.main.blocks.eighth.str100",
						) ?? ""}
					</span>
				</Row>
				<Row gaps={rowGaps} align="center">
					<CheckBox
						disabled
						label={
							t(
								"pages.mainPage.pages.accounts.tabs.executorTeams.modal.content.tabs.main.blocks.eighth.str4",
							) ?? ""
						}
					/>
					<Stepper disabled />
					<span>
						{t(
							"pages.mainPage.pages.accounts.tabs.executorTeams.modal.content.tabs.main.blocks.eighth.str101",
						) ?? ""}
					</span>
				</Row>
			</Row>
		</Column>
	);
});

declare namespace Eighth {
	interface Props {}
}

export default Eighth;
