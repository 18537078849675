import { RefObject } from "react";
import { react } from "uikit";
import Companies from "./components/Companies";
import Phone from "./components/Phone";
import Level from "./components/Level";
import Description from "./components/Description";

interface Context {
	companyBranchRef: RefObject<Companies.Ref | null>;
	phoneRef: RefObject<Phone.Ref | null>;
	levelRef: RefObject<Level.Ref | null>;
	descriptionRef: RefObject<Description.Ref | null>;
}

export default class Controller extends react.ControllerBase<Context> {
	validate() {
		const isBranchesValid =
			!!this.context?.companyBranchRef.current?.validate();
		const isPhoneValid = !!this.context?.phoneRef.current?.validate();
		const isLevelValid = !!this.context?.levelRef.current?.validate();
		const isDescriptionValid =
			!!this.context?.descriptionRef.current?.validate();

		return (
			isBranchesValid &&
			isPhoneValid &&
			isLevelValid &&
			isDescriptionValid
		);
	}
}
