import { react } from "uikit";
import { Dispatch, RefObject } from "react";

import Access from "./components/Access";

import MainTab from ".";

interface Context {
	value: MainTab.Value;

	accessRef: RefObject<Access.Ref>;

	setNameError: Dispatch<boolean>;
	setAssignableToError: Dispatch<boolean>;
}

export default class Controller extends react.ControllerBase<Context> {
	validate() {
		const isValidAssignableTo = !!this.context?.value.assignableTo;
		const isValidAccess = !!this.context?.accessRef.current?.validate();
		const isValidName = !!this.context?.value.name;

		if (!isValidAssignableTo) this.context?.setAssignableToError(true);

		if (!isValidName) this.context?.setNameError(true);

		return isValidAssignableTo && isValidAccess && isValidName;
	}
}
