import styled from "styled-components";
import { Row } from "uikit";

const Root = styled(Row)<Root.Props>`
	padding: ${(props) => props.padding || "0 20px"};
`;

declare namespace Root {
	interface Props extends Row.Props {
		padding?: string;
	}
}

export default Root;
