import React, {
	Dispatch,
	RefAttributes,
	memo,
	useCallback,
	useEffect,
	useMemo,
	useState,
} from "react";
import { useTranslation } from "react-i18next";
import { Column, InputBorders, react } from "uikit";

import Accounts from "../../../../../../../../../../../../../Counterparties/components/Modal/components/Content/tabs/Accounts";
import mapByKey from "../../../../../../../../../../../../../../../../../../utils/mapByKey";
import InputModal from "../../../../../../../../../../../../../../../../../../components/InputModal";
import tPath from "../../../../../../../../../../../../constants/tPath";

import Text from "./components/Text";
import Table from "./components/Table";
import Controller from "./Controller";

const BalanceSelectBase = react.withController<
	BalanceSelect.PropsBase,
	Controller
>(({ check, value, onChange, controller }) => {
	const path =
		"mainPage.customers.counterparties.modal.tabs.accounts.modal.tabs.main.check.balances";

	const { t } = useTranslation();

	const modelItemById = useMemo(
		() => mapByKey(check?.paymentAccounts ?? [], "id"),
		[check],
	);

	const [isModalOpen, setIsModalOpen] = useState(false);

	const handleOpen = useCallback(() => {
		if (!check) return;
		setIsModalOpen(true);
	}, [check]);

	const handleClose = useCallback(() => {
		setIsModalOpen(false);
	}, []);

	const name = useMemo(
		() => (value ? t(`${path}.${modelItemById[value]?.type}`) : null),
		[modelItemById, t, value],
	);

	const [selected, setSelected] = useState<number | null>(value);

	const [error, setError] = useState(false);

	const onSubmit = useCallback(() => {
		if (selected) setError(false);
		onChange(selected);
		setIsModalOpen(false);
	}, [onChange, selected]);

	useEffect(() => {
		if (!check) onChange(null);
	}, [check, onChange]);

	const tableData = useMemo(
		() =>
			check?.paymentAccounts?.filter((a) => a?.type !== "threshold") ??
			[],
		[check?.paymentAccounts],
	);

	const placeholder = useMemo(
		() => t([`${tPath}.modal.tabs.main.other.balance`]),
		[t],
	);

	controller.setContext({ check, value, setError });
	return (
		<Column>
			<div
				onClick={handleOpen}
				style={{ cursor: check ? "pointer" : "not-allowed" }}
			>
				<InputBorders.InputBorders
					style={{ height: 32 }}
					disabled={!check}
					error={error}
				>
					<Text isPlaceholder={!name || name.length === 0}>
						{name ?? placeholder}
					</Text>
				</InputBorders.InputBorders>
			</div>
			{isModalOpen && (
				<InputModal draggable onClose={handleClose} onSubmit={onSubmit}>
					<Table
						tableData={tableData}
						selected={selected}
						setSelected={setSelected}
					/>
				</InputModal>
			)}
		</Column>
	);
}, Controller);

const BalanceSelect = memo(BalanceSelectBase);

declare namespace BalanceSelect {
	type Ref = Controller | null;

	interface PropsBase {
		check: Accounts.Check | null;
		value: number | null;
		onChange: Dispatch<number | null>;
	}
	type Props = PropsBase & RefAttributes<Ref>;
}

export default BalanceSelect;
