import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { last, noop } from "lodash";
import { useRender } from "uikit";
import File from "../../../../services/File";
import humanFileSize from "../../../../utils/humanFileSize";

import DeleteModal from "../../../DeleteModal";
import FileViewerModal from "../../../FileViewerModal";
import Root from "./components/Root";
import Preview from "./components/Preview";
import Footer from "./components/Footer";

const Item: React.FC<Item.Props> = ({ disabled, file, onDelete }) => {
	const render = useRender();

	const [url, setURL] = useState("");
	const [showFileViewerModal, setShowFileViewerModal] = useState(false);
	const [showDeleteModal, setShowDeleteModal] = useState(false);

	useEffect(() => {
		file.on("uploaded", render);

		(async () => {
			const blob = await file.load();

			if (blob) setURL(URL.createObjectURL(blob));
		})();

		return () => {
			file.off("uploaded", render);
		};
	}, [file, render]);

	const previewOnClick = useCallback(() => setShowFileViewerModal(true), []);

	const footerOnDelete = useCallback(() => setShowDeleteModal(true), []);

	const fileViewerModalOnClose = useCallback(
		() => setShowFileViewerModal(false),
		[],
	);

	const deleteModalOnCancel = useCallback(
		() => setShowDeleteModal(false),
		[],
	);

	const deleteModalOnConfirm = useCallback(() => {
		onDelete();
		setShowDeleteModal(false);
	}, [onDelete]);

	const { t } = useTranslation();
	return (
		<>
			<Root gaps="8px" sizes="1fr auto!">
				<Preview
					url={url}
					type={file.type}
					extension={last(file?.name?.split(".")) ?? ""}
					loading={file.isUploading}
					onClick={previewOnClick}
				/>
				<Footer
					disabled={disabled}
					url={url}
					name={file.name}
					size={humanFileSize(file.size, true)}
					onDelete={footerOnDelete}
				/>
			</Root>
			{showFileViewerModal ? (
				<FileViewerModal
					disabled={disabled}
					value={file}
					onClose={fileViewerModalOnClose}
					onDelete={disabled ? noop : onDelete}
				/>
			) : null}
			{showDeleteModal ? (
				<DeleteModal
					disabled={disabled}
					label={t("fileList.item.str200") ?? ""}
					onCancel={deleteModalOnCancel}
					onConfirm={deleteModalOnConfirm}
				/>
			) : null}
		</>
	);
};

declare namespace Item {
	interface Props {
		disabled: boolean;
		file: File.Model;

		onDelete: () => void;
	}
}

export default Item;
