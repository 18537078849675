import translationPath from "./translationPath";

export interface ErrorResponse {
	message: string;
	status: 400 | 404 | 401 | 403 | 409 | 500;
	baseText: string;
	translation: string;
	canRetry: boolean;
	/** get additional information */
	additional?: boolean;
	/** value to get more information
	*
	* ```json
	{
		"reason": "ALREADY_EXISTS",
		"entityType": "phone",
		"metadata": {
			"phones": ["380000000000"]
		}
	}
	* ```
	*/
	details?: Record<string, any>;
	isPhone?: boolean;
}

type RequestErrorContext = "create" | "update";

export const unknownErrors: Record<RequestErrorContext, ErrorResponse> = {
	create: {
		message: "",
		status: 400,
		baseText: "Failed to create a new executor. Please try again.",
		translation: `${translationPath}.errors.create`,
		canRetry: true,
	},
	update: {
		message: "",
		status: 400,
		baseText: "Failed to change the executor. Please try again.",
		translation: `${translationPath}.errors.update`,
		canRetry: true,
	},
};

const errors: ErrorResponse[] = [
	{
		message: "Phones: already exists",
		status: 409,
		baseText: "Phones already exists!",
		translation: `${translationPath}.errors.phone_exist`,
		canRetry: false,
		additional: true,
		isPhone: true,
		details: [],
	},
];

/**
 * Retrieve prepared error by message
 * @param message Response error message from server
 * @param isEdit Flag to determine if the error is from edit or create
 */
export const getErrorByMessage = (
	message: string,
	isEdit: boolean,
	details: Record<string, any> = [],
) => {
	const error = errors.find((error) => error.message === message);

	const errorPhone = errors.find((error) => {
		if (
			error.message.toLowerCase().startsWith("phones:") &&
			error.message.toLowerCase().includes("already exists") &&
			message.toLowerCase().startsWith("phones:") &&
			message.toLowerCase().includes("already exist")
		) {
			return true;
		}

		return false;
	});

	if (!error && errorPhone) {
		return {
			...errorPhone,
			details,
		};
	}

	if (!error) {
		const context = isEdit ? "update" : "create";
		return unknownErrors[context];
	}
	return error;
};

export default errors;
