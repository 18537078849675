import styled from "styled-components";
import { Column } from "uikit";

const Root = styled(Column)`
	width: auto;
	height: auto;
	border-radius: 5px;
	overflow: hidden;
`;

export default Root;
