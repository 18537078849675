import React, { useCallback } from "react";
import { DatePicker } from "uikit";

import useDatePickerLocale from "../../hooks/useDatePickerLocale";

const ImprovedDatePicker: React.FC<ImprovedDatePicker.Props> = ({
	value,
	onChange,
	...props
}) => {
	const locale = useDatePickerLocale();
	const handleChange = useCallback(
		(newDate) => {
			onChange?.(newDate);
		},
		[onChange],
	);

	return (
		<DatePicker
			value={value}
			onChange={handleChange}
			locale={locale}
			{...props}
		/>
	);
};

declare namespace ImprovedDatePicker {
	interface Props extends DatePicker.Props {
		value?: Date;
		onChange?: (x: Date) => void;
	}
}

export default ImprovedDatePicker;
