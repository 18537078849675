import React, {
	Dispatch,
	RefAttributes,
	useCallback,
	useLayoutEffect,
	useRef,
	useState,
} from "react";
import { useTranslation } from "react-i18next";
import { TextBox, react, useDebounce } from "uikit";
import { isUndefined } from "lodash";
import LabeledField from "../../../../../../../../../../../../../../../../components/LabeledField";
import InternalController from "./Controller";

const Alias = react.withController<Alias.PropsBase, Alias.Controller>(
	({
		controller,

		value,

		disabled,
		originalTaxiServiceId,
		originalValue,
		taxiServiceId,

		onChange,
	}) => {
		const currentCheckAliasPromiseRef = useRef<Promise<boolean> | null>(
			null,
		);
		const { t } = useTranslation();
		const [error, setError] = useState(false);

		controller.setContext({
			originalTaxiServiceId,
			taxiServiceId,
			originalValue,
			value,
			currentCheckAliasPromiseRef,

			setError,
		});

		const textBoxOnChange = useCallback(
			(value: string) => {
				setError(false);
				const newValue = String(value)?.replace(".", "")?.trim() || "";
				onChange(newValue);
			},
			[onChange],
		);

		const checkAlias = useCallback(async () => {
			await controller.validate();

			if (!value) {
				setError(false);
			}
		}, [controller, value]);

		const debouncedCheckAlias = useDebounce(checkAlias, 300);

		useLayoutEffect(() => {
			if (!value) {
				setError(false);

				return;
			}

			debouncedCheckAlias();
		}, [debouncedCheckAlias, value, taxiServiceId]);

		return (
			<LabeledField
				label={
					t(
						"pages.mainPage.pages.accounts.tabs.car.editModal.content.mainTab.workData.parkNumber.str0",
					) ?? ""
				}
			>
				<TextBox.TextBox
					value={value}
					disabled={disabled || isUndefined(taxiServiceId)}
					error={error}
					style={{
						minHeight: "32px",
						flex: "1 0 0",
					}}
					type="number"
					placeholder={
						t(
							"pages.mainPage.pages.accounts.tabs.car.editModal.content.mainTab.workData.parkNumber.str0",
						) ?? ""
					}
					onChange={textBoxOnChange}
				/>
			</LabeledField>
		);
	},
	InternalController,
);

declare namespace Alias {
	type Ref = InternalController | null;

	type Controller = InternalController;

	type Value = string | undefined;

	interface PropsBase {
		value: Value;

		originalTaxiServiceId?: number;
		originalValue?: Value;
		disabled: boolean;
		taxiServiceId?: number;
		currentCarId?: number;

		onChange: Dispatch<string>;
	}

	type Props = PropsBase & RefAttributes<Ref>;
}

export default Alias;
