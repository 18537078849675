import carClass from "./carClass";
import client from "./client";
import color from "./color";
import contrAgent from "./contrAgent";
import executor from "./executor";
import history from "./history";
import object from "./object";
import objectGroup from "./objectGroup";
import order from "./order";
import service from "./service";
import vehicle from "./vehicle";
import vehiclePark from "./vehiclePark";
import dispatcher from "./dispatcher";
import role from "./role";
import vehicleType from "./vehicleType";
import workShift from "./workShift";
import orderArchiveModal from "./orderArchiveModal";
import orderModal from "./orderModal";
import transaction from "./transaction";
import counterparty from "./counterparty/index";
import reward from "./reward";
import counterpartyCheck from "./counterpartyCheck";
import counterpartyCheckCard from "./counterpartyCheckCard";
import counterpartyEmployee from "./counterpartyEmployee";
import counterpartyEmployeeCheck from "./counterpartyEmployeeCheck";
import counterpartyEmployeeCheckCard from "./counterpartyEmployeeCheckCard";
import counterpartyGroup from "./counterpartyGroup";
import agentGroup from "./agentGroup";
import clientGroup from "./clientGroup";
import customerTariff from "./customerTariff";
import agent from "./agent/index";
import orderAll from "./order/all";
import orderClosed from "./order/closed";
import orderExecutable from "./order/executable";
import orderLive from "./order/live";
import orderPreliminary from "./order/preliminary";
import orderArchive from "./order/archive";
import orderPoints from "./order/points";
import costomerCounterparty from "./costomerCounterparty";
import costomerCounterpartyGroupModal from "./costomerCounterpartyGroupModal";
import costomerCounterpartyGroupsModal from "./costomerCounterpartyGroupsModal";
import agentGroupsModal from "./agentGroupsModal";
import agentGroupModal from "./agentGroupModal";
import executorTeam from "./executorTeam";
import executorParameters from "./executorParameters";
import averageSpeed from "./averageSpeed";
import customerRateRule from "./customerRateRule";
import cashlessOrders from "./cashlessOrders";
import cashlessAccountsSummaryReport from "./cashlessAccountsSummaryReport";
import cashlessOrdersGeneral from "./cashlessOrdersGeneral";
import agentOrdersReport from "./agentOrdersReport";
import accountsReport from "./accountsReport";
import cashlessOrdersPeriodReport from "./cashlessOrdersPeriodReport";
import fuelDeductionOrdersReport from "./fuelDeductionOrdersReport";
import dispatchersActivityReport from "./dispatchersActivityReport";
/* accounting page */
import accountingExecutorsGeneral from "./accountingExecutorsGeneral";
import accountingExecutorsFirstTable from "./accountingExecutorsFirstTable";
import accountingExecutorsSecondTable from "./accountingExecutorsSecondTable";
import accountingBankAccountsFirstTable from "./accountingBankAccountsFirstTable";
import accountingBankAccountsSecondTableCards from "./accountingBankAccountsSecondTableCards";
import accountingBankAccountsSecondTableCalculations from "./accountingBankAccountsSecondTableCalculations";
import accountingAgentsFirstTable from "./accountingAgentsFirstTable";
import accountingAgentsSecondTable from "./accountingAgentsSecondTable";
import accountingReplenishPerformersBalance from "./accountingReplenishPerformersBalance";

const tables = {
	averageSpeed,
	agentGroupsModal,
	agentGroupModal,
	costomerCounterpartyGroupModal,
	costomerCounterpartyGroupsModal,
	counterpartyEmployeeCheck,
	counterpartyEmployeeCheckCard,
	carClass,
	vehicleType,
	color,
	cashlessOrders,
	cashlessAccountsSummaryReport,
	cashlessOrdersGeneral,
	fuelDeductionOrdersReport,
	cashlessOrdersPeriodReport,
	accountsReport,
	agentOrdersReport,
	dispatchersActivityReport,
	object,
	objectGroup,
	service,
	executor,
	vehicle,
	vehiclePark,
	dispatcher,
	role,
	workShift,
	executorTeam,
	executorParameters,
	order,
	client,
	contrAgent,
	history,
	orderAll,
	orderClosed,
	orderExecutable,
	orderLive,
	orderPreliminary,
	orderArchive,
	orderPoints,
	orderArchiveModal,
	orderModal,
	customerTariff,
	customerRateRule,
	transaction,
	agent,
	counterparty,
	counterpartyCheck,
	counterpartyCheckCard,
	counterpartyEmployee,
	counterpartyGroup,
	agentGroup,
	clientGroup,
	costomerCounterparty,
	reward,
	/* accounting page */
	accountingExecutorsGeneral,
	accountingExecutorsFirstTable,
	accountingExecutorsSecondTable,
	accountingBankAccountsFirstTable,
	accountingBankAccountsSecondTableCards,
	accountingBankAccountsSecondTableCalculations,
	accountingAgentsFirstTable,
	accountingAgentsSecondTable,
	accountingReplenishPerformersBalance,
} as const;

export default tables;
