import { react } from "uikit";
import { Dispatch } from "react";

import { validatePhone } from "../../../../../../../../../../../../../../../../../../utils/validatePhone";
import { validateEmail } from "../../../../../../../../../../../../../../../../../../utils/validateEmail";

import Main from ".";

interface Context {
	value: Main.Value;

	setCheckValueError: Dispatch<boolean>;
	setEmailError: (err: boolean) => void;
}

export default class Controller extends react.ControllerBase<Context> {
	validate() {
		const isCheckValueValid =
			!!this.context?.value.additionalFields.checkValue.trim().length;

		const phones = this.context?.value?.additionalFields?.phones;
		const email = this.context?.value?.additionalFields?.email;

		const isValidEmail = email ? validateEmail(email.trim()) : true;
		const isValidPhones = phones?.length
			? phones?.every((phone) => validatePhone(phone) === true)
			: true;

		if (!isCheckValueValid) this.context?.setCheckValueError(true);
		if (!isValidEmail) this.context?.setEmailError(true);

		return isCheckValueValid && isValidEmail && isValidPhones;
	}
}
