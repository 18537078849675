import { RefObject } from "react";
import { react } from "uikit";

import Content from "./components/Content";
import Status from "./components/Status";

interface Context {
	contentRef: RefObject<Content.Ref | null>;
	statusRef: RefObject<Status.Ref | null>;
}

export default class Controller extends react.ControllerBase<Context> {
	validate() {
		const isContentValid = !!this.context?.contentRef.current?.validate();
		const isStatusValid = !!this.context?.statusRef.current?.validate();

		return isContentValid && isStatusValid;
	}
}
