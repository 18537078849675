import React, {
	Dispatch,
	SetStateAction,
	memo,
	useCallback,
	useEffect,
	useMemo,
} from "react";
import { useTranslation } from "react-i18next";
import { MultiSelect } from "uikit";
import Executor from "../../../../../../../../../../../../../../services/Executor";
import { ModelId } from "../../../../../../../../../../../../../../types/ModelId";
import getOptions from "../../../../../../../../../../../../../../utils/generateOptions";
import LabeledField from "../../../../../../../../../../../../../../components/LabeledField";

const MAIN_PHONE_GROUP = 0;

const Phones: React.FC<Phones.Props> = memo(
	({ error, setError, executors, value, onChange }) => {
		const { t } = useTranslation();
		const selectValue = useMemo(
			() => value.map((item) => item.phone),
			[value],
		);

		const allPhones = useMemo(
			() => executors.flatMap((e) => e.person?.phones),
			[executors],
		);

		const mainPhones = useMemo(
			() =>
				executors.flatMap((c) =>
					c.person?.phones?.find((p) => p.group === MAIN_PHONE_GROUP),
				),
			[executors],
		);

		const phoneOptions = useMemo(
			() =>
				executors.length === 1
					? getOptions(allPhones.map((p) => p?.number as string))
					: getOptions(mainPhones.map((p) => p?.number as string)),
			[allPhones, executors.length, mainPhones],
		);

		const handlePhonesChange = useCallback(
			(newPhones) => {
				setError(false);
				onChange(
					newPhones.map((phone) => ({
						taxiServiceId: executors[0].taxiService?.id as number,
						phone,
					})),
				);
			},
			[executors, onChange, setError],
		);

		useEffect(() => {
			if (executors.length === 1) {
				const mainPhone = executors[0].person?.phones?.find(
					(p) => p?.group === MAIN_PHONE_GROUP,
				);
				if (mainPhone)
					onChange([
						{
							taxiServiceId: executors[0].taxiService
								?.id as number,
							phone: mainPhone.number,
						},
					]);
			} else {
				const newContacts = executors.flatMap((e) => ({
					taxiServiceId: e.taxiService?.id,
					phone: e.person?.phones?.[0].number,
				}));

				onChange(newContacts as Phones.Value[]);
			}
		}, [executors, onChange]);

		useEffect(() => {
			console.log("error:", error);
		}, [error]);

		return (
			<LabeledField
				label={
					selectValue.length === 1
						? t(
								"pages.mainPage.pages.accounts.tabs.executors.header.sendingSMS.modal.phones.str200",
						  ) ?? ""
						: t(
								"pages.mainPage.pages.accounts.tabs.executors.header.sendingSMS.modal.phones.str201",
						  ) ?? ""
				}
			>
				<MultiSelect
					error={error}
					disabled={executors.length !== 1}
					placeholder={
						t(
							"pages.mainPage.pages.accounts.tabs.executors.header.sendingSMS.modal.phones.str0",
						) ?? ""
					}
					options={phoneOptions}
					value={selectValue}
					onChange={handlePhonesChange}
				/>
			</LabeledField>
		);
	},
);

declare namespace Phones {
	interface Props {
		error: boolean;
		setError: Dispatch<SetStateAction<boolean>>;
		executors: Executor.Model[];
		value: Value[];
		onChange: (x: Value[]) => void;
	}

	interface Value {
		phone: string;
		taxiServiceId: ModelId;
	}
}

export default Phones;
