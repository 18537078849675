import { react } from "uikit";
import { RefObject } from "react";

import Code from "./components/Code";

interface Context {
	codeRef: RefObject<Code.Ref>;
}

export default class Controller extends react.ControllerBase<Context> {
	validate() {
		const isValidCode = !!this.context?.codeRef.current?.validate();

		return isValidCode;
	}
}
