import React, {
	Dispatch,
	RefAttributes,
	useCallback,
	useMemo,
	useState,
} from "react";
import { useTranslation } from "react-i18next";
import { Option, react } from "uikit";
import LabeledField from "../../../../../../../../../../../../../../../../components/LabeledField";

import useModelSubscribe from "../../../../../../../../../../../../../../../../hooks/useModelSubscribe2";
import CarBaseType from "../../../../../../../../../../../../../../../../services/CarBaseType";
import SelectWithModal from "../../../../../../../../../../../../../../../../components/SelectWithModal";
import InternalController from "./Controller";

const CarBaseTypeId = react.withController<
	CarBaseTypeId.Props,
	CarBaseTypeId.Controller
>(
	({
		controller,

		value,

		taxiServiceIds,
		disabled,

		onChange,
	}) => {
		const { t } = useTranslation();
		const [error, setError] = useState(false);

		const modelData = useModelSubscribe({ taxiServiceIds }, CarBaseType);

		const items = useMemo(() => modelData.models, [modelData.models]);

		const selectOptions = useMemo<Option<number>[]>(
			() =>
				items.map((item) => ({
					key: item.id,
					label: item.name,
					value: item.id,
				})),
			[items],
		);

		const selectOnChange = useCallback(
			(newValue: SelectWithModal.Value<number>) => {
				setError(false);

				onChange(newValue as number | undefined);
			},
			[onChange],
		);

		controller.setContext({ value, setError });

		return (
			<LabeledField
				label={
					t(
						"pages.mainPage.pages.accounts.tabs.car.editModal.content.mainTab.mainData.carBaseTypeId.str0",
					) ?? ""
				}
			>
				<SelectWithModal
					value={value}
					disabled={disabled}
					error={error}
					title={
						t(
							"pages.mainPage.pages.accounts.tabs.car.editModal.content.mainTab.mainData.carBaseTypeId.str0",
						) ?? ""
					}
					options={selectOptions}
					onChange={selectOnChange}
				/>
			</LabeledField>
		);
	},
	InternalController,
);

declare namespace CarBaseTypeId {
	type Ref = InternalController | null;

	type Controller = InternalController;

	type Value = number | undefined;

	interface PropsBase {
		value: Value;

		taxiServiceIds?: number[];
		disabled: boolean;

		onChange: Dispatch<Value>;
	}

	type Props = PropsBase & RefAttributes<Ref>;
}

export default CarBaseTypeId;
