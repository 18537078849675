import React, {
	Dispatch,
	RefAttributes,
	useCallback,
	useMemo,
	useState,
} from "react";
import { useTranslation } from "react-i18next";
import { Option, Select, react } from "uikit";

import LabeledField from "../../../../../../../../../../../../../../../../components/LabeledField";
import useModelSubscribe from "../../../../../../../../../../../../../../../../hooks/useModelSubscribe";
import mapByKey from "../../../../../../../../../../../../../../../../utils/mapByKey";
import WorkShift from "../../../../../../../../../../../../../../../../services/WorkShift";

import InternalController from "./Controller";

const WorkShiftId = react.withController<
	WorkShiftId.PropsBase,
	WorkShiftId.Controller
>(
	({
		value,

		disabled,
		taxiServiceId,

		onChange,
	}) => {
		const { t } = useTranslation();

		const taxiServiceIds = useMemo(
			() => (typeof taxiServiceId === "number" ? [taxiServiceId] : []),
			[taxiServiceId],
		);

		const modelData = useModelSubscribe(
			{
				taxiServiceIds,
			},
			WorkShift,
		);

		const items = useMemo(() => modelData?.cache ?? [], [modelData?.cache]);

		const [error, setError] = useState(false);

		const selectOptions = useMemo<Option<number>[]>(
			() =>
				items.map((item) => ({
					key: item.id,
					label: item.name ?? "",
					value: item.id,
				})),
			[items],
		);

		const selectOptionByKey = useMemo(
			() => mapByKey(selectOptions, "key"),
			[selectOptions],
		);

		const selectOnChange = useCallback(
			(key) => {
				setError(false);

				onChange(selectOptionByKey[key].value);
			},
			[onChange, selectOptionByKey],
		);

		return (
			<LabeledField
				label={
					t(
						"pages.mainPage.pages.accounts.tabs.executors.editModal.content.mainTab.workData.workShiftId.str200",
					) ?? ""
				}
			>
				<Select
					value={value}
					disabled={disabled}
					error={error}
					placeholder={
						t(
							"pages.mainPage.pages.accounts.tabs.executors.editModal.content.mainTab.workData.workShiftId.str200",
						) ?? ""
					}
					options={selectOptions}
					onChange={selectOnChange}
				/>
			</LabeledField>
		);
	},
	InternalController,
);

declare namespace WorkShiftId {
	type Ref = InternalController | null;

	type Controller = InternalController;

	type Value = number | undefined;
	type NewValue = number;

	interface PropsBase {
		value: Value;

		disabled: boolean;
		taxiServiceId?: number;

		onChange: Dispatch<NewValue>;
	}

	type Props = PropsBase & RefAttributes<Ref>;
}

export default WorkShiftId;
