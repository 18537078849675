import { ReactNode } from "react";

import { Language } from "../../../../../../../../../../../services";
import translationTablePath from "../constants";

import fullName from "./fullName";
import alias from "./alias";
import phones from "./phones";
import role from "./role";
import status from "./status";
import registeredAt from "./registeredAt";
import access from "./access";
import birthAt from "./birthAt";

export enum ColumnId {
	FullName = "fullName",
	Alias = "alias",
	Phones = "phones",
	Role = "role",
	Status = "status",
	RegisteredAt = "registeredAt",
	Access = "access",
	BirthAt = "birthAt",
}

export interface Column {
	render: (options: Column.Options) => ReactNode;
	label: string | string[];
}

export declare namespace Column {
	interface Options {
		width?: number;
		language: Language;
		onResize?: (width: number, columnId: ColumnId) => void;
	}
}

export const columns: Record<ColumnId, Column> = {
	[ColumnId.FullName]: {
		render: fullName,
		label: [`${translationTablePath}.${ColumnId.FullName}`, "Full Name"],
	},

	[ColumnId.Alias]: {
		render: alias,
		label: [`${translationTablePath}.${ColumnId.Alias}`, "Alias"],
	},

	[ColumnId.Phones]: {
		render: phones,
		label: [`${translationTablePath}.${ColumnId.Phones}`, "Phones"],
	},

	[ColumnId.Role]: {
		render: role,
		label: [`${translationTablePath}.${ColumnId.Role}`, "Role"],
	},

	[ColumnId.Status]: {
		render: status,
		label: [`${translationTablePath}.${ColumnId.Status}`, "Status"],
	},

	[ColumnId.RegisteredAt]: {
		render: registeredAt,
		label: [
			`${translationTablePath}.${ColumnId.RegisteredAt}`,
			"RegisteredAt",
		],
	},

	[ColumnId.Access]: {
		render: access,
		label: [`${translationTablePath}.${ColumnId.Access}`, "Access"],
	},

	[ColumnId.BirthAt]: {
		render: birthAt,
		label: [`${translationTablePath}.${ColumnId.BirthAt}`, "BirthAt"],
	},
};

export const defaultColumnIds = [
	ColumnId.FullName,
	ColumnId.Alias,
	ColumnId.Phones,
	ColumnId.Role,
	ColumnId.Status,
	ColumnId.RegisteredAt,
	ColumnId.Access,
	ColumnId.BirthAt,
];

export const disabledColumnsIds = [];

export default columns;
