/* eslint-disable no-shadow */

import React, { Dispatch, RefAttributes, useMemo, memo } from "react";
import { Column, Row, TextBox, react, useRefWithSetter } from "uikit";
import FullName from "./components/FullName";
import LabeledField from "../../../../../../../../../../../../../../components/LabeledField";
import Authorization from "./components/Authorization";
import Phones from "./components/Phones";
import InternalController from "./Controller";
import useObjectEditor from "../../../../../../../../../../../../../../hooks/useObjectEditor";

const PersonDataBase = react.withController<
	PersonData.PropsBase,
	PersonData.Controller
>(({ controller, value, disabled, onChange }) => {
	const [fullNameRef, setFullNameRef] = useRefWithSetter<FullName.Ref | null>(
		null,
	);
	const [authorizationRef, setAuthorizationRef] =
		useRefWithSetter<Authorization.Ref | null>(null);

	controller.setContext({ fullNameRef, authorizationRef });

	const valueEditor = useObjectEditor(value, onChange);

	const fullNameValue = valueEditor.usePicker([
		"lastName",
		"firstName",
		"fatherName",
	]);

	const emailValue = valueEditor.useGetter("email");

	const emailOnChange = valueEditor.useSetter("email");

	const phonesValue = valueEditor.useGetter("phones");

	const phonesOnChange = valueEditor.useSetter("phones");

	const contacts = useMemo(
		() => (
			<Row
				gaps="8px*"
				sizes={phonesValue.length === 3 ? "1fr*" : "275.5px* 315.5px"}
			>
				<LabeledField label="E-mail">
					<TextBox.TextBox
						value={emailValue}
						disabled={disabled}
						style={{
							minHeight: "32px",
							flex: "1 0 0",
						}}
						type="email"
						placeholder="E-mail"
						onChange={emailOnChange}
					/>
				</LabeledField>
				<Phones
					value={phonesValue}
					disabled={disabled}
					max={3}
					onChange={phonesOnChange}
				/>
			</Row>
		),
		[disabled, emailOnChange, emailValue, phonesOnChange, phonesValue],
	);

	const authorizationValue = valueEditor.usePicker(["login", "password"]);

	const assignValue = valueEditor.useAssigner();

	return (
		<Column gaps="8px*">
			<FullName
				ref={setFullNameRef}
				disabled={disabled}
				value={fullNameValue}
				onChange={assignValue}
			/>
			{contacts}
			<Authorization
				ref={setAuthorizationRef}
				disabled={disabled}
				value={authorizationValue}
				onChange={assignValue}
			/>
		</Column>
	);
}, InternalController);

const PersonData = memo(PersonDataBase);

declare namespace PersonData {
	type Ref = InternalController;

	type Controller = InternalController;

	interface Value extends FullName.Value, Authorization.Value {
		phones: Phones.Value;
		email: string;
	}

	interface PropsBase {
		value: Value;

		disabled: boolean;

		onChange: Dispatch<Value>;
	}

	type Props = PropsBase & RefAttributes<Ref>;
}

export default PersonData;
