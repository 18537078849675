import React, { Dispatch, useLayoutEffect, useMemo } from "react";
import { Option, Tabs } from "uikit";
import { useTranslation } from "react-i18next";
import Root from "./components/Root";
import Title from "./components/Title";
import InformationContainer from "./components/InformationContainer";
import EditModal from "../..";

import {
	StyledRow,
	StyledSpan,
	StyledColumn,
	Star,
} from "../../../../../../../../../../components/common";
import { useGetRating } from "../../../../../../../../../../hooks/useGetRating";

const Header: React.FC<Header.Props> = ({
	tab,

	title,
	createdAt,
	counters,
	clientId,
	onChangeTab,
}) => {
	const { t } = useTranslation();
	const { get, response } = useGetRating();

	const tabs = useMemo<Option<EditModal.Tab>[]>(
		() => [
			{
				key: "main",
				value: "main",
				label: t(`mainPage.customers.clients.main.title`),
			},
			{
				key: "options",
				value: "options",
				label: t(`mainPage.customers.clients.options.title`),
			},
			{
				key: "addresses",
				value: "addresses",
				label: t(`mainPage.customers.clients.addresses.title`),
			},
			{
				key: "orders",
				value: "orders",
				label: t(`mainPage.customers.clients.orders.title`),
			},
			{
				key: "transactions",
				value: "transactions",
				label: t(`mainPage.customers.clients.transactions.title`),
			},
			{
				key: "rating",
				value: "rating",
				label: t(`mainPage.customers.clients.rating.title`),
			},
			{
				key: "files",
				value: "files",
				label: t(`mainPage.customers.clients.files.title`),
			},
			{
				key: "executors",
				value: "executors",
				label: t(`mainPage.customers.clients.executors.title`),
			},
			{
				key: "history",
				value: "history",
				label: t(`mainPage.customers.clients.history.title`),
			},
		],
		[t],
	);

	useLayoutEffect(() => {
		if (clientId) get(clientId, "customer");
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [clientId]);

	const failed = useMemo(() => {
		const cancelled = counters?.cancelled || 0;
		const fail = counters?.failed || 0;
		return cancelled + fail;
	}, [counters?.failed, counters?.cancelled]);

	const success = useMemo(() => counters?.success || 0, [counters?.success]);
	const total = useMemo(() => failed + success, [failed, success]);

	const trustLevel = useMemo(() => {
		const rating = response || 5;
		return rating;
	}, [response]);

	return (
		<Root sizes="auto!*">
			<InformationContainer
				align="center"
				justify="space-between"
				gaps="10px"
			>
				<Title>{title}</Title>
				<StyledColumn gap="3px">
					<StyledRow alignItems="center" gap="7px">
						<StyledSpan>{t(`createdAt`)}:</StyledSpan>
						<StyledSpan colors="#21333F" font={{ fw: "600" }}>
							{createdAt
								? new Date(createdAt).toLocaleDateString()
								: new Date().toLocaleDateString()}
						</StyledSpan>
					</StyledRow>

					<StyledRow alignItems="center">
						<StyledSpan text={{ ws: "nowrap" }} p="0 5px 0 0">
							{`${t([
								`orderPage.order_form.total_rides`,
								"Total trips",
							])}: `}
						</StyledSpan>
						<StyledSpan font={{ fw: "700" }} colors="#03a9f4">
							{total}
						</StyledSpan>
						<StyledSpan
							font={{ fw: "700" }}
							colors="#647079"
							p="0 2px"
						>
							/
						</StyledSpan>
						<StyledSpan font={{ fw: "700" }} colors="#4caf50">
							{success}
						</StyledSpan>
						<StyledSpan
							font={{ fw: "700" }}
							colors="#647079"
							p="0 2px"
						>
							/
						</StyledSpan>
						<StyledSpan font={{ fw: "700" }} colors="#f83528">
							{failed}
						</StyledSpan>
						<StyledRow m="0 0 0 10px" w="90px" h="18px">
							<StyledRow w="90px" h="16px">
								<Star value={5} starNumber={1} />
								<Star value={5} starNumber={2} />
								<Star value={5} starNumber={3} />
								<Star value={5} starNumber={4} />
								<Star value={5} starNumber={5} />
							</StyledRow>
							<StyledSpan
								font={{ fw: "700" }}
								p="0 2px"
								colors="#21333F"
							>
								{trustLevel}
							</StyledSpan>
						</StyledRow>
					</StyledRow>
				</StyledColumn>
			</InformationContainer>
			<Tabs
				value={tab}
				options={tabs}
				variant="panels"
				onChange={(newTab) =>
					onChangeTab(newTab.toString() as EditModal.Tab)
				}
			/>
		</Root>
	);
};

declare namespace Header {
	interface Props {
		tab: EditModal.Tab;

		title: string;
		createdAt?: string;
		clientId?: number;
		counters?: {
			cancelled?: number;
			failed?: number;
			success?: number;
			total?: number;
		};
		onChangeTab: Dispatch<EditModal.Tab>;
	}
}

export default Header;
