import React, { memo, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Row, Column, CheckBox, MultiSelect, Stepper } from "uikit";

import { borderStyles, columnGaps, rowGaps } from "../../../constants/styles";
import getOrderOptions from "../../../constants/orderOptions";

const Sixth: React.FC<Sixth.Props> = memo(() => {
	const { t } = useTranslation();

	const orderOptions = useMemo(() => getOrderOptions(t), [t]);
	return (
		<Column gaps={columnGaps} style={borderStyles}>
			<Row gaps={rowGaps} align="center">
				<CheckBox
					disabled
					label={
						t(
							"pages.mainPage.pages.accounts.tabs.executorTeams.modal.content.tabs.main.blocks.sixth.str0",
						) ?? ""
					}
				/>
				<Stepper disabled />
				<span>
					{t(
						"pages.mainPage.pages.accounts.tabs.executorTeams.modal.content.tabs.main.blocks.sixth.str200",
					) ?? ""}
					.{" "}
					{t(
						"pages.mainPage.pages.accounts.tabs.executorTeams.modal.content.tabs.main.blocks.sixth.str201",
					) ?? ""}
					:
				</span>
			</Row>
			<MultiSelect
				disabled
				placeholder={
					t(
						"pages.mainPage.pages.accounts.tabs.executorTeams.modal.content.tabs.main.blocks.sixth.str1",
					) ?? ""
				}
				options={orderOptions}
			/>
		</Column>
	);
});

declare namespace Sixth {
	interface Props {}
}

export default Sixth;
