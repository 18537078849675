/* eslint-disable react-hooks/rules-of-hooks */
import React from "react";
import GeneralEmployees from "../../../../../../../../../../../tabs/Employees";
import Cards from "../../../../../../Cards";

const Employees: React.FC<Employees.Props> = ({
	visible,
	checkCards,
	setCheckCards,
}) => {
	const employees = checkCards.flatMap((card) => card.employees);

	return (
		<GeneralEmployees
			columnsName="counterpartyEmployeeCheck"
			tableFrom="customers.counterparty.check.employees"
			isCheck={true}
			visible={visible}
			employees={employees}
			cards={checkCards}
			setCards={setCheckCards}
		/>
	);
};

declare namespace Employees {
	interface Props {
		visible: boolean;
		checkCards: Value;
		setCheckCards: (cards: Value) => void;
	}

	type Value = Cards.Value;
}

export default Employees;
