import React, {
	Dispatch,
	SetStateAction,
	useCallback,
	useEffect,
	useMemo,
	useState,
} from "react";
import { Column, theme } from "uikit";
import { cloneDeep } from "lodash";
import { useTranslation } from "react-i18next";

import DefaultPageHeader from "../../../../../../../../../../../../../components/DefaultPageHeader";
import DeleteModal from "../../../../../../../../../../../../../components/DeleteModal";
import useCurrentTaxiServices from "../../../../../../../../../../../../../hooks/useCurrentTaxiService";
import { PaymentAccount } from "../../../../../../../../../../../../../types/PaymentAccount";
import {
	UseTableOptions,
	useTableOptions,
} from "../../../../../../../../../../../../../components/LightTable";
import tPath from "../constants/tPath";
import Accounts from "..";

import defaultValue from "./constants/defaultValue";
import Content from "./components/Content";
import Modal from "./components/Modal";
import Root from "./components/Root";

const Cards: React.FC<Cards.Props> = ({
	cards = [],
	setCards,
	checkValue,
	editorTable,
	onChangeTable,
	height,
	notesValue,
	paymentAccounts,
	companyId,
	taxiServiceId,
	counterpartyId,
	allCards = [],
	allChecks = [],
}) => {
	const { t } = useTranslation();
	const { isNotActive, isActive, onActive } = useTableOptions({
		value: editorTable,
		setValue: onChangeTable,
	});

	const [showModal, setShowModal] = useState(false);
	const [showDeleteModal, setShowDeleteModal] = useState(false);

	const [actualDefaultValue, setActualDefaultValue] = useState(defaultValue);

	const settings = useCurrentTaxiServices()?.settings;
	const checkCard = settings?.counterparty.check.checkCard;

	useEffect(() => {
		if (checkCard) {
			setActualDefaultValue({
				...defaultValue,
				active: checkCard?.active,
				accessAllEmployeeToPersonalApp:
					checkCard?.accessAllEmployeeToPersonalApp,
				isAccessToCorporateAccount:
					checkCard?.isAccessToCorporateAccount,
				additionalFields: {
					...defaultValue.additionalFields,
					...notesValue,
					tripLimit: {
						...defaultValue.additionalFields.tripLimit,
						active: checkCard?.tripLimit.active,
						availableChangeOrder:
							checkCard?.tripLimit.availableChangeOrder,
					},
				},
				isLoginOnlyByEmployee: checkCard?.isLoginOnlyByEmployee,
			});
		}
	}, [checkCard, notesValue]);

	const [editingItem, setEditingItem] =
		useState<Modal.Value>(actualDefaultValue);
	const [indexEditingItem, setIndexEditingItem] = useState<number | null>(
		null,
	);

	const [selected, setSelected] = useState<number[]>([]);

	const edit = useCallback(
		(index: number) => {
			const item = cards[index];

			setEditingItem(cloneDeep(item));
			setShowModal(true);
			setSelected([]);
		},
		[cards],
	);

	const editHeaderHandler = useCallback(() => {
		setIndexEditingItem(selected[0]);
		edit(selected[0]);
	}, [edit, selected]);

	const editContentHandler = useCallback(
		(index: number) => {
			setIndexEditingItem(index);
			edit(index);
		},
		[edit],
	);

	const addHandler = useCallback(() => {
		setEditingItem(actualDefaultValue);
		setShowModal(true);
	}, [actualDefaultValue]);

	const preDeleteHandler = useCallback(() => {
		setShowDeleteModal(true);
	}, []);

	const cancelDelete = useCallback(() => {
		setShowDeleteModal(false);
		setSelected([]);
	}, []);

	const deleteHandler = useCallback(() => {
		const newCards = cards.map((item, i) => {
			if (selected.includes(i)) {
				return { ...item, isDelete: true };
			}
			return item;
		});

		setCards(newCards);
		setSelected([]);
		setShowDeleteModal(false);
	}, [cards, selected, setCards]);

	const cancelHandler = useCallback(() => {
		setShowModal(false);
		setEditingItem(actualDefaultValue);
	}, [actualDefaultValue]);

	const saveHandler = useCallback(
		(card: Modal.Value, index: number | null = null) => {
			const internalIndex = index ?? indexEditingItem;

			if (internalIndex != null) {
				const newCards = [...cards];
				newCards[internalIndex] = card;
				setCards(newCards);
			} else {
				setCards([...cards, card]);
			}

			setShowModal(false);
			setEditingItem(actualDefaultValue);
			setIndexEditingItem(null);
		},
		[actualDefaultValue, cards, indexEditingItem, setCards],
	);

	const labelDeleteModal = useMemo(() => {
		if (selected.length > 1) {
			return (
				t([
					`${tPath}.modal.tabs.cards.deleteModal.title2`,
					"Do you really want to delete the card?",
				]) || ""
			);
		}
		return (
			t([
				`${tPath}.modal.tabs.cards.deleteModal.title`,
				"Do you really want to delete the cards?",
			]) || ""
		);
	}, [selected.length, t]);

	return (
		<Root sizes="auto! auto! 1fr" gaps="16px" maxedWidth maxedHeight>
			<Column
				style={{
					height: "100%",
					flex: "1 1 auto",
				}}
				sizes="auto auto 1fr"
				gaps="8px*"
			>
				<div
					style={{
						backgroundColor: theme.colors.background_color,
						borderTopRightRadius: theme.borderRadius.m,
						fontFamily: "Lato",
						fontWeight: 600,
						fontSize: "18px",
						padding: "5px 15px",
						color: theme.colors.primary,
					}}
				>
					{t(
						"pages.mainPage.pages.customers.tabs.counterparties.modal.content.accounts.cards.str150",
					) ?? ""}
				</div>
				<DefaultPageHeader
					canAdd={!!checkValue}
					canEdit={selected.length === 1}
					canDelete={!!selected.length}
					onAdd={addHandler}
					onEdit={editHeaderHandler}
					onDelete={preDeleteHandler}
					afterAdditionalButtons={[
						{
							disabled: false,
							icon: { id: "counterpart", size: 20 },
							onClick: () => {
								onActive("yes");
							},
							variation: isActive ? "primary" : "secondary",
							title:
								t([
									`${tPath}.modal.tabs.cards.table.showActive`,
									"Show active cards",
								]) || "",
						},
						{
							disabled: false,
							icon: { id: "counterpart", size: 20 },
							onClick: () => {
								onActive("no");
							},
							variation: isNotActive ? "primary" : "secondary",
							title:
								t([
									`${tPath}.modal.tabs.cards.table.showNoActive`,
									"Show inactive cards",
								]) || "",
						},
					]}
				/>
				<Content
					value={cards}
					selected={selected}
					setSelected={setSelected}
					onEdit={editContentHandler}
					saveHandler={saveHandler}
					height={height}
				/>
			</Column>
			{showModal && (
				<Modal
					value={editingItem}
					onCancel={cancelHandler}
					onSave={saveHandler}
					paymentAccounts={paymentAccounts}
					companyId={companyId}
					taxiServiceId={taxiServiceId}
					counterpartyId={counterpartyId}
					cards={allCards}
					checks={allChecks}
				/>
			)}
			{showDeleteModal && (
				<DeleteModal
					label={labelDeleteModal}
					onCancel={cancelDelete}
					onConfirm={deleteHandler}
				/>
			)}
		</Root>
	);
};

declare namespace Cards {
	type Value = Modal.Value[];

	interface Props {
		cards: Value;
		setCards: Dispatch<SetStateAction<Value>>;
		checkValue: string | null;
		editorTable: UseTableOptions["editor"];
		onChangeTable: UseTableOptions["onChange"];
		height: number;
		paymentAccounts: PaymentAccount[];
		companyId?: number;
		taxiServiceId?: number;
		counterpartyId?: number;
		allCards: Value;
		allChecks?: Accounts.Check[];
		notesValue: {
			notes: string;
			orderNotes: string;
			executorNotes: string;
		};
	}
}

export default Cards;
