import styled from "styled-components";
import { theme } from "uikit";

const Label = styled.div<Label.Props>`
	font-family: "Lato";
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 17px;

	color: ${({ selected }) =>
		selected ? theme.colors.primary : theme.colors.secondary};
`;

declare namespace Label {
	interface Props {
		selected: boolean;
	}
}

export default Label;
