import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { Dispatcher } from "../../../../../../../../../../../../services";
import { CellContentRoot } from "../../../../../../../../../../../../components/LightTable";

const StatusCellContent: React.FC<StatusCellContent.Props> = ({ item }) => {
	const { t } = useTranslation();

	const content = useMemo(() => {
		if (!item.status) return "";

		const statusToName: Record<string, string> = {
			active:
				t(
					`pages.mainPage.pages.accounts.tabs.users.editModal.content.mainTab.workData.status.active`,
				) ?? "",
			blocked:
				t(
					`pages.mainPage.pages.accounts.tabs.users.editModal.content.mainTab.workData.status.blocked`,
				) ?? "",
			dismissed:
				t(
					`pages.mainPage.pages.accounts.tabs.users.editModal.content.mainTab.workData.status.dismissed`,
				) ?? "",
		};

		return statusToName?.[item.status] ?? "";
	}, [item.status, t]);

	return (
		<CellContentRoot alignItems="center" w="100%" h="100%">
			{content}
		</CellContentRoot>
	);
};

declare namespace StatusCellContent {
	interface Props {
		item: Dispatcher.Model;
	}
}

export default StatusCellContent;
