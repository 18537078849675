import React from "react";
import { RowDataType } from "rsuite-table";
import { TFunction } from "i18next";

import Counterparty from "../../../../../../../../services/Counterparty";
import Language from "../../../../../../../../services/Language";
import BalanceOutput from "../../../../../../../../components/BalanceOutput";
import { StyledRow, StyledP } from "../../../../../../../../components/common";

export const CounterpartyCellDataIds = {
	name: "name",
	edrpou: "edrpou",
	group: "counterpartyGroupName",
	contactName: "contactName",
	phones: "phones",
	email: "email",
	orderCount: "orderCount",
	overallBalance: "overallBalance",
	overallBonusBalance: "overallBonusBalance",
	disconnectionThreshold: "disconnectionThreshold",
	accountsBalance: "accountsBalance",
	accountsBonusBalance: "accountsBonusBalance",
	accountsDisconnectionThreshold: "accountsDisconnectionThreshold",
	active: "active",
	company: "company",
	registrationBranch: "registrationBranch",
} as const;

export type TypeCounterpartyCellDataIds = typeof CounterpartyCellDataIds;
export type ValueCounterpartyCellDataIds =
	(typeof CounterpartyCellDataIds)[keyof typeof CounterpartyCellDataIds];

const getCounterpartyCellData = (
	rowData: RowDataType<Counterparty.Model>,
	columnId: string,
	lang: Language,
	t: TFunction<string[], undefined, string[]>,
) => {
	const model = rowData as Counterparty.Model;

	switch (columnId) {
		case "name":
			return model.additionalFields?.name;

		case "edrpou":
			return model.additionalFields?.edrpou;

		// case "overallBalance":
		// 	return (
		// 		<BalanceOutput
		// 			balanceType="main"
		// 			value={model.paymentAccounts}
		// 		/>
		// 	);

		// case "overallBonusBalance":
		// 	return (
		// 		<BalanceOutput
		// 			balanceType="bonus"
		// 			value={model.paymentAccounts}
		// 		/>
		// 	);
		// case "disconnectionThreshold":
		// 	return (
		// 		<BalanceOutput
		// 			balanceType="threshold"
		// 			value={model?.paymentAccounts}
		// 		/>
		// 	);
		case "accountsBalance":
			return model.checks.map((account) => {
				const name = account?.additionalFields?.checkValue ?? "";
				return (
					<StyledRow
						key={account.id}
						w="100%"
						justify="start"
						gap="10px"
					>
						<StyledP colors={model?.active ? "" : "#9BA3A8"}>
							{name}:
						</StyledP>

						<BalanceOutput
							balanceType="main"
							value={account.paymentAccounts}
						/>
					</StyledRow>
				);
			});

		case "accountsBonusBalance":
			return model.checks.map((account) => {
				const name = account?.additionalFields?.checkValue ?? "";
				return (
					<StyledRow
						key={account.id}
						w="100%"
						justify="start"
						gap="10px"
					>
						<StyledP colors={model?.active ? "" : "#9BA3A8"}>
							{name}:
						</StyledP>

						<BalanceOutput
							balanceType="bonus"
							value={account.paymentAccounts}
						/>
					</StyledRow>
				);
			});
		case "accountsDisconnectionThreshold":
			return model.checks.map((account) => {
				const name = account?.additionalFields?.checkValue ?? "";
				return (
					<StyledRow
						key={account.id}
						w="100%"
						justify="start"
						gap="10px"
					>
						<StyledP colors={model?.active ? "" : "#9BA3A8"}>
							{name}:
						</StyledP>

						<BalanceOutput
							balanceType="threshold"
							value={account.paymentAccounts}
						/>
					</StyledRow>
				);
			});

		case "orderCount":
			return <>{model.rideCounter?.total ?? 0}</>;

		case "contactName":
			return model.additionalFields?.contactName;

		case "phones":
			return model.additionalFields?.phones.join(", ");

		case "email":
			return model.additionalFields?.email;

		case "company":
			return model.company?.name?.[lang];

		case "registrationBranch":
			return model?.defaultTaxiService?.settlement?.[lang];

		// case "registrationDate":
		// 	return new Date(model.createdAt).toLocaleDateString();

		// case "firstTripDate":
		// 	return <></>;

		// case "lastTripDate":
		// 	return <></>;

		// case "mobileApp":
		// 	return (
		// 		<DisabledCheckbox
		// 			value={model.accessAllEmployeeToPersonalApp}
		// 		/>
		// 	);

		// case "corporateAccount":
		// 	return (
		// 		<DisabledCheckbox value={model.isAccessToCorporateAccount} />
		// 	);

		// case "notes":
		// 	return model.additionalFields?.notes;

		case "active":
			return t(`actives.${model.active}`);

		case "counterpartyGroupName":
			return model.counterpartyGroup?.name;

		default:
			return null;
	}
};

export default getCounterpartyCellData;
