import React, { RefAttributes, useCallback, useState } from "react";
import { CheckBox, Row, react, useRefWithSetter } from "uikit";
import { useTranslation } from "react-i18next";

import useObjectEditor from "../../../../../../../../../../../../../../hooks/useObjectEditor";
import EditModal from "../../../../../../../../../../../../../../components/EditModal";
import { NonEditableProperties } from "../../../../../../../../../../../../../../types/NonEditableProperties";
import tPath from "../../constants/tPath";
import Accounts from "../..";

import { AccountsTabs } from "./constants/tabs";
import InternalController from "./Controller";
import Root from "./components/Root";
import Header from "./components/Header";
import Content from "./components/Content";

const Modal = react.withController<Modal.PropsBase, Modal.Controller>(
	({
		controller,
		companyId,
		generalPaymentAccounts,
		generalPaymentTransactions,
		ignoreGeneralThresholdBalance,
		allowGeneralBonusBalance,
		value,
		onCancel,
		onSave,
		title,
	}) => {
		const { t } = useTranslation();

		const [contentRef, setContentRef] =
			useRefWithSetter<Content.Ref | null>(null);
		controller.setContext({ contentRef });

		const [tab, setTab] = useState<Header.Tab>(AccountsTabs.MAIN);

		const [internalValue, setInternalValue] = useState(value);
		const valueEditor = useObjectEditor(internalValue, setInternalValue);

		const contentValue = valueEditor.usePicker([
			"additionalFields",
			"otherFiles",
			"personalFiles",
			"activationDate",
			"completionDate",
			"corporateLogin",
			"corporatePassword",
			"accessAllEmployeeToPersonalApp",
			"isAccessToCorporateAccount",
			"paymentAccounts",
			"paymentTransactions",
			"useGeneralMainBalance",
			"useGeneralBonusBalance",
			"useGeneralThresholdBalance",
			"ignoreThresholdBalance",
			"allowBonusBalance",
			"mainRateId",
			"additionalRateId",
		]);

		const updateContentValue = valueEditor.useAssigner();

		const active = valueEditor.useGetter("active");
		const setActive = valueEditor.useSetter("active");

		const modalOnSave = useCallback(() => {
			if (!contentRef.current?.validate()) return;

			onSave(internalValue);
		}, [contentRef, internalValue, onSave]);

		const checkCards = valueEditor.useGetter("checkCards");
		const setCheckCards = valueEditor.useSetter("checkCards");

		return (
			<EditModal
				footer={
					<Row>
						<CheckBox
							label={t(`${tPath}.modal.active`) || ""}
							value={active}
							onChange={setActive}
						/>
					</Row>
				}
				onCancel={onCancel}
				onSave={modalOnSave}
			>
				<Root sizes="auto! 1fr">
					<Header
						tab={tab}
						title={title}
						createdAt={value.createdAt}
						onChangeTab={setTab}
					/>
					<Content
						companyId={companyId}
						checkCards={checkCards}
						setCheckCards={setCheckCards}
						ref={setContentRef}
						tab={tab}
						id={value.id}
						generalPaymentAccounts={generalPaymentAccounts}
						generalPaymentTransactions={generalPaymentTransactions}
						ignoreGeneralThresholdBalance={
							ignoreGeneralThresholdBalance
						}
						allowGeneralBonusBalance={allowGeneralBonusBalance}
						value={contentValue}
						onChange={updateContentValue}
					/>
				</Root>
			</EditModal>
		);
	},
	InternalController,
);

declare namespace Modal {
	type Ref = InternalController | null;
	type Controller = InternalController;

	interface PropsBase {
		companyId: number | undefined;
		generalPaymentAccounts: Content.Props["generalPaymentAccounts"];
		generalPaymentTransactions: Content.Props["generalPaymentTransactions"];
		allowGeneralBonusBalance: boolean;
		ignoreGeneralThresholdBalance: boolean;
		value: Accounts.Check;
		onCancel: () => void;
		onSave: (value: Accounts.Check) => void;
		title: string;
	}
	type Props = PropsBase & RefAttributes<Ref>;

	interface Value extends Content.Value, Partial<NonEditableProperties> {
		active: boolean;
		mainRateId?: number;
		additionalRateId?: number;
	}
}

export default Modal;
