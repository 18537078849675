import { react } from "uikit";
import TaxiServiceSelect from ".";

interface Context {
	value: TaxiServiceSelect.Value;

	setTaxiServiceIdError: (error: boolean) => void;
}

export default class Controller extends react.ControllerBase<Context> {
	validate() {
		const isTaxiServiceIdValid = this.context?.value != null;

		if (!isTaxiServiceIdValid) this.context?.setTaxiServiceIdError(true);

		return isTaxiServiceIdValid;
	}
}
