import React, { Dispatch, memo } from "react";

import InternalFilters from "./components/Filters";
import Root from "./components/Root";
import Language from "../../../../../../../../../../../../../../../../services/Language";

const HeaderBase: React.FC<HeaderBase.Props> = ({
	filters,

	allowedTaxiServiceIds,
	language,

	onChangeFilters,
}) => (
	<Root gaps="10px*" align="center" maxedWidth>
		<InternalFilters
			value={filters}
			allowedTaxiServiceIds={allowedTaxiServiceIds}
			language={language}
			onChange={onChangeFilters}
		/>
	</Root>
);

declare namespace HeaderBase {
	interface Props {
		filters: InternalFilters.Value;

		allowedTaxiServiceIds: number[];
		language: Language;

		onChangeFilters: Dispatch<InternalFilters.Value>;
	}

	namespace Filters {
		type Value = InternalFilters.Value;
		type Props = InternalFilters.Props;
	}
}

export const Header = memo(HeaderBase);
export default HeaderBase;
