import React, { memo, useCallback, useState } from "react";
import { useRefWithSetter } from "uikit";
import Root from "./components/Root";
import Header from "./components/Header";
import Content from "./components/Content";
import useObjectEditor from "../../../../../../../../../../../../../../hooks/useObjectEditor";
import EditModal from "../../../../../../../../../../../../../../components/EditModal";
import { NonEditableProperties } from "../../../../../../../../../../../../../../types/NonEditableProperties";

const Modal: React.FC<Modal.Props> = memo((props) => {
	const { value, onCancel, onSave, headerTitle } = props;

	const [contentRef, setContentRef] = useRefWithSetter<Content.Ref>(null);

	const [internalValue, setInternalValue] = useState(value);
	const editor = useObjectEditor(internalValue, setInternalValue);

	const handleSubmit = useCallback(() => {
		if (!contentRef.current?.validate()) return;

		onSave(internalValue);
	}, [contentRef, internalValue, onSave]);

	return (
		<EditModal onCancel={onCancel} onSave={handleSubmit}>
			<Root sizes="auto! 1fr">
				<Header title={headerTitle} />
				<Content
					ref={setContentRef}
					value={editor.value}
					onChange={editor.assign}
				/>
			</Root>
		</EditModal>
	);
});

declare namespace Modal {
	interface Props {
		value: Value;
		headerTitle: string;
		onCancel: () => void;
		onSave: (x: any, index?: number | null) => void;
	}

	interface Value extends Content.Value, Partial<NonEditableProperties> {}
}

export default Modal;
