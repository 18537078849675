import React, { memo } from "react";
import { useTranslation } from "react-i18next";

import Transaction from "../../../../../../services/Transaction";

const Type: React.FC<Type.Props> = memo(({ value }) => {
	const { t } = useTranslation();

	const { metadata, targetAction } = value;

	return (
		<div>
			{metadata.context
				? t(`transactionType.${metadata.context}`)
				: t(`transactionType.${targetAction}`)}
		</div>
	);
});

declare namespace Type {
	interface Props {
		value: Value;
	}

	interface Value {
		metadata: Transaction.Model["metadata"];
		targetAction: Transaction.Model["targetAction"];
	}
}

export default Type;
