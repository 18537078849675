/* eslint-disable no-shadow */

import React, { Dispatch, RefAttributes, useMemo, memo } from "react";
import { useTranslation } from "react-i18next";
import { Column, Row, react, useRefWithSetter } from "uikit";

import useObjectEditor from "../../../../../../../../../../../../../../hooks/useObjectEditor";
import {
	StyledGrid,
	StyledRow,
	TextWithStepper,
} from "../../../../../../../../../../../../../../components/common";
import FullName from "./components/FullName";
import Authorization from "./components/Authorization";
import Phones from "./components/Phones";
import InternalController from "./Controller";

const PersonDataBase = react.withController<
	PersonData.PropsBase,
	PersonData.Controller
>(({ controller, value, disabled, onChange }) => {
	const [fullNameRef, setFullNameRef] = useRefWithSetter<FullName.Ref | null>(
		null,
	);
	const [phonesRef, setPhonesRef] = useRefWithSetter<Phones.Ref | null>(null);
	const [authorizationRef, setAuthorizationRef] =
		useRefWithSetter<Authorization.Ref | null>(null);

	controller.setContext({ fullNameRef, phonesRef, authorizationRef });

	const valueEditor = useObjectEditor(value, onChange);

	const fullNameValue = valueEditor.usePicker([
		"lastName",
		"firstName",
		"fatherName",
	]);

	const phonesValue = valueEditor.useGetter("phones");
	const phonesOnChange = valueEditor.useSetter("phones");

	const priority = valueEditor.useGetter("priority");
	const setPriority = valueEditor.useSetter("priority");

	const contacts = useMemo(
		() => (
			<Row
				gaps="8px*"
				sizes={
					phonesValue.length === 3 ? "275.5px*" : "275.5px* 315.5px"
				}
			>
				<Phones
					ref={setPhonesRef}
					value={phonesValue}
					disabled={disabled}
					max={3}
					onChange={phonesOnChange}
				/>
			</Row>
		),
		[disabled, phonesOnChange, phonesValue, setPhonesRef],
	);

	const authorizationValue = valueEditor.usePicker(["login", "password"]);

	const assignValue = valueEditor.useAssigner();

	const { t } = useTranslation();
	return (
		<Column gaps="8px*">
			<FullName
				ref={setFullNameRef}
				disabled={disabled}
				value={fullNameValue}
				onChange={assignValue}
			/>
			{contacts}
			<StyledGrid areas="" columns="1fr auto" alignItems="end">
				<Authorization
					ref={setAuthorizationRef}
					disabled={disabled}
					value={authorizationValue}
					onChange={assignValue}
				/>
				<TextWithStepper
					value={priority}
					onChange={setPriority}
					title={
						t(
							"pages.mainPage.pages.accounts.tabs.executors.editModal.content.mainTab.personData.str200",
						) ?? ""
					}
					stepperSetting={{
						min: 0,
						max: 100,
					}}
					columns="repeat(2,1fr)"
				/>
			</StyledGrid>
		</Column>
	);
}, InternalController);

const PersonData = memo(PersonDataBase);

declare namespace PersonData {
	type Ref = InternalController;

	type Controller = InternalController;

	interface Value extends FullName.Value, Authorization.Value {
		phones: Phones.Value;
		priority: number;
	}

	interface PropsBase {
		value: Value;

		disabled: boolean;

		onChange: Dispatch<Value>;
	}

	type Props = PropsBase & RefAttributes<Ref>;
}

export default PersonData;
