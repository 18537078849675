/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable no-shadow */

import React, { RefAttributes, memo, useMemo, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Column, Row, react, useRefWithSetter } from "uikit";

import TaxiService from "../../../../../../../../../../../../services/TaxiService2";
import Language from "../../../../../../../../../../../../services/Language";
import useObjectEditor from "../../../../../../../../../../../../hooks/useObjectEditor";
import {
	CompaniesAndTaxiServices,
	CompaniesAndTaxiServicesBase,
	Name,
	NameBase,
} from "../../../../../../../../../../../../components/common";
import FieldsContainer from "../../../../../../../../../../../../components/FieldsContainer";
import { TabRoot } from "..";

import InternalController from "./Controller";

const MainTab = memo(
	react.withController<MainTab.PropsBase, MainTab.Controller>(
		({
			controller,
			value,
			disabled,
			visible,
			language /*  onChange */,
		}) => {
			const { t } = useTranslation();

			const [companyBranchRef, setCompanyBranchRef] =
				useRefWithSetter<CompaniesAndTaxiServicesBase.Ref | null>(null);
			const [nameRef, setNameRef] = useRefWithSetter<NameBase.Ref | null>(
				null,
			);

			const currentValueRef = useRef(value);

			currentValueRef.current = value;

			const [, onChange] = useState({});

			const valueEditor = useObjectEditor(value, onChange);

			const nameValue = valueEditor.useGetter("name");
			const nameOnChange = valueEditor.useSetter("name");

			const taxiServicesData = valueEditor.usePicker(
				["taxiServiceIds", "taxiServices", "companyIds"],
				"json",
			);

			const onChangeContent = valueEditor.useAssigner();

			const firstRow = useMemo(
				() => (
					<Row gaps="8px*" sizes="1fr*">
						<Name
							ref={setNameRef}
							name={nameValue || ""}
							setName={nameOnChange}
							label={
								t(
									"pages.settings.pages.finances.tabs.executorTariffPlans2.editModal.content.mainTab.str200",
								) ?? ""
							}
						/>
						<CompaniesAndTaxiServices
							ref={setCompanyBranchRef}
							value={taxiServicesData}
							onChange={onChangeContent}
						/>
					</Row>
				),
				[
					t,
					setNameRef,
					nameValue,
					nameOnChange,
					setCompanyBranchRef,
					taxiServicesData,
					onChangeContent,
				],
			);

			controller.setContext({
				nameRef,
				companyBranchRef,
			});

			return (
				<TabRoot hasPaddings visible={visible}>
					<Column
						sizes="auto! 1fr"
						gaps="19px*"
						maxedWidth
						maxedHeight
					>
						<FieldsContainer
							label={
								t(
									"pages.mainPage.pages.accounts.tabs.roles.editModal.content.mainTab.str4",
								) ?? ""
							}
						>
							<Column gaps="8px*">{firstRow}</Column>
						</FieldsContainer>
					</Column>
				</TabRoot>
			);
		},
		InternalController,
	),
);

declare namespace MainTab {
	type Ref = InternalController | null;

	type Controller = InternalController;

	interface Value {
		name: string;
		companyIds: number[];
		taxiServiceIds: number[];
		taxiServices?: TaxiService.Model[];
	}

	interface PropsBase {
		value: Value;

		disabled: boolean;
		visible: boolean;
		language: Language;

		// onChange: Dispatch<Value>;
	}

	type Props = PropsBase & RefAttributes<Ref>;
}

export default MainTab;
