import React, { useMemo } from "react";

import { Dispatcher } from "../../../../../../../../../../../../services";
import { useTypedSelector } from "../../../../../../../../../../../../redux/store";
import mapByKey from "../../../../../../../../../../../../utils/mapByKey";
import { CellContentRoot } from "../../../../../../../../../../../../components/LightTable";

const AccessCellContent: React.FC<AccessCellContent.Props> = ({ item }) => {
	const language = useTypedSelector((state) => state.session.language);

	const { models: taxiServices } = useTypedSelector(
		(state) => state.taxiServices,
	);

	const taxiServiceById = useMemo(
		() => mapByKey(taxiServices, "id"),
		[taxiServices],
	);

	const content = useMemo(() => {
		if (!item.taxiServiceIds || !Array.isArray(item.taxiServiceIds)) {
			return "Bad taxiServiceIds";
		}

		return (
			item.taxiServiceIds
				.map((taxiServiceId) => {
					const taxiService = taxiServiceById[taxiServiceId];
					return taxiService && taxiService.settlement
						? taxiService.settlement[language] || ""
						: "";
				})
				.filter((taxiServiceName) => taxiServiceName)
				.join(", ") || "Bad taxiServiceIds"
		);
	}, [item.taxiServiceIds, taxiServiceById, language]);

	return (
		<CellContentRoot alignItems="center" w="100%" h="100%">
			{content}
		</CellContentRoot>
	);
};

declare namespace AccessCellContent {
	interface Props {
		item: Dispatcher.Model;
	}
}

export default AccessCellContent;
