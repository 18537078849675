/* eslint-disable no-shadow */

import React, {
	Dispatch,

	ReactNode,
	useCallback,
	useEffect,
	useLayoutEffect,
} from "react";
import { CheckBox, Column } from "uikit";
import { RowDataType, SortType } from "rsuite-table";
import { useTranslation } from "react-i18next";

import Client from "../../../../../../../../../../../../../../../../services/Client";
import LightTable, {
	UseTableOptions,
	useTableOptions,
} from "../../../../../../../../../../../../../../../../components/LightTable";
import TableSettings from "../../../../../../../../../../../../../../../../components/TableSettings";
import tables from "../../../../../../../../../../../../../../../../constants/tables";
import {
	ColumnId,
	ColumnIds,
} from "../../../../../../../../../../../../../../../../constants/tables/types";
import {
	useColumns,
	useVisibleColumns,
} from "../../../../../../../../../../../../../../../../hooks/useTableSettings";

import { columnProps } from "./constants";

declare namespace ModelTable {
	interface Sort {
		column?: string;
		type?: SortType;
	}

	type ColumnValue = number | string | ReactNode;
	interface RowData extends Record<ColumnId<"client">, ColumnValue> {
		_isActive: boolean;
	}

	type TableData = RowData[];

	interface Props {
		localStorageKey?: string;
		defaultColumns?: ColumnIds<"client">;
		loading: boolean;
		data: TableData;

		selected: number[];
		setSelected: Dispatch<number[]>;

		editorTable: UseTableOptions["editor"];
		onChangeTable: UseTableOptions["onChange"];
	}
}

const ModelTable: React.FC<ModelTable.Props> = ({
	loading,
	data,
	localStorageKey,
	defaultColumns,

	selected,
	setSelected,

	onChangeTable,
	editorTable,
}) => {
	const { t } = useTranslation();

	const {
		sort,
		onSort,
		onScroll,
		tableRef,
		onKeyUp,
		onKeyDown,
		ctrlPressed,
		setDataLength,
	} = useTableOptions({
		value: editorTable,
		setValue: onChangeTable,
		heightRow: 44,
	});

	useLayoutEffect(() => {
		setDataLength(data.length);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data.length]);

	const columns = useColumns("client");
	const { columnIds, setColumnIds } = useVisibleColumns(
		`customers.clientGroups.modal.${localStorageKey ?? ""}`,
		"client",
	);

	useEffect(() => {
		if (defaultColumns) {
			setColumnIds(defaultColumns);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const tableRowClassName = useCallback(
		(rowData) => {
			if (selected.includes(rowData?.id ?? "")) return "selected";

			if (!(rowData as ModelTable.RowData)?._isActive)
				return "not-active";

			return "";
		},
		[selected],
	);

	const updateSelected = useCallback(
		(item: RowDataType<Client.Model>, force = false) => {
			const model = item as Client.Model;
			if (ctrlPressed || force) {
				if (selected.includes(model.id)) {
					setSelected(selected.filter((id) => model.id !== id));
				} else {
					setSelected([...selected, model.id]);
				}
			} else {
				selected.includes(model.id)
					? setSelected([])
					: setSelected([model.id]);
			}
		},
		[ctrlPressed, selected, setSelected],
	);

	const changeAllSelectedHandler = useCallback(
		(isAllSelected: boolean) => {
			if (isAllSelected) {
				setSelected(data.map(({ id }) => Number(id)));
			} else {
				setSelected([]);
			}
		},
		[setSelected, data],
	);

	return (
		<Column sizes="1fr auto!" maxedWidth maxedHeight>
			<div tabIndex={-1} onKeyDown={onKeyDown} onKeyUp={onKeyUp}>
				<LightTable
					ref={tableRef}
					shouldUpdateScroll={false}
					fillHeight
					virtualized
					headerHeight={44}
					rowHeight={44}
					loading={loading}
					data={data}
					rowClassName={tableRowClassName}
					onRowClick={updateSelected}
					sortColumn={sort?.dataKey}
					sortType={sort?.sortType}
					onSortColumn={onSort}
					onScroll={onScroll}
				>
					<LightTable.Column width={36}>
						<LightTable.HeaderCell verticalAlign="middle">
							<CheckBox
								value={
									selected.length === data.length &&
									data.length !== 0
								}
								onChange={changeAllSelectedHandler}
							/>
						</LightTable.HeaderCell>
						<LightTable.Cell verticalAlign="middle">
							{(rowData) => (
								<CheckBox
									value={selected.includes(rowData.id)}
									onChange={() =>
										updateSelected(rowData, true)
									}
								/>
							)}
						</LightTable.Cell>
					</LightTable.Column>
					{columnIds.map((columnId) => (
						<LightTable.Column
							key={columnId}
							resizable
							sortable
							{...columnProps[columnId]}
						>
							<LightTable.HeaderCell verticalAlign="middle">
								{t(
									`tableColumns.customers.clients.${columnId}`,
								)}
							</LightTable.HeaderCell>

							<LightTable.Cell
								verticalAlign="middle"
								dataKey={columnId}
								fullText
							>
								{(rowData) =>
									(rowData as ModelTable.RowData)[columnId]
								}
							</LightTable.Cell>
						</LightTable.Column>
					))}
				</LightTable>
			</div>
			<TableSettings
				value={columnIds}
				defaultValue={tables.client.defaultIds}
				columns={columns}
				onChange={setColumnIds}
			/>
		</Column>
	);
};

export default ModelTable;
