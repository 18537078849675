import Payment from "../services/Payment";
import { PaymentProviderAccessorType } from "../services/Payment/types";
import { PaymentAccount } from "../types/PaymentAccount";

export default async function updateBalances(
	accounts: PaymentAccount[],
	providerAccessorId: number,
	providerAccessorType: PaymentProviderAccessorType,
) {
	const main = accounts.find((a) => a.type === "main");
	const bonus = accounts.find((a) => a.type === "bonus");

	if (main?.isEdited && bonus?.isEdited) {
		const res = await Promise.all([
			updateBalance(main, providerAccessorId, providerAccessorType),
			updateBalance(bonus, providerAccessorId, providerAccessorType),
		]);

		return res;
	}
	if (main?.isEdited) {
		const res = await updateBalance(
			main,
			providerAccessorId,
			providerAccessorType,
		);
		return res;
	}
	if (bonus?.isEdited) {
		const res = await updateBalance(
			bonus,
			providerAccessorId,
			providerAccessorType,
		);

		return res;
	}

	return false;
}

export async function updateBalance(
	account: PaymentAccount,
	providerAccessorId: number,
	providerAccessorType: PaymentProviderAccessorType,
) {
	if (account.isEdited) {
		const toRequest = {
			amount: account.amount,
			description: account.description ?? "",
			paymentAccountId: account.id,
			providerAccessor: {
				id: providerAccessorId,
				type: providerAccessorType,
			},
		};

		const res = await Payment.updateBalance(toRequest);
		return res;
	}

	return false;
}
