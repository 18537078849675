import { Option } from "uikit";

// eslint-disable-next-line no-shadow
export enum CounterpartyPeriods {
	DAY = "day",
	WEEK = "week",
	MONTH = "month",
	CUSTOM = "custom",
}

export const options: Option<string>[] = [
	{
		key: CounterpartyPeriods.DAY,
		value: CounterpartyPeriods.DAY,
		label: CounterpartyPeriods.DAY,
	},
	{
		key: CounterpartyPeriods.WEEK,
		value: CounterpartyPeriods.WEEK,
		label: CounterpartyPeriods.WEEK,
	},
	{
		key: CounterpartyPeriods.MONTH,
		value: CounterpartyPeriods.MONTH,
		label: CounterpartyPeriods.MONTH,
	},
	{
		key: CounterpartyPeriods.CUSTOM,
		value: CounterpartyPeriods.CUSTOM,
		label: CounterpartyPeriods.CUSTOM,
	},
];
