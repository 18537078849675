import styled from "styled-components";
import { theme } from "uikit";

const Title = styled.div`
	font-family: "Lato";
	font-style: normal;
	font-weight: 600;
	font-size: 18px;

	color: ${theme.colors.primary};
`;

export default Title;
