import styled from "styled-components";
import { Column } from "uikit";

const Root = styled(Column)`
	border-radius: 5px;
	overflow: hidden;
	width: 100%;
	min-height: 600px;
`;

export default Root;
