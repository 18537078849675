import { t } from "i18next";
import React, { Dispatch, memo, useCallback } from "react";
import { Column, Row, CheckBox, TextBox, Stepper } from "uikit";
import FieldsContainer from "../../../../../../../../../../../components/FieldsContainer";
import translationPath from "../../../../../../constants/translationPath";

const ButtonSettings: React.FC<ButtonSettings.Props> = memo(
	({ additionalFields, setAdditionalFields }) => {
		const updateHandler = useCallback(
			(newValue, field, i) => {
				const updatedActions = additionalFields.actions.map(
					(a, index) =>
						i === index
							? {
									...a,
									[field]: newValue,
							  }
							: a,
				);
				setAdditionalFields({
					...additionalFields,
					actions: updatedActions,
				});
			},
			[additionalFields, setAdditionalFields],
		);

		return (
			<Column
				style={{
					marginTop: 5,
					maxWidth: "max-content",
				}}
			>
				<FieldsContainer
					label={
						t(
							`${translationPath}.modal.additionalFields.actions.title`,
						) || ""
					}
					gaps="10px"
				>
					{additionalFields.actions.map((action, i) => (
						<Row
							align="center"
							gaps="10px*"
							key={i}
							sizes="1fr auto"
						>
							<Row align="center" gaps="10px*">
								<div style={{ flexGrow: 1 }}>
									<CheckBox
										label={
											t(
												`${translationPath}.modal.additionalFields.actions.types.${action.type}`,
											) || ""
										}
										value={action.active}
										onChange={(newActive) => {
											updateHandler(
												newActive,
												"active",
												i,
											);
										}}
									/>
								</div>
								<TextBox.TextBox
									value={action.text}
									onChange={(newText) => {
										updateHandler(newText, "text", i);
									}}
								/>
							</Row>
							{action.value ? (
								<Stepper
									width="80px"
									value={action.value}
									onChange={(newValue) => {
										updateHandler(newValue, "value", i);
									}}
								/>
							) : (
								<div style={{ width: 80 }} />
							)}
						</Row>
					))}
				</FieldsContainer>
			</Column>
		);
	},
);

declare namespace ButtonSettings {
	interface Props {
		additionalFields: AdditionalFields;
		setAdditionalFields: Dispatch<AdditionalFields>;
	}

	type AdditionalFields = { actions: ExecutorAppActions[] };

	interface ExecutorAppActions {
		active: boolean;
		type: ExecutorAppActionsTypes;
		text: string;
		value?: number;
	}
}
// eslint-disable-next-line no-shadow
export enum ExecutorAppActionsTypes {
	YES_MIN = "yes_min",
	YES = "yes",
	NO = "no",
}

export default ButtonSettings;
