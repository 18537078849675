import Base from "../Base";
import createLogger from "../../utils/logger.util";

const logger = createLogger({ name: "AccountSettings" });

class AccountSettings extends Base {
	public static async getCarJson(): Promise<Record<string, any> | null> {
		const titleError = "[AccountSettings] Error getCarJson:";
		try {
			const result = await this.request(
				(prpc) => prpc.theirsModel.settings.account.getCarJson(),
				{ silent: false, error: true },
			);

			if (result && result.error) {
				logger.error(titleError, result.data);
				return null;
			}

			return result;
		} catch (err: any) {
			logger.error(titleError, err);
			return null;
		}
	}

	public static async getColorJson(): Promise<Record<string, any> | null> {
		const titleError = "[AccountSettings] Error getColorJson:";
		try {
			const result = await this.request(
				(prpc) => prpc.theirsModel.settings.account.getColorJson(),
				{ silent: false, error: true },
			);

			if (result && result.error) {
				logger.error(titleError, result.data);
				return null;
			}

			return result;
		} catch (err: any) {
			logger.error(titleError, err);
			return null;
		}
	}

	public static async updateCarJson(
		json: Record<string, any>,
	): Promise<boolean> {
		try {
			await this.request((prpc) =>
				prpc.theirsModel.settings.account.updateCarJson(json),
			);
		} catch (err: any) {
			logger.error("[AccountSettings] Error updateCarJson:", err);
			return false;
		}
		return true;
	}

	public static async updateColorJson(
		json: Record<string, any>,
	): Promise<boolean> {
		try {
			await this.request((prpc) =>
				prpc.theirsModel.settings.account.updateColorJson(json),
			);
		} catch (err: any) {
			logger.error("[AccountSettings] Error updateColorJson:", err);
			return false;
		}
		return true;
	}
}

export default AccountSettings;
