import { Option } from "uikit";
import { TFunction } from "i18next";

const getTabs = (
	t: TFunction,
): Option<
	"main" | "options" | "additional-data" | "executors" | "files" | "history"
>[] => [
	{
		key: "main",
		value: "main",
		label:
			t(
				"pages.mainPage.pages.accounts.tabs.car.editModal.header.tabs.main",
			) ?? "Main",
	},
	{
		key: "options",
		value: "options",
		label:
			t(
				"pages.mainPage.pages.accounts.tabs.car.editModal.header.tabs.options",
			) ?? "Options",
	},
	{
		key: "additional-data",
		value: "additional-data",
		label:
			t(
				"pages.mainPage.pages.accounts.tabs.car.editModal.header.tabs.additionalData",
			) ?? "Additional Data",
	},
	{
		key: "executors",
		value: "executors",
		label:
			t(
				"pages.mainPage.pages.accounts.tabs.car.editModal.header.tabs.executors",
			) ?? "Executors",
	},
	{
		key: "files",
		value: "files",
		label:
			t(
				"pages.mainPage.pages.accounts.tabs.car.editModal.header.tabs.files",
			) ?? "Files",
	},
	{
		key: "history",
		value: "history",
		label:
			t(
				"pages.mainPage.pages.accounts.tabs.car.editModal.header.tabs.history",
			) ?? "History",
	},
];

type Tabs = ReturnType<typeof getTabs>[number];
export type TabsValue = Tabs["value"];

export { getTabs };
