/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable no-param-reassign */
/* eslint-disable no-shadow */

import React, { Dispatch, RefAttributes, memo, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Column, react } from "uikit";
import File from "../../../../../../../../../../../../services/File";
import useObjectEditor from "../../../../../../../../../../../../hooks/useObjectEditor";

import FileListWithTitle from "../../../../../../../../../../../../components/FileListWithTitle";
import useFileEditor from "./hooks/useFileEditor";
import InternalController from "./Controller";
import TabRoot from "../TabRoot";

const FilesTab = memo(
	react.withController<FilesTab.PropsBase, FilesTab.Controller>(
		({ value, disabled, visible, onChange }) => {
			const { t } = useTranslation();
			const valueEditor = useObjectEditor(value, onChange);

			const [otherFilesValue, otherFilesOnChange] = useFileEditor(
				valueEditor,
				"otherFiles",
			);

			const [passportFilesValue, passportFilesOnChange] = useFileEditor(
				valueEditor,
				"passportFiles",
			);

			const [taxIdFilesValue, taxIdFilesOnChange] = useFileEditor(
				valueEditor,
				"taxIdFiles",
			);

			const [drivingLicenseFilesValue, drivingLicenseFilesOnChange] =
				useFileEditor(valueEditor, "drivingLicenseFiles");

			const [IEFilesValue, IEFilesOnChange] = useFileEditor(
				valueEditor,
				"IEFiles",
			);

			const avatarFiles = valueEditor.useGetter("avatarFiles");
			const setAvatarFiles = valueEditor.useSetter("avatarFiles");
			const setAvatarFilesHandler = useCallback(
				(newFiles: File.Model[]) => {
					newFiles.forEach((file) => {
						file.isPublicImage = true;
						file.upload();
					});
					setAvatarFiles(newFiles);
				},
				[setAvatarFiles],
			);

			const [licenseFilesValue, licenseFilesOnChange] = useFileEditor(
				valueEditor,
				"licenseFiles",
			);

			return (
				<TabRoot hasPaddings visible={visible}>
					<Column
						gaps="24px*"
						sizes="250px!* 0.015625px!"
						maxedWidth
						maxedHeight
					>
						<FileListWithTitle
							value={avatarFiles}
							disabled={disabled}
							title={
								t(
									"pages.mainPage.pages.accounts.tabs.executors.editModal.content.filesTab.str0",
								) ?? ""
							}
							onChange={setAvatarFilesHandler}
						/>
						<FileListWithTitle
							value={otherFilesValue}
							disabled={disabled}
							title={
								t(
									"pages.mainPage.pages.accounts.tabs.executors.editModal.content.filesTab.str1",
								) ?? ""
							}
							onChange={otherFilesOnChange}
						/>
						<FileListWithTitle
							value={passportFilesValue}
							disabled={disabled}
							title={
								t(
									"pages.mainPage.pages.accounts.tabs.executors.editModal.content.filesTab.str2",
								) ?? ""
							}
							onChange={passportFilesOnChange}
						/>
						<FileListWithTitle
							value={taxIdFilesValue}
							disabled={disabled}
							title={
								t(
									"pages.mainPage.pages.accounts.tabs.executors.editModal.content.filesTab.str3",
								) ?? ""
							}
							onChange={taxIdFilesOnChange}
						/>
						<FileListWithTitle
							value={drivingLicenseFilesValue}
							disabled={disabled}
							title={
								t(
									"pages.mainPage.pages.accounts.tabs.executors.editModal.content.filesTab.str4",
								) ?? ""
							}
							onChange={drivingLicenseFilesOnChange}
						/>
						<FileListWithTitle
							value={IEFilesValue}
							disabled={disabled}
							title={
								t(
									"pages.mainPage.pages.accounts.tabs.executors.editModal.content.filesTab.str5",
								) ?? ""
							}
							onChange={IEFilesOnChange}
						/>
						<FileListWithTitle
							value={licenseFilesValue}
							disabled={disabled}
							title={
								t(
									"pages.mainPage.pages.accounts.tabs.executors.editModal.content.filesTab.str6",
								) ?? ""
							}
							onChange={licenseFilesOnChange}
						/>
						<div />
					</Column>
				</TabRoot>
			);
		},
		InternalController,
	),
);

declare namespace FilesTab {
	type Ref = InternalController | null;

	type Controller = InternalController;

	interface Value {
		otherFiles: File.Model[];
		passportFiles: File.Model[];
		taxIdFiles: File.Model[];
		drivingLicenseFiles: File.Model[];
		IEFiles: File.Model[];
		avatarFiles: File.Model[];
		licenseFiles: File.Model[];
	}

	interface PropsBase {
		value: Value;

		disabled: boolean;
		visible: boolean;

		onChange: Dispatch<Value>;
	}

	type Props = PropsBase & RefAttributes<Ref>;
}

export default FilesTab;
