import { TFunction } from "i18next";

const getColumns = (t: TFunction) =>
	[
		{
			id: "fullName",
			label:
				t(
					"pages.mainPage.pages.accounts.tabs.executors.content.modelTable.columns.fullName",
				) ?? "Full Name",
		},
		{
			id: "alias",
			label:
				t(
					"pages.mainPage.pages.accounts.tabs.executors.content.modelTable.columns.alias",
				) ?? "Alias",
		},
		{
			id: "parkNumber",
			label:
				t(
					"pages.mainPage.pages.accounts.tabs.executors.content.modelTable.columns.parkNumber",
				) ?? "Park Number",
		},
		{
			id: "phones",
			label:
				t(
					"pages.mainPage.pages.accounts.tabs.executors.content.modelTable.columns.phones",
				) ?? "Phones",
		},
		{
			id: "balance",
			label:
				t(
					"pages.mainPage.pages.accounts.tabs.executors.content.modelTable.columns.balance",
				) ?? "Balance",
		},
		{
			id: "bonusBalance",
			label:
				t(
					"pages.mainPage.pages.accounts.tabs.executors.content.modelTable.columns.bonusBalance",
				) ?? "Bonus Balance",
		},
		{
			id: "role",
			label:
				t(
					"pages.mainPage.pages.accounts.tabs.executors.content.modelTable.columns.role",
				) ?? "Role",
		},
		{
			id: "company",
			label:
				t(
					"pages.mainPage.pages.accounts.tabs.executors.content.modelTable.columns.company",
				) ?? "Company",
		},
		{
			id: "taxiService",
			label:
				t(
					"pages.mainPage.pages.accounts.tabs.executors.content.modelTable.columns.taxiService",
				) ?? "Taxi Service",
		},
		{
			id: "registeredAt",
			label:
				t(
					"pages.mainPage.pages.accounts.tabs.executors.content.modelTable.columns.registeredAt",
				) ?? "Registered At",
		},
		{
			id: "responsibleDispatcher",
			label:
				t(
					"pages.mainPage.pages.accounts.tabs.executors.content.modelTable.columns.responsibleDispatcher",
				) ?? "Responsible Dispatcher",
		},
		{
			id: "notes",
			label:
				t(
					"pages.mainPage.pages.accounts.tabs.executors.content.modelTable.columns.notes",
				) ?? "Notes",
		},
	] as const;

type Column = ReturnType<typeof getColumns>[number];
export type ColumnId = Column["id"];

export { getColumns };
