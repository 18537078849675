import React, {
	Dispatch,
	RefAttributes,
	memo,
	useCallback,
	useState,
} from "react";
import { useTranslation } from "react-i18next";
import { Column, MultilineTextBox, Row, react, useRefWithSetter } from "uikit";
import { cloneDeep } from "lodash";

import Client from "../../../../../../../../../../../../services/Client";
import useObjectEditor from "../../../../../../../../../../../../hooks/useObjectEditor";
import InputModal from "../../../../../../../../../../../../components/InputModal";
import LabeledField from "../../../../../../../../../../../../components/LabeledField";

import StatusSelect from "./components/StatusSelect";
import InternalController from "./Controller";
import Root from "./components/Root";

const CustomInputModalBase = react.withController<
	CustomInputModal.PropsBase,
	CustomInputModal.Controller
>(({ draggable, title, value, onClose, onSubmit, controller }) => {
	const { t } = useTranslation();
	const [statusSelectRef, setStatusSelectRef] =
		useRefWithSetter<StatusSelect.Ref | null>(null);
	controller.setContext({ statusSelectRef });

	const [internalValue, setInternalValue] = useState<CustomInputModal.Value>(
		cloneDeep(value),
	);

	const editor = useObjectEditor(internalValue, setInternalValue);

	const level = editor.useGetter("level");
	const setLevel = editor.useSetter("level");

	const description = editor.useGetter("description");
	const setDescription = editor.useSetter("description");
	const [descriptionError, setDescriptionError] = useState(false);
	const handleDescriptionChange = useCallback(
		(newDesc) => {
			setDescriptionError(false);
			setDescription(newDesc);
		},
		[setDescription],
	);

	const inputModalOnSubmit = useCallback(() => {
		if (value.level === internalValue.level) {
			onClose?.();
		} else if (description?.trim().length) {
			if (!controller.validate()) return;

			onSubmit?.(internalValue);
			onClose?.();
		} else {
			setDescriptionError(true);
			setDescription("");
		}
	}, [
		controller,
		description,
		internalValue,
		onClose,
		onSubmit,
		setDescription,
		value.level,
	]);

	return (
		<InputModal
			draggable={draggable}
			title={title}
			onClose={onClose}
			onSubmit={inputModalOnSubmit}
		>
			<Root sizes="250px!">
				<Column gaps="15px*" sizes="auto! 1fr">
					<Row sizes="1fr" maxedWidth>
						<StatusSelect
							ref={setStatusSelectRef}
							value={level}
							onChange={setLevel}
							setDescription={setDescription}
							setDescriptionError={setDescriptionError}
						/>
					</Row>
					<LabeledField
						label={
							t(
								"pages.mainPage.pages.customers.tabs.clients.editModal.status.customInputModal.str0",
							) ?? ""
						}
					>
						<MultilineTextBox
							disabled={value.level === internalValue.level}
							value={
								value.level === internalValue.level
									? value.description
									: description
							}
							error={
								descriptionError &&
								(t(
									"pages.mainPage.pages.customers.tabs.clients.editModal.status.customInputModal.str200",
								) ??
									"")
							}
							placeholder={
								t(
									"pages.mainPage.pages.customers.tabs.clients.editModal.status.customInputModal.str1",
								) ?? ""
							}
							style={{ flex: "1 0 0" }}
							onChange={handleDescriptionChange}
						/>
					</LabeledField>
				</Column>
			</Root>
		</InputModal>
	);
}, InternalController);

const CustomInputModal = memo(CustomInputModalBase);

declare namespace CustomInputModal {
	type Ref = InternalController;
	type Controller = InternalController;

	interface PropsBase extends Omit<InputModal.Props, "onSubmit"> {
		value: Value;
		onSubmit: Dispatch<Value>;
	}
	type Props = PropsBase & RefAttributes<Ref>;

	interface Value {
		level: Client.Status;
		description: string;
	}
}

export default CustomInputModal;
