import React, {
	Dispatch,
	SetStateAction,
	useCallback,
	useMemo,
	useLayoutEffect,
} from "react";
import { CheckBox } from "uikit";
import { compact } from "lodash";
import { useTranslation } from "react-i18next";

import {
	useColumns,
	useVisibleColumns,
} from "../../../../../../../../../../../../../../../../hooks/useTableSettings";
import formatNumber from "../../../../../../../../../../../../../../../../utils/formatNumber";
import LightTable, {
	useTableOptions,
} from "../../../../../../../../../../../../../../../../components/LightTable";
import TableSettings from "../../../../../../../../../../../../../../../../components/TableSettings";

import DisabledToggleButton from "./components/DisabledToggleButton";
import Root from "./components/Root";
import Modal from "../Modal";

const Content: React.FC<Content.Props> = ({
	value,
	selected,
	setSelected,
	onEdit,
}) => {
	const { t } = useTranslation();

	const {
		sort,
		onSort,
		onScroll,
		tableRef,
		onKeyUp,
		onKeyDown,
		ctrlPressed,

		setDataLength,
	} = useTableOptions({
		itemLimit: 2,
	});

	useLayoutEffect(() => {
		setDataLength(value.length);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [value.length]);

	const columns = useColumns("reward");
	const { columnIds, setColumnIds } = useVisibleColumns(
		"customers.agent.reward",
		"reward",
	);

	const data = useMemo(
		() =>
			columnIds.map((columnId) => {
				const column = columns.find((col) => col.id === columnId);
				if (!column) return null;

				return (
					<LightTable.Column
						resizable
						sortable
						key={columnId}
						verticalAlign="middle"
						flexGrow={1}
					>
						<LightTable.HeaderCell style={{ padding: "0 15px" }}>
							{column.label}
						</LightTable.HeaderCell>
						<LightTable.Cell
							verticalAlign="middle"
							style={{ padding: "0 15px" }}
							dataKey={column.id}
							fullText
						>
							{(rowData) => {
								const model = rowData as Modal.Value;

								switch (columnId) {
									case "active":
										return (
											<DisabledToggleButton
												value={model.active}
											/>
										);

									case "default":
										return (
											<DisabledToggleButton
												value={model.default}
											/>
										);
									case "title":
										return model.name;
									case "percent":
										return formatNumber(
											Number(model.percent),
										);
									case "amount":
										return formatNumber(
											Number(model.amount),
										);
									case "rewardCalculationMethod":
										if (!model.rewardCalculationMethod)
											return "";
										return t(model.rewardCalculationMethod);
									case "maxAmountFromOrderInPercent":
										return formatNumber(
											Number(
												model.maxAmountFromOrderInPercent,
											),
										);

									default:
										return null;
								}
							}}
						</LightTable.Cell>
					</LightTable.Column>
				);
			}),
		[columnIds, columns, t],
	);

	const updateSelected = useCallback(
		(index: number, force = false) => {
			if (ctrlPressed || force) {
				if (selected.includes(index)) {
					setSelected(selected.filter((i) => index !== i));
				} else {
					setSelected([...selected, index]);
				}
			} else {
				selected.includes(index)
					? setSelected([])
					: setSelected([index]);
			}
		},
		[ctrlPressed, selected, setSelected],
	);

	const getClassName = useCallback(
		(rowData) =>
			rowData && selected.includes(rowData.id) ? "selected" : "",
		[selected],
	);

	const handleOnRowClick = useCallback(
		(rowData) => {
			updateSelected(rowData.id);
		},
		[updateSelected],
	);

	const handleOnChangeSelected = useCallback(
		(isAllSelected) => {
			if (isAllSelected) {
				const payload = compact(value.map((item) => item.id));
				setSelected(payload);
			} else {
				setSelected([]);
			}
		},
		[setSelected, value],
	);

	const handleContentEdit = useCallback(
		(rowData) => {
			onEdit?.(rowData.id);
		},
		[onEdit],
	);

	const dataTable = useMemo(
		() => compact([...value].map((item) => item.reward)),
		[value],
	);

	return (
		<Root sizes="1fr auto!">
			<div tabIndex={-1} onKeyDown={onKeyDown} onKeyUp={onKeyUp}>
				<LightTable
					ref={tableRef}
					data={dataTable}
					virtualized
					fillHeight
					rowHeight={46}
					shouldUpdateScroll={false}
					rowClassName={getClassName}
					onRowClick={handleOnRowClick}
					onRowDoubleClick={handleContentEdit}
					sortColumn={sort?.dataKey}
					sortType={sort?.sortType}
					onScroll={onScroll}
					onSortColumn={onSort}
				>
					<LightTable.Column verticalAlign="middle" width={70}>
						<LightTable.HeaderCell style={{ padding: "0 20px" }}>
							<CheckBox
								value={
									selected.length === value.length &&
									value.length !== 0
								}
								onChange={handleOnChangeSelected}
							/>
						</LightTable.HeaderCell>
						<LightTable.Cell
							verticalAlign="middle"
							style={{ padding: "0 25px" }}
						>
							{(data) => (
								<CheckBox
									value={selected.includes(data.id)}
									onChange={() => {
										updateSelected(data.id, true);
									}}
								/>
							)}
						</LightTable.Cell>
					</LightTable.Column>
					{data}
				</LightTable>
			</div>
			<TableSettings
				value={columnIds}
				defaultValue={columnIds}
				columns={columns}
				onChange={setColumnIds}
			/>
		</Root>
	);
};

declare namespace Content {
	interface Value extends Record<string, any> {
		reward: Modal.Value;
		id?: number;
	}

	interface Props {
		value: Value[];
		selected: number[];
		setSelected: Dispatch<SetStateAction<number[]>>;
		onEdit?: Dispatch<number>;
	}

	type ColumnType = {
		id: string;
		label: string;
		width?: number;
		justify?: "center";
	};
}

export default Content;
