/* eslint-disable no-shadow */

import React, { Dispatch, memo } from "react";
import { useTranslation } from "react-i18next";
import { Icon, InputGroup, Row, theme } from "uikit";
import Language from "../../../../../../../../../../../../../../../../../../services/Language";
import useObjectEditor from "../../../../../../../../../../../../../../../../../../hooks/useObjectEditor";
import CompanyAndTaxiServiceSelect from "../../../../../../../../../../../../../../../../../../components/CompanyAndTaxiServiceSelect";
import SearchIconBorders from "../../../../../../../../../../../../../../../../../../components/SearchIconBorders";
import SearchTextBox from "../../../../../../../../../../../../../../../../../../components/SearchTextBox";

const Filters = memo<Filters.Props>(
	({ value, allowedTaxiServiceIds, language, onChange }) => {
		const valueEditor = useObjectEditor(value, onChange);

		const CTSSValue = valueEditor.usePicker([
			"companyIds",
			"taxiServiceIds",
		]);

		const CTSSOnChange = valueEditor.useAssigner();

		const searchTextBoxOnChange = valueEditor.useSetter("search");

		const { t } = useTranslation();
		return (
			<Row gaps="10px" align="center" justify="space-between" maxedWidth>
				<Row sizes="200px!*2" gaps="12px" align="center">
					<CompanyAndTaxiServiceSelect
						value={CTSSValue}
						language={language}
						allowedTaxiServiceIds={allowedTaxiServiceIds}
						onChange={CTSSOnChange}
					/>
				</Row>
				<Row sizes="270px!" gaps="12px 10px*" align="center">
					<InputGroup.InputGroup sizes="auto 1fr">
						<SearchIconBorders>
							<Icon
								id="search2"
								size={16}
								colors={[theme.colors.disabled_text]}
							/>
						</SearchIconBorders>
						<SearchTextBox
							placeholder={`${
								t(
									"pages.mainPage.pages.accounts.tabs.executorTeams.modal.content.tabs.executorSelectTab.modelTable.header.filters.str200",
								) ?? ""
							}...`}
							value={valueEditor.get("search")}
							onChange={searchTextBoxOnChange}
						/>
					</InputGroup.InputGroup>
				</Row>
			</Row>
		);
	},
);

declare namespace Filters {
	interface Value {
		companyIds: number[] | ["all"];
		taxiServiceIds: number[] | ["all"];
		search: string;
	}

	interface Props {
		value: Value;

		allowedTaxiServiceIds: number[];
		language: Language;

		onChange: Dispatch<Value>;
	}
}

export default Filters;
