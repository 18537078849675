import { TFunction } from "i18next";

interface Column {
	id: string;
	label: string;
}

const pathT = "pages.mainPage.pages.accounts.tabs.car.content.modelTable.";

const getColumns = (t: TFunction): Column[] => [
	{
		id: "parkNumber",
		label: t(`${pathT}parkNumber`) ?? "",
	},
	{
		id: "aliases",
		label: t(`${pathT}aliases`) ?? "",
	},
	{
		id: "brand",
		label: t(`${pathT}brand`) ?? "",
	},
	{
		id: "model",
		label: t(`${pathT}model`) ?? "",
	},
	{
		id: "class",
		label: t(`${pathT}class`) ?? "",
	},
	{
		id: "bodyType",
		label: t(`${pathT}bodyType`) ?? "",
	},
	{
		id: "registeredAt",
		label: t(`${pathT}registeredAt`) ?? "",
	},
	{
		id: "responsibleDispatcher",
		label: t(`${pathT}responsibleDispatcher`) ?? "",
	},
	{
		id: "company",
		label: t(`${pathT}company`) ?? "",
	},
	{
		id: "taxiService",
		label: t(`${pathT}taxiService`) ?? "",
	},
	{
		id: "color",
		label: t(`${pathT}color`) ?? "",
	},
	{
		id: "notes",
		label: t(`${pathT}notes`) ?? "",
	},
]; // columns

const defaultColumnIds = [
	"parkNumber",
	"aliases",
	"brand",
	"model",
	"class",
	"bodyType",
	"registeredAt",
	"responsibleDispatcher",
	"company",
	"taxiService",
	"color",
	"notes",
];

export { defaultColumnIds, getColumns };
