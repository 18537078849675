import React, {
	Dispatch,
	RefAttributes,
	SetStateAction,
	memo,
	useCallback,
	useMemo,
	useState,
} from "react";
import { Option, Select, react } from "uikit";
import { useTranslation } from "react-i18next";

import Client from "../../../../../../../../../../../../../../services/Client";
import LabeledField from "../../../../../../../../../../../../../../components/LabeledField";
import getOptions from "../../../../../../../../../../../../../../utils/generateOptions";

import InternalController from "./Controller";

const StatusSelectBase = react.withController<
	StatusSelect.PropsBase,
	StatusSelect.Controller
>(({ controller, value, onChange, setDescriptionError, setDescription }) => {
	const { t } = useTranslation();

	const onStatusChange = useCallback(
		(newValue) => {
			setDescriptionError(false);
			onChange(newValue);
			setDescription("");
			setStatusError(false);
		},
		[onChange, setDescription, setDescriptionError],
	);

	const [statusError, setStatusError] = useState(false);

	controller.setContext({
		status: value,
		onStatusError: setStatusError,
	});

	const options = useMemo(() => {
		const payload = getOptions(Object.values(Client.Status));
		return payload || [];
	}, []);

	const localizedOptions = useMemo<Option<string | number>[]>(
		() =>
			options
				.filter((item) => item.value !== "phone")
				.map((option) => ({
					...option,
					label: t(`statuses.${option.label}`),
				})),
		[options, t],
	);

	return (
		<LabeledField
			label={
				t(`mainPage.customers.clients.main.other.status`) || "Status"
			}
		>
			<Select
				value={value}
				onChange={onStatusChange}
				options={localizedOptions}
				error={statusError}
			/>
		</LabeledField>
	);
}, InternalController);

const StatusSelect = memo(StatusSelectBase);

declare namespace StatusSelect {
	type Ref = InternalController;
	type Controller = InternalController;

	type Value = Client.Status;

	interface PropsBase {
		value: Value;
		onChange: Dispatch<Value>;
		setDescription: (x: string) => void;
		setDescriptionError: Dispatch<SetStateAction<boolean>>;
	}
	type Props = PropsBase & RefAttributes<Ref>;
}

export default StatusSelect;
