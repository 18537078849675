import React, { Dispatch, memo, useCallback, useMemo, useState } from "react";
import {
	Button,
	CheckBox,
	Column,
	Icon,
	Option,
	Row,
	TextSelect,
	theme,
} from "uikit";
import { clamp, clone } from "lodash";
import { useDebouncedCallback } from "use-debounce";
import { useTranslation } from "react-i18next";
import useObjectEditor from "../../../../../../../../../../../../../../../../../hooks/useObjectEditor";
import { useTypedSelector } from "../../../../../../../../../../../../../../../../../redux/store";
import Map from "../../../../../../../../../../../../../../../../../services/Map";
import getTextSelectOptions from "./helpers/getTextSelectOptions";
import tPath from "../../../../../../../../constants/tPath";

const Addresses = memo<Addresses.Props>(
	({ value, min = 1, max = Number.MAX_SAFE_INTEGER, onChange }) => {
		const { t } = useTranslation();

		const internalValue = useMemo(() => {
			if (!value) return value;
			return value?.length < min
				? clone(value).concat({
						value: "",
						group: 0,
						isCounterparty: false,
				  })
				: clone(value)?.slice(0, max);
		}, [max, min, value]);

		const valueEditor = useObjectEditor(internalValue, onChange);
		const queryEditor = useObjectEditor(internalValue, onChange);

		const [options, setOptions] = useState<Option<any>[]>([]);

		const addAddress = useCallback(() => {
			valueEditor.push({ value: "", group: 1, isCounterparty: false });
			queryEditor.push({ value: "", group: 1, isCounterparty: false });
		}, [queryEditor, valueEditor]);

		const { inputRequestDelayMs, minSearchQueryLength, mapLanguage } =
			useTypedSelector((state) => state.settings.map);
		const { defaultCity } = useTypedSelector((state) => state.mapReducer);

		const getOptionsFromSearch = useDebouncedCallback(
			async (query, index) => {
				if (query.length < minSearchQueryLength) return;

				const params: Map.Search.Options = {
					searchType: ["street", "address"],
					country: "ua",
					language: mapLanguage,
					near: {
						point: defaultCity.coordinates || null,
						radius: 9000000,
					},
				};

				const res = await Map.search(query, params);
				const newTextSelectOptions = getTextSelectOptions(res.objects);

				setOptions((prevOptions) => {
					const newOptions = [...prevOptions];

					if (!newOptions[index]) {
						newOptions[index] = {
							key: index,
							label: index,
							value: newTextSelectOptions,
						};
					} else {
						newOptions[index].value = newTextSelectOptions;
					}

					return newOptions;
				});
			},
			inputRequestDelayMs,
		);

		const renderField = useCallback(
			(index: number) => {
				const isFirstItem = index === 0;
				const isLastItem =
					index === valueEditor.value?.length
						? valueEditor.value.length - 1
						: 1;
				const isMaxedItem = index === (max ?? 0) - 1;

				const label = isFirstItem
					? t(`${tPath}.modal.tabs.main.addresses.main`)
					: t(`${tPath}.modal.tabs.main.addresses.additional`);

				let sizes = "";

				if (isFirstItem) sizes = "1fr 32px";
				else sizes = "1fr";

				return (
					<Column gaps="7px*" key={index}>
						<Row gaps="30px*" align="center">
							<span>{label}</span>
							<CheckBox
								label={
									t(
										`${tPath}.modal.tabs.main.addresses.auto`,
									) || ""
								}
								value={
									valueEditor?.value[index]?.isCounterparty
								}
								onChange={(newIsCounterparty) => {
									valueEditor.set(index, {
										...valueEditor.get(index),
										isCounterparty: newIsCounterparty,
									});
								}}
							/>
						</Row>
						<Row gaps="8px*" sizes={sizes}>
							{!isFirstItem ? (
								<Row sizes="1fr 32px!" style={{ flexGrow: 1 }}>
									<TextSelect
										value={
											queryEditor.get(index)?.value ?? ""
										}
										style={{
											minHeight: "32px",
											flex: "1 0 0",
										}}
										placeholder={label}
										onChange={(newAddress) => {
											queryEditor.set(index, {
												...queryEditor.get(index),
												value: newAddress,
											});
											getOptionsFromSearch(
												newAddress,
												index,
											);
										}}
										options={options[index]?.value}
									/>
									<Button.Button
										icon={
											<Icon
												id="minus"
												size={16}
												colors={[theme.colors.white]}
											/>
										}
										style={{
											borderTopLeftRadius: 0,
											borderBottomLeftRadius: 0,
										}}
										onClick={() => {
											valueEditor.remove(index);
											queryEditor.remove(index);
											setOptions((prevOptions) => {
												const newOptions = [
													...prevOptions,
												];
												newOptions.splice(index, 1);
												return newOptions;
											});
										}}
									/>
								</Row>
							) : (
								<TextSelect
									value={queryEditor.get(index)?.value ?? ""}
									style={{
										minHeight: "32px",
										flex: "1 0 0",
									}}
									placeholder={label}
									onChange={(newAddress) => {
										queryEditor.set(index, {
											...queryEditor.get(index),
											value: newAddress,
										});
										getOptionsFromSearch(newAddress, index);
									}}
									options={options[index]?.value}
								/>
							)}
							{isLastItem && !isMaxedItem && (
								<Button.Button
									icon={
										<Icon
											id="plus"
											size={16}
											colors={[theme.colors.white]}
										/>
									}
									onClick={addAddress}
								/>
							)}
						</Row>
					</Column>
				);
			},
			[
				valueEditor,
				max,
				t,
				queryEditor,
				options,
				addAddress,
				getOptionsFromSearch,
			],
		);

		return (
			<>
				{Array(clamp(valueEditor.value?.length || 0, min, max))
					.fill(null)
					.map((_, index) => renderField(index))}
			</>
		);
	},
);

declare namespace Addresses {
	type Value = {
		value: string;
		group: number;
		isCounterparty: boolean;
	}[];

	interface Props {
		value: Value;
		min?: number;
		max?: number;
		onChange: Dispatch<Value>;
	}
}

export default Addresses;
