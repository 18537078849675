import { react } from "uikit";
import { RefObject } from "react";
import CompanySelect from "./components/CompanySelect";
import TaxiServiceSelect from "./components/TaxiServiceSelect";
import CounterpartyConnect from "./components/CounterpartyConnect";

interface Context {
	companySelectRef: RefObject<CompanySelect.Ref | null>;
	taxiServiceSelectRef: RefObject<TaxiServiceSelect.Ref | null>;
	counterpartyConnectRef: RefObject<CounterpartyConnect.Ref>;
}

export default class Controller extends react.ControllerBase<Context> {
	validate() {
		const isCompanySelectValid =
			this.context?.companySelectRef.current?.validate();
		const isTaxiServiceSelectValid =
			this.context?.taxiServiceSelectRef.current?.validate();
		const isCounterpartyConnectValid =
			this.context?.counterpartyConnectRef.current?.validate();

		return (
			isCompanySelectValid &&
			isTaxiServiceSelectValid &&
			isCounterpartyConnectValid
		);
	}
}
