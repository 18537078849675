import React, { useMemo } from "react";
import moment from "moment";

import { Dispatcher } from "../../../../../../../../../../../../services";
import { CellContentRoot } from "../../../../../../../../../../../../components/LightTable";

const RegisteredAtCellContent: React.FC<RegisteredAtCellContent.Props> = ({
	item,
}) => {
	const content = useMemo(
		() =>
			item.createdAt ? moment(item.createdAt).format("DD.MM.YYYY") : "",
		[item?.createdAt],
	);

	return (
		<CellContentRoot alignItems="center" w="100%" h="100%">
			{content}
		</CellContentRoot>
	);
};

declare namespace RegisteredAtCellContent {
	interface Props {
		item: Dispatcher.Model;
	}
}

export default RegisteredAtCellContent;
