import React, { Dispatch, memo } from "react";
import { useTranslation } from "react-i18next";
import { CheckBox, Row } from "uikit";
import useObjectEditor from "../../../../../../../../../../../../../../hooks/useObjectEditor";

const Header: React.FC<Header.Props> = memo(({ value, onChange }) => {
	const { t } = useTranslation();
	const editor = useObjectEditor(value, onChange);

	const isRewardAllowed = editor.useGetter("isRewardAllowed");
	const setIsRewardAllowed = editor.useSetter("isRewardAllowed");

	const isArbitraryRewardAllowed = editor.useGetter(
		"isArbitraryRewardAllowed",
	);
	const setIsArbitraryRewardAllowed = editor.useSetter(
		"isArbitraryRewardAllowed",
	);

	return (
		<Row
			gaps="16px*"
			sizes="1fr 1fr"
			style={{ margin: "24px 24px 0 24px" }}
		>
			<div>
				<CheckBox
					label={
						t(
							"pages.mainPage.pages.customers.tabs.agents.modal.content.tabs.reward.header.str0",
						) ?? ""
					}
					value={isRewardAllowed}
					onChange={setIsRewardAllowed}
				/>
			</div>
			<div>
				<CheckBox
					label={
						t(
							"pages.mainPage.pages.customers.tabs.agents.modal.content.tabs.reward.header.str1",
						) ?? ""
					}
					value={isArbitraryRewardAllowed}
					onChange={setIsArbitraryRewardAllowed}
				/>
			</div>
		</Row>
	);
});

declare namespace Header {
	interface Value {
		isRewardAllowed: boolean;
		isArbitraryRewardAllowed: boolean;
	}

	interface Props {
		value: Value;
		onChange: Dispatch<Value>;
	}
}

export default Header;
