import { RefObject } from "react";
import { react } from "uikit";
import FullName from "./components/FullName";
import Authorization from "./components/Authorization";
import Phones from "./components/Phones";

interface Context {
	fullNameRef: RefObject<FullName.Ref | null>;
	phonesRef: RefObject<Phones.Ref | null>;
	authorizationRef: RefObject<Authorization.Ref | null>;
}

export default class Controller extends react.ControllerBase<Context> {
	validate() {
		const isAuthorizationValid =
			!!this.context?.authorizationRef.current?.validate();
		const isPhonesValid = !!this.context?.phonesRef.current?.validate();
		const isFullNameValid = !!this.context?.fullNameRef.current?.validate();

		return isAuthorizationValid && isPhonesValid && isFullNameValid;
	}
}
