import { react } from "uikit";
import { Dispatch, SetStateAction } from "react";
import BalanceSelect from ".";

interface Context {
	check: BalanceSelect.Props["check"];
	value: BalanceSelect.Props["value"];
	setError: Dispatch<SetStateAction<boolean>>;
}

export default class Controller extends react.ControllerBase<Context> {
	validate() {
		const isValid = this.context?.check ? !!this.context?.value : true;

		if (!isValid) this.context?.setError(true);

		return isValid;
	}
}
