/* eslint-disable react-hooks/rules-of-hooks */

import React, { Dispatch, RefAttributes, memo, useMemo, useState } from "react";
import { Column, react } from "uikit";
import { noop } from "lodash";

import Executor from "../../../../../../../../../../../../services/Executor";
import Language from "../../../../../../../../../../../../services/Language";
import useModelSubscribe from "../../../../../../../../../../../../hooks/useModelSubscribe2";
import useObjectEditor from "../../../../../../../../../../../../hooks/useObjectEditor";
import useTaxiServiceIdsDecoder from "../../../../../../../../../../../../hooks/useTaxiServiceIdsDecoder";

import InternalController from "./Controller";
import ModelTable from "./components/ModelTable";
import Root from "./components/Root";
import ExecutorGroup from "../../../../../../../../../../../../services/ExecutorGroup";

const ExecutorSelectTab = memo(
	react.withController<
		ExecutorSelectTab.PropsBase,
		ExecutorSelectTab.Controller
	>(({ value, disabled, visible, language, onChange }) => {
		const valueEditor = useObjectEditor(value, onChange);

		const executorIds = valueEditor.useGetter("executorIds");
		const setExecutorIds = valueEditor.useSetter("executorIds");
		const allowedTaxiServiceIds = valueEditor.useGetter("taxiServiceIds");
		const companyIds = valueEditor.useGetter("companyIds");

		const [filters, setFilters] = useState<ModelTable.Props["filters"]>({
			companyIds,
			taxiServiceIds: allowedTaxiServiceIds,
			search: "",
		});
		const [sort, setSort] = useState<ModelTable.Sort>({});

		const decodeTaxiServiceIds = useTaxiServiceIdsDecoder({
			allowedTaxiServiceIds,
		});

		const modelSubscriptionOptions =
			useMemo<Executor.SubscribeOptions>(() => {
				const result: Executor.SubscribeOptions = {
					taxiServiceIds: decodeTaxiServiceIds(
						filters.companyIds,
						filters.taxiServiceIds,
					),
					language,
					query: filters.search,
				};

				if (sort.column && sort.type)
					result.order = {
						[sort.column as keyof Executor.SubscribeOptions["order"]]:
							sort.type,
					};

				return result;
			}, [
				decodeTaxiServiceIds,
				filters.companyIds,
				filters.search,
				filters.taxiServiceIds,
				language,
				sort.column,
				sort.type,
			]);

		const data = useModelSubscribe(modelSubscriptionOptions, Executor);
		const items = useMemo(() => data.models ?? [], [data.models]);

		return (
			<Root hasPaddings={false} visible={visible}>
				<Column sizes="1fr" maxedWidth maxedHeight>
					<ModelTable
						filters={filters}
						selected={executorIds}
						sort={sort}
						disabled={disabled}
						loading={false}
						data={items}
						language={language}
						allowedTaxiServiceIds={allowedTaxiServiceIds}
						onChangeFilters={setFilters}
						onChangeSelected={setExecutorIds}
						onChangeSort={setSort}
						onLoadMore={noop}
					/>
				</Column>
			</Root>
		);
	}, InternalController),
);

declare namespace ExecutorSelectTab {
	type Ref = InternalController | null;

	type Controller = InternalController;

	interface Value
		extends Pick<
			ExecutorGroup.Model,
			"companyIds" | "taxiServiceIds" | "executorIds"
		> {
		id?: number;
	}

	interface PropsBase {
		value: Value;

		disabled: boolean;
		visible: boolean;
		language: Language;

		onChange: Dispatch<Value>;
	}

	type Props = PropsBase & RefAttributes<Ref>;
}

export default ExecutorSelectTab;
