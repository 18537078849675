import React, {
	Dispatch,
	RefAttributes,
	SetStateAction,
	memo,
	useCallback,
	useMemo,
	useState,
} from "react";
import { Option, Select, react } from "uikit";
import { useTranslation } from "react-i18next";
import Controller from "./Controller";
import getOptions from "../../../../../../../../../../../../../../../../../../../../utils/generateOptions";
import EntityStatus from "../../../../../../../../../../../../../../../../../../../../types/EntityStatus";
import LabeledField from "../../../../../../../../../../../../../../../../../../../../components/LabeledField";

const options = getOptions(Object.values(EntityStatus));

const StatusSelectBase = react.withController<
	StatusSelect.PropsBase,
	Controller
>(({ controller, value, onChange, setDescriptionError, setDescription }) => {
	const { t } = useTranslation();
	const onStatusChange = useCallback(
		(newValue) => {
			setDescriptionError(false);
			onChange(newValue);
			setDescription("");
			setStatusError(false);
		},
		[onChange, setDescription, setDescriptionError],
	);

	const [statusError, setStatusError] = useState(false);

	controller.setContext({
		status: value,
		onStatusError: setStatusError,
	});

	const localizedOptions = useMemo<Option<string | number>[]>(
		() =>
			options.map((option) => ({
				...option,
				label: t(`statuses.${option.label}`),
			})),
		[t],
	);

	return (
		<LabeledField label={t(`status`) || ""}>
			<Select
				value={value}
				onChange={onStatusChange}
				options={localizedOptions}
				error={statusError}
			/>
		</LabeledField>
	);
}, Controller);

const StatusSelect = memo(StatusSelectBase);

declare namespace StatusSelect {
	type Ref = Controller;

	interface PropsBase {
		value: Value;
		onChange: Dispatch<Value>;
		setDescription: (x: string) => void;
		setDescriptionError: Dispatch<SetStateAction<boolean>>;
	}
	type Props = PropsBase & RefAttributes<Ref>;

	type Value = EntityStatus;
}

export default StatusSelect;
