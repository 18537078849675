import React, {
	Dispatch,
	RefAttributes,
	SetStateAction,
	memo,
	useEffect,
	useState,
} from "react";
import { useTranslation } from "react-i18next";
import { CheckBox, Column, Option, Row, react, useRefWithSetter } from "uikit";
import { useDebouncedCallback } from "use-debounce";
import { useTypedSelector } from "../../../../../../../../../../../../../../../../redux/store";
import Map from "../../../../../../../../../../../../../../../../services/Map";
import useObjectEditor from "../../../../../../../../../../../../../../../../hooks/useObjectEditor";
import search from "./searchFn";
import SearchSettlement from "./components/SearchSettlement";
import SearchStreet from "./components/SearchStreet";
import SearchHouse from "./components/SearchHouse";
import Controller from "./Controller";

const ContentBase = react.withController<Content.PropsBase, Controller>(
	({ value, onChange, controller }) => {
		const { t } = useTranslation();
		const [searchSettlementRef, setSearchSettlementRef] =
			useRefWithSetter<SearchSettlement.Ref>(null);
		const [searchStreetRef, setSearchStreetRef] =
			useRefWithSetter<SearchStreet.Ref>(null);
		const [searchHouseRef, setSearchHouseRef] =
			useRefWithSetter<SearchHouse.Ref>(null);
		controller.setContext({
			searchSettlementRef,
			searchStreetRef,
			searchHouseRef,
		});

		const {
			searchRadiusMeters,
			mapLanguage: language,
			inputRequestDelayMs: delayMs,
		} = useTypedSelector((state) => state.settings.map);

		const editor = useObjectEditor(value, onChange);

		const pointFeature = editor.useGetter("pointFeature");
		const setPointFeature = editor.useSetter("pointFeature");

		const setPointCoordinates = editor.useSetter("pointCoordinates");

		useEffect(() => {
			if (pointFeature?.coordinates) {
				setPointCoordinates(pointFeature?.coordinates);
			}
		}, [pointFeature?.coordinates, setPointCoordinates]);

		const isAgent = editor.useGetter("isAgent");
		const setIsAgent = editor.useSetter("isAgent");

		const [streetFocused, setStreetFocused] = useState(false);
		const [houseFocused, setHouseFocused] = useState(false);

		const [settlement, setSettlement] = useState(pointFeature);
		const [street, setStreet] = useState(pointFeature);

		const debouncedSearchRequest = useDebouncedCallback(
			async (
				query: string,
				searchType: Map.Search.Type,
				setTextSelectOptions: Dispatch<SetStateAction<Content.Options>>,
			) => {
				const optionsToRequest: Map.Search.Options = {
					country: "ua",
					searchType,
					language,
				};

				if (searchType === "street") {
					const nearPoint = settlement?.coordinates;
					if (nearPoint) {
						optionsToRequest.near = {
							point: nearPoint,
							radius: searchRadiusMeters,
						};
					}
				}

				if (searchType === "address") {
					const nearPoint = street?.coordinates;
					if (nearPoint) {
						optionsToRequest.near = {
							point: nearPoint,
							radius: searchRadiusMeters,
						};
					}
				}

				await search(query, optionsToRequest, setTextSelectOptions);
			},
			delayMs,
		);

		return (
			<Column style={{ padding: 20 }} gaps="20px*">
				<SearchSettlement
					ref={setSearchSettlementRef}
					settlement={settlement}
					setSettlement={setSettlement}
					setStreetFocused={setStreetFocused}
					debouncedSearchRequest={debouncedSearchRequest}
				/>
				<Row gaps="8px*" sizes="4fr 1fr">
					<SearchStreet
						ref={setSearchStreetRef}
						disabled={!settlement}
						street={street}
						setStreet={setStreet}
						streetFocused={streetFocused}
						setStreetFocused={setStreetFocused}
						setHouseFocused={setHouseFocused}
						debouncedSearchRequest={debouncedSearchRequest}
					/>
					<SearchHouse
						ref={setSearchHouseRef}
						disabled={!street}
						house={pointFeature}
						setHouse={setPointFeature}
						street={street}
						houseFocused={houseFocused}
						setHouseFocused={setHouseFocused}
						debouncedSearchRequest={debouncedSearchRequest}
					/>
				</Row>
				<CheckBox
					label={
						t(
							"pages.mainPage.pages.customers.tabs.agents.modal.content.tabs.addresses.modal.content.str0",
						) ?? ""
					}
					value={isAgent}
					onChange={setIsAgent}
				/>
			</Column>
		);
	},
	Controller,
);

const Content = memo(ContentBase);

declare namespace Content {
	type Ref = Controller | null;

	interface PropsBase {
		value: Value;
		onChange: Dispatch<Value>;
	}
	type Props = PropsBase & RefAttributes<Ref>;

	interface Value {
		pointCoordinates: { lat: number; lng: number };
		pointFeature: Map.Search.Object | null;
		isAgent: boolean;
	}

	type Options = Option<Map.Search.Object>[];
}

export default Content;
