import React, { memo, useMemo } from "react";

import Transaction from "../../../../../../../../../../../../services/Transaction";
import TransactionsTab from "../../../../../../../../../../../../components/TransactionsTab";
import { PaymentAccount } from "../../../../../../../../../../../../types/PaymentAccount";

const Transactions: React.FC<Transactions.Props> = memo(
	({ paymentAccounts, ...props }) => {
		const paymentAccountIds = useMemo(
			() => paymentAccounts?.map((a) => a.id).filter(Boolean),
			[paymentAccounts],
		);

		const subscribeOptions = useMemo<Transaction.SubscribeOptions>(
			() => ({
				paymentAccountIds,
			}),
			[paymentAccountIds],
		);

		return (
			<TransactionsTab
				options={subscribeOptions}
				isSubscribe={!!paymentAccountIds?.length}
				{...props}
			/>
		);
	},
);

declare namespace Transactions {
	interface Props extends Omit<TransactionsTab.Props, "options"> {
		visible: boolean;
		paymentAccounts?: PaymentAccount[];
	}
}

export default Transactions;
