/* eslint-disable no-param-reassign */
/* eslint-disable no-shadow */
import * as ModelEvent from "@node-elion/syncron";
import { compact } from "lodash";

import ServiceSubscribeOptionsBase from "../../types/ServiceSubscribeOptionsBase";
import Language from "../Language";
import TaxiService from "../TaxiService2";
import Dispatcher from "../Dispatcher";
import CarModel from "../CarModel";
import CarClass from "../CarClass";
import CarColor from "../CarColor";
import Service from "../Service";
import Executor from "../Executor";
import CarPark from "../CarPark";
import Subscription from "../../types/Subscription";
import Base from "../Base";
import Card from "../Card";
import File from "../File";
import CarBodyType from "../CarBodyType";
import SubscriptionPool from "../../redux/services/SubscriptionPool";
import { SortingOrder } from "../../types/SortingOrder";

class Car extends Base {
	// Its needed due to typescript bundler conflict
	private static _Card: Card | null = null;

	public static get Card() {
		if (this._Card) return this._Card;

		this._Card = new Card((prpc) => prpc.theirsModel.car.card);

		return this._Card;
	}

	public static fromResponse(data: any): Car.Model | undefined {
		try {
			const carClass = data?.class
				? CarClass.fromResponse(data.class)
				: undefined;

			const distributableCarClass = data?.compatibleCarToCarClass
				? data?.compatibleCarToCarClass
						?.filter((item) => item?.deletedAt === null)
						.map((item) => CarClass.fromResponse(item?.carClass))
				: [];
			const broadcastableCarClass =
				data?.compatibleCarToCarClassToBroadcastable
					? data?.compatibleCarToCarClassToBroadcastable
							?.filter((item) => item?.deletedAt === null)
							.map((item) =>
								CarClass.fromResponse(item?.carClass),
							)
					: [];

			const taxiService = data?.taxiService
				? TaxiService.fromResponse(data.taxiService)
				: undefined;

			const responsibleDispatcher = data?.responsibleDispatcher
				? Dispatcher.fromResponse(data.responsibleDispatcher)
				: undefined;

			const color = data?.color
				? CarColor.fromResponse(data.color)
				: undefined;

			const model =
				data?.modelToBodyType && data?.modelToBodyType?.model
					? CarModel.fromResponse(data.modelToBodyType.model)
					: undefined;

			const bodyType =
				data?.modelToBodyType && data?.modelToBodyType?.bodyType
					? CarBodyType.fromResponse(data.modelToBodyType.bodyType)
					: undefined;

			const park = data?.park
				? CarPark.fromResponse(data.fleet)
				: undefined;

			const services =
				data?.carToServices?.map(
					(carToService) => carToService.service,
				) || [];

			const executorsItem =
				data?.crews
					?.map(
						(crew) =>
							crew?.crewToExecutors?.map((crewToExecutor) => {
								const value = Executor.fromResponse(
									crewToExecutor.executor,
								);
								if (!value) {
									console.log(`[Car] Executor error`, {
										data,
									});
								}

								return value;
							}) || [],
					)
					.flat(1) || [];

			const executors: any[] = compact(executorsItem);

			const otherFiles = data?.otherFiles?.map(File.fromResponse) || [];
			const transportFiles =
				data?.avatarFiles?.map(File.fromResponse) || [];
			const registrationCertificateFiles =
				data?.registrationCertificateFiles?.map(File.fromResponse) ||
				[];
			const licenseCardFiles =
				data?.licenseCardFiles?.map(File.fromResponse) || [];
			const insuranceFiles =
				data?.wcInsuranceFiles?.map(File.fromResponse) || [];

			const payload = {
				id: data.id,

				class: carClass,
				distributableCarClass,
				broadcastableCarClass,

				taxiService,
				responsibleDispatcher,
				color,
				model,
				bodyType,
				park,
				services,
				executors,
				otherFiles,
				transportFiles,
				registrationCertificateFiles,
				licenseCardFiles,
				insuranceFiles,

				parkNumber: data?.callSign,
				registrationNumber:
					data?.additionalFields?.registrationNumber ?? "",
				vehicleNumber: data?.additionalFields?.vehicleNumber ?? "",
				manufactureYear: data?.additionalFields?.manufactureYear ?? "",
				seats: data.additionalFields?.seats,
				notes: data.notes,
				registrationCertificate:
					data.additionalFields?.registrationCertificate?.notes ?? "",
				licenseCard: data.additionalFields?.licenseCard?.notes ?? "",
				radioStation: data.additionalFields?.radioStation ?? "",
				taximeter: data.additionalFields?.taximeter ?? "",
				insurances: {
					wc: {
						series:
							data.additionalFields?.wcInsurance?.series ?? "",
						number:
							data.additionalFields?.wcInsurance?.seriesNumber ??
							"",
						expirationDate:
							data.additionalFields?.wcInsurance?.dateRange?.to,
					},
					additional:
						data.additionalFields?.additionalInsurance?.map(
							(insurance) => ({
								name: insurance.name ?? "",
								data: insurance.notes ?? "",
								expirationDate: insurance.dateRange?.to,
							}),
						) ?? [],
				},

				active: data.status,

				createdAt: data.createdAt,
				updatedAt: data.updatedAt,
				deletedAt: data.deletedAt,
			};

			return payload;
		} catch (error) {
			console.log("[Car] error", error);
			return undefined;
		}
	}

	public static toRequest(model: Car.Model.New | Car.Model.Modified): any {
		const value = {
			callSign: model.parkNumber,
			status: model.active,
			notes: model.notes,

			taxiServiceId: model.taxiServiceId,
			responsibleDispatcherId: model.responsibleDispatcherId,
			executorIds: model.executorIds,
			colorId: model.colorId,
			classId: model.classId,
			bodyTypeId: model.bodyTypeId,
			modelId: model.modelId,
			fleetId: model.parkId,

			serviceIds: model.serviceIds,
			distributableCarClassIds: model.distributableCarClassIds,
			broadcastableCarClassIds: model.broadcastableCarClassIds,

			additionalFields: {
				registrationNumber: model.registrationNumber ?? "",
				vehicleNumber: model.vehicleNumber ?? "",
				manufactureYear: model.manufactureYear ?? "",
				seats: model.seats,
				registrationCertificate: {
					notes: model.registrationCertificate ?? "",
				},
				licenseCard: {
					notes: model.licenseCard ?? "",
				},
				radioStation: model.radioStation,
				taximeter: model.taximeter,
				wcInsurance: model.insurances
					? {
							series: model.insurances.wc?.series ?? "",
							seriesNumber: model.insurances.wc?.number ?? "",
							dateRange: {
								// from: model.insurances.wc?.expirationDate,
								to: model.insurances.wc?.expirationDate,
							},
					  }
					: undefined,
				additionalInsurance: model.insurances?.additional?.map(
					(insurance) => ({
						name: insurance.name,
						notes: insurance.data,
						dateRange: {
							// from: insurance.expirationDate,
							to: insurance.expirationDate,
						},
					}),
				),
			},

			otherFileIds: model.otherFileIds,
			avatarFileIds: model.transportFileIds,
			registrationCertificateFileIds:
				model.registrationCertificateFileIds,
			licenseCardFileIds: model.licenseCardFileIds,
			wcInsuranceFileIds: model.insuranceFileIds,
		};

		console.log("[Car] toRequest", { value });
		return value;
	}

	public static async getById(
		id: number,
	): Promise<Car.Model | undefined | null> {
		try {
			const res = await this.request(
				(prpc) => prpc.theirsModel.car.getById(id),
				{ silent: false, error: true },
			);

			console.log("[Car] getById", { id, res });

			if (!res?.id) return null;

			return this.fromResponse(res);
		} catch (error) {
			console.log("[Car] error getById", error);
			return null;
		}
	}

	public static async store(object: Car.Model.New) {
		const res = await this.request(
			(prpc) => prpc.theirsModel.car.create(Car.toRequest(object)),
			{ silent: false, error: true },
		);

		console.log("[Car] store", { object, res });
		if (res.error) return res;
		return this.fromResponse(res);
	}

	public static async update(object: Car.Model.Modified) {
		const res = await this.request(
			(prpc) =>
				prpc.theirsModel.car.update(object.id, Car.toRequest(object)),
			{ silent: false, error: true },
		);

		console.log("[Car] update", { object, res });
		if (res.error) return res;
		return this.fromResponse(res);
	}

	static async rating(object: {
		id: Car.Model["id"];
	}): Promise<number | null> {
		try {
			const res = await this.request((prpc) =>
				prpc.theirsModel.feedback.car.rating(object.id),
			);

			return res;
		} catch (error) {
			console.error(error);
			return null;
		}
	}

	public static async destroy(id: number[] | number) {
		if (Array.isArray(id))
			await Promise.all(id.map((id) => this.destroyOne(id)));
		else await this.destroyOne(id);
	}

	public static async checkAlias(
		options: Car.CheckAliasOptions,
	): Promise<boolean> {
		const response = await this.request((prpc) =>
			prpc.theirsModel.car.checkCallSign({
				taxiServiceId: options.taxiServiceId,
				callSign: options.alias,
			}),
		);

		return response.isFree;
	}

	public static async subscribe(
		options: Car.SubscribeOptions,
		onUpdate: Subscription.OnUpdate<Car.Model>,
	): Promise<Subscription<Car.SubscribeOptions> | null> {
		const modelEventConstructor = new ModelEvent.ModelEventConstructor({
			onUpdate: (state) => {
				console.log("[Car]", state);
				const items = compact(state?.models || []);

				const models = compact(items.map(this.fromResponse));

				onUpdate({
					...state,

					models,
				});
			},
		});

		const subscription = await SubscriptionPool.add(
			(prpc) =>
				prpc.theirsModel.car.subscribe({
					params: this.optionsToRequest(options),
					ping: () => true,
					onEvent: async (events) => {
						await modelEventConstructor.onEvent(events);
					},
					onError: (error) => {
						// eslint-disable-next-line no-console
						console.log(error);
					},
				}),
			{ name: "Car.subscribe" },
		);

		return {
			unsubscribe: () => subscription.unsubscribe(),
			update: (options: Car.SubscribeOptions) =>
				subscription.update(this.optionsToRequest(options)),
		} as Subscription<Car.SubscribeOptions>;
	}

	private static async destroyOne(id: number) {
		this.request((prpc) => prpc.theirsModel.car.delete(id));
	}

	private static optionsToRequest(options: Car.SubscribeOptions) {
		const orderColumnToRequestOrderColumn = {
			id: "id",
			status: "status",
			taxiService: "taxiService",
			company: "company",
			parkNumber: "callSign",
		};

		const order = Object.fromEntries(
			Object.entries(options.order ?? {})
				.map(([key, value]) => {
					const column =
						orderColumnToRequestOrderColumn[
							key as keyof typeof orderColumnToRequestOrderColumn
						];
					const direction = value.toUpperCase();

					return column ? [column, direction] : undefined;
				})
				.filter(Boolean) as [string, SortingOrder][],
		);

		return {
			subscribeType: options.subscribeType,
			query: options.query,
			offset: options.offset,
			limit: options.limit,

			ids: options.ids,
			taxiServiceIds: options.taxiServiceIds,
			executorIds: options.executorIds,
			fleetIds: options.carParkIds,

			status: options.active,
			lang: options.language,

			order,
		};
	}
}

export enum CarSubscribeTypeEnum {
	FULL = "full",
	BUILD = "build",
	CAR_PAGE = "car_page",
	CAR_MODAL = "car_modal",
	CAR_MULTISELECT_MODAL = "car_multiselect_modal",
	MAIN_ORDER_PAGE = "main_order_page",
}

export const CAR_SUBSCRIBE_TYPE: string[] = [
	CarSubscribeTypeEnum.CAR_PAGE,
	CarSubscribeTypeEnum.CAR_MODAL,
	CarSubscribeTypeEnum.CAR_MULTISELECT_MODAL,
	CarSubscribeTypeEnum.MAIN_ORDER_PAGE,
];

export const CarSubscribeType = {
	FULL: "full",
	BUILD: "build",
	CAR_PAGE: "car_page",
	CAR_MODAL: "car_modal",
	CAR_MULTISELECT_MODAL: "car_multiselect_modal",
	MAIN_ORDER_PAGE: "main_order_page",
} as const;
export type TypeCarSubscribeType = typeof CarSubscribeType;
export type ValueCarSubscribeType =
	(typeof CarSubscribeType)[keyof typeof CarSubscribeType];

declare namespace Car {
	interface Model {
		id: number;

		class?: CarClass.Model;
		distributableCarClass?: CarClass.Model[];
		broadcastableCarClass?: CarClass.Model[];

		taxiService?: TaxiService.Model;
		responsibleDispatcher?: Dispatcher.Model;
		color?: CarColor.Model;
		model?: CarModel.Model;
		bodyType?: CarBodyType.Model;
		park?: CarPark.Model;
		services?: Service.Model[];
		executors?: Executor.Model[];

		otherFiles?: File.Model[];
		transportFiles?: File.Model[];
		registrationCertificateFiles?: File.Model[];
		licenseCardFiles?: File.Model[];
		insuranceFiles?: File.Model[];

		parkNumber: string;
		registrationNumber: string;
		vehicleNumber: string;
		manufactureYear: string;
		seats?: number;
		notes: string;
		registrationCertificate: string;
		licenseCard: string;
		radioStation: string;
		taximeter: string;
		insurances: Model.Insurances;

		active: boolean;

		createdAt: string;
		updatedAt: string;
		deletedAt: string | null;
	}

	interface CheckAliasOptions {
		taxiServiceId: number;
		alias: string;
	}

	interface SubscribeOptions
		extends Omit<ServiceSubscribeOptionsBase<Car.Model>, "order"> {
		ids?: number[];
		taxiServiceIds?: number[];
		executorIds?: number[];
		carParkIds?: number[];
		subscribeType?: ValueCarSubscribeType;

		active?: boolean;
		language?: Language;

		order?: Record<
			"id" | "status" | "taxiService" | "company" | "parkNumber",
			SortingOrder
		>;
	}

	namespace Model {
		interface Insurances {
			wc?: Insurances.WC;
			additional?: Insurances.Additional;
		}

		interface New {
			taxiServiceId: number;
			responsibleDispatcherId: number;
			colorId?: number;
			modelId?: number;
			bodyTypeId?: number;
			parkId?: number;
			serviceIds?: number[];
			executorIds?: number[];

			classId?: number;
			distributableCarClassIds?: number[];
			broadcastableCarClassIds?: number[];

			otherFileIds?: number[];
			transportFileIds?: number[];
			registrationCertificateFileIds?: number[];
			licenseCardFileIds?: number[];
			insuranceFileIds?: number[];

			parkNumber: string;
			registrationNumber: string;
			vehicleNumber: string;
			manufactureYear: string;
			seats?: number;
			notes?: string;
			registrationCertificate: string;
			licenseCard: string;
			radioStation: string;
			taximeter: string;
			insurances: Model.Insurances;

			active?: boolean;
		}

		type Modified = Partial<New> & Pick<Model, "id">;

		namespace Insurances {
			interface WC {
				series?: string;
				number?: string;
				expirationDate?: Date | null;
			}

			type Additional = Additional.Item[];

			namespace Additional {
				interface Item {
					name?: string;
					data?: string;
					expirationDate?: Date | null;
				}
			}
		}
	}
}

export default Car;
