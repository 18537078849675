import { Option } from "uikit";

// eslint-disable-next-line no-shadow
export enum CardsTabs {
	MAIN = "main",
	EMPLOYEES = "employees",
	ORDERS = "orders",
	TRANSACTIONS = "transactions",
	FILES = "files",
	HISTORY = "history",
}

const tabs: Option<string>[] = [
	{
		key: CardsTabs.MAIN,
		value: CardsTabs.MAIN,
		label: "",
	},
	{
		key: CardsTabs.EMPLOYEES,
		value: CardsTabs.EMPLOYEES,
		label: "",
	},
	{
		key: CardsTabs.ORDERS,
		value: CardsTabs.ORDERS,
		label: "",
	},
	{
		key: CardsTabs.TRANSACTIONS,
		value: CardsTabs.TRANSACTIONS,
		label: "",
	},
	{
		key: CardsTabs.FILES,
		value: CardsTabs.FILES,
		label: "",
	},
	{
		key: CardsTabs.HISTORY,
		value: CardsTabs.HISTORY,
		label: "",
	},
];

export default tabs;
