import React, { Dispatch } from "react";
import { useTranslation } from "react-i18next";
import {
	Button,
	DatePicker,
	Icon,
	InputBorder,
	InputGroup,
	TextBox,
	theme,
} from "uikit";

import useDatePickerLocale from "../../../../../../../../../../../../../../../../hooks/useDatePickerLocale";
import useObjectEditor from "../../../../../../../../../../../../../../../../hooks/useObjectEditor";

const Item: React.FC<Item.Props> = ({
	value,

	disabled,

	onChange,

	onShowFileListModal,
}) => {
	const { t } = useTranslation();
	const locale = useDatePickerLocale();

	const valueEditor = useObjectEditor(value, onChange);

	const nameValue = valueEditor.useGetter("name");
	const nameOnChange = valueEditor.useSetter("name");

	const dataValue = valueEditor.useGetter("data");
	const dataOnChange = valueEditor.useSetter("data");

	const expirationDateValue = valueEditor.useGetter("expirationDate");
	const expirationDateOnChange = valueEditor.useSetter("expirationDate");

	return (
		<InputGroup.InputGroup sizes="1fr*2 130px! 32px!">
			<TextBox.TextBox
				value={nameValue}
				disabled={disabled}
				placeholder={
					t(
						"pages.mainPage.pages.accounts.tabs.car.editModal.content.additionalDataTab.insurances.item.str0",
					) ?? ""
				}
				onChange={nameOnChange}
			/>
			<InputBorder.InputBorder />
			<TextBox.TextBox
				value={dataValue}
				disabled={disabled}
				placeholder={
					t(
						"pages.mainPage.pages.accounts.tabs.car.editModal.content.additionalDataTab.insurances.item.str1",
					) ?? ""
				}
				onChange={dataOnChange}
			/>
			<InputBorder.InputBorder />
			<DatePicker
				value={expirationDateValue}
				placeholder={
					t(
						"pages.mainPage.pages.accounts.tabs.car.editModal.content.additionalDataTab.insurances.item.str2",
					) ?? ""
				}
				disabled={disabled}
				locale={locale}
				onChange={expirationDateOnChange}
			/>
			<Button.Button
				style={{
					borderTopLeftRadius: 0,
					borderBottomLeftRadius: 0,
				}}
				disabled={disabled}
				icon={
					<Icon id="copy" size={16} colors={[theme.colors.white]} />
				}
				onClick={onShowFileListModal}
			/>
		</InputGroup.InputGroup>
	);
};

declare namespace Item {
	interface Value {
		name: string;
		data: string;
		expirationDate?: Date | null;
	}

	interface Props {
		value: Value;

		disabled: boolean;

		onChange: Dispatch<Value>;

		onShowFileListModal: () => void;
	}
}

export default Item;
