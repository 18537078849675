import React, {
	Dispatch,
	SetStateAction,
	memo,
	useCallback,
	useEffect,
	useMemo,
	useState,
} from "react";
import { useTranslation } from "react-i18next";
import { Column, MultilineTextBox, Option, Row, Select, TextBox } from "uikit";
import styled from "styled-components";
import File from "../../../../../../../../../../../../../../services/File";
import PushNotice from "../../../../../../../../../../../../../../services/PushNotice";
import useObjectEditor from "../../../../../../../../../../../../../../hooks/useObjectEditor";

import mapByKey from "../../../../../../../../../../../../../../utils/mapByKey";
import FileUpload from "../../../../../../../../../../../../../../components/FileUpload";
import LabeledField from "../../../../../../../../../../../../../../components/LabeledField";
import { TEMPLATE_MISSING } from "../../defaultValue";

const Count = styled(Row)`
	position: absolute;
	top: -20px;
	right: 0;
	opacity: 0.8;
`;

const Template: React.FC<Template.Props> = memo(
	({ error, setError, templates, value, onChange }) => {
		const { t } = useTranslation();
		const teamplateOptions = useMemo<Option<number>[]>(() => {
			const options = templates.map((t) => ({
				key: t.id,
				value: t.id,
				label: t.name,
			}));
			options.unshift({
				key: TEMPLATE_MISSING,
				value: TEMPLATE_MISSING,
				label:
					t(
						"pages.mainPage.pages.accounts.tabs.executors.header.sendingPush.modal.template.str200",
					) ?? "",
			});
			return options;
		}, [templates, t]);

		const templateById = useMemo(
			() => mapByKey(templates, "id"),
			[templates],
		);

		const editor = useObjectEditor(value, onChange);

		const templateId = editor.useGetter("notificationTemplateId");
		const setTemplateId = editor.useSetter("notificationTemplateId");

		const text = editor.useGetter("text");
		const setText = editor.useSetter("text");
		const [customText, setCustomText] = useState("");

		const title = editor.useGetter("title");
		const setTitle = editor.useSetter("title");
		const [customTitle, setCustomTitle] = useState("");

		const handleTemplateIdChange = useCallback(
			(newTemplateId) => {
				setError((prev) => ({ ...prev, templateId: false }));
				setTemplateId(newTemplateId);
				if (newTemplateId !== TEMPLATE_MISSING) {
					setTitle(templateById[newTemplateId].title);
					setText(templateById[newTemplateId].templateText);
				} else {
					setTitle(customTitle);
					setText(customText);
				}
			},
			[
				customText,
				customTitle,
				setError,
				setTemplateId,
				setText,
				setTitle,
				templateById,
			],
		);

		useEffect(() => {
			setText(customText);
			if (customText) {
				setError((prev) => ({ ...prev, text: false }));
			}

			setTitle(customTitle);
			if (customTitle) {
				setError((prev) => ({ ...prev, title: false }));
			}
		}, [customText, customTitle, setError, setText, setTitle]);

		const bigPicture = editor.useGetter("bigPicture");
		const setBigPicture = editor.useSetter("bigPicture");

		const largeIcon = editor.useGetter("largeIcon");
		const setLargeIcon = editor.useSetter("largeIcon");

		const updateBigPictureHandler = useCallback(
			(file: File.Model | null) => {
				if (!file) {
					setBigPicture(null);
					return;
				}
				// eslint-disable-next-line no-param-reassign
				file.isPublicImage = true;
				file.upload();
				setBigPicture(file);
			},
			[setBigPicture],
		);

		const updateLargeIconHandler = useCallback(
			(file: File.Model | null) => {
				if (!file) {
					setLargeIcon(null);
					return;
				}
				// eslint-disable-next-line no-param-reassign
				file.isPublicImage = true;
				file.upload();
				setLargeIcon(file);
			},
			[setLargeIcon],
		);

		return (
			<Column gaps="15px*" style={{ flexGrow: 1 }}>
				<LabeledField
					label={
						t(
							"pages.mainPage.pages.accounts.tabs.executors.header.sendingPush.modal.template.str0",
						) ?? ""
					}
				>
					<Select
						error={error.templateId}
						placeholder={
							t(
								"pages.mainPage.pages.accounts.tabs.executors.header.sendingPush.modal.template.str1",
							) ?? ""
						}
						options={teamplateOptions}
						value={templateId ?? undefined}
						onChange={handleTemplateIdChange}
					/>
				</LabeledField>
				<LabeledField
					label={
						t(
							"pages.mainPage.pages.accounts.tabs.executors.header.sendingPush.modal.template.str2",
						) ?? ""
					}
				>
					<TextBox.TextBox
						error={error.title}
						disabled={templateId !== TEMPLATE_MISSING}
						placeholder={
							t(
								"pages.mainPage.pages.accounts.tabs.executors.header.sendingPush.modal.template.str3",
							) ?? ""
						}
						value={title}
						onChange={setCustomTitle}
					/>
				</LabeledField>
				<LabeledField
					label={
						t(
							"pages.mainPage.pages.accounts.tabs.executors.header.sendingPush.modal.template.str4",
						) ?? ""
					}
					style={{ flex: "1 1 auto" }}
				>
					<Row
						style={{ flex: "1 1 auto", position: "relative" }}
						sizes="auto 1fr"
					>
						<Count gaps="10px*">
							<Row gaps="5px*">
								<div>
									{t(
										"pages.mainPage.pages.accounts.tabs.executors.header.sendingPush.modal.template.str201",
									) ?? ""}
									:
								</div>
								<div>{text?.length}</div>
							</Row>
							<Row gaps="5px*">
								<div>
									{t(
										"pages.mainPage.pages.accounts.tabs.executors.header.sendingPush.modal.template.str202",
									) ?? ""}
									:
								</div>
								<div>{text?.split("\n").length}</div>
							</Row>
						</Count>
						<MultilineTextBox
							error={error.text}
							placeholder={
								t(
									"pages.mainPage.pages.accounts.tabs.executors.header.sendingPush.modal.template.str5",
								) ?? ""
							}
							disabled={templateId !== TEMPLATE_MISSING}
							value={text}
							onChange={setCustomText}
						/>
					</Row>
				</LabeledField>
				<div
					style={{
						display: "grid",
						gridTemplateColumns: "48% 48%",
						justifyContent: "space-between",
						height: 125.96,
					}}
				>
					<FileUpload
						disabled={templateId !== TEMPLATE_MISSING}
						value={bigPicture ?? null}
						label={
							t(
								"pages.mainPage.pages.accounts.tabs.executors.header.sendingPush.modal.template.str203",
							) ?? ""
						}
						onChange={updateBigPictureHandler}
					/>
					<FileUpload
						disabled={templateId !== TEMPLATE_MISSING}
						value={largeIcon ?? null}
						label={
							t(
								"pages.mainPage.pages.accounts.tabs.executors.header.sendingPush.modal.template.str204",
							) ?? ""
						}
						onChange={updateLargeIconHandler}
					/>
				</div>
			</Column>
		);
	},
);

declare namespace Template {
	interface Props {
		error: Error;
		setError: Dispatch<SetStateAction<Error>>;
		templates: PushNotice.Model[];
		value: Value;
		onChange: (x: Value) => void;
	}

	type Value = {
		text?: string;
		title?: string;
		bigPicture?: File.Model | null;
		largeIcon?: File.Model | null;
		notificationTemplateId?: number;
	} & (
		| {
				text: string;
				title: string;
				bigPicture: File.Model;
				largeIcon: File.Model;
		  }
		| { notificationTemplateId: number }
	);

	interface Error {
		templateId: boolean;
		text: boolean;
		title: boolean;
	}
}

export default Template;
