import React, { Dispatch, memo, useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Icon, Row, theme } from "uikit";

import formatNumber from "../../../../../../../../../../../../../../../../../../../../../../../../utils/formatNumber";
import { useCurrencyGlobalSettings } from "../../../../../../../../../../../../../../../../../../../../../../../../hooks";
import BalanceModal from "../../../../../../../../../../../../../../../../../../../../../../../../components/BalanceModal";
import { ITransaction } from "../../../../../../../../../../../../../../../../../../../../../../../../types/ITransaction";

import Label from "./components/Label";

const Transaction: React.FC<Transaction.Props> = memo((props) => {
	const { value = [], type, onChange, disabled = false } = props;

	const { t } = useTranslation();
	const currencyGlobalSettings = useCurrencyGlobalSettings();

	const [showModal, setShowModal] = useState(false);
	const [err, setErr] = useState(false);

	const formattedNumber = useMemo(() => {
		try {
			if (type === "threshold") {
				const item = value.at(-1);
				return formatNumber(item?.amount || 0);
			}
			return formatNumber(
				value.reduce((acc, item) => (acc += item.amount), 0),
			);
		} catch (error) {
			console.error(error);
			return "0";
		}
	}, [type, value]);

	const handleOpenModal = useCallback(() => {
		setShowModal(true);
	}, []);

	const handleCloseModal = useCallback(() => {
		setShowModal(false);
	}, []);

	const handleSubmit = useCallback(
		(data: ITransaction) => {
			if (!data.description) {
				setErr(true);
				return;
			}

			const toRequest: Transaction.Value = {
				amount: data.amount,
				description: data.description,
			};
			const checkValue = value || [];

			if (type === "threshold") {
				onChange([toRequest]);
				setShowModal(false);
				return;
			}

			onChange([...checkValue, toRequest]);
			setShowModal(false);
		},
		[onChange, value, type],
	);

	return (
		<>
			<Row align="center" gaps="4px 11.5px">
				<Icon id="wallet" size={16} colors={[theme.colors.success]} />
				<Label>
					{formattedNumber} {currencyGlobalSettings}
				</Label>
				<Button.Button
					disabled={disabled}
					text={
						t(
							"pages.mainPage.pages.customers.tabs.counterparties.modal.content.accounts.modal.content.tabs.main.account.transactions.transaction.str201",
						) ?? ""
					}
					style={{
						minHeight: "32px",
					}}
					onClick={handleOpenModal}
				/>
			</Row>
			{showModal && (
				<BalanceModal
					error={err}
					onClose={handleCloseModal}
					onSubmit={handleSubmit}
					defaultAmount={
						type === "threshold"
							? value[value.length - 1]?.amount
							: undefined
					}
					defaultDescription={
						type === "threshold"
							? value[value.length - 1]?.description
							: undefined
					}
				/>
			)}
		</>
	);
});

declare namespace Transaction {
	interface Props {
		value: Value[];
		type: string;
		onChange: Dispatch<Value[]>;
		disabled?: boolean;
	}

	type Value = { amount: number; description: string };
}

export default Transaction;
