import { react } from "uikit";

interface Context {}

export default class Controller extends react.ControllerBase<Context> {
	// eslint-disable-next-line class-methods-use-this
	validate() {
		return true;
	}
}
