import React, { useMemo } from "react";
import { Tabs } from "uikit";
import { useTranslation } from "react-i18next";

import { useTypedSelector } from "../../../../redux/store";
import useActiveTab from "../../../../hooks/useActiveTab";
import BasicPageLayout from "../../../../components/BasicPageLayout";
import { hasAccess } from "../../../../access";
import { TabOptions } from "../../../../types";

import { Customers, Executors, Transport } from "./tabs";
import { TabKeys, TabAccessNames } from "./constants/access";

const Blacklist: React.FC = () => {
	const { t } = useTranslation();

	const personalRole = useTypedSelector(
		(state) => state.account.personalRole,
	);
	const tabs: TabOptions.Array = useMemo(
		() =>
			[
				{
					key: TabKeys.BLACKLIST_CUSTOMERS,
					label: t("mainPage.blacklist.customers.title"),
					value: {
						render: () => <Customers />,
					},
					accessName: TabAccessNames[TabKeys.BLACKLIST_CUSTOMERS],
				},
				{
					key: TabKeys.BLACKLIST_EXECUTORS,
					label: t("mainPage.blacklist.executors.title"),
					value: {
						render: () => <Executors />,
					},
					accessName: TabAccessNames[TabKeys.BLACKLIST_EXECUTORS],
				},
				{
					key: TabKeys.BLACKLIST_TRANSPORT,
					label: t("mainPage.blacklist.transport.title"),
					value: {
						render: () => <Transport />,
					},
					accessName: TabAccessNames[TabKeys.BLACKLIST_TRANSPORT],
				},
			].filter((btn) => hasAccess(btn.accessName, personalRole)),
		[t, personalRole],
	);

	const { activeKey, activeTab, setActiveKey } = useActiveTab(tabs);

	return (
		<BasicPageLayout
			navigation={
				<Tabs
					value={activeKey}
					onChange={setActiveKey}
					options={tabs}
					variant="vertical"
				/>
			}
			content={activeTab.value.render()}
		/>
	);
};

export default Blacklist;
