import { react } from "uikit";
import { RefObject } from "react";
import BalanceSelect from "./components/BalanceSelect";

interface Context {
	balanceSelectRef: RefObject<BalanceSelect.Ref | null>;
}

export default class Controller extends react.ControllerBase<Context> {
	validate() {
		const isBalanceSelectValid =
			this.context?.balanceSelectRef.current?.validate();

		return isBalanceSelectValid;
	}
}
