import { Dispatch, RefObject } from "react";
import { react } from "uikit";
import { isNil } from "lodash";
import WorkData from ".";
import RoleIds from "./components/RoleIds";
import ResponsibleDispatcherId from "./components/ResponsibleDispatcherId";
import Alias from "./components/Alias";

interface Context {
	value: WorkData.Value;

	setTaxiServiceIdError: Dispatch<boolean>;

	aliasRef: RefObject<Alias.Ref | null>;
	roleIdsRef: RefObject<RoleIds.Ref | null>;
	responsibleDispatcherIdRef: RefObject<ResponsibleDispatcherId.Ref | null>;
}

export default class Controller extends react.ControllerBase<Context> {
	async validate() {
		const isResponsibleDispatcherIdValid =
			!!this.context?.responsibleDispatcherIdRef.current?.validate();
		const isRoleIdValid = !!this.context?.roleIdsRef.current?.validate();
		const isTaxiServiceIdValid = !isNil(this.context?.value.taxiServiceId);
		const isAliasValid =
			!!(await this.context?.aliasRef.current?.validate());

		if (!isTaxiServiceIdValid) this.context?.setTaxiServiceIdError(true);

		return (
			isResponsibleDispatcherIdValid &&
			isRoleIdValid &&
			isTaxiServiceIdValid &&
			isAliasValid
		);
	}
}
