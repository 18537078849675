/* eslint-disable prettier/prettier */
import React, { Dispatch, memo, useCallback, useMemo } from "react";
import { CheckBox, Column, Row, Select, ToggleButton } from "uikit";
import { useTranslation } from "react-i18next";
import FieldsContainer from "../../../../../../../../../../components/FieldsContainer";
import ImprovedStepper from "../../../../../../../../../../components/ImprovedStepper";
import useObjectEditor from "../../../../../../../../../../hooks/useObjectEditor";
import { CounterpartyPeriods, options } from "./constants";
import tPath from "../../../../constants/tPath";

const TripLimit: React.FC<TripLimit.Props> = memo(({ value, onChange }) => {
	const { t } = useTranslation();
	const tOptions = useMemo(
		() =>
			options.map((option) => ({
				...option,
				label: t(`tripLimit.${option.key}`),
			})),
		[t],
	);

	const valueEditor = useObjectEditor(value, onChange);

	const active = valueEditor.useGetter("active");
	const setActive = valueEditor.useSetter("active");

	const amountByPeriod = valueEditor.useGetter("amountByPeriod");
	const setAmountByPeriod = valueEditor.useSetter("amountByPeriod");

	const amountByTrip = valueEditor.useGetter("amountByTrip");
	const setAmountByTrip = valueEditor.useSetter("amountByTrip");

	const countByPeriod = valueEditor.useGetter("countByPeriod");
	const setCountByPeriod = valueEditor.useSetter("countByPeriod");

	const distanceByPeriod = valueEditor.useGetter("distanceByPeriod");
	const setDistanceByPeriod = valueEditor.useSetter("distanceByPeriod");

	const availableChangeOrder = valueEditor.useGetter("availableChangeOrder");
	const setAvailableChangeOrder = valueEditor.useSetter(
		"availableChangeOrder",
	);
	const handleAvailableChangeOrder = useCallback(
		(newAvailable) => {
			setAvailableChangeOrder({ active: newAvailable });
		},
		[setAvailableChangeOrder],
	);

	/// ///////////////////////////////////////////////////////////////////////////////////////////
	const amountByPeriodEditor = useObjectEditor(
		amountByPeriod ?? { active: false, amount: 0, customPeriod: 0 },
		setAmountByPeriod,
	);
	const amountByTripEditor = useObjectEditor(
		amountByTrip ?? { active: false, maxAmountOfTrip: 0 },
		setAmountByTrip,
	);
	const countByPeriodEditor = useObjectEditor(
		countByPeriod ?? { active: false, customPeriod: 0, numberOfTrips: 0 },
		setCountByPeriod,
	);
	const distanceByPeriodEditor = useObjectEditor(
		distanceByPeriod ?? { active: false, distance: 0, customPeriod: 0 },
		setDistanceByPeriod,
	);
	/// ///////////////////////////////////////////////////////////////////////////////////////////

	const amountByPeriodActive = amountByPeriodEditor.useGetter("active");
	const setAmountByPeriodActive = amountByPeriodEditor.useSetter("active");
	const amountByPeriodAmount = amountByPeriodEditor.useGetter("amount");
	const setAmountByPeriodAmount = amountByPeriodEditor.useSetter("amount");
	const amountByPeriodPeriod = amountByPeriodEditor.useGetter("period");
	const setAmountByPeriodPeriod = amountByPeriodEditor.useSetter("period");
	const amountByPeriodCustomPeriod =
		amountByPeriodEditor.useGetter("customPeriod");
	const setAmountByPeriodCustomPeriod =
		amountByPeriodEditor.useSetter("customPeriod");

	const amountByTripActive = amountByTripEditor.useGetter("active");
	const setAmountByTripActive = amountByTripEditor.useSetter("active");
	const amountByTripMaxAmountOfTrip =
		amountByTripEditor.useGetter("maxAmountOfTrip");
	const setAmountByTripMaxAmountOfTrip =
		amountByTripEditor.useSetter("maxAmountOfTrip");

	const countByPeriodActive = countByPeriodEditor.useGetter("active");
	const setCountByPeriodActive = countByPeriodEditor.useSetter("active");
	const countByPeriodNumberOfTrips =
		countByPeriodEditor.useGetter("numberOfTrips");
	const setCountByPeriodNumberOfTrips =
		countByPeriodEditor.useSetter("numberOfTrips");
	const countByPeriodPeriod = countByPeriodEditor.useGetter("period");
	const setCountByPeriodPeriod = countByPeriodEditor.useSetter("period");
	const countByPeriodCustomPeriod =
		countByPeriodEditor.useGetter("customPeriod");
	const setCountByPeriodCustomPeriod =
		countByPeriodEditor.useSetter("customPeriod");

	const distanceByPeriodActive = distanceByPeriodEditor.useGetter("active");
	const setDistanceByPeriodActive =
		distanceByPeriodEditor.useSetter("active");
	const distanceByPeriodDistance =
		distanceByPeriodEditor.useGetter("distance");
	const setDistanceByPeriodDistance =
		distanceByPeriodEditor.useSetter("distance");
	const distanceByPeriodPeriod = distanceByPeriodEditor.useGetter("period");
	const setDistanceByPeriodPeriod =
		distanceByPeriodEditor.useSetter("period");
	const distanceByPeriodCustomPeriod =
		distanceByPeriodEditor.useGetter("customPeriod");
	const setDistanceByPeriodCustomPeriod =
		distanceByPeriodEditor.useSetter("customPeriod");

	const amountByPeriodPeriodSelectHandler = useCallback(
		(val) => {
			setAmountByPeriodPeriod(val);
		},
		[setAmountByPeriodPeriod],
	);
	const countByPeriodPeriodSelectHandler = useCallback(
		(val) => {
			setCountByPeriodPeriod(val);
		},
		[setCountByPeriodPeriod],
	);
	const distanceByPeriodPeriodSelectHandler = useCallback(
		(val) => {
			setDistanceByPeriodPeriod(val);
		},
		[setDistanceByPeriodPeriod],
	);

	return (
		<FieldsContainer
			gaps="8px*"
			label={t(`${tPath}.components.tripLimit.title`) || ""}
			additionalFields={
				<ToggleButton.ToggleButton
					// TODO: is not work
					disabled
					value={active}
					onChange={setActive}
				/>
			}
			style={{ paddingTop: 18 }}
		>
			<Row gaps="10px*" sizes="1fr auto">
				<Column gaps="8px*">
					<Row
						gaps="8px*"
						align="center"
						sizes="auto 100px 120px 160px auto auto"
					>
						<div style={{ width: 153.8 }}>
							<CheckBox
								disabled={!active}
								label={
									t(
										`${tPath}.components.tripLimit.orderAmount`,
									) || ""
								}
								value={amountByPeriodActive}
								onChange={setAmountByPeriodActive}
							/>
						</div>
						<ImprovedStepper
							disabled={!active || !amountByPeriodActive}
							width="100px"
							value={amountByPeriodAmount}
							onChange={setAmountByPeriodAmount}
						/>
						<div>
							{t(`${tPath}.components.tripLimit.value`)}{" "}
							{t(`${tPath}.components.tripLimit.forThePeriod`)}
						</div>
						<Select
							disabled={!active || !amountByPeriodActive}
							value={amountByPeriodPeriod}
							onChange={amountByPeriodPeriodSelectHandler}
							placeholder={
								t(
									`${tPath}.components.tripLimit.choosePeriod`,
								) || ""
							}
							options={tOptions}
						/>
						{amountByPeriodPeriod ===
							CounterpartyPeriods.CUSTOM && (
							<>
								<ImprovedStepper
									disabled={!active || !amountByPeriodActive}
									value={amountByPeriodCustomPeriod}
									onChange={setAmountByPeriodCustomPeriod}
								/>
								<div>
									{t(`${tPath}.components.tripLimit.days`)}
								</div>
							</>
						)}
					</Row>
					<Row
						gaps="8px*"
						align="center"
						sizes="auto 100px 120px 160px auto auto"
					>
						<div style={{ width: 153.8 }}>
							<CheckBox
								label={
									t(
										`${tPath}.components.tripLimit.tripsCount`,
									) || ""
								}
								value={countByPeriodActive}
								onChange={setCountByPeriodActive}
								disabled={!active}
							/>
						</div>
						<ImprovedStepper
							disabled={!active || !countByPeriodActive}
							step={1}
							value={countByPeriodNumberOfTrips}
							onChange={setCountByPeriodNumberOfTrips}
						/>
						<div>
							{t(`${tPath}.components.tripLimit.forThePeriod`)}
						</div>
						<Select
							disabled={!active || !countByPeriodActive}
							value={countByPeriodPeriod}
							onChange={countByPeriodPeriodSelectHandler}
							placeholder={
								t(
									`${tPath}.components.tripLimit.choosePeriod`,
								) || ""
							}
							options={tOptions}
						/>
						{countByPeriodPeriod === CounterpartyPeriods.CUSTOM && (
							<>
								<ImprovedStepper
									disabled={!active || !countByPeriodActive}
									value={countByPeriodCustomPeriod}
									onChange={setCountByPeriodCustomPeriod}
								/>
								<div>
									{t(`${tPath}.components.tripLimit.days`)}
								</div>
							</>
						)}
					</Row>
					<Row
						gaps="8px*"
						align="center"
						sizes="auto 100px 120px 160px auto auto"
					>
						<div style={{ width: 153.8 }}>
							<CheckBox
								label={
									t(
										`${tPath}.components.tripLimit.kilometerage`,
									) || ""
								}
								value={distanceByPeriodActive}
								onChange={setDistanceByPeriodActive}
								disabled={!active}
							/>
						</div>
						<ImprovedStepper
							disabled={!active || !distanceByPeriodActive}
							value={distanceByPeriodDistance}
							onChange={setDistanceByPeriodDistance}
						/>
						<div>
							{t(`units.km`) ?? "km"}.{" "}
							{t(`${tPath}.components.tripLimit.forThePeriod`)}
						</div>
						<Select
							disabled={!active || !distanceByPeriodActive}
							value={distanceByPeriodPeriod}
							onChange={distanceByPeriodPeriodSelectHandler}
							placeholder={
								t(
									`${tPath}.components.tripLimit.choosePeriod`,
								) || ""
							}
							options={tOptions}
						/>
						{distanceByPeriodPeriod ===
							CounterpartyPeriods.CUSTOM && (
							<>
								<ImprovedStepper
									disabled={
										!active || !distanceByPeriodActive
									}
									value={distanceByPeriodCustomPeriod}
									onChange={setDistanceByPeriodCustomPeriod}
								/>
								<div>
									{t(`${tPath}.components.tripLimit.days`)}
								</div>
							</>
						)}
					</Row>
				</Column>
				<Column gaps="8px*">
					<Row gaps="8px*" align="center">
						<CheckBox
							disabled={!active}
							label={
								t(
									`${tPath}.components.tripLimit.tripCostLimit`,
								) || ""
							}
							value={amountByTripActive}
							onChange={setAmountByTripActive}
						/>
						<ImprovedStepper
							disabled={!active || !amountByTripActive}
							width="100px"
							value={amountByTripMaxAmountOfTrip}
							onChange={setAmountByTripMaxAmountOfTrip}
						/>
						<span>{t(`${tPath}.components.tripLimit.value`)}</span>
					</Row>
					{availableChangeOrder && (
						<CheckBox
							disabled={!active}
							label={
								t(
									`${tPath}.components.tripLimit.availableChangeOrder`,
								) || ""
							}
							value={availableChangeOrder?.active}
							onChange={handleAvailableChangeOrder}
						/>
					)}
				</Column>
			</Row>
		</FieldsContainer>
	);
});

declare namespace TripLimit {
	interface Props {
		value: Value;
		onChange: Dispatch<Value>;
	}

	interface Value {
		active: boolean;
		amountByPeriod?: {
			active: boolean;
			amount: number;
			period?: CounterpartyPeriods;
			customPeriod: number;
		};
		amountByTrip?: {
			active: boolean;
			maxAmountOfTrip: number;
		};
		countByPeriod?: {
			active: boolean;
			numberOfTrips: number;
			period?: CounterpartyPeriods;
			customPeriod: number;
		};
		distanceByPeriod?: {
			active: boolean;
			distance: number;
			period?: CounterpartyPeriods;
			customPeriod: number;
		};
		availableChangeOrder?: { active: boolean };
	}
}

export default TripLimit;
