/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable no-shadow */

import React, { useCallback, useMemo, useState } from "react";
import { isEqual } from "lodash";

import DeleteModal from "../../../../../../components/DeleteModal";
import { useTypedSelector } from "../../../../../../redux/store";
import useModelSubscribe from "../../../../../../hooks/useModelSubscribe2";
import Service from "../../../../../../services/CarPark";
import mapByKey from "../../../../../../utils/mapByKey";
import useTaxiServiceIdsDecoder from "../../../../../../hooks/useTaxiServiceIdsDecoder";
import useGetCompanyIdsByTaxiServiceIds from "../../../../../../hooks/useGetCompanyIdsByTaxiServiceIds";
import { useSort } from "../../../../../../hooks/useTableSettings";

import Content from "./components/Content";
import Header from "./components/Header";
import EditModal from "./components/EditModal";
import Root from "./components/Root";

const CarParks: React.FC = () => {
	const settingsLanguage = useTypedSelector(
		(state) => state.session.language,
	);

	const getCompanyIdsByTaxiServiceIds = useGetCompanyIdsByTaxiServiceIds();
	const decodeTaxiServiceIds = useTaxiServiceIdsDecoder();

	const [headerFilters, setHeaderFilters] = useState<Header.Filters.Value>({
		search: "",
	});

	const { sortMethod: sort, setSortMethod: setSort } = useSort(
		"accounts.vehicleParks",
		"vehiclePark",
	);

	const modelSubscriptionOptions = useMemo<Service.SubscribeOptions>(() => {
		const result: Service.SubscribeOptions = {
			query: headerFilters.search,
			language: settingsLanguage,
		};

		if (sort.column && sort.type)
			result.order = {
				[sort.column as keyof Service.SubscribeOptions["order"]]:
					sort.type,
			};

		return result;
	}, [settingsLanguage, headerFilters.search, sort.column, sort.type]);

	const modelData = useModelSubscribe(modelSubscriptionOptions, Service);

	const modelItems = useMemo(() => modelData.models, [modelData.models]);

	const modelItemById = useMemo(
		() => mapByKey(modelItems, "id"),
		[modelItems],
	);

	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const [editingItem, setEditingItem] = useState<EditModal.Value | null>(
		null,
	);
	const [selected, setSelected] = useState<number[]>([]);

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const edit = useCallback(
		(id: number) => {
			const item = modelItemById[id];

			const taxiServiceIds = item.taxiServices?.map(
				(taxiService) => taxiService.id,
			);

			const companyIds: number[] | undefined = taxiServiceIds
				? getCompanyIdsByTaxiServiceIds(taxiServiceIds)
				: undefined;

			const executorIds = item.executors?.map((executor) => executor.id);

			const dispatcherIds = item.dispatchers?.map(
				(dispatcher) => dispatcher.id,
			);

			const carIds = item.cars?.map((car) => car.id);

			setEditingItem({
				id: item.id,

				companyIds: companyIds ?? [],
				taxiServiceIds: taxiServiceIds ?? [],
				executorIds: executorIds ?? [],
				dispatcherIds: dispatcherIds ?? [],
				carIds: carIds ?? [],

				name: item.name,
				description: item.description,

				active: item.active,

				createdAt: item.createdAt ?? undefined,
			});
		},
		[getCompanyIdsByTaxiServiceIds, modelItemById],
	);

	const headerCanEdit = useMemo(
		() => selected.length === 1,
		[selected.length],
	);

	const headerCanDelete = useMemo(
		() => selected.length !== 0,
		[selected.length],
	);

	const headerOnAdd = useCallback(() => {
		setEditingItem({
			companyIds: [],
			taxiServiceIds: [],
			dispatcherIds: [],
			executorIds: [],
			carIds: [],

			name: "",
			description: "",

			active: true,
		});
	}, []);

	const headerOnEdit = useCallback(() => {
		edit(selected[0]);
	}, [edit, selected]);

	const headerOnDelete = useCallback(() => {
		setShowDeleteModal(true);
	}, []);

	const contentOnEdit = useCallback((item) => edit(item.id), [edit]);

	const contentOnLoadMore = useCallback(() => {}, []);

	const content = useMemo(
		() => (
			<>
				<Header
					filters={headerFilters}
					canEdit={headerCanEdit}
					canDelete={headerCanDelete}
					onChangeFilters={setHeaderFilters}
					onAdd={headerOnAdd}
					onEdit={headerOnEdit}
					onDelete={headerOnDelete}
				/>
				<Content
					selected={selected}
					sort={sort}
					loading={modelData.loading}
					data={modelItems}
					language={settingsLanguage}
					onChangeSelected={setSelected}
					onChangeSort={setSort}
					onEdit={contentOnEdit}
					onLoadMore={contentOnLoadMore}
				/>
			</>
		),
		[
			contentOnEdit,
			contentOnLoadMore,
			headerCanDelete,
			headerCanEdit,
			headerFilters,
			headerOnAdd,
			headerOnDelete,
			headerOnEdit,
			modelData.loading,
			modelItems,
			selected,
			setSort,
			settingsLanguage,
			sort,
		],
	);

	const editModalOnCancel = useCallback(() => {
		setEditingItem(null);
	}, []);

	const editModalOnSave = useCallback(
		async (newItem: EditModal.Value) => {
			if (isEqual(editingItem, newItem)) {
				setEditingItem(null);

				return;
			}

			const newProperties: Service.Model.New = {
				taxiServiceIds: decodeTaxiServiceIds(
					newItem.companyIds,
					newItem.taxiServiceIds,
				),
				dispatcherIds: newItem.dispatcherIds,
				executorIds: newItem.executorIds,
				carIds: newItem.carIds,

				name: newItem.name,
				description: newItem.description,

				active: newItem.active,
			};

			if (typeof newItem.id === "number")
				await Service.update({
					id: newItem.id,

					...newProperties,
				});
			else await Service.store(newProperties);

			setEditingItem(null);
		},
		[decodeTaxiServiceIds, editingItem],
	);

	const deleteModalOnCancel = useCallback(() => {
		setShowDeleteModal(false);
	}, []);

	const deleteModalOnConfirm = useCallback(async () => {
		setShowDeleteModal(false);

		Service.destroy(selected);

		setSelected([]);
	}, [selected]);

	return (
		<Root sizes="auto! 1fr" gaps="16px" maxedWidth maxedHeight>
			{content}
			{editingItem && (
				<EditModal
					value={editingItem}
					language={settingsLanguage}
					onCancel={editModalOnCancel}
					onSave={editModalOnSave}
				/>
			)}
			{showDeleteModal && (
				<DeleteModal
					onCancel={deleteModalOnCancel}
					onConfirm={deleteModalOnConfirm}
				/>
			)}
		</Root>
	);
};

export default CarParks;
