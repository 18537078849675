/* eslint-disable no-shadow */

import React, { Dispatch, Key, memo, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Icon, InputGroup, MultiSelect, Row, theme } from "uikit";

import { getStatuses } from "../../../../../../../../../../utils";
import SearchIconBorders from "../../../../../../../../../../components/SearchIconBorders";
import SearchTextBox from "../../../../../../../../../../components/SearchTextBox";

const Filters = memo<Filters.Props>(({ value, onChange }) => {
	const { t } = useTranslation();

	const statuses = useMemo(() => getStatuses(t), [t]);

	const searchTextBoxOnChange = useCallback(
		(search: string) => {
			onChange?.({
				...value,

				search,
			});
		},
		[onChange, value],
	);

	const statusesOnChange = useCallback(
		(statuses: Key[]) => {
			onChange?.({
				...value,

				statuses: statuses as Filters.Value["statuses"],
			});
		},
		[onChange, value],
	);

	return (
		<Row sizes="200px! 270px!" gaps="12px 10px*" align="center">
			<MultiSelect
				all
				options={statuses}
				value={value.statuses}
				onChange={statusesOnChange}
			/>
			<InputGroup.InputGroup sizes="auto 1fr">
				<SearchIconBorders>
					<Icon
						id="search2"
						size={16}
						colors={[theme.colors.disabled_text]}
					/>
				</SearchIconBorders>
				<SearchTextBox
					placeholder={`${
						t(
							"pages.mainPage.pages.accounts.tabs.users.header.filters.str200",
						) ?? ""
					}...`}
					value={value.search}
					onChange={searchTextBoxOnChange}
				/>
			</InputGroup.InputGroup>
		</Row>
	);
});

declare namespace Filters {
	interface Value {
		statuses: ("active" | "blocked" | "dismissed")[];
		search: string;
	}

	interface Props {
		value: Value;

		onChange: Dispatch<Value>;
	}
}

export default Filters;
