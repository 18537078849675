import styled from "styled-components";

const Button = styled.div<Button.Props>`
	cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
`;

declare namespace Button {
	interface Props {
		disabled: boolean;
	}
}

export default Button;
