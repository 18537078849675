import React, { memo } from "react";
import { CheckBox, Row } from "uikit";
import { useTranslation } from "react-i18next";

import useObjectEditor from "../../../../../../../../../../hooks/useObjectEditor";
import tPath from "../../../../constants/tPath";

const Footer: React.FC<Footer.Props> = memo(({ value, onChange }) => {
	const { t } = useTranslation();

	const valueEditor = useObjectEditor(value, onChange);

	const active = valueEditor.useGetter("active");
	const setActive = valueEditor.useSetter("active");

	return (
		<Row align="center" gaps="25px*">
			<CheckBox
				label={t(`${tPath}.modal.active`) || "Active"}
				value={active}
				onChange={setActive}
			/>
		</Row>
	);
});

declare namespace Footer {
	interface Props {
		value: Value;
		onChange: (value: Value) => void;
	}

	interface Value {
		active: boolean;
	}
}

export default Footer;
