import Employee from "../../../../../../../../../../services/Employee";

export default function prepareEmployee(c): Employee.New {
	const phones =
		c.person?.phones?.map?.((item) => ({
			value: item?.number,
			group: item.group,
			isCounterparty: false,
			id: item?.id,
		})) || [];

	return {
		id: crypto.randomUUID(),
		active: true,
		otherFiles: c.otherFiles || [],
		personalFiles: c.personalFiles || [],
		phones,
		isAccessToCorporateAccount: c.isAccessToCorporateAccount,
		isAccessToCorporateApp: c.isAccessToCorporateApp,
		login: c.login,
		password: c.password,
		isOneTimePassword: false,
		oneTimePassword: "",
		isCode: false,
		code: {
			value: "",
			keyword: "",
		},
		additionalFields: {
			addresses: [],
			tripLimit: {
				active: false,
				amountByPeriod: {
					active: false,
					amount: 0,
					customPeriod: 0,
				},
				amountByTrip: {
					active: false,
					maxAmountOfTrip: 0,
				},
				countByPeriod: {
					active: false,
					customPeriod: 0,
					numberOfTrips: 0,
				},
				distanceByPeriod: {
					active: false,
					customPeriod: 0,
					distance: 0,
				},
			},
		},
		customerId: c.id,
		customer: {
			id: c.id,
			emails: c.person?.emails || [],
			birthday: c.person?.birthday
				? new Date(c.person.birthday)
				: undefined,
			gender: c.gender,
			name: c.person.firstName,
			surname: c.person.lastName,
			fatherName: c.person.fatherName,
		},
	};
}
