import Map from "../../../../../../../../../../../../../../../../../../services/Map";

function objectToString(type: Map.Search.Type, object: objectToString.Object) {
	if (!object || !type) return "";

	return `${object.settlementType || ""} ${object.settlement || ""}, ${
		object.streetType || ""
	} ${object.street || ""}${object.number ? `, ${object.number}` : ""}`;
}

declare namespace objectToString {
	interface Object {
		streetType?: string;
		street?: string;

		settlement?: string;
		settlementType?: string;
		district?: string;
		region?: string;

		name?: string | Record<string, string>;
		number?: string;
	}
}

export default objectToString;
