import React, { useCallback } from "react";
import { useInternal } from "uikit";

import File from "../../services/File";
import FileList from "../FileList";
import InputModal from "../InputModal";

import Root from "./components/Root";

const FileListModal: React.FC<FileListModal.Props> = ({
	value,

	disabled,
	draggable,
	title,

	onClose,
	onSubmit,
}) => {
	const [internalValue, setInternalValue] = useInternal(value);

	const inputModalOnSubmit = useCallback(() => {
		onSubmit?.(internalValue ?? []);
	}, [internalValue, onSubmit]);

	const fileListOnChange = useCallback(
		(files: File.Model[]) => {
			files.forEach((file) => file.upload());

			setInternalValue(files);
		},
		[setInternalValue],
	);

	return (
		<InputModal
			draggable={draggable}
			title={title}
			onClose={onClose}
			onSubmit={inputModalOnSubmit}
		>
			<Root sizes="250px!">
				<FileList
					value={internalValue}
					disabled={disabled}
					onChange={fileListOnChange}
				/>
			</Root>
		</InputModal>
	);
};

declare namespace FileListModal {
	type Value = FileList.Value;

	interface Props
		extends Omit<FileList.Props, "onChange">,
			Omit<InputModal.Props, "onSubmit"> {
		onSubmit?: (value: Value) => void;
	}
}

export default FileListModal;
