import React, {
	Dispatch,
	useCallback,
	useEffect,
	useLayoutEffect,
	useMemo,
} from "react";
import { CheckBox, Row } from "uikit";
import { useTranslation } from "react-i18next";
import { RowDataType } from "rsuite-table";

import Agent from "../../../../../../../../../../../../../../../../services/Agent";

import {
	useColumns,
	useVisibleColumns,
} from "../../../../../../../../../../../../../../../../hooks/useTableSettings";
import LightTable, {
	useTableOptions,
	UseTableOptions,
} from "../../../../../../../../../../../../../../../../components/LightTable";
import TableSettings from "../../../../../../../../../../../../../../../../components/TableSettings";
import getAgentCellData from "../../../../../../../../../../../../../../../../constants/tables/agent/getAgentCellData";

import Root from "./components/Root";
import Filters from "./components/Filters";
import { Tables } from "../../../../../../../../../../../../../../../../constants/tables/types";

const Table: React.FC<Table.Props> = ({
	onChangeTable,
	editorTable,
	value,
	selected,
	setSelected,
	onEdit,
	showFilter,
	localStorageKey = "customers.agentGroup.modal",
	columnsNameKey = "agentGroupModal",
}) => {
	const { t } = useTranslation();
	const {
		sort,
		onSort,
		onScroll,
		tableRef,
		onKeyUp,
		onKeyDown,
		ctrlPressed,
		setDataLength,
		query,
		setQuery,
		lang,
	} = useTableOptions({
		value: editorTable,
		setValue: onChangeTable,
		heightRow: 44,
	});

	useLayoutEffect(() => {
		setDataLength(value.length);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [value.length]);

	const columns = useColumns(columnsNameKey);
	const { columnIds, setColumnIds } = useVisibleColumns(
		localStorageKey,
		columnsNameKey,
	);

	const updateSelected = useCallback(
		(item: RowDataType<Agent.Model>, force = false) => {
			const model = item as Agent.Model;
			if (ctrlPressed || force) {
				if (selected.includes(model.id)) {
					setSelected(selected.filter((id) => model.id !== id));
				} else {
					setSelected([...selected, model.id]);
				}
			} else {
				selected.includes(model.id)
					? setSelected([])
					: setSelected([model.id]);
			}
		},
		[ctrlPressed, selected, setSelected],
	);

	const data = useMemo(
		() =>
			columnIds.map((columnId) => {
				const column = columns.find((col) => col.id === columnId);
				if (!column) return null;
				return (
					<LightTable.Column
						key={columnId}
						verticalAlign="middle"
						width={200}
						resizable
						sortable
					>
						<LightTable.HeaderCell style={{ padding: "0 20px" }}>
							{column.label}
						</LightTable.HeaderCell>
						<LightTable.Cell
							verticalAlign="middle"
							style={{ padding: "0 20px" }}
							dataKey={column.id}
							fullText
						>
							{(rowData: RowDataType<Agent.Model>) =>
								getAgentCellData(rowData, columnId, lang, t)
							}
						</LightTable.Cell>
					</LightTable.Column>
				);
			}),
		[columnIds, columns, lang, t],
	);

	const getClassName = useCallback(
		(rowData: RowDataType<Agent.Model>) =>
			rowData && selected.includes(rowData.id) ? "selected" : "",
		[selected],
	);

	const handleOnChangeSelected = useCallback(
		(isAllSelected: boolean) => {
			if (isAllSelected) {
				setSelected(value.map(({ id }) => id));
			} else {
				setSelected([]);
			}
		},
		[setSelected, value],
	);

	return (
		<>
			{showFilter && (
				<Row
					align="center"
					sizes="270px"
					justify="flex-end"
					style={{ padding: 10 }}
				>
					<Filters value={query} onChange={setQuery} />
				</Row>
			)}

			<Root sizes="1fr auto!" maxedWidth maxedHeight>
				<div tabIndex={-1} onKeyDown={onKeyDown} onKeyUp={onKeyUp}>
					<LightTable
						ref={tableRef}
						data={value}
						virtualized
						fillHeight
						headerHeight={44}
						rowHeight={44}
						shouldUpdateScroll={false}
						rowClassName={getClassName}
						onRowClick={updateSelected}
						onRowDoubleClick={onEdit}
						sortColumn={sort?.dataKey}
						sortType={sort?.sortType}
						onSortColumn={onSort}
						onScroll={onScroll}
					>
						<LightTable.Column verticalAlign="middle" width={50}>
							<LightTable.HeaderCell
								style={{ padding: "0 25px" }}
							>
								<CheckBox
									value={
										selected.length === value.length &&
										value.length !== 0
									}
									onChange={handleOnChangeSelected}
								/>
							</LightTable.HeaderCell>
							<LightTable.Cell
								verticalAlign="middle"
								style={{ padding: "0 25px" }}
							>
								{(agent) => (
									<CheckBox
										value={selected.includes(agent.id)}
										onChange={() => {
											updateSelected(agent, true);
										}}
									/>
								)}
							</LightTable.Cell>
						</LightTable.Column>
						{data}
					</LightTable>
				</div>
				<TableSettings
					value={columnIds}
					defaultValue={columnIds}
					columns={columns}
					onChange={setColumnIds}
				/>
			</Root>
		</>
	);
};

declare namespace Table {
	interface Props {
		value: Agent.Model[];
		localStorageKey?: string;
		columnsNameKey?: keyof Tables;

		selected: number[];
		setSelected: Dispatch<number[]>;
		onEdit?: Dispatch<Agent.Model>;
		editorTable: UseTableOptions["editor"];
		onChangeTable: UseTableOptions["onChange"];
		showFilter?: boolean;
	}

	type ColumnType = {
		id: string;
		label: string;
		width?: number;
		justify?: "center";
	};
}

export default Table;
