import React, {
	Dispatch,
	RefAttributes,
	memo,
	useCallback,
	useMemo,
	useState,
} from "react";
import { Column, DatePicker, Row, Select, TextBox, react } from "uikit";
import { useTranslation } from "react-i18next";
import { compact, union } from "lodash";
import { v4 as uuid } from "uuid";

import Counterparty from "../../../../../../../../../../../../../../../services/Counterparty";
import useModelSubscribe from "../../../../../../../../../../../../../../../hooks/useModelSubscribe2";
import useObjectEditor from "../../../../../../../../../../../../../../../hooks/useObjectEditor";
import useDatePickerLocale from "../../../../../../../../../../../../../../../hooks/useDatePickerLocale";
import getOptions from "../../../../../../../../../../../../../../../utils/generateOptions";
import { validateEmail } from "../../../../../../../../../../../../../../../utils/validateEmail";
import {
	countryCodePhone,
	validatePhone,
} from "../../../../../../../../../../../../../../../utils/validatePhone";
import { ModelId } from "../../../../../../../../../../../../../../../types/ModelId";
import FieldsContainer from "../../../../../../../../../../../../../../../components/FieldsContainer";
import LabeledField from "../../../../../../../../../../../../../../../components/LabeledField";
// import {
// 	Divider,
// 	StyledColumn,
// 	StyledP,
// 	StyledRow,
// } from "../../../../../../../../../../../../../../../components/common";
import {
	useAutodetectPhones,
	AutodetectPhone,
} from "../../../../../../../../../../../hooks";
import AutodetectPhoneErrorModal from "../../../../../../../../../../../components/AutodetectPhoneErrorModal";
import Accounts from "../../../../../../../../Modal/components/Content/tabs/Accounts";
import TripLimit from "../../../../../../../../Modal/components/TripLimit";
import tPath from "../../../../../../constants/tPath";
import TabRoot from "../../components/TabRoot";

import InternalController from "./Controller";
import Code from "./components/Code";
import Phones from "./components/Phones";
import Addresses from "./components/Addresses";
import Permissions from "./components/Permissions";

export enum GenderOptions {
	MALE = "male",
	FEMALE = "female",
	UNSPECIFIED = "unknown",
}
const genderOptions = getOptions([
	GenderOptions.MALE,
	GenderOptions.FEMALE,
	GenderOptions.UNSPECIFIED,
]);

const MainBase: React.FC<Main.Props> = react.withController<
	Main.PropsBase,
	Main.Controller
>(({ value, onChange, controller, counterpartyId, employeeId, checks }) => {
	const { autodetectPhones } = useAutodetectPhones();
	const { t } = useTranslation();
	const locale = useDatePickerLocale();
	const { models } = useModelSubscribe({}, Counterparty);

	const [showErrorModal, setShowErrorModal] = useState<boolean>(false);
	const [errorData, setErrorData] = useState<AutodetectPhone[]>([]);

	const [indexPhones, setIndexPhones] = useState<number[]>([]);

	const editor = useObjectEditor(value, onChange);

	const isCode = editor.useGetter("isCode");
	const setIsCode = editor.useSetter("isCode");

	const code = editor.useGetter("code");
	const setCode = editor.useSetter("code");

	const phones = editor.useGetter("phones");
	const setPhones = editor.useSetter("phones");
	const [phonesErr, setPhonesErr] = useState<boolean[]>([]);

	const permissionsValue = editor.usePicker([
		"isAccessToCorporateApp",
		"isAccessToCorporateAccount",
		"login",
		"password",
		"isOneTimePassword",
		"oneTimePassword",
	]);

	const assigner = editor.useAssigner();

	const additionalFields = editor.useGetter("additionalFields");
	const setAdditionalFields = editor.useSetter("additionalFields");
	const additionalFieldsEditor = useObjectEditor(
		additionalFields,
		setAdditionalFields,
	);
	const tripLimit = additionalFieldsEditor.useGetter("tripLimit");
	const setTripLimit = additionalFieldsEditor.useSetter("tripLimit");

	const addresses = additionalFieldsEditor.useGetter("addresses");
	const setAddresses = additionalFieldsEditor.useSetter("addresses");

	const customer = editor.useGetter("customer");
	const setCustomer = editor.useSetter("customer");
	const customerEditor = useObjectEditor(customer, setCustomer);
	const gender = customerEditor.useGetter("gender");
	const setGender = customerEditor.useSetter("gender");
	const handleGenderChange = useCallback(
		(newGender) => {
			setGender(newGender);
		},
		[setGender],
	);
	const birthday = customerEditor.useGetter("birthday");
	const setBirthday = customerEditor.useSetter("birthday");
	const handleChangeBirthday = useCallback(
		(newDate) => {
			setBirthday(newDate);
		},
		[setBirthday],
	);
	const fatherName = customerEditor.useGetter("fatherName");
	const setFatherName = customerEditor.useSetter("fatherName");
	const name = customerEditor.useGetter("name");
	const setName = customerEditor.useSetter("name");
	const surname = customerEditor.useGetter("surname");
	const setSurname = customerEditor.useSetter("surname");
	const emails = customerEditor.useGetter("emails");
	const setEmails = customerEditor.useSetter("emails");

	const [errors, setError] = useState({
		email: false,
		phone: false,
	});

	const errorsEditor = useObjectEditor(errors, setError);

	const emailErr = errorsEditor.useGetter("email");
	const setEmailError = errorsEditor.useSetter("email");

	const handleEmailChange = useCallback(
		(newEmail: string) => {
			const isValid = validateEmail(newEmail);
			setEmailError(!isValid);
			setEmails([{ group: 0, value: newEmail }]);
		},
		[setEmailError, setEmails],
	);

	const addErrorData = useCallback(
		(data: AutodetectPhone, index: number, exist?: boolean) => {
			if (!data) return;

			setErrorData((prev) => {
				const update = prev.find((phone) => phone.value === data.value);

				const add = update
					? prev.map((item) =>
							item.value === data.value ? data : item,
					  )
					: [...prev, { ...data, index }];

				return exist
					? add
					: [...prev].filter((item) => item.value !== data.value);
			});

			if (exist) {
				setIndexPhones((prev) => union([...prev, index]));
				setPhonesErr((prev) => prev.map((_, i) => i === index));
			} else {
				setIndexPhones((prev) =>
					prev?.filter((item) => item !== index),
				);
			}
		},
		[],
	);

	const handlePhonesChange = useCallback(
		(newPhones: Phones.Value) => {
			setErrorData([]);

			const modifyPhones = newPhones.map((newPhone) => {
				const phone = countryCodePhone(newPhone.value, "uk");
				const id = uuid();

				return {
					...newPhone,
					value: phone,
					id: newPhone.id ? newPhone.id : id,
				};
			});

			const errors = modifyPhones.map((phone, _, arr) => {
				const value = validatePhone(phone?.value) !== true;

				if (!value) {
					const onePhone = arr.filter(
						(item) => item.value === phone.value,
					);
					if (onePhone.length > 1) return true;
				}

				return value;
			});
			setPhonesErr(errors);
			setPhones(modifyPhones);
		},
		[setPhones],
	);

	const onChangeSurname = useCallback(
		(value: string) => {
			const data = value.trim().length ? value : undefined;
			setSurname(data as string);
		},
		[setSurname],
	);
	const onChangeName = useCallback(
		(value: string) => {
			const data = value.trim().length ? value : undefined;
			setName(data as string);
		},
		[setName],
	);
	const onChangeFatherName = useCallback(
		(value: string) => {
			const data = value.trim().length ? value : undefined;
			setFatherName(data as string);
		},
		[setFatherName],
	);

	const tGenderOptions = useMemo(
		() =>
			genderOptions.map((option) => ({
				...option,
				label: t(`genders.${option.key}`),
			})),
		[t],
	);
	const modelItem = useMemo(() => models ?? [], [models]);

	const allCards = useMemo(
		() =>
			modelItem
				.filter((counterparty) => counterparty.id !== counterpartyId)
				.map(
					(counterparty) =>
						counterparty?.checks
							?.map((check) =>
								check.checkCards.map((item) => ({
									...item,
									code: {
										...item.code,
										keyword:
											check.additionalFields
												?.checkValue || "",
									},
									additionalFields:
										counterparty.additionalFields,
								})),
							)
							?.flat() || [],
				)
				?.flat() ?? [],
		[counterpartyId, modelItem],
	);

	const intervalCards = useMemo(
		() =>
			checks
				?.map((check) =>
					check.checkCards.map((item) => ({
						...item,
						code: {
							...item.code,
							keyword: check.additionalFields?.checkValue || "",
						},
						additionalFields: {
							name: t([
								`${tPath}.modal.tabs.main.this_counterparty`,
							]),
						},
					})),
				)
				?.flat() || [],

		[checks, t],
	);

	const existPhonesIsCounterparty = useMemo(() => {
		const allPhones = compact(
			[...allCards, ...intervalCards]
				.map(({ employees, additionalFields, code }) => {
					const payload = employees
						.map((employee) => {
							const { phones, customer } = employee;
							const existPhone = phones
								.filter((phone) => phone.isCounterparty)
								.map(({ id, value, isCounterparty }) => ({
									value,
									phoneId: id,
									customerId: customer.id,
									employeeId: employee.id || "",
									isEmployeeId: employeeId === employee.id,
									counterpartyName:
										additionalFields.name || "",
									checkValueName: code.keyword || "",
									cardName: code.value || "",
									fatherName: customer.fatherName || "",
									name: customer.name || "",
									surname: customer.surname || "",
									isDuplicate: false,
									isCounterparty,
								}))
								?.flat();

							if (existPhone.length) return existPhone;
							return null;
						})
						?.flat();

					return payload;
				})
				?.flat(),
		);

		return [...allPhones, ...autodetectPhones];
	}, [allCards, employeeId, intervalCards, autodetectPhones]);

	const autodetectPhoneErrorData = useMemo(() => {
		if (!showErrorModal) return [];
		if (errorData.length) return errorData;

		return [
			{
				value: phones.map((phone) => phone.value || "").join(", "),
				isDuplicate: true,
				checkValueName: code.keyword || "",
				cardName: code.value || "",
				fatherName: customer.fatherName || "",
				name: customer.name || "",
				surname: customer.surname || "",
			},
		];
	}, [
		showErrorModal,
		phones,
		code.keyword,
		code.value,
		customer.fatherName,
		customer.name,
		customer.surname,
		errorData,
	]);

	controller.setContext({
		value,
		indexPhones,
		existPhonesIsCounterparty,

		setPhonesErr,
		setEmailError,
		addErrorData,
		setIndexPhones,
		setShowErrorModal,
	});

	return (
		<TabRoot hasPaddings visible={true}>
			<div>
				<Column gaps="25px*">
					<FieldsContainer
						label={t(`${tPath}.modal.tabs.main.personalData`) || ""}
						gaps="10px*"
					>
						<Row gaps="10px*" sizes="1fr*3 0.5fr*2">
							<LabeledField
								label={
									t([`${tPath}.modal.tabs.main.surname`]) ||
									""
								}
							>
								<TextBox.TextBox
									placeholder={
										t(`${tPath}.modal.tabs.main.surname`) ||
										""
									}
									value={surname}
									onChange={onChangeSurname}
								/>
							</LabeledField>
							<LabeledField
								label={t(`${tPath}.modal.tabs.main.name`) || ""}
							>
								<TextBox.TextBox
									placeholder={
										t(`${tPath}.modal.tabs.main.name`) || ""
									}
									value={name}
									onChange={onChangeName}
								/>
							</LabeledField>
							<LabeledField
								label={
									t(`${tPath}.modal.tabs.main.fatherName`) ||
									""
								}
							>
								<TextBox.TextBox
									placeholder={
										t(
											`${tPath}.modal.tabs.main.fatherName`,
										) || ""
									}
									value={fatherName}
									onChange={onChangeFatherName}
								/>
							</LabeledField>
							<LabeledField
								label={
									t(`${tPath}.modal.tabs.main.birthday`) || ""
								}
							>
								<DatePicker
									value={birthday}
									onChange={handleChangeBirthday}
									locale={locale}
								/>
							</LabeledField>
							<LabeledField label={t(`gender`) || ""}>
								<Select
									value={gender}
									onChange={handleGenderChange}
									placeholder={t(`gender`) || ""}
									options={tGenderOptions}
								/>
							</LabeledField>
						</Row>
						<Row gaps="10px*" sizes="1fr 3fr">
							<LabeledField
								label={
									t(`${tPath}.modal.tabs.main.email`) || ""
								}
							>
								<TextBox.TextBox
									error={emailErr}
									placeholder={
										t(`${tPath}.modal.tabs.main.email`) ||
										""
									}
									value={emails[0]?.value}
									onChange={handleEmailChange}
								/>
							</LabeledField>
							<Row
								gaps="10px*"
								sizes={phones?.length === 3 ? "1fr*" : "32.7%*"}
							>
								<Phones
									max={3}
									disabled={false}
									value={phones}
									errors={phonesErr}
									onChange={handlePhonesChange}
									indexPhones={indexPhones}
								/>
							</Row>
						</Row>
						<Row gaps="10px*" sizes="1fr 3fr">
							<Row
								gaps="10px*"
								sizes={
									addresses?.length === 3 ? "1fr*" : "32.7%*"
								}
							>
								<Addresses
									max={3}
									value={addresses}
									onChange={setAddresses}
								/>
							</Row>
						</Row>
					</FieldsContainer>
					<Code
						isCode={isCode}
						setIsCode={setIsCode}
						value={code}
						onChange={setCode}
					/>
					<TripLimit value={tripLimit} onChange={setTripLimit} />
					<Permissions value={permissionsValue} onChange={assigner} />
				</Column>
			</div>
			{showErrorModal && (
				<AutodetectPhoneErrorModal
					value={autodetectPhoneErrorData}
					show={showErrorModal}
					setShowModal={setShowErrorModal}
				/>
			)}
		</TabRoot>
	);
}, InternalController);
const Main = memo(MainBase);

declare namespace Main {
	type Ref = InternalController | null;
	type Controller = InternalController;
	interface PropsBase {
		value: Value;
		onChange: Dispatch<Value>;
		companyId?: number;
		taxiServiceId?: number;
		counterpartyId?: number;
		employeeId?: number;
		checks?: Accounts.Check[];
	}
	type Props = PropsBase & RefAttributes<Ref>;

	interface Value extends Permissions.Value {
		phones: Phones.Value;
		additionalFields: {
			addresses: Addresses.Value;
			tripLimit: TripLimit.Value;
		};
		isCode: boolean;
		isOneTimePassword?: boolean;
		oneTimePassword?: string;
		code: Code.Value;
		customer: {
			id?: ModelId;
			gender: GenderOptions;
			name: string;
			surname: string;
			fatherName: string;
			birthday?: Date;
			emails: {
				value: string;
				group: number;
			}[];
		};
	}
}

export default Main;
