import React, {
	useCallback,
	useEffect,
	useLayoutEffect,
	useMemo,
	useState,
} from "react";
import { useTranslation } from "react-i18next";
import { cloneDeep, compact } from "lodash";
import { v4 as uuid } from "uuid";

import Agent from "../../../../../../services/Agent";
import AgentGroup from "../../../../../../services/AgentGroup";
import Counterparty from "../../../../../../services/Counterparty";
import mapByKey from "../../../../../../utils/mapByKey";
import useModelSubscribe2 from "../../../../../../hooks/useModelSubscribe2";
import useCurrentTaxiServices from "../../../../../../hooks/useCurrentTaxiService";
import DefaultPageHeader from "../../../../../../components/DefaultPageHeader";
import DeleteModal from "../../../../../../components/DeleteModal";
import { useTableOptions } from "../../../../../../components/LightTable";
import { useAutodetectPhones } from "../../hooks";
import { TabKeys, ARR_ACCESS_PART_KEY } from "../../constants/access";

import defaultValue from "./constants/defaultValue";
import HeaderAdditional from "./components/HeaderAdditional";
import tPath from "./constants/tPath";
import Root from "./components/Root";
import Content from "./components/Content";
import Modal from "./components/Modal";

const Agents: React.FC = () => {
	const { t } = useTranslation();
	const { setAutodetectPhones } = useAutodetectPhones();
	const {
		setDataLength,
		dataLength,
		filter,
		lang,
		limit,
		sort,
		editor,
		onChange,
		query,
	} = useTableOptions();

	const optionsAgent = useMemo(() => {
		const order = {};
		const payload: Agent.SubscribeOptions = {
			limit,
			lang,
			order,
			query,
		};
		if (typeof filter.active === "boolean") {
			payload.active = filter.active;
		}

		if (typeof filter.default === "boolean") {
			payload.default = filter.default;
		}

		if (sort.dataKey) order[sort.dataKey] = sort.sortType;
		return payload;
	}, [filter, lang, limit, sort.dataKey, sort.sortType, query]);

	const { models } = useModelSubscribe2(optionsAgent, Agent);
	const { models: counterparties } = useModelSubscribe2({}, Counterparty);
	const { models: groups } = useModelSubscribe2({}, AgentGroup);

	const settings = useCurrentTaxiServices()?.settings;
	const agent = useMemo(
		() => settings?.defaults.agent,
		[settings?.defaults.agent],
	);

	useLayoutEffect(() => {
		if (dataLength !== models.length) {
			setDataLength(models.length);
		}
	}, [models.length, dataLength, setDataLength]);

	const [actualDefaultValue, setActualDefaultValue] = useState(defaultValue);
	useEffect(() => {
		setActualDefaultValue({
			...defaultValue,
			isAccessToApp: agent?.isAllowedApp,
			isAccessToAccount: agent?.isAllowedCabinet,
			status: agent?.status,
		});
	}, [agent]);

	const [showModal, setShowModal] = useState(false);
	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const [isSave, setIsSave] = useState<boolean>(false);
	const [selected, setSelected] = useState<number[]>([]);

	const [editingItem, setEditingItem] =
		useState<Modal.Value>(actualDefaultValue);

	const modelItemById = useMemo(() => mapByKey(models, "id"), [models]);

	const edit = useCallback(
		(id: number) => {
			const item = modelItemById[id];
			setEditingItem(cloneDeep(item));

			setShowModal(true);
			setSelected([]);
		},
		[modelItemById],
	);

	const editHeaderHandler = useCallback(() => {
		edit(selected[0]);
	}, [edit, selected]);

	const editContentHandler = useCallback(
		(model: Agent.Modified) => edit(model.id),
		[edit],
	);

	const addHandler = useCallback(() => {
		setEditingItem(actualDefaultValue);
		setShowModal(true);
	}, [actualDefaultValue]);

	const preDeleteHandler = useCallback(() => {
		setShowDeleteModal(true);
	}, []);

	const deleteHandler = useCallback(async () => {
		await Agent.delete(selected);
		setSelected([]);
		setShowDeleteModal(false);
	}, [selected]);

	const cancelHandler = useCallback(() => {
		setShowModal(false);
		setEditingItem(actualDefaultValue);
	}, [actualDefaultValue]);

	const cancelDeleteHandler = useCallback(() => {
		setShowDeleteModal(false);
		setSelected([]);
	}, []);

	const saveHandler = useCallback(
		async (model: Agent.New | Agent.Modified, force = false) => {
			let isAllOk = true;

			if (isSave) return;

			setIsSave(true);
			if ("id" in model) {
				isAllOk = await Agent.update(model, force);
			} else {
				isAllOk = await Agent.store(model, force);
			}

			setIsSave(false);

			if (isAllOk) {
				setShowModal(false);
				setEditingItem(actualDefaultValue);
			}
		},
		[actualDefaultValue, isSave],
	);

	useEffect(() => {
		if (editingItem && editingItem.id) {
			const paymentAccounts =
				modelItemById[editingItem.id]?.paymentAccounts ?? [];

			const agentToRewards =
				modelItemById[editingItem.id]?.agentToRewards ?? [];

			setEditingItem({ ...editingItem, agentToRewards, paymentAccounts });
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [models, modelItemById]);

	const headerTitle = useMemo(
		() =>
			editingItem?.id
				? (editingItem.name[lang] as string)
				: t(`${tPath}.modal.title`),
		[editingItem, lang, t],
	);

	const checkedAgents = useMemo(
		() => models.filter((agent) => selected.includes(agent.id)),
		[models, selected],
	);

	const allCards = useMemo(
		() =>
			counterparties
				?.map(
					(counterparty) =>
						counterparty?.checks
							?.map((check) =>
								check.checkCards.map((item) => ({
									...item,
									code: {
										...item.code,
										keyword:
											check.additionalFields
												?.checkValue || "",
									},
									additionalFields:
										counterparty.additionalFields,
								})),
							)
							?.flat() || [],
				)
				?.flat() ?? [],
		[counterparties],
	);

	useEffect(() => {
		const counterpartiesPhoneList = compact(
			[...allCards]
				.map(({ employees, additionalFields, code }) => {
					const payload = employees
						.map((employee) => {
							const { phones, customer } = employee;
							const existPhone = phones
								.filter((phone) => phone.isCounterparty)
								.map(({ value, isCounterparty }) => ({
									value,
									phoneId: uuid(),
									customerId: customer.id,
									employeeId: employee.id || "",
									isEmployeeId: !!employee.id,
									counterpartyName:
										additionalFields.name || "",
									checkValueName: code.keyword || "",
									cardName: code.value || "",
									fatherName: customer.fatherName || "",
									name: customer.name || "",
									surname: customer.surname || "",
									isDuplicate: false,
									isCounterparty,
								}))
								?.flat();

							if (existPhone.length) return existPhone;
							return null;
						})
						?.flat();

					return payload;
				})
				?.flat(),
		);

		const agentPhoneList = models
			.map((agent) =>
				agent?.phones
					?.filter(
						(item) =>
							item?.isAgent === true &&
							agent?.id !== editingItem?.id,
					)
					?.map((phone) => ({
						value: phone.number,
						phoneId: phone.id,
						isAgent: phone.isAgent,
						agentId: agent?.id,
						agentName: agent?.name?.[lang] || "",
						cardName: agent?.code?.value || "",
					})),
			)
			.flat();

		setAutodetectPhones([...agentPhoneList, ...counterpartiesPhoneList]);
	}, [
		allCards,
		editingItem?.code?.value,
		editingItem?.id,
		editingItem?.name,
		editingItem?.phones,
		lang,
		models,
		setAutodetectPhones,
		t,
	]);

	return (
		<Root sizes="auto! 1fr" gaps="16px" maxedWidth maxedHeight>
			<DefaultPageHeader
				accessName={ARR_ACCESS_PART_KEY[TabKeys.AGENTS]}
				canEdit={selected.length === 1}
				canDelete={!!selected.length}
				onAdd={addHandler}
				onDelete={preDeleteHandler}
				onEdit={editHeaderHandler}
				afterAdditionalButtons={
					<HeaderAdditional agents={checkedAgents} />
				}
			/>
			<Content
				value={models}
				selected={selected}
				setSelected={setSelected}
				onEdit={editContentHandler}
				editorTable={editor}
				onChangeTable={onChange}
			/>
			{showModal && (
				<Modal
					groups={groups}
					counterparties={counterparties}
					value={editingItem}
					onCancel={cancelHandler}
					onSave={saveHandler}
					headerTitle={headerTitle}
					loading={isSave}
				/>
			)}
			{showDeleteModal && (
				<DeleteModal
					label={t(`${tPath}.deleteModal.title`) || ""}
					onCancel={cancelDeleteHandler}
					onConfirm={deleteHandler}
				/>
			)}
		</Root>
	);
};

export default Agents;
