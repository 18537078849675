import React, { memo, useMemo } from "react";
import TransactionsTab from "../../../../../../../../../../../../../../../../../../components/TransactionsTab";
import Transaction from "../../../../../../../../../../../../../../../../../../services/Transaction";
import { PaymentAccount } from "../../../../../../../../../../../../../../../../../../types/PaymentAccount";

const Transactions: React.FC<Transactions.Props> = memo(
	({ paymentAccounts, ...props }) => {
		const paymentAccountIds = useMemo(
			() => paymentAccounts?.map((a) => a.id).filter(Boolean),
			[paymentAccounts],
		);

		const subscribeOptions = useMemo<Transaction.SubscribeOptions>(
			() => ({
				paymentAccountIds,
			}),
			[paymentAccountIds],
		);

		return (
			<TransactionsTab
				options={subscribeOptions}
				isSubscribe={!!paymentAccountIds?.length}
				tableFrom="customers.counterparty.check"
				{...props}
			/>
		);
	},
);

declare namespace Transactions {
	interface Props
		extends Omit<TransactionsTab.Props, "options" | "tableFrom"> {
		paymentAccounts: PaymentAccount[];
	}
}

export default Transactions;
