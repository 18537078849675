/* eslint-disable no-shadow */
import moment from "moment";
import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import { Header } from "rsuite";
import { Button, Column, Icon, Row } from "uikit";
import { isString, noop } from "lodash";

import MountableSubscription from "../../../../../../../../../components/MountableSubscription";
import useDebouncedValue from "../../../../../../../../../hooks/useDebouncedValue";
import {
	useSort,
	useVisibleColumns,
	useWidths,
} from "../../../../../../../../../hooks/useTableSettings";
import getCompanies from "../../../../../../../../../redux/services/Company/getCompanies";
import { revertOrder } from "../../../../../../../../../redux/services/Order/revertOrder";
import {
	useTypedDispatch,
	useTypedSelector,
} from "../../../../../../../../../redux/store";
import Order from "../../../../../../../../../services/Order";
import ArchiveFilter from "../../../../../../../../../types/ArchiveFilter";
import { DateRange } from "../../../../../../../../../types/DataRange";
import IPagination from "../../../../../../../../../types/IPagination";
import OrderTable from "../../../../../../../../../components/OrderPageWidgets/OrderTable/components/ModelTable";
import tables from "../../../../../../../../../constants/tables";
import TableWidths from "../../../../../../../../../types/TableWidths";
import { ColumnId } from "../../../../../../../../../constants/tables/types";
import Root from "../components/Root";

import Filters from "./components/Filters";

declare namespace OrderTab {
	interface Props {
		entityId: number | undefined;
		visible: boolean;
		options: Value;
		saveKey: string;
	}

	type Value = Order.SubscribeOptions;
}

const OrderTab: React.FC<OrderTab.Props> = memo(
	({ entityId, visible, options, saveKey }) => {
		const dispatch = useTypedDispatch();
		const login = useTypedSelector((state) => state.account.user?.login);
		const lang = useTypedSelector((state) => state.session.language);
		const searchDelay = useTypedSelector(
			(state) => state.settings.map.inputRequestDelayMs,
		);

		const [activeOrderId, setActiveOrderId] = useState<number | null>(null);

		const [filter, setFilter] = useState<ArchiveFilter>({
			searchQuery: "",
			dateFrom: moment().startOf("day").toDate(),
			dateTo: moment().endOf("day").toDate(),
			companyFilter: [],
			taxiServiceFilter: ["all"],
		});

		const { columnIds, setColumnIds } = useVisibleColumns(saveKey, "order");
		const { sortMethod, setSortMethod } = useSort(saveKey, "order");
		const { widths, setWidths } = useWidths(saveKey, "order");

		useEffect(() => {
			dispatch(getCompanies());
		}, [dispatch]);

		const filterDateRange = useMemo<DateRange>(
			() => ({
				from: filter.dateFrom,
				to: filter.dateTo,
			}),
			[filter.dateFrom, filter.dateTo],
		);

		const debouncedSearchQuery = useDebouncedValue(
			filter.searchQuery,
			searchDelay,
		);

		// TODO:
		const [offset, setOffset] = useState(0);
		const [limit, setLimit] = useState(200);
		const [virtualCount, setVirtualCount] = useState(0);
		const order = useMemo(
			() =>
				isString(sortMethod.column) && isString(sortMethod.type)
					? {
							[sortMethod.column]:
								sortMethod.type === "asc" ? "ASC" : "DESC",
					  }
					: undefined,
			[sortMethod],
		);

		const subscribeOptions = useMemo<Order.SubscribeOptions>(
			() => ({
				offset: offset === 0 ? undefined : offset,
				// limit,
				// order,
				query: debouncedSearchQuery,
				dateRange: filterDateRange,
				tab: "overall",
				...options,
			}),
			[
				debouncedSearchQuery,
				filterDateRange,

				// limit,

				offset,
				options,
				// order,
			],
		);

		const [orders, setOrders] = useState<Order.Model[]>([]);
		const [pagination, setPagination] = useState<IPagination | null>(null);

		useEffect(() => {
			if (
				virtualCount < offset + orders.length ||
				virtualCount > (pagination?.count || 0)
			)
				setVirtualCount(
					Math.min(offset + orders.length, pagination?.count || 0),
				);
		}, [
			virtualCount,
			offset,
			orders.length,
			pagination?.offset,
			pagination?.count,
		]);

		const disableUnarchiveButton = activeOrderId == null || login == null;
		const unarchiveActiveOrder = useCallback(() => {
			if (disableUnarchiveButton) return;

			dispatch(
				revertOrder({
					id: activeOrderId,
					executorLogin: login,
				}),
			);
		}, [activeOrderId, disableUnarchiveButton, dispatch, login]);

		const onMetadataChange = useCallback((metadata) => {
			setPagination(metadata.pagination);
		}, []);
		const mountableSubscription = useMemo(() => {
			if (limit === 0) return null;
			if (!entityId) return null;

			return (
				<MountableSubscription
					subscribeOptions={subscribeOptions}
					Service={Order}
					onModelsChange={setOrders}
					onMetadataChange={onMetadataChange}
				/>
			);
		}, [entityId, limit, onMetadataChange, subscribeOptions]);

		const modelTableSort = useMemo(() => {
			if (sortMethod) {
				return {
					column: sortMethod.column,
					type: sortMethod.type,
				};
			}

			return undefined;
		}, [sortMethod]);

		const columns = useMemo<OrderTable.Column[]>(
			() =>
				tables.orderArchive?.columns
					?.map((column) => ({
						id: column.id as OrderTable.ColumnId,
						width: widths[column?.id],
						visible: columnIds.includes(
							column.id as OrderTable.ColumnId,
						),
					}))
					.filter((column) => column.id !== "timeToDelivery"),
			[columnIds, widths],
		);

		const orderTableOnChangeSelected = useCallback(
			(id: number) => setActiveOrderId(id),
			[setActiveOrderId],
		);

		const orderTableOnChangeSort = useCallback(
			(sort: OrderTable.Sort) => {
				setSortMethod(sort);
			},
			[setSortMethod],
		);

		const orderTableOnChangeColumns = useCallback(
			(columns: OrderTable.Column[]) => {
				setColumnIds(
					columns
						.filter((column) => column.visible)
						.map((column) => column.id),
				);

				setWidths(
					columns.reduce((accumulator, column) => {
						accumulator[column.id] = column.width;

						return accumulator;
					}, {} as TableWidths<ColumnId<"orderArchive">>),
				);
			},
			[setColumnIds, setWidths],
		);

		return (
			<Root visible={visible} hasPaddings={false}>
				<Column
					maxedHeight
					align="center"
					gaps="10px*"
					style={{ paddingTop: 10 }}
				>
					{mountableSubscription}
					<Header style={{ width: "100%", padding: "0 10px" }}>
						<Row
							maxedWidth
							gaps="15px*"
							align="center"
							justify="space-between"
						>
							<Row gaps="5px*" align="center">
								<Button.Button
									variant="secondary"
									icon={<Icon id="pencil" size={20} />}
								/>
								<Button.Button
									variant="secondary"
									icon={<Icon id="return-back" size={20} />}
									disabled={disableUnarchiveButton}
									onClick={unarchiveActiveOrder}
								/>
								<Button.Button
									variant="secondary"
									icon={<Icon id="export-page" size={20} />}
								/>
							</Row>
							<Filters filter={filter} setFilter={setFilter} />
						</Row>
					</Header>
					<OrderTable
						selected={activeOrderId ?? undefined}
						sort={modelTableSort}
						columns={columns}
						data={orders}
						language={lang}
						onChangeSelected={orderTableOnChangeSelected}
						onChangeSort={orderTableOnChangeSort}
						onChangeColumns={orderTableOnChangeColumns}
						onEdit={noop}
						onLoadMore={noop}
					/>
				</Column>
			</Root>
		);
	},
);

export default OrderTab;
