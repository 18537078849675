import React, { Dispatch, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { CheckBoxWithContent, Row } from "uikit";
import useObjectEditor from "../../../../../../../../../../hooks/useObjectEditor";

const Footer: React.FC<Footer.Props> = ({ value, disabled, onChange }) => {
	const valueEditor = useObjectEditor(value, onChange);

	const activeOnChange = useCallback(
		(active: boolean) => {
			valueEditor.set("active", active);
		},
		[valueEditor],
	);

	const { t } = useTranslation();
	return (
		<Row gaps="24px">
			<CheckBoxWithContent
				gap="10px"
				disabled={disabled}
				value={valueEditor.get("active")}
				onChange={activeOnChange}
			>
				{t(
					"pages.mainPage.pages.accounts.tabs.executors.editModal.footer.str150",
				) ?? ""}
			</CheckBoxWithContent>
		</Row>
	);
};

declare namespace Footer {
	interface Value {
		active: boolean;
	}

	interface Props {
		value: Value;

		disabled: boolean;

		onChange: Dispatch<Value>;
	}
}

export default Footer;
