import { react } from "uikit";

interface Context {
	taxiServiceId?: number;

	onTaxiServiceIdError: (error: boolean) => void;
}

export default class Controller extends react.ControllerBase<Context> {
	validate() {
		const isTaxiServiceIdValid = this.context?.taxiServiceId != null;

		if (!isTaxiServiceIdValid) this.context?.onTaxiServiceIdError(true);

		return isTaxiServiceIdValid;
	}
}
