import React, {
	Dispatch,
	RefAttributes,
	memo,
	useCallback,
	useState,
} from "react";
import { Column, MultilineTextBox, TextBox, react } from "uikit";
import { useTranslation } from "react-i18next";
import Root from "./components/Root";
import Controller from "./Controller";
import useObjectEditor from "../../../../../../../../../../hooks/useObjectEditor";
import tPath from "../../../../constants/tPath";
import LabeledField from "../../../../../../../../../../components/LabeledField";
import Main from "./components/Main";

const ContentBase = react.withController<Content.PropsBase, Controller>(
	({
		counterparties,
		counterpartyById,
		value,
		onChange,
		controller,
		editorTable,
		onChangeTable,
	}) => {
		const { t } = useTranslation();

		const editor = useObjectEditor(value, onChange);

		const name = editor.useGetter("name");
		const setName = editor.useSetter("name");
		const [nameError, setNameError] = useState(false);
		const handleNameChange = useCallback(
			(newName: string) => {
				setNameError(false);
				setName(newName);
			},
			[setName],
		);

		const mainValue = editor.useGetter("counterpartyIds");
		const setMainValue = editor.useSetter("counterpartyIds");

		const notes = editor.useGetter("notes");
		const setNotes = editor.useSetter("notes");

		controller.setContext({ value, setNameError });

		return (
			<Root>
				<Column gaps="16px*" sizes="auto 1fr auto" maxedHeight>
					<LabeledField label={t(`${tPath}.modal.name`) || ""}>
						<TextBox.TextBox
							placeholder={t(`${tPath}.modal.name`) || ""}
							error={nameError}
							value={name}
							onChange={handleNameChange}
						/>
					</LabeledField>
					<Main
						counterparties={counterparties}
						counterpartyById={counterpartyById}
						value={mainValue}
						onChange={setMainValue}
						editorTable={editorTable}
						onChangeTable={onChangeTable}
					/>
					<LabeledField label={t(`${tPath}.modal.notes`) || ""}>
						<MultilineTextBox
							placeholder={t(`${tPath}.modal.notes`) || ""}
							value={notes}
							onChange={setNotes}
						/>
					</LabeledField>
				</Column>
			</Root>
		);
	},
	Controller,
);

const Content = memo(ContentBase);

declare namespace Content {
	type Ref = Controller | null;

	interface PropsBase {
		counterparties: Main.Props["counterparties"];
		counterpartyById: Main.Props["counterpartyById"];
		editorTable: Main.Props["editorTable"];
		onChangeTable: Main.Props["onChangeTable"];
		value: Value;
		onChange: Dispatch<Value>;
	}
	type Props = PropsBase & RefAttributes<Ref>;

	interface Value {
		name: string;
		counterpartyIds: Main.Value;
		notes: string;
	}
}

export default Content;
