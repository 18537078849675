import { string } from "yup";

export const validateEmail = (email: string): boolean => {
	const regex = /^([a-zA-Z0-9_.-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
	const schemaPhone = string().matches(regex);
	const isValid = schemaPhone.isValidSync(email);
	return isValid;
};

export default function fn(email: string) {
	// const emailValidationRegex = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/;
	const emailValidationRegex = /\S+@\S+\.\S+/;
	return emailValidationRegex.test(email);
}
