import { react } from "uikit";
import { Dispatch, SetStateAction } from "react";

import { validatePhone } from "../../../../../../../../../../../../../../../utils/validatePhone";
import { validateEmail } from "../../../../../../../../../../../../../../../utils/validateEmail";
import { AutodetectPhone } from "../../../../../../../../../../../hooks";

import Main from ".";

interface Context {
	value: Main.Value;
	indexPhones: number[];

	setIndexPhones: Dispatch<SetStateAction<number[]>>;
	setPhonesErr: Dispatch<SetStateAction<boolean[]>>;
	setShowErrorModal: Dispatch<SetStateAction<boolean>>;
	setEmailError: (err: boolean) => void;
	addErrorData: (
		data: AutodetectPhone,
		index: number,
		exist?: boolean,
	) => void;
	existPhonesIsCounterparty: AutodetectPhone[];
}

export default class Controller extends react.ControllerBase<Context> {
	validate() {
		const phones = this.context?.value?.phones || [];
		const isPhonesValidLength = !!this.context?.value?.phones?.length;
		let indexPhones = this.context?.indexPhones;

		const existPhonesIsCounterparty =
			this.context?.existPhonesIsCounterparty;

		const addErrorData = this.context?.addErrorData;
		const setIndexPhones = this.context?.setIndexPhones;
		const setShowErrorModal = this.context?.setShowErrorModal;

		let isValidPhone = true;

		const isPhonesValid = phones.every((phone, _, arr) => {
			const value = validatePhone(phone.value?.trim?.()) === true;

			if (value) {
				const onePhone = arr.filter(
					(item) => item.value === phone.value,
				);
				if (onePhone.length > 1) return false;
			}

			return value;
		});

		if (!isPhonesValid) {
			phones?.forEach((phone, i, arr) => {
				this.context?.setPhonesErr((prev) => {
					const newPhonesErr = [...prev];
					newPhonesErr[i] = !validatePhone(phone.value?.trim?.());

					if (!newPhonesErr[i]) {
						const onePhone = arr.filter(
							(item) => item.value === phone.value,
						);
						if (onePhone.length > 1) {
							const index = arr.indexOf(onePhone[1]);
							newPhonesErr[index] = true;
						}
					}

					return newPhonesErr;
				});
			});
		}

		isValidPhone = phones
			?.map((phone, i) => {
				const { isCounterparty } = phone;
				const value = phone.value?.trim?.();

				if (!isCounterparty) {
					setIndexPhones?.((prev) =>
						prev?.filter((item) => item !== i),
					);
					this.context?.setPhonesErr((prev = []) => {
						prev[i] = false;
						return prev;
					});
					addErrorData?.({ value }, i, false);

					return true;
				}

				const exist = existPhonesIsCounterparty?.find(
					(item) =>
						item.value === phone.value && item.phoneId !== phone.id,
				);

				if (exist) {
					addErrorData?.(exist, i, true);
					this.context?.setPhonesErr((prev = []) => {
						prev[i] = true;
						return prev;
					});

					return false;
				}

				this.context?.setPhonesErr((prev = []) => {
					prev[i] = false;
					return prev;
				});

				addErrorData?.({ value }, i, false);
				indexPhones = indexPhones?.filter((item) => item !== i);
				setIndexPhones?.((prev) => prev?.filter((item) => item !== i));
				return true;
			})
			.every((item) => item === true);

		const emails = this.context?.value?.customer.emails;
		const isValidEmail = emails?.length
			? emails
					.map((email) => {
						if (!email.value) return true;
						return validateEmail(email.value?.trim?.());
					})
					.every((item) => item === true)
			: true;
		if (!isValidEmail) this.context?.setEmailError(true);
		if (!isValidPhone) setShowErrorModal?.(true);

		return (
			isValidPhone && isPhonesValid && isValidEmail && isPhonesValidLength
		);
	}
}
