import React, { Dispatch, memo, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { CheckBox } from "uikit";

import Counterparty from "../../../../../../../../../../../../../../../../../../../../../../services/Counterparty";
import useObjectEditor from "../../../../../../../../../../../../../../../../../../../../../../hooks/useObjectEditor";
import LabeledField from "../../../../../../../../../../../../../../../../../../../../../../components/LabeledField";
import {
	StyledRow,
	StyledColumn,
} from "../../../../../../../../../../../../../../../../../../../../../../components/common";

import Transaction from "./components/Transaction";

const Transactions: React.FC<Transactions.Props> = memo(
	({
		value,
		onChange,
		path,
		isGeneralMainBalance,
		isGeneralBonusBalance,
		useGeneralThresholdBalance,
		ignoreThresholdBalance,
		generalMainAmount,
		generalBonusAmount,
		generalThresholdBalance,
		ignoreGeneralThresholdBalance,
		allowGeneralBonusBalance,
		allowBonusBalance,
		setIsGeneralMainBalance,
		setUseGeneralThresholdBalance,
		setIsGeneralBonusBalance,
	}) => {
		const { t } = useTranslation();
		const editor = useObjectEditor(value, onChange);

		const main = editor.useGetter("main");
		const setMain = editor.useSetter("main");

		const bonus = editor.useGetter("bonus");
		const setBonus = editor.useSetter("bonus");

		const threshold = editor.useGetter("threshold");
		const setThreshold = editor.useSetter("threshold");

		const disabledThreshold = useMemo(() => {
			if (useGeneralThresholdBalance) return true;
			if (ignoreThresholdBalance) return true;
			return false;
		}, [useGeneralThresholdBalance, ignoreThresholdBalance]);

		const disabledThresholdCheckBox = useMemo(() => {
			if (ignoreGeneralThresholdBalance) return true;
			if (ignoreThresholdBalance) return true;
			return false;
		}, [ignoreGeneralThresholdBalance, ignoreThresholdBalance]);

		const disabledBonusCheckBox = useMemo(() => {
			if (disabledThresholdCheckBox) return true;
			if (allowBonusBalance) return true;
			return !allowGeneralBonusBalance;
		}, [
			allowGeneralBonusBalance,
			disabledThresholdCheckBox,
			allowBonusBalance,
		]);

		const valueMain = useMemo(() => {
			if (!isGeneralMainBalance) return main;
			return [{ amount: generalMainAmount ?? 0, description: "" }];
		}, [isGeneralMainBalance, main, generalMainAmount]);

		const valueBones = useMemo(() => {
			if (!isGeneralBonusBalance) return bonus;
			return [{ amount: generalBonusAmount ?? 0, description: "" }];
		}, [bonus, generalBonusAmount, isGeneralBonusBalance]);

		const valueThreshold = useMemo(() => {
			if (!useGeneralThresholdBalance) return threshold;
			return [{ amount: generalThresholdBalance ?? 0, description: "" }];
		}, [generalThresholdBalance, threshold, useGeneralThresholdBalance]);

		return (
			<StyledRow gap="10px" justify="space-between" w="70%">
				<StyledColumn gap="10px">
					<LabeledField
						label={t([`${path}.balances.main`, "Balance"]) || ""}
					>
						<Transaction
							disabled={isGeneralMainBalance}
							value={valueMain}
							type="main"
							onChange={setMain}
						/>
					</LabeledField>
					{/* <CheckBox
						label={
							t(
								"pages.mainPage.pages.customers.tabs.counterparties.modal.content.accounts.modal.content.tabs.main.account.transactions.str0",
							) ?? ""
						}
						value={isGeneralMainBalance}
						onChange={setIsGeneralMainBalance}
					/> */}
				</StyledColumn>
				<StyledColumn gap="10px">
					<LabeledField
						label={
							t([`${path}.balances.bonus`, "Bonus balance"]) || ""
						}
					>
						<Transaction
							disabled={
								isGeneralBonusBalance ||
								ignoreThresholdBalance ||
								!allowBonusBalance
							}
							value={valueBones}
							type="bonus"
							onChange={setBonus}
						/>
					</LabeledField>

					{/* <CheckBox
						disabled={disabledBonusCheckBox}
						value={isGeneralBonusBalance}
						label={
							t(
								"pages.mainPage.pages.customers.tabs.counterparties.modal.content.accounts.modal.content.tabs.main.account.transactions.str1",
							) ?? ""
						}
						onChange={setIsGeneralBonusBalance}
					/> */}
				</StyledColumn>
				<StyledColumn gap="10px">
					<LabeledField
						label={
							t([
								`${path}.balances.threshold`,
								"Shutdown threshold",
							]) || ""
						}
					>
						<Transaction
							disabled={disabledThreshold}
							value={valueThreshold}
							type="threshold"
							onChange={setThreshold}
						/>
					</LabeledField>
					{/* <CheckBox
						disabled={disabledThresholdCheckBox}
						label={
							t(
								"pages.mainPage.pages.customers.tabs.counterparties.modal.content.accounts.modal.content.tabs.main.account.transactions.str2",
							) ?? ""
						}
						value={useGeneralThresholdBalance}
						onChange={setUseGeneralThresholdBalance}
					/> */}
				</StyledColumn>
			</StyledRow>
		);
	},
);

declare namespace Transactions {
	interface Props {
		value: Value;
		onChange: Dispatch<Value>;
		path?: string;
		isGeneralMainBalance: boolean;
		isGeneralBonusBalance: boolean;
		ignoreThresholdBalance: boolean;
		allowGeneralBonusBalance: boolean;
		allowBonusBalance: boolean;
		ignoreGeneralThresholdBalance: boolean;
		useGeneralThresholdBalance: boolean;
		generalMainAmount: number | undefined;
		generalBonusAmount: number | undefined;
		generalThresholdBalance: number | undefined;
		setIsGeneralMainBalance: (value: boolean) => void;
		setUseGeneralThresholdBalance: (value: boolean) => void;
		setIsGeneralBonusBalance: (value: boolean) => void;
	}

	type Value = Counterparty.New["paymentTransactions"];
}

export default Transactions;
