import React, { Dispatch } from "react";
import { DatePicker } from "uikit";
import { useTranslation } from "react-i18next";

import LabeledField from "../../../../../../../../../../../../../../../../../components/LabeledField";
import useObjectEditor from "../../../../../../../../../../../../../../../../../hooks/useObjectEditor";
import useDatePickerLocale from "../../../../../../../../../../../../../../../../../hooks/useDatePickerLocale";

declare namespace Birthday {
	interface Value {
		birthday: Date | null | undefined;
	}

	interface Props {
		value: Value;

		onChange: Dispatch<Value>;
	}
}

const Birthday: React.FC<Birthday.Props> = ({ value, onChange }) => {
	const { t } = useTranslation();

	const valueEditor = useObjectEditor(value, onChange);
	const birthday = valueEditor.useGetter("birthday") || undefined;
	const setBirthday = valueEditor.useSetter("birthday");

	return (
		<LabeledField
			label={
				t(`mainPage.customers.clients.main.personalData.birthDate`) ||
				"Birth date"
			}
		>
			<DatePicker
				locale={useDatePickerLocale()}
				value={birthday}
				onChange={setBirthday}
				placeholder // true will use current date as a placeholder
			/>
		</LabeledField>
	);
};

export default Birthday;
