import getOptions from "../../../../../../../../../utils/generateOptions";

// eslint-disable-next-line no-shadow
export enum ModalTabs {
	MAIN = "main",
	REWARD = "reward",
	PHONES = "phones",
	ADDRESSES = "addresses",
	ORDERS = "orders",
	TRANSACTIONS = "transactions",
}

export const tabs = getOptions(Object.values(ModalTabs));
