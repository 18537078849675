import { RefObject } from "react";
import { react } from "uikit";

import Main from "./tabs/Main";

interface Context {
	mainRef: RefObject<Main.Ref | null>;
}

export default class Controller extends react.ControllerBase<Context> {
	validate() {
		const main = this.context?.mainRef.current?.validate();

		return main;
	}
}
