import React, { Dispatch, memo, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Column, DatePicker, Icon, Row, TextBox, theme } from "uikit";
import { extend } from "lodash";

import File from "../../../../../../../../../../../../../../services/File";
import useDatePickerLocale from "../../../../../../../../../../../../../../hooks/useDatePickerLocale";
import useObjectEditor from "../../../../../../../../../../../../../../hooks/useObjectEditor";
import FileListModal from "../../../../../../../../../../../../../../components/FileListModal";
import FieldsContainer from "../../../../../../../../../../../../../../components/FieldsContainer";
import LabeledField from "../../../../../../../../../../../../../../components/LabeledField";
import Nullable from "../../../../../../../../../../../../../../types/Nullable";
import Optional from "../../../../../../../../../../../../../../types/Optional";

const property: unknown = null;

namespace PassportValue {
	export const properties = {
		passportSeries: property as string,
		passportNumber: property as string,
		passportIssuedAt: property as Date | undefined,
		passportIssuedBy: property as string,
		taxId: property as string,
		birthAt: property as Date | undefined,
		residenceAddress: property as string,
		livingAddress: property as string,
		passportFiles: property as File.Model[],
	};

	export const propertyNames = Object.keys(
		properties,
	) as (keyof typeof properties)[];
}

const PassportBase = memo(({ value, disabled, onChange }: Passport.Props) => {
	const { t } = useTranslation();
	const locale = useDatePickerLocale();

	const valueEditor = useObjectEditor(value, onChange);

	const seriesValue = valueEditor.useGetter("passportSeries");
	const seriesOnChange = valueEditor.useSetter("passportSeries");

	const numberValue = valueEditor.useGetter("passportNumber");
	const numberOnChange = valueEditor.useSetter("passportNumber");

	const issuedByValue = valueEditor.useGetter("passportIssuedBy");
	const issuedByOnChange = valueEditor.useSetter("passportIssuedBy");

	const issuedAtValue = valueEditor.useGetter("passportIssuedAt");
	const setIssuedAtValue = valueEditor.useSetter("passportIssuedAt");

	const issuedAtOnChange = useCallback(
		(issuedAt: Nullable<Date>) => {
			setIssuedAtValue(issuedAt || undefined);
		},
		[setIssuedAtValue],
	);

	const birthAtValue = valueEditor.useGetter("birthAt");
	const setBirthAtValue = valueEditor.useSetter("birthAt");

	const birthAtOnChange = useCallback(
		(issuedAt: Nullable<Date>) => {
			setBirthAtValue(issuedAt || undefined);
		},
		[setBirthAtValue],
	);

	const taxIdValue = valueEditor.useGetter("taxId");
	const taxIdOnChange = valueEditor.useSetter("taxId");

	const residenceAddressValue = valueEditor.useGetter("residenceAddress");
	const residenceAddressOnChange = valueEditor.useSetter("residenceAddress");

	const livingAddressValue = valueEditor.useGetter("livingAddress");
	const livingAddressOnChange = valueEditor.useSetter("livingAddress");

	const passportFilesValue = valueEditor.useGetter("passportFiles");
	const passportFilesOnChange = valueEditor.useSetter("passportFiles");

	const [showFileModal, setShowFileModal] = useState(false);

	const fileButtonOnClick = useCallback(() => {
		setShowFileModal(true);
	}, []);

	const fileListModalOnClose = useCallback(() => {
		setShowFileModal(false);
	}, []);

	const fileListModalOnSubmit = useCallback(
		(files: File.Model[]) => {
			console.log(files);
			passportFilesOnChange(files);
			setShowFileModal(false);
		},
		[passportFilesOnChange],
	);

	return (
		<>
			<FieldsContainer
				label={
					t(
						"pages.mainPage.pages.accounts.tabs.executors.editModal.content.additionalDataTab.passport.str0",
					) ?? ""
				}
			>
				<Column gaps="8px*">
					<Row gaps="8px*" sizes="110px 1fr 1fr">
						<LabeledField
							label={
								t(
									"pages.mainPage.pages.accounts.tabs.executors.editModal.content.additionalDataTab.passport.str1",
								) ?? ""
							}
						>
							<TextBox.TextBox
								value={seriesValue}
								disabled={disabled}
								maxLength={2}
								style={{
									minHeight: "32px",
									flex: "1 0 0",
								}}
								placeholder={
									t(
										"pages.mainPage.pages.accounts.tabs.executors.editModal.content.additionalDataTab.passport.str1",
									) ?? ""
								}
								onChange={seriesOnChange}
							/>
						</LabeledField>
						<LabeledField
							label={
								t(
									"pages.mainPage.pages.accounts.tabs.executors.editModal.content.additionalDataTab.passport.str3",
								) ?? ""
							}
						>
							<TextBox.TextBox
								value={numberValue}
								disabled={disabled}
								maxLength={9}
								style={{
									minHeight: "32px",
									flex: "1 0 0",
								}}
								placeholder={
									t(
										"pages.mainPage.pages.accounts.tabs.executors.editModal.content.additionalDataTab.passport.str3",
									) ?? ""
								}
								onChange={numberOnChange}
							/>
						</LabeledField>

						<LabeledField
							label={
								t(
									"pages.mainPage.pages.accounts.tabs.executors.editModal.content.additionalDataTab.passport.str5",
								) ?? ""
							}
						>
							<TextBox.TextBox
								value={issuedByValue}
								disabled={disabled}
								style={{
									minHeight: "32px",
									flex: "1 0 0",
								}}
								placeholder={
									t(
										"pages.mainPage.pages.accounts.tabs.executors.editModal.content.additionalDataTab.passport.str5",
									) ?? ""
								}
								onChange={issuedByOnChange}
							/>
						</LabeledField>
					</Row>
					<Row gaps="8px*" sizes="148px*2 1fr">
						<LabeledField
							label={
								t(
									"pages.mainPage.pages.accounts.tabs.executors.editModal.content.additionalDataTab.passport.str7",
								) ?? ""
							}
						>
							<DatePicker
								value={issuedAtValue}
								disabled={disabled}
								locale={locale}
								placeholder={
									t(
										"pages.mainPage.pages.accounts.tabs.executors.editModal.content.additionalDataTab.passport.str7",
									) ?? ""
								}
								onChange={issuedAtOnChange}
							/>
						</LabeledField>
						<LabeledField
							label={
								t(
									"pages.mainPage.pages.accounts.tabs.executors.editModal.content.additionalDataTab.passport.str9",
								) ?? ""
							}
						>
							<DatePicker
								value={birthAtValue}
								disabled={disabled}
								locale={locale}
								placeholder={
									t(
										"pages.mainPage.pages.accounts.tabs.executors.editModal.content.additionalDataTab.passport.str9",
									) ?? ""
								}
								onChange={birthAtOnChange}
							/>
						</LabeledField>
						<LabeledField
							label={
								t(
									"pages.mainPage.pages.accounts.tabs.executors.editModal.content.additionalDataTab.passport.str11",
								) ?? ""
							}
						>
							<TextBox.TextBox
								value={taxIdValue}
								disabled={disabled}
								style={{
									minHeight: "32px",
									flex: "1 0 0",
								}}
								placeholder={
									t(
										"pages.mainPage.pages.accounts.tabs.executors.editModal.content.additionalDataTab.passport.str11",
									) ?? ""
								}
								onChange={taxIdOnChange}
							/>
						</LabeledField>
					</Row>
					<Row align="end" gaps="8px*" sizes="1fr* 32px!">
						<LabeledField
							label={
								t(
									"pages.mainPage.pages.accounts.tabs.executors.editModal.content.additionalDataTab.passport.str13",
								) ?? ""
							}
						>
							<TextBox.TextBox
								value={residenceAddressValue}
								disabled={disabled}
								style={{
									minHeight: "32px",
									flex: "1 0 0",
								}}
								placeholder={
									t(
										"pages.mainPage.pages.accounts.tabs.executors.editModal.content.additionalDataTab.passport.str13",
									) ?? ""
								}
								onChange={residenceAddressOnChange}
							/>
						</LabeledField>
						<LabeledField
							label={
								t(
									"pages.mainPage.pages.accounts.tabs.executors.editModal.content.additionalDataTab.passport.str15",
								) ?? ""
							}
						>
							<TextBox.TextBox
								value={livingAddressValue}
								disabled={disabled}
								style={{
									minHeight: "32px",
									flex: "1 0 0",
								}}
								placeholder={
									t(
										"pages.mainPage.pages.accounts.tabs.executors.editModal.content.additionalDataTab.passport.str15",
									) ?? ""
								}
								onChange={livingAddressOnChange}
							/>
						</LabeledField>
						<Button.Button
							disabled={disabled}
							icon={
								<Icon
									id="copy"
									size={16}
									colors={[
										disabled
											? theme.colors.secondary
											: theme.colors.white,
									]}
								/>
							}
							onClick={fileButtonOnClick}
						/>
					</Row>
				</Column>
			</FieldsContainer>{" "}
			{showFileModal ? (
				<FileListModal
					value={passportFilesValue}
					title={
						t(
							"pages.mainPage.pages.accounts.tabs.executors.editModal.content.additionalDataTab.passport.str17",
						) ?? ""
					}
					onSubmit={fileListModalOnSubmit}
					onClose={fileListModalOnClose}
				/>
			) : undefined}
		</>
	);
});

const Passport = extend(PassportBase, {
	Value: PassportValue,
});

declare namespace Passport {
	type Value = Optional<typeof PassportValue.properties>;

	interface Props {
		value: Value;

		disabled: boolean;

		onChange: Dispatch<Value>;
	}
}

export default Passport;
