import React, { memo } from "react";
import { useTranslation } from "react-i18next";

import Transaction from "../../../../../../services/Transaction";
import { StyledRow } from "../../../../../common";

const TransactionAuthor: React.FC<TransactionAuthor.Props> = memo(
	({ operation }) => {
		const { t } = useTranslation();

		return (
			<StyledRow alignItems="center" gap="8px" w="100%">
				<div>
					{t([
						`transactionAuthor.${operation?.createdBy?.type}`,
						`transactionAuthor.system`,
					])}
				</div>
				<div>{operation?.createdBy?.data?.person?.name}</div>
			</StyledRow>
		);
	},
);

declare namespace TransactionAuthor {
	interface Props {
		operation: Transaction.Model["operation"];
	}
}

export default TransactionAuthor;
