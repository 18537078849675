import React, {
	Dispatch,
	RefAttributes,
	useCallback,
	useLayoutEffect,
	useMemo,
	useState,
} from "react";
import { useTranslation } from "react-i18next";
import { Option, Select, react } from "uikit";

import mapByKey from "../../../../../../../../../../../../../../../../utils/mapByKey";
import LabeledField from "../../../../../../../../../../../../../../../../components/LabeledField";

import { useExecutorContext } from "../../../../../../../../context";
import TaxiServiceId from "../TaxiServiceId";
import InternalController from "./Controller";

const ExecutorRateId = react.withController<
	ExecutorRateId.PropsBase,
	ExecutorRateId.Controller
>(({ value, onChange, disabled, taxiServiceId }) => {
	const { t } = useTranslation();
	const { executorRate, taxiServices } = useExecutorContext();

	const [error, setError] = useState(false);

	const selectOptions = useMemo<Option<number>[]>(
		() =>
			executorRate.map((item) => ({
				key: item.id,
				label: item.name,
				value: item.id,
			})),
		[executorRate],
	);

	const selectOptionByKey = useMemo(
		() => mapByKey(selectOptions, "key"),
		[selectOptions],
	);

	const selectOnChange = useCallback(
		(key) => {
			setError(false);

			onChange(selectOptionByKey[key].value);
		},
		[onChange, selectOptionByKey],
	);

	useLayoutEffect(() => {
		if (
			(!value && taxiServices.length) ||
			(!taxiServiceId && taxiServices.length)
		) {
			const taxiServicesIds = taxiServices.map((item) => item.id);

			const exist = executorRate?.find((item) => {
				if (
					!item.root &&
					item.default &&
					taxiServicesIds.every((id) =>
						item.taxiServiceIds.includes(id),
					)
				) {
					return true;
				}

				return false;
			});

			if (exist && exist.id !== value) {
				onChange(exist.id);
				return;
			}
			const root = executorRate?.find((item) => item.root === true);
			if (root) onChange(root.id);
		}
	}, [executorRate, onChange, value, taxiServices, taxiServiceId]);

	return (
		<LabeledField
			label={
				t(
					"pages.mainPage.pages.accounts.tabs.executors.editModal.content.mainTab.workData.executorRateId.str0",
				) ?? ""
			}
		>
			<Select
				value={value}
				disabled={disabled || executorRate.length === 0}
				error={error}
				placeholder={
					t(
						"pages.mainPage.pages.accounts.tabs.executors.editModal.content.mainTab.workData.executorRateId.str1",
					) ?? ""
				}
				options={selectOptions}
				onChange={selectOnChange}
			/>
		</LabeledField>
	);
}, InternalController);

declare namespace ExecutorRateId {
	type Ref = InternalController | null;

	type Controller = InternalController;

	type Value = number | undefined;

	interface PropsBase {
		value: Value;
		onChange: Dispatch<Value>;

		taxiServiceId: TaxiServiceId.Value;
		disabled: boolean;
	}

	type Props = PropsBase & RefAttributes<Ref>;
}

export default ExecutorRateId;
