import React, { useMemo } from "react";

import GeneralEmployees from "../../../../../tabs/Employees";
import Accounts from "../Accounts";

const Employees: React.FC<Employees.Props> = ({
	visible,
	checks,
	onChange,
	companyId,
	counterpartyId,
	taxiServiceId,
	cards,
}) => {
	const actualEmployees = useMemo(
		() =>
			checks
				.flatMap((check) => check.checkCards)
				.flatMap((checkCard) => checkCard.employees),
		[checks],
	);

	return (
		<GeneralEmployees
			columnsName="counterpartyEmployee"
			tableFrom="customers.counterparty.employees"
			isCounterparty={true}
			visible={visible}
			employees={actualEmployees}
			checks={checks}
			setChecks={onChange}
			companyId={companyId}
			counterpartyId={counterpartyId}
			taxiServiceId={taxiServiceId}
			cards={cards}
		/>
	);
};

declare namespace Employees {
	interface Props {
		visible: boolean;
		checks: Value;
		onChange: (value: Value) => void;
		companyId?: number;
		taxiServiceId?: number;
		counterpartyId?: number;
		cards?: GeneralEmployees.Props["cards"];
	}

	type Value = Accounts.Check[];
}

export default Employees;
