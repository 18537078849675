import React, {
	Dispatch,
	RefAttributes,
	useCallback,
	useLayoutEffect,
	useMemo,
	useState,
} from "react";
import { useTranslation } from "react-i18next";
import { Option, Select, react } from "uikit";

import LabeledField from "../../../../../../../../../../../../../../../../components/LabeledField";
import mapByKey from "../../../../../../../../../../../../../../../../utils/mapByKey";
import Language from "../../../../../../../../../../../../../../../../services/Language";

import { useExecutorContext } from "../../../../../../../../context";
import ExecutorRateId from "../ExecutorRateId";
import InternalController from "./Controller";

const TaxiServiceId = react.withController<
	TaxiServiceId.PropsBase,
	TaxiServiceId.Controller
>(
	({
		value,
		onChange,

		disabled,
		error,
		language,

		companyId,

		executorRateIdOnChange,
		executorRateIdValue,
	}) => {
		const { t } = useTranslation();
		const { executorRate, taxiServices } = useExecutorContext();
		const [checkRate, setCheckRate] = useState(false);
		const selectOptions = useMemo<Option<number>[]>(
			() =>
				taxiServices
					.filter(
						(item) =>
							typeof companyId !== "number" ||
							item.company?.id === companyId,
					)
					.map((item) => ({
						key: item.id,
						label: item.settlement[language],
						value: item.id,
					})),
			[companyId, taxiServices, language],
		);

		const selectOptionByKey = useMemo(
			() => mapByKey(selectOptions, "key"),
			[selectOptions],
		);

		const changeExecutorRate = useCallback(
			(id?: number) => {
				if (
					taxiServices.length &&
					executorRate.length &&
					executorRateIdValue
				) {
					const taxiServicesIds = taxiServices.map((item) => item.id);
					if (id) {
						const first = executorRate?.find((item) => {
							if (
								!item.root &&
								item.default &&
								item.taxiServiceIds.includes(id) &&
								taxiServicesIds.length !==
									item.taxiServiceIds.length
							) {
								return true;
							}
							return false;
						});

						if (first) {
							executorRateIdOnChange?.(first.id);
							setCheckRate(false);
							return;
						}
					}

					if (id) {
						const base = executorRate?.find((item) => {
							if (
								!item.root &&
								item.default &&
								taxiServicesIds.every((id) =>
									item.taxiServiceIds.includes(id),
								)
							) {
								return true;
							}

							return false;
						});

						if (base) {
							executorRateIdOnChange?.(base.id);
							setCheckRate(false);
							return;
						}
					}

					const root = executorRate?.find(
						(item) => item.root === true,
					);

					if (root) {
						executorRateIdOnChange?.(root.id);
					}

					setCheckRate(false);
				}
			},
			[
				executorRate,
				executorRateIdOnChange,
				executorRateIdValue,
				taxiServices,
			],
		);

		const selectOnChange = useCallback(
			(key) => {
				const id = selectOptionByKey[key].value;
				onChange(id);
				setCheckRate(true);
			},
			[onChange, selectOptionByKey],
		);

		useLayoutEffect(() => {
			if (checkRate) changeExecutorRate(value);
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [executorRate]);

		return (
			<LabeledField
				label={
					t(
						"pages.mainPage.pages.accounts.tabs.executors.editModal.content.mainTab.workData.taxiServiceId.str0",
					) ?? ""
				}
			>
				<Select
					value={value}
					disabled={disabled}
					error={error}
					placeholder={
						t(
							"pages.mainPage.pages.accounts.tabs.executors.editModal.content.mainTab.workData.taxiServiceId.str0",
						) ?? ""
					}
					options={selectOptions}
					onChange={selectOnChange}
				/>
			</LabeledField>
		);
	},
	InternalController,
);

declare namespace TaxiServiceId {
	type Ref = InternalController | null;

	type Controller = InternalController;

	type Value = number | undefined;
	type NewValue = number;

	interface PropsBase {
		value: Value;
		onChange: Dispatch<NewValue>;

		disabled: boolean;
		error: boolean;
		language: Language;
		companyId?: number;

		executorRateIdValue: ExecutorRateId.Value;
		executorRateIdOnChange: ExecutorRateId.PropsBase["onChange"];
	}

	type Props = PropsBase & RefAttributes<Ref>;
}

export default TaxiServiceId;
