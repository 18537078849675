import { Dispatch, MutableRefObject } from "react";
import { react } from "uikit";
import Executor from "../../../../../../../../../../../../../../../../services/Executor";

interface Context {
	originalTaxiServiceId?: number;
	taxiServiceId?: number;
	originalValue?: string;
	value: string | undefined;
	currentCheckAliasPromiseRef: MutableRefObject<Promise<boolean> | null>;

	setError: Dispatch<boolean>;
}

export default class Controller extends react.ControllerBase<Context> {
	async validate() {
		if (!this.context?.value || !this.context?.taxiServiceId) {
			this.context?.setError(true);

			return false;
		}

		if (
			this.context.value === this.context.originalValue &&
			this.context.taxiServiceId === this.context.originalTaxiServiceId
		) {
			this.context.setError(false);

			return true;
		}

		const currentCheckAliasPromise = Executor.checkAlias({
			taxiServiceId: this.context?.taxiServiceId,
			alias: this.context?.value,
		});

		this.context.currentCheckAliasPromiseRef.current =
			currentCheckAliasPromise;

		const result = await this.context.currentCheckAliasPromiseRef.current;

		if (
			currentCheckAliasPromise !==
			this.context.currentCheckAliasPromiseRef.current
		) {
			return false;
		}

		if (!result) {
			this.context.setError(true);

			return false;
		}

		this.context.setError(false);

		return true;
	}
}
