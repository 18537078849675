import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { Role } from "../../../../../../../../../../../../services";
import { CellContentRoot } from "../../../../../../../../../../../../components/LightTable";

const AssignsCellContent: React.FC<AssignsCellContent.Props> = ({ item }) => {
	const { t } = useTranslation();

	const content = useMemo(() => {
		const messages = {
			dispatcher:
				"pages.mainPage.pages.accounts.tabs.roles.content.modelTable.str200",
			default:
				"pages.mainPage.pages.accounts.tabs.roles.content.modelTable.str201",
		};

		return (
			t(
				item.assignableTo === "dispatcher"
					? messages.dispatcher
					: messages.default,
			) ?? ""
		);
	}, [item.assignableTo, t]);

	return (
		<CellContentRoot alignItems="center" w="100%" h="100%">
			{content}
		</CellContentRoot>
	);
};

declare namespace AssignsCellContent {
	interface Props {
		item: Role.Model;
	}
}

export default AssignsCellContent;
