import React, {
	Dispatch,
	SetStateAction,
	useCallback,
	useLayoutEffect,
	useMemo,
} from "react";
import { ToggleButton } from "uikit";
import { RowDataType } from "rsuite-table";

import Counterparty from "../../../../../../../../../../../../../../../../../../../../services/Counterparty";
import {
	useColumns,
	useVisibleColumns,
} from "../../../../../../../../../../../../../../../../../../../../hooks/useTableSettings";
import LightTable, {
	useTableOptions,
} from "../../../../../../../../../../../../../../../../../../../../components/LightTable";
import TableSettings from "../../../../../../../../../../../../../../../../../../../../components/TableSettings";
import {
	StyledGrid,
	StyledGridItem,
	StyledRow,
	StyledSpan,
} from "../../../../../../../../../../../../../../../../../../../../components/common";

import Accounts from "../../../../../../../../../../../../../../../Counterparties/components/Modal/components/Content/tabs/Accounts";
import DisabledCheckbox from "../../../../../../../../../../../../../Content/components/DisabledCheckbox";
import BalanceOutput from "../../../../../../../../../../../../../Content/components/BalanceOutput";

const Table: React.FC<Table.Props> = ({
	tableData,
	selected,
	setSelected,
	additional,
}) => {
	const { sort, onSort, onScroll, tableRef, setDataLength } = useTableOptions(
		{
			itemLimit: 2,
		},
	);

	useLayoutEffect(() => {
		setDataLength(tableData.length);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [tableData.length]);

	const columns = useColumns("counterpartyCheck");
	const { columnIds, setColumnIds } = useVisibleColumns(
		"customers.agent.selectCounterpartyCheckTable",
		"counterpartyCheck",
	);

	const updateSelected = useCallback(
		(item: RowDataType<Counterparty.Model>) => {
			setSelected((prev) => (prev === item.id ? null : item.id));
		},
		[setSelected],
	);
	const getOrderCount = useCallback(({ rideCounter }: Accounts.Check) => {
		const total = rideCounter?.total ?? 0;
		const success = rideCounter?.success ?? 0;
		const failed = rideCounter?.failed ?? 0;
		return (
			<StyledRow alignItems="center">
				<StyledSpan font={{ fw: "700" }} colors="#03a9f4">
					{total}
				</StyledSpan>
				<StyledSpan font={{ fw: "700" }} colors="#647079" p="0 2px">
					/
				</StyledSpan>
				<StyledSpan font={{ fw: "700" }} colors="#4caf50">
					{success}
				</StyledSpan>
				<StyledSpan font={{ fw: "700" }} colors="#647079" p="0 2px">
					/
				</StyledSpan>
				<StyledSpan font={{ fw: "700" }} colors="#f83528">
					{failed}
				</StyledSpan>
			</StyledRow>
		);
	}, []);
	const data = useMemo(
		() =>
			columnIds.map((columnId) => {
				const column = columns.find((col) => col.id === columnId);
				if (!column) return null;

				return (
					<LightTable.Column
						resizable
						sortable
						key={columnId}
						verticalAlign="middle"
						flexGrow={1}
					>
						<LightTable.HeaderCell style={{ padding: "0 20px" }}>
							{column.label}
						</LightTable.HeaderCell>
						<LightTable.Cell
							verticalAlign="middle"
							style={{ padding: "0 20px" }}
							dataKey={column.id}
							fullText
						>
							{(rowData: RowDataType<Accounts.Check>) => {
								const model = rowData as Accounts.Check;

								switch (columnId) {
									case "status":
										return (
											<div
												style={{
													maxWidth: "max-content",
													pointerEvents: "none",
												}}
											>
												<ToggleButton.ToggleButton
													value={model.active}
													disabled
												/>
											</div>
										);

									case "edrpou":
										return additional?.edrpou;

									case "counterpartyName":
										return additional?.name;

									case "checkNumber":
										return model.additionalFields
											?.checkValue;

									case "balance":
										return (
											<BalanceOutput
												balanceType="main"
												value={model.paymentAccounts}
											/>
										);

									case "tripThreshold":
										return (
											<BalanceOutput
												balanceType="threshold"
												value={model?.paymentAccounts}
											/>
										);

									case "bonusBalance":
										return (
											<BalanceOutput
												balanceType="bonus"
												value={model.paymentAccounts}
											/>
										);

									case "orderCount":
										return <>{getOrderCount(model)}</>;

									case "discountPlan":
										return <></>;

									case "additionalDiscountPlan":
										return <></>;

									case "tariff":
										return <></>;

									case "registrationDate":
										return rowData.createdAt
											? new Date(
													rowData.createdAt,
											  ).toLocaleDateString()
											: new Date().toLocaleDateString();

									case "activationDate":
										return new Date(
											rowData.activationDate,
										).toLocaleDateString();

									case "firstTripDate":
										return <></>;

									case "lastTripDate":
										return <></>;

									case "mobileApp":
										return (
											<DisabledCheckbox
												value={
													model.accessAllEmployeeToPersonalApp
												}
											/>
										);

									case "corporateAccount":
										return (
											<DisabledCheckbox
												value={
													rowData.isAccessToCorporateAccount
												}
											/>
										);

									case "notes":
										return rowData.additionalFields?.notes;

									default:
										return null;
								}
							}}
						</LightTable.Cell>
					</LightTable.Column>
				);
			}),
		[
			additional?.edrpou,
			additional?.name,
			columnIds,
			columns,
			getOrderCount,
		],
	);

	const getRowClassName = useCallback(
		(item: RowDataType<Counterparty.Model>) =>
			item && selected === item.id ? "selected" : "",
		[selected],
	);

	const areas = useMemo(() => `'table' 'settings' `, []);
	return (
		<StyledGrid
			areas={areas}
			rows="1fr 32px"
			w={{ min: "1000px" }}
			h={{
				min: "500px",
				max: "600px",
			}}
			br="5px"
			over="hidden"
		>
			<StyledGridItem area="table" w="100%" h="100%" tabIndex={-1}>
				<LightTable
					ref={tableRef}
					data={tableData}
					virtualized
					fillHeight
					rowHeight={44}
					shouldUpdateScroll={false}
					rowClassName={getRowClassName}
					onRowClick={updateSelected}
					sortColumn={sort?.dataKey}
					sortType={sort?.sortType}
					onScroll={onScroll}
					onSortColumn={onSort}
				>
					{data}
				</LightTable>
			</StyledGridItem>
			<StyledGridItem area="settings" w="100%" h="100%" zi="1">
				<TableSettings
					value={columnIds}
					defaultValue={columnIds}
					columns={columns}
					onChange={setColumnIds}
				/>
			</StyledGridItem>
		</StyledGrid>
	);
};

declare namespace Table {
	interface Props {
		tableData: Accounts.Check[];
		additional?: Counterparty.Model["additionalFields"];
		selected: number | null;
		setSelected: Dispatch<SetStateAction<number | null>>;
	}
}

export default Table;
