import React, { Dispatch, memo, RefAttributes } from "react";
import { FieldErrors } from "react-hook-form";
import { Flex, react, useRefWithSetter } from "uikit";

import { useTypedSelector } from "../../../../../../../../../../redux/store";
import useObjectEditor from "../../../../../../../../../../hooks/useObjectEditor";
import {
	StyledRow,
	SuspenseLoader,
	Tab,
} from "../../../../../../../../../../components/common";
import { Tabs } from "../../constants/tabs";
import Header from "../Header";

import ExecutorSelectTab from "./tabs/ExecutorSelectTab";
import OrderCancellation from "./tabs/OrderCancellation";
import Main from "./tabs/Main";
import InternalController from "./Controller";

const ContentBase = react.withController<Content.PropsBase, Content.Controller>(
	({ value, onChange, tab, controller }) => {
		const language = useTypedSelector((state) => state.session.language);

		const [mainRef, setMainRef] = useRefWithSetter<Main.Ref | null>(null);

		controller.setContext({ mainRef });

		const valueEditor = useObjectEditor(value, onChange);

		const mainTabValue = valueEditor.usePicker([
			"taxiServices",
			"taxiServiceIds",
			"companyIds",
			"name",
			"executorAppId",
			"priority",
			"isAllowToSelectCarClassesInApp",
			"configuration",
		]);

		const executorsTabValue = valueEditor.usePicker([
			"executorIds",
			"taxiServiceIds",
			"companyIds",
			"id",
		]);

		const assigner = valueEditor.useAssigner();

		return (
			<StyledRow w="100%" h="100%" overY="scroll">
				<Tab visible={tab === Tabs.MAIN}>
					<Main
						ref={setMainRef}
						value={mainTabValue}
						onChange={assigner}
						language={language}
					/>
				</Tab>
				<Tab visible={tab === Tabs.ORDER_CANCELLATION}>
					<OrderCancellation value={[]} onChange={() => {}} />
				</Tab>
				<Tab visible={tab === Tabs.EXECUTORS}>
					<ExecutorSelectTab
						value={executorsTabValue}
						disabled={false}
						visible={true}
						language={language}
						onChange={assigner}
					/>
				</Tab>
				<Tab visible={tab === Tabs.HISTORY}>
					<Flex
						align="center"
						justify="center"
						maxedWidth
						maxedHeight
					>
						<SuspenseLoader />
					</Flex>
				</Tab>
			</StyledRow>
		);
	},
	InternalController,
);

const Content = memo(ContentBase);
declare namespace Content {
	type Ref = InternalController | null;
	type Controller = InternalController;
	interface PropsBase {
		value: Value;
		onChange: Dispatch<any>;
		tab: Header.TabType;
		errors?: FieldErrors<any>;
	}

	type Props = PropsBase & RefAttributes<Ref>;

	interface Value extends Main.Value, ExecutorSelectTab.Value {}
}

export default Content;
