import React, {
	RefAttributes,
	memo,
	useCallback,
	useMemo,
	useState,
} from "react";
import { useTranslation } from "react-i18next";
import { Select, react } from "uikit";
import { BlacklistPhoneLevel, blacklistPhoneLevelOptions } from "./constants";
import tPath from "../../../../../../../../constants/tPath";
import LabeledField from "../../../../../../../../../../../../../../components/LabeledField";
import InternalController from "./Controller";

const path = `${tPath}.modal.tabs.main.level`;

const LevelBase = react.withController<Level.PropsBase, Level.Controller>(
	({ level, setLevel, controller }) => {
		const [error, setError] = useState(false);
		controller.setContext({ value: level, setError });

		const { t } = useTranslation();

		const handleLevelChange = useCallback(
			(newLevel) => {
				setError(false);
				setLevel(newLevel);
			},
			[setLevel],
		);

		const tOptions = useMemo(
			() =>
				blacklistPhoneLevelOptions
					.filter((item) => item.key !== BlacklistPhoneLevel.PHONE)
					.map((o) => ({
						...o,
						label: t(`${path}.options.${o.key}`),
					})),
			[t],
		);

		return (
			<LabeledField label={t(`${path}.title`) || ""}>
				<Select
					error={error && (t(`${path}.error`) as string)}
					placeholder={t(`${path}.placeholder`) || ""}
					value={level ?? undefined}
					onChange={handleLevelChange}
					options={tOptions}
				/>
			</LabeledField>
		);
	},
	InternalController,
);

const Level = memo(LevelBase);

declare namespace Level {
	type Ref = InternalController | null;
	type Controller = InternalController;

	interface PropsBase {
		level: Value;
		setLevel: (value: Value) => void;
	}
	type Props = PropsBase & RefAttributes<Ref>;

	type Value = BlacklistPhoneLevel | undefined;
}

export default Level;
