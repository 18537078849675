import React, { Dispatch, memo, useMemo } from "react";
import { Row } from "uikit";
import { useTranslation } from "react-i18next";

import ExecutorRate from "../../services/ExecutorRate";
import { PaymentProviderAccessorType } from "../../services/Payment/types";
import { PaymentAccount } from "../../types/PaymentAccount";
import useObjectEditor from "../../hooks/useObjectEditor";
import { ITransaction } from "../../types/ITransaction";
import FieldsContainer from "../FieldsContainer";
import LabeledField from "../LabeledField";

import Transaction from "./components/Transaction";
import Balance from "./components/Balance";

const Account: React.FC<Account.Props> = memo((props) => {
	const {
		sizes,
		label,
		tPath,
		entityId,
		ownerId,
		owner,
		value,
		onChange,
		rate,
	} = props;

	const { t } = useTranslation();

	const editor = useObjectEditor(value, onChange);

	const transactions = editor.useGetter("paymentTransactions");
	const setTransactions = editor.useSetter("paymentTransactions");

	const accounts = useMemo(
		() => value.paymentAccounts ?? [],
		[value.paymentAccounts],
	);

	const main = useMemo(() => {
		const mainAccount = accounts.find((item) => item.type === "main");
		if (!mainAccount && transactions) {
			const mainTransactions = transactions?.main?.reduce(
				(acc, item) => (acc += item?.amount ?? 0),
				0,
			);
			return mainTransactions ?? 0;
		}
		return mainAccount?.amount ?? 0;
	}, [accounts, transactions]);

	const colorMainBalance = useMemo(() => {
		if (!rate || !rate?.active || !rate?.amountOfMoneyToBlock?.active) {
			return "";
		}
		const { amountOfMoneyToBlock } = rate;

		if (amountOfMoneyToBlock.value >= main) return "red";

		return "";
	}, [rate, main]);

	return (
		<FieldsContainer label={label ?? (t(`accountBase`) || "")}>
			<Row gaps="20px*" sizes={sizes ?? "1fr 1fr"}>
				<Row gaps="10px*" sizes="1fr*">
					{transactions
						? Object.keys(transactions).map((key) => (
								<LabeledField
									key={key}
									label={
										t(`${tPath ?? "balances"}.${key}`) || ""
									}
								>
									<Transaction
										value={transactions[key]}
										type={key}
										onChange={(val: ITransaction[]) => {
											setTransactions({
												...transactions,
												[key]: val,
											});
										}}
										colorMainBalance={
											key === "main"
												? colorMainBalance
												: ""
										}
									/>
								</LabeledField>
						  ))
						: accounts.map((account, i) => (
								<LabeledField
									key={i}
									label={
										t(
											`${tPath ?? "balances"}.${
												account.type
											}`,
										) || ""
									}
								>
									<Balance
										disabled={!entityId}
										ownerId={ownerId}
										owner={owner}
										label={t("account.str0") ?? ""}
										value={account}
										colorMainBalance={
											account.type === "main"
												? colorMainBalance
												: ""
										}
									/>
								</LabeledField>
						  ))}
				</Row>
				<div />
			</Row>
		</FieldsContainer>
	);
});

declare namespace Account {
	interface Props {
		label?: string;
		sizes?: string;
		tPath?: string;
		entityId?: number;
		owner: PaymentProviderAccessorType;
		ownerId: number | undefined;
		value: Value;
		onChange: Dispatch<Value>;
		rate?: ExecutorRate.Model | undefined;
	}

	type Value = {
		paymentAccounts: PaymentAccount[];
		paymentTransactions?: Partial<
			Record<PaymentAccount["type"], ITransaction[]>
		>;
	};
}

export default Account;
