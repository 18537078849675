/* eslint-disable no-shadow */

import React, {
	Dispatch,
	useCallback,
	useLayoutEffect,
	useMemo,
	useState,
} from "react";
import { useTranslation } from "react-i18next";
import { InputBorders, useInternal } from "uikit";
import PropsOf from "uikit/src/types/PropsOf";
import { isUndefined } from "lodash";

import CarBrand from "../../services/CarBrand";
import useModelSubscribe from "../../hooks/useModelSubscribe2";
import MountableSubscription from "../MountableSubscription";
import LabelBox from "../LabelBox";
import SelectModal from "../SelectModal2";

// eslint-disable-next-line prettier/prettier
const CarBrandSelect: React.FC<CarBrandSelect.Props> = ({
	value,
	disabled,
	carBaseTypeId,
	onChange,
	...props
}) => {
	const { t } = useTranslation();
	const [internalValue, setInternalValue] = useInternal(value);

	const [showModal, setShowModal] = useState(false);

	const [carBrandMetadata, setCarBrandMetadata] = useState<{ total: number }>(
		{ total: 0 },
	);
	const [carBrands, setCarBrands] = useState<CarBrand.Model[]>([]);
	const [filters, setFilters] = useState<SelectModal.Filters>({});
	const [loading, setLoading] = useState(true);

	const currentCarBrandData = useModelSubscribe(
		{ ids: isUndefined(value) ? [] : [value] },
		CarBrand,
	);

	const subscriptionOptions = useMemo<CarBrand.SubscribeOptions>(
		() => ({
			query: filters.search,
			offset: filters.offset,
			limit: filters.count,

			carBaseTypeIds: isUndefined(carBaseTypeId)
				? undefined
				: [carBaseTypeId],

			order: {
				name: "ASC",
			},
		}),
		[carBaseTypeId, filters.count, filters.offset, filters.search],
	);

	const valueText = useMemo(
		() => currentCarBrandData.models.at(0)?.name,
		[currentCarBrandData.models],
	);

	const rootOnClick = useCallback(() => {
		if (!disabled) {
			setShowModal(true);
			setLoading(true);
		}
	}, [disabled]);

	const modalOnSubmit = useCallback(
		(value: SelectModal.Value) => {
			onChange?.(value as number);
			setInternalValue(value as number);
			setShowModal(false);
		},
		[onChange, setInternalValue],
	);

	const modalOnClose = useCallback(() => setShowModal(false), []);

	const options = useMemo(
		() =>
			Array(filters.offset)
				.fill(null)
				.concat(
					carBrands.map((carBrand) => ({
						key: carBrand.id,
						value: carBrand.id,
						label: carBrand.name,
					})),
				)
				.concat(
					Array(
						Math.max(
							carBrandMetadata.total -
								(filters.offset ?? 0) -
								carBrands.length,
							0,
						),
					).fill(null),
				),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[carBrands],
	);

	useLayoutEffect(() => {
		setLoading(false);
	}, [options]);

	return (
		<>
			<LabelBox
				{...props}
				placeholder={t("carBrandSelect.str0") ?? ""}
				disabled={disabled}
				onClick={rootOnClick}
			>
				{valueText}
			</LabelBox>
			{showModal && (
				<>
					<SelectModal
						value={internalValue}
						title={t("carBrandSelect.str0") ?? ""}
						options={options}
						loading={loading}
						onSubmit={modalOnSubmit}
						onClose={modalOnClose}
						onChangeFilters={setFilters}
					/>
					<MountableSubscription
						subscribeOptions={subscriptionOptions}
						Service={CarBrand}
						onModelsChange={setCarBrands}
						onMetadataChange={setCarBrandMetadata}
					/>
				</>
			)}
		</>
	);
};

declare namespace CarBrandSelect {
	type Value = number | undefined;

	export interface PropsBase {
		value?: Value;

		carBaseTypeId?: number;

		onChange?: Dispatch<Value>;
	}

	type Props = Omit<
		PropsOf<typeof InputBorders.InputBorders>,
		keyof PropsBase
	> &
		PropsBase;
}

export default CarBrandSelect;
