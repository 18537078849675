import React, { Dispatch, RefAttributes, useMemo } from "react";
import { react, useRefWithSetter } from "uikit";

import Language from "../../../../../../../../../../services/Language";
import useObjectEditor from "../../../../../../../../../../hooks/useObjectEditor";

import InternalController from "./Controller";
import Root from "./components/Root";
import MainTab from "./components/MainTab";
import HistoryTab from "./components/HistoryTab";
import OptionTab from "./components/OptionTab";
import AdditionalDataTab from "./components/AdditionalDataTab";
import FilesTab from "./components/FilesTab";
import CarSelectTab from "./components/CarSelectTab";
import OrderTab from "./components/OrderTab";
import Transactions from "./components/Transactions";
import ClientsTab from "./components/ClientsTab";
import FeedbackTab from "./components/FeedbackTab";

const Content = react.withController<Content.PropsBase, Content.Controller>(
	({
		controller,

		value,
		onChange,

		executorId,
		type,
		disabled,
		history,
		language,
		originalTaxiServiceId,
		originalAlias,
	}) => {
		const [mainTabRef, setMainTabRef] =
			useRefWithSetter<MainTab.Ref | null>(null);

		controller.setContext({ mainTabRef });

		const valueEditor = useObjectEditor(value, onChange);

		const taxiServiceId = valueEditor.useGetter("taxiServiceId");
		const mainTabValue = valueEditor.usePicker([
			"id",

			"paymentAccounts",
			"paymentTransactions",
			"lastName",
			"firstName",
			"fatherName",
			"phones",
			"login",
			"password",
			"priority",

			"alias",
			"taxiServiceId",
			"roleIds",
			"responsibleDispatcherId",
			"workShiftId",
			"executorTeamId",
			"executorRateId",

			"notes",
			"carIds",
		]);

		const optionTabValue = valueEditor.usePicker([
			"serviceIds",
			"permits",
			"taxiServiceId",
		]);

		const carSelectTabValue = valueEditor.usePicker([
			"id",
			"carIds",
			"taxiServiceId",
		]);

		const additionalDataValue = valueEditor.usePicker([
			"passportSeries",
			"passportNumber",
			"passportIssuedAt",
			"passportIssuedBy",
			"taxId",
			"birthAt",
			"residenceAddress",
			"livingAddress",
			"passportFiles",

			"drivingLicenseSeries",
			"drivingLicenseNumber",
			"drivingLicenseCategory",
			"drivingLicenseIssuedAt",
			"drivingExperience",
			"drivingLicenseFiles",

			"bankCardNumber",
			"bankCardExpiresAt",
			"bankCardNote",

			"IESeries",
			"IECertificateNumber",
			"IERegisteredAt",
			"IEUSREOU",
			"IEIBAN",
			"IEFiles",
		]);

		// const executorSelectTabValue = valueEditor.usePicker(["executorIds"]);

		// const taxiServiceId = valueEditor.useGetter("taxiServiceId");

		const filesTabValue = valueEditor.usePicker([
			"otherFiles",
			"passportFiles",
			"taxIdFiles",
			"drivingLicenseFiles",
			"IEFiles",
			"avatarFiles",
			"licenseFiles",
		]);

		const orderTabValue = valueEditor.usePicker(["id"]);

		const anyTabOnChange = valueEditor.useAssigner();

		const content = useMemo(
			() => (
				<>
					<MainTab
						ref={setMainTabRef}
						executorId={executorId}
						value={mainTabValue}
						disabled={disabled}
						visible={type === "main"}
						language={language}
						originalTaxiServiceId={originalTaxiServiceId}
						originalAlias={originalAlias}
						onChange={anyTabOnChange}
					/>
					<OptionTab
						value={optionTabValue}
						disabled={disabled}
						visible={type === "options"}
						language={language}
						roleIds={value.roleIds}
						onChange={anyTabOnChange}
					/>
					<AdditionalDataTab
						value={additionalDataValue}
						disabled={disabled}
						visible={type === "additional-data"}
						onChange={anyTabOnChange}
					/>
					<CarSelectTab
						value={carSelectTabValue}
						disabled={disabled}
						visible={type === "cars"}
						language={language}
						allowedTaxiServiceIds={
							taxiServiceId ? [taxiServiceId] : []
						}
						onChange={anyTabOnChange}
					/>
					<FilesTab
						value={filesTabValue}
						disabled={disabled}
						visible={type === "files"}
						onChange={anyTabOnChange}
					/>
					<FeedbackTab
						executorId={executorId}
						language={language}
						visible={type === "comments"}
					/>
					<OrderTab
						value={orderTabValue}
						visible={type === "orders"}
					/>
					<Transactions
						tableFrom="accounts.executors.transactions"
						visible={type === "transactions"}
						paymentAccounts={mainTabValue.paymentAccounts}
					/>
					<HistoryTab value={history} visible={type === "history"} />
					<ClientsTab visible={type === "customers"} />
				</>
			),
			[
				setMainTabRef,
				executorId,
				mainTabValue,
				disabled,
				type,
				language,
				originalTaxiServiceId,
				originalAlias,
				anyTabOnChange,
				optionTabValue,
				value.roleIds,
				additionalDataValue,
				carSelectTabValue,
				filesTabValue,
				orderTabValue,
				history,
				taxiServiceId,
			],
		);

		return <Root>{content}</Root>;
	},
	InternalController,
);

declare namespace Content {
	type Ref = InternalController | null;

	type Controller = InternalController;

	type Type =
		| "main"
		| "options"
		| "additional-data"
		| "cars"
		| "files"
		| "comments"
		| "orders"
		| "transactions"
		| "history"
		| "customers";

	type Value = MainTab.Value &
		OptionTab.Value &
		AdditionalDataTab.Value &
		CarSelectTab.Value &
		FilesTab.Value;
	// OptionTab.Value &
	// AdditionalDataTab.Value &
	// ExecutorSelectTab.Value &
	// FilesTab.Value;

	interface PropsBase {
		executorId?: number;
		value: Value;

		type: Type;
		disabled: boolean;
		language: Language;
		originalTaxiServiceId?: number;
		originalAlias?: string;
		history: HistoryTab.Value;

		onChange: Dispatch<Value>;
	}

	type Props = PropsBase & RefAttributes<Ref>;
}

export default Content;
