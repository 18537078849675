/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable no-param-reassign */
/* eslint-disable no-shadow */

import React, { Dispatch, RefAttributes, memo, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Column, DatePicker, Row, TextBox, react } from "uikit";
import File from "../../../../../../../../../../../../services/File";
import useObjectEditor from "../../../../../../../../../../../../hooks/useObjectEditor";
import useDatePickerLocale from "../../../../../../../../../../../../hooks/useDatePickerLocale";
import Nullable from "../../../../../../../../../../../../types/Nullable";

import FileListWithTitle from "../../../../../../../../../../../../components/FileListWithTitle";
import LabeledField from "../../../../../../../../../../../../components/LabeledField";
import FieldsContainer from "../../../../../../../../../../../../components/FieldsContainer";

import TabRoot from "../TabRoot";
import InternalController from "./Controller";

const PassportTab = memo(
	react.withController<PassportTab.PropsBase, PassportTab.Controller>(
		({ value, disabled, visible, onChange }) => {
			const { t } = useTranslation();
			const locale = useDatePickerLocale();
			const valueEditor = useObjectEditor(value, onChange);

			const seriesOnChange = useCallback(
				(series: string) => {
					if (series.length > 2) series = series.substring(0, 2);

					valueEditor.set("series", series);
				},
				[valueEditor],
			);

			const numberOnChange = useCallback(
				(number: string) => {
					valueEditor.set("number", number);
				},
				[valueEditor],
			);

			const issuedByOnChange = useCallback(
				(issuedBy: string) => {
					valueEditor.set("issuedBy", issuedBy);
				},
				[valueEditor],
			);

			const issuedAtOnChange = useCallback(
				(issuedAt: Nullable<Date>) => {
					valueEditor.set("issuedAt", issuedAt || undefined);
				},
				[valueEditor],
			);

			const birthAtOnChange = useCallback(
				(birthAt: Nullable<Date>) => {
					valueEditor.set("birthAt", birthAt || undefined);
				},
				[valueEditor],
			);

			const taxIdOnChange = useCallback(
				(taxId: string) => {
					valueEditor.set("taxId", taxId);
				},
				[valueEditor],
			);

			const residenceAddressOnChange = useCallback(
				(residenceAddress: string) => {
					valueEditor.set("residenceAddress", residenceAddress);
				},
				[valueEditor],
			);

			const livingAddressOnChange = useCallback(
				(livingAddress: string) => {
					valueEditor.set("livingAddress", livingAddress);
				},
				[valueEditor],
			);

			const passportFilesOnChange = useCallback(
				(passportFiles: File.Model[]) => {
					passportFiles.forEach((file) => file.upload());

					valueEditor.set("passportFiles", passportFiles);
				},
				[valueEditor],
			);

			return (
				<TabRoot hasPaddings visible={visible}>
					<Column
						gaps="8px* 24px"
						sizes="auto! 1fr"
						maxedWidth
						maxedHeight
					>
						<FieldsContainer
							label={
								t(
									"pages.mainPage.pages.accounts.tabs.users.editModal.content.passportTab.str0",
								) ?? ""
							}
						>
							<Column gaps="8px*">
								<Row gaps="8px*" sizes="100px 1fr 1fr">
									<LabeledField
										label={
											t(
												"pages.mainPage.pages.accounts.tabs.users.editModal.content.passportTab.str1",
											) ?? ""
										}
									>
										<TextBox.TextBox
											value={valueEditor.get("series")}
											disabled={disabled}
											maxLength={2}
											style={{
												minHeight: "32px",
												flex: "1 0 0",
											}}
											placeholder={
												t(
													"pages.mainPage.pages.accounts.tabs.users.editModal.content.passportTab.str1",
												) ?? ""
											}
											onChange={seriesOnChange}
										/>
									</LabeledField>
									<LabeledField
										label={
											t(
												"pages.mainPage.pages.accounts.tabs.users.editModal.content.passportTab.str3",
											) ?? ""
										}
									>
										<TextBox.TextBox
											value={valueEditor.get("number")}
											disabled={disabled}
											maxLength={9}
											style={{
												minHeight: "32px",
												flex: "1 0 0",
											}}
											placeholder={
												t(
													"pages.mainPage.pages.accounts.tabs.users.editModal.content.passportTab.str3",
												) ?? ""
											}
											onChange={numberOnChange}
										/>
									</LabeledField>

									<LabeledField
										label={
											t(
												"pages.mainPage.pages.accounts.tabs.users.editModal.content.passportTab.str5",
											) ?? ""
										}
									>
										<TextBox.TextBox
											value={valueEditor.get("issuedBy")}
											disabled={disabled}
											style={{
												minHeight: "32px",
												flex: "1 0 0",
											}}
											placeholder={
												t(
													"pages.mainPage.pages.accounts.tabs.users.editModal.content.passportTab.str5",
												) ?? ""
											}
											onChange={issuedByOnChange}
										/>
									</LabeledField>
								</Row>
								<Row gaps="8px*" sizes="128px 128px 1fr">
									<LabeledField
										label={
											t(
												"pages.mainPage.pages.accounts.tabs.users.editModal.content.passportTab.str7",
											) ?? ""
										}
									>
										<DatePicker
											value={valueEditor.get("issuedAt")}
											disabled={disabled}
											locale={locale}
											onChange={issuedAtOnChange}
										/>
									</LabeledField>
									<LabeledField
										label={
											t(
												"pages.mainPage.pages.accounts.tabs.users.editModal.content.passportTab.str8",
											) ?? ""
										}
									>
										<DatePicker
											value={valueEditor.get("birthAt")}
											disabled={disabled}
											locale={locale}
											onChange={birthAtOnChange}
										/>
									</LabeledField>
									<LabeledField
										label={
											t(
												"pages.mainPage.pages.accounts.tabs.users.editModal.content.passportTab.str9",
											) ?? ""
										}
									>
										<TextBox.TextBox
											value={valueEditor.get("taxId")}
											disabled={disabled}
											style={{
												minHeight: "32px",
												flex: "1 0 0",
											}}
											placeholder={
												t(
													"pages.mainPage.pages.accounts.tabs.users.editModal.content.passportTab.str9",
												) ?? ""
											}
											onChange={taxIdOnChange}
										/>
									</LabeledField>
								</Row>
								<Row align="end" gaps="8px*" sizes="1fr*">
									<LabeledField
										label={
											t(
												"pages.mainPage.pages.accounts.tabs.users.editModal.content.passportTab.str11",
											) ?? ""
										}
									>
										<TextBox.TextBox
											value={valueEditor.get(
												"residenceAddress",
											)}
											disabled={disabled}
											style={{
												minHeight: "32px",
												flex: "1 0 0",
											}}
											placeholder={
												t(
													"pages.mainPage.pages.accounts.tabs.users.editModal.content.passportTab.str11",
												) ?? ""
											}
											onChange={residenceAddressOnChange}
										/>
									</LabeledField>
									<LabeledField
										label={
											t(
												"pages.mainPage.pages.accounts.tabs.users.editModal.content.passportTab.str13",
											) ?? ""
										}
									>
										<TextBox.TextBox
											value={valueEditor.get(
												"livingAddress",
											)}
											disabled={disabled}
											style={{
												minHeight: "32px",
												flex: "1 0 0",
											}}
											placeholder={
												t(
													"pages.mainPage.pages.accounts.tabs.users.editModal.content.passportTab.str13",
												) ?? ""
											}
											onChange={livingAddressOnChange}
										/>
									</LabeledField>
								</Row>
							</Column>
						</FieldsContainer>
						<FileListWithTitle
							title={
								t(
									"pages.mainPage.pages.accounts.tabs.users.editModal.content.passportTab.str15",
								) ?? ""
							}
							disabled={disabled}
							value={valueEditor.get("passportFiles")}
							onChange={passportFilesOnChange}
						/>
					</Column>
				</TabRoot>
			);
		},
		InternalController,
	),
);

declare namespace PassportTab {
	type Ref = InternalController | null;

	type Controller = InternalController;

	interface Value {
		series: string;
		number: string;
		issuedAt?: Date;
		issuedBy: string;
		taxId: string;
		birthAt?: Date;
		residenceAddress: string;
		livingAddress: string;
		passportFiles: File.Model[];
	}

	interface PropsBase {
		value: Value;

		disabled: boolean;
		visible: boolean;

		onChange: Dispatch<Value>;
	}

	type Props = PropsBase & RefAttributes<Ref>;
}

export default PassportTab;
