import React, { Dispatch, memo, useCallback } from "react";
import { Row, TextBox, CheckBox, Column } from "uikit";
import { useTranslation } from "react-i18next";

import Counterparty from "../../../../../../../../../../../../../../../../../services/Counterparty";
import useObjectEditor from "../../../../../../../../../../../../../../../../../hooks/useObjectEditor";
import FieldsContainer from "../../../../../../../../../../../../../../../../../components/FieldsContainer";

import tPath from "../../../../../../../../constants/tPath";

const Permissions: React.FC<Permissions.Props> = memo(({ value, onChange }) => {
	const { t } = useTranslation();

	const valueEditor = useObjectEditor(value, onChange);

	const corporateApp = valueEditor.useGetter("isAccessToCorporateApp");
	const setCorporateApp = valueEditor.useSetter("isAccessToCorporateApp");

	const corporateAccount = valueEditor.useGetter(
		"isAccessToCorporateAccount",
	);
	const setCorporateAccount = valueEditor.useSetter(
		"isAccessToCorporateAccount",
	);

	const isOneTimePassword = valueEditor.useGetter("isOneTimePassword");
	const setIsOneTimePassword = valueEditor.useSetter("isOneTimePassword");

	const login = valueEditor.useGetter("login");
	const setLogin = valueEditor.useSetter("login");

	const password = valueEditor.useGetter("password");
	const setPassword = valueEditor.useSetter("password");

	const oneTimePassword = valueEditor.useGetter("oneTimePassword");
	const setOneTimePassword = valueEditor.useSetter("oneTimePassword");

	const getOneTimePassword = useCallback(async () => {
		if (oneTimePassword) return null;
		const newOneTimePassword = await Counterparty.generateOneTimePassword();
		return setOneTimePassword(newOneTimePassword);
	}, [oneTimePassword, setOneTimePassword]);

	const onChangeIsOneTimePassword = useCallback(
		(value) => {
			setIsOneTimePassword(value);
			if (value) {
				getOneTimePassword();
			}
		},
		[getOneTimePassword, setIsOneTimePassword],
	);

	return (
		<FieldsContainer
			gaps="10px*"
			label={t(`${tPath}.modal.tabs.main.options.title`) || ""}
		>
			<Row justify="space-between" align="start">
				<Column gaps="10px*" sizes="1fr*2" maxedWidth>
					<Row gaps="10px*" sizes="200px*2">
						<TextBox.TextBox
							value={login ?? ""}
							onChange={setLogin}
							autoComplete="one-time-code"
							placeholder={
								t(`${tPath}.modal.tabs.main.options.login`) ||
								""
							}
						/>
						<TextBox.TextBox
							value={password ?? ""}
							onChange={setPassword}
							autoComplete="one-time-code"
							placeholder={
								t(
									`${tPath}.modal.tabs.main.options.password`,
								) || ""
							}
						/>
						<CheckBox
							label={
								t(
									`${tPath}.modal.tabs.main.options.mobileApp`,
								) || ""
							}
							value={corporateApp}
							onChange={setCorporateApp}
						/>
						<CheckBox
							disabled
							label={
								t(
									`${tPath}.modal.tabs.main.options.isAccessToCorporateAccount`,
								) || ""
							}
							value={corporateAccount}
							onChange={setCorporateAccount}
						/>
					</Row>
					<Column>
						<Row justify="start" align="center" gaps="8px">
							<CheckBox
								label={
									t(
										`${tPath}.modal.tabs.main.options.isOneTimePassword`,
									) || ""
								}
								value={isOneTimePassword}
								onChange={onChangeIsOneTimePassword}
							/>
							<TextBox.TextBox
								placeholder={
									t(
										"pages.mainPage.pages.customers.tabs.counterparties.tabs.employees.modal.content.tabs.main.permissions.str0",
									) ?? ""
								}
								disabled
								value={oneTimePassword}
							/>
						</Row>
					</Column>
				</Column>
			</Row>
		</FieldsContainer>
	);
});

declare namespace Permissions {
	interface Props {
		value: Value;
		onChange: Dispatch<Value>;
	}

	interface Value {
		isAccessToCorporateApp: boolean;
		isAccessToCorporateAccount: boolean;
		login?: string;
		password?: string;
		isOneTimePassword?: boolean;
		oneTimePassword?: string;
	}
}

export default Permissions;
