import styled from "styled-components";

const Wrapper = styled.div`
	position: relative;
	display: flex;
	gap: 3px;

	button.primary,
	button.disabled {
		outline: none;
		border-radius: 5px 0 0 5px;
	}

	.call__icon_button {
		cursor: pointer;
		padding: 0;
		border-radius: 0;
		background: #ebebeb;
		border-radius: 0 5px 5px 0;
		width: 14px;
		display: flex;
		justify-content: center;
		align-items: center;
		color: #647079;
		border: 1px solid rgba(33, 51, 63, 0.1);
		outline: none;

		&[disabled] {
			color: #9ba3a866;
			background-color: white;
		}

		* {
			outline: none;
		}
	}
`;

export default Wrapper;
