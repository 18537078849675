import Schedule from "../../../../../../../../../../../../../../services/Schedule";

interface Column {
	id: string;
	label: string;
}

export const columns: Column[] = [
	{
		id: "days",
		label: "Дни недели",
	},
	{
		id: "from",
		label: "Время с",
	},
	{
		id: "to",
		label: "Время до",
	},
];

export const defaultColumnIds = ["days", "from", "to"];

export const days: Schedule.Model.Day[] = [
	"monday",
	"tuesday",
	"wednesday",
	"thursday",
	"friday",
	"saturday",
	"sunday",
];

export default { columns, defaultColumnIds, days };
