/* eslint-disable react-hooks/rules-of-hooks */
import React, { Dispatch, RefAttributes, memo, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Column, MultilineTextBox, react, useRefWithSetter } from "uikit";

import { PaymentProviderAccessorType } from "../../../../../../../../../../../../services/Payment/types";
import Language from "../../../../../../../../../../../../services/Language";
import FieldsContainer from "../../../../../../../../../../../../components/FieldsContainer";
import useObjectEditor from "../../../../../../../../../../../../hooks/useObjectEditor";
import Account from "../../../../../../../../../../../../components/Account";
import { useExecutorContext } from "../../../../context";
import TabRoot from "../TabRoot";

import InternalController from "./Controller";
import PersonData from "./components/PersonData";
import WorkData from "./components/WorkData";

const MainTab = memo(
	react.withController<MainTab.PropsBase, MainTab.Controller>(
		({
			controller,

			value,

			executorId,
			disabled,
			visible,
			language,
			originalTaxiServiceId,
			originalAlias,

			onChange,
		}) => {
			const { t } = useTranslation();
			const { executorRate } = useExecutorContext();

			const [personDataRef, setPersonDataRef] =
				useRefWithSetter<PersonData.Ref | null>(null);
			const [workDataRef, setWorkDataRef] =
				useRefWithSetter<WorkData.Ref | null>(null);

			controller.setContext({ personDataRef, workDataRef });

			const valueEditor = useObjectEditor(value, onChange);

			const accountValue = valueEditor.usePicker([
				"paymentAccounts",
				"paymentTransactions",
			]);

			const personDataValue = valueEditor.usePicker([
				"lastName",
				"firstName",
				"fatherName",

				"phones",

				"login",
				"password",

				"priority",
			]);

			const workDataValue = valueEditor.usePicker([
				"id",
				"alias",
				"taxiServiceId",
				"roleIds",
				"responsibleDispatcherId",
				"workShiftId",
				"executorTeamId",
				"executorRateId",
				"carIds",
			]);

			const assignValue = valueEditor.useAssigner();

			const notesValue = valueEditor.useGetter("notes");

			const notesOnChange = valueEditor.useSetter("notes");

			const executorRateIdValue = valueEditor.useGetter("executorRateId");

			const rate = useMemo(
				() =>
					executorRate.find(
						(item) => item.id === executorRateIdValue,
					),
				[executorRate, executorRateIdValue],
			);

			return (
				<TabRoot hasPaddings visible={visible}>
					<Column sizes="auto!*" gaps="19px*" maxedWidth maxedHeight>
						<FieldsContainer
							label={
								t(
									"pages.mainPage.pages.accounts.tabs.executors.editModal.content.mainTab.str0",
								) ?? ""
							}
						>
							<PersonData
								ref={setPersonDataRef}
								value={personDataValue}
								disabled={disabled}
								onChange={assignValue}
							/>
						</FieldsContainer>
						<Account
							entityId={executorId}
							owner={PaymentProviderAccessorType.TAXI_SERVICE}
							ownerId={workDataValue.taxiServiceId}
							value={accountValue}
							onChange={assignValue}
							rate={rate}
						/>
						<FieldsContainer
							label={
								t(
									"pages.mainPage.pages.accounts.tabs.executors.editModal.content.mainTab.str1",
								) ?? ""
							}
						>
							<WorkData
								ref={setWorkDataRef}
								value={workDataValue}
								disabled={disabled}
								language={language}
								originalTaxiServiceId={originalTaxiServiceId}
								originalAlias={originalAlias}
								onChange={assignValue}
							/>
						</FieldsContainer>
						<MultilineTextBox
							value={notesValue}
							disabled={disabled}
							placeholder={
								t(
									"pages.mainPage.pages.accounts.tabs.executors.editModal.content.mainTab.str2",
								) ?? ""
							}
							onChange={notesOnChange}
						/>
						<div style={{ height: 5 }} />
					</Column>
				</TabRoot>
			);
		},
		InternalController,
	),
);

declare namespace MainTab {
	type Ref = InternalController | null;

	type Controller = InternalController;

	interface Value extends PersonData.Value, WorkData.Value {
		paymentAccounts: Account.Value["paymentAccounts"];
		paymentTransactions?: Account.Value["paymentTransactions"];
		notes: string;
		carIds: number[];
	}

	interface PropsBase {
		executorId?: number;
		value: Value;

		disabled: boolean;
		visible: boolean;
		language: Language;
		originalTaxiServiceId?: number;
		originalAlias?: string;

		onChange: Dispatch<Value>;
	}

	type Props = PropsBase & RefAttributes<Ref>;
}

export default MainTab;
