/* eslint-disable import/no-unresolved */
/* eslint-disable no-shadow */

import React, {
	RefAttributes,
	useCallback,
	useEffect,
	useMemo,
	useState,
} from "react";
import { useTranslation } from "react-i18next";
import { react, useRefWithSetter } from "uikit";

import CarPark from "../../../../../../../../services/CarPark";
import Dispatcher from "../../../../../../../../services/Dispatcher";
import Map from "../../../../../../../../redux/services/Map";
import useKeepCardsOpen from "../../../../../../../../hooks/useKeepCardsOpen";
import useCardSubscriber from "../../../../../../../../hooks/useCardSubscriber";
import useObjectEditor from "../../../../../../../../hooks/useObjectEditor";
import EditModalBase from "../../../../../../../../components/EditModal";

import Root from "./components/Root";
import Header from "./components/Header";
import Content from "./components/Content";
import InternalController from "./Controller";
import Footer from "./components/Footer";

const EditModal = react.withController<
	EditModal.PropsBase,
	EditModal.Controller
>(({ controller, value, language, onCancel, onSave }) => {
	const { t } = useTranslation();
	const [contentRef, setContentRef] = useRefWithSetter<Content.Ref | null>(
		null,
	);

	const cardIds = useMemo(
		() => (typeof value.id === "number" ? [value.id] : []),
		[value.id],
	);

	useKeepCardsOpen(cardIds, CarPark.Card);

	const cardSession = useCardSubscriber(CarPark.Card);

	const currentCard = useMemo(
		() =>
			cardSession?.cards.find((card) => cardIds.includes(card.id)) ??
			null,
		[cardIds, cardSession?.cards],
	);

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [history, setHistory] = useState<Dispatcher.History>([]);

	useEffect(() => {
		// (async () => {
		// 	if (value.id) setHistory(await Dispatcher.getHistory(value.id));
		// })();
	}, [value.id]);

	controller.setContext({ contentRef });

	const [tab, setTab] = useState<Header.Tab>("main");
	const [internalValue, setInternalValue] = useState(value);

	const valueEditor = useObjectEditor(internalValue, setInternalValue);

	const assignValue = valueEditor.useAssigner();

	const name = valueEditor.useGetter("name");

	const headerTitle = useMemo(
		() =>
			typeof value.id === "number"
				? name
				: t(
						"pages.mainPage.pages.accounts.tabs.carParks.editModal.str200",
				  ) ?? "",
		[name, t, value.id],
	);

	const headerLockedBy = useMemo(
		() =>
			currentCard?.locked
				? [
						currentCard.lockedBy.person?.lastName ?? "",
						currentCard.lockedBy.person?.firstName ?? "",
						currentCard.lockedBy.person?.fatherName ?? "",
				  ]
						.join(" ")
						.trim()
				: undefined,
		[currentCard?.locked, currentCard?.lockedBy],
	);

	const editable = useMemo(
		() => !(currentCard?.locked === true),
		[currentCard?.locked],
	);

	const modalContent = useMemo(
		() => (
			<Root sizes="auto! 1fr">
				<Header
					tab={tab}
					title={headerTitle}
					lockedBy={headerLockedBy}
					createdAt={value.createdAt}
					onChangeTab={setTab}
				/>
				<Content
					ref={setContentRef}
					value={internalValue}
					type={tab}
					disabled={!editable}
					language={language}
					history={history}
					onChange={assignValue}
				/>
			</Root>
		),
		[
			assignValue,
			editable,
			headerLockedBy,
			headerTitle,
			history,
			internalValue,
			language,
			setContentRef,
			tab,
			value.createdAt,
		],
	);

	const footerValue = valueEditor.usePicker(["active"]);

	const [footerError] = useState("");

	const modalFooter = useMemo(
		() => (
			<Footer
				value={footerValue}
				disabled={!editable}
				error={footerError}
				onChange={assignValue}
			/>
		),
		[assignValue, editable, footerError, footerValue],
	);

	const modalOnSave = useCallback(() => {
		if (!contentRef.current?.validate()) return;

		onSave(valueEditor.value);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [contentRef, onSave]);

	return (
		<EditModalBase
			footer={modalFooter}
			canSave={editable}
			onCancel={onCancel}
			onSave={modalOnSave}
		>
			{modalContent}
		</EditModalBase>
	);
}, InternalController);

declare namespace EditModal {
	type Ref = InternalController | null;

	type Controller = InternalController;

	interface Value extends Content.Value, Footer.Value {
		id?: number;

		name: string;

		createdAt?: string;
	}

	interface PropsBase {
		value: Value;
		language: Map.Language;

		onCancel: () => void;
		onSave: (value: Value) => void;
	}

	type Props = PropsBase & RefAttributes<Ref>;
}

export default EditModal;
