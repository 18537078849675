import { createObjectFromConstant } from "../../../../../../../assets/languages/langs";
import Modal from "../components/Modal";

const defaultExecutorTeam: Modal.Value = {
	name: createObjectFromConstant(),
	active: true,
	default: false,
	executorIds: [],
	taxiServiceIds: [],
	configuration: {
		enableReOffer: false,
		displayRequiredOffersInLiveTabAfterCancel: false,
		displayRequiredOffersInLiveTabAfterExpire: false,
		displayFreeWayOffersInLiveTabAfterCancel: false,
		displayFreeWayOffersInLiveTabAfterExpire: false,
		returnToParkingOnMandatoryOrderRefusal: false,
		returnToParkingOnFreeWaveOrderRefusal: false,
		returnToParkingOnMandatoryOrderAfterExpire: false,
		returnToParkingOnDispatcherRemovalFromMandatoryOrder: false,
		returnToParkingOnOrderCancellation: false,
	},

	taxiServices: [],
	companyIds: [],
	id: undefined,
	executorAppId: 0,
	priority: 0,

	isAllowToSelectCarClassesInApp: false,
};

export default defaultExecutorTeam;
