import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { Column } from "uikit";

import Client from "../../../../../../../../../../../services/Client";
import InputModal from "../../../../../../../../../../../components/InputModal";
import { UseTableOptions } from "../../../../../../../../../../../components/LightTable";

import ClientsSelectTable from "./components/ClientsSelectTable";

const ClientSelectModal: React.FC<ClientSelectModal.Props> = ({
	clients,
	selected,
	onClose,
	onSubmit,
	editorTable,
	onChangeTable,
}) => {
	const { t } = useTranslation();
	const [internalValue, setInternalValue] = useState(selected);

	const inputModalOnSubmit = useCallback(() => {
		onSubmit?.(internalValue ?? []);
	}, [internalValue, onSubmit]);

	return (
		<InputModal
			draggable
			title={
				t(
					"pages.mainPage.pages.customers.tabs.counterparties.tabs.employees.clientSelectModal.str0",
				) ?? ""
			}
			onClose={onClose}
			onSubmit={inputModalOnSubmit}
		>
			<Column style={{ width: "1000px", minHeight: "600px" }}>
				<ClientsSelectTable
					editorTable={editorTable}
					onChangeTable={onChangeTable}
					clients={clients}
					selected={internalValue}
					setSelected={setInternalValue}
				/>
			</Column>
		</InputModal>
	);
};

declare namespace ClientSelectModal {
	type Value = number[];

	interface Props {
		clients: Client.Model[];
		selected: Value;
		onClose?: () => void;
		onSubmit?: (value: Value) => void;
		editorTable: UseTableOptions["editor"];
		onChangeTable: UseTableOptions["onChange"];
	}
}

export default ClientSelectModal;
