import React, { memo } from "react";
import { ToggleButton } from "uikit";
import Accounts from "../../../..";

const Status: React.FC<Status.Props> = memo(({ value, model, saveHandler }) => (
	<div
		onClick={(event) => event.stopPropagation()}
		style={{ maxWidth: "max-content" }}
	>
		<ToggleButton.ToggleButton
			value={model.active}
			onChange={(newActive) => {
				const index = value.indexOf(model);
				saveHandler?.({ ...model, active: newActive }, index);
			}}
		/>
	</div>
));

declare namespace Status {
	interface Props {
		value: Accounts.Check[];
		model: Accounts.Check;
		saveHandler?: (check: Accounts.Check, index?: number | null) => void;
	}
}

export default Status;
