/* eslint-disable no-shadow */

import React, {
	Dispatch,
	RefAttributes,
	memo,
	useCallback,
	useMemo,
	useState,
} from "react";
import { Key, Option, Select, react } from "uikit";
import { Skeleton } from "@mui/material";
import { useTranslation } from "react-i18next";

import TaxiService from "../../../../../../../../../../../../../../../../../../../services/TaxiService";
import Client from "../../../../../../../../../../../../../../../../../../../services/Client";
import { useTypedSelector } from "../../../../../../../../../../../../../../../../../../../redux/store";
import useObjectEditor from "../../../../../../../../../../../../../../../../../../../hooks/useObjectEditor";
import useModelSubscribe from "../../../../../../../../../../../../../../../../../../../hooks/useModelSubscribe";
import RequiredKeys from "../../../../../../../../../../../../../../../../../../../types/RequiredKeys";
import LabeledField from "../../../../../../../../../../../../../../../../../../../components/LabeledField";

import InternalController from "./Controller";

declare namespace TaxiServiceSelect {
	type Ref = InternalController;

	type Controller = InternalController;
	type Value = RequiredKeys<
		Partial<
			Pick<
				Client.Model,
				"companyId" | "defaultTaxiServiceId" | "carClassId"
			>
		>
	>;

	interface PropsBase {
		disabled: boolean;

		value: Value;
		onChange: Dispatch<Value>;
	}

	type Props = PropsBase & RefAttributes<Ref>;
}

const TaxiServiceSelectBase = react.withController<
	TaxiServiceSelect.PropsBase,
	TaxiServiceSelect.Controller
>(({ controller, disabled, value, onChange }) => {
	const { t } = useTranslation();
	const language = useTypedSelector((state) => state.session.language);

	const valueEditor = useObjectEditor(value, onChange);

	const companyId = valueEditor.useGetter("companyId");
	const taxiServiceId = valueEditor.useGetter("defaultTaxiServiceId");
	const setTaxiServiceId = valueEditor.useSetter("defaultTaxiServiceId");

	const setCarClassId = valueEditor.useSetter("carClassId");

	const onTaxiServiceIdChange = useCallback(
		(newValue: Key) => {
			setTaxiServiceId(newValue as typeof taxiServiceId);
			setTaxiServiceIdError(false);
			setCarClassId(undefined);
		},
		[setTaxiServiceId, setCarClassId],
	);

	const [taxiServiceIdError, setTaxiServiceIdError] = useState(false);

	controller.setContext({
		taxiServiceId,
		onTaxiServiceIdError: setTaxiServiceIdError,
	});

	const taxiServices = useModelSubscribe(
		useMemo(
			() => ({
				companyIds: companyId != null ? [companyId] : undefined,
			}),
			[companyId],
		),
		TaxiService,
	);

	const options = useMemo<Option<TaxiService.Model["id"]>[]>(() => {
		const options =
			companyId != null &&
			taxiServices?.cache.map((taxiService) => ({
				key: taxiService.id,
				label: taxiService.settlement[language],
				value: taxiService.id,
			}));

		return options || [];
	}, [companyId, taxiServices?.cache, language]);

	return (
		<LabeledField
			label={
				t(`mainPage.customers.clients.main.other.registrationCity`) ||
				"Registration city"
			}
		>
			{!taxiServices ? (
				<Skeleton variant="rectangular" width={275.5} height={32} />
			) : (
				<Select
					disabled={disabled}
					value={taxiServiceId}
					onChange={onTaxiServiceIdChange}
					options={options}
					placeholder={
						companyId == null
							? t(`selectCompanyFirst`) || "Select company first"
							: t(
									`mainPage.customers.clients.main.other.no_data`,
							  ) || "No data"
					}
					error={taxiServiceIdError}
				/>
			)}
		</LabeledField>
	);
}, InternalController);

const TaxiServiceSelect = memo(TaxiServiceSelectBase);

export default TaxiServiceSelect;
