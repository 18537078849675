import React, { memo, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { Column, theme } from "uikit";
import styled from "styled-components";

import Message from "../../../../../../../../../../../../services/Message";
import Executor from "../../../../../../../../../../../../services/Executor";
import useObjectEditor from "../../../../../../../../../../../../hooks/useObjectEditor";
import EditModal from "../../../../../../../../../../../../components/EditModal";
import Template from "./components/Template";
import defaultValue from "./defaultValue";

const Root = styled(Column)`
	padding: 25px;
	min-width: 550px;
	min-height: 400px;
`;

const Header = styled(Column)`
	padding-left: 20px;
	height: 50px;
	background: ${theme.colors.button_secondary};
	justify-content: center;

	font-size: 20px;
	line-height: 1;
	font-weight: 700;
	color: ${theme.colors.primary};
`;

const Modal: React.FC<Modal.Props> = memo(
	({ executors, templates, onClose, onSubmit }) => {
		const { t } = useTranslation();
		const [value, setValue] = useState<Modal.Value>(defaultValue);

		const editor = useObjectEditor(value, setValue);

		const sendData = editor.useGetter("data");
		const setSendData = editor.useSetter("data");
		const [sendDataError, setSendDataError] = useState({
			templateId: false,
			text: false,
			title: false,
		});

		const handleSubmit = useCallback(async () => {
			const {
				notificationTemplateId,
				text,
				title,
				bigPicture,
				largeIcon,
			} = sendData;
			if (notificationTemplateId == null) {
				setSendDataError({ ...sendDataError, templateId: true });
				return;
			}
			if (!notificationTemplateId && !title && !text) {
				setSendDataError({ ...sendDataError, title: true, text: true });
				return;
			}
			if (!notificationTemplateId && !title) {
				setSendDataError({ ...sendDataError, title: true });
				return;
			}
			if (!notificationTemplateId && !text) {
				setSendDataError({ ...sendDataError, text: true });
				return;
			}

			if (bigPicture) {
				await bigPicture.upload();
			}
			if (largeIcon) {
				await largeIcon.upload();
			}

			const data: Message.SendPushNotice["data"] = notificationTemplateId
				? { notificationTemplateId }
				: {
						bigPictureId: bigPicture?.id as number,
						largeIconId: largeIcon?.id as number,
						title: title as string,
						text: text as string,
				  };

			const submitData: Message.SendPushNotice = {
				contacts: executors.map((e) => ({
					executorId: e.id,
					taxiServiceId: e.taxiService?.id as number,
				})),
				data,
			};

			onSubmit(submitData);
		}, [executors, onSubmit, sendData, sendDataError]);

		return (
			<EditModal
				onCancel={onClose}
				submitText={
					t(
						"pages.mainPage.pages.accounts.tabs.executors.header.sendingPush.modal.str0",
					) ?? ""
				}
				footerJustify="center"
				onSave={handleSubmit}
			>
				<Header>
					{t(
						"pages.mainPage.pages.accounts.tabs.executors.header.sendingPush.modal.str200",
					) ?? ""}{" "}
					PUSH{" "}
					{t(
						"pages.mainPage.pages.accounts.tabs.executors.header.sendingPush.modal.str201",
					) ?? ""}
				</Header>
				<Root maxedHeight maxedWidth gaps="20px*">
					<Template
						error={sendDataError}
						setError={setSendDataError}
						templates={templates}
						value={sendData}
						onChange={setSendData}
					/>
				</Root>
			</EditModal>
		);
	},
);

declare namespace Modal {
	interface Props {
		executors: Executor.Model[];
		templates: Template.Props["templates"];
		onClose: () => void;
		onSubmit: (x: Message.SendPushNotice) => void;
	}

	interface Value {
		data: Template.Value;
	}
}

export default Modal;
