import React, {
	RefAttributes,
	memo,
	useCallback,
	useLayoutEffect,
	useMemo,
	useState,
} from "react";
import { Row, react, useRefWithSetter } from "uikit";
import { useDebouncedCallback } from "use-debounce";

import { useTypedDispatch } from "../../../../../../../../../../../../redux/store";
import getPhoneInfo from "../../../../../../../../../../../../redux/services/Order/getPhoneInfo";
import useObjectEditor from "../../../../../../../../../../../../hooks/useObjectEditor";
import { validatePhone } from "../../../../../../../../../../../../utils/validatePhone";
import { ModelId } from "../../../../../../../../../../../../types/ModelId";
import TabRoot from "../../components/TabRoot";

import Level from "./components/Level";
import Phone from "./components/Phone";
import FullName from "./components/FullName";
import Description from "./components/Description";
import Companies from "./components/Companies";
import InternalController from "./Controller";

const MainBase = react.withController<Main.PropsBase, Main.Controller>(
	({ id, visible, value, onChange, controller }) => {
		const dispatch = useTypedDispatch();
		const [companyRef, setCompanyRef] =
			useRefWithSetter<Companies.Ref | null>(null);
		const [phoneRef, setPhoneRef] = useRefWithSetter<Phone.Ref | null>(
			null,
		);
		const [levelRef, setLevelRef] = useRefWithSetter<Level.Ref | null>(
			null,
		);
		const [descriptionRef, setDescriptionRef] =
			useRefWithSetter<Level.Ref | null>(null);

		controller.setContext({
			companyBranchRef: companyRef,
			phoneRef,
			levelRef,
			descriptionRef,
		});

		const [fullNames, setFullNames] = useState<FullName.Value[]>([]);

		const editor = useObjectEditor(value, onChange);

		const phone = editor.useGetter("phone");
		const setPhone = editor.useSetter("phone");

		const level = editor.useGetter("level");
		const setLevel = editor.useSetter("level");

		const description = editor.useGetter("description");
		const setDescription = editor.useSetter("description");

		const companyIds = editor.useGetter("companyIds");
		const setCompanyIds = editor.useSetter("companyIds");

		const fullName = editor.usePicker(["name", "surname", "fatherName"]);

		const name = editor.useGetter("name");
		const surname = editor.useGetter("surname");
		const fatherName = editor.useGetter("fatherName");
		const setName = editor.useSetter("name");
		const setSurname = editor.useSetter("surname");
		const setFatherName = editor.useSetter("fatherName");

		const assigner = editor.useAssigner();

		const addFullName = useCallback(
			(data: Pick<Main.Value, "name" | "surname" | "fatherName">) => {
				if (!data) return;
				const payload = {
					name: data?.name || name || "",
					surname: data?.surname || surname || "",
					fatherName: data?.fatherName || fatherName || "",
				};
				if (!name) setName(payload.name || "");
				if (!surname) setSurname(payload.surname || "");
				if (!fatherName) {
					setFatherName(payload.fatherName || "");
				}

				setFullNames((prev) => [...prev, payload]);
			},
			[name, surname, fatherName, setName, setSurname, setFatherName],
		);

		const debounceCustomerSearch = useDebouncedCallback((raw) => {
			const phone = raw?.trim() || "";
			const phoneNumber = phone
				.split("")
				.filter((a) => Number.isFinite(+a))
				.join("");

			const isValid = validatePhone(phoneNumber);
			if (!isValid) return;

			dispatch(
				getPhoneInfo(
					{
						companyId: companyIds[0],
						companyIds,
						phone: phoneNumber,
					},

					async (res) => {
						if (!res) return;
						setFullNames([]);
						const { client, clients } = res;

						if (client?.type === "customer") {
							addFullName(client.data?.person);
						}

						if (client?.type === "employee") {
							addFullName(client.data?.customer?.person);
						}

						if (clients && clients.length) {
							clients.forEach(({ client, blacklist }) => {
								if (client) {
									if (client?.type === "customer") {
										addFullName(client.data?.person);
									}

									if (client?.type === "employee") {
										addFullName(
											client.data?.customer?.person,
										);
									}
								} else if (blacklist) {
									const payload = {
										name: blacklist?.name || "",
										surname: blacklist?.surname || "",
										fatherName: blacklist?.fatherName || "",
									};

									addFullName(payload);
								}
							});
						}
					},
				),
			);
		}, 1000);

		useLayoutEffect(() => {
			debounceCustomerSearch(phone);
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [phone]);

		const dataNames = useMemo(() => {
			const payload = fullNames.map(
				(item) => !!item.name || !!item.surname || !!item.fatherName,
			);

			if (payload.includes(true)) return fullNames;
			return [];
		}, [fullNames]);

		return (
			<TabRoot hasPaddings visible={visible}>
				<Row sizes="1fr 1fr" gaps="16px*">
					<Companies
						ref={setCompanyRef}
						companyIds={companyIds}
						setCompanyIds={setCompanyIds}
					/>
				</Row>
				<Row sizes="1fr 1fr" gaps="16px*">
					<Phone
						ref={setPhoneRef}
						disabled={!!id || !companyIds.length}
						phone={phone}
						setPhone={setPhone}
					/>
					<Level
						ref={setLevelRef}
						level={level}
						setLevel={setLevel}
					/>
				</Row>

				{!dataNames.length && (
					<FullName value={fullName} onChange={assigner} />
				)}
				{!!dataNames.length &&
					fullNames.map((value, i) => {
						const onChange = (value: FullName.Value) => {
							setFullNames((prev) =>
								prev.map((item, index) => {
									if (index === i) return value;
									return item;
								}),
							);
						};

						return (
							<FullName
								key={i}
								value={value}
								onChange={onChange}
							/>
						);
					})}
				<Description
					ref={setDescriptionRef}
					description={description}
					setDescription={setDescription}
				/>
			</TabRoot>
		);
	},
	InternalController,
);

const Main = memo(MainBase);

declare namespace Main {
	type Ref = InternalController | null;
	type Controller = InternalController;

	interface PropsBase {
		id?: ModelId;
		visible: boolean;
		value: Value;
		onChange: (x: Value) => void;
	}
	type Props = PropsBase & RefAttributes<Ref>;

	interface Value extends FullName.Value {
		companyIds: Companies.Value;
		phone: Phone.Value;
		level: Level.Value;
		description: Description.Value;
	}
}

export default Main;
