import { react } from "uikit";

import Client from "../../../../../../../../../../../../../../services/Client";

interface Context {
	status: Client.Status;
	onStatusError: (error: boolean) => void;
}

export default class Controller extends react.ControllerBase<Context> {
	validate() {
		const isStatusValid = !!this.context?.status;

		if (!isStatusValid) this.context?.onStatusError(true);

		return isStatusValid;
	}
}
