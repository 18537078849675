import React, { memo, PropsWithChildren } from "react";
import { useTranslation } from "react-i18next";
import { CheckBox } from "uikit";

import useObjectEditor from "../../../../../../../../../../../../../../hooks/useObjectEditor";
import FieldsContainer from "../../../../../../../../../../../../../../components/FieldsContainer";
import {
	StyledRow,
	StyledColumn,
} from "../../../../../../../../../../../../../../components/common";
import tPath from "../../../../../../../../constants/tPath";

import LoginPassword from "./components/LoginPassword";

const Options: React.FC<Options.Props> = memo(
	({ value, onChange, children }) => {
		const { t } = useTranslation();

		const valueEditor = useObjectEditor(value, onChange);

		const mobileApp = valueEditor.useGetter(
			"accessAllEmployeeToPersonalApp",
		);
		const setMobileApp = valueEditor.useSetter(
			"accessAllEmployeeToPersonalApp",
		);
		const corporateAccount = valueEditor.useGetter(
			"isAccessToCorporateAccount",
		);
		const setCorporateAccount = valueEditor.useSetter(
			"isAccessToCorporateAccount",
		);
		// const addCustomerAsEmployeeAfterOrder = valueEditor.useGetter("addCustomerAsEmployeeAfterOrder");
		// const setAddCustomerAsEmployeeAfterOrder = valueEditor.useSetter("addCustomerAsEmployeeAfterOrder");

		const loginPassword = valueEditor.usePicker([
			"corporateLogin",
			"corporatePassword",
		]);

		const assigner = valueEditor.useAssigner();

		return (
			<FieldsContainer
				label={t(`${tPath}.modal.tabs.main.options.title`) || ""}
			>
				<StyledColumn gap="1rem" w="100%">
					<StyledRow w="100%" gap="10px" flex={{ flex: "1 1 100%" }}>
						{children}
					</StyledRow>
					<StyledRow w="100%" gap="10px 2rem" justify="space-between">
						<StyledRow w="40%" gap="10px">
							<LoginPassword
								value={loginPassword}
								onChange={assigner}
							/>
						</StyledRow>
						<StyledRow
							w="60%"
							gap="10px"
							p="0 0 10px 0"
							alignItems="end"
						>
							<CheckBox
								label={
									t(
										`${tPath}.modal.tabs.main.options.corporateApp`,
									) || ""
								}
								value={mobileApp}
								onChange={setMobileApp}
							/>
							<CheckBox
								disabled
								label={
									t(
										`${tPath}.modal.tabs.main.options.corporateAccount`,
									) || ""
								}
								value={corporateAccount}
								onChange={setCorporateAccount}
							/>
						</StyledRow>
					</StyledRow>
				</StyledColumn>
			</FieldsContainer>
		);
	},
);

declare namespace Options {
	interface Props extends PropsWithChildren {
		value: Value;
		onChange: (value: Value) => void;
	}

	interface Value extends LoginPassword.Value {
		addCustomerAsEmployeeAfterOrder: boolean;
		isAccessToCorporateAccount: boolean;
		accessAllEmployeeToPersonalApp: boolean;
	}
}

export default Options;
