import React from "react";

import { AccessCellContent as Content } from "../components";

import base from "./base";

import { Column, ColumnId } from ".";

function access({ width, onResize }: Column.Options) {
	return base(ColumnId.Access, (item) => <Content item={item} />, {
		width,
		onResize: onResize as (
			columnWidth?: number | undefined,
			dataKey?: string | undefined,
		) => void,
	});
}

export default access;
