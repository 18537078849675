import React, { memo } from "react";
import HistoryTab from "../../../../../../../../../../../../tabs/HistoryTab";

const History: React.FC<History.Props> = memo(({ visible }) => (
	<HistoryTab visible={visible} value={[]} />
));

declare namespace History {
	interface Props {
		visible: boolean;
		value: Value;
	}
	interface Value {}
}

export default History;
