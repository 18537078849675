import { react } from "uikit";

interface Context {
	value?: number;

	setCompanyError: (error: boolean) => void;
}

export default class Controller extends react.ControllerBase<Context> {
	validate() {
		const isCompanyIdValid = this.context?.value != null;

		if (!isCompanyIdValid) this.context?.setCompanyError(true);

		return isCompanyIdValid;
	}
}
