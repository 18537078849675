import React, { Dispatch, useMemo } from "react";

import CarClass from "../../../../../../../../../../../../../../services/CarClass";
import Language from "../../../../../../../../../../../../../../services/Language";
import CheckBoxSelect from "../../../../../../../../../../../../../../components/CheckBoxSelect";

const DistributableCarClass: React.FC<DistributableCarClass.Props> = ({
	value,
	onChange,
	distributableCarClass,
	disabled,
	language,
}) => {
	const items = useMemo(
		() => distributableCarClass ?? [],
		[distributableCarClass],
	);

	const classModelsOptions = useMemo(
		() =>
			items.map((item) => ({
				key: item.id,
				label: item.name?.[language],
			})),
		[items, language],
	);

	return (
		<CheckBoxSelect
			title="pages.preferencesPages.screenDirectory.carClass.modal.main.settingsData.str200"
			disabled={disabled}
			value={value}
			options={classModelsOptions}
			onChange={onChange}
			verticalScroll={true}
			h={{ max: "200px" }}
			p="2px"
			rows={{ auto: "21px" }}
		/>
	);
};

declare namespace DistributableCarClass {
	type Value = number[];

	interface Props {
		value: Value;
		onChange: Dispatch<Value>;
		distributableCarClass?: CarClass.Model[];

		disabled: boolean;
		language: Language;
	}
}

export default DistributableCarClass;
