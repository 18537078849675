import React, { Dispatch, memo, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { RadioButton, TextBox } from "uikit";

import { RewardCalculationMethod } from "../../../../../../../../../../../../../../../../../../services/Agent";
import {
	useObjectEditor,
	useCurrencyGlobalSettings,
} from "../../../../../../../../../../../../../../../../../../hooks";
import LabeledField from "../../../../../../../../../../../../../../../../../../components/LabeledField";
import {
	StyledColumn,
	StyledRow,
	StyledP,
	TextWithStepper,
} from "../../../../../../../../../../../../../../../../../../components/common";

const Content: React.FC<Content.Props> = memo(({ value, onChange }) => {
	const { t } = useTranslation();
	const currencyGlobalSettings = useCurrencyGlobalSettings();
	const editor = useObjectEditor(value, onChange);

	const name = editor.useGetter("name");
	const setName = editor.useSetter("name");

	const amount = editor.useGetter("amount");
	const setAmount = editor.useSetter("amount");

	const percent = editor.useGetter("percent");
	const setPercent = editor.useSetter("percent");

	const maxAmountFromOrderInPercent = editor.useGetter(
		"maxAmountFromOrderInPercent",
	);
	const setMaxAmountFromOrderInPercent = editor.useSetter(
		"maxAmountFromOrderInPercent",
	);

	const rewardCalculationMethod = editor.useGetter("rewardCalculationMethod");
	const setRewardCalculationMethod = editor.useSetter(
		"rewardCalculationMethod",
	);

	const setAgentRecipient = useCallback(() => {
		if (rewardCalculationMethod !== RewardCalculationMethod.ADD_TO_ORDER) {
			setRewardCalculationMethod(RewardCalculationMethod.ADD_TO_ORDER);
		}
	}, [rewardCalculationMethod, setRewardCalculationMethod]);

	const setServiceRecipient = useCallback(() => {
		if (
			rewardCalculationMethod !== RewardCalculationMethod.CUT_FROM_ORDER
		) {
			setRewardCalculationMethod(RewardCalculationMethod.CUT_FROM_ORDER);
		}
	}, [rewardCalculationMethod, setRewardCalculationMethod]);

	return (
		<StyledColumn p="20px" gap="20px">
			<LabeledField
				label={
					t(
						"pages.mainPage.pages.customers.tabs.agents.modal.content.tabs.reward.main.modal.content.str0",
					) ?? ""
				}
			>
				<TextBox.TextBox
					placeholder={
						t(
							"pages.mainPage.pages.customers.tabs.agents.modal.content.tabs.reward.main.modal.content.str0",
						) ?? ""
					}
					value={name}
					onChange={setName}
				/>
			</LabeledField>
			<StyledColumn gap="10px">
				<StyledP>
					{t(
						"pages.mainPage.pages.customers.tabs.agents.modal.content.tabs.reward.main.modal.content.str200",
					) ?? ""}
					:
				</StyledP>
				<StyledRow gap="10px">
					<RadioButton.RadioButton
						label={
							t(
								"pages.mainPage.pages.customers.tabs.agents.modal.content.tabs.reward.main.modal.content.str2",
							) ?? ""
						}
						selected={
							rewardCalculationMethod ===
							RewardCalculationMethod.ADD_TO_ORDER
						}
						onClick={setAgentRecipient}
					/>
					<RadioButton.RadioButton
						label={
							t(
								"pages.mainPage.pages.customers.tabs.agents.modal.content.tabs.reward.main.modal.content.str3",
							) ?? ""
						}
						selected={
							rewardCalculationMethod ===
							RewardCalculationMethod.CUT_FROM_ORDER
						}
						onClick={setServiceRecipient}
					/>
				</StyledRow>
			</StyledColumn>
			<StyledColumn w={{ min: "600px" }} gap="20px">
				<StyledRow gap="8px" alignItems="center">
					<TextWithStepper
						stepperSetting={{
							min: 0,
							max: 100,
						}}
						value={percent}
						onChange={setPercent}
						title={`${
							t(
								"pages.mainPage.pages.customers.tabs.agents.modal.content.tabs.reward.main.modal.content.str201",
							) ?? ""
						}
:`}
						textAfterStepper="%"
					/>
				</StyledRow>
				<StyledRow gap="8px" alignItems="center" w="100%">
					<TextWithStepper
						value={amount}
						onChange={setAmount}
						title={`${
							t(
								"pages.mainPage.pages.customers.tabs.agents.modal.content.tabs.reward.main.modal.content.str202",
							) ?? ""
						}:`}
						textAfterStepper={currencyGlobalSettings}
					/>

					<TextWithStepper
						stepperSetting={{
							min: 0,
							max: 100,
						}}
						disabled={
							rewardCalculationMethod !==
							RewardCalculationMethod.CUT_FROM_ORDER
						}
						value={maxAmountFromOrderInPercent}
						onChange={setMaxAmountFromOrderInPercent}
						title={
							t(
								"pages.mainPage.pages.customers.tabs.agents.modal.content.tabs.reward.main.modal.content.str204",
							) ?? ""
						}
						textAfterStepper={`% ${
							t(
								"pages.mainPage.pages.customers.tabs.agents.modal.content.tabs.reward.main.modal.content.str205",
							) ?? ""
						}`}
					/>
				</StyledRow>
			</StyledColumn>
		</StyledColumn>
	);
});

declare namespace Content {
	interface Props {
		value: Value;
		onChange: Dispatch<Value>;
	}

	interface Value {
		name: string;
		percent: number;
		amount: number;
		maxAmountFromOrderInPercent: number;
		rewardCalculationMethod: RewardCalculationMethod;
	}
}

export default Content;
