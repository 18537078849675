/* eslint-disable no-shadow */

import React, { Dispatch, RefAttributes } from "react";
import { Column, react } from "uikit";
import { useTranslation } from "react-i18next";

import Client from "../../../../../../../../../../../../../services/Client";
import useObjectEditor from "../../../../../../../../../../../../../hooks/useObjectEditor";
import FieldsContainer from "../../../../../../../../../../../../../components/FieldsContainer";

import TabRoot from "../../TabRoot";
import InternalController from "./Controller";

import SMSNotifications from "./components/SMSNotification";
import MobileApp from "./components/MobileApp";
import PersonalCabinet from "./components/PersonalCabinet";
import LoginPassword from "./components/LoginPassword";
import { StyledRow } from "../../../../../../../../../../../../../components/common";

declare namespace OptionsTab {
	type Ref = InternalController | null;

	type Controller = InternalController;

	type Value = SMSNotifications.Value &
		MobileApp.Value &
		PersonalCabinet.Value &
		LoginPassword.Value &
		Partial<Pick<Client.Model, "id">>;

	interface PropsBase {
		visible: boolean;

		value: Value;
		onChange: Dispatch<Value>;
	}

	type Props = PropsBase & RefAttributes<Ref>;
}

const OptionsTab = react.withController<
	OptionsTab.PropsBase,
	OptionsTab.Controller
>(({ value, visible, onChange }) => {
	const { t } = useTranslation();

	const valueEditor = useObjectEditor(value, onChange);
	const picker = valueEditor.usePicker([
		"smsNotifications",
		"isAllowedApp",
		"isAllowedCabinet",
		"login",
		"hasPassword",
		"password",
	]);
	const assigner = valueEditor.useAssigner();

	return (
		<TabRoot hasPaddings visible={visible}>
			<Column sizes="auto!*" gaps="19px*" maxedWidth maxedHeight>
				<FieldsContainer
					label={
						t(
							`mainPage.customers.clients.options.permissions.title`,
						) || "Permissions"
					}
				>
					<SMSNotifications value={picker} onChange={assigner} />
				</FieldsContainer>

				<FieldsContainer
					label={
						t(`mainPage.customers.clients.options.access.title`) ||
						"Access"
					}
				>
					<StyledRow gap="0 3rem" alignItems="end">
						<LoginPassword value={picker} onChange={assigner} />
						<StyledRow gap="8px" alignItems="end" p="0 0 6px 0">
							<MobileApp value={picker} onChange={assigner} />
							<PersonalCabinet
								value={picker}
								onChange={assigner}
							/>
						</StyledRow>
					</StyledRow>
				</FieldsContainer>
			</Column>
		</TabRoot>
	);
}, InternalController);

export default OptionsTab;
