import React, { memo, useCallback, useMemo, useState } from "react";
import { Button, Icon } from "uikit";
import { useTranslation } from "react-i18next";

import useModelSubscribe from "../../../../../../../../../../hooks/useModelSubscribe2";
import Client from "../../../../../../../../../../services/Client";
import Message from "../../../../../../../../../../services/Message";
import PushNotice from "../../../../../../../../../../services/PushNotice";
import {
	Popup,
	StyledP,
} from "../../../../../../../../../../components/common";

import Modal from "./components/Modal";

interface Props {
	clients: Client.Model[];
}

const SendingPush: React.FC<Props> = memo(({ clients }) => {
	const { t } = useTranslation();
	const { models: templates } = useModelSubscribe({}, PushNotice);

	const notOrdersTemplates = useMemo(
		() => templates.filter((t) => !t.orderTemplate),
		[templates],
	);

	const [showModal, setShowModal] = useState(false);

	const handleShowModal = useCallback(() => {
		setShowModal(true);
	}, []);

	const handleCloseModal = useCallback(() => {
		setShowModal(false);
	}, []);

	const handleSend = useCallback(async (value: Message.SendPushNotice) => {
		const res = await Message.sendPushNotice(value);
		if (res) {
			setShowModal(false);
		}
	}, []);

	const disabled = useMemo(
		() => clients.length === 0 || clients.some((c) => !c.isApp),
		[clients],
	);

	return (
		<Popup
			useHoverControl
			trackerId={"client_call_id"}
			offset={{ x: 32, y: 33 }}
			tracker={
				<div>
					<Button.Button
						disabled={disabled}
						variant="secondary"
						onClick={handleShowModal}
						icon={<Icon id="push" size={32} />}
					/>
					{showModal && (
						<Modal
							clients={clients}
							templates={notOrdersTemplates}
							onClose={handleCloseModal}
							onSubmit={handleSend}
						/>
					)}
				</div>
			}
		>
			{!showModal && (
				<StyledP br="4px" bgC="#000000" colors="#ffffff" p="5px 10px">
					{t(
						"pages.mainPage.pages.customers.tabs.clients.header.sendingPush.str200",
					) ?? ""}{" "}
					PUSH
				</StyledP>
			)}
		</Popup>
	);
});

export default SendingPush;
