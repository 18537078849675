/* eslint-disable no-shadow */
import React, {
	Dispatch,
	ReactElement,
	useCallback,
	useMemo,
	useRef,
	useState,
} from "react";
import { CheckBox, Column, Table } from "uikit";
import { SortType } from "rsuite-table";
import { UseTranslationResponse, useTranslation } from "react-i18next";
import { clone } from "lodash";

import Language from "../../../../../../../../../../../../../../services/Language";
import Company from "../../../../../../../../../../../../../../services/Company";
import TaxiService from "../../../../../../../../../../../../../../services/TaxiService";
import Executor from "../../../../../../../../../../../../../../services/Executor";
import { useTypedSelector } from "../../../../../../../../../../../../../../redux/store";
import useModelSubscribe from "../../../../../../../../../../../../../../hooks/useModelSubscribe";
import mapByKey from "../../../../../../../../../../../../../../utils/mapByKey";
import LightTable from "../../../../../../../../../../../../../../components/LightTable";
import TableSettings from "../../../../../../../../../../../../../../components/TableSettings";

import { getColumns, defaultColumnIds } from "./constants";
import HeaderBase, { Header } from "./components/Header";

interface ColumnContext {
	language: Language;

	companyById: Record<number, Company.Model>;
	taxiServiceById: Record<number, TaxiService.Model>;

	t: UseTranslationResponse<"translation", undefined>[0];
}

const Columns: Record<string, (context: ColumnContext) => ReactElement> = {
	fullName: ({ t }) => (
		<LightTable.Column width={400} sortable resizable>
			<LightTable.HeaderCell verticalAlign="middle">
				{t(
					"pages.mainPage.pages.accounts.tabs.executorTeams.modal.content.tabs.executorSelectTab.modelTable.str150",
				) ?? ""}
			</LightTable.HeaderCell>
			<LightTable.Cell verticalAlign="middle" dataKey="fullName" fullText>
				{(item) =>
					`${item.person.lastName ?? ""} ${
						item.person.firstName ?? ""
					} ${item.person.fatherName ?? ""}`.trim()
				}
			</LightTable.Cell>
		</LightTable.Column>
	),

	roles: ({ t }) => (
		<LightTable.Column width={140} sortable resizable>
			<LightTable.HeaderCell>
				{t(
					"pages.mainPage.pages.accounts.tabs.executorTeams.modal.content.tabs.executorSelectTab.modelTable.str100",
				) ?? ""}
			</LightTable.HeaderCell>
			<LightTable.Cell dataKey="roles" fullText>
				{(rowData) =>
					rowData.roles?.map((role) => role.name).join(", ") ?? ""
				}
			</LightTable.Cell>
		</LightTable.Column>
	),

	alias: ({ t }) => (
		<LightTable.Column width={200} sortable resizable>
			<LightTable.HeaderCell verticalAlign="middle">
				{t(
					"pages.mainPage.pages.accounts.tabs.executorTeams.modal.content.tabs.executorSelectTab.modelTable.str151",
				) ?? ""}
			</LightTable.HeaderCell>
			<LightTable.Cell verticalAlign="middle" dataKey="alias" fullText>
				{(item) => item.alias}
			</LightTable.Cell>
		</LightTable.Column>
	),
	team: ({ language, t }) => (
		<LightTable.Column width={200} sortable resizable>
			<LightTable.HeaderCell verticalAlign="middle">
				{t(
					"pages.mainPage.pages.accounts.tabs.executorTeams.modal.content.tabs.executorSelectTab.modelTable.str152",
				) ?? ""}
			</LightTable.HeaderCell>
			<LightTable.Cell verticalAlign="middle" dataKey="team" fullText>
				{(item) => item.group?.name?.[language] ?? ""}
			</LightTable.Cell>
		</LightTable.Column>
	),
	company: ({ language, companyById, taxiServiceById, t }) => (
		<LightTable.Column width={200} sortable resizable>
			<LightTable.HeaderCell verticalAlign="middle">
				{t(
					"pages.mainPage.pages.accounts.tabs.executorTeams.modal.content.tabs.executorSelectTab.modelTable.str153",
				) ?? ""}
			</LightTable.HeaderCell>
			<LightTable.Cell
				verticalAlign="middle"
				dataKey="taxiService"
				fullText
			>
				{(item) => {
					const { taxiService } = item;
					const companyId =
						taxiServiceById[taxiService.id]?.company?.id;

					const companyName = companyId
						? companyById[companyId]?.name?.[language] ?? ""
						: "";

					return (
						<div
							style={{
								textOverflow: "ellipsis",
								overflow: "hidden",
								whiteSpace: "nowrap",
							}}
						>
							{companyName ?? ""}
						</div>
					);
				}}
			</LightTable.Cell>
		</LightTable.Column>
	),

	taxiService: ({ language, taxiServiceById, t }) => (
		<LightTable.Column width={200} sortable resizable>
			<LightTable.HeaderCell verticalAlign="middle">
				{t(
					"pages.mainPage.pages.accounts.tabs.executorTeams.modal.content.tabs.executorSelectTab.modelTable.str154",
				) ?? ""}
			</LightTable.HeaderCell>
			<LightTable.Cell
				verticalAlign="middle"
				dataKey="taxiService"
				fullText
			>
				{(item) => {
					const { taxiService } = item;

					const taxiServiceName =
						taxiServiceById[taxiService?.id]?.settlement?.[
							language
						] ?? "";

					return (
						<div
							style={{
								textOverflow: "ellipsis",
								overflow: "hidden",
								whiteSpace: "nowrap",
							}}
						>
							{taxiServiceName ?? ""}
						</div>
					);
				}}
			</LightTable.Cell>
		</LightTable.Column>
	),
};

const ModelTable: React.FC<ModelTable.Props> = ({
	filters,
	selected,
	sort,
	disabled,
	loading,
	data,
	language,
	allowedTaxiServiceIds,
	onChangeFilters,
	onChangeSelected,
	onChangeSort,
	onLoadMore,
}) => {
	const [t] = useTranslation();

	const columns = useMemo(() => getColumns(t), [t]);

	const canLoadMoreRef = useRef(true);
	const tableRef = useRef<Table.Ref | null>(null);

	const setItemSelection = useCallback(
		(id: number, value: boolean) => {
			if (value) {
				const newSelected = clone(selected);

				newSelected.push(id);

				onChangeSelected(newSelected);
			} else
				onChangeSelected(
					selected.filter((selectedId) => selectedId !== id),
				);
		},
		[onChangeSelected, selected],
	);

	const tableRowClassName = useCallback((item) => {
		if (!item?.active) return "not-active";

		return "";
	}, []);

	const tableOnScroll = useMemo(() => {
		canLoadMoreRef.current = true;

		return (x: number, y: number) => {
			if (!canLoadMoreRef.current) return;

			const contextHeight = data.length * 44;
			const top = Math.abs(y);
			const tableContainerHeight =
				tableRef.current?.root.getBoundingClientRect().height ?? 0;

			if (contextHeight - top - tableContainerHeight < 300) {
				onLoadMore();

				canLoadMoreRef.current = false;
			}
		};
	}, [data.length, onLoadMore]);

	const [columnIds, setColumnIds] = useState(defaultColumnIds);

	const companyData = useModelSubscribe({}, Company);

	const companies = useMemo(
		() => companyData?.cache ?? [],
		[companyData?.cache],
	);

	const companyById = useMemo(() => mapByKey(companies, "id"), [companies]);

	const { models: taxiServices } = useTypedSelector(
		(state) => state.taxiServices,
	);

	const taxiServiceById = useMemo(
		() => mapByKey(taxiServices, "id"),
		[taxiServices],
	);

	const columnContext = useMemo<ColumnContext>(
		() => ({ language, companyById, taxiServiceById, t }),
		[companyById, language, t, taxiServiceById],
	);

	return (
		<Column sizes="auto! 1fr auto!" maxedWidth maxedHeight>
			<Header
				filters={filters}
				allowedTaxiServiceIds={allowedTaxiServiceIds}
				language={language}
				onChangeFilters={onChangeFilters}
			/>
			<LightTable
				ref={tableRef}
				fillHeight
				virtualized
				shouldUpdateScroll={false}
				headerHeight={44}
				rowHeight={44}
				sortColumn={sort.column}
				sortType={sort.type}
				loading={loading}
				data={data}
				rowClassName={tableRowClassName}
				onScroll={tableOnScroll}
				onSortColumn={(column, type) => onChangeSort({ column, type })}
			>
				<LightTable.Column width={36}>
					<LightTable.HeaderCell verticalAlign="middle">
						<CheckBox
							value={
								!!data.length &&
								data.every((item) => selected.includes(item.id))
							}
							disabled={disabled}
							onChange={(value) =>
								onChangeSelected(
									value
										? selected.concat(
												data
													.filter(
														(item) =>
															!selected.includes(
																item.id,
															),
													)
													.map((item) => item.id),
										  )
										: selected.filter(
												(id) =>
													!data.find(
														(item) =>
															item.id === id,
													),
										  ),
								)
							}
						/>
					</LightTable.HeaderCell>
					<LightTable.Cell verticalAlign="middle">
						{(item) => (
							<CheckBox
								value={selected.includes(item.id)}
								disabled={disabled}
								onChange={(selected) =>
									setItemSelection(item.id, selected)
								}
							/>
						)}
					</LightTable.Cell>
				</LightTable.Column>

				{columnIds.map((columnId) => Columns[columnId](columnContext))}
			</LightTable>
			<TableSettings
				value={columnIds}
				defaultValue={defaultColumnIds}
				columns={columns}
				onChange={setColumnIds}
			/>
		</Column>
	);
};

declare namespace ModelTable {
	interface Sort {
		column?: string;
		type?: SortType;
	}

	interface Props {
		filters: HeaderBase.Filters.Value;
		selected: number[];
		sort: Sort;

		disabled: boolean;
		loading: boolean;
		data: Executor.Model[];
		language: Language;
		allowedTaxiServiceIds: number[];

		onChangeFilters: Dispatch<HeaderBase.Filters.Value>;
		onChangeSelected: Dispatch<number[]>;
		onChangeSort: Dispatch<Sort>;

		onLoadMore: () => void;
	}
}

export default ModelTable;
