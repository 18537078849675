import React, { memo } from "react";
import { Column, react } from "uikit";

const ContentBase = react.inputify<Content.Props>(() => (
	<Column gaps="12px*" style={{ flexGrow: 1 }}></Column>
));
const Content = memo(ContentBase);

declare namespace Content {
	interface Props {}
}

export default Content;
