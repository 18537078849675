import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import LightTable from "../../../../../../../../../../../../../../../components/LightTable";
import TableSortMethod from "../../../../../../../../../../../../../../../types/SortMethod";

declare namespace AddressTable {
	interface Row {
		key: string;
		rideNumber: number;

		settlement: string;
		street: string;
		house: string;
	}

	type Sort = TableSortMethod<keyof AddressTable.Row>;

	export interface Props {
		data: Row[];

		sort: Sort;
		onSortChange: (sort: Sort) => void;

		defaultAddress?: string;
		onChangeDefaultAddress: (addressHash: string) => void;
	}
}

const AddressTable: React.FC<AddressTable.Props> = ({
	data,

	sort,
	onSortChange,

	// defaultAddress,
	// onChangeDefaultAddress,
}) => {
	const { t } = useTranslation();

	const makeSortableColumn = useCallback(
		(
			key: keyof AddressTable.Row,
			props?: Parameters<typeof LightTable.Column>[0],
		) => (
			<LightTable.Column {...props} sortable resizable>
				<LightTable.HeaderCell>
					{t(`mainPage.customers.clients.addresses.${key}`)}
				</LightTable.HeaderCell>

				<LightTable.Cell dataKey={key} fullText>
					{(rowData) => rowData[key]}
				</LightTable.Cell>
			</LightTable.Column>
		),
		[t],
	);

	return (
		<LightTable
			data={data}
			sortColumn={sort.column}
			sortType={sort.type}
			fillHeight
			virtualized
			shouldUpdateScroll={false}
			onSortColumn={(dataKey, type) => {
				onSortChange({
					column: dataKey as keyof AddressTable.Row,
					type,
				});
			}}
		>
			{/* <LightTable.Column width={100}>
				<LightTable.HeaderCell>
					<Flex justify="center" align="center">
						{t(`mainPage.customers.clients.addresses.main`)}
					</Flex>
				</LightTable.HeaderCell>

				<LightTable.Cell>
					{(rowData) => (
						<Flex justify="center" align="center">
							<CheckBox
								value={
									(rowData as AddressTable.Row).key ===
									defaultAddress
								}
								onChange={(isCheck) => {
									if (isCheck)
										onChangeDefaultAddress(
											(rowData as AddressTable.Row).key,
										);
								}}
							/>
						</Flex>
					)}
				</LightTable.Cell>
			</LightTable.Column> */}

			{makeSortableColumn("rideNumber", { flexGrow: 0.2 })}
			{makeSortableColumn("settlement", { flexGrow: 0.3 })}
			{makeSortableColumn("street", { flexGrow: 0.7 })}
			{makeSortableColumn("house", { flexGrow: 0.3 })}
		</LightTable>
	);
};

export default AddressTable;
