export const openAndCloseTypeOptions = [
	{
		key: "auto",
		value: "auto",
		label: "Автоматический",
	},
	{
		key: "manually",
		value: "manually",
		label: "Ручной",
	},
];

export default { assignOptions: openAndCloseTypeOptions };
