import React, {
	Dispatch,
	useCallback,
	useState,
	memo,
	RefAttributes,
} from "react";
import { CheckBoxWithContent, react, useRefWithSetter } from "uikit";
import { useTranslation } from "react-i18next";

import ExecutorGroup from "../../../../../../../../services/ExecutorGroup";
import useObjectEditor from "../../../../../../../../hooks/useObjectEditor";
import { ExecutorTeamsProvider } from "../../../../../context";
import ClarificationModal from "../../../../../../../../components/DeleteModal";
import EditModal from "../../../../../../../../components/EditModal";
import {
	StyledGrid,
	StyledRow,
} from "../../../../../../../../components/common";
import translationPath from "../../constants/translationPath";

import Content from "./components/Content";
import Header from "./components/Header";
import InternalController from "./Controller";

const ModalBase = react.withController<Modal.PropsBase, Modal.Controller>(
	({
		value,
		onCancel,
		onSave,
		createdAt,
		headerTitle,

		showClarificationModal,
		setShowClarificationModal,
		controller,
	}) => {
		const { t } = useTranslation();

		const [tab, setTab] = useState<Header.TabType>("main");

		const [internalValue, setInternalValue] = useState(value);

		const [contentRef, setContentRef] =
			useRefWithSetter<Content.Ref | null>(null);
		controller.setContext({ contentRef });

		const handleSubmit = useCallback(() => {
			const validate = contentRef.current?.validate();
			if (!validate) return;

			onSave(internalValue);
		}, [contentRef, onSave, internalValue]);

		const valueEditor = useObjectEditor(internalValue, setInternalValue);

		const active = valueEditor.useGetter("active");
		const setActive = valueEditor.useSetter("active");
		const isDefault = valueEditor.useGetter("default");
		const setIsDefault = valueEditor.useSetter("default");

		const contentValues = valueEditor.usePicker([
			"taxiServices",
			"taxiServiceIds",
			"companyIds",
			"executorIds",
			"executorAppId",
			"id",
			"name",
			"priority",
			"isAllowToSelectCarClassesInApp",
			"configuration",
		]);
		const assigner = valueEditor.useAssigner();

		const cancelClarifyModalHandler = useCallback(() => {
			setShowClarificationModal(false);
		}, [setShowClarificationModal]);

		return (
			<EditModal
				onCancel={onCancel}
				onSave={handleSubmit}
				footer={
					<StyledGrid areas="" gap="25px" alignItems="center">
						<StyledRow gap="30px">
							<CheckBoxWithContent
								value={active}
								onChange={setActive}
							>
								{t(`${translationPath}.modal.active`) || ""}
							</CheckBoxWithContent>
							<CheckBoxWithContent
								value={isDefault}
								onChange={setIsDefault}
							>
								{t(`${translationPath}.modal.default`) || ""}
							</CheckBoxWithContent>
						</StyledRow>
					</StyledGrid>
				}
			>
				<ExecutorTeamsProvider
					id="executor_times_modal_provider_id"
					internalValue={internalValue}
					setInternalValue={setInternalValue}
				>
					<StyledGrid
						areas=""
						rows="auto 1fr"
						br="5px"
						over="hidden"
						w={{ width: "1200px", min: "900px" }}
						h={{ height: "86vh", min: "500px" }}
					>
						<Header
							title={headerTitle}
							createdAt={createdAt}
							tab={tab}
							onChangeTab={setTab}
						/>
						<Content
							tab={tab}
							value={contentValues}
							onChange={assigner}
							ref={setContentRef}
						/>
					</StyledGrid>
					{showClarificationModal && (
						<ClarificationModal
							label={
								t(`${translationPath}.clarificationModal`) || ""
							}
							onCancel={cancelClarifyModalHandler}
							onConfirm={() => {}}
						/>
					)}
				</ExecutorTeamsProvider>
			</EditModal>
		);
	},
	InternalController,
);

const Modal = memo(ModalBase);
declare namespace Modal {
	type Ref = InternalController | null;
	type Controller = InternalController;

	interface PropsBase {
		value: Value;
		headerTitle: string;
		createdAt: string;
		onCancel: () => void;
		onSave: (template: any, force?: boolean) => Promise<void>;

		showClarificationModal: boolean;
		setShowClarificationModal: Dispatch<boolean>;
	}

	type Props = PropsBase & RefAttributes<Ref>;
	interface Value
		extends Pick<
				ExecutorGroup.Model,
				"active" | "default" | "name" | "configuration"
			>,
			Content.Value {}
}

export default Modal;
